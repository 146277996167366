import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="loginIcon"
      {...props}
    >
      <defs>
        <path
          id="bppr3sgs9a"
          d="M19 4c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1h1V6h-1c-.552 0-1-.448-1-1s.448-1 1-1zm-8.6 3.2c.442-.331 1.069-.242 1.4.2l3.02 4.02c.257.351.257.829 0 1.18l-2.82 4c-.187.262-.488.419-.81.42-.207.002-.41-.061-.58-.18-.218-.153-.367-.387-.412-.65-.045-.263.017-.533.172-.75L12.09 13H4c-.552 0-1-.448-1-1s.448-1 1-1h8l-1.8-2.4c-.331-.442-.242-1.069.2-1.4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-1260 -32) translate(606.5 28) translate(577.5 4) translate(76)">
                <path d="M0 0H24V24H0z" />
                <mask id="3doiszq6yb" fill="#fff">
                  <use xlinkHref="#bppr3sgs9a" />
                </mask>
                <g fill="#1AAADC" mask="url(#3doiszq6yb)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
