import { FC, Fragment, memo } from 'react';
import { DialogWithActions } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  storeCardTransactions,
  closeUserBelongToSomeoneElseModal,
  mbSaveRegisterCardRequest,
} from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';
import { saveCardBelongsToSomeoneElseNormalize } from 'Normalize';

type CardBelongSomeElseModelProps = {
  setOtherCard: () => void;
  setActive: () => void;
};

const CardBelongSomeElseModel: FC<CardBelongSomeElseModelProps> = memo(({ setOtherCard, setActive }) => {
  const dispatch = useDispatch();

  const {
    web_btn_add_card_add_and_next,
    web_desc_not_your_card,
    web_title_not_your_card,
    web_btn_vazgec,
  } = useLocalization();
  const { showUserBelongSomeoneModal, tempCardInfo } = useSelector(storeCardTransactions);

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  return (
    <div id="CardBelongSomeElseModel">
      <DialogWithActions
        closeIcon
        title={web_title_not_your_card}
        text={web_desc_not_your_card}
        primaryButton={{
          label: web_btn_add_card_add_and_next,
          handleClick: () => {
            dispatch(
              mbSaveRegisterCardRequest(
                saveCardBelongsToSomeoneElseNormalize(tempCardInfo, customerNumber, sessionToken)
              )
            );
            setOtherCard();
            setActive();
          },
        }}
        secondaryButton={{
          label: web_btn_vazgec,
          handleClick: () => dispatch(closeUserBelongToSomeoneElseModal()),
        }}
        hide={() => dispatch(closeUserBelongToSomeoneElseModal())}
        open={showUserBelongSomeoneModal}
        alignTitle="left"
      />
    </div>
  );
});
export default CardBelongSomeElseModel;
