import * as React from 'react';

function FavoriteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M7 12.25a.583.583 0 0 1-.414-.17L2.053 7.544a3.068 3.068 0 0 1 0-4.317 3.057 3.057 0 0 1 4.317 0l.63.63.63-.63a3.057 3.057 0 0 1 4.317 0 3.068 3.068 0 0 1 0 4.316l-4.533 4.539c-.11.109-.259.17-.414.169z"
          id="bajnjw26ea"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h14v14H0z" />
        <mask id="7kqyr5uifb" fill="#fff">
          <use xlinkHref="#bajnjw26ea" />
        </mask>
        <use fill="#000" fill-rule="nonzero" xlinkHref="#bajnjw26ea" />
        <g mask="url(#7kqyr5uifb)" fill="#F47524">
          <path d="M0 0h14v14H0z" />
        </g>
      </g>
    </svg>
  );
}

export default FavoriteIcon;
