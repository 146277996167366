import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';
import { OnUpdateOnlinePermissionBodyData } from 'Config/ServiceConfig';

const command = 'RI.OnUpdateOnlinePermission';

const onUpdateOnlinePermission = async (params: OnUpdateOnlinePermissionBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onUpdateOnlinePermission;
