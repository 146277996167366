import { PayloadAction } from '@reduxjs/toolkit';
import {
  formNames,
  onAddApplicationBodyData,
  onGetShippingLocationInfoListBodyData,
  onOnlineApplicationQueryBodyData,
  OnUpdateApplicationBodyData,
  onUpdateCustomerAddressReqBodyData,
} from 'Config';
import { replace, RouterState } from 'connected-react-router';
import {
  CardApplicationUpdateModel,
  OnAddApplicationModel,
  ProductPriceModel,
  OnGetShippingLocationInfoListModel,
  OnUpdateCustomerAdressModel,
} from 'Models';
import { cityListItem } from 'Models/GetCityListModel';
import { townOtherListItem } from 'Models/GetTownListOtherModel';
import { routePath } from 'Navigator/routes';
import { masterPassPaymentNormalize, VsPolBelbimNormalize } from 'Normalize';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  onAddApplication,
  onGetShippingLocationInfoList,
  OnGetSubProductList,
  onUpdateApplication,
  onUpdateCustomerAdress,
  productPrice,
} from 'Services/Queries';
import { AccountState, storeAccount } from 'Stores/Account';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { onGetMasterpassTokenRequest, vsPosBelbimPaymentRequest } from 'Stores/CreditCardTransactions';
import { resetFormError, setFormError } from 'Stores/Form';
import { showToastify } from 'Stores/Toastify';
import { UserState, userStore } from 'Stores/User';
import { storeWelcome, WelcomeState } from 'Stores/Welcome';
import { getEnvironment, isBelbimRequestSuccess, MasterPassTokenEnum } from 'Utils';
import { refTrnType } from 'Views/AddBalanceToCard/constant';
import { resendCargoSuccess } from '.';

import {
  applicationStore,
  getProductPriceFail,
  getProductPriceRequest,
  getProductPriceSuccess,
  getSubProductList,
  onAddApplicationFail,
  onAddApplicationRequest,
  onAddApplicationSuccess,
  onGetShippingLocationInfoListFail,
  onGetShippingLocationInfoListRequest,
  onGetShippingLocationInfoListSuccess,
  onGetSubProductListSuccess,
  onUpdateApplicationFail,
  onUpdateApplicationRequest,
  onUpdateApplicationSuccess,
  resendCargoFail,
  resendCargoRequest,
  setApplicationData,
} from './slices';
import { ApplicationProcessState } from './types';
import { SubProductResponseModel } from 'Models/OnGetSubProductList';

function createRequestBodyData(
  activeCardShippingFee: number,
  activeUpStateShippingFee: number,
  data: onAddApplicationBodyData,
  otherTownList: townOtherListItem[],
  cityList: cityListItem[],
  h?: string
) {
  const otherTownCode = otherTownList?.find((item: townOtherListItem) => {
    return (
      item?.TownName?.toLocaleLowerCase('tr-TR') ===
      String(data.AddressDetail?.TownCode || data.AddressDetail?.Town)?.toLocaleLowerCase('tr-TR')
    );
  });

  const otherCityCode = cityList?.find((item: cityListItem) => item.CityId === String(data.AddressDetail?.CityCode));
  let requestBodyData = { ...data };

  if (!data.ApplicationCenterId) {
    requestBodyData = {
      ...data,
      AddressDetail: {
        AddressType: 9,
        CountryCode: 90,
        City: otherCityCode?.CityName,
        CityCode: data.AddressDetail?.CityCode,
        TownCode: otherTownCode ? Number(otherTownCode?.TownId) : Number(data.AddressDetail?.TownCode),
        District: data.AddressDetail?.District,
        Street: data.AddressDetail?.Street,
        AddressDetail: data.AddressDetail?.AddressDetail,
        PostalCode: Number(data.AddressDetail?.PostalCode),
      },
      IsFree: false,
      ShippingFee: data?.AddressDetail?.CityCode === 34 ? activeCardShippingFee : activeUpStateShippingFee,
      SubProductCode: '',
      ApplicationCenterId: 0,
      h,
    };
  } else {
    requestBodyData = {
      ...data,
      AddressDetail: {
        AddressType: 9,
        CountryCode: 90,
        City: otherCityCode?.CityName,
        CityCode: data.AddressDetail?.CityCode,
        TownCode: otherTownCode ? Number(otherTownCode?.TownId) : Number(data.AddressDetail?.TownCode),
        District: data.AddressDetail?.District,
        Street: data.AddressDetail?.Street,
        AddressDetail: data.AddressDetail?.AddressDetail,
        PostalCode: Number(data.AddressDetail?.PostalCode),
      },
      IsFree: false,
      // ReceiptNumber: '',
      ShippingFee: 0,
      SubProductCode: '',
      h,
    };
  }
  return requestBodyData;
}

function* handleonAddApplicationRequestSaga() {
  try {
    yield put(resetFormError());

    const appData: ApplicationProcessState = yield select(applicationStore);
    const welcomeStore: WelcomeState = yield select(storeWelcome);
    const userData: UserState = yield select(userStore);
    const accountData: AccountState = yield select(storeAccount);
    const cardData: CardTransactionsState = yield select(storeCardTransactions);

    const {
      updateApplicationId,
      paymentInformationData,
      isShipping,
      productPriceResponse,
      applicationCard,
      activeApplicationData,
      h,
      activeCardShippingFee,
      activeUpStateShippingFee,
      onGetShippingLocationInfoList,
      cardApplicationStatus,
    } = appData;
    const { istanbulCardTypeList, HashData, CustodyCustomerNumber, SubProductCode } = cardData;
    const { Cellphone } = welcomeStore;
    const { customerNumber, sessionToken } = userData;
    const { cityList, otherTownList } = accountData;

    const price = cardApplicationStatus === 6 ? 0 : productPriceResponse?.data.ProductPrice;

    const normalizeData = {
      Value1: paymentInformationData?.creditCardNumber.replaceAll(' ', ''),
      Cvv: paymentInformationData?.cvv,
      ExpireDate: paymentInformationData?.expireDate,
      Name: paymentInformationData?.cardName,
    };
    let requestBody = createRequestBodyData(
      activeCardShippingFee!,
      activeUpStateShippingFee!,
      activeApplicationData!,
      otherTownList!,
      cityList!,
      h
    );

    const cleanSrc = activeApplicationData?.DocumentData?.includes('charset=utf-8;')
      ? activeApplicationData?.DocumentData?.replace('charset=utf-8;', '')
      : activeApplicationData?.DocumentData;

    const src = cleanSrc ? cleanSrc?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') : '';

    const relationData = {
      HashData,
      CustodyCustomerNumber,
      SubProductCode,
    };

    requestBody = {
      ...requestBody,
      CustomerNumber: customerNumber,
      SessionToken: sessionToken,
      ProductCode: applicationCard?.ProductCode,
      DocumentData: src,
      MimeType: activeApplicationData?.MimeType || '',
      ReceiptNumber: activeApplicationData?.ReceiptNumber,
      ...relationData,
    };

    if (appData?.campusId > 0) {
      requestBody.ShippingLocationDetailId = appData?.campusId;
      requestBody.ApplicationCenterId = 0;
    }

    if (!updateApplicationId) {
      const response: OnAddApplicationModel = yield call(() => onAddApplication(requestBody));
      const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(response);
      if (requestSuccess) {
        yield put(onAddApplicationSuccess(response.data.ApplicationId));
        yield put(
          vsPosBelbimPaymentRequest(
            VsPolBelbimNormalize(
              normalizeData,
              Cellphone,
              !isShipping
                ? Number(price?.toFixed(2))
                : Number(price?.toFixed(2)) +
                    Number(
                      requestBody?.AddressDetail?.CityCode === 34
                        ? activeCardShippingFee!.toFixed(2)
                        : activeUpStateShippingFee!.toFixed(2)
                    ),
              refTrnType.yeniKartBasvurusu,
              0, // commission amount
              undefined, // selected Card
              customerNumber,
              sessionToken,
              response.data.ApplicationId,
              0 // pmtaccountId
            )
          )
        );
      } else {
        yield put(setFormError({ errorCode: errorMessage, formName: formNames.paymentStep }));
        yield put(onAddApplicationFail());
      }
    } else {
      const req: any = VsPolBelbimNormalize(
        normalizeData,
        Cellphone,
        !isShipping
          ? Number(price?.toFixed(2))
          : Number(price?.toFixed(2)) +
              Number(
                requestBody?.AddressDetail?.CityCode === 34
                  ? activeCardShippingFee!.toFixed(2)
                  : activeUpStateShippingFee!.toFixed(2)
              ),
        cardApplicationStatus === 6 ? refTrnType.ikinciKargoBasvurusu : refTrnType.yeniKartBasvurusu,
        0, // commission amount
        undefined, // selected Card
        customerNumber,
        sessionToken,
        updateApplicationId,
        0 // pmtaccountId
      );
      const body: OnUpdateApplicationBodyData = {
        ...requestBody,
        ApplicationId: updateApplicationId,
        AppCenter: isShipping ? 0 : activeApplicationData?.ApplicationCenterId, //
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        ShippingFee: isShipping ? activeUpStateShippingFee! : 0,
        DocumentData: src!,
        MimeType: activeApplicationData?.MimeType,
        ShippingLocationDetailId:
          requestBody.ApplicationCenterId! > 0 || isShipping
            ? 0
            : appData?.campusId > 0
            ? appData?.campusId
            : activeApplicationData?.ShippingLocationDetailId,
      };
      yield put(onUpdateApplicationRequest({ body, req }));
    }
  } catch (error) {
    yield put(onAddApplicationFail());
  }
}

function* handleProductPrice({ payload }: PayloadAction<onOnlineApplicationQueryBodyData>) {
  try {
    const response: ProductPriceModel = yield call(() => productPrice(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(
        setApplicationData({
          ApplicationReasonId: response.data.ApplicationReasonId,
          ExpenseAmount: response.data.ExpenseAmount,
        })
      );

      yield put(getProductPriceSuccess(response));
    } else {
      yield put(getProductPriceFail());
    }
  } catch (error) {
    yield put(getProductPriceFail());
  }
}

function* handleOnUpdateApplication({ payload }: PayloadAction<OnUpdateApplicationBodyData | any>) {
  try {
    yield put(resetFormError());

    const routerState: RouterState = yield select((state: any) => state.router);
    const appData: ApplicationProcessState = yield select(applicationStore);

    let pathname = routerState?.location?.pathname;
    const { productPriceResponse } = appData;

    let requestBody = payload?.body || payload;
    const src = requestBody?.DocumentData
      ? requestBody.DocumentData?.replace(/^data:image\/(png|jpg|jpeg);charset=utf-8;base64,/, '')
      : undefined;

    requestBody = { ...requestBody, DocumentData: src, ExpenseAmount: productPriceResponse?.data.ExpenseAmount };
    if (appData?.campusId > 0) {
      requestBody.ShippingLocationDetailId = appData?.campusId;
      requestBody.ApplicationCenterId = 0;
    }
    const response: CardApplicationUpdateModel = yield call(() => onUpdateApplication(requestBody));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    const userData: CardTransactionsState = yield select(storeCardTransactions);
    const { activeCardApplicationDetail } = userData;

    if (requestSuccess) {
      if (pathname === routePath.cardApplicationsDetail) {
        yield put(
          replace(routePath.cardApplicationUpdateSuccess, {
            id: response.data.ApplicationId,
            pc: activeCardApplicationDetail?.ProductCode,
            campusId: activeCardApplicationDetail?.ShippingLocationDetailId,
          })
        );
      } else {
        yield put(vsPosBelbimPaymentRequest(payload.req));
      }

      yield put(onUpdateApplicationSuccess());
    } else {
      yield put(
        showToastify({
          toastMessage: response.data.ResponseDescription,
          type: 'error',
        })
      );
      yield put(onUpdateApplicationFail());
    }
  } catch (error) {
    yield put(onUpdateApplicationFail());
  }
}

function* handleOnGetShippingLocationInfoListRequest({
  payload,
}: PayloadAction<onGetShippingLocationInfoListBodyData>) {
  try {
    const response: OnGetShippingLocationInfoListModel = yield call(() => onGetShippingLocationInfoList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(onGetShippingLocationInfoListSuccess(response));
    } else {
      yield put(onGetShippingLocationInfoListFail());
    }
  } catch (error) {
    yield put(onGetShippingLocationInfoListFail());
  }
}

function* handleGetSubProductList({ payload }: PayloadAction<any>) {
  try {
    const response: SubProductResponseModel = yield call(() => OnGetSubProductList(payload));
    if (response?.data?.SubProductList) {
      yield put(onGetSubProductListSuccess(response?.data?.SubProductList));
    } else {
      yield put(onGetSubProductListSuccess([]));
    }
  } catch (error) {
    yield put(onGetSubProductListSuccess([]));
  }
}

function* handleResendCargoRequest({ payload }: PayloadAction<onUpdateCustomerAddressReqBodyData>) {
  try {
    const appData: ApplicationProcessState = yield select(applicationStore);
    const welcomeStore: WelcomeState = yield select(storeWelcome);
    const userData: UserState = yield select(userStore);
    const { updateApplicationId, paymentInformationData, isShipping, activeCardShippingFee } = appData;
    const { Cellphone } = welcomeStore;
    const { customerNumber, sessionToken } = userData;

    const normalizeData = {
      Value1: paymentInformationData?.creditCardNumber,
      Cvv: paymentInformationData?.cvv,
      ExpireDate: paymentInformationData?.expireDate,
      Name: paymentInformationData?.cardName,
    };

    const response: OnUpdateCustomerAdressModel = yield call(() => onUpdateCustomerAdress(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(
        vsPosBelbimPaymentRequest(
          VsPolBelbimNormalize(
            normalizeData,
            Cellphone,
            Number(activeCardShippingFee!.toFixed(2)),
            refTrnType.ikinciKargoBasvurusu,
            0, // commission amount
            undefined, // selected Card
            customerNumber,
            sessionToken,
            updateApplicationId,
            0 // pmtaccountId
          )
        )
      );
      yield put(resendCargoSuccess());
    } else {
      yield put(resendCargoFail());
    }
  } catch (error) {
    yield put(onGetShippingLocationInfoListFail());
  }
}

function* cardApplicationSagaWatcher() {
  yield takeLatest(onAddApplicationRequest, handleonAddApplicationRequestSaga);
  yield takeLatest(getProductPriceRequest, handleProductPrice);
  yield takeLatest(onUpdateApplicationRequest, handleOnUpdateApplication);
  yield takeLatest(onGetShippingLocationInfoListRequest, handleOnGetShippingLocationInfoListRequest);
  yield takeLatest(resendCargoRequest, handleResendCargoRequest);
  yield takeLatest(getSubProductList, handleGetSubProductList);
}
export default cardApplicationSagaWatcher;
