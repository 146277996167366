export type errorMessageKeys =
  | 'require'
  | 'mailFormat'
  | 'dateFormat'
  | 'minTc'
  | 'minBirthDate'
  | 'minPassword'
  | 'minPhone'
  | 'minHes'
  | 'passwordMatch'
  | 'checkboxTrue'
  | 'minSeriNo'
  | 'minCardNo'
  | 'minCreditCard'
  | 'minExpireDate'
  | 'invalidExpireDate'
  | 'minCvv'
  | 'minIban'
  | 'endDateNotBefore'
  | 'notAfterCurrent'
  | 'minAmountNotBiggerThanMax'
  | 'minPostCode'
  | 'checkMaxValue'
  | 'invalidDate'
  | 'invalidHour';

const errorMessages: { [key in errorMessageKeys]: string } = {
  require: 'require',
  mailFormat: 'mailFormat',
  dateFormat: 'dateFormat',
  minTc: 'minTc',
  minBirthDate: 'minBirthDate',
  minPassword: 'minPassword',
  minPhone: 'minPhone',
  minHes: 'minHes',
  passwordMatch: 'passwordMatch',
  checkboxTrue: 'checkboxTrue',
  minSeriNo: 'minSeriNo',
  minCardNo: 'minCardNo',
  minCreditCard: 'minCreditCard',
  minExpireDate: 'minExpireDate',
  invalidExpireDate: 'invalidExpireDate',
  minCvv: 'minCvv',
  minIban: 'minIban',
  endDateNotBefore: 'endDateNotBefore',
  notAfterCurrent: 'notAfterCurrent',
  minAmountNotBiggerThanMax: 'minAmountNotBiggerThanMax',
  minPostCode: 'minPostCode',
  checkMaxValue: 'checkMaxValue',
  invalidDate: 'invalidDate',
  invalidHour: 'invalidHour',
};

export default errorMessages;
