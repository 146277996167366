import { FC, Fragment, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { CreditCardList } from './CreditCardList';
import { NoCard } from './NoCreditCard';
import {
  CreditCardTransactionsState,
  creditCardTransactionsStore,
  onGetMasterpassTokenRequest,
} from 'Stores/CreditCardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { MasterPassAccountStatusEnum, MasterpassServicess, MasterPassTokenEnum } from 'Utils';
import { masterPassListCardNormalize, masterPassRegisterNormalize } from 'Normalize';

import './CreditCard.scss';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { MasterpassConnect } from 'Views/MasterpassConnect';
import { masterPassLinkToClientNormalize } from 'Normalize/MasterPassRequestNormalize';

const CreditCard: FC = () => {
  const { creditCardList, loading } = useSelector(creditCardTransactionsStore);

  const dispatch = useDispatch();
  const history = useHistory();

  const userData: UserState = useSelector(userStore);
  const cardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const accountStatus = cardData?.masterPassAccountStatus;

  useEffect(() => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CARD_LIST,
        request: {
          data: masterPassListCardNormalize(),
        },
      })
    );
  }, []);

  return (
    <DashboardLayout backButton>
      <Fragment>
        {accountStatus === MasterPassAccountStatusEnum.NO_LINKED ? (
          <MasterpassConnect />
        ) : (
          !loading && (
            <div id="credit-card">
              {creditCardList && creditCardList.length !== 0 ? (
                <CreditCardList data={creditCardList} />
              ) : (
                !loading && <NoCard />
              )}
            </div>
          )
        )}
      </Fragment>
    </DashboardLayout>
  );
};

export default CreditCard;
