import * as React from 'react';

function CloseOtherIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="closeIcon"
      {...props}
    >
      <defs>
        <path
          id="1ljbtyzsxa"
          d="M6.613 5.21l.094.083L12 10.585l5.293-5.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L13.415 12l5.292 5.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 13.415l-5.293 5.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L10.585 12 5.293 6.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-956 -237) translate(440 217) translate(516 20)">
              <path d="M0 0H24V24H0z" />
              <mask id="ou9t4ec3xb" fill="#fff">
                <use xlinkHref="#1ljbtyzsxa" />
              </mask>
              <g fill="#383C52" mask="url(#ou9t4ec3xb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CloseOtherIcon;
