import { routePath } from 'Navigator/routes';
import { useState } from 'react';
import { useEffect } from 'react';
import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAccessTrue } from 'Stores/App';
type AccessProps = {};
const Access: FC<AccessProps> = ({}) => {
  const dispatch = useDispatch();

  const [pass, setPass] = useState('');

  const history = useHistory();
  // 8rbq6wBP
  useEffect(() => {
    if (pass === '8rbq6wBP') {
      dispatch(setAccessTrue());
      history.push(routePath.welcome);
    }
  }, [pass]);

  return (
    <Fragment>
      <input type="password" value={pass} onChange={(e) => setPass(e.target.value)} style={{ margin: '20px' }} />
    </Fragment>
  );
};
export default Access;
