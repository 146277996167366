import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Stores/index';
import { DialogState } from './types';

export const initialState: DialogState = {
  isOtpOpen: false,
  isBlockedDialogOpen: false,
  is505ErrorDialogOpen: false,
  is651ErrorDialogOpen: false,
  is736ErrorDialogOpen: false,
  password: '',
  inputDialogOpen: false,
  fullScreen: false,
};

const dialog = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setFullScreen: (state: DialogState, action: PayloadAction<boolean>) => {
      state.fullScreen = action.payload;
    },
    showOTPDialog: (state: DialogState) => {
      state.isOtpOpen = true;
    },
    closeOTPDialog: (state: DialogState) => {
      state.isOtpOpen = false;
    },
    showBlockedDialog: (state: DialogState) => {
      state.isBlockedDialogOpen = true;
    },
    closeBlockedDialog: (state: DialogState) => {
      state.isBlockedDialogOpen = false;
    },
    show505ErrorDialog: (state: DialogState) => {
      state.is505ErrorDialogOpen = true;
    },
    close505ErrorDialog: (state: DialogState) => {
      state.is505ErrorDialogOpen = false;
    },
    show651ErrorDialog: (state: DialogState) => {
      state.is651ErrorDialogOpen = true;
    },
    close651ErrorDialog: (state: DialogState) => {
      state.is651ErrorDialogOpen = false;
    },
    openInputDialog: (state: DialogState) => {
      state.inputDialogOpen = true;
    },
    closeInputDialog: (state: DialogState) => {
      state.inputDialogOpen = false;
    },
    show736ErrorDialog: (state: DialogState) => {
      state.is736ErrorDialogOpen = true;
    },
    close736ErrorDialog: (state: DialogState) => {
      state.is736ErrorDialogOpen = false;
    },
    resetDialogStore: (state: DialogState) => initialState,
  },
});

export const storeDialog = (state: RootState) => state.dialog;

export const {
  closeOTPDialog,
  showOTPDialog,
  closeBlockedDialog,
  showBlockedDialog,
  show505ErrorDialog,
  close505ErrorDialog,
  openInputDialog,
  closeInputDialog,
  show651ErrorDialog,
  close651ErrorDialog,
  show736ErrorDialog,
  close736ErrorDialog,
  resetDialogStore,
  setFullScreen,
} = dialog.actions;

export default dialog.reducer;
