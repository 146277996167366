import { ChangeEvent } from 'react';
import convertToBase64 from './ConvertToBase64';

const getImageFromDevice = async (event: ChangeEvent<HTMLInputElement>) => {
  event.stopPropagation();
  event.preventDefault();
  // @ts-ignore
  if (event.target.files[0]) {
    // @ts-ignore
    var file = event.target.files[0];
    const result = await convertToBase64(file);
    return { type: file.type.replace('image/', ''), src: String(result) };
  }
  return { type: '', src: '' };
};

export default getImageFromDevice;
