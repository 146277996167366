import * as React from 'react';

function GrayInfoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="grayInfoIcon"
      {...props}
    >
      <defs>
        <path
          id="khhuti781a"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C17.196 3.054 14.652 2 12 2zm1 14c0 .552-.448 1-1 1s-1-.448-1-1v-5c0-.552.448-1 1-1s1 .448 1 1v5zm-1-7c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-957 -402) translate(795 388) translate(162 14)">
              <path d="M0 0H24V24H0z" />
              <mask id="7u0ryx9g4b" fill="#fff">
                <use xlinkHref="#khhuti781a" />
              </mask>
              <g fill="#AFB1BA" mask="url(#7u0ryx9g4b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GrayInfoIcon;
