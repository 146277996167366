import { Box, Typography } from '@material-ui/core';
import { FC, memo } from 'react';
import { fontSizes } from 'Theme/Variables';
import { cardBalanceNormalize } from 'Utils';
import moment from 'moment';
import { getCurrentMonth } from 'Views/CardDetails/CardTransactions/LastTransactionItem/constant';

import './OtherCardLastTransactionItem.scss';

type OtherCardLastTransactionItemProps = {
  transactionItem: {
    InsertDate: string;
    Amount: number;
    Description: string;
  };
};

const OtherCardLastTransactionItem: FC<OtherCardLastTransactionItemProps> = memo(({ transactionItem }) => {
  const { InsertDate, Amount, Description } = transactionItem;

  return (
    <div id="otherCardLastTransactionItem">
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="h1">
            <Box fontWeight="bold" fontSize={fontSizes.big} textAlign="center">
              {moment(InsertDate).format('D')}
            </Box>
          </Typography>
          <Typography variant="h1">
            <Box fontSize={fontSizes.smaller}>{getCurrentMonth(InsertDate)}</Box>
          </Typography>
        </Box>
        <Box className="otherCardLastTransactionItem__desc">
          <Typography variant="h6">
            <Box fontWeight="bold" fontSize={fontSizes.small}>
              {Description}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h6">
          <Box fontWeight="bold" fontSize={fontSizes.small}>
            {cardBalanceNormalize(Amount)} TL
          </Box>
        </Typography>
      </Box>
    </div>
  );
});

export default OtherCardLastTransactionItem;
