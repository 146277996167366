import { FC, useState, SetStateAction, Dispatch, useEffect } from 'react';
import { useCardName, useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { InformationRow } from '../InformationRow';
import { renderApplicationDate } from 'Utils';
import { DialogWithActions, PhotoStepPopup } from 'Components';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { ChangePhoto } from '../ChangePhoto';
import { useDispatch, useSelector } from 'react-redux';
import { onAddApplicationBodyData } from 'Config';
import { UserState, userStore } from 'Stores/User';
import {
  setUpdateApplicationId,
  onAddApplicationRequest,
  setActiveCard,
  setApplicationData,
  setDeliveryId,
  clearApplicationData,
} from 'Stores/CardApplication';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { getTownOtherListRequest } from 'Stores/Account';
import { useHistory } from 'react-router';

import './CardApplicationRejectImage.scss';

type CardApplicationRejectImageProps = {
  item: any;
  setIsChangePhoto: Dispatch<SetStateAction<boolean>>;
  isChangePhoto: boolean;
  address: string;
};
const CardApplicationRejectImage: FC<CardApplicationRejectImageProps> = ({
  item,
  setIsChangePhoto,
  isChangePhoto,
  address,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cardName = useCardName(item.ProductCode, item.SubProductCode, true);

  const userData: UserState = useSelector(userStore);

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const { customerNumber, sessionToken } = userData;
  const { activeCardApplicationDetail, istanbulCardTypeList } = cardData;

  const [fileName, setFileName] = useState<string>(`${item.FileName}`);
  const [isOpenRuleDialog, setIsOpenRuleDialog] = useState<boolean>(false);
  const [photoData, setPhotoData] = useState<string>(item.PhotoBytes);
  const [mimType, setMimType] = useState<string>(item.MimeType);

  const {
    web_card_applications_detail_number,
    web_card_applications_detail_date,
    web_card_applications_detail_type,
    web_delivery_location,
    web_photo_rules_popup_desc,
    web_photo_rules_popup_title,
    web_photo_rules_link_btn,
    web_application_info,
    web_necessary_documents,
    web_photo,
    web_change_photo,
    web_update_application,
    web_delivery_info,
    web_photo_rules_popup_btn,
    web_btn_okay,
  } = useLocalization();

  useEffect(() => {
    dispatch(
      getTownOtherListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CityId: String(activeCardApplicationDetail?.Address?.CityCode),
      })
    );
  }, []);

  const chooseType = (updateData: onAddApplicationBodyData) => {
    if (updateData.ApplicationCenterId == 0 && updateData.ShippingLocationDetailId == 0) return 0;
    if (updateData.ShippingFee == 0 && updateData.ShippingLocationDetailId == 0) return 1;
    if (updateData.ApplicationCenterId == 0 && updateData.ShippingFee == 0) return 2;
  };

  const updateApplication = () => {
    try {
      const activeApplicationCard = istanbulCardTypeList?.find(
        (item) => item.ProductCode === activeCardApplicationDetail?.ProductCode
      );

      const updateData: onAddApplicationBodyData = {
        AddressDetail: {
          AddressDetail: activeCardApplicationDetail?.Address.AddressDetail,
          AddressType: activeCardApplicationDetail?.Address.AddressType,
          City: activeCardApplicationDetail?.Address.City,
          CityCode: activeCardApplicationDetail?.Address.CityCode,
          CountryCode: activeCardApplicationDetail?.Address.CountryCode,
          District: activeCardApplicationDetail?.Address.District,
          PostalCode: activeCardApplicationDetail?.Address.PostalCode,
          Street: activeCardApplicationDetail?.Address.Street,
          TownCode: activeCardApplicationDetail?.Address.Town,
        },
        ApplicationCenterId: activeCardApplicationDetail?.ApplicationCenterId,
        ApplicationReasonId: activeCardApplicationDetail?.ApplicationReasonId,
        CustomerNumber: customerNumber,
        DocumentData: `data:image/${mimType};charset=utf-8;base64,${photoData}`,
        FileName: activeCardApplicationDetail?.FileName,
        IsFree: false,
        MimeType: activeCardApplicationDetail?.MimeType,
        OrganizationId: activeCardApplicationDetail?.OrganizationId,
        OrganizationTypeId: activeCardApplicationDetail?.OrganizationTypeId,
        ProductCode: activeCardApplicationDetail?.ProductCode,
        ReceiptNumber: activeCardApplicationDetail?.ReceiptNumber,
        ReceiptDate: activeCardApplicationDetail?.ReceiptDate,
        SessionToken: sessionToken,
        ShippingFee: activeCardApplicationDetail?.ShippingFee,
        SubProductCode: '',
        ShippingLocationDetailId: activeCardApplicationDetail?.ShippingLocationDetailId,
      };
      dispatch(clearApplicationData({ withoutCard: false }));
      dispatch(setActiveCard(activeApplicationCard!));
      dispatch(setApplicationData(updateData));
      dispatch(setDeliveryId(chooseType(updateData)!));
      dispatch(setUpdateApplicationId(activeCardApplicationDetail?.ApplicationId!));
      dispatch(onAddApplicationRequest());
    } catch (error) {
      console.log(error);
    }
  };

  return isChangePhoto ? (
    <ChangePhoto
      setIsChangePhoto={setIsChangePhoto}
      setFileName={setFileName}
      setPhotoData={setPhotoData}
      setMimType={setMimType}
    />
  ) : (
    <div id="cardApplicationRejectImage">
      <Typography variant="h3" align="left">
        <Box mb={gutters.regular} fontWeight={600}>
          {web_application_info}
        </Box>
      </Typography>
      <InformationRow topDivider name={web_card_applications_detail_number} desc={item?.ApplicationId?.toString()} />
      <InformationRow
        topDivider
        name={web_card_applications_detail_date}
        desc={renderApplicationDate(item.ApplicationDate)}
      />
      <InformationRow botDivider topDivider name={web_card_applications_detail_type} desc={cardName} />
      <Typography variant="h3" align="left">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {web_delivery_info}
        </Box>
      </Typography>
      <InformationRow
        botDivider
        topDivider
        name={web_delivery_location}
        desc={
          item.ShippingLocationName ? item.ShippingLocationName : address
          //: item && item.Address && item.Address.Text && item.Address.Text.toString().toLocaleLowerCase('tr-TR')
        }
      />
      <Typography variant="h3" align="left">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {web_necessary_documents}
        </Box>
      </Typography>
      <Box className="cardApplicationRejectImage__img-area">
        <Box>
          <Typography variant="h3">
            <Box fontSize="13px" color={colors.checkboxLabel}>
              {web_photo}
            </Box>
          </Typography>
          <Typography variant="h3">
            <Box
              mt="5px"
              fontSize={fontSizes.regular}
              fontWeight={600}
              color={fileName === item.FileName ? colors.errorText : colors.systemGreen}
            >{`${item.FileName}`}</Box>
          </Typography>
        </Box>
        <Typography variant="h3">
          <Box
            onClick={() => setIsChangePhoto(true)}
            fontWeight={500}
            className="cardApplicationRejectImage__change-btn"
            color={colors.darkPrimary}
            fontSize="13px"
          >
            {web_change_photo}
          </Box>
        </Typography>
      </Box>
      <Typography align="center" variant="h3">
        <Box
          onClick={() => setIsOpenRuleDialog(true)}
          mt={gutters.regular}
          fontWeight={500}
          className="cardApplicationRejectImage__change-btn"
          color={colors.darkPrimary}
          fontSize="13px"
        >
          {web_photo_rules_link_btn}
        </Box>
      </Typography>
      <Box mt={gutters.big} />

      <Button
        onClick={updateApplication}
        disabled={fileName === item.FileName}
        variant="contained"
        color="secondary"
        fullWidth
      >
        {web_update_application}
      </Button>
      <PhotoStepPopup
        isOpen={isOpenRuleDialog}
        buttonText={isOpenRuleDialog ? web_btn_okay : web_photo_rules_popup_btn}
        onClick={() => {
          setIsOpenRuleDialog(false);
        }}
      />
    </div>
  );
};
export default CardApplicationRejectImage;
