import { PayloadAction } from '@reduxjs/toolkit';
import { onLoginBodyData } from 'Config/ServiceConfig';
import { InstagramRaffleContestModel } from 'Models';
import { routePath } from 'Navigator/routes';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { instagramRaffleContest } from 'Services/Queries';
import { instagramRaffleContestRequest, instagramRaffleContestRequestSuccess } from './slices';
import { setFormError } from 'Stores/Form';
import { formNameKeys, formNames } from 'Config';

function* handleInstagramRaffleContestSaga({ payload }: PayloadAction<any>) {
  try {
    const response: InstagramRaffleContestModel = yield call(() => instagramRaffleContest(payload));
    console.log(response?.data);
    // if (onLoginResult) {
    // } else {
    // }
  } catch (error: any) {
    // yield put(setFormError({ errorCode: error }));
  }
}

// Watchers
function* instagramRaffleContestSagasWatcher() {
  yield takeLatest(instagramRaffleContestRequest, handleInstagramRaffleContestSaga);
}

export default instagramRaffleContestSagasWatcher;
