import * as React from 'react';

function BigGrayPeopleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="73"
      height="73"
      viewBox="0 0 73 73"
      {...props}
    >
      <defs>
        <path
          id="4go0qh63sa"
          d="M35.625 38.594c11.477 0 20.781 9.304 20.781 20.781 0 1.64-1.329 2.969-2.968 2.969H17.812c-1.64 0-2.968-1.33-2.968-2.969 0-11.477 9.304-20.781 20.781-20.781zm0-29.688c6.558 0 11.875 5.317 11.875 11.875 0 6.559-5.317 11.875-11.875 11.875S23.75 27.34 23.75 20.781c0-6.558 5.317-11.875 11.875-11.875z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-166 -187) translate(155 176) translate(11.875 11.875)">
              <path d="M0 0H71.25V71.25H0z" />
              <mask id="v4pfe9iteb" fill="#fff">
                <use xlinkHref="#4go0qh63sa" />
              </mask>
              <g fill="#C3C4CB" mask="url(#v4pfe9iteb)">
                <path d="M0 0H71.25V71.25H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BigGrayPeopleIcon;
