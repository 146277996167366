import { onRegisterCardTransactionBodyData } from 'Config';
import moment from 'moment';
import { getDateFromSelectedFilter } from 'Utils';
import { beforeType } from './GetCardTransactionListNormalize';

export const registerCardTransactionNormalize = (mifareId: string, customerNumber?: number, sessionToken?: string) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: mifareId,
    TransactionCount: 10,
  };
  return formValues;
};

export const registeredCardTransactionDetailNormalize = (values: onRegisterCardTransactionBodyData & beforeType) => {
  const formValues = {
    CustomerNumber: values.CustomerNumber,
    SessionToken: values.SessionToken,
    MifareId: values.MifareId,
    PageIndex: values.PageIndex,
    PageSize: values.PageSize ? values.PageSize : 10,
    MinTrnAmount: values.MinTrnAmount && Number(values.MinTrnAmount),
    MaxTrnAmount: values.MaxTrnAmount && Number(values.MaxTrnAmount),
    StartDate: values.StartDate
      ? moment(values.StartDate).format('YYYY-MM-DD')
      : getDateFromSelectedFilter(values.beforeValue ? values.beforeValue : 7),
    EndDate: values.EndDate ? moment(values.EndDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
    TransactionCount: 0,
  };
  return formValues;
};
