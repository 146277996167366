import { FC, memo, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { OtherCardDetailsHeader } from './OtherCardDetailsHeader';
import { OtherCardTransactions } from './OtherCardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { setNormalizeCardTransactionStates, storeCardTransactions } from 'Stores/CardTransactions';
import { useCardType } from 'Hooks';

import './OtherCardDetails.scss';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

const OtherCardDetails: FC = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const store = useSelector(storeCardTransactions);
  const cardItem: any = store.activeInProcessCard;
  const otherCards = store.otherCards;

  const { cardPath, cardColor } = useCardType(cardItem?.ProductCode);

  useEffect(() => {
    dispatch(setNormalizeCardTransactionStates());
  }, [otherCards]);

  useEffect(() => {
    if (!cardItem) {
      history.push(routePath.cards);
    }
  }, []);

  return (
    <DashboardLayout noContainer marginTop={0}>
      <div id="otherCardDetails">
        <OtherCardDetailsHeader cardItem={cardItem} cardColorClass={cardColor} cardPath={cardPath} />
        <OtherCardTransactions cardColorClass={cardColor} cardItem={cardItem} cardPath={cardPath} />
      </div>
    </DashboardLayout>
  );
});

export default OtherCardDetails;
