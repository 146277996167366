import { MasterPassRefType, MasterPassTokenEnum } from 'Utils';
import getEnvironment from 'Utils/GetEnvironment';
import IsUsingTmobCms from 'Utils/IsUsingTmobCms';

const baseUrlGw = 'https://apigateway.belbim.istanbul:8080/belbim/';
const baseUrlTestGw = 'https://tstapigw.belbim.istanbul:8080/belbim/';
const baseTmobLabsUrl = 'https://belbimpanel.tmoblabs.com';
const isDev = getEnvironment();
const isUsingTmob = IsUsingTmobCms();

const url = isDev ? baseUrlTestGw : baseUrlGw;

export const tmobLabsUrl = `${url}getRequest`;
export const belbimUrl = `${url}getRequest`;
export const vposUrl = `${url}vpos`;
export const faqUrl = `${url}getRequest`;
export const campaignUrl = `${url}getRequest`;
export const newsUrl = isUsingTmob && isDev ? `${baseTmobLabsUrl}/umbraco/api/News/GetNews` : `${url}getRequest`;
export const tagsUrl = `${url}getRequest`;
export const pinUrl = `${url}getRequest`;
export const cmsUrl =
  isUsingTmob && isDev ? `${baseTmobLabsUrl}/umbraco/api/Translations/Translations` : `${url}getRequest`;
export const fibaBankUrl = isDev
  ? 'https://odemetestmw.fibabanka.com.tr/pg/onlineShoppingLoan'
  : 'https://odememw.fibabanka.com.tr/pg/onlineShoppingLoan';
export const fibaBankgetApp = isDev
  ? 'https://odemetestmw.fibabanka.com.tr/pg/OnlineStoreCreditService/getApplicationStatus'
  : 'https://odememw.fibabanka.com.tr/pg/OnlineStoreCreditService/getApplicationStatus';

// TMOB API PARAMS & URLS

export type tmobLabsURLStrings = 'GetTranslations';
export const pathURls: { [key in tmobLabsURLStrings]: tmobLabsURLStrings } = {
  GetTranslations: 'GetTranslations',
};

export type ApiParamTypes = getCmsParams;

export type getCmsParams = {
  languageId: string;
  versionNumber?: string;
};

// BELBIM API BODY DATA

export type ApiGwParams = {
  token: string;
};

export type onCheckPhoneNumberBodyData = {
  CountryCode: string;
  Cellphone: string;
};

export type getApiGWTokenBodyData = {
  command: string;
  id: string;
  password: string;
};

export type getApiGWTokenRefreshBodyData = {
  command: string;
  rid: string;
};

export type onDeleteCustomerCardByClosedBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId?: string;
  CardCloseReason: number;
};

export type onCreateOnlineCustomerBodyData = {
  CitizenshipNumber: string;
  Name?: string;
  Surname?: string;
  BirthDate: string;
  Mail?: string | null;
  CountryCode?: string;
  Cellphone?: string;
  OnlinePermissionMessage?: {
    '2'?: number;
    '3'?: number;
    '4'?: number;
    '5'?: number;
    '6'?: number;
  };
  OnlineCustomerLanguage?: string;
  PropertyType: number;
  CommercialStatusChannelType?: number;
  Language?: string;
  h?: string;
};

export type onSetPasswordBodyData = {
  CountryCode?: string;
  Cellphone?: string;
  Password: string;
  ConfirmPassword: string;
  PasswordResetFlag: number;
  h?: string;
};

export type onLoginBodyData = {
  CountryCode?: string;
  CellPhone?: string;
  Password: string;
  DeviceId: string;
  OSType: number;
  IPAdress: string | null;
  Port: string;
};
export type onConfirmLoginSmsBodyData = {
  CountryCode?: string;
  CellPhone?: string;
  SmsToken: string;
  OSType: number;
  AppVersion: string;
  IPAdress: string | null;
  Port: string;
};

export type onSendTokenChangePasswordBodyData = {
  CountryCode?: string;
  CellPhone?: string;
  CitizenshipNumber: string;
  BirthDate: string;
};

export type onConfirmSmsBodyData = {
  CountryCode?: string;
  Cellphone?: string;
  SmsToken: string;
};
export type onInıtBodyData = {
  Language: string;
};

export type onFastLoginBodyData = {
  CountryCode?: string;
  CellPhone?: string;
  Password: string;
  OSType: number;
  AppVersion: string;
  IPAdress: string | null;
  Port: string;
};

export type insertOrUpdateHesCodeBodyData = {
  CustomerNumber?: number;
  Sessiontoken?: string;
  HesCode?: string;
};

export type checkHesCodeBodyData = {
  CustomerNumber: string;
  Sessiontoken: string;
};

export type onChangePasswordCustomerReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
};

export type onGetCardListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type getRegisteredCardTransactionBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type onSaveCustomerCardBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId?: string;
  AliasNumber?: string;
  NickName: string;
  TypeOfSave: number;
};

export type saveRegisterCardTransactionBodyData = {
  ClientKey: string;
  ClientPass: string;
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId?: string;
  AliasNumber?: string;
  NickName: string;
};

export type setDefaultCardBodyData = {
  SessionToken?: string;
  CustomerNumber?: number;
  MifareId?: string;
};
export type changeCardNickNameBodyData = {
  SessionToken?: string;
  CustomerNumber?: number;
  MifareId?: string;
  CardNickName?: string;
};

export type deleteRegisteredDefaultCardTransactionBodyData = {} & setDefaultCardBodyData;

export type onGetCardTransactionListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CardId?: number | string;
  TransactionCount?: number;
  PageIndex?: number;
  TrnType: number;
  MinTrnAmount?: number;
  MaxTrnAmount?: number;
  StartDate?: string;
  EndDate?: string;
  PageSize?: number;
};

export type onRegisterCardTransactionBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId: string;
  TransactionCount?: number;
  PageIndex?: number;
  MinTrnAmount?: number;
  MaxTrnAmount?: number;
  StartDate?: string;
  EndDate?: string;
  PageSize?: number;
};

export type InstrunctionListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId: string;
  TaskOrInstruction: number;
};

export type masterpassTokenReqBodyData = {
  CustomerNumber?: number;
  activeSelectedCreditCard?: any;
  SessionToken?: string;
  TokenType?: MasterPassTokenEnum;
  RefTrnType?: MasterPassRefType;
  Amount?: any;
  CommissionAmount?: any;
  PanMasked?: string;
  MifareId?: string;
  PmtAccountId?: number;
  DeviceType?: any;
  MacroMerchantId?: any;
};

export type masterpassPaymentForMobileReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  TokenType?: MasterPassTokenEnum;
  RefTrnType?: MasterPassRefType;
  Amount?: any;
  CommissionAmount?: any;
  PanMasked?: string;
  MifareId?: string;
  PmtAccountId?: number;
  DeviceType?: any;
  MacroMerchantId?: any;
  referenceNo?: string;
  PaymentNumber?: string;
  token?: string;
  returnUrl?: string;
};

export type onGetCommissionByBinNumberReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  BinNumber?: string;
  LoadAmount: number;
  CardAmount?: number;
  RefTrnType: string;
};

export type vsPosBeldimReqBodyData = {
  data: string;
};

export type masterPassCheckAccountReqBodyData = {
  sendSms: string;
  userId: string;
  token: string;
  referenceNo: string;
  sendSmsLanguage: 'tur' | 'eng';
};

export type masterPassRegisterReqBodyData = {
  token?: string;
  email?: string;
  cvc?: string;
  accountAliasName?: string;
  expiryDate?: string;
  rtaPan?: string;
  uiChannelType?: string;
  timeZone?: string;
  sendSmsLanguage?: string;
  sendSms?: string;
  referenceNo?: string;
  msisdn?: string;
  mobileAccountConfig?: string;
  identityVerificationFlag?: string;
  defaultAccount?: string;
  cardTypeFlag?: string;
  eActionType?: string;
  actionType?: string;
  clientId?: string;
  dateTime?: Date;
  version?: string;
  clientType?: string;
};
export type masterPassListCardReqBodyData = {
  msisdn: string;
  token: string;
};
export type masterPassOtpReqBodyData = {
  validationCode: string;
  referenceNo: string;
  sendSms: string;
  sendSmsLanguage: string;
  pinType: string;
};
export type masterPassDeleteCardReqBodyData = {
  accountAliasName: string;
  msisdn: string;
  token: string;
  referenceNo: string;
  sendSmsLanguage: string;
  sendSms: string;
};
export type onGetAgreementContentReqBodyData = {
  AgreementType: number;
  Language: string;
  isSignupProcess?: boolean;
};

export type onCountInstructionListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type onGetCustomerInfoReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type onLoginLogListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type getBinListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type checkVposReqBodyData = {
  PaymentNumber?: string;
  CustomerNumber?: number;
  SessionToken?: string;
};

export type getSeasonTicketPriceReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId: string;
  SeasonTicketType: string;
  primaryCard?: any;
};

export type onCheckProductReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId: string;
  AliasNumber: string;
  NickName?: string;
};

export type onChangeCustomerInfoReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CountryCode?: string;
  Cellphone?: string;
  Email?: string;
  Job?: string;
  Iban?: string;
  TypeOfField: number;
};

export type onUpdateCommercialStatusReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CommercialStatusChannelType: number;
  Language: string;
};
export type onChangePhoneValidationReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CountryCode?: string;
  CellPhone?: string;
  SmsToken: number;
};
export type getCityListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type getTownListReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CityId: string;
};

export type onUpdateCustomerAddressReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  AddressType?: string;
  AddressDetail?: string;
  CityCode?: number;
  City?: string;
  TownCode?: string;
  District?: string;
  PostalCode?: string;
  Street?: string;
  ProcessType?: number;
  custody?: any;
  stepData?: any;
};

export type onConfirmEmailReqBodyData = {
  EmailData: string | null;
};

export type onOnlineApplicationQueryBodyData = {
  CitizenshipNumber?: number;
  CustomerNumber?: number;
  SessionToken?: string;
  ProductCode: string;
  SubProductCode?: string;
  LoginUserTckn?: string;
  CustodyTckn?: string;
  HashData?: string;
  CustodyCustomerNumber?: string;
};

export type onGetCustodyRelationModelBodyData = {
  CustomerNumber?: number;
  LoginUserTckn?: string;
  CustodyTckn?: string;
  SessionToken?: string;
  BirthDate?: string;
};

export type onOnlineApplicationCardQueryBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type organizationTypeListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type organizationListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  OrganizationTypeId: number;
  CityCode: number;
};
export type onAddApplicationBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  ProductCode?: string;
  SubProductCode?: string;
  ApplicationReasonId?: number;
  OrganizationId?: number;
  OrganizationTypeId?: number;
  AddressDetail?: {
    AddressType?: any;
    CountryCode?: number;
    City?: string;
    CityCode?: number;
    TownCode?: number | string;
    Town?: string;
    District?: string;
    Street?: string;
    AddressDetail?: string;
    PostalCode?: string | number;
  };
  MimeType?: string;
  FileName?: string;
  ReceiptNumber?: string; //doldurulmayacak
  ReceiptDate?: string;
  DocumentData?: string; //fotoğrafın byte halini gönderiyoruz.
  ApplicationCenterId?: 0 | 9 | number; //adres seçildiyse 0 ibm seçildiyse varsa 9 tipindeki adres kaydı göndeirlmeli, yoksa adress parametreleri null gönderilecek.
  ShippingFee?: 9.3 | number; //değerlendirilecek, belbim
  IsFree?: boolean;
  h?: string;
  ExpenseAmount?: number;
  ShippingLocationDetailId?: number;
};

export type campaignsReqBodyData = {
  LanguageId: string;
  Token?: string;
  CardId: string;
};

export type newsReqBodyData = {
  LanguageId: string;
};

export type onChangeRegisterCardNickNameBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId?: string;
  NickName?: string;
};

export type getTownListOtherReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  CityId?: string;
};

export type getCardVisaReqBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId: string;
};

export type onCustomerApplicationListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  ProductCode: string;
  ApplicationId?: number;
};

export type OnGetApplicationCenterListBodyData = {
  CustomerNumber?: number;
};

export type GetPinsBodyData = {
  LanguageId?: number;
  TagId?: number;
  TownId?: number;
  SubCategoryId?: number;
  Latitude?: number;
  Longitude?: number;
  Distance?: number;
};

export type OnUpdateApplicationBodyData = {
  AddressDetail?: {
    AddressType?: any;
    CountryCode?: number;
    City?: string;
    CityCode?: number;
    TownCode?: number | string;
    District?: string;
    Street?: string;
    AddressDetail?: string;
    PostalCode?: string | number;
  };
  SendToPtt?: boolean;
  ReceiptDate?: string;
  ReceiptNumber?: string;
  SessionToken?: string;
  DocumentData?: string;
  FileName?: string;
  MimeType?: string;
  OrganizationTypeId?: number;
  OrganizationId?: number;
  ApplicationReasonId?: number;
  CustomerNumber?: number;
  ApplicationId: number;
  AppCenter?: number;
  ShippingFee: number;
  ShippingLocationDetailId?: number;
};

export type CommonUpdateCommercialStatus4MailBodyData = {
  CommercialData: string;
};
export type OnUpdateOnlinePermissionBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  OnlinePermissionMessage: { 1?: number; 2?: number; 3?: number; 4?: number; 5?: number; 6?: number; 7?: number };
  Language: string;
};

export type OnKillCustomerSessionAllBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type LoginWithOpenIdBodyData = {
  Code: string;
  RedirectUri: string;
  ClientIP: string;
};

export type CreateInstructionFromQRAccountBodyData = {
  ClientData?: {
    clientKey: string;
    clientPass: string;
    clientConnectionIndex: number; // statik olarak 0 gönderilecek
  };
  ChannelId?: number; // statik 4 gönderilecek
  CustomerNumber?: number;
  SessionToken?: string;
  MifareId?: string;
  Amount?: string;
  PmtAccountId?: number; // login servislerinden dönülen değer gönderilecek
  RandomValue?: string; // karakter sayısınca random değer setlenecek
  MsgHash?: string;
};

export type LoginWithTokenBodyData = {
  command: string;
  id: string;
};

export type onMicroCreditCheckStatusBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  OrderId?: string;
};

export type onMicroCreditBankProcessBodyData = {
  command: string;
  id: any;
  data: {
    CustomerNumber?: number;
    SessionToken?: string;
    IpAddress?: string | null;
    Port: string;
    ChannelType?: string;
  };
};

export type onlineShoppingLoanBodyData = {
  orderId: string;
  companyName: string;
  productType: string;
  amount: number;
  currency: number;
  successRedirectUrl: string;
  failRedirectUrl: string;
};

export type getApplicationStatusBodyData = {
  UID: string;
  CTID: string;
  orderId: string;
  companyName: string;
  FCID: string;
  TCID: string;
};

export type onGetShippingLocationInfoListBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
  Type: string;
  ShippingLocationId?: string;
};

export type resendCustomerCardBodyData = {
  CustomerNumber?: number;
  ProductCode?: string;
};

export type OnGetAccountWithCardsBodyData = {
  CustomerNumber?: number;
  SessionToken?: string;
};

export type ApiBodyDataTypes =
  | onCheckPhoneNumberBodyData
  | getApiGWTokenBodyData
  | getApiGWTokenRefreshBodyData
  | onCreateOnlineCustomerBodyData
  | onSetPasswordBodyData
  | onLoginBodyData
  | onConfirmLoginSmsBodyData
  | onSendTokenChangePasswordBodyData
  | onConfirmSmsBodyData
  | onInıtBodyData
  | onFastLoginBodyData
  | insertOrUpdateHesCodeBodyData
  | onGetCardListBodyData
  | onSaveCustomerCardBodyData
  | getRegisteredCardTransactionBodyData
  | saveRegisterCardTransactionBodyData
  | setDefaultCardBodyData
  | changeCardNickNameBodyData
  | onGetCardTransactionListBodyData
  | InstrunctionListReqBodyData
  | onRegisterCardTransactionBodyData
  | deleteRegisteredDefaultCardTransactionBodyData
  | masterpassTokenReqBodyData
  | onGetCommissionByBinNumberReqBodyData
  | vsPosBeldimReqBodyData
  | masterPassRegisterReqBodyData
  | masterPassListCardReqBodyData
  | masterPassOtpReqBodyData
  | masterPassDeleteCardReqBodyData
  | onGetAgreementContentReqBodyData
  | onCountInstructionListReqBodyData
  | onGetCustomerInfoReqBodyData
  | onLoginLogListReqBodyData
  | getBinListReqBodyData
  | checkVposReqBodyData
  | getSeasonTicketPriceReqBodyData
  | onCheckProductReqBodyData
  | onUpdateCommercialStatusReqBodyData
  | onChangeCustomerInfoReqBodyData
  | masterPassCheckAccountReqBodyData
  | onChangePhoneValidationReqBodyData
  | getCityListReqBodyData
  | getTownListReqBodyData
  | onUpdateCustomerAddressReqBodyData
  | onConfirmEmailReqBodyData
  | onOnlineApplicationQueryBodyData
  | onOnlineApplicationCardQueryBodyData
  | organizationTypeListBodyData
  | organizationListBodyData
  | onAddApplicationBodyData
  | campaignsReqBodyData
  | newsReqBodyData
  | onChangeRegisterCardNickNameBodyData
  | getTownListOtherReqBodyData
  | getCardVisaReqBodyData
  | onCustomerApplicationListBodyData
  | OnGetApplicationCenterListBodyData
  | GetPinsBodyData
  | OnUpdateApplicationBodyData
  | CommonUpdateCommercialStatus4MailBodyData
  | masterpassPaymentForMobileReqBodyData
  | OnUpdateOnlinePermissionBodyData
  | OnKillCustomerSessionAllBodyData
  | CreateInstructionFromQRAccountBodyData
  | LoginWithTokenBodyData
  | onMicroCreditCheckStatusBodyData
  | onMicroCreditBankProcessBodyData
  | onlineShoppingLoanBodyData
  | getApplicationStatusBodyData
  | onGetShippingLocationInfoListBodyData
  | resendCustomerCardBodyData
  | OnGetAccountWithCardsBodyData;
