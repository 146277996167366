import { axiosHelper } from 'Services/AxiosHelper';
import { onAddApplicationBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';
import { OrganizationListModel } from 'Models';

const command = 'RI.OnAddApplication';

const onAddApplication = async (params: onAddApplicationBodyData): Promise<OrganizationListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onAddApplication;
