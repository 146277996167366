const turkishCharacterNormalize = (string: string) => {
  /*  var maxLength = 100; */
  let returnString = string;

  if (isNaN(+string)) {
    returnString = string.toLocaleLowerCase('tr-Tr');

    // returnString = returnString?.replace(/ö/g, 'o');
    // returnString = returnString?.replace(/ç/g, 'c');
    // returnString = returnString?.replace(/ş/g, 's');
    // returnString = returnString?.replace(/ı/g, 'i');
    // returnString = returnString?.replace(/ğ/g, 'g');
    // returnString = returnString?.replace(/ü/g, 'u');
    // if there are other invalid chars, convert them into blank spaces
    returnString = returnString?.replace(/[^a-zA-Z\s-]/g, '');
    // convert multiple spaces and hyphens into one space
    returnString = returnString?.replace(/[\s-]+/g, ' ');
    // trims current string
    returnString = returnString?.replace(/^\s+|\s+$/g, '');
  }

  //Convert Characters

  return returnString;
};

export const turkishLowerCase = (string: string) => {
  let returnString = string;
  if (isNaN(+string)) {
    returnString = returnString
      ?.replace(/Ğ/g, 'g')
      .replace(/Ü/g, 'u')
      .replace(/Ş/g, 's')
      .replace(/I/g, 'i')
      .replace(/İ/g, 'i')
      .replace(/Ö/g, 'o')
      .replace(/Ç/g, 'c')
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c');
  }
  returnString = string.toLowerCase();
  return returnString.replace('i̇', 'i');
};
export default turkishCharacterNormalize;
