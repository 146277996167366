import { FC, useState, useRef, useEffect, memo } from 'react';
import { AddCardSchema, AddCardSchemaInitialValues, AddCardSchemaInputs } from 'Validations';
import { useErrorMessage, useLocalization } from 'Hooks';
import { FormWrapper, MaskedTextField, DialogWithActions } from 'Components';
import { Grid, Button, Box, TextField, Link, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { formikGlobalConfig, inputMaskTypes } from 'Config';
import { gutters, colors, fontSizes } from 'Theme/Variables';
import { Formik } from 'formik';
import { CardVisual } from './CardVisual';
import { useDispatch, useSelector } from 'react-redux';
import {
  decrementAddCardStep,
  incrementAddCardStep,
  onSaveCustomerCardRequest,
  mbSaveRegisterCardRequest,
  storeCardTransactions,
  setIsUserAddingNewCardInTransactions,
  onCheckProductRequest,
} from 'Stores/CardTransactions/slices';
import {
  saveUserCardNormalize,
  saveUserCardWithCardNoNormalize,
  saveUserCardWithSeriNoNormalize,
  mbSaveRegisterCardWithSeriNoNormalize,
  mbSaveRegisterCardWithCardNoNormalize,
  saveCardBelongsToSomeoneElseNormalize,
  addUserTemprorayCardNormalize,
} from 'Normalize';
import { userStore } from 'Stores/User/slices';
import { UserState } from 'Stores/User';

import './AddCard.scss';
import sanitizeHtml from 'Utils/SanitizeHtml';

type AddCardProps = {
  step: number;
  setPassive: () => void;
  isUserCard: boolean;
};

const AddCard: FC<AddCardProps> = memo(({ step, setPassive, isUserCard }) => {
  const dispatch = useDispatch();

  const formRef = useRef<any>();

  const userData: UserState = useSelector(userStore);
  const {
    isUserInUploadTLProcess,
    isUserInUploadSubscriptionProcess,
    isUserInAutomatiInstructionProcess,
  } = useSelector(storeCardTransactions);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { getError, getErrorMessage } = useErrorMessage();

  const resetFormState = () => {
    formRef.current.resetForm();
  };

  useEffect(() => {
    resetFormState();
  }, [step]);

  const {
    web_plc_serial_no,
    web_btn_next,
    web_plc_card_no,
    web_plc_card_name,
    web_title_doesnt_known_cardno_modal,
    web_btn_passive_add_doesnt_known_cardno_modal,
    web_btn_vazgec,
    web_check_manual_add_card_no,
    web_check_manual_add_serial_no,
    web_desc_manual_add_method,
    web_desc_doesnt_known_cardno_modal,
  } = useLocalization();

  const handleSubmit = (values: AddCardSchemaInputs) => {
    if (isUserInUploadTLProcess || isUserInUploadSubscriptionProcess || isUserInAutomatiInstructionProcess) {
      /*       dispatch(setIsUserAddingNewCardInTransactions(true));
       */ dispatch(onCheckProductRequest(addUserTemprorayCardNormalize(values, customerNumber, sessionToken)));
      return;
    }
    if (isUserCard) {
      if (step === 1) {
        dispatch(onSaveCustomerCardRequest(saveUserCardNormalize(values, customerNumber, sessionToken)));
      }
      if (step === 2) {
        dispatch(onSaveCustomerCardRequest(saveUserCardWithCardNoNormalize(values, customerNumber, sessionToken)));
      }
      if (step === 3) {
        dispatch(onSaveCustomerCardRequest(saveUserCardWithSeriNoNormalize(values, customerNumber, sessionToken)));
      }
    }
    if (!isUserCard) {
      if (step === 2) {
        dispatch(
          mbSaveRegisterCardRequest(mbSaveRegisterCardWithCardNoNormalize(values, customerNumber, sessionToken))
        );
      }
      if (step === 3) {
        dispatch(
          mbSaveRegisterCardRequest(mbSaveRegisterCardWithSeriNoNormalize(values, customerNumber, sessionToken))
        );
      }
    }
  };

  return (
    <div id="addCard">
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Formik
            initialValues={AddCardSchemaInitialValues}
            validationSchema={AddCardSchema[step - 1]}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
            innerRef={formRef}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                setFieldValue,
                values: { seriNo, cardNumber, cardName },
                errors: { seriNo: errorSeriNo, cardNumber: errorCardNumber, cardName: errorCardName },
                touched: { seriNo: touchedSeriNo, cardNumber: touchedCardNumber, cardName: touchedCardName },
              } = formikProps;

              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  {(step === 2 || step === 3) && (
                    <Box marginBottom={fontSizes.small} className="add-card__radio-button">
                      <Box color={colors.disabled} fontSize={fontSizes.small} fontWeight={500}>
                        {web_desc_manual_add_method}
                      </Box>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue={step === 2 ? 'first' : 'second'}
                      >
                        <FormControlLabel
                          value="first"
                          control={<Radio color="primary" />}
                          label={web_check_manual_add_card_no}
                          onClick={() => {
                            if (step === 3) {
                              dispatch(decrementAddCardStep());
                            }
                          }}
                        />
                        <FormControlLabel
                          value="second"
                          control={<Radio color="primary" />}
                          label={web_check_manual_add_serial_no}
                          className="add-card__radio-button--second"
                          onClick={() => {
                            if (step === 2) {
                              dispatch(incrementAddCardStep());
                              formRef.current.resetForm();
                            }
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  )}

                  {step !== 2 && (
                    <Box marginBottom={gutters.regular}>
                      <TextField
                        name="seriNo"
                        label={web_plc_serial_no}
                        value={seriNo.toUpperCase()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 14 }}
                        variant="filled"
                        fullWidth
                        error={getError(errorSeriNo, touchedSeriNo)}
                        helperText={getErrorMessage(errorSeriNo, touchedSeriNo)}
                        itemID="cardSeriNoInputTest"
                      />
                    </Box>
                  )}
                  {step !== 3 && (
                    <Box marginBottom={gutters.regular}>
                      <MaskedTextField
                        name="cardNumber"
                        mask={inputMaskTypes.userCardNo}
                        label={web_plc_card_no}
                        value={cardNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(errorCardNumber, touchedCardNumber)}
                        helperText={getErrorMessage(errorCardNumber, touchedCardNumber)}
                        itemID="cardCardNumberInputTest"
                      />
                    </Box>
                  )}
                  <Box marginBottom={gutters.big}>
                    <TextField
                      name="cardName"
                      label={web_plc_card_name}
                      variant="filled"
                      fullWidth
                      value={cardName}
                      onChange={(e) => setFieldValue('cardName', sanitizeHtml(e.target.value))}
                      onBlur={handleBlur}
                      error={getError(errorCardName, touchedCardName)}
                      helperText={getErrorMessage(errorCardName, touchedCardName)}
                      inputProps={{ maxLength: 20 }}
                      itemID="cardCardNameInputTest"
                    />
                  </Box>
                  <Button variant="contained" color="secondary" fullWidth type="submit" itemID="addCardBtnTest">
                    {web_btn_next}
                  </Button>
                </FormWrapper>
              );
            }}
          </Formik>
          {step === 1 && (
            <Link underline="none" onClick={() => setOpenDialog(true)}>
              <Box color={colors.primary} style={{ fontSize: '13px', fontWeight: 500 }} marginTop={gutters.regular}>
                {web_title_doesnt_known_cardno_modal}
              </Box>
            </Link>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <CardVisual />
        </Grid>
      </Grid>
      <DialogWithActions
        closeIcon
        title={web_title_doesnt_known_cardno_modal}
        text={web_desc_doesnt_known_cardno_modal}
        primaryButton={{
          label: web_btn_passive_add_doesnt_known_cardno_modal,
          handleClick: () => {
            dispatch(incrementAddCardStep());
            setPassive();
            setOpenDialog(false);
            formRef?.current?.resetForm();
          },
        }}
        secondaryButton={{
          label: web_btn_vazgec,
          handleClick: () => setOpenDialog(false),
        }}
        hide={() => setOpenDialog(false)}
        open={openDialog}
        alignTitle="left"
        textMarginBottom={gutters.big}
      />
    </div>
  );
});

export default AddCard;
