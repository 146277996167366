const IbanNormalize = (iban: string): string => {
  // Split the IBAN into groups of four characters
  let formattedIBAN = '';
  for (let i = 0; i < iban.length; i += 4) {
    formattedIBAN += iban.slice(i, i + 4) + ' ';
  }

  // Trim the trailing space
  return formattedIBAN.trim();
};

export default IbanNormalize;
