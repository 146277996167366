import { Box, Typography } from '@material-ui/core';
import { useCardType, useLocalization } from 'Hooks';
import { FC } from 'react';

import DeleteItem from '../DeleteItems/DeleteItems';
import RedCard from 'Assets/Images/cardTypes/red.png';

type DeleteOptionsProps = {
  items: any;
  productCode: string;
};

const DeleteOptions: FC<DeleteOptionsProps> = ({ items, productCode }) => {
  const { web_card_settings_delete_card_header } = useLocalization();
  const { cardColor, cardPath } = useCardType(productCode);

  return (
    <>
      <Typography variant="h3" align="center">
        <Box fontWeight={600}>{web_card_settings_delete_card_header}</Box>
      </Typography>
      <img className="card-delete__image" src={cardPath} alt="" />
      {items.map((e: any, i: number) => (
        <DeleteItem key={i} {...e} />
      ))}
    </>
  );
};
export default DeleteOptions;
