import { Box } from '@material-ui/core';
import './VirtualCardInformation.scss';
import { fontSizes, colors } from 'Theme/Variables';
import { useDigitalAccount, useLocalization } from 'Hooks';

const VirtualCardInformation = () => {
  const { web_information_virtual_card_title, web_information_virtual_card_index } = useLocalization();
  const digitalAccount = useDigitalAccount();
  const virtualCard = digitalAccount?.cardMasters?.find((card) => card.IsVirtualCard);

  return (
    <Box id="virtualCard-information" marginTop={'6px'}>
      <Box
        fontWeight={700}
        fontSize={fontSizes.regular}
        marginBottom={'8px'}
        color={colors.secondary}
        letterSpacing={'-0.4px'}
        lineHeight={'22px'}
      >
        {web_information_virtual_card_title}
      </Box>
      <Box
        fontWeight={500}
        fontSize={fontSizes.regular}
        color={colors.secondary}
        letterSpacing={'-0.4px'}
        lineHeight={'22px'}
      >
        {web_information_virtual_card_index}
      </Box>
    </Box>
  );
};

export default VirtualCardInformation;
