import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ChevronDarkRightIcon } from 'Components/Svg';

import './Items.scss';

export type ItemsType = {
  title: string;
  subTitle: string;
  Icon: any;
  isNext?: boolean;
  lastItem?: boolean;
  func?: () => void;
  firstItem?: boolean;
  itemID: string;
};

const Items: FC<ItemsType> = ({ title, subTitle, Icon, isNext, lastItem, func, firstItem, itemID }) => {
  return (
    <>
      <div className={firstItem ? 'itemtypes_cursor' : ''} onClick={func} id="itemtypes" itemID={itemID}>
        <div className="itemtypes__side">
          <Box className="itemtypes__icon" display="flex">
            <Icon fill="rgb(26,170,220)" />
          </Box>
          <div className="itemtypes__col">
            <Typography variant="h3" align="center">
              <Box fontWeight={600}>{title}</Box>
            </Typography>
            <Typography variant="subtitle2" align="center">
              <Box marginTop="3px">{subTitle}</Box>
            </Typography>
          </div>
        </div>
        <div className="itemtypes__side">
          {isNext && (
            <Box className="itemtypes__icon" display="flex">
              {ChevronDarkRightIcon}
            </Box>
          )}
        </div>
      </div>
      {!lastItem && <div className="itemtypes__divider"></div>}
    </>
  );
};

export default Items;
