import * as React from 'react';

function IbmSelectedPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="47"
      height="57"
      viewBox="0 0 47 57"
      {...props}
    >
      <defs>
        <path
          id="021mfm5fda"
          d="M13.5 24.75c6.213 0 11.25-5.037 11.25-11.25S19.713 2.25 13.5 2.25 2.25 7.287 2.25 13.5c0 2.984 1.185 5.845 3.295 7.955 2.11 2.11 4.971 3.295 7.955 3.295zM9.349 11.408l3.375-3.218c.049-.048.106-.086.168-.112.053-.048.114-.086.18-.113.273-.12.583-.12.856 0 .138.054.264.134.37.236l3.376 3.375c.44.441.44 1.157 0 1.598-.441.44-1.157.44-1.598 0l-1.451-1.463V18c0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125v-6.367l-1.474 1.406c-.45.428-1.163.41-1.592-.04-.428-.45-.41-1.163.04-1.591z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#1AAADC"
              fillRule="nonzero"
              stroke="#1AAADC"
              strokeWidth="2"
              d="M38.432 6.576c-8.813-8.768-23.045-8.768-31.858 0-8.765 8.768-8.765 23.052 0 31.867L22.503 54l15.882-15.604h0c8.812-8.768 8.812-23.052.047-31.82z"
              transform="translate(-309.000000, -404.000000) translate(310.000000, 405.000000)"
            />
            <g transform="translate(-309.000000, -404.000000) translate(310.000000, 405.000000) translate(22.500000, 21.500000) rotate(-360.000000) translate(-22.500000, -21.500000) translate(9.000000, 8.000000)">
              <path d="M0 0H27V27H0z" />
              <mask id="7ghhw9zp6b" fill="#fff">
                <use xlinkHref="#021mfm5fda" />
              </mask>
              <g fill="#FFF" mask="url(#7ghhw9zp6b)">
                <path d="M0 0H27V27H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IbmSelectedPin;
