import { reverseBirthDate } from 'Utils';
import { ForgotPasswordSchemaInputs } from 'Validations';

export const sendTokenChangePasswordNormalize = (
  tcNo: string,
  birthDate: string,
  phoneNumber?: string,
  countryCode?: string
) => {
  const formValues = {
    CountryCode: countryCode,
    Cellphone: phoneNumber,
    CitizenshipNumber: tcNo,
    BirthDate: reverseBirthDate(birthDate),
  };
  return formValues;
};
