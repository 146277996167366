import { FC, memo, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, colors, gutters } from 'Theme/Variables';
import { UserName } from './UserName';
import { UserCitizenshipNumber } from './UserCitizenshipNumber';
import { UserBirthDate } from './UserBirthDate';
import { UserJob } from './UserJob';
import { UserPhoneNumber } from './UserPhoneNumber';
import { UserMailAdress } from './UserMailAdress';
import { UserAdress } from './UserAdress';
import { UserIbanNumber } from './UserIbanNumber';
import { UserState, userStore } from 'Stores/User';
import {
  getCityListRequest,
  showHideIbanDialog,
  showHideUserJobDialog,
  showHideUserMailDialog,
  showHideUserMailInfoDialog,
  showHideUserPhoneDialog,
} from 'Stores/Account';
import { useDispatch, useSelector } from 'react-redux';

import './UserInfo.scss';

type UserInfoProps = {};

const UserInfo: FC<UserInfoProps> = memo(() => {
  const {
    web_my_informations,
    web_id_informations,
    web_communication_informations,
    web_iban_informations,
  } = useLocalization();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCityListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(showHideUserMailDialog(false));
    dispatch(showHideUserJobDialog(false));
    dispatch(showHideIbanDialog(false));
    dispatch(showHideUserPhoneDialog(false));
    dispatch(showHideUserMailInfoDialog(false));
  }, []);

  return (
    <DashboardLayout backButton>
      <div id="userInfo">
        <Typography variant="h3" align="center">
          <Box fontWeight={600} className="userInfo__title">
            {web_my_informations}
          </Box>
        </Typography>
        <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.gray} marginBottom={gutters.regular}>
          {web_id_informations}
        </Box>
        <Box>
          <UserName />
          <UserCitizenshipNumber />
          <UserBirthDate />
          <UserJob />
        </Box>
        <Box
          fontSize={fontSizes.regularBig}
          fontWeight="bold"
          color={colors.gray}
          marginBottom={gutters.regular}
          marginTop={gutters.big}
        >
          {web_communication_informations}
        </Box>
        <Box>
          <UserPhoneNumber />
          <UserMailAdress />
          <UserAdress />
        </Box>
        {/** Faz-2 */}
        {/*   <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.gray} marginBottom={gutters.regular} marginTop={gutters.big}>
          {web_iban_informations}
        </Box>

        <Box>
          <UserIbanNumber />
        </Box> */}
      </div>
    </DashboardLayout>
  );
});
export default UserInfo;
