import { FunctionComponent } from 'react';
import { Box, Button, Dialog, DialogProps, Typography } from '@material-ui/core';
import { CloseIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { fontSizes, gutters } from 'Theme/Variables';
import Linkify from 'react-linkify';

import './DialogTerms.scss';
import { AccountState, storeAccount } from 'Stores/Account';
import { useSelector } from 'react-redux';

type DialogTermsProps = {
  hide: () => void;
  title?: string;
  text?: string;
  isButtonOkay?: boolean;
  headerTextAlign?: string;
  handleClickButton?: Function;
  content?: string;
} & DialogProps;

const DialogTerms: FunctionComponent<DialogTermsProps> = ({
  open,
  hide,
  isButtonOkay,
  headerTextAlign,
  handleClickButton,
  title,
  content,
}) => {
  const { web_btn_accept, web_btn_okay } = useLocalization();

  const accountState: AccountState = useSelector(storeAccount);

  const isLoading: boolean = accountState?.loading;

  const handleClick = () => {
    if (handleClickButton) {
      handleClickButton();
    }
    hide();
  };

  return (
    <Dialog open={open} onClose={hide} id="dialogTerms" keepMounted={false} disablePortal={true}>
      <div className="dialogContainer">
        <CloseIcon onClick={() => hide()} />
        <Typography variant="h5" align="center">
          <Box fontWeight={600} marginBottom={gutters.regular} textAlign={headerTextAlign}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body1" className="dialogTerms-textContainer" component="div">
          <Box fontSize={fontSizes.regular} marginBottom={gutters.regular}>
            {!isLoading && (
              <Linkify
                componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
                  const isLink = decoratedText.includes('https');
                  return isLink ? (
                    <a
                      target="blank"
                      href={decoratedHref}
                      key={key}
                      style={{ maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all' }}
                    >
                      {decoratedText}
                    </a>
                  ) : (
                    decoratedText
                  );
                }}
              >
                {content}
              </Linkify>
            )}
          </Box>
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClick}
            type="submit"
            itemID="dialogOkeyButtonTest"
          >
            {isButtonOkay ? web_btn_okay : web_btn_accept}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

const DialogTermsWrapper: FunctionComponent<DialogTermsProps> = (props) => {
  if (props.open) {
    return <DialogTerms {...props} />;
  } else {
    return null;
  }
};

export default DialogTermsWrapper;
