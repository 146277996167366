import * as React from 'react';

function ProfileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="profileIcon"
      {...props}
    >
      <defs>
        <filter id="vixpstyiea">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <path
          id="qo7jws895b"
          d="M12 13c3.866 0 7 3.134 7 7 0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1 0-3.866 3.134-7 7-7zm0-10c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#vixpstyiea)" transform="translate(-20 -146) translate(19 62)">
            <g transform="translate(1 84)">
              <path d="M0 0H24V24H0z" />
              <mask id="72qynsvnsc" fill="#fff">
                <use xlinkHref="#qo7jws895b" />
              </mask>
              <g fill="#383C52" mask="url(#72qynsvnsc)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ProfileIcon;
