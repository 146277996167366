const getEnvironment = () => {
  const host = window?.location?.hostname;
  const tmobTestEnv = 'istanbulkart-tmob-test.web.app';
  const belbimTestEnv = 'istanbulkart-web-test.web.app';
  const belbimTestEnv2 = 'tstbireysel.istanbulkart.istanbul';
  const tmobTestMobilEnv = 'istanbulkart-tmob-test-mobile.web.app';
  const belbimTestMobilEnv = 'istanbulkart-web-test-mobile.web.app';
  const localTestEnv = 'localhost';
  const localIpEnv = '192.168.1.40';

  return (
    host === tmobTestEnv ||
    host === belbimTestEnv ||
    host === belbimTestEnv2 ||
    host === tmobTestMobilEnv ||
    host === belbimTestMobilEnv ||
    host === localTestEnv ||
    localIpEnv === host
  );
};

export default getEnvironment;
