// @ts-nocheck
import { axiosHelper } from 'Services/AxiosHelper';
import { GetApiGWTokenModel } from 'Models';
import { getEnvironment, gwUser, gwUserProd } from 'Utils';

const isDev = getEnvironment();

const credsProd = gwUserProd();
const creds = gwUser();

const getApiGWToken = async (): Promise<GetApiGWTokenModel> =>
  (
    await axiosHelper({
      method: 'post',
      data: {
        res: isDev ? creds : credsProd,
      },
    })
  ).data;

export default getApiGWToken;
