// @ts-nocheck
import { getCheckParams } from 'Config';
import { axiosHelper } from '../AxiosHelper';
import { InstagramRaffleContestModel } from './../../Models';
import { getApiGWTokenCookie } from 'Utils';

const command = 'instagram.RaffleContest';

const instagramRaffleContest = async ({
  id,
  InstagramEncryptedData,
}: getCheckParams): Promise<InstagramRaffleContestModel> => {
  return (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        id,
        token: getApiGWTokenCookie(),
      },
      data: {
        InstagramEncryptedData,
      },
    })
  ).data;
};

export default instagramRaffleContest;
