import axios from 'axios';
import { get } from 'lodash';

const getStreetList = async (param: string) => {
  const url = `https://api.ibb.gov.tr/ibb-adres-api/adres/mahalle/csbm/${param}`;

  const response = await axios.get(url);

  return get(response, 'data');
};

export default getStreetList;
