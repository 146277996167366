import { FC, memo } from 'react';
import { Grid, Box } from '@material-ui/core';
import { colors, fontSizes } from 'Theme/Variables';
import { useCardType, useLocalization } from 'Hooks';
import classNames from 'classnames';

import './VirtualCardItem.scss';
import { CardMastersData } from 'Models';
import { IbanNormalize } from 'Utils';

type VirtualCardItemProps = {
  virtualCard?: CardMastersData;
  isUserOtherCard?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const VirtualCardItem: FC<VirtualCardItemProps> = memo(({ virtualCard, isUserOtherCard, style, onClick }) => {
  const cardIsActive = virtualCard?.CardStatusCode === 'N' || virtualCard?.CardStatusCode === 'NN';
  const { web_no_vc_title } = useLocalization();
  const { cardPath, cardColor } = useCardType('IKP_SNL');
  return (
    <Grid item lg={4} sm={6} xs={12} onClick={onClick}>
      <div id="virtualCardItem" style={style}>
        <div
          className={classNames(`card-item__virtual`, {
            'card-item__container': true,
            'card-item__not--active': !cardIsActive && !isUserOtherCard,
          })}
        >
          <Box display="flex">
            <img src={cardPath} alt="" />
          </Box>
          <Box overflow="hidden" marginLeft={fontSizes.big} flex={1}>
            <Box
              fontSize={fontSizes.small}
              color={colors.secondary}
              style={{ marginBottom: '3px' }}
              className={`card-item__${cardColor}__text`}
            >
              {virtualCard?.CardNickname ?? web_no_vc_title}
            </Box>
            {!isUserOtherCard && virtualCard?.MaskedCardNumber?.toString() && (
              <Box
                fontSize={fontSizes.regularBig}
                color={colors.secondary}
                fontWeight="bold"
                className={`card-item__${cardColor}__text`}
              >
                {IbanNormalize(virtualCard?.MaskedCardNumber?.toString())}
              </Box>
            )}
          </Box>
        </div>
      </div>
    </Grid>
  );
});

export default VirtualCardItem;
