import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { colors, gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { TypesDialog } from './TypesDialog';
import { NamesDialog } from './NamesDialog';
import {
  organizationListRequest,
  organizationListSuccess,
  organizationTypeListRequest,
  UserState,
  userStore,
} from 'Stores/User';
import { formikGlobalConfig, onAddApplicationBodyData } from 'Config';
import { DialogWithActions, FormWrapper, SelectArrowDownIcon } from 'Components';
import { Formik } from 'formik';
import { UniversityStepSchema, UniversityStepSchemaValues } from 'Validations';
import classNames from 'classnames';

import './UniversityStep.scss';
import {
  ApplicationProcessState,
  applicationStore,
  closeApplicationEmtpyAdressError,
  incStep,
  onGetShippingLocationInfoListRequest,
  openApplicationEmtpyAdressError,
  openEmptyStreetError,
  setApplicationData,
  setCampusIdRequest,
} from 'Stores/CardApplication';
import { AccountState, storeAccount } from 'Stores/Account';
import { addressInvalid } from '../helper';

type UniversityStepProps = {
  hideNextButton?: boolean;
  fillExportedData: (e: any) => void;
  onEditAddress: (address: any, submitCallback: () => void) => void;
};

const UniversityStep: FC<UniversityStepProps> = ({ fillExportedData, onEditAddress, hideNextButton = false }) => {
  const {
    web_card_application_organisation_name,
    web_university_selection_university,
    web_university_selection_campus,
    web_btn_okay,
    web_card_incorrect_address_alert_title,
    web_card_incorrect_address_alert_desc,
  } = useLocalization();

  const dispatch = useDispatch();

  const accountStore: AccountState = useSelector(storeAccount);
  const userData: UserState = useSelector(userStore);
  const store: ApplicationProcessState = useSelector(applicationStore);
  const { townList } = accountStore;

  const { onGetShippingLocationInfoList, applicationEmtpyAdressError } = store;
  const onGetShippingLocationInfoListData = onGetShippingLocationInfoList?.data;

  const [selectedNameLabel, setSelectedNameLabel] = useState<string>(web_card_application_organisation_name);
  const [isOpenTypesDialog, setIsOpenTypesDialog] = useState<boolean>(false);
  const [isOpenOrganizationDialog, setIsOpenOrganizationDialog] = useState<boolean>(false);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const { getError, getErrorMessage } = useErrorMessage();

  const getOnGetShippingLocationInfoList = () => {
    dispatch(
      onGetShippingLocationInfoListRequest({
        SessionToken: sessionToken,
        CustomerNumber: customerNumber,
        Type: '1',
      })
    );
    setIsOpenTypesDialog(true);
  };

  const getOnGetShippingLocationInfoListDetail = (id: string) => {
    dispatch(
      onGetShippingLocationInfoListRequest({
        SessionToken: sessionToken,
        CustomerNumber: customerNumber,
        Type: '1',
        ShippingLocationId: id,
      })
    );
  };

  const handleSubmit = () => {
    const contactAdress = userData.adressList?.find((item) => item.AddressType === 9);
    const townCode = townList?.find(
      (item: any) => item.ilceAdi.toLocaleLowerCase('tr-TR') === contactAdress?.Town.toLocaleLowerCase('tr-TR')
    );

    if (addressInvalid(contactAdress)) {
      dispatch(openApplicationEmtpyAdressError());
      onEditAddress({ ...contactAdress }, handleSubmit);
    } else if (contactAdress && !contactAdress?.Street) {
      dispatch(openEmptyStreetError());
      return;
    } else {
      fillExportedData({
        cityCode: contactAdress?.CityCode,
        town: contactAdress?.Town,
        district: contactAdress?.District,
        street: contactAdress?.Street,
        adressDetail: contactAdress?.AddressDetail,
        adressType: 9,
        postCode: contactAdress?.PostalCode,
      });
      dispatch(incStep());
    }
  };

  return (
    <div id="organisationStep">
      <Formik
        initialValues={UniversityStepSchemaValues}
        enableReinitialize
        validationSchema={UniversityStepSchema}
        onSubmit={handleSubmit}
        {...formikGlobalConfig}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            values: { universityName, campusName },
            errors: { universityName: errorUniversityName, campusName: errorCampusName },
            touched: { universityName: touchedUniversityName, campusName: touchedCampusName },
          } = formikProps;

          return (
            <FormWrapper onSubmit={formikProps.handleSubmit}>
              <TypesDialog
                selectedValue={universityName}
                bodyData={onGetShippingLocationInfoListData?.ShippingLocationList}
                open={isOpenTypesDialog}
                close={setIsOpenTypesDialog}
                onChange={(value: number, label: string) => {
                  setIsOpenTypesDialog(false);
                  formikProps.setFieldValue('universityName', label, true);
                  formikProps.setFieldValue('campusName', '', true);
                  getOnGetShippingLocationInfoListDetail(value.toString());
                }}
              />
              <Box onClick={() => getOnGetShippingLocationInfoList()}>
                <TextField
                  variant="filled"
                  label={web_university_selection_university}
                  fullWidth
                  onBlur={handleBlur}
                  InputProps={{
                    fullWidth: true,
                    readOnly: true,
                    endAdornment: <SelectArrowDownIcon />,
                  }}
                  error={getError(errorUniversityName, touchedUniversityName)}
                  helperText={getErrorMessage(errorUniversityName, touchedUniversityName)}
                  onChange={handleChange}
                  name="organizationLabel"
                  id="organizationLabel"
                  value={universityName}
                  itemID="cardApplyOrganizationLabelInputTest"
                />
              </Box>
              <Box mt={gutters.small} />
              <NamesDialog
                selectedValue={campusName}
                bodyData={onGetShippingLocationInfoListData?.ShippingLocationDetailList}
                onChange={(value: number, label: string) => {
                  setIsOpenOrganizationDialog(false);
                  formikProps.setFieldValue('campusName', label, true);
                  dispatch(setCampusIdRequest(value));
                }}
                open={isOpenOrganizationDialog}
                close={setIsOpenOrganizationDialog}
              />
              <Box
                className={classNames({
                  'organisationStep--disabled': !!!campusName,
                })}
                onClick={() => (!!universityName ? setIsOpenOrganizationDialog(true) : undefined)}
              >
                <TextField
                  variant="filled"
                  label={web_university_selection_campus}
                  fullWidth
                  onBlur={handleBlur}
                  InputProps={{
                    fullWidth: true,
                    readOnly: true,
                    endAdornment: <SelectArrowDownIcon />,
                    style: {
                      backgroundColor: !!!campusName ? colors.lightGray : undefined,
                    },
                  }}
                  error={getError(errorCampusName, touchedCampusName)}
                  helperText={getErrorMessage(errorCampusName, touchedCampusName)}
                  onChange={handleChange}
                  name="organizationLabel"
                  id="organizationLabel"
                  value={campusName}
                  itemID="cardApplyOrganizationLabelInputTest"
                />
              </Box>

              <Box mt={gutters.small} />
              {!hideNextButton ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  itemID="nextCardApplyOrganizationStepBtnTest"
                >
                  Devam Et
                </Button>
              ) : null}

              <DialogWithActions
                alignTitle="left"
                primaryButton={{
                  label: web_btn_okay,
                  handleClick: () => dispatch(closeApplicationEmtpyAdressError()),
                }}
                open={applicationEmtpyAdressError}
                hide={() => dispatch(closeApplicationEmtpyAdressError())}
                title={web_card_incorrect_address_alert_title}
                text={web_card_incorrect_address_alert_desc}
              />
            </FormWrapper>
          );
        }}
      </Formik>
    </div>
  );
};
export default UniversityStep;
