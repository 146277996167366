import { FC, Fragment, memo } from 'react';
import { CloseIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography, Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { close736ErrorDialog, DialogState, storeDialog } from 'Stores/Dialog';
import { fontSizes, gutters } from 'Theme/Variables';

type CantUploadSubsModalProps = {};

const CantUploadSubsModal: FC<CantUploadSubsModalProps> = memo(() => {
  const { web_subscription_can_not_upload_title, web_subscription_can_not_upload_desc } = useLocalization();

  const dialogStore: DialogState = useSelector(storeDialog);

  const dispatch = useDispatch();

  return (
    <Dialog
      open={dialogStore?.is736ErrorDialogOpen}
      onClose={() => dispatch(close736ErrorDialog())}
      keepMounted={false}
      disablePortal={true}
    >
      <Box className="dialogContainer">
        <CloseIcon onClick={() => dispatch(close736ErrorDialog())} />
        <Box>
          <Typography variant="h6">
            <Box textAlign="center" fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.big}>
              {web_subscription_can_not_upload_title}
            </Box>
          </Typography>
          <Typography variant="subtitle2">
            <Box fontSize={fontSizes.regular} textAlign="center">
              {web_subscription_can_not_upload_desc}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
});
export default CantUploadSubsModal;
