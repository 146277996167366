export const addCustomerAdressNormalize = (
  values: any,
  customerNumber?: number,
  sessionToken?: string,
  cityCode?: number,
  town?: string,
  townCode?: string,
  addressType?: string,
  processType?: number,
  custody?: any,
  data?: any
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    AddressType: addressType ?? values.adressType,
    AddressDetail: values.adressDetail,
    CityCode: cityCode,
    City: values.city,
    TownCode: townCode,
    Town: town,
    District: values.district,
    PostalCode: values.postCode,
    Street: values.street,
    ProcessType: processType,
    custody: custody,
    stepData: data,
  };
  return formValues;
};

export const updateCustomerAdressNormalize = (
  values: any,
  customerNumber?: number,
  sessionToken?: string,
  cityCode?: number,
  townCode?: string,
  addressType?: string,
  processType?: number,
  custody?: any,
  data?: any
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    AddressType: addressType,
    AddressDetail: values.adressDetail,
    CityCode: cityCode,
    City: values.city,
    TownCode: townCode,
    Town: values.town,
    District: values.district,
    PostalCode: values.postCode,
    Street: values.street,
    ProcessType: processType,
    custody: custody,
    stepData: data,
  };
  return formValues;
};

export const deleteCustomerAdressNormalize = (
  customerNumber?: number,
  sessionToken?: string,
  addressType?: string,
  processType?: number
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    AddressType: addressType,
    ProcessType: processType,
  };
  return formValues;
};
