import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { routes, routePath } from './routes';
import { useSelector } from 'react-redux';
import { storeIsInit } from 'Stores/App';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'Stores';
import GlobalComponents from './GlobalComponents';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { RouteChangeTracker } from 'Components';

const Navigator = () => {
  const appIsInit = useSelector(storeIsInit);

  return (
    <>
      <ConnectedRouter history={history}>
        {appIsInit && (
          <Switch>
            {routes.map(({ component, exact, path, isPrivate }, index) =>
              isPrivate ? (
                <PrivateRoute key={index} path={path} component={component} exact={exact || false} />
              ) : (
                <PublicRoute key={index} path={path} component={component} exact={exact || false} />
              )
            )}
            <Route render={() => <Redirect to={routePath.welcome} />} />
          </Switch>
        )}

        {appIsInit && <GlobalComponents />}
        <RouteChangeTracker />
      </ConnectedRouter>
    </>
  );
};

export default Navigator;
