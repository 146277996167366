import { TaksListItem } from 'Models/InstrunctionListReq';

export type cardItemDetailType = {
  ActivationStatus: number;
  AliasNumber: string;
  CardBalance: number;
  CardId: number;
  CardStatusCode: string;
  IsPrimary: boolean;
  MifareId: string;
  NickName: string | undefined;
  ProductCode: string;
  ProductCodeDesc: string;
  VisaExpireDate: string;
  SeasonTicketLimit: number | null;
  SeasonTicketExpireDate: string | null;
  SeasonTicketDay: number | null;
  SeasonTicketLoadable: boolean;
  cardOwnerType: 'user';
  isOtherCard?: boolean;
  CardCvv?: number;
  CardExpiryDate?: string;
};

/**
 * 
1   Müşteri Hizmetleri Abonman Yükleme
2   Kurumsal Abonman Yükleme
3   Vizeleme-Ücretli
11  Bireysel Abonman Yükleme
12  Mobil Abonman Yükleme
13  Adk Abonman Yükleme
 
 */

export const findAbonmanTypes = (cards: TaksListItem[]) => {
  const filteredCards = cards?.filter(
    (item) =>
      item.TaskTypeId === 11 ||
      item.TaskTypeId === 12 ||
      item.TaskTypeId === 13 ||
      item.TaskTypeId === 1 ||
      item.TaskTypeId === 2
  );
  return filteredCards;
};
