import BaseDataModel from './BaseDataModel';
import BaseModel from './BaseModel';

export enum CardProductCode {
  SAGLIK_CALISANI = 'UCT_SGL',
  ECZACI = 'IKT_SGL',
  ADA_SAKINI_MAVI_KART = 'IKT_ADA',
  TURK_ALMAN_UNIVERSITESI_PERSONEL = 'PER_TAU',
  ISTANBULKART = 'IKT_IKT',
  SPOR_ISTANBUL_UYE_KARTI = 'IKT_SPR',
  ANNE_KART = 'UCT_ANA',
  BASIN_MENSUBU = 'UCT_BSN',
  MAVI_KART = 'IKT_MVI',
  OGRENCI = 'IKT_ORC',
  OGRETMEN = 'IKT_ORT',
  '65_YAS_USTU' = 'UCT_ABY',
  '60_YAS_USTU' = 'IKT_YSL',
}

export type OnOnlineApplicationCardQueryItemModel = {
  CardPrintType: number;
  ProductCode: string;
  ProductName: string;
  QueryService: any;
  RequiredFields: any[];
  ShippingType: any;
  SubProductCode: any;
  CardApplicationStatus: number;
  PttPostCode: string;
};

export type OnOnlineApplicationCardQueryModel = {
  data: {
    MessageHash: any;
    ProductList: Array<OnOnlineApplicationCardQueryItemModel>;
    ShippingFee: number;
    UpstateShippingFee: number;
  } & BaseDataModel;
} & BaseModel;
