import { FunctionComponent, useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useErrorMessage, useLocalization, useResize } from 'Hooks';
import useOnScreen from 'Hooks/useOnScreen';
import { SignupSchemaInputs } from 'Validations';
import { FormikProps } from 'formik';
import { ArrowDownIcon, Checkbox, FormErrorWrapper } from 'Components';
import { scrollTop } from 'Utils';
import { formNames } from 'Config';
import { useSelector } from 'react-redux';
import { storeAccount } from 'Stores/Account';

import './SingupConditionsStep.scss';

type SingupConditionsStepProps = {
  formikProps: FormikProps<SignupSchemaInputs>;
};

const SingupConditionsStep: FunctionComponent<SingupConditionsStepProps> = ({
  formikProps: {
    values: { conditionsAccepted },
    errors: { conditionsAccepted: errorConditionsAccepted },
    touched: { conditionsAccepted: touchedConditionsAccepted },
    handleChange,
    handleBlur,
    setTouched,
  },
}) => {
  const { web_title_sozlesmeler, web_check_sozlesmeler, web_btn_next, web_btn_scroll } = useLocalization();
  const { getErrorMessage } = useErrorMessage();

  const containerRef = useRef<any>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useResize(containerRef);
  const buttonContainerIsVisible = useOnScreen(buttonContainerRef);

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  useEffect(() => {
    scrollTop();
    setTouched({});
    // eslint-disable-next-line
  }, []);

  return (
    <div id="signupConditions">
      <div className="signupConditions-container" ref={containerRef}>
        <Typography variant="h5" align="center">
          <Box className="signupConditions-title">{web_title_sozlesmeler}</Box>
        </Typography>
        <Box marginBottom={gutters.big}>
          <Typography variant="subtitle2">{agreementContent?.AgreementContent}</Typography>
        </Box>
        <div className="signupConditions-button-container" ref={buttonContainerRef}>
          <FormErrorWrapper formName={formNames.signup} resetFormOnRouteChange>
            <Box className="checkbox-error">
              <Checkbox
                name="conditionsAccepted"
                color="primary"
                checked={conditionsAccepted}
                onChange={handleChange}
                onBlur={handleBlur}
                label={web_check_sozlesmeler}
                errorText={getErrorMessage(errorConditionsAccepted, touchedConditionsAccepted)}
                itemID="signUpConditionsAcceptedInputTest"
              />
            </Box>
            <Button variant="contained" color="secondary" fullWidth type="submit" itemID="signUpNextBtnTest">
              {web_btn_next}
            </Button>
          </FormErrorWrapper>
        </div>
        {!buttonContainerIsVisible && (
          <div
            className="signupConditions-dialog"
            style={{
              width: width || 0,
            }}
            onClick={() => buttonContainerRef.current?.scrollIntoView()}
          >
            <Typography variant="body2" component="div" className="signupConditions-dialog__inner flexAlignCenter">
              <Box>{web_btn_scroll}</Box>
              <ArrowDownIcon />
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingupConditionsStep;
