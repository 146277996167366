import { axiosHelper } from 'Services/AxiosHelper';
import { GetBinListModel } from 'Models';
import { getBinListReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'b2c.RIGetBinList';

const getBinList = async (params: getBinListReqBodyData): Promise<GetBinListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default getBinList;
