import * as yup from 'yup';
import { errorMessages } from 'Config';

export const UniversityStepSchema = yup.object({
  universityName: yup.string().required(errorMessages.require),
  campusName: yup.string().required(errorMessages.require),
});

export type UniversityStepSchemaInput = yup.InferType<typeof UniversityStepSchema>;

export const UniversityStepSchemaValues: UniversityStepSchemaValues = {
  universityName: '',
  campusName: '',
};
type UniversityStepSchemaValues = {
  universityName: string;
  campusName: string;
};
