import { FunctionComponent } from 'react';
import { Box, Button, Dialog, DialogProps, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { DialogCloseComponent } from 'Components/DialogCloseComponent';
import { MasterpassLogo } from 'Components/Svg';

import './DialogWithActions.scss';

type DialogWithActionsProps = {
  hide: () => void;
  title?: string;
  text?: string;
  alignTitle?: string;
  textMarginBottom?: string;
  primaryButton?: {
    label: string;
    handleClick: Function;
  };
  secondaryButton?: {
    label: string;
    handleClick: Function;
    type?: 'outlined' | 'contained';
  };
  closeIcon?: boolean;
  alignText?: string;
  masterpassLogo?: boolean;
  disableClickOutside?: boolean;
  imageResource?: string;
  splitText?: string | null;
} & DialogProps;

const DialogWithActions: FunctionComponent<DialogWithActionsProps> = ({
  open,
  hide,
  title,
  text,
  primaryButton,
  secondaryButton,
  alignTitle,
  textMarginBottom,
  closeIcon,
  alignText,
  masterpassLogo,
  disableClickOutside,
  imageResource,
  splitText = null,
}) => {
  const { label, handleClick } = primaryButton || {
    label: '',
    handleClick: () => {},
  };

  const { label: secondaryButtonLabel, handleClick: secondaryHandleClick } = secondaryButton || {
    label: '',
    handleClick: () => {},
  };

  const renderContentWithImage = (content: any) => {
    if (imageResource) {
      return (
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-lg-6">
            {content()}
          </div>
          <div className="imageContainer MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-lg-6">
            <img className="showcase__img" src={imageResource} alt="" />
          </div>
        </div>
      );
    } else {
      return content();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={hide}
      id="dialogWithActions"
      keepMounted={false}
      disablePortal={true}
      disableBackdropClick={disableClickOutside}
    >
      {closeIcon && <DialogCloseComponent closeFunction={hide} />}
      <div className="dialogContainer">
        {title && (
          <Box
            textAlign={alignTitle ? alignTitle : 'center'}
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              <Box fontWeight={600} marginBottom={gutters.regular}>
                {title}
              </Box>
            </Typography>
            {masterpassLogo && (
              <Box>
                <MasterpassLogo />
              </Box>
            )}
          </Box>
        )}
        {renderContentWithImage(() => {
          return (
            text && (
              <Box marginBottom={textMarginBottom}>
                <Typography variant="body1" className="dialogWithActions-textContainer" component="div">
                  <Box
                    fontSize={fontSizes.regular}
                    marginBottom={gutters.regular}
                    textAlign={alignText ? alignText : 'left'}
                  >
                    {!!splitText ? text?.split(splitText)?.map((item) => <p>• {item}</p>) : text}
                  </Box>
                </Typography>
              </Box>
            )
          );
        })}
        {!!label && (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClick()}
            type="submit"
            itemID="confirmBtnTest"
          >
            {label}
          </Button>
        )}
        {!!secondaryButtonLabel && (
          <Box marginTop={gutters.small}>
            <Button
              variant={secondaryButton?.type ? secondaryButton.type : 'outlined'}
              color="secondary"
              fullWidth
              onClick={() => secondaryHandleClick()}
              type="submit"
              itemID="rejectBtnTest"
            >
              {secondaryButtonLabel}
            </Button>
          </Box>
        )}
      </div>
    </Dialog>
  );
};

const DialogTermsWrapper: FunctionComponent<DialogWithActionsProps> = (props) => {
  if (props.open) {
    return <DialogWithActions {...props} />;
  } else {
    return null;
  }
};

export default DialogTermsWrapper;
