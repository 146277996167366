import { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components/Svg';

import { CardTransactionListTypeEnum, turkishCharacterNormalize } from 'Utils';
import { OrganizationListItemModel, OrganizationTypeListItemModel } from 'Models';
import { useLocalization } from 'Hooks';
import classNames from 'classnames';
import './NamesDialog.scss';
import { shippingLocationDetailList } from 'Models/OnGetShippingLocationInfoListModel';

type NamesDialogProps = {
  dialogTitle?: string;
  bodyData?: shippingLocationDetailList[];
  selectedValue?: any;
  onChange?: any;
  open: boolean;
  close: any;
};

const NamesDialog: FC<NamesDialogProps> = ({ dialogTitle, bodyData, selectedValue, onChange, close, open }) => {
  const { web_account_search } = useLocalization();

  const handleChanges = (type: CardTransactionListTypeEnum, label: string) => {
    onChange(type, label);
  };
  const [searchKey, setSearchKey] = useState<string>('');

  useEffect(() => {
    setSearchKey('');
  }, [close]);

  const onClose = () => {
    setSearchKey('');
    close(false);
  };

  return (
    <Dialog id="dialogCity" onClose={onClose} scroll="paper" keepMounted={false} open={open}>
      <DialogTitle className="dialogCity-search">
        <CloseIcon onClick={() => close(false)} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCity-container">
          <OrganizationBlock
            types={bodyData}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={searchKey.toLocaleLowerCase()}
            itemClickHandle={handleChanges}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
const OrganizationBlock: FC<{
  types: shippingLocationDetailList[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ types, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogCity-container__list">
      {!!types &&
        types.length > 0 &&
        types
          .filter((item: shippingLocationDetailList) => {
            return item?.Name?.toLocaleLowerCase('tr-TR')?.includes(lowerCaseSearchKey);
          })
          .map((item: shippingLocationDetailList, index) => (
            <Item
              key={item.Id.toString()}
              title={item.Name}
              isSelected={item.Name === selectedValue}
              onClick={() => itemClickHandle(item.Id, item.Name)}
            />
          ))}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseCardApplyOrganizationLabelNameBtnTest">
      <div
        className={classNames({
          'dialogCity-container__list-item': true,
          'dialogCity-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default NamesDialog;
