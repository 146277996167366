import { FC } from 'react';
import { CloseIcon } from 'Components';

type DialogCloseProps = {
  closeFunction: any;
};

const DialogCloseComponent: FC<DialogCloseProps> = ({ closeFunction }) => {
  return <CloseIcon onClick={closeFunction} />;
};

export default DialogCloseComponent;
