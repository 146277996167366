import { FC, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { animateScroll as scroll } from 'react-scroll';
import Linkify from 'react-linkify';

import './FAQDetail.scss';
import { useDispatch } from 'react-redux';
import { onGetFaqRequest } from 'Stores/Account';
import { useLocation } from 'react-router';
import { routePath } from 'Navigator/routes';

type FAQDetailProps = {
  Question: string;
  Answer: string;
};
const FAQDetail: FC<FAQDetailProps> = ({ Answer, Question }) => {
  const dispatch = useDispatch();
  const newText = Answer.split('\n').map((str, index) => <p key={index.toString()}>{str}</p>);
  const { pathname } = useLocation();
  scroll.scrollToTop({
    duration: 1,
    smooth: true,
  });

  useEffect(() => {
    if (pathname !== routePath.faq) {
      dispatch(onGetFaqRequest());
    }
  }, []);

  return (
    <div id="faqDetail">
      <Typography component="span" variant="h3">
        <Box mb={gutters.big} fontWeight={600}>
          {Question}
        </Box>
      </Typography>
      <Typography component="span">
        <Box fontSize={fontSizes.regular}>
          {/* <Linkify {...{ target: '_blank', style: { color: 'red', fontWeight: 'bold' } }}>{newText}</Linkify> */}
          <Linkify
            componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
              const isLink = decoratedText.includes('https');
              return isLink ? (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              ) : (
                decoratedText
              );
            }}
          >
            {newText}
          </Linkify>
        </Box>
      </Typography>
    </div>
  );
};
export default FAQDetail;
