import { FC } from 'react';
import { HomeDrawer } from './HomeDrawer';
import { useHistory, useLocation } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { Link } from '@material-ui/core';
import { ProfileAvatar } from './ProfileAvatar';
import classNames from 'classnames';
import { HomeDarkIcon, IstanbulDarkLogo, IstanbulWhiteLogo, HomeWhiteIcon, Container } from 'Components';
import { useLocalization } from 'Hooks';
import { routeIncludeCheck } from 'Utils';
import { userStore } from 'Stores/User';
import { useSelector } from 'react-redux';

import './DashboardHeader.scss';

type DashboardHeaderProps = {};

type DashboardHeaderMenuItemType = {
  title?: string;
  onClick?: () => void;
  isHomePage?: boolean;
  menuItemPath: string;
  itemID: string;
};

const DashboardHeader: FC<DashboardHeaderProps> = () => {
  const { pathname } = useLocation();

  const history = useHistory();
  const userData = useSelector(userStore);

  const isResetPassword = userData?.resetPassword;
  const isMiniApp = userData?.isMiniAppLogin;

  const {
    web_link_btn_kartlarim_homepage,
    web_link_btn_islemler_homepage,
    web_link_btn_kart_basvurusu_homepage,
    web_link_btn_gez_ve_harca_homepage,
  } = useLocalization();

  //TODO-menuItemPathler Routelar tamamen yazıldıktan sonra ona göre düzenlenecektir.

  const pushToHistory = (route: string) => {
    if (window.location.pathname !== route) {
      history.push(route);
    }
  };
  const menuItems: DashboardHeaderMenuItemType[] = [
    {
      onClick: () => pushToHistory(routePath.home),
      menuItemPath: routePath.home,
      itemID: 'navigateHomeBtnTest',
    },
    {
      title: web_link_btn_kartlarim_homepage,
      onClick: () => pushToHistory(routePath.cards),
      menuItemPath: routePath.cards,
      itemID: 'navigateCardsBtnTest',
    },
    {
      title: web_link_btn_islemler_homepage,
      onClick: () => pushToHistory(routePath.transactions),
      menuItemPath: routePath.transactions,
      itemID: 'navigateTransactionsBtnTest',
    },
    {
      title: web_link_btn_gez_ve_harca_homepage,
      onClick: () => pushToHistory(routePath.discover),
      menuItemPath: routePath.discover,
      itemID: 'navigateDiscoverBtnTest',
    },
    {
      title: web_link_btn_kart_basvurusu_homepage,
      onClick: () => pushToHistory(routePath.cardApplication),
      menuItemPath: routePath.cardApplication,
      itemID: 'navigateCardApplicationBtnTest',
    },
  ];

  const handleDirectHomePage = () => {
    if (isResetPassword) {
      return;
    }

    history.push(routePath.home);
  };

  const isHomePage = pathname === routePath.home;

  return (
    <div id="dashboardHeader">
      <div
        className={classNames({
          'dashboardHeader--blue': isHomePage,
          'dashboardHeader--white': !isHomePage,
        })}
      >
        <Container>
          <div className="dashboardHeader__logo--wrapper">
            {isHomePage ? (
              <IstanbulWhiteLogo className="dashboardHeader__logo--item" onClick={handleDirectHomePage} />
            ) : (
              <IstanbulDarkLogo className="dashboardHeader__logo--item" onClick={handleDirectHomePage} />
            )}
            <HomeDrawer />
            <ProfileAvatar isHomePage={isHomePage} />
          </div>
          <ul>
            {menuItems.map(({ title, onClick, menuItemPath, itemID }, index) =>
              isMiniApp && itemID === 'navigateCardApplicationBtnTest' ? (
                ''
              ) : (
                <MenuItem
                  key={index}
                  title={title}
                  onClick={isResetPassword ? undefined : onClick}
                  isIcon={index === 0}
                  isHomePage={isHomePage}
                  menuItemPath={menuItemPath}
                  itemID={itemID}
                />
              )
            )}
          </ul>
        </Container>
      </div>
    </div>
  );
};

export default DashboardHeader;

const MenuItem: FC<DashboardHeaderMenuItemType & { isIcon?: boolean }> = ({
  title,
  onClick,
  isIcon,
  isHomePage,
  menuItemPath,
  itemID,
}) => {
  const { pathname } = useLocation();

  return (
    <li>
      <Link
        itemID={itemID}
        underline="none"
        onClick={onClick}
        className={classNames({
          'dashboardHeader--white--activeLink': routeIncludeCheck(pathname, menuItemPath),
        })}
      >
        {title && title}
        {isIcon && isHomePage && <HomeWhiteIcon />}
        {isIcon && !isHomePage && <HomeDarkIcon />}
      </Link>
    </li>
  );
};
