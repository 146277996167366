import { FC, Fragment } from 'react';
import { ArrowLeftIcon, Container, DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import './WelcomeIbmPoints.scss';
import { WelcomeHeader } from 'Views/Welcome/WelcomeHeader';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { gutters } from 'Theme/Variables';
import { routePath } from 'Navigator/routes';
import { IbmPoints } from 'Views/IbmPoints';

type WelcomeIbmPointsProps = {};
const WelcomeIbmPoints: FC<WelcomeIbmPointsProps> = ({}) => {
  const {} = useLocalization();
  const dispatch = useDispatch();

  const history = useHistory();
  return (
    <div id="welcomeIbmPoints">
      <WelcomeHeader />
      <Container>
        <Box marginBottom={gutters.large} marginTop={gutters.large}>
          <IbmPoints />
        </Box>
      </Container>
    </div>
  );
};
export default WelcomeIbmPoints;
