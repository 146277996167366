import { Fragment, FunctionComponent, memo, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { WelcomeHeader } from './WelcomeHeader';
import { WelcomeSignIn } from './WelcomeSignIn';
import { WelcomeLinks } from './WelcomeLinks';
import { WelcomeGetApp } from './WelcomeSignIn/WelcomeGetApp';
import { useDispatch } from 'react-redux';
import { setWelcomeStateNormalize } from 'Stores/Welcome';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './Welcome.scss';
import { getApiGWTokenCookie, MasterpassServicess } from 'Utils';
import { useLocalization } from 'Hooks';

type WelcomeParams = {};
type WelcomeProps = RouteComponentProps<WelcomeParams>;

const Welcome: FunctionComponent<WelcomeProps> = memo(() => {
  /*   const dispatch = useDispatch();
   */
  const { web_homepage_image } = useLocalization();

  useEffect(() => {
    /*  dispatch(setWelcomeStateNormalize()); */
    if (window.MFS === undefined || window.EvamSdkObject === undefined) {
      window.location.reload();
    }
  }, []);

  return (
    <Fragment>
      <WelcomeHeader />
      <Grid container spacing={0} id="welcomeContainer">
        <WelcomeSignIn />
        <Grid item lg={1} />
        <Grid item lg={6} className="welcomeContainer">
          <div className="welcomeContainer-bg" />
          <WelcomeLinks />
          <LazyLoadImage
            src={web_homepage_image}
            alt="İstanbul Kart"
            className="welcomeContainer-img"
            effect="opacity"
          />
        </Grid>
      </Grid>
      <WelcomeGetApp showOnlyMobile />
    </Fragment>
  );
});

export default Welcome;
