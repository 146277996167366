import { Formik, FormikProps } from 'formik';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userStore } from 'Stores/User';
import { gutters } from 'Theme/Variables';
import { TypesDialog } from './TypesDialog';
import { formikGlobalConfig } from 'Config';
import { useErrorMessage, useLocalization } from 'Hooks';
import { FormWrapper, SelectArrowDownIcon } from 'Components';
import { OrganizationTypeSchemaInitialValues } from 'Validations';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { CardProductCode } from 'Models/OnOnlineApplicationCardQueryModel';
import CartApplicationErrors from '../../CardApplication/CartApplicationErrors';
import { ApplicationProcessState, applicationStore, incStep, setActiveCard } from 'Stores/CardApplication';
import {
  onOnlineApplicationCardRequest,
  onOnlineApplicationRequest,
  setRelationData,
  storeCardTransactions,
} from 'Stores/CardTransactions';

import './ProductTypeStep.scss';

type CheckBoxOptionProps = {
  isSelected?: boolean;
  name: string;
  description?: string;
  onSelect: () => void;
};

const CheckBoxOption: FC<CheckBoxOptionProps> = ({ isSelected, name, description, onSelect }) => {
  const cx = classNames('t-checkbox', {
    't-checkbox--selected': isSelected,
  });

  return (
    <div className={cx} onClick={onSelect}>
      <div className="t-checkbox__icon" />
      <div className="t-checkbox__content">
        <div className="t-checkbox__title">{name}</div>
        {description ? <div className="t-checkbox__desc">{description}</div> : null}
      </div>
    </div>
  );
};

type ProductTypeStepProps = {
  relationShipApply?: boolean;
};

const ProductTypeStep: FC<ProductTypeStepProps> = ({ relationShipApply }) => {
  const { web_card_application_organisation_type, web_card_application_organisation_name } = useLocalization();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const formRef = useRef<
    FormikProps<{
      organizationTypeLabel: string;
      organizationLabel: string;
    }>
  >(null);

  const { customerNumber, sessionToken, citizenshipNumber } = useSelector(userStore);
  const {
    HashData,
    CustodyTckn,
    BirthDate,
    HasCustodyRelation,
    CustodyCustomerNumber,
    istanbulCardTypeList,
  } = useSelector(storeCardTransactions);
  const store: ApplicationProcessState = useSelector(applicationStore);
  const currentCard = store.applicationCard;
  const subProductList = store.subProductList;

  let { activeApplicationData, applicationCard } = store;
  const OrganizationTypeId = activeApplicationData?.OrganizationTypeId;

  const [cardType, setCardType] = useState<string>('');
  const [otherCard, setOtherCard] = useState<string | null>(null);
  const [selectedOrganizationTypeLabel, setSelectedOrganizationTypeLabel] = useState<string>(
    web_card_application_organisation_type
  );
  const [selectedNameLabel, setSelectedNameLabel] = useState<string>(web_card_application_organisation_name);
  const [isOpenTypesDialog, setIsOpenTypesDialog] = useState<boolean>(false);

  const { getError, getErrorMessage } = useErrorMessage();
  useEffect(() => {
    dispatch(
      onOnlineApplicationCardRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
  }, []);
  const employeeCheckOptions = [
    {
      name: localization?.web_Integrated_University_Personnel_Card,
      description: localization?.web_Integrated_University_Student_Card_info,
      value: '0',
    },
  ];

  const studentCheckOptions = [
    {
      name: 'Standart öğrenci kartı',
      description: localization?.web_card_standart_student,
      value: '0',
    },
    {
      name: 'Diğer öğrenci kartları',
      description: localization?.web_card_other_student,
      value: '1',
    },
    ...(!relationShipApply && CustodyTckn == null
      ? [
          {
            name: localization?.web_Integrated_University_Student_Card,
            description: localization?.web_Integrated_University_Student_Card_info,
            value: '2',
          },
        ]
      : []),
  ];

  const teacherCheckOptions = [
    {
      name: 'Öğretmen',
      description: '',
      value: '0',
    },
    ...(!relationShipApply && CustodyTckn == null
      ? [
          {
            name: localization?.web_Integrated_University_Teacher_Card,
            description: localization?.web_Integrated_University_Teacher_Card_info,
            value: '2',
          },
        ]
      : []),
  ];

  const checkOptionList: Record<any, { name: string; description: string; value: string }[]> = {
    PER_TAU: employeeCheckOptions,
    IKT_ORC: studentCheckOptions,
    IKT_ORT: teacherCheckOptions,
  };

  const integratedCardOptions = subProductList
    ?.filter((item: any) => item.GroupCode === 'Entegre')
    .map((filter: any) => {
      return {
        ...filter,
        Name: filter.ProductName,
        value: filter.SubProductCode,
      };
    });

  const checkOptions: any[] = checkOptionList[currentCard?.ProductCode || CardProductCode.OGRENCI];
  const handleSubmit = (e: any) => {
    if (disabled) {
      return;
    }
    const HideOrganizationStep =
      currentCard?.ProductCode === CardProductCode.OGRETMEN || cardType === '1' || cardType === '2';
    let SubProductCode = otherCard || undefined;
    if (otherCard == 'PER_TAU') {
      SubProductCode = '';
    }
    var applicationCardSelect: any = istanbulCardTypeList?.filter(
      (filter: any) => filter.SubProductCode == e.organizationLabel
    );
    let ShippingType;
    if (applicationCardSelect?.length == 1) {
      ShippingType = applicationCardSelect[0].ShippingType;
    } else {
      if (currentCard?.ProductCode === CardProductCode.OGRENCI) {
        ShippingType = 4;
      } else {
        ShippingType = applicationCard?.ShippingType;
      }
    }

    if (applicationCard !== undefined) {
      applicationCard = { ...applicationCard, ShippingType: ShippingType };
      dispatch(setActiveCard(applicationCard));
    }

    const request = {
      request: {
        SubProductCode,
        ProductCode: applicationCard?.ProductCode,
        CitizenshipNumber: citizenshipNumber,
        CustomerNumber: CustodyCustomerNumber || customerNumber,
        _CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        LoginUserTckn: citizenshipNumber,
        CustodyCustomerNumber,
        CustodyTckn,
        BirthDate,
        HashData,
        HasCustodyRelation,
        cardType,
      },
      product: applicationCard,
      callback: () => dispatch(incStep()),
    };

    dispatch(onOnlineApplicationRequest(request));
    dispatch(
      setRelationData({
        SubProductCode,
        HideOrganizationStep,
        HashData,
        CustodyTckn,
        BirthDate,
        HasCustodyRelation,
      })
    );
  };

  const onSelectCardType = (value: string) => {
    // Personel Entegre Üniversite
    if (currentCard?.ProductCode === CardProductCode.TURK_ALMAN_UNIVERSITESI_PERSONEL && value === '2') {
      setIsOpenTypesDialog(true);
      setCardType(value);
      return;
    }

    if (currentCard?.ProductCode === CardProductCode.OGRETMEN || value === '1' || value === '2') {
      dispatch(setRelationData({ HideOrganizationStep: true }));
    } else {
      dispatch(setRelationData({ HideOrganizationStep: false, SubProductCode: undefined }));
    }

    setIsOpenTypesDialog(false);
    formRef.current?.setFieldValue('organizationTypeLabel', '', true);
    formRef.current?.setFieldValue('organizationLabel', '', true);
    setOtherCard(null);
    setCardType(value);
  };

  const onSelectOtherCard = (value: string) => {
    setOtherCard(value);
  };

  const disabled = !cardType || (cardType !== '0' && otherCard === null);
  return (
    <div id="organisationStep">
      <CartApplicationErrors />
      <Typography variant="h3">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          Ürün tipini seç
        </Box>
      </Typography>
      {checkOptions.map((item, idx) => (
        <CheckBoxOption
          key={idx}
          name={item.name}
          description={item.description}
          onSelect={() => onSelectCardType(item.value)}
          isSelected={cardType === item.value}
        />
      ))}
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={OrganizationTypeSchemaInitialValues(
          selectedOrganizationTypeLabel !== web_card_application_organisation_type ? selectedOrganizationTypeLabel : '',
          selectedNameLabel !== web_card_application_organisation_name ? selectedNameLabel : ''
        )}
        onSubmit={handleSubmit}
        {...formikGlobalConfig}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            values: { organizationTypeLabel },
            errors: { organizationTypeLabel: errorOrganizationTypeLabel },
            touched: { organizationTypeLabel: touchedOrganizationTypeLabel },
          } = formikProps;

          let otherList = subProductList
            .filter((filter: any) => filter.GroupCode == 'Diğer')
            .map((filter: any) => {
              return {
                ...filter,
                Name: filter.ProductName,
                value: filter.SubProductCode,
              };
            });
          return (
            <FormWrapper onSubmit={formikProps.handleSubmit}>
              <TypesDialog
                selectedValue={OrganizationTypeId}
                bodyData={cardType === '2' ? integratedCardOptions : otherList}
                open={isOpenTypesDialog}
                close={setIsOpenTypesDialog}
                onChange={(value: string, label: string) => {
                  onSelectOtherCard(value);
                  setIsOpenTypesDialog(false);
                  formikProps.setFieldValue('organizationTypeLabel', label, true);
                  formikProps.setFieldValue('organizationLabel', value, true);
                }}
              />
              {cardType &&
                currentCard?.ProductCode !== CardProductCode.TURK_ALMAN_UNIVERSITESI_PERSONEL &&
                (cardType === '1' || cardType === '2') && (
                  <>
                    <Box>
                      <Typography variant="h3">
                        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
                          Kartlar
                        </Box>
                      </Typography>

                      <div onClick={() => setIsOpenTypesDialog(true)}>
                        <TextField
                          variant="filled"
                          label="Kart Seç"
                          fullWidth
                          onBlur={handleBlur}
                          InputProps={{
                            fullWidth: true,
                            endAdornment: <SelectArrowDownIcon />,
                          }}
                          error={getError(errorOrganizationTypeLabel, touchedOrganizationTypeLabel)}
                          helperText={getErrorMessage(errorOrganizationTypeLabel, touchedOrganizationTypeLabel)}
                          onChange={handleChange}
                          name="organizationLabel"
                          id="organizationLabel"
                          value={organizationTypeLabel}
                          itemID="cardApplyOrganizationLabelInputTest"
                        />
                      </div>
                    </Box>
                  </>
                )}
              <Box mt={gutters.small} />
              <Button
                disabled={disabled}
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                itemID="nextCardApplyOrganizationStepBtnTest"
              >
                Devam Et
              </Button>
            </FormWrapper>
          );
        }}
      </Formik>
    </div>
  );
};
export default ProductTypeStep;
