import { FC } from 'react';
import { useCardType, useDigitalAccount, useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { useHistory } from 'react-router';
import { TransactionDetail } from './TransactionDetail';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import { CardTransactionsState, setAddCardStep, storeCardTransactions } from 'Stores/CardTransactions';
import { cardBalanceNormalize } from 'Utils';
import { CreditCardTransactionsState, creditCardTransactionsStore } from 'Stores/CreditCardTransactions';
import digitalAccountCard from 'Assets/Images/cardTypes/digital_account_card.png';

import './AddCardSuccessLoadBalance.scss';

type AddCardSuccessLoadBalanceProps = {};

const AddCardSuccessLoadBalance: FC<AddCardSuccessLoadBalanceProps> = ({}) => {
  const {
    web_btn_success_upload_tl,
    web_desc1_success_upload_tl,
    web_desc2_success_upload_tl,
    web_btn_add_to_my_cards,
    card_colour_digital_card_activation_control,
    web_tl_success_current_balance,
    web_tl_success_homepage_btn,
  } = useLocalization();
  const history = useHistory();

  const { isVisible, show, hide } = useModal();

  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const digitalAccount = useDigitalAccount();
  const { isUserAddingNewCardInTransactions, activeSelectedCard }: any = cardData;

  const { cardColor, cardPath } = useCardType(activeSelectedCard?.ProductCode);

  const isDigitalAccount = cardData.isDigitalActive;

  return (
    <div id="addCardSuccessLoadBalance">
      <Box marginTop={gutters.big}>
        <div className="add-card-success-load-balance__img--wrapper">
          <img
            src={isDigitalAccount ? digitalAccountCard : cardPath}
            alt=""
            className={`add-card-success-load-balance__img--wrapper__${isDigitalAccount ? 'digital' : cardColor}`}
          />
          <GreenTickBigFilledIcon />
        </div>
        {!isDigitalAccount && (
          <Box className="add-card-success-load-balance__desc">
            <Link underline="none" style={{ cursor: 'unset' }}>
              <Box textAlign="center" color={colors.alternative3} fontSize={fontSizes.regular}>
                {web_desc1_success_upload_tl}
              </Box>
            </Link>
            <Typography variant="body1" align="center">
              <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
                {web_desc2_success_upload_tl}
              </Box>
            </Typography>
          </Box>
        )}
        {isDigitalAccount && (
          <Typography variant="body1" align="center">
            <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
              {web_tl_success_current_balance}:{' '}
              <Box component="span" fontWeight="bold" color={colors.toastSuccessText}>
                {cardBalanceNormalize(digitalAccount.balanceTotal)} TL
              </Box>
            </Box>
          </Typography>
        )}
        <div className="add-card-success-load-balance__container">
          <Box fontWeight="bold">
            {isUserAddingNewCardInTransactions && !isDigitalAccount ? (
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    dispatch(setAddCardStep(0));
                    history.push(routePath.addCard);
                  }}
                  itemID="addCardToMyCardsBtnTest"
                >
                  {web_btn_add_to_my_cards}
                </Button>
                <Box marginTop={gutters.small}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type="submit"
                    onClick={() => show()}
                    itemID="seeUploadDetailBtnTest"
                  >
                    {web_btn_success_upload_tl}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Button
                variant={isDigitalAccount ? 'outlined' : 'contained'}
                color="secondary"
                fullWidth
                type="submit"
                onClick={() => show()}
                itemID="seeUploadDetailBtnTest"
              >
                {web_btn_success_upload_tl}
              </Button>
            )}
            {isDigitalAccount && (
              <Box marginTop={gutters.small}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  type="submit"
                  onClick={() => history.push(routePath.home)}
                  itemID="seeUploadDetailBtnTest"
                >
                  {web_tl_success_homepage_btn}
                </Button>
              </Box>
            )}
          </Box>
        </div>
      </Box>
      <Box>
        <TransactionDetail isVisible={isVisible} hide={hide} />
      </Box>
    </div>
  );
};

export default AddCardSuccessLoadBalance;
