import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { CloseIcon, Countdown, FormWrapper, MasterpassLogo } from 'Components';
import { FormErrorWrapper } from 'Components/FormErrorWrapper';
import { InputOneCharBox } from 'Components/InputOneCharBox';
import { formNames } from 'Config';
import { useLocalization } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { loginNormalize, onConfirmSmsNormalize, sendTokenChangePasswordNormalize } from 'Normalize';
import { masterPassOtpNormalize } from 'Normalize/MasterPassRequestNormalize';
import { AccountState, storeAccount } from 'Stores/Account';
import { onGetMasterpassForMobileRequest, onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { DialogState, closeOTPDialog, storeDialog } from 'Stores/Dialog';
import { FormState, resetFormError, storeFormError } from 'Stores/Form';
import {
  UserState,
  onChangeCustomerInfoRequest,
  onChangePhoneValidationRequest,
  onConfirmLoginSmsRequest,
  onConfirmSmsRequest,
  onConfirmSmsRequestForForgotPassword,
  onLoginRequest,
  onSendTokenChangePasswordRequest,
  userStore,
} from 'Stores/User';
import { onCheckNumberRequest, storeWelcome } from 'Stores/Welcome/slices';
import { gutters } from 'Theme/Variables';
import { MasterPassTokenEnum } from 'Utils';
import { userInfoTypes } from 'Views/UserInfo/constant';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './DialogOtp.scss';

type DialogOTPProps = {
  isOpen: boolean;
  onResendSms?: Function;
};

const DialogOTP: FunctionComponent<DialogOTPProps> = ({ isOpen }) => {
  const {
    location: { pathname },
    location: { state },
  } = useHistory();

  const dispatch = useDispatch();

  // İf user redirect from store using location state
  const phoneNumber: string = get(state, 'phoneNumber');
  const countryCode: string = get(state, 'countryCode');

  // İf user passive use the store value according to request
  const { Cellphone, CountryCode } = useSelector(storeWelcome);

  const { userPassword, loginFormStep, userTcNo, birthDate } = useSelector(userStore);

  const { web_title_otp_screen, web_btn_accept } = useLocalization();
  const [value, setValue] = useState<string>('');
  const [isCountingDown, setIsCountingDown] = useState<boolean>(true);

  // For user Info Change

  const accountState: AccountState = useSelector(storeAccount);
  const { fullScreen }: DialogState = useSelector(storeDialog);
  const userNewPhone = accountState?.userNewNumberForUpdate;

  const userData: UserState = useSelector(userStore);
  const customerNumber: number | undefined = userData?.customerNumber;
  const sessionToken: string | undefined = userData?.sessionToken;

  const formState: FormState = useSelector(storeFormError);
  const errorCode: string = formState?.errorCode;

  // For user Info Change

  useEffect(() => {
    document.addEventListener('keydown', enterHandler);
    return () => document.removeEventListener('keydown', enterHandler);
    // eslint-disable-next-line
  }, [value]);

  const hide = () => {
    dispatch(resetFormError());
    dispatch(closeOTPDialog());
  };

  const handleButtonClick = () => {
    if (isCountingDown) {
      switch (pathname) {
        case routePath.welcome:
          dispatch(onConfirmSmsRequest(onConfirmSmsNormalize(value, Cellphone, CountryCode)));
          break;
        case routePath.login:
          if (loginFormStep === 0) {
            dispatch(onConfirmSmsRequest(onConfirmSmsNormalize(value, Cellphone, CountryCode)));
          }
          if (loginFormStep === 1) {
            dispatch(onConfirmLoginSmsRequest(onConfirmSmsNormalize(value, phoneNumber, countryCode)));
          }
          break;
        case routePath.signup:
          if (loginFormStep === 0) {
            dispatch(onConfirmSmsRequest(onConfirmSmsNormalize(value, Cellphone, CountryCode)));
          }
          break;
        case routePath.forgotPassword:
          dispatch(onConfirmSmsRequestForForgotPassword(onConfirmSmsNormalize(value, phoneNumber, countryCode)));
          break;
        case routePath.virtualCardCreate:
        case routePath.mobileAddCreditCard:
          dispatch(
            onGetMasterpassForMobileRequest({
              TokenType: MasterPassTokenEnum.OTP,
              request: {
                data: masterPassOtpNormalize(value),
              },
            })
          );
          break;
        case routePath.userInfo:
          dispatch(
            onChangePhoneValidationRequest({
              CustomerNumber: customerNumber,
              SessionToken: sessionToken,
              CountryCode: userNewPhone.countryCode,
              CellPhone: userNewPhone.cellPhone,
              SmsToken: parseInt(value),
            })
          );
          break;
        case routePath.addBalanceToCard:
        case routePath.uploadSubscription:
        case routePath.addCreditCard:
        case routePath.creditCards:
          dispatch(
            onGetMasterpassTokenRequest({
              TokenType: MasterPassTokenEnum.OTP,
              request: {
                data: masterPassOtpNormalize(value),
              },
            })
          );
          break;
        case routePath.mobilePaymentOrder:
          dispatch(
            onGetMasterpassForMobileRequest({
              TokenType: MasterPassTokenEnum.OTP,
              request: {
                data: masterPassOtpNormalize(value),
              },
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const enterHandler = useCallback(
    (event: any) => {
      if (event.keyCode === 13 && value.length === decideInputCharBoxAmount()) {
        setTimeout(handleButtonClick, 10); // Timer trick for avoiding form submit
      }
    },
    // eslint-disable-next-line
    [value]
  );

  const onResetTimer = () => {
    setIsCountingDown(true);
    dispatch(resetFormError());
    switch (pathname) {
      case routePath.welcome:
        dispatch(
          onCheckNumberRequest({
            CountryCode,
            Cellphone,
          })
        );
        break;
      case routePath.login:
        if (loginFormStep === 0) {
          dispatch(
            onCheckNumberRequest({
              CountryCode: countryCode,
              Cellphone: phoneNumber,
            })
          );
        } else {
          dispatch(
            onLoginRequest({
              onLoginData: loginNormalize(userPassword, phoneNumber, countryCode),
              formName: formNames.dialogOTP,
            })
          );
        }
        break;
      case routePath.forgotPassword:
        dispatch(
          onSendTokenChangePasswordRequest(
            sendTokenChangePasswordNormalize(userTcNo, birthDate, phoneNumber, countryCode)
          )
        );
        break;
      case routePath.mobileAddCreditCard:
        dispatch(
          onGetMasterpassForMobileRequest({
            TokenType: MasterPassTokenEnum.RESEND_OTP,
            request: {
              data: masterPassOtpNormalize(''),
            },
          })
        );
        break;
      case routePath.userInfo:
        dispatch(
          onChangeCustomerInfoRequest({
            CustomerNumber: customerNumber,
            SessionToken: sessionToken,
            CountryCode: userNewPhone.countryCode,
            Cellphone: userNewPhone.cellPhone,
            TypeOfField: userInfoTypes.phone,
          })
        );
        break;
      case routePath.addBalanceToCard:
      case routePath.uploadSubscription:
      case routePath.addCreditCard:
      case routePath.creditCards:
        dispatch(
          onGetMasterpassTokenRequest({
            TokenType: MasterPassTokenEnum.RESEND_OTP,
            request: {
              data: masterPassOtpNormalize(''),
            },
          })
        );
        break;
      case routePath.mobilePaymentOrder:
        dispatch(
          onGetMasterpassForMobileRequest({
            TokenType: MasterPassTokenEnum.RESEND_OTP,
            request: {
              data: masterPassOtpNormalize(''),
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const onCountEnd = () => {
    setIsCountingDown(false);
    setValue('');
  };

  const decideInputCharBoxAmount = useCallback(() => {
    if (
      pathname === routePath.addCreditCard ||
      pathname === routePath.creditCards ||
      pathname === routePath.addBalanceToCard ||
      pathname === routePath.uploadSubscription ||
      pathname === routePath.mobileAddCreditCard ||
      pathname === routePath.virtualCardCreate ||
      pathname === routePath.mobilePaymentOrder
    ) {
      return 6;
    }
    return 5;
  }, [pathname]);

  const checkDisableCount = () => {
    if (
      pathname === routePath.addCreditCard ||
      pathname === routePath.creditCards ||
      pathname === routePath.addBalanceToCard ||
      pathname === routePath.uploadSubscription ||
      pathname === routePath.mobileAddCreditCard ||
      pathname === routePath.virtualCardCreate ||
      pathname === routePath.mobilePaymentOrder
      // added last two line
    ) {
      return 6;
    } else {
      return 5;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      id="dialog-otp"
      open={isOpen}
      onClose={hide}
      keepMounted={false}
      disablePortal={true}
      disableBackdropClick
    >
      <div className={`${!fullScreen ? 'dialogContainer' : 'dialogContainerFull'}`}>
        {!fullScreen && <CloseIcon onClick={() => hide()} />}
        <FormErrorWrapper formName={formNames.dialogOTP} resetFormOnRouteChange>
          <Typography variant="h5" align="center">
            <Box fontWeight={600} marginBottom={gutters.regular}>
              {web_title_otp_screen}
            </Box>
            {(pathname === routePath.addCreditCard ||
              pathname === routePath.creditCards ||
              pathname === routePath.mobilePaymentOrder ||
              pathname === routePath.addBalanceToCard) && (
              <Box marginBottom={gutters.regular}>
                <MasterpassLogo width="100px" height="18px" />
              </Box>
            )}
          </Typography>

          <FormWrapper>
            <InputOneCharBox
              value={value}
              amount={decideInputCharBoxAmount()}
              setValue={setValue}
              disabled={!isCountingDown}
            />
            <Button
              disabled={value.length !== checkDisableCount()}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleButtonClick}
              itemID="dialogOtpBtnTest"
            >
              {web_btn_accept}
            </Button>
          </FormWrapper>
          <Countdown
            countdownSeconds={120}
            onCountEnd={onCountEnd}
            onResetTimer={onResetTimer}
            errorForReset={errorCode === '1150'}
          />
        </FormErrorWrapper>
      </div>
    </Dialog>
  );
};

const DialogOTPWrapper = () => {
  const { isOtpOpen } = useSelector(storeDialog);

  if (isOtpOpen) {
    return <DialogOTP isOpen={isOtpOpen} />;
  } else {
    return null;
  }
};

export default DialogOTPWrapper;
