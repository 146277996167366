const maskCreditCardNumber = (cardNumber: string, maskCenter: boolean = false, endFour: boolean = false) => {
  if (maskCenter) {
    if (cardNumber.length !== 16) {
      return 'Geçersiz kart numarası uzunluğu';
    }
    return cardNumber.substring(0, 4) + '********' + cardNumber.substring(12);
  } else if (endFour) {
    const first4 = cardNumber?.substring(0, 4);
    const fiveAndSix = cardNumber?.substring(4, 6);
    const last2 = cardNumber?.substring(cardNumber.length - 4);

    return `${first4} ${fiveAndSix}** **** ${last2}`;
  } else {
    const first4 = cardNumber?.substring(0, 4);
    const fiveAndSix = cardNumber?.substring(4, 6);
    const last2 = cardNumber?.substring(cardNumber.length - 2);

    return `${first4} ${fiveAndSix}** **** **${last2}`;
  }
};
export default maskCreditCardNumber;
