import * as React from 'react';

function PhotoErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  const randomId = Math.floor(Math.random() * 1000) + '_' + Math.floor(Math.random() * 100);
  return (
    <svg
      width="30px"
      height="29px"
      viewBox="0 0 30 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,7.78986258 17.4553597,5.67024664 15.8925565,4.10744349 C14.3297534,2.54464034 12.2101374,1.66666667 10,1.66666667 Z M12.75,10.9166667 L10.9166667,10.9166667 L10.9166667,12.75 C10.9166667,13.256261 10.506261,13.6666667 10,13.6666667 C9.49373898,13.6666667 9.08333333,13.256261 9.08333333,12.75 L9.08333333,10.9166667 L7.25,10.9166667 C6.74373898,10.9166667 6.33333333,10.506261 6.33333333,10 C6.33333333,9.49373898 6.74373898,9.08333333 7.25,9.08333333 L9.08333333,9.08333333 L9.08333333,7.25 C9.08333333,6.74373898 9.49373898,6.33333333 10,6.33333333 C10.506261,6.33333333 10.9166667,6.74373898 10.9166667,7.25 L10.9166667,9.08333333 L12.75,9.08333333 C13.256261,9.08333333 13.6666667,9.49373898 13.6666667,10 C13.6666667,10.506261 13.256261,10.9166667 12.75,10.9166667 Z"
          id={`path-${randomId}`}
        ></path>
      </defs>
      <g id="Kart-basvurusu---1.-fotografli-kartlar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Alternatif-1.0-mobile-with-text---2" transform="translate(-167.000000, -425.000000)">
          <g id="Group-8" transform="translate(167.823967, 425.000000)">
            <circle id="Oval" fill="#FFFFFF" cx="13.8592929" cy="13.1621356" r="4.9"></circle>
            <g
              id="icon/placeholder"
              transform="translate(14.142136, 14.142136) rotate(-315.000000) translate(-14.142136, -14.142136) translate(4.142136, 4.142136)"
            >
              <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
              <mask id={`mask-${randomId}`} fill="white">
                <use xlinkHref={`#path-${randomId}`}></use>
              </mask>
              <use id="Shape" fill="#383C52" fill-rule="nonzero" xlinkHref={`#path-${randomId}`}></use>
              <g id="brand/color/secondary" mask={`url(#mask-${randomId})`} fill="#EE3C2D">
                <rect id="brand/color_cards/red/2" x="0" y="0" width="20" height="20"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhotoErrorIcon;
