import * as React from 'react';

function HomeWhiteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      {...props}
    >
      <defs>
        <path
          id="3z632gb1ba"
          d="M12 2.004c.267 0 .522.107.71.296l7.71 7.88c.373.387.58.903.58 1.44V20c.002 1.063-.829 1.942-1.89 2H16v-9c0-.552-.448-1-1-1H9c-.552 0-1 .448-1 1v9H4.89c-1.061-.058-1.892-.937-1.89-2v-8.38c-.006-.535.203-1.05.58-1.43l7.71-7.89c.188-.19.443-.296.71-.296zM14 14v7h-4v-7h4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-155 -94) translate(155.136 94)">
            <path d="M0 0H24V24H0z" />
            <mask id="19x5sfnuqb" fill="#fff">
              <use xlinkHref="#3z632gb1ba" />
            </mask>
            <g fill="#FFF" mask="url(#19x5sfnuqb)">
              <path d="M0 0H24V24H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HomeWhiteIcon;
