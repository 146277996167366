import * as React from 'react';

function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="settingsIcon"
      {...props}
    >
      <defs>
        <path
          id="0dloireqba"
          d="M19.525 8.674c-.158-.101-1.41-.85-1.906-1.077l-.615-1.488c.183-.492.534-1.87.595-2.11.05-.222-.017-.455-.178-.615l-.97-.964c-.16-.162-.393-.23-.615-.179-.182.042-1.587.397-2.11.595l-1.488-.615c-.216-.476-.944-1.695-1.077-1.906C11.041.12 10.829 0 10.6 0H9.235c-.228 0-.44.117-.561.31-.101.158-.85 1.41-1.077 1.906l-1.488.614c-.492-.182-1.87-.533-2.11-.595-.222-.05-.455.018-.615.179l-.964.97c-.162.16-.23.393-.179.615.042.182.397 1.586.595 2.11l-.615 1.488C1.745 7.813.526 8.54.315 8.674.12 8.794 0 9.006 0 9.235v1.363c0 .227.117.44.31.561.158.101 1.41.849 1.906 1.077l.614 1.488c-.182.492-.533 1.87-.595 2.11-.05.222.018.454.179.615l.964.964c.16.162.393.23.615.178.182-.041 1.587-.396 2.11-.595l1.488.615c.216.476.944 1.696 1.077 1.906.12.199.335.32.567.318h1.363c.227 0 .44-.117.561-.31.101-.158.849-1.41 1.077-1.906l1.488-.615c.492.183 1.87.534 2.11.595.222.05.454-.017.615-.178l.964-.964c.162-.16.23-.393.178-.615-.041-.182-.396-1.587-.595-2.11l.615-1.488c.476-.216 1.696-.944 1.906-1.077.199-.12.32-.336.318-.567V9.235c.002-.228-.116-.44-.31-.561zm-9.608 5.561C7.53 14.233 5.597 12.296 5.6 9.91c.003-2.387 1.94-4.32 4.326-4.318 2.387.003 4.32 1.94 4.318 4.326-.005 2.386-1.94 4.317-4.326 4.318z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-881 -181) translate(881 181)" />
            <g transform="translate(-881 -181) translate(881 181) translate(2 2)">
              <mask id="6b60509ozb" fill="#fff">
                <use xlinkHref="#0dloireqba" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#0dloireqba" />
              <g fill="#FFF" mask="url(#6b60509ozb)">
                <path d="M0 0H24V24H0z" transform="translate(-2 -2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SettingsIcon;
