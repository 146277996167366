import { axiosHelper } from 'Services/AxiosHelper';
import { OnCountInstructionListModel } from 'Models';
import { onCountInstructionListReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnCountInstructionList';

const onCountInstructionList = async (
  params: onCountInstructionListReqBodyData
): Promise<OnCountInstructionListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onCountInstructionList;
