import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { setGlobalLoadingFalse } from 'Stores/App/slices';
import { securityErrors, setFormError } from './slices';
import { FormState } from './types';
import { show505ErrorDialog } from 'Stores/Dialog/slices';
import { UserState, userStore } from 'Stores/User';
// Workers
function* handleSetFormError({ payload }: PayloadAction<FormState>) {
  const userState: UserState = yield select(userStore);
  const isMiniApp = userState.isMiniAppLogin;
  if (
    payload.errorCode === securityErrors['505'] ||
    payload.errorCode === securityErrors['506'] ||
    payload.errorCode === securityErrors['507']
  ) {
    if (isMiniApp) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const REDIRECT_URL = `${protocol}//${host}/mini-app`;
      window.location.href = REDIRECT_URL;
    } else {
      yield put(show505ErrorDialog());
    }
  }
  yield put(setGlobalLoadingFalse());
}

// Watchers
function* errorFormSagaWatcher() {
  yield takeLatest(setFormError, handleSetFormError);
}

// eslint-disable-next-line
export default errorFormSagaWatcher;
