import * as yup from 'yup';
import { errorMessages } from 'Config';

export const AddCustomerComplaint = yup.object({
  subjectID: yup.string().required(errorMessages.require),
  processDate: yup
    .string()
    .required(errorMessages.require)
    .test('checkValid', errorMessages.invalidDate, (value?: string | null) => {
      const dayMonthYear = value?.split('.');

      if (!dayMonthYear || dayMonthYear.length < 3) return false;
      const [day, month, year] = dayMonthYear;

      if (Number(day) < 0 || Number(day) > 31) {
        return false;
      }

      if (Number(month) < 0 || Number(month) > 12) {
        return false;
      }

      if (Number(year) < 2020) {
        return false;
      }

      return true;
    }),
  processHour: yup
    .string()
    .required(errorMessages.require)
    .test('checkValid', errorMessages.invalidHour, (value?: string | null) => {
      const hourMinute = value?.split(':');

      if (!hourMinute || hourMinute.length < 2) return false;
      const [hour, minute] = hourMinute;

      if (Number(hour) < 0 || Number(hour) > 23) {
        return false;
      }

      if (Number(minute) < 0 || Number(minute) > 59) {
        return false;
      }

      return true;
    }),
  complaintDetail: yup.string().required(errorMessages.require),
});
