import * as React from 'react';

function Avatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      {...props}
      className="avatar"
    >
      <defs>
        <path
          id="ol1s542sra"
          d="M12 13c3.866 0 7 3.134 7 7 0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1 0-3.866 3.134-7 7-7zm0-10c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0H24V24H0z" />
          <mask id="2x48vgv20b" fill="#fff">
            <use xlinkHref="#ol1s542sra" />
          </mask>
          <g fill="currentColor" mask="url(#2x48vgv20b)">
            <path d="M0 0H24V24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Avatar;
