import { FunctionComponent, useState, KeyboardEvent, MouseEvent, memo } from 'react';
import { Box, Divider, Drawer, Link } from '@material-ui/core';
import { CloseWhiteIcon, MenuBarIcon } from 'Components';
import './WelcomeDrawer.scss';
import { WelcomeLinks } from 'Views/Welcome/WelcomeLinks';
import { gutters } from 'Theme/Variables';
import { WelcomeHeaderMenuItemType } from '../WelcomeHeader';
import { useWindowDimensions } from 'Hooks';
import classNames from 'classnames';
import { IstanbulWhiteLogo } from 'Components';
import { useHistory, useLocation } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { userStore } from 'Stores/User';
import { useSelector } from 'react-redux';

type WelcomeDrawerProps = {
  menuItems: WelcomeHeaderMenuItemType[];
  isDrawerLinks?: boolean;
};

const WelcomeDrawer: FunctionComponent<WelcomeDrawerProps> = memo(({ menuItems, isDrawerLinks }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { height, isTinyHeightDevice } = useWindowDimensions();
  const { pathname } = useLocation();
  const history = useHistory();
  const userData = useSelector(userStore);
  const isMiniApp = userData?.isMiniAppLogin;

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleDirectHomePage = () => {
    if (pathname === routePath.home) {
      window.location.reload();
    } else if (pathname === routePath.welcome) {
      window.location.reload();
    } else {
      history.push(routePath.home);
    }
  };

  return (
    <div id="welcomeDrawer">
      <MenuBarIcon onClick={toggleDrawer(true)} className="welcomeDrawer-bar" />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: classNames({
            'welcomeDrawer-drawer': true,
            'welcomeDrawer-drawer--smallHeightDevice': isTinyHeightDevice,
          }),
          style: {
            height: height,
          },
        }}
      >
        <Box padding={gutters.regular} justifyContent="center" display="flex" className="welcomeDrawer-drawer--logo">
          <IstanbulWhiteLogo width={125} onClick={handleDirectHomePage} />
        </Box>
        <CloseWhiteIcon onClick={toggleDrawer(false)} />
        <Box
          paddingLeft={gutters.regular}
          paddingRight={gutters.regular}
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <div className="welcomeDrawer-drawer--divider">
            <Divider />
          </div>

          <Box display="flex" flexDirection="column-reverse">
            {menuItems.map(({ title, onClick, Icon, itemID }, index) =>
              isMiniApp && itemID === 'navigateCardApplicationBtnTest' ? (
                ''
              ) : (
                <MenuItem
                  key={index}
                  title={title}
                  onClick={() => {
                    onClick();
                    setIsOpen(false);
                  }}
                  Icon={Icon && Icon}
                  itemID={itemID}
                />
              )
            )}
          </Box>
          <WelcomeLinks isDrawerLinks={isDrawerLinks} />
        </Box>
      </Drawer>
    </div>
  );
});

const MenuItem: FunctionComponent<WelcomeHeaderMenuItemType> = memo(({ title, onClick, Icon, itemID }) => (
  <>
    <Divider />
    <Box display="flex" paddingBottom={gutters.regular} paddingTop={gutters.regular}>
      {Icon && (
        <Box marginRight={gutters.regular} style={{ marginTop: '-2px' }}>
          <Icon />
        </Box>
      )}
      <Link underline="none" display="block" onClick={onClick} itemID={itemID}>
        <Box fontWeight="bold">{title}</Box>
      </Link>
    </Box>
  </>
));

export default WelcomeDrawer;
