import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onCreateOnlineCustomerBodyData, onSetPasswordBodyData } from 'Config/ServiceConfig';
import { OnCreateOnlineCustomerModel, OnSetPasswordModel } from 'Models';
import { RootState } from 'Stores/index';
import { SignUpState } from './types';

const MAX_STEP_COUNT = 5;

export const initialState: SignUpState = {
  isLoading: false,
  onCreateOnlineCustomerModel: undefined,
  step: 1,
  error: undefined,
  isUserAcceptedMail: false,
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    onCreateOnlineCustomerRequest: (state: SignUpState, { payload }: PayloadAction<onCreateOnlineCustomerBodyData>) => {
      state.isLoading = true;
    },
    onCreateOnlineCustomerSuccess: (state: SignUpState, action: PayloadAction<OnCreateOnlineCustomerModel>) => {
      state.isLoading = false;
      state.onCreateOnlineCustomerModel = action.payload;
    },
    onCreateOnlineCustomerFail: (state: SignUpState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    onSetPasswordRequest: (state: SignUpState, { payload }: PayloadAction<onSetPasswordBodyData>) => {
      state.isLoading = true;
    },
    onSetPasswordSuccess: (state: SignUpState, action: PayloadAction<OnSetPasswordModel>) => {
      state.isLoading = false;
    },
    onSetPasswordFail: (state: SignUpState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
    incrementSignupStep: (state: SignUpState) => {
      if (state.step < MAX_STEP_COUNT) state.step = ++state.step;
    },
    decrementSignupStep: (state: SignUpState) => {
      if (state.step > 0) state.step = --state.step;
    },
    resetSignupStep: (state: SignUpState) => {
      state.step = 0;
    },
    setIsUserAcceptedMail: (state: SignUpState, { payload }: PayloadAction<boolean>) => {
      state.isUserAcceptedMail = payload;
    },
    resetSignUpStore: (state: SignUpState) => initialState,
  },
});

export const signUpIsLoading = (state: RootState) => state.signUp.isLoading;
export const storeSingupStep = (state: RootState) => state.signUp.step;

export const {
  onCreateOnlineCustomerRequest,
  onCreateOnlineCustomerSuccess,
  onSetPasswordRequest,
  onSetPasswordSuccess,
  incrementSignupStep,
  decrementSignupStep,
  resetSignupStep,
  onSetPasswordFail,
  onCreateOnlineCustomerFail,
  setIsUserAcceptedMail,
  resetSignUpStore,
} = signUpSlice.actions;

export default signUpSlice.reducer;
