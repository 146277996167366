import axios from 'axios';
import { get } from 'lodash';

const getTownList = async (param: string) => {
  const url = `https://api.ibb.gov.tr/ibb-adres-api/adres/il/ilce/${param}`;

  const response = await axios.get(url);

  return get(response, 'data');
};

export default getTownList;
