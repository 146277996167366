import CryptoJS from 'crypto-js';
import { getEnvironment } from 'Utils';

const createEncryptedData = (request: any) => {
  const hashParams: string =
    request.ChannelCode +
    request.Amount +
    request.CommissionAmount +
    request.RandomValue +
    request.RefTrnType +
    request.TerminalNumber +
    request.Pan +
    request.PmtAccountId +
    request.CreditCardId +
    request.CellPhone +
    request.MifareId;

  const merchantApiPass = getEnvironment() ? '12345' : 'VPOS85032201';

  const createHashCode = (value: string) => {
    // return CryptoJS.enc.Base64.stringify(CryptoJS.SHA1(value));
    return CryptoJS.SHA1(value).toString(CryptoJS.enc.Base64);
  };

  const requestBody: { Hash: String } = {
    ...request,
    Hash: createHashCode(hashParams + merchantApiPass),
  };

  const encryptData = (text: string) => {
    const key = CryptoJS.enc.Base64.parse('wUN7bZ8LZfmknh3UP5TW30yJaVcIs7kVoS1LSDHub8M=');
    const iv = CryptoJS.enc.Base64.parse('AAAAAAAAAAAAAAAAAAAAAA==');
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: iv,
    }).ciphertext.toString();
    return encrypted;
  };

  const encryptedData: string = encryptData(JSON.stringify(requestBody));

  return encryptedData;
};

export default createEncryptedData;
