import * as React from 'react';

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="copyIcon"
      {...props}
    >
      <title>5A8634CE-A4A7-48E7-AE2C-0CABEEE72B7A</title>
      <defs>
        <path
          d="M15,6 C16.6568542,6 18,7.34314575 18,9 L18,15 C18,16.6568542 16.6568542,18 15,18 L9,18 C7.34314575,18 6,16.6568542 6,15 L6,12 L3,12 C1.34314575,12 0,10.6568542 0,9 L0,3 C0,1.34314575 1.34314575,0 3,0 L9,0 C10.6568542,0 12,1.34314575 12,3 L12,6 L15,6 Z M15,8 L9,8 C8.48716416,8 8.06449284,8.38604019 8.00672773,8.88337887 L8,9 L8,15 C8,15.5128358 8.38604019,15.9355072 8.88337887,15.9932723 L9,16 L15,16 C15.5128358,16 15.9355072,15.6139598 15.9932723,15.1166211 L16,15 L16,9 C16,8.48716416 15.6139598,8.06449284 15.1166211,8.00672773 L15,8 Z M10,3 C10,2.48716416 9.61395981,2.06449284 9.11662113,2.00672773 L9,2 L3,2 C2.48716416,2 2.06449284,2.38604019 2.00672773,2.88337887 L2,3 L2,9 C2,9.51283584 2.38604019,9.93550716 2.88337887,9.99327227 L3,10 L6,10 L6,9 C6,7.34314575 7.34314575,6 9,6 L10,6 L10,3 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="Kart-Detay---Kart-ayarları" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop---Kart-ayarları---imtiyazlı---1.1" transform="translate(-951.000000, -379.000000)">
          <g id="icon/copy/2" transform="translate(951.000000, 379.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g id="brand/color/secondary" transform="translate(3.000000, 3.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use id="Mask" fill="#1AAADC" fillRule="nonzero" xlinkHref="#path-1"></use>
              <g mask="url(#mask-2)" fill="currentColor" id="brand/color/1st/333333">
                <g transform="translate(-3.000000, -3.000000)">
                  <rect x="0" y="0" width="24" height="24"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CopyIcon;
