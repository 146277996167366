import React from 'react';

const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.7">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 9H18C19.6569 9 21 10.3431 21 12V18C21 19.6569 19.6569 21 18 21H12C10.3431 21 9 19.6569 9 18V15H5.67C4.19768 14.9945 3.00548 13.8023 3 12.33V5.67C3.00548 4.19768 4.19768 3.00548 5.67 3H12.33C13.8023 3.00548 14.9945 4.19768 15 5.67V9ZM9 13V12C9 10.3431 10.3431 9 12 9H13V5.67C13 5.29997 12.7 5 12.33 5H5.67C5.29997 5 5 5.29997 5 5.67V12.33C5 12.7 5.29997 13 5.67 13H9Z"
          fill="white"
        />
        <mask id="mask0_77_743" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 9H18C19.6569 9 21 10.3431 21 12V18C21 19.6569 19.6569 21 18 21H12C10.3431 21 9 19.6569 9 18V15H5.67C4.19768 14.9945 3.00548 13.8023 3 12.33V5.67C3.00548 4.19768 4.19768 3.00548 5.67 3H12.33C13.8023 3.00548 14.9945 4.19768 15 5.67V9ZM9 13V12C9 10.3431 10.3431 9 12 9H13V5.67C13 5.29997 12.7 5 12.33 5H5.67C5.29997 5 5 5.29997 5 5.67V12.33C5 12.7 5.29997 13 5.67 13H9Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_77_743)">
          <rect width="24" height="24" fill="white" />
        </g>
      </g>
    </svg>
  );
};

export default CopyIcon;
