import React, { FC, Fragment } from 'react';
import { Box, Typography, Button, Dialog, Divider } from '@material-ui/core';
import { fontSizes, colors, gutters } from 'Theme/Variables';
import { BlueCheckIcon, CloseOtherIcon, SelectArrowDownIcon } from 'Components/Svg';
import { CardTransactionListTypeEnum } from 'Utils';
import { useLocalization } from 'Hooks';

import './SelectWithDialog.scss';

type SelectWithDialogProps = {
  label: string;
  text: string;
  dialogTitle?: string;
  bodyData?: any[];
  selectedValue?: any;
  onChange?: any;
  isOtherCard?: boolean;
};

const SelectWithDialog: FC<SelectWithDialogProps> = ({
  label,
  text,
  dialogTitle,
  bodyData,
  selectedValue,
  onChange,
  isOtherCard,
}) => {
  const [open, setOpen] = React.useState(false);

  const { web_max_transactions } = useLocalization();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (type: CardTransactionListTypeEnum, label: string) => {
    setOpen(false);
    onChange(type, label);
  };

  return (
    <div className="selectWithDialog">
      <Button
        className="selectWithDialog__btn"
        fullWidth
        variant="outlined"
        onClick={() => !isOtherCard && handleClickOpen()}
        itemID="selectFilterByMonthBtnTest"
      >
        <div className="selectWithDialog__row">
          <Box width={'100%'} display="flex" flexDirection="column" alignItems="baseline">
            <Typography variant="caption" align="left">
              <Box color={colors.checkboxLabel} fontSize={'11px'}>
                {label}
              </Box>
            </Typography>
            <Typography variant="h3">
              <Box fontWeight={600} fontSize={fontSizes.regular}>
                {text}
              </Box>
            </Typography>
          </Box>
          <SelectArrowDownIcon />
        </div>
      </Button>
      <Dialog keepMounted={false} open={open} onClose={handleClose}>
        <CloseOtherIcon onClick={handleClose} />
        <div className="dialogContainer">
          {dialogTitle && (
            <Box textAlign="center">
              <Typography variant="h5">
                <Box fontWeight={600} marginTop={-2} marginBottom={gutters.large}>
                  {dialogTitle}
                </Box>
              </Typography>
            </Box>
          )}
          {bodyData &&
            bodyData
              .filter((item) => item !== undefined && !item.hidden)
              .map((item, index) => (
                <Fragment key={index}>
                  <Box>
                    <Divider className="selectWithDialog__divider" />
                    <Box onClick={() => handleChange(item.value, item.label)} className="selectWithDialog__item-row">
                      <Typography variant="body1" className="dialogWithActions-textContainer" component="div">
                        <Box
                          color={item.value === selectedValue ? colors.darkPrimary : colors.secondary}
                          fontWeight={600}
                          fontSize={fontSizes.regular}
                          marginBottom={gutters.small}
                          marginTop={gutters.small}
                        >
                          {item.label}
                        </Box>
                      </Typography>
                      {selectedValue === item.value && <BlueCheckIcon />}
                    </Box>
                    {index === bodyData.length - 1 && <Divider />}
                  </Box>
                </Fragment>
              ))}
          {bodyData?.length === 5 && web_max_transactions == '6' && <Divider />}
        </div>
      </Dialog>
    </div>
  );
};
export default SelectWithDialog;
