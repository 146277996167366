import React, { FunctionComponent } from 'react';
import './Container.scss';

type ContainerProps = {
  children: JSX.Element | JSX.Element[];
};

const Container: FunctionComponent<ContainerProps> = ({ children }) => {
  return <div className="container">{children}</div>;
};

export default Container;
