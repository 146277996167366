import { FunctionComponent } from 'react';
import { useLocalization } from 'Hooks';
import { UsingPhotoDesktop, UsingPhotoMobile } from 'Assets/Images/usingPhoto';
import { PhotoErrorIcon, PhotoSuccessIcon } from 'Components/Svg';
import { Button, Dialog } from '@material-ui/core';
import './PhotoStepPopup.scss';

type DialogOTPProps = {
  isOpen: boolean;
  onClick?: () => void;
  buttonText?: string;
};

const PhotoStepPopup: FunctionComponent<DialogOTPProps> = ({ isOpen, onClick = () => {}, buttonText }) => {
  const {
    web_photo_rules_popup_title,
    web_photo_rules_popup_desc_new,
    web_photo_rules_popup_valid,
    web_photo_rules_popup_invalid,
    web_photo_rules_popup_btn,
  } = useLocalization();
  return (
    <Dialog open={isOpen} onClose={() => {}} keepMounted={false} disablePortal={true}>
      <div id="photo_intro">
        <h5>{web_photo_rules_popup_title}</h5>
        <p
          style={{
            marginTop: 20,
          }}
        >
          {web_photo_rules_popup_desc_new?.split('-')?.map((item) => {
            return <p>• {item}</p>;
          })}
        </p>

        <div className="introImagesWeb">
          <div className="errorPhoto">
            <img src={UsingPhotoDesktop.Item1} alt="" />
            <div className="error">
              <PhotoErrorIcon />
            </div>
            <span>{web_photo_rules_popup_invalid}</span>
          </div>
          <div className="errorPhoto">
            <img src={UsingPhotoDesktop.Item2} alt="" />
            <div className="error">
              <PhotoErrorIcon />
            </div>
            <span>{web_photo_rules_popup_invalid}</span>
          </div>
          <div className="errorPhoto">
            <img src={UsingPhotoDesktop.Item3} alt="" />
            <div className="error">
              <PhotoErrorIcon />
            </div>
            <span>{web_photo_rules_popup_invalid}</span>
          </div>
          <div className="errorPhoto">
            <img src={UsingPhotoDesktop.Item4} alt="" />
            <div className="error">
              <PhotoErrorIcon />
            </div>
            <span>{web_photo_rules_popup_invalid}</span>
          </div>
          <div className="successPhoto">
            <img src={UsingPhotoDesktop.Item5} alt="" />
            <div className="success">
              <PhotoSuccessIcon />
            </div>
            <span>{web_photo_rules_popup_valid}</span>
          </div>
        </div>
        <div className="introImagesMobil">
          <div className="errors">
            <div className="con">
              <div className="errorPhoto">
                <img src={UsingPhotoMobile.Item1} alt="" />
                <div className="error">
                  <PhotoErrorIcon />
                </div>
              </div>
              <div className="errorPhoto">
                <img src={UsingPhotoMobile.Item2} alt="" />
                <div className="error">
                  <PhotoErrorIcon />
                </div>
              </div>
              <div className="errorPhoto">
                <img src={UsingPhotoMobile.Item3} alt="" />
                <div className="error">
                  <PhotoErrorIcon />
                </div>
              </div>
              <div className="errorPhoto">
                <img src={UsingPhotoMobile.Item4} alt="" />
                <div className="error">
                  <PhotoErrorIcon />
                </div>
              </div>
            </div>
            <span>{web_photo_rules_popup_invalid}</span>
          </div>
          <div className="successPhoto">
            <img src={UsingPhotoMobile.Item5} alt="" />
            <div className="success">
              <PhotoSuccessIcon />
            </div>
            <span>{web_photo_rules_popup_valid}</span>
          </div>
        </div>
        <div className="button">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onClick}
            type="submit"
            itemID="confirmBtnTest"
          >
            {buttonText ?? web_photo_rules_popup_btn}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default PhotoStepPopup;
