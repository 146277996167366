import { FC } from 'react';
import { ChevronDarkRightIcon, TickIcon } from 'Components';
import { Box } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { maskCreditCardNumber } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  creditCardTransactionsStore,
  resetCommissionAmount,
  setActiveSelectedCreditCard,
  setLoadAmount,
  setUserTemporaryCreditCard,
} from 'Stores/CreditCardTransactions';
import { resetFormError } from 'Stores/Form';
import classNames from 'classnames';
import { useCreditCardType } from 'Hooks';

import './CreditCardItem.scss';

type CreditCardItemProps = {
  creditCardItem: any;
  hide: Function;
  isDialog: boolean;
};

const CreditCardItem: FC<CreditCardItemProps> = ({ creditCardItem, hide, isDialog }) => {
  const dispatch = useDispatch();

  //To show green border check if addedTemporary card is the same with selected card
  const { activeSelectedCreditCard } = useSelector(creditCardTransactionsStore);

  const { creditCardBrandUrl, creditCardLogoUrl } = useCreditCardType(creditCardItem);

  const decideIsSelected = () => {
    const activeCard = activeSelectedCreditCard?.Value1?.slice(0, 6) + activeSelectedCreditCard?.Value1?.slice(14, 16);
    const cardItem = creditCardItem?.Value1?.slice(0, 6) + creditCardItem?.Value1?.slice(14, 16);
    return activeCard === cardItem;
  };

  return (
    <div
      id="creditCardItem"
      onClick={() => {
        dispatch(setActiveSelectedCreditCard(creditCardItem));
        if (isDialog) {
          dispatch(resetCommissionAmount());
          dispatch(resetFormError());
          dispatch(setLoadAmount(0));
          dispatch(setUserTemporaryCreditCard(undefined));
        }
        hide();
      }}
    >
      <Box
        className={classNames({
          creditCardItem__container: true,
          'creditCardItem__container--active': decideIsSelected(),
        })}
      >
        <Box display="flex" alignItems="center">
          <Box marginRight={gutters.regular} width={66}>
            <img src={creditCardLogoUrl} alt="" className="creditCardItem--cardLogo" />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" flex={1}>
            {creditCardItem?.Name && <Box className="creditCardItem--title">{creditCardItem?.Name}</Box>}
            {creditCardItem?.Value1 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="creditCardItem--cardNumber">{maskCreditCardNumber(creditCardItem?.Value1)}</Box>
                <span className="creditCardItem--dot" />
                <img className="creditCardItem--brand" src={creditCardBrandUrl} alt="" />
              </Box>
            )}
          </Box>
        </Box>
        {isDialog && decideIsSelected() && <TickIcon />}
        {!isDialog && <ChevronDarkRightIcon />}
      </Box>
    </div>
  );
};

export default CreditCardItem;
