import * as React from 'react';

function BelbimWhiteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 83}
      height={props.width || 19}
      viewBox="0 0 83 19"
      {...props}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M0 16.337V2.119h5.237c3.772 0 5.665 1.216 5.665 3.648 0 1.285-.636 2.21-1.893 2.777 1.976.622 2.957 1.852 2.957 3.703 0 2.722-1.88 4.076-5.624 4.076H0v.014zm5.416-8.594c1.272 0 1.907-.498 1.907-1.479 0-1.077-.635-1.63-1.907-1.63H3.58v3.109h1.837zM3.58 13.739h2.058c.788 0 1.368-.082 1.741-.262.553-.249.83-.719.83-1.396 0-1.188-.857-1.782-2.584-1.782H3.579v3.44zm30.7 2.598V2.119h3.565v11.468h6.121v2.75zm11.29 0V2.119h5.236c3.772 0 5.665 1.216 5.665 3.648 0 1.285-.635 2.21-1.893 2.777 1.976.622 2.957 1.852 2.957 3.703 0 2.722-1.865 4.076-5.624 4.076H45.57v.014zm5.416-8.594c1.27 0 1.907-.484 1.907-1.479 0-1.077-.636-1.63-1.907-1.63h-1.838v3.109h1.838zm-1.852 5.996h2.059c.788 0 1.368-.082 1.74-.262.554-.249.83-.719.83-1.396 0-1.188-.857-1.782-2.584-1.782h-2.045v3.44zm13.817 2.598V2.382h3.772v1.506c1.133-1.175 2.487-1.755 4.049-1.755 1.754 0 2.943.732 3.565 2.21 1.395-1.478 2.915-2.21 4.573-2.21 2.722 0 4.09 1.547 4.09 4.656v9.534h-3.8V7.314c0-1.644-.566-2.473-1.7-2.473-1.036 0-1.948.636-2.707 1.907v9.589h-3.676V7.314c0-1.644-.566-2.473-1.7-2.473-1.05 0-1.948.636-2.694 1.907v9.589h-3.772zm-4.863 0v-9.63a.669.669 0 00.138.138 2.295 2.295 0 001.658.663c.663 0 1.216-.221 1.658-.65.055-.055.11-.11.152-.18h.055v9.659h-3.661zM57.59 4.164c0-.65.22-1.203.663-1.645a2.252 2.252 0 011.658-.677c.663 0 1.216.221 1.658.664.442.442.663.994.663 1.658 0 .663-.22 1.215-.663 1.658-.442.442-.995.65-1.658.65-.65 0-1.202-.222-1.658-.664-.442-.442-.663-.995-.663-1.644zm-44.174 4.67c1.133-2.363 3.303-4.421 5.886-5.817-1.215 1.354-2.376 2.888-2.887 4.463-1.63 5.057 1.934 8.83 7.654 8.47 3.289-.207 6.77-1.658 9.34-3.883-2.763 4.035-8.635 6.702-13.775 6.273-5.707-.483-8.498-4.739-6.218-9.506z" />
        <path d="M18.21 7.024c.913-1.907 2.668-3.565 4.754-4.698-.981 1.092-1.741 2.308-2.142 3.593-1.312 4.076 1.368 7.143 5.983 6.853 2.653-.166 5.375-1.423 7.447-3.22-2.224 3.248-6.88 5.486-11.026 5.14-4.615-.386-6.853-3.813-5.015-7.668z" />
        <path d="M27.745 6.775c-1.493 1.382-3.013 1.949-4.021 2.073.635-.387 1.907-1.12 2.777-2.225 1.63-2.072 1.81-4.435.262-5.471-.898-.594-2.127-.567-3.33-.042C25.12-.23 27.676-.41 28.879.862c1.34 1.436.607 4.13-1.133 5.913z" />
      </g>
    </svg>
  );
}

export default BelbimWhiteIcon;
