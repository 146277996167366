import * as React from 'react';

function AvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="avatarIcon"
      {...props}
    >
      <defs>
        <path
          id="tyywv0cnxa"
          d="M12 13c3.866 0 7 3.134 7 7 0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1 0-3.866 3.134-7 7-7zm0-10c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-462 -329) translate(446 305) translate(16 24)">
              <path d="M0 0H24V24H0z" />
              <mask id="nuwpa9o9bb" fill="#fff">
                <use xlinkHref="#tyywv0cnxa" />
              </mask>
              <g fill="#1AAADC" mask="url(#nuwpa9o9bb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AvatarIcon;
