import { FC, useEffect, useRef, useState } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Radio } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { Adress } from './Adress';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { AdressList } from './AdressList';
import { ApplicationProcessState, applicationStore, setApplicationData, setDeliveryId } from 'Stores/CardApplication';

import ApplicationCenters from './ApplicationCenter/ApplicationCenters';
import './DeliveryStep.scss';
import { UniversityStep } from './UniversityStep';

type DeliveryStepProps = {};

const DeliveryStep: FC<DeliveryStepProps> = () => {
  const dispatch = useDispatch();

  const lastSubmitFn = useRef<(() => void) | undefined>(undefined);

  const [isAddNewAdress, setIsAddNewAdress] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<object | undefined>(undefined);

  const userData: UserState = useSelector(userStore);
  const store: ApplicationProcessState = useSelector(applicationStore);

  const reasonId = store.activeApplicationData?.ApplicationReasonId;

  const institutionType = store?.institutionType;

  const cardApplicationStatus = store?.cardApplicationStatus;

  const activeCardType = store?.applicationCard;
  const isMustManipulationShippingType = reasonId === 595 && activeCardType?.ProductCode === 'UCT_ANA';

  const shippingType = isMustManipulationShippingType ? 3 : activeCardType?.ShippingType;
  const adressList = userData.adressList?.filter((address) => address?.AddressType !== 7);
  const city = Number(store?.activeApplicationData?.AddressDetail?.CityCode);
  const onlyApplicationCenter = shippingType === 3 ? false : shippingType === 1 ? true : false;

  const { deliveryId, activeApplicationData, activeCardShippingFee, activeUpStateShippingFee } = store;

  const {
    web_delivery_method,
    web_come_to_my_address,
    web_shipping_cost,
    web_reference_center,
    web_reference_center_desc,
    web_shipping_address_title,
    web_choose_reference_center,
    web_university_title,
    web_university_desc,
    web_university_title2,
  } = useLocalization();

  const selection = [
    {
      name: web_come_to_my_address,
      id: 0,
      content: `${web_shipping_cost} ${city === 34 ? activeCardShippingFee : activeUpStateShippingFee} TL`,
      visible: shippingType === 2 || shippingType === 3 || shippingType === 4,
    },
    {
      name: web_reference_center,
      id: 1,
      content: web_reference_center_desc,
      visible: cardApplicationStatus !== 6 && (shippingType === 1 || shippingType === 3 || shippingType === 4),
    },
    {
      name: web_university_title,
      id: 2,
      content: web_university_desc,
      visible:
        (institutionType === 'Üniversite' ||
          institutionType === 'Üniversite AÖF' ||
          activeApplicationData?.OrganizationTypeId === 10000 || // 10000: Üniversite
          activeApplicationData?.OrganizationTypeId === 35269065) && // 35269065: Üniversite AÖF
        shippingType === 4,
    },
  ];

  const fillExportedData = (values: any) => {
    dispatch(
      setApplicationData({
        AddressDetail: {
          AddressType: values.adressType,
          CityCode: values.cityCode,
          TownCode: values.townCode,
          Town: values.town,
          District: values.district,
          Street: values.street,
          AddressDetail: values.adressDetail,
          PostalCode: values.postCode,
          CountryCode: 90,
        },
      })
    );
  };

  useEffect(() => {
    if (deliveryId === 1) {
      setIsAddNewAdress(false);
      setEditAddress(undefined);
    }
  }, [deliveryId]);

  useEffect(() => {
    if (onlyApplicationCenter) {
      dispatch(setDeliveryId(1));
    } else {
      dispatch(setDeliveryId(shippingType === 2 || shippingType === 3 ? 0 : 1));
    }
  }, [shippingType]);

  return (
    <div id="deliveryStep">
      <Typography variant="h3">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {web_delivery_method}
        </Box>
      </Typography>

      {selection.map((e) => e.visible && <DeliveryTypes key={e.id} e={e} deliveryId={deliveryId} />)}

      <Typography variant="h3">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {deliveryId === 0
            ? web_shipping_address_title
            : deliveryId === 1
            ? web_choose_reference_center
            : web_university_title2}
        </Box>
      </Typography>

      {(deliveryId === 1 || onlyApplicationCenter) && (
        <ApplicationCenters
          hideNextButton={!!editAddress}
          fillExportedData={fillExportedData}
          applicationData={activeApplicationData}
          onEditAddress={(address, submitCallback) => {
            setEditAddress(address);
            lastSubmitFn.current = submitCallback;
          }}
        />
      )}

      {deliveryId === 2 && (
        <UniversityStep
          hideNextButton={!!editAddress}
          fillExportedData={fillExportedData}
          onEditAddress={(address, submitCallback) => {
            setEditAddress(address);
            lastSubmitFn.current = submitCallback;
          }}
        />
      )}

      {((deliveryId === 0 && adressList?.length === 0) || isAddNewAdress || editAddress || adressList === null) &&
        !onlyApplicationCenter && (
          <Adress onSubmit={lastSubmitFn.current} address={editAddress} fillExportedData={fillExportedData} />
        )}

      {deliveryId === 0 &&
        adressList &&
        adressList.length > 0 &&
        !isAddNewAdress &&
        !editAddress &&
        !onlyApplicationCenter && (
          <AdressList
            adressList={adressList}
            fillExportedData={fillExportedData}
            setIsAddNewAdress={setIsAddNewAdress}
            onEditChangeAddress={() => {
              setEditAddress(activeApplicationData?.AddressDetail);
              lastSubmitFn.current = undefined;
            }}
          />
        )}
    </div>
  );
};

type DeliveryTypesProps = { e: any; deliveryId: number };

const DeliveryTypes: FC<DeliveryTypesProps> = ({ e, deliveryId }) => {
  const dispatch = useDispatch();

  return (
    <div key={e.id} className="deliveryStep__check" onClick={() => dispatch(setDeliveryId(e.id))}>
      <Radio checked={deliveryId === e.id} color="primary" />
      <div className="deliveryStep__check__col">
        <Typography variant="h3">
          <Box fontWeight={600} fontSize={fontSizes.regular}>
            {e.name}
          </Box>
        </Typography>
        <Typography variant="h3">
          <Box color={colors.checkboxLabel} mt={1} fontSize={fontSizes.small}>
            {e.content}
          </Box>
        </Typography>
      </div>
    </div>
  );
};

export default DeliveryStep;
