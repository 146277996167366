import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { useSelector } from 'react-redux';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';

const useCardName = (productCode: string, subProductCode: any = null, parrent: boolean = false): string | undefined => {
  const { istanbulCardTypeList } = useSelector(storeCardTransactions) as CardTransactionsState;

  const requestCard = istanbulCardTypeList?.find((item: any) => {
    return (
      item.ProductCode === productCode &&
      (!parrent ||
        (parrent && (subProductCode ? item.SubProductCode === subProductCode : item.SubProductCode === null)))
    );
  });

  return requestCard?.ProductName;
};

export default useCardName;
