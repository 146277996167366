import { getApplicationStatusBodyData } from 'Config/ServiceConfig';
import { axiosHelper } from 'Services/AxiosHelper';

const getApplicationStatus = async (data: getApplicationStatusBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      data,
      isFibaBankgetApp: true,
    })
  ).data;

export default getApplicationStatus;
