import { FC, useEffect, useState } from 'react';
import { DialogWithActions, LocationIcon, SelectArrowDownIcon } from 'Components';
import { useCustomerLocation, useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { MapsState, mapStore, onGetApplicationCenterListRequest } from 'Stores/Maps';
import { ApplicationCenterListItemModel } from 'Models';
import { ApplicationsCenterDialog } from './ApplicationsCenterDialog';
import { onAddApplicationBodyData } from 'Config';

import './ApplicationCenters.scss';
import { closeIbmPoints } from 'Utils';
import {
  applicationStore,
  closeApplicationEmtpyAdressError,
  incStep,
  openApplicationEmtpyAdressError,
  openEmptyStreetError,
  setApplicationData,
  setDeliveryId,
} from 'Stores/CardApplication';
import { AccountState, getTownListRequest, storeAccount } from 'Stores/Account';
import { addressInvalid } from '../helper';

type ApplicationCentersProps = {
  hideNextButton?: boolean;
  applicationData?: onAddApplicationBodyData;
  fillExportedData: (e: any) => void;
  onEditAddress: (address: any, submitCallback: () => void) => void;
};

const ApplicationCenters: FC<ApplicationCentersProps> = ({
  applicationData,
  fillExportedData,
  onEditAddress,
  hideNextButton = false,
}) => {
  const dispatch = useDispatch();

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [currentApplicationCenter, setCurrentApplicationCenter] = useState<
    ApplicationCenterListItemModel | undefined
  >();

  const {
    web_reference_center_desc,
    web_reference_center_title,
    web_btn_okay,
    web_card_incorrect_address_alert_title,
    web_card_incorrect_address_alert_desc,
  } = useLocalization();

  const accountStore: AccountState = useSelector(storeAccount);
  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const mapData: MapsState = useSelector(mapStore);

  const { activeApplicationData, applicationEmtpyAdressError } = useSelector(applicationStore);
  const { ApplicationCenterId } = activeApplicationData!;

  const { customerNumber, adressList } = userData;
  const { applicationCenterList } = mapData;
  const { townList } = accountStore;

  // TODO: Adres tipleri enum olmalı
  const contactAddress = adressList?.find((it) => it.AddressType === 9);
  const { latitude, longitude } = useCustomerLocation();

  const getCurrentApplicationCenter = () => {
    const item = applicationCenterList?.find(
      (item) => item.ApplicationCenterId === applicationData?.ApplicationCenterId
    );
    setCurrentApplicationCenter(item);
  };

  useEffect(() => {
    const contactAdress = adressList?.find((item) => item.AddressType === 9);
    dispatch(getTownListRequest(String(contactAdress?.CityCode)));
    if (applicationData?.ApplicationCenterId) {
      getCurrentApplicationCenter();
      dispatch(setDeliveryId(1));
    }
    dispatch(onGetApplicationCenterListRequest({ CustomerNumber: customerNumber }));
  }, []);

  useEffect(() => {
    setIsOpenDialog(false);
    dispatch(setApplicationData({ ApplicationCenterId: currentApplicationCenter?.ApplicationCenterId }));
  }, [currentApplicationCenter]);

  const handleSubmit = () => {
    const contactAdress = adressList?.find((item) => item.AddressType === 9);
    const townCode = townList?.find(
      (item) => item.ilceAdi.toLocaleLowerCase('tr-TR') === contactAdress?.Town.toLocaleLowerCase('tr-TR')
    );
    if (addressInvalid(contactAdress)) {
      dispatch(openApplicationEmtpyAdressError());
      onEditAddress({ ...contactAddress }, handleSubmit);
    } else if (contactAdress && !contactAdress?.Street) {
      dispatch(openEmptyStreetError());
      return;
    } else {
      fillExportedData({
        cityCode: contactAdress?.CityCode,
        town: contactAdress?.Town,
        district: contactAdress?.District,
        street: contactAdress?.Street,
        adressDetail: contactAdress?.AddressDetail,
        adressType: 9,
        postCode: contactAdress?.PostalCode,
      });
      dispatch(incStep());
    }
  };

  const getList = () => {
    const data = closeIbmPoints({
      lat: latitude === 0 ? 41.00527 : latitude,
      lng: longitude === 0 ? 28.97696 : longitude,
      pointList: applicationCenterList,
    });
    return data;
  };

  return (
    <div id="applicationCenters">
      <Box onClick={() => setIsOpenDialog(true)} className="applicationCenters__default-pick">
        <LocationIcon />
        <Box ml={gutters.small} className="applicationCenters__adress-detail">
          <Typography variant="h3">
            <Box fontWeight={600} fontSize={fontSizes.regular}>
              {currentApplicationCenter ? currentApplicationCenter.ApplicationCenterName : web_reference_center_title}
            </Box>
          </Typography>
          <Typography variant="h3">
            <Box mt={1} color={colors.checkboxLabel} fontSize={fontSizes.small}>
              {currentApplicationCenter ? currentApplicationCenter.Description : web_reference_center_desc}
            </Box>
          </Typography>
        </Box>
        <SelectArrowDownIcon />
      </Box>
      <ApplicationsCenterDialog
        open={isOpenDialog}
        close={setIsOpenDialog}
        currentApplicationCenter={currentApplicationCenter}
        setCurrentApplicationCenter={setCurrentApplicationCenter}
        applicationCenterList={getList()}
      />
      <Box mt={gutters.small} />
      {!hideNextButton ? (
        <Button
          onClick={handleSubmit}
          disabled={!ApplicationCenterId}
          className={!ApplicationCenterId ? 'applicationCenters__disable' : ''}
          color="secondary"
          fullWidth
          variant="contained"
          itemID="nextCardApplicationCenterBtnTest"
        >
          Devam et
        </Button>
      ) : null}

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeApplicationEmtpyAdressError()),
        }}
        open={applicationEmtpyAdressError}
        hide={() => dispatch(closeApplicationEmtpyAdressError())}
        title={web_card_incorrect_address_alert_title}
        text={web_card_incorrect_address_alert_desc}
      />
    </div>
  );
};
export default ApplicationCenters;
