import * as React from 'react';

function CloseCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="closeCircleIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM14.71 13.29C14.8993 13.4778 15.0058 13.7334 15.0058 14C15.0058 14.2666 14.8993 14.5222 14.71 14.71C14.5222 14.8993 14.2666 15.0058 14 15.0058C13.7334 15.0058 13.4778 14.8993 13.29 14.71L12 13.41L10.71 14.71C10.5222 14.8993 10.2666 15.0058 10 15.0058C9.73336 15.0058 9.47777 14.8993 9.29 14.71C9.10069 14.5222 8.9942 14.2666 8.9942 14C8.9942 13.7334 9.10069 13.4778 9.29 13.29L10.59 12L9.29 10.71C8.89788 10.3179 8.89788 9.68212 9.29 9.29C9.68212 8.89788 10.3179 8.89788 10.71 9.29L12 10.59L13.29 9.29C13.6821 8.89788 14.3179 8.89788 14.71 9.29C15.1021 9.68212 15.1021 10.3179 14.71 10.71L13.41 12L14.71 13.29Z"
        fill="#383C52"
      />
    </svg>
  );
}

export default CloseCircleIcon;
