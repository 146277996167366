import { Box, Typography } from '@material-ui/core';
import { WhiteTickIcon, AlertIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hideToastify, storeTostify } from 'Stores/Toastify/slices';
import { gutters, fontSizes, colors } from 'Theme/Variables';

export type ToastMessageProps = {
  title?: string;
  desc?: string;
  type?: string;
};

const ToastMessage: FC<ToastMessageProps> = ({ title, desc, type }) => {
  const iconSize = 32;

  const Icon = type === 'success' ? WhiteTickIcon : AlertIcon;

  return (
    <Box id="toastify-container">
      <Box display="flex" alignItems="center">
        <Icon width={iconSize} />
        <Typography variant="body1" align="center" component="span">
          <Box fontSize={fontSizes.small} paddingLeft={gutters.small}>
            {title}
          </Box>
        </Typography>
      </Box>
      {!!desc && (
        <Box color={colors.checkboxLabel} paddingLeft={gutters.small} display="flex">
          <Box width={iconSize} />
          <Typography variant="body1" align="center" component="span">
            {desc}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ToastMessageWrapper = () => {
  const toastifyState = useSelector(storeTostify);
  const dispatch = useDispatch();
  const {
    web_success_hes_guncelleme,
    web_msg_change_password_success,
    web_account_contact_info_update_text,
    web_card_settings_make_favourite_card_success,
    web_card_settings_update_card_name_success,
    web_msg_succes_delete_credit_card,
    web_msg_succes_add_credit_card,
    msg_web_iban_added,
    msg_web_iban_updated,
    msg_web_address_added,
    msg_web_address_updated,
    msg_web_job_added,
    msg_web_job_updated,
    msg_web_phone_number_added,
    msg_web_phone_number_updated,
    web_delete_address_success_msg,
    web_application_completed,
    web_acikriza_success,
  } = useLocalization();

  const { toastMessage, showToast } = toastifyState;

  const toastMessageDecide = () => {
    switch (toastMessage) {
      case 'web_success_hes_guncelleme':
        return web_success_hes_guncelleme;
      case 'web_msg_change_password_success':
        return web_msg_change_password_success;
      case 'web_account_contact_info_update_text':
        return web_account_contact_info_update_text;
      case 'web_card_settings_make_favourite_card_success':
        return web_card_settings_make_favourite_card_success;
      case 'web_card_settings_update_card_name_success':
        return web_card_settings_update_card_name_success;
      case 'web_msg_succes_delete_credit_card':
        return web_msg_succes_delete_credit_card;
      case 'web_msg_succes_add_credit_card':
        return web_msg_succes_add_credit_card;
      case 'msg_web_iban_added':
        return msg_web_iban_added;
      case 'msg_web_iban_updated':
        return msg_web_iban_updated;
      case 'msg_web_address_added':
        return msg_web_address_added;
      case 'msg_web_address_updated':
        return msg_web_address_updated;
      case 'msg_web_job_added':
        return msg_web_job_added;
      case 'msg_web_job_updated':
        return msg_web_job_updated;
      case 'msg_web_phone_number_added':
        return msg_web_phone_number_added;
      case 'msg_web_phone_number_updated':
        return msg_web_phone_number_updated;
      case 'web_delete_address_success_msg':
        return web_delete_address_success_msg;
      case 'web_application_completed':
        return web_application_completed;
      case 'web_acikriza_success':
        return web_acikriza_success;
      default:
        return toastMessage;
    }
  };

  const options = {
    type: toastifyState.type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
  };

  useEffect(() => {
    if (showToast) {
      toast(<ToastMessage title={toastMessageDecide()} type={options.type} />, options);
    }
    setTimeout(() => {
      dispatch(hideToastify());
    }, 1000);
  }, [showToast]);
  return <Fragment></Fragment>;
};

export default ToastMessageWrapper;
