import { GetPinsBodyData } from 'Config/ServiceConfig';
import { GetPinsModel } from 'Models';
import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';

const command = 'cms.getPins';

const getPins = async (param: GetPinsBodyData): Promise<GetPinsModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: param,
      isPinUrl: true,
    })
  ).data;

export default getPins;
