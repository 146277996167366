import * as React from 'react';

function ChevronBlueRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      {...props}
    >
      <defs>
        <path
          id="qaijlgds7a"
          d="M8.293 5.293c.36-.36.928-.388 1.32-.083l.094.083 6 6c.36.36.388.928.083 1.32l-.083.094-6 6c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L13.585 12 8.293 6.707c-.36-.36-.388-.928-.083-1.32l.083-.094z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-491 -561) translate(155 536) translate(336 25.385)">
              <path d="M0 0H24V24H0z" />
              <mask id="my10j3w7wb" fill="#fff">
                <use xlinkHref="#qaijlgds7a" />
              </mask>
              <g fill="#1AAADC" mask="url(#my10j3w7wb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChevronBlueRightIcon;
