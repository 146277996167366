import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import { fontSizes } from 'Theme/Variables';

import './DeleteItems.scss';

type DeleteItemProps = {
  title: string;
  func: () => void;
  itemID: string;
};

const DeleteItem: FC<DeleteItemProps> = ({ title, func, itemID }) => {
  return (
    <div onClick={func} id="card-delete-item" itemID={itemID}>
      <Typography variant="h3">
        <Box fontSize={fontSizes.regular} fontWeight={600}>
          {title}
        </Box>
      </Typography>
    </div>
  );
};

export default DeleteItem;
