import { createMuiTheme } from '@material-ui/core';
import { styleNumParse } from 'Utils';
import { colors, deviceWidth, fontSizes, gutters, radius } from './Variables';

export const materialUITheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      secondary: colors.checkboxLabel,
    },
    error: {
      main: colors.errorText,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: styleNumParse(deviceWidth.tiny),
      md: styleNumParse(deviceWidth.small),
      lg: styleNumParse(deviceWidth.regular),
      xl: styleNumParse(deviceWidth.big),
    },
  },
  typography: {
    fontFamily: 'Mark Pro',
    h2: {
      fontSize: fontSizes.bigger,
      color: colors.secondary,
    },
    h3: {
      fontSize: fontSizes.regularBig,
      color: colors.secondary,
    },
    h4: {
      fontSize: fontSizes.largest,
      color: colors.secondary,
      letterSpacing: '-1.83px',
    },
    h5: {
      fontSize: fontSizes.big,
      color: colors.secondary,
    },
    h6: {
      fontSize: fontSizes.regular,
      color: colors.secondary,
    },
    subtitle1: {
      color: colors.secondary,
      fontSize: fontSizes.tiny,
      lineHeight: 1.22,
      letterSpacing: -0.2,
    },
    subtitle2: {
      fontSize: fontSizes.small,
      color: colors.darkGray,
    },
    body2: {
      fontSize: fontSizes.smaller,
      color: '#fff',
    },
  },
  shape: {
    borderRadius: styleNumParse(radius.large),
  },
  overrides: {
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 'calc(85%)',
        overflowX: 'hidden',
      },
      paperScrollBody: {
        position: 'relative',
      },
    },
    MuiFilledInput: {
      input: {
        backgroundColor: 'white',
        border: `2px ${colors.lightGray} solid`,
        borderRadius: styleNumParse(radius.large),
        padding: '20px 14px 7px 14px',
        color: colors.input,
        fontWeight: 'bold',

        '&::placeholder': {
          color: colors.placeholder,
          fontWeight: 'normal',
        },
      },
      inputAdornedEnd: {
        paddingRight: 40,
      },
      root: {
        backgroundColor: 'white',
        '&$focused': {
          backgroundColor: 'white',
        },
        '&:hover': {
          backgroundColor: 'white',
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.gray,
        width: '100%',
        '&$focused': {
          color: colors.gray,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        top: -2,
        left: 4,
      },
    },
    MuiFormControl: {
      root: {
        overflowX: 'hidden',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: styleNumParse(fontSizes.small),
        color: colors.checkboxLabel,
      },
    },

    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&$disabled': {
          backgroundColor: colors.gray,
          color: 'white',
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.secondary,
        },
      },
      outlinedSecondary: {
        backgroundColor: 'white',
        border: `2px ${colors.lightGray} solid`,
        borderRadius: '14px',
        color: colors.secondary,
        padding: '11px 16px',
        fontWeight: 'bold',
        '&:hover': {
          border: `2px ${colors.lightGray} solid`,
          backgroundColor: 'white',
        },
      },
      root: {
        textTransform: 'unset',
        fontSize: fontSizes.regular,
        paddingTop: 11,
        paddingBottom: 11,
        border: '1px solid transparent',
        transition: 'unset',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0 12px 0 0px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: styleNumParse(fontSizes.small) - 1,
        marginTop: gutters.small,
      },
    },
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiCheckbox: {
      disableRipple: true,
      disableFocusRipple: true,
    },
    MuiButton: {
      disableFocusRipple: true,
      disableElevation: true,
      disableTouchRipple: true,
    },
  },
});
