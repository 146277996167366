import { Link } from '@material-ui/core';
import { IstanbulKartLogo, LoginIcon } from 'Components';
import { useLocalization, useLanguage } from 'Hooks';
import { useDispatch } from 'react-redux';
import { FunctionComponent, memo } from 'react';
import { setLanguage } from 'Stores/App';
import { WelcomeDrawer } from './WelcomeDrawer';
import { localStorageKeys } from 'Config';
import { routePath } from 'Navigator/routes';
import { generateLanguageParam } from 'Utils';
import { onInıtDataRequest } from 'Stores/App';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { onGetFaqRequest } from 'Stores/Account';

import './WelcomeHeader.scss';

export type WelcomeHeaderMenuItemType = {
  title: string;
  onClick: () => void;
  Icon?: any;
  itemID: string;
  isActive?: boolean;
};

const WelcomeHeader = memo(() => {
  const dispatch = useDispatch();
  const {
    link_btn_language,
    web_footer_bize_ulasin,
    web_footer_sik_sorulan_sorular,
    web_link_btn_giris_yap,
  } = useLocalization();

  const i18n = useLanguage();

  const history = useHistory();

  const { pathname } = useLocation();

  const handleLanguageChange = () => {
    const language = localStorage.getItem(localStorageKeys.i18nextLng) === 'en' ? 'tr' : 'en';
    i18n.changeLanguage(language);
    dispatch(setLanguage());
    dispatch(onInıtDataRequest(generateLanguageParam()));
    dispatch(onGetFaqRequest());
  };

  const menuItems: WelcomeHeaderMenuItemType[] = [
    {
      title: link_btn_language,
      onClick: () => handleLanguageChange(),
      itemID: 'languageBtnTest',
    },
    {
      title: web_footer_bize_ulasin,
      onClick: () => history.push(routePath.welcomeContact),
      itemID: 'footerReactUsBtnTest',
      isActive: pathname === routePath.welcomeContact,
    },
    {
      title: web_footer_sik_sorulan_sorular,
      onClick: () => history.push(routePath.welcomeFaq),
      itemID: 'footerFaqBtnTest',
      isActive: pathname === routePath.welcomeFaq,
    },
    {
      title: web_link_btn_giris_yap,
      onClick: () => dispatch(push(routePath.login)),
      itemID: 'loginBtnTest',
    },
  ];

  const handleLogoClick = () => {
    if (pathname === routePath.welcome) {
      window.location.reload();
    } else {
      history.push(routePath.welcome);
    }
  };

  return (
    <div id="headerWelcome">
      <IstanbulKartLogo width={220} onClick={handleLogoClick} />
      <WelcomeDrawer menuItems={menuItems} isDrawerLinks />
      <ul>
        {menuItems.map(({ title, onClick, itemID, isActive }, index) => (
          <MenuItem
            key={index}
            title={title}
            onClick={onClick}
            isLogin={index === menuItems.length - 1}
            itemID={itemID}
            isActive={isActive}
          />
        ))}
      </ul>
    </div>
  );
});

const MenuItem: FunctionComponent<WelcomeHeaderMenuItemType & { isLogin: boolean }> = memo(
  ({ title, onClick, isLogin, itemID, isActive }) => (
    <>
      <li>
        <Link underline="none" onClick={onClick} itemID={itemID} className={isActive ? 'welcomeHeaderActiveItem' : ''}>
          <span>
            {title}
            {isLogin && <LoginIcon />}
          </span>
        </Link>
      </li>
    </>
  )
);

export default WelcomeHeader;
