import { PayloadAction } from '@reduxjs/toolkit';
import {
  checkVposReqBodyData,
  formNames,
  getBinListReqBodyData,
  masterPassDeleteCardReqBodyData,
  masterPassRegisterReqBodyData,
  onGetCommissionByBinNumberReqBodyData,
  vsPosBeldimReqBodyData,
} from 'Config';
import {
  MasterpassTokenModel,
  OnGetCommissionByBinNumberModel,
  MasterPassGetCardList,
  GetBinListModel,
  CheckVposPaymentModel,
  ResultType,
  ResendCustomerCardModel,
} from 'Models';
import {
  checkVposPayment,
  checkVposPaymentForMobile,
  onGenerateTLVToken,
  onGetCommissionByBinNumber,
  resendCustomerCard,
} from 'Services/Queries';
import {
  onGetCreditCardListSuccess,
  onGetMasterpassTokenRequest,
  onGetMasterpassTokenRequestFail,
  onGetMasterpassTokenRequestSuccess,
  onGetCommissionByBinNumberRequest,
  onGetCommissionByBinNumberRequestSuccess,
  onGetCommissionByBinNumberRequestFail,
  onGetCreditCardListFail,
  onDeleteMasterPassSuccess,
  onDeleteMasterPassRequest,
  onDeleteMasterPassFail,
  vsPosBelbimPaymentRequest,
  vsPosBelbimPaymentRequestSuccess,
  vsPosBelbimPaymentRequestFail,
  open3DSecureModal,
  getBinListRequest,
  getBinListRequestSuccess,
  getBinListRequestFail,
  checkPaymentStatusRequest,
  close3DSecureModal,
  checkPaymentStatusRequestResult,
  setMasterPassAccountStatus,
  creditCardTransactionsStore,
  setLoadingForMasterpass,
  onGetMasterpassForMobileRequest,
  onGetMasterpassForMobileRequestSuccess,
  onGetMasterpassForMobileRequestFail,
  setUserTemporaryCreditCard,
  setTempMasterPassCardActive,
  setTempMasterPassCard,
} from './slices';
import { put, takeLatest, call, take, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import {
  getActiveLanguageForMasterPass,
  isBelbimRequestSuccess,
  maskCreditCardNumber,
  MasterPassAccountStatusEnum,
  MasterPassFormEnum,
  MasterpassServicess,
  MasterPassTokenEnum,
  sendEVAMCustomEvent,
  turkishCharacterNormalize,
} from 'Utils';
import MasterPassServices from 'Utils/MasterPassServices';
import { closeOTPDialog, showOTPDialog } from 'Stores/Dialog';
import { setFormError } from 'Stores/Form';
import { CreditCardTransactionsState, MasterPassTokenActionType } from './types';
import { storeWelcome, WelcomeState } from 'Stores/Welcome';
import { goBack, push, RouterState, replace } from 'connected-react-router';
import { masterPassListCardNormalize } from 'Normalize';
import { UserState, userStore } from 'Stores/User';
import { showToastify } from 'Stores/Toastify';
import { VsPosBelbimReq } from 'Services/Mutations';
import { getBinList } from 'Services/Queries';
import { staticErrorMessage } from 'Stores/App/sagas';
import {
  CardTransactionsState,
  setAutomaticInstructionStep,
  setUploadSubscriptionStep,
  setUploadTLStep,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { routePath } from 'Navigator/routes';
import { BinItem } from 'Models/GetBinListModel';
import { ApplicationProcessState, applicationStore, resetCardApplicationStatus } from 'Stores/CardApplication';
import EVAMSdkEventTypes from 'Utils/EVAMSdkEventTypes';
import moment from 'moment';

const resultChannel = channel();

function* handleGetMasterpassToken({ payload }: MasterPassTokenActionType | any) {
  const store: WelcomeState = yield select(storeWelcome);
  const userData: UserState = yield select(userStore);
  const cardStore: CreditCardTransactionsState = yield select(creditCardTransactionsStore);
  const isMiniApp = userData.isMiniAppLogin;
  const cardState: CardTransactionsState = yield select(storeCardTransactions);
  const { isUserInUploadTLProcess, isUserInUploadSubscriptionProcess, isUserInAutomatiInstructionProcess } = cardState;
  const activeSelectedCard = payload.activeSelectedCreditCard;
  delete payload.activeSelectedCreditCard;

  const { request } = payload;
  const { Cellphone, CountryCode } = store;
  const { masterPassAccountStatus } = cardStore;

  const phone = `${CountryCode.replace('+', '')}${Cellphone}`;
  let requestQuery = payload;
  if (payload.PanMasked) {
    requestQuery = { ...payload, PanMasked: maskCreditCardNumber(payload?.PanMasked, false, true).replaceAll(' ', '') };
  }

  if (cardState.isDigitalActive) {
    delete requestQuery.PmtAccountId;
    delete requestQuery.MifareId;
    requestQuery.RefTrnType = 6;
  }

  try {
    const response: MasterpassTokenModel = yield call(() => onGenerateTLVToken(requestQuery));
    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (
      requestSuccess ||
      payload.TokenType === MasterPassTokenEnum.OTP ||
      payload.TokenType === MasterPassTokenEnum.RESEND_OTP
    ) {
      switch (payload.TokenType) {
        case MasterPassTokenEnum.CARD_LIST:
          MasterpassServicess.listCards(phone, response.data.Token, (_: any, res: MasterPassGetCardList) => {
            if (res.responseCode == '0000' || res.responseCode == '') {
              const data = res.cards;
              data && resultChannel.put(onGetCreditCardListSuccess(data));
              resultChannel.put(setLoadingForMasterpass(false));
            } else {
              resultChannel.put(onGetCreditCardListFail());
            }
          });
          break;
        case MasterPassTokenEnum.CHECK_MASTERPASSS:
          const checkData = request.data;
          checkData.token = response.data.Token;
          checkData.userId = phone;
          MasterPassServices.checkMasterPass(MasterPassFormEnum.CHECK_MASTERPASSS, checkData, (_: any, res: any) => {
            resultChannel.put(setMasterPassAccountStatus(res.accountStatus));
          });
          break;
        case MasterPassTokenEnum.REGISTER_PURCHASE:
          const cardState: CardTransactionsState = yield select(storeCardTransactions);
          const userInUploadTLProcess = cardState.isUserInUploadTLProcess;
          const userInUploadSubscriptionProcess = cardState.isUserInUploadSubscriptionProcess;
          const userInAutomatiInstructionProcess = cardState.isUserInAutomatiInstructionProcess;
          const data = request.data;
          data.token = response.data && response.data.Token;
          data.referenceNo = response.data.ReqRefNumber;
          data.msisdn = phone;
          data.orderNo = response.data.PaymentNumber;
          if (activeSelectedCard) {
            data.rtaPan = activeSelectedCard.Value1;
            data.cvc = activeSelectedCard.Cvv;
            data.expiryDate = activeSelectedCard.ExpireDate.split('/').reverse().join('');
            data.sendSms = 'N';
            delete data.sendSmsMerchant;
          }
          MasterPassServices.purchase('purchase-form', data, (_: any, res: any) => {
            if (res.responseCode === '5010') {
              const newUrl = res.url3D + '&returnUrl=' + response.data.ReturnUrl;
              const vsPosBelbimPaymentResult: ResultType = {
                PaymentNumber: response.data.PaymentNumber,
                url: newUrl,
              };
              resultChannel.put(vsPosBelbimPaymentRequestSuccess(vsPosBelbimPaymentResult));
              resultChannel.put(open3DSecureModal());
              resultChannel.put(setLoadingForMasterpass(false));
            } else {
              resultChannel.put(setLoadingForMasterpass(false));
              if (userInUploadTLProcess) {
                resultChannel.put(
                  setFormError({
                    errorCode: res.responseDescription,
                    formName: formNames.addBalanceToCard,
                  })
                );
              }
              if (userInUploadSubscriptionProcess) {
                resultChannel.put(
                  setFormError({
                    errorCode: res.responseDescription,
                    formName: formNames.uploadSubscription,
                  })
                );
              }
              if (userInAutomatiInstructionProcess) {
                resultChannel.put(
                  setFormError({
                    errorCode: res.responseDescription,
                    formName: formNames.loadAutomaticInstructions,
                  })
                );
              }
            }
          });
          break;
        case MasterPassTokenEnum.DELETE_CARD:
          resultChannel.put(onDeleteMasterPassRequest());
          //kartı sil
          const deleteCard: masterPassDeleteCardReqBodyData = request.data;
          deleteCard.msisdn = phone;
          deleteCard.token = response.data.Token ? response.data.Token : '';
          deleteCard.referenceNo = response.data.ReqRefNumber ? response.data.ReqRefNumber : '';
          MasterpassServicess.deleteCard(MasterPassFormEnum.DELETE_CARD, deleteCard, (_: any, res: any) => {
            if (res.responseCode == '0000' || res.responseCode == '') {
              resultChannel.put(onDeleteMasterPassSuccess());
              resultChannel.put(
                onGetMasterpassTokenRequest({
                  CustomerNumber: userData.customerNumber,
                  SessionToken: userData.sessionToken,
                  TokenType: MasterPassTokenEnum.CARD_LIST,
                  request: {
                    data: masterPassListCardNormalize(),
                  },
                })
              );
              resultChannel.put(goBack());
              resultChannel.put(
                showToastify({
                  toastMessage: 'web_msg_succes_delete_credit_card',
                  type: 'success',
                })
              );
            } else {
              resultChannel.put(
                setFormError({
                  errorCode: res.responseDescription,
                  formName: formNames.creditCardDetail,
                })
              );

              resultChannel.put(onDeleteMasterPassFail());
            }
          });
          //
          break;
        case MasterPassTokenEnum.REGISTER:
          const registerJson: masterPassRegisterReqBodyData = request.data;
          registerJson.token = response.data.Token;
          registerJson.referenceNo = response.data.ReqRefNumber;
          registerJson.msisdn = phone;
          console.log('registerJson', registerJson);
          console.log('request', request);
          const tempCard = {
            Name: request.data.accountAliasName,
            Value1: request.data.rtaPan,
            ExpireDate: request.data.expiryDate,
            Cvv: request.data.cvc,
          };
          resultChannel.put(setTempMasterPassCard(tempCard));
          if (request.type === MasterPassTokenEnum.LINK) {
            MasterPassServices.linkCardToClient(
              MasterPassFormEnum.LINK_TO_MP,
              registerJson,
              (_: any, response: any) => {
                if (response.responseCode == '5001' || response.responseCode == '5008') {
                  resultChannel.put(showOTPDialog());
                  // used to set loading false
                  resultChannel.put(setLoadingForMasterpass(false));
                } else {
                  resultChannel.put(
                    setFormError({
                      errorCode: response.responseDescription,
                      formName: formNames.addCreditCard,
                    })
                  );
                  resultChannel.put(setLoadingForMasterpass(false));
                }
              }
            );
          } else {
            MasterPassServices.register(MasterPassFormEnum.REGISTER, registerJson, (_: any, response: any) => {
              if (response.responseCode == '5001' || response.responseCode == '5008') {
                resultChannel.put(showOTPDialog());
                resultChannel.put(setLoadingForMasterpass(false));
              } else {
                resultChannel.put(setLoadingForMasterpass(false));
                resultChannel.put(
                  setFormError({
                    errorCode: 'Kart bilgilerini lütfen tekrar kontrol edin', // TODO ADD LOCALIZATION
                    formName: formNames.addCreditCard,
                  })
                );
                // to show mobile page
              }
            });
          }
          if (responseCode === '505' || responseCode === '506' || responseCode === '507') {
            // Oturum Kodu Hatalı
            if (!isMiniApp) {
              yield put(push(routePath.welcome));
              yield put(
                setFormError({
                  errorCode: responseCode,
                  formName: formNames.creditCardDetail,
                })
              );
            } else if (isMiniApp && (responseCode === '505' || responseCode === '506' || responseCode === '507')) {
              const protocol = window.location.protocol;
              const host = window.location.host;
              const REDIRECT_URL = `${protocol}//${host}/mini-app`;
              window.location.href = REDIRECT_URL;
            }
          }
          break;
        //kayıt
        case MasterPassTokenEnum.RECURING_PAYMENT:
          break;
        case MasterPassTokenEnum.OTP:
          MasterPassServices.validateTransaction(MasterPassFormEnum.OTP, request.data, (_: any, res: any) => {
            if (res.responseCode == '0000' || res.responseCode == '') {
              resultChannel.put(
                onGetMasterpassTokenRequest({
                  CustomerNumber: userData.customerNumber,
                  SessionToken: userData.sessionToken,
                  TokenType: MasterPassTokenEnum.CARD_LIST,
                  request: {
                    data: masterPassListCardNormalize(),
                  },
                })
              );

              resultChannel.put(closeOTPDialog());
              resultChannel.put(setLoadingForMasterpass(false));
              //resultChannel.put(setActiveSelectedCreditCard(cardStore.tempMasterPassCard));
              resultChannel.put(setTempMasterPassCardActive());
              if (masterPassAccountStatus) {
                resultChannel.put(setMasterPassAccountStatus(''));
              }
              if (isUserInUploadTLProcess) {
                resultChannel.put(push(routePath.addBalanceToCard));
              } else if (isUserInUploadSubscriptionProcess) {
                resultChannel.put(push(routePath.uploadSubscription));
              } else {
                resultChannel.put(
                  showToastify({
                    toastMessage:
                      masterPassAccountStatus === MasterPassAccountStatusEnum.NO_LINKED
                        ? 'web_masterpass_link_success_msg'
                        : 'web_msg_succes_add_credit_card',
                    type: 'success',
                  })
                );
                if (masterPassAccountStatus !== MasterPassAccountStatusEnum.NO_LINKED) {
                  resultChannel.put(goBack());
                }
                if (masterPassAccountStatus) {
                  resultChannel.put(setMasterPassAccountStatus(''));
                }
              }
            } else {
              resultChannel.put(
                setFormError({
                  errorCode: res.responseDescription,
                  formName: formNames.dialogOTP,
                })
              );
              resultChannel.put(setLoadingForMasterpass(false));
            }
          });
          break;
        case MasterPassTokenEnum.RESEND_OTP:
          const token = MasterpassServicess.getLastToken();
          MasterpassServicess.resendOtp(token, 'tur', () => {});
          resultChannel.put(setLoadingForMasterpass(false));
          break;
        default:
          break;
      }

      yield put(onGetMasterpassTokenRequestSuccess(response));
    } else {
      if (responseCode === '505' || responseCode === '506' || responseCode === '507') {
        // Oturum Kodu Hatalı
        if (!isMiniApp) {
          yield put(push(routePath.welcome));
          yield put(
            setFormError({
              errorCode: responseCode,
              formName: formNames.creditCardDetail,
            })
          );
        } else if (isMiniApp && (responseCode === '505' || responseCode === '506' || responseCode === '507')) {
          const protocol = window.location.protocol;
          const host = window.location.host;
          const REDIRECT_URL = `${protocol}//${host}/mini-app`;
          window.location.href = REDIRECT_URL;
        }
      }
      if (isUserInUploadTLProcess) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.addBalanceToCard,
          })
        );
      }
      if (isUserInUploadSubscriptionProcess) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.uploadSubscription,
          })
        );
      }
      if (isUserInAutomatiInstructionProcess) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.loadAutomaticInstructions,
          })
        );
      }
      yield put(onGetMasterpassTokenRequestFail(errorMessage));
    }
  } catch (error: any) {
    yield put(onGetMasterpassTokenRequestFail(error));
  }
}

function* handleVsPosBelbimPaymentRequest({ payload }: PayloadAction<vsPosBeldimReqBodyData>) {
  try {
    const vsPosBelbimPaymentResult: ResultType = yield call(() => VsPosBelbimReq(payload));
    yield put(vsPosBelbimPaymentRequestSuccess(vsPosBelbimPaymentResult));
    yield put(open3DSecureModal());
  } catch (error: any) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    yield put(vsPosBelbimPaymentRequestFail(error));
  }
}
function* handleCheckVposPayment({ payload }: PayloadAction<checkVposReqBodyData>) {
  try {
    const appState: ApplicationProcessState = yield select(applicationStore);
    const cardState: CardTransactionsState = yield select(storeCardTransactions);
    const userData: UserState = yield select(userStore);
    const creditCardStore: CreditCardTransactionsState = yield select(creditCardTransactionsStore);
    const routerState: RouterState = yield select((state: any) => state.router);
    let pathname = routerState?.location?.pathname;
    const {
      isUserInUploadTLProcess,
      isUserInUploadSubscriptionProcess,
      isUserInAutomatiInstructionProcess,
      isUserAddingNewCardInTransactions,
    } = cardState;
    const {
      updateApplicationId,
      applicationCard,
      isShipping,
      institutionType,
      campusId,
      cardApplicationStatus,
    } = appState;

    const vsPosBelbimPaymentResult: CheckVposPaymentModel = yield call(() => checkVposPayment(payload));

    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(vsPosBelbimPaymentResult);

    if (requestSuccess) {
      yield put(checkPaymentStatusRequestResult(vsPosBelbimPaymentResult));

      if (vsPosBelbimPaymentResult.data.IsSuccess) {
        yield put(close3DSecureModal());

        if (isUserInUploadTLProcess) {
          yield put(setUploadTLStep(1));

          const selectedCreditCardBinNumber = creditCardStore?.activeSelectedCreditCard?.Value1.slice(0, 6);

          const creditCardState: CreditCardTransactionsState = yield select(creditCardTransactionsStore);

          const creditCardBinList: BinItem[] | undefined = creditCardState.creditCardBinList;

          const activeCardInfo = creditCardBinList?.find(
            (item: BinItem) => item.BinNumber === selectedCreditCardBinNumber
          );

          let evamSdkData = {
            customer_no: userData?.customerNumber,
            kart_id: cardState?.activeSelectedCard?.CardId,
            /*  kayıtlı_kart_bilgisi: creditCardStore?.activeSelectedCreditCard,
            masterpass_ile_kayıt_bilgisi: creditCardStore?.activeSelectedCreditCard?.IsMasterPassMember
              ? creditCardStore?.activeSelectedCreditCard
              : '', */
            kart_logo_bilgisi: activeCardInfo?.CardBrand,
            bin: activeCardInfo?.BinNumber,
            amount: creditCardStore?.loadAmount,
            kayitli_kart_bilgisi_masterpass_ile_kayit_bilgisi: activeCardInfo?.CardBrand,
            masterpass_ile_kayit_bilgisi_IsMasterPassMember:
              creditCardStore?.activeSelectedCreditCard?.IsMasterPassMember,
            kayitli_kart_bilgisi_IsMasterPassMember: activeCardInfo?.CardBrand,
            masterpass_ile_kayit_bilgisi_CardStatus: creditCardStore?.activeSelectedCreditCard?.CardStatus,
            kayitli_kart_bilgisi_CardStatus: creditCardStore?.activeSelectedCreditCard?.CardStatus,
            masterpass_ile_kayit_bilgisi_LoyaltyCode: creditCardStore?.activeSelectedCreditCard?.LoyaltyCode,
            kayitli_kart_bilgisi_LoyaltyCode: creditCardStore?.activeSelectedCreditCard?.LoyaltyCode,
            masterpass_ile_kayit_bilgisi_ProductName: creditCardStore?.activeSelectedCreditCard?.ProductName,
            masterpass_ile_kayit_bilgisi_creditCardName: creditCardStore?.activeSelectedCreditCard?.Name,
            masterpass_ile_kayit_bilgisi_value1: creditCardStore?.activeSelectedCreditCard?.Value1,
          };
          sendEVAMCustomEvent(EVAMSdkEventTypes.KAYITLI_ISTANBUL_KARTA_TL_YUKLEME, evamSdkData);

          // Kullanıcı yeni istanbulkart eklerse
          if (isUserAddingNewCardInTransactions) {
            let evamSdkData = {
              customer_no: userData.customerNumber,
              amount: creditCardStore.loadAmount,
              kart_id: cardState?.activeSelectedCard?.CardId,
              panmasked: creditCardStore?.activeSelectedCreditCard?.Value1.slice(0, 6),
              kayıtsızkart: cardState?.activeSelectedCard,
              kanal: 'web', // TODO
            };
            sendEVAMCustomEvent(EVAMSdkEventTypes.HIZLI_ISLEM_ILE_BASKA_IST_KARTA_TL_YUKLEME, evamSdkData);
          }
        }
        if (isUserInUploadSubscriptionProcess) {
          yield put(setUploadSubscriptionStep(1));
          let evamSdkData = {
            customer_no: userData.customerNumber,
            kart_id: cardState?.activeSelectedCard?.CardId,
            tarih: moment(new Date()).format('DD.MM.YYYY'),
            task_type: '', // TODO
          };
          sendEVAMCustomEvent(EVAMSdkEventTypes.ABONMAN_YUKLEME, evamSdkData);
          if (isUserAddingNewCardInTransactions) {
            let evamSdkData = {
              customer_no: userData.customerNumber,
              kart_id: cardState?.activeSelectedCard?.CardId,
              tarih: moment(new Date()).format('DD.MM.YYYY'),
              kanal: 'web', // TODO
            };
            sendEVAMCustomEvent(EVAMSdkEventTypes.HIZLI_ISLEM_ILE_BASKA_ISTANBUL_KARTA_ABONMAN_YUKLEME, evamSdkData);
          }
        }
        if (isUserInAutomatiInstructionProcess) {
          yield put(setAutomaticInstructionStep(2));
        }
        if (pathname === routePath.cardApplicationSteps) {
          yield put(
            replace(routePath.cardApplicationUpdateSuccess, {
              pc: applicationCard?.ProductCode,
              id: updateApplicationId,
              isApplication: true,
              fromCenter: !isShipping,
              campusId: campusId,
            })
          );
          yield put(resetCardApplicationStatus());
          // if (cardApplicationStatus === 6) {
          //   const resendCustomerCardResult: ResendCustomerCardModel = yield call(() =>
          //     resendCustomerCard({
          //       CustomerNumber: userData?.customerNumber,
          //       ProductCode: applicationCard?.ProductCode,
          //     })
          //   );
          //   console.log('resendCustomerCardResult', resendCustomerCardResult);
          // }
          /* İKİNCİ KARGO SÜRECİNDE resendCustomerCard servisini iletmişlerdi ama sonrasında kullanılmadı. 
          Sonra kullanılabilmesi durumu için yorum satırında bırakıyorum.*/
        }
      } else if (
        vsPosBelbimPaymentResult.data.BankResponseMessage === '-' &&
        !vsPosBelbimPaymentResult.data.IsSuccess
      ) {
        //KULLANICI HALA 3D EKRANINDA
      } else {
        yield put(close3DSecureModal());
        yield put(showToastify({ toastMessage: vsPosBelbimPaymentResult.data.BankResponseMessage, type: 'error' }));
        yield put(vsPosBelbimPaymentRequestFail(vsPosBelbimPaymentResult.data.BankResponseMessage));
      }
    } else {
      console.log(errorMessage);
    }
  } catch (error: any) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    yield put(vsPosBelbimPaymentRequestFail(error));
  }
}

function* handleOnGetCommissionByBinNumberRequest({ payload }: PayloadAction<onGetCommissionByBinNumberReqBodyData>) {
  const cardState: CardTransactionsState = yield select(storeCardTransactions);
  const { isUserInUploadTLProcess, isUserInUploadSubscriptionProcess } = cardState;
  try {
    const onGetCommissionByBinNumberResult: OnGetCommissionByBinNumberModel = yield call(() =>
      onGetCommissionByBinNumber(payload)
    );

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onGetCommissionByBinNumberResult);

    if (requestSuccess) {
      yield put(onGetCommissionByBinNumberRequestSuccess(onGetCommissionByBinNumberResult.data.CommissionAmount));
    } else {
      yield put(onGetCommissionByBinNumberRequestFail(errorMessage));
      if (isUserInUploadTLProcess) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.addBalanceToCard,
          })
        );
      }
      if (isUserInUploadSubscriptionProcess) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.uploadSubscription,
          })
        );
      }
    }
  } catch (error: any) {
    yield put(onGetCommissionByBinNumberRequestFail(error));
  }
}

function* handleGetBinListRequestSaga({ payload }: PayloadAction<getBinListReqBodyData>) {
  try {
    const GetBinListModelResult: GetBinListModel = yield call(() => getBinList(payload));

    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(GetBinListModelResult);

    if (requestSuccess) {
      yield put(getBinListRequestSuccess(GetBinListModelResult));
    } else {
      yield put(getBinListRequestFail(errorMessage));
    }
  } catch (error: any) {
    yield put(getBinListRequestFail(error));
  }
}

function* handleOnGetMasterpassForMobileRequest({ payload }: PayloadAction<MasterPassTokenActionType | any>) {
  try {
    const { request } = payload;

    switch (payload.TokenType) {
      case MasterPassTokenEnum.REGISTER:
        const registerJsonForMobile: masterPassRegisterReqBodyData = request.data;
        MasterPassServices.register(MasterPassFormEnum.REGISTER, registerJsonForMobile, (_: any, response: any) => {
          if (response.responseCode == '5001' || response.responseCode == '5008') {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                cardNumber: request?.data?.rtaPan,
                expireDate: request?.data?.expiryDate,
                cvv: request?.data?.cvc,
                cardName: request?.data?.accountAliasName,
                processDone: false,
              })
            );
            resultChannel.put(showOTPDialog());
            resultChannel.put(onGetMasterpassForMobileRequestSuccess());
          } else {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                errorMessage: response.responseDescription,
                errorCode: response.responseCode,
              })
            );
            resultChannel.put(onGetMasterpassForMobileRequestFail(response.responseDescription));
            resultChannel.put(
              setFormError({
                errorCode: response.responseDescription,
                formName: formNames.mobileAddCreditCard,
              })
            );
          }
        });
        break;
      case MasterPassTokenEnum.LINK:
        MasterPassServices.linkCardToClient(MasterPassFormEnum.LINK_TO_MP, request.data, (_: any, response: any) => {
          if (response.responseCode == '5001' || response.responseCode == '5008' || response.responseCode === '5007') {
            resultChannel.put(
              replace(routePath.mobileAccountOtp, {
                json: JSON.stringify(response),
                lang: request.data.sendSmsLanguage,
              })
            );

            resultChannel.put(setLoadingForMasterpass(false));
          } else {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                processDone: false,
                errorMessage: response.responseDescription,
              })
            );
            resultChannel.put(
              setFormError({
                errorCode: response.responseDescription,
                formName: formNames.mobileAddCreditCard,
              })
            );
            resultChannel.put(setLoadingForMasterpass(false));
          }
        });
        break;
      case MasterPassTokenEnum.OTP:
        MasterPassServices.validateTransaction(MasterPassFormEnum.OTP, request.data, (_: any, res: any) => {
          if (res.responseCode == '0000' || res.responseCode == '') {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                processDone: true,
                isCardAddedWithMasterpass: true,
              })
            );
            resultChannel.put(closeOTPDialog());
            resultChannel.put(onGetMasterpassForMobileRequestSuccess());
          } else {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                errorMessage: res.responseDescription,
                errorCode: res.responseCode,
              })
            );
            resultChannel.put(
              setFormError({
                errorCode: res.responseDescription,
                formName: formNames.dialogOTP,
              })
            );
            resultChannel.put(onGetMasterpassForMobileRequestFail(res.responseDescription));
          }
        });
        break;
      case MasterPassTokenEnum.RESEND_OTP:
        const token = MasterpassServicess.getLastToken();

        MasterpassServicess.resendOtp(token, getActiveLanguageForMasterPass(), (_: any, res: any) => {
          if (res.responseCode == '5001' || res.responseCode == '5008') {
          } else {
            resultChannel.put(
              setFormError({
                errorCode: res.responseDescription,
                formName: formNames.dialogOTP,
              })
            );
          }
        });
        resultChannel.put(onGetMasterpassForMobileRequestSuccess());
        break;
      case MasterPassTokenEnum.REGISTER_PURCHASE:
        const activeSelectedCard = payload.activeSelectedCreditCard;
        delete payload.activeSelectedCreditCard;
        const data = request?.data;
        data.token = payload && payload?.token;
        data.referenceNo = payload?.referenceNo;
        data.msisdn = payload?.request?.phoneNumberForMobile;
        data.orderNo = payload?.PaymentNumber;
        if (activeSelectedCard) {
          data.rtaPan = activeSelectedCard.rtaPan;
          data.cvc = activeSelectedCard.cvv;
          data.expiryDate = activeSelectedCard.expiryDate;
          data.sendSms = 'N';
          delete data.sendSmsMerchant;
        }
        MasterPassServices.purchase('purchase-form', data, (_: any, res: any) => {
          if (res.responseCode === '5010') {
            const newUrl = res.url3D + '&returnUrl=' + payload?.returnUrl;

            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                status: 'success',
                url3D: newUrl, //res.url3D,
                url3DError: res.url3DError,
                url3DSuccess: res.url3DSuccess,
              })
            );
          } else {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                status: 'error',
                response: res.responseDescription,
              })
            );
          }
        });
        break;
      case MasterPassTokenEnum.INITIATE_RECURRING_PAYMENT:
        MasterPassServices.initiateRecurringPayment(
          MasterPassFormEnum.INITIATE_RECURRING_FORM,
          request.data,
          (_: any, response: any) => {
            if (response.responseCode == '0000' || response.responseCode == '') {
              if (request.data.actionType == 'D') {
                resultChannel.put(
                  onGetMasterpassForMobileRequest({
                    ...payload,
                    request: {
                      ...request,
                      data: {
                        ...request.data,
                        actionType: 'A',
                      },
                    },
                  })
                );
              } else {
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    processDone: true,
                  })
                );
              }
            } else if (response.responseCode == '5001' || response.responseCode == '5008') {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  processDone: false,
                })
              );
              resultChannel.put(showOTPDialog());
              resultChannel.put(onGetMasterpassForMobileRequestSuccess());
            } else if (response.responseCode == '4152') {
              resultChannel.put(
                onGetMasterpassForMobileRequest({
                  ...payload,
                  request: {
                    ...request,
                    data: {
                      ...request.data,
                      actionType: 'D',
                    },
                  },
                })
              );
            } else {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  processDone: false,
                  errorMessage: response.responseDescription,
                  errorCode: response.responseCode,
                })
              );
              resultChannel.put(onGetMasterpassForMobileRequestFail(response.responseDescription));
            }
          }
        );
        break;
    }
    yield put(open3DSecureModal());
  } catch (error: any) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    yield put(vsPosBelbimPaymentRequestFail(error));
  }
}
// Watchers
function* creditCardTransactionsSagaWatcher() {
  yield takeLatest(onGetMasterpassTokenRequest, handleGetMasterpassToken);
  yield takeLatest(onGetMasterpassForMobileRequest, handleOnGetMasterpassForMobileRequest);
  yield takeLatest(vsPosBelbimPaymentRequest, handleVsPosBelbimPaymentRequest);
  yield takeLatest(getBinListRequest, handleGetBinListRequestSaga);
  yield takeLatest(onGetCommissionByBinNumberRequest, handleOnGetCommissionByBinNumberRequest);
  yield takeLatest(checkPaymentStatusRequest, handleCheckVposPayment);
  while (true) {
    const action: PayloadAction = yield take(resultChannel);
    yield put(action);
  }
}

// eslint-disable-next-line
export default creditCardTransactionsSagaWatcher;
