import * as React from 'react';

function PasswordShowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      className="passwordShowHideIcon"
    >
      <defs>
        <path
          id="m4m309ehca"
          d="M11.73 5c5.98-.18 9.5 5.39 10.14 6.5.179.31.179.69 0 1-.86 1.5-4.07 6.36-9.6 6.5h-.25c-5.89 0-9.26-5.41-9.89-6.5-.179-.31-.179-.69 0-1C3 10 6.2 5.14 11.73 5zM12 8.5c-1.933 0-3.5 1.567-3.5 3.5s1.567 3.5 3.5 3.5 3.5-1.567 3.5-3.5-1.567-3.5-3.5-3.5zm0 2c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-912 -330) translate(490 316) translate(422 14)">
              <path d="M0 0H24V24H0z" />
              <mask id="jl5b6v058b" fill="#fff">
                <use xlinkHref="#m4m309ehca" />
              </mask>
              <g fill="#C3C4CB" mask="url(#jl5b6v058b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PasswordShowIcon;
