import { FC } from 'react';
import { useCardType, useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';

import './FibaBankSuccessPage.scss';
import { closeBankSecureModal, MicroCreditState, microCreditStore } from 'Stores/MicroCredit';
import { UserState, userStore } from 'Stores/User';

type FibaBankFailPageProps = {};

const FibaBankFailPage: FC<FibaBankFailPageProps> = ({}) => {
  const { web_micro_credit_fibafail_msg, web_micro_credit_fibafail_btn } = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const microCreditData: MicroCreditState = useSelector(microCreditStore);
  const useerData: UserState = useSelector(userStore);

  return (
    <div id="addCardSuccessLoadBalance">
      <Box marginTop={gutters.big}>
        {/* <div className="add-card-success-load-balance__img--wrapper">
          <img
            src={'/static/media/digital_tr.82535037.png'}
            alt=""
            className={`add-card-success-load-balance__img--wrapper__${'blue'}`}
          />
          <GreenTickBigFilledIcon />
        </div> */}
        {
          //BURASI KALACAK}
        }
        <Box className="add-card-success-load-balance__desc">
          <Typography variant="body1" align="center">
            <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
              {web_micro_credit_fibafail_msg}
            </Box>
          </Typography>
        </Box>
        <div className="add-card-success-load-balance__container">
          {/* <Box marginTop={gutters.small}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              type="submit"
              onClick={() => clikcToButton()}
              itemID="seeUploadDetailBtnTest"
            >
              {web_micro_credit_fibafail_btn}
            </Button>
          </Box> */}
        </div>
      </Box>
    </div>
  );
};

export default FibaBankFailPage;
