import { FC, memo } from 'react';
import { DashboardLayout } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { useSelector } from 'react-redux';
import { AccountState, storeAccount } from 'Stores/Account';
import Linkify from 'react-linkify';

import './News.scss';

type NewsProps = {};

const News: FC<NewsProps> = memo(() => {
  const accountState: AccountState = useSelector(storeAccount);
  const userActiveNews = accountState?.activeNews;

  return (
    <DashboardLayout>
      <div id="news">
        <Typography variant="h3">
          <Box className="news__title" fontWeight={700}>
            {userActiveNews?.HeadLine}
          </Box>
        </Typography>
        <Box marginBottom={gutters.regular}>
          <img src={userActiveNews?.WebDetailPictureUrl} alt="" className="news__showcase" />
        </Box>
        <Box>
          <Typography variant="body1">
            <Box fontSize={fontSizes.regular}>
              <Linkify
                componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
                  const isLink = decoratedText.includes('https');
                  return isLink ? (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  ) : (
                    decoratedText
                  );
                }}
              >
                {userActiveNews?.Description}
              </Linkify>
            </Box>
          </Typography>
        </Box>
      </div>
    </DashboardLayout>
  );
});
export default News;
