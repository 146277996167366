import { FC, Fragment } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Typography } from '@material-ui/core';
import { BalanceTransferStep } from './BalanceTransferStep';

import './BalanceTransfer.scss';

const BalanceTransfer: FC = () => {
  const { digital_card_to_physical_card_money_transfer_header_text } = useLocalization();

  return (
    <DashboardLayout backButton>
      <div id="balanceTransfer">
        <Typography variant="h3" className="balanceTransfer__title">
          {digital_card_to_physical_card_money_transfer_header_text}
        </Typography>
        <Fragment>
          <BalanceTransferStep />
        </Fragment>
      </div>
    </DashboardLayout>
  );
};

export default BalanceTransfer;
