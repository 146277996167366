import * as React from 'react';

function TickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="tickIcon"
      {...props}
    >
      <defs>
        <path
          id="kggp1wx60a"
          d="M10 1.667c-4.602 0-8.333 3.73-8.333 8.333 0 4.602 3.73 8.333 8.333 8.333 4.602 0 8.333-3.73 8.333-8.333 0-2.21-.878-4.33-2.44-5.893C14.33 2.545 12.21 1.667 10 1.667zm3.583 6.341l-3.808 5c-.157.205-.4.325-.658.325-.257.002-.5-.115-.659-.316l-2.033-2.592c-.183-.235-.227-.55-.115-.826.112-.277.363-.472.659-.513.295-.04.59.079.773.314L9.1 11.133l3.15-4.166c.182-.239.476-.362.774-.324.297.039.55.232.666.509.116.276.075.593-.107.831v.025z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-618 -170) translate(586 162) translate(32 8)">
              <path d="M0 0H20V20H0z" />
              <mask id="yoxiqt9x4b" fill="#fff">
                <use xlinkHref="#kggp1wx60a" />
              </mask>
              <g fill="currentColor" mask="url(#yoxiqt9x4b)">
                <path d="M0 0H20V20H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TickIcon;
