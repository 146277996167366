import { FC, memo } from 'react';
import { useLocalization, useErrorMessage } from 'Hooks';
import { Box, TextField, Button, Typography } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { formikGlobalConfig, formNames } from 'Config';
import { DialogWithInput, FormWrapper, FormErrorWrapper } from 'Components';
import { Formik } from 'formik';
import {
  UpdateMailAdressSchema,
  UpdateMailAdressSchemaInputInitialValues,
  UpdateMailAdressSchemaInput,
} from 'Validations';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeCustomerInfoRequest, UserState, userStore } from 'Stores/User';
import { useState } from 'react';
import { fontSizes, gutters } from 'Theme/Variables';
import { userInfoTypes } from '../constant';
import { AccountState, showHideUserMailDialog, storeAccount } from 'Stores/Account';
import { MailInfoDialog } from './MailInfoDialog';

import './UserMailAdress.scss';

type UserMailAdressProps = {};
const UserMailAdress: FC<UserMailAdressProps> = memo(() => {
  const {
    web_label_email,
    web_communication_email_null,

    email_update_popup_button,
    email_update_popup_desc,
  } = useLocalization();
  const { getError, getErrorMessage } = useErrorMessage();

  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const userEmail: string | undefined = userData.email;
  const customerNumber: number | undefined = userData.customerNumber;
  const sessionToken: string | undefined = userData.sessionToken;

  const accountState: AccountState = useSelector(storeAccount);
  const mailDialogIsOpen: boolean = accountState.isMailDialogOpen;

  const handleSubmit = (values: UpdateMailAdressSchemaInput) => {
    dispatch(
      onChangeCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        Email: values.email,
        TypeOfField: userInfoTypes.mail,
      })
    );
  };

  const [mail, setMail] = useState<string>('');

  return (
    <Box id="userMailAdress">
      <InfoItem
        title={web_label_email}
        value={userEmail ? userEmail : web_communication_email_null}
        onClick={() => dispatch(showHideUserMailDialog(true))}
        add={!userEmail}
      />
      <Box>
        <DialogWithInput
          open={mailDialogIsOpen}
          title={web_label_email}
          hide={() => dispatch(showHideUserMailDialog(false))}
          closeIcon
        >
          <Box>
            <FormErrorWrapper resetFormOnRouteChange formName={formNames.updateUserMail}>
              <Typography variant="body1" align="center">
                <Box fontSize={fontSizes.regular}>{email_update_popup_desc}</Box>
              </Typography>
              <Formik
                initialValues={UpdateMailAdressSchemaInputInitialValues(userEmail)}
                validationSchema={UpdateMailAdressSchema}
                onSubmit={handleSubmit}
                {...formikGlobalConfig}
              >
                {(formikProps) => {
                  const {
                    handleChange,
                    handleBlur,
                    values: { email },
                    errors: { email: emailError },
                    touched: { email: touchedEmail },
                  } = formikProps;

                  return (
                    <FormWrapper onSubmit={formikProps.handleSubmit}>
                      <Box>
                        <TextField
                          className="userMailAdress__input"
                          variant="filled"
                          fullWidth
                          name="email"
                          value={email.trim()}
                          onChange={(e) => {
                            handleChange(e);
                            setMail(e.currentTarget.value);
                          }}
                          onBlur={handleBlur}
                          label={web_label_email}
                          error={getError(emailError, touchedEmail)}
                          helperText={getErrorMessage(emailError, touchedEmail)}
                          itemID="changeUserMailInputTest"
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          type="submit"
                          itemID="changeUserMailBtnTest"
                        >
                          {email_update_popup_button}
                        </Button>
                      </Box>
                    </FormWrapper>
                  );
                }}
              </Formik>
            </FormErrorWrapper>
          </Box>
        </DialogWithInput>
        <MailInfoDialog mail={mail} />
      </Box>
    </Box>
  );
});
export default UserMailAdress;
