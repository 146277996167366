import { FC, memo, useEffect, useState } from 'react';
import { CurrentLocationIcon, IbmPin, IbmSelectedPin } from 'Components/Svg';
import { ApplicationCenterListItemModel } from 'Models';
import GoogleMapReact from 'google-map-react';

import './IbmMap.scss';
import { mapDirection, VIEW_TYPE } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  mapStore,
  setDiscoverCurrentMapLocation,
  setDiscoverSelectedPinFromList,
  setDiscoverViewType,
  setIbmSelectedPinFromList,
} from 'Stores/Maps';
import { PointList } from 'Components/PointList';
import { PointListIbm } from 'Components/PointListIbm';

const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();

type markerPositions = {
  latitude: string;
  longitude: string;
  markerImg?: string;
};

type MapProps = {
  height?: string;
  children?: JSX.Element;
  onClickFunction?: Function;
};

const IbmMap: FC<MapProps> = memo(({ height, children, onClickFunction }) => {
  const dispatch = useDispatch();
  const mapState = useSelector(mapStore);
  const {
    userLocation,
    applicationCenterList,
    ibmSelectedPin,
    discoverSelectedPinFromList,
    zoom,
    discoverCurrentMapLocation,
    discoverIsDirectionEnable,
    discoverViewType,
    ibmSelectedPinFromList,
  } = mapState;
  const [mapRender, setMapRender] = useState<any>();

  const checkSelectedPin = (item: ApplicationCenterListItemModel): boolean => {
    if (ibmSelectedPin) {
      return ibmSelectedPin?.ApplicationCenterId === item.ApplicationCenterId;
    } else {
      return false;
    }
  };

  const onSelectedPointFromList = (item: any) => {
    dispatch(setDiscoverViewType());
    dispatch(setIbmSelectedPinFromList(item));
  };

  useEffect(() => {
    directionsRenderer.setMap(null);
    directionsRenderer.setOptions({ suppressMarkers: true });
    mapDirection({
      discoverIsDirectionEnable,
      directionsService,
      directionsRenderer,
      mapRender,
      userLocation,
      ibmSelectedPin,
    });
  }, [ibmSelectedPin, discoverIsDirectionEnable, mapRender]);

  useEffect(() => {
    if (ibmSelectedPinFromList && mapRender) {
      mapRender.setCenter({
        lat: ibmSelectedPinFromList?.Latitude,
        lng: ibmSelectedPinFromList?.Longitude,
      });
      mapRender.setZoom(19);
    }
  }, [ibmSelectedPinFromList, mapRender]);

  const setMapCenterLocation = () => {
    if (mapRender && !discoverIsDirectionEnable) {
      const location = {
        latitude: mapRender.getCenter().lat(),
        longitude: mapRender.getCenter().lng(),
      };

      dispatch(setDiscoverCurrentMapLocation({ ...location }));
    }
  };

  return (
    <div id="map">
      {children}
      {discoverViewType === VIEW_TYPE.MAP ? (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyDz4VkQWoG5biNiKmwCGNkL_ocDVQCJenk' }}
          center={{ lat: discoverCurrentMapLocation.latitude, lng: discoverCurrentMapLocation.longitude }}
          defaultZoom={zoom}
          options={{
            fullscreenControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
          }}
          style={{ minHeight: '30vh', height }}
          onGoogleApiLoaded={({ map }) => {
            setMapRender(map);
          }}
          onChange={setMapCenterLocation}
        >
          <UserMarker
            icon={<CurrentLocationIcon className="map__current-icon" />}
            lat={userLocation.latitude}
            lng={userLocation.longitude}
          />

          {applicationCenterList &&
            applicationCenterList.map((item, index) => (
              <Marker
                selectedPin={checkSelectedPin(item)}
                onClickFunction={onClickFunction}
                item={item}
                lat={item.Latitude}
                lng={item.Longitude}
                key={index}
              />
            ))}
        </GoogleMapReact>
      ) : (
        <PointListIbm onSelectedPointFromList={onSelectedPointFromList} />
      )}
    </div>
  );
});
export default IbmMap;

type MarkerProps = {
  lat?: number;
  lng?: number;
  item?: any;
  onClickFunction?: Function;
  selectedPin?: boolean;
};

const Marker: FC<MarkerProps> = memo(({ item, onClickFunction, selectedPin }) => {
  return (
    <div onClick={() => onClickFunction && onClickFunction(item)} id="marker">
      {!selectedPin ? <IbmPin className="marker__unSelected" /> : <IbmSelectedPin className="marker__selected" />}
    </div>
  );
});

type UserMarkerProps = {
  lat?: number;
  lng?: number;
  item?: any;
  onClickFunction?: Function;
  icon: JSX.Element;
};

const UserMarker: FC<UserMarkerProps> = ({ item, onClickFunction, icon }) => {
  return (
    <div onClick={() => onClickFunction && onClickFunction(item)} id="marker" itemID="discoverChooseLocationBtnTest">
      {icon}
    </div>
  );
};
