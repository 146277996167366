import React, { memo, useState } from 'react';
import './WelcomeLinks.scss';
import { Box, Link } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { DialogTerms } from 'Components';
import { useLocalization, useModal, useWindowDimensions } from 'Hooks';
import { IbbWhiteIcon, BelbimWhiteIcon } from 'Components';
import { onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { useDispatch, useSelector } from 'react-redux';
import { agreementsTypes, generateLanguageParam } from 'Utils';

type WelcomeLinksProps = {
  isDrawerLinks?: boolean;
};

const WelcomeLinks: FunctionComponent<WelcomeLinksProps> = memo(({ isDrawerLinks }) => {
  const {
    web_welcome_privacy_policy_and_terms_of_service,
    web_welcome_kullanim_kosullari,
    web_termof_use,
  } = useLocalization();

  const { isVisible, show, hide } = useModal();

  const [isGizlilikPolitikası, setIsGizlilikPolitikası] = useState<boolean>(false);

  const { isTinyHeightDevice } = useWindowDimensions();

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  const dispatch = useDispatch();

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  return (
    <>
      <Box
        id="welcomeLinks"
        className={classNames({
          'welcomeLinks--isDrawer': isDrawerLinks,
          'welcomeLinks--isMobile': !isDrawerLinks,
          'welcomeLinks--smallHeightDevice': isTinyHeightDevice,
        })}
        marginBottom={gutters.big}
        style={{ width: '%100', flex: 1 }}
      >
        {/*  */}
        <Box display="flex" alignItems="center" style={{ width: '%100', flex: 1 }}>
          <Link underline="none">
            <Box
              color="white"
              fontSize={fontSizes.smaller}
              marginRight={gutters.small}
              marginLeft={gutters.small}
              onClick={() => {
                handleAgreementSelect(agreementsTypes.GIZLILIK_POLITIKASI);
                setIsGizlilikPolitikası(true);
                show();
              }}
              itemID="welcomeGizlilikPolitikasıButtonTest"
            >
              {web_welcome_privacy_policy_and_terms_of_service}
            </Box>
          </Link>

          <Link underline="none">
            <Box
              color="white"
              fontSize={fontSizes.smaller}
              marginLeft={gutters.small}
              marginRight={gutters.large}
              onClick={() => {
                handleAgreementSelect(agreementsTypes.UYGULAMA_KULLANIM_KOSULLARI);
                setIsGizlilikPolitikası(false);
                show();
              }}
              itemID="uygulamaKullanımKoşullarıBtnTest"
            >
              {web_welcome_kullanim_kosullari}
            </Box>
          </Link>
          <Box display="flex" alignItems="center" marginLeft={gutters.large}>
            <Box>
              <IbbWhiteIcon />
            </Box>
            <Box marginLeft={gutters.regular}>
              <BelbimWhiteIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogTerms
        open={isVisible}
        hide={hide}
        isButtonOkay
        keepMounted={false}
        title={isGizlilikPolitikası ? web_welcome_privacy_policy_and_terms_of_service : web_termof_use}
        headerTextAlign="left"
        content={agreementContent?.AgreementContent}
      />
    </>
  );
});

export default WelcomeLinks;
