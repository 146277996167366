import * as React from 'react';

function PlaceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19px"
      height="18px"
      viewBox="0 0 19 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M14.67,22 L14.61,22 C14.1574387,21.9727227 13.7798552,21.6443893 13.69,21.2 L12.15,13.63 C12.069879,13.2371423 11.7628577,12.930121 11.37,12.85 L3.8,11.31 C3.35473463,11.2215673 3.02490048,10.8443009 2.99672926,10.3912137 C2.96855804,9.93812657 3.24911852,9.52290813 3.68,9.38 L19.68,4.05 C20.0469877,3.89846804 20.4688624,3.97840306 20.7549797,4.25368255 C21.0410969,4.52896203 21.1372549,4.94743729 21,5.32 L15.67,21.32 C15.5270061,21.7453709 15.1181524,22.0233915 14.67,22 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="Gez-ve-harca" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
        <g id="Desktop---Gez-harca-1.2" transform="translate(-1124.000000, -683.000000)">
          <g id="button/medium/primary-copy-2" transform="translate(1105.000000, 670.000000)">
            <g id="Group" transform="translate(16.000000, 9.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Path" fill-rule="nonzero"></g>
              <g mask="url(#mask-2)" fill="#383C52" id="brand/color/secondary">
                <g>
                  <rect x="0" y="0" width="24" height="24"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PlaceIcon;
