import { Port } from 'Services/Contants';
import { getEnvironment, getPublicIpFromStorage, turkishCharacterNormalize } from 'Utils';
import { userTemporaryCreditCard } from 'Views/AddBalanceToCard/LoadBalance/ChooseCreditCard/SelectedCreditCard/constant';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { createEncryptedData, expireDateFixer } from 'Utils';
import { refTrnType } from 'Views/AddBalanceToCard/constant';

export const VsPolBelbimNormalize = (
  selectedCreditCard: userTemporaryCreditCard,
  Cellphone: string,
  amount: number,
  RefTrnType: number,
  commissionAmount?: number,
  selectedCard?: cardItemParams,
  customerNumber?: number,
  sessionToken?: string,
  refTrnId?: number,
  PmtAccountId?: number,
  creditCardBrand?: string
) => {
  const date = new Date();

  const formValues = {
    Amount: RefTrnType === refTrnType.yeniKartBasvurusu ? amount.toFixed(2).toString() : amount,
    CardHolderName: turkishCharacterNormalize(selectedCreditCard?.Name || 'Mastercard'),
    CardLoadAmount: '0',
    CardType: creditCardBrand ? creditCardBrand : 'M',
    CellPhone: Cellphone,
    ChannelCode: 'INTERNET',
    CommissionAmount: commissionAmount && commissionAmount.toString(),
    CreditCardId: '',
    CustomerNumber: customerNumber,
    Cvv: selectedCreditCard.Cvv,
    ExpiryDate: expireDateFixer(selectedCreditCard?.ExpireDate, RefTrnType),
    IPAddress: getPublicIpFromStorage(),
    Port: Port,
    MerchantNumber: null, //getEnvironment() ? null : 'VPOS85032201',
    MifareId: selectedCard ? selectedCard?.MifareId : '', //selectedCard?.MifareId,
    Pan: selectedCreditCard?.Value1?.replaceAll(' ', ''),
    PaymentCardType: 'C',
    PaymentContent: 'istanbulkart',
    PmtAccountId,
    RandomValue:
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours() + 1).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2),
    RefTrnId: refTrnId ? refTrnId : 0,
    RefTrnType,
    SessionToken: sessionToken,
    TerminalNumber: '',
    UserId: getEnvironment() ? 'VposTest' : 'VPOS_SECURE',
    TextResponse:
      RefTrnType === refTrnType.TL_YUKLEME ||
      RefTrnType === refTrnType.ABONMAN_YUKLEME ||
      RefTrnType === refTrnType.yeniKartBasvurusu ||
      RefTrnType === refTrnType.DIGITAL_KART_TL_YUKLEME ||
      RefTrnType === refTrnType.DIGITAL_KART_ABONMAN_YUKLEME ||
      RefTrnType === refTrnType.ikinciKargoBasvurusu,
  };

  const encrptedFormValues = createEncryptedData(formValues);
  return encrptedFormValues;
};
