import { FC, memo } from 'react';
import { Grid, Box } from '@material-ui/core';
import { fontSizes, gutters, colors } from 'Theme/Variables';
import { useLocalization } from 'Hooks';
import { WarnAndTipsItem } from './WarnAndTipsItem';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { routePath } from 'Navigator/routes';
import { userStore } from 'Stores/User/slices';
import { UserState } from 'Stores/User';
import { CardTransactionsState, setInActiveCard, storeCardTransactions } from 'Stores/CardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { useMemo } from 'react';

import './WarnAndTips.scss';

type InstructionItemProps = {
  MifareId: string;
  TaskCount: number;
  InstructionCount: number;
};

type WarnAndTipsProps = {};

export type WarnAndTipsItemProps = {
  title: string;
  description: string;
  onClick: () => void;
  isSuccess?: boolean;
  itemID: string;
};

const WarnAndTips: FC<WarnAndTipsProps> = memo(() => {
  const {
    web_uyarilar_ipuclari_homepage,
    web_uyarilar_eposta,
    web_uyarilar_kartbakiyesi,
    web_uyarilar_eposta_desc,
    web_automatic_payment_instruction,
    web_balance_running_low,
    web_below_the_set_limit,
    web_choose_favorite_istanbulcard,
    web_email_warning,
    web_hes_code_warning,
    web_istanbulkart_has_been_renewed,
    web_pending_instruction_warning,
    web_subscription_almost_over,
    web_visa_duty_warning,
    web_warning_favouritecard_desc,
    web_warning_favouritecard_title,
    web_warning_hescode_desc,
    web_warning_hescode_title,
    web_warning_pending_instruction_desc,
    web_warning_pending_instruction_title,
    web_warning_subscription_desc,
    web_warning_subscription_title,
    web_warning_visa_desc,
    web_warning_visa_title,
    web_warning_cardimages_desc,
    web_warning_cardimages_title,
    web_uyarilar_pasifkart,
    web_uyarilar_pasifkart_desc,
    web_active_card_warning,
  } = useLocalization();
  const dispatch = useDispatch();

  const userState: UserState = useSelector(userStore);

  const cardState: CardTransactionsState = useSelector(storeCardTransactions);

  /*  const userCards: cardItemParams[] = cardState?.cards; */

  /*   const instructionList: InstructionItemProps[] | undefined = cardState?.cardInstructionList;
   */
  /*  const isUserHasPrimaryCard = userCards?.some((item: cardItemParams) => item.IsPrimary); */

  /* const isUserHasIsNewCard = userCards?.some((item: cardItemParams) => item.IsNewCard); */

  /*   const isUserHasTaskorInstructionList: InstructionItemProps | undefined = instructionList?.find(
    (item: InstructionItemProps) => item.InstructionCount > 0
  ); */

  /*  const selectedCardHasInsctructions: any = userCards?.find(
    (item: any) => item.MifareId === isUserHasTaskorInstructionList?.MifareId
  ); */

  /*  const isUserHasPassiveCard: boolean = userCards?.some((item: cardItemParams) => item?.ActivationStatus === 0); */

  /*   const isUserHasVisaWarning: boolean = cardState?.isUserHasVisaExpireWarning; */

  const warns = [
    /*  {
      warnItem: {
        title: web_warning_visa_title,
        description: web_warning_visa_desc,
        onClick: () => dispatch(push(routePath.cardDetails)),
        isSuccess: true,
      },
      isShown: !!web_visa_duty_warning && isUserHasVisaWarning,
      itemID: 'cardVisaWarningBtnTest',
    }, */
    {
      warnItem: {
        title: web_uyarilar_eposta,
        description: web_uyarilar_eposta_desc,
        onClick: () => dispatch(push(routePath.userInfo)),
        isSuccess: true,
      },
      isShown: !!web_email_warning && !userState?.email,
      itemID: 'emailWarningBtnTest',
    },
    /*  {
      warnItem: {
        title: web_warning_pending_instruction_title,
        description: web_warning_pending_instruction_desc,
        onClick: () => {
          dispatch(setInActiveCard(selectedCardHasInsctructions));
          dispatch(push(routePath.cardDetails));
        },
        isSuccess: true,
      },
      isShown: !!web_pending_instruction_warning && !!selectedCardHasInsctructions,
      itemID: 'pendingInstructionsBtnTest',
    }, */
    // {
    //   warnItem: {
    //     title: web_warning_favouritecard_title,
    //     description: web_warning_favouritecard_desc,
    //     onClick: () => dispatch(push(routePath.cards)),
    //     isSuccess: true,
    //   },
    //   isShown: !!web_choose_favorite_istanbulcard && !isUserHasPrimaryCard,
    //   itemID: 'chooseFavoriteCardBtnTest',
    // },
    // {
    //   warnItem: {
    //     title: web_uyarilar_pasifkart,
    //     description: web_uyarilar_pasifkart_desc,
    //     onClick: () => {},
    //     isSuccess: true,
    //   },
    //   isShown: !!web_active_card_warning && isUserHasPassiveCard,
    //   itemID: 'passiveCardWarningBtnTest',
    // },
    // {
    //   warnItem: {
    //     title: web_warning_hescode_title,
    //     description: web_warning_hescode_desc,
    //     onClick: () => dispatch(push(routePath.hes)),
    //     isSuccess: true,
    //   },
    //   isShown: !!web_hes_code_warning && userState?.checkHESCode,
    //   itemID: 'hesCodeWarningBtnTest',
    // },
    /*  {
      warnItem: {
        title: web_warning_cardimages_desc,
        description: web_warning_cardimages_title,
        onClick: () => dispatch(push(routePath.cardApplication)),
        isSuccess: true,
      },
      isShown: !!isUserHasIsNewCard && !!web_istanbulkart_has_been_renewed,
      itemID: 'istanbulCardHasBeenRenewedBtnTest',
    }, */
    /*   {
      warnItem: {
        title: web_warning_subscription_title,
        description: web_warning_subscription_desc,
        onClick: () => {},
        isSuccess: true,
      },
      isShown: !!web_subscription_almost_over,
    }, */

    /*    {
      warnItem: {
        title: 'web_below_the_set_limit',
        description: 'web_below_the_set_limit',
        onClick: () => {},
        isSuccess: true,
      },
      isShown: !!web_below_the_set_limit,
    }, */

    /*  {
      warnItem: {
        title: web_uyarilar_kartbakiyesi,
        description: 'Yücel-kart-231-DASD12-DASD',
        onClick: () => {},
        isSuccess: false,
      },
      isShown: !!web_balance_running_low,
    }, */
    /*  {
      warnItem: {
        title: web_automatic_payment_instruction,
        description: 'Yücel-kart-231-DASD12-DASD',
        onClick: () => {},
        isSuccess: false,
      },
      isShown: !!web_automatic_payment_instruction,
    }, */
  ];

  const isShownWarnAndTips = useMemo(() => {
    return warns?.filter((item) => item.isShown).length;
  }, [warns]);

  return (
    <Box>
      {!cardState.loading && isShownWarnAndTips > 0 && (
        <div id="warn-and-tips">
          <Box
            marginBottom={gutters.regular}
            fontSize={fontSizes.regularBig}
            color={colors.secondary}
            fontWeight="bold"
          >
            {web_uyarilar_ipuclari_homepage}
          </Box>
          <Grid container spacing={1}>
            {warns
              .filter((item) => item.isShown === true)
              .slice(0, 3)
              .map((item, index) => (
                <Grid lg={4} item sm={6} xs={12} key={index}>
                  <WarnAndTipsItem
                    title={item.warnItem.title}
                    description={item.warnItem.description}
                    isSuccess={item.warnItem?.isSuccess}
                    onClick={item.warnItem.onClick}
                    itemID={item.itemID}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </Box>
  );
});
export default WarnAndTips;
