import React, { FC } from 'react';
import { Box, Typography, Dialog, Divider } from '@material-ui/core';
import { fontSizes, colors, gutters } from 'Theme/Variables';
import { DialogCloseComponent } from 'Components/DialogCloseComponent';
import { useLocalization } from 'Hooks';
import { cardBalanceNormalize } from 'Utils';

import moment from 'moment';

import './TransactionDetail.scss';

type TransactionDetailDialog = {
  dialogTitle: string;
  TrnId?: number;
  TrnCode: string;
  TrnCodeDescription?: string;
  TrnAmount?: number;
  TrnDescription?: string;
  CardBalanceNew?: number;
  TrnStatus?: string;
  TrnDate?: string;
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  value?: string;
  isOtherCard?: boolean;
  Description?: string;
  TrnTicketCount: number;
};
type DetailRowProps = {
  value?: string;
  label: string;
};

const TransactionDetail: FC<TransactionDetailDialog> = (props) => {
  const {
    web_transaction_detail_popup_transaction_type,
    web_transaction_detail_popup_transaction_fee,
    web_transaction_detail_popup_transaction_date,
    web_transaction_detail_popup_description,
    web_transaction_detail_popup_transaction_time,
    transactions_loading,
    web_subscription_used_quantity_lbl,
    web_subscription_trncode_harcama_ve_iade_lbl,
  } = useLocalization();
  return (
    <div className="transactionDetailDialog">
      <Dialog keepMounted={false} open={props.open} onClose={props.handleClose}>
        <DialogCloseComponent closeFunction={props.handleClose} />

        <div className="dialogContainer">
          {props.dialogTitle && (
            <Box textAlign="center">
              <Typography variant="h5">
                <Box fontWeight={600} marginTop={-2} marginBottom={gutters.large}>
                  {props.dialogTitle}
                </Box>
              </Typography>
            </Box>
          )}
          {(props.TrnCodeDescription || props.Description) && (
            <DetailRow
              label={web_transaction_detail_popup_transaction_type}
              value={props.TrnCodeDescription ? props.TrnCodeDescription : props.Description}
            />
          )}
          <DetailRow
            label={web_transaction_detail_popup_transaction_fee}
            value={`${
              transactions_loading?.includes(props.TrnCode)
                ? '+'
                : web_subscription_trncode_harcama_ve_iade_lbl?.includes(props.TrnCode)
                ? '-'
                : ''
            } ${
              web_subscription_trncode_harcama_ve_iade_lbl?.includes(props.TrnCode)
                ? props.TrnTicketCount
                : `${cardBalanceNormalize(props.TrnAmount ? props.TrnAmount : 0)}`
            }
  ${web_subscription_trncode_harcama_ve_iade_lbl?.includes(props.TrnCode) ? web_subscription_used_quantity_lbl : 'TL'}`}
          />
          {/* <DetailRow label={web_transaction_detail_popup_transaction_fee} value={checkValue(props.TrnCode)} /> */}
          <DetailRow
            label={web_transaction_detail_popup_transaction_date}
            value={moment(props.TrnDate).format('DD.MM.YYYY')}
          />
          <DetailRow
            label={web_transaction_detail_popup_transaction_time}
            value={moment(props.TrnDate).format('HH:mm:ss')}
          />
          {!props.isOtherCard && props.TrnDescription && (
            <DetailRow label={web_transaction_detail_popup_description} value={props.TrnDescription} />
          )}
        </div>
      </Dialog>
    </div>
  );
};

const DetailRow: FC<DetailRowProps> = ({ value, label }) => (
  <Box>
    <Divider className="transactionDetailDivider" style={{ marginBottom: 10, marginTop: 10 }} />
    <Typography variant="body1" className="dialogWithActions-textContainer" component="div">
      <Box color={colors.secondary} fontSize={fontSizes.smaller}>
        {label}
      </Box>
    </Typography>
    <Typography variant="body1" className="dialogWithActions-textContainer" component="div">
      <Box
        color={colors.secondary}
        fontWeight={600}
        fontSize={fontSizes.regular}
        style={{
          overflowWrap: 'break-word',
        }}
      >
        {value}
      </Box>
    </Typography>
  </Box>
);
export default TransactionDetail;
