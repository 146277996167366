import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { AccountState, getDistrictListRequest, storeAccount } from 'Stores/Account';
import { turkishCharacterNormalize } from 'Utils';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DialogTown.scss';

export type townListItem = {
  kayitNo: number;
  ilceKodu: number;
  ilceAdi: string;
};

type DialogTownProps = {
  hide: () => void;
  isOpen: boolean;
  selectedValue?: any;
  setSelectedValue: Function;
};

const DialogTown: FC<DialogTownProps> = ({ hide, isOpen, selectedValue, setSelectedValue }) => {
  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const lowerCaseSearchKey = searchKey.toLocaleLowerCase();

  const accountData: AccountState = useSelector(storeAccount);

  const towns: townListItem[] | undefined = accountData?.townList;

  const dispatch = useDispatch();

  const itemClickHandle = (townItem: townListItem) => {
    setSelectedValue(townItem);
    dispatch(getDistrictListRequest(townItem.kayitNo.toString()));
    hide();
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogTown" onClose={onClose} scroll="paper" keepMounted={false} open={isOpen}>
      <DialogTitle className="dialogTown-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogTown-container">
          <JobBlock
            towns={towns}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={lowerCaseSearchKey}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const JobBlock: FC<{
  towns: townListItem[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ towns, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogTown-container__list">
      {!!towns &&
        towns.length > 0 &&
        towns
          .filter((item: townListItem) => {
            return turkishCharacterNormalize(item.ilceAdi).includes(turkishCharacterNormalize(lowerCaseSearchKey));
          })
          .map((item: townListItem, index) => (
            <Item
              key={index}
              title={item.ilceAdi}
              isSelected={item.ilceAdi.toLocaleLowerCase('tr-TR') === selectedValue}
              onClick={() => itemClickHandle(item)}
            />
          ))}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseAdressTownBtnTest">
      <div
        className={classNames({
          'dialogTown-container__list-item': true,
          'dialogTown-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title.toLocaleLowerCase('tr-TR')}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default DialogTown;
