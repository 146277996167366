import { FC, Fragment, useState } from 'react';
import { ChevronDarkRightIcon, DashboardLayout, DialogWithActions } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import './StudentCard.scss';
import iconProfile from '../../../Assets/Icons/icon-profile.svg';
import iconPeople from '../../../Assets/Icons/icon-people.svg';
import { useHistory } from 'react-router';
import {
  closeAlreadyHave,
  closeCantApplyDialog,
  onOnlineApplicationRequest,
  storeCardTransactions,
} from '../../../Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from '../../../Stores/User';
import { setActiveCard } from 'Stores/CardApplication';
import { ApprovalStatusEnum } from '../../../Utils';
import CardApplicationErrors from '../CartApplicationErrors';

type CardApplicationItemProps = {
  checkAlreadyApplicationStatus: (event: OnOnlineApplicationCardQueryItemModel) => void;
  OnOnlineApplicationCardQueryItemModel: (event: OnOnlineApplicationCardQueryItemModel) => void;
};

const StudentCard: FC<CardApplicationItemProps> = ({ OnOnlineApplicationCardQueryItemModel }) => {
  const {
    web_card_application_title,
    web_card_application_desc,
    web_btn_okay,
    web_cant_apply_this_card,
    web_cant_apply_popup_title,
    cant_apply_popup_title,
    web_active_application,
    web_card_already_exists,
    web_card_for_myself_info,
    web_card_relation_info,
  } = useLocalization();
  const history = useHistory<any>();
  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const { isOpenAlreadyHave, isOpenCantApplyDialog, customerCardApplicationList } = useSelector(storeCardTransactions);
  const currentItem = history?.location?.state?.item as OnOnlineApplicationCardQueryItemModel;
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const checkCardStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    dispatch(
      onOnlineApplicationRequest({
        request: { CustomerNumber: customerNumber, SessionToken: sessionToken, ProductCode: Product?.ProductCode },
        product: Product,
      })
    );
  };

  const checkAlreadyApplicationStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    checkCardStatus(Product);

    // TODO: Old code should be removed.
    // const alreadyData = customerCardApplicationList?.find((item) => {
    //   return item.ProductCode === Product.ProductCode;
    // });
    // if (alreadyData) {
    //   if (alreadyData?.ApprovalStatus === ApprovalStatusEnum.WAS_CREATED) {
    //     checkCardStatus(Product);
    //   }
    // } else {
    //   checkCardStatus(Product);
    // }
  };

  const applicationForMyself = () => {
    const studentCartTypes = ['IKT_ORC'];
    if (studentCartTypes.includes(currentItem?.ProductCode)) {
      dispatch(setActiveCard(currentItem));
      history.push('/card-application-steps');
      return;
    }
    checkAlreadyApplicationStatus(currentItem);
  };

  return (
    <DashboardLayout backButton>
      <Fragment>
        <CardApplicationErrors />

        <Typography variant="h3" align="center">
          <Box fontWeight={600}>{web_card_application_title}</Box>
        </Typography>
        <Typography variant="h3" align="center">
          <Box mb={gutters.big} color={colors.checkboxLabel} fontSize={fontSizes.regular} mt={gutters.small}>
            {web_card_application_desc}?
          </Box>
        </Typography>
        <div
          style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}
          // key={applicationCard?.ProductName}
          // onClick={() => checkAlreadyApplicationStatus(currentItem)}
          onClick={applicationForMyself}
          className="cardApplicationItem__item"
          itemID="applyCardBtnTest"
        >
          <div className="cardApplicationItem__item__first-row">
            <img className="showcase__img" src={iconProfile} alt="" />
            <Typography variant="h3">
              <Box
                style={{ textTransform: 'capitalize' }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={600}
              >
                Kendim adına başvuracağım
              </Box>
              <Box
                style={{ textTransform: 'capitalize', marginRight: 120 }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={300}
              >
                {web_card_for_myself_info}
              </Box>
            </Typography>
          </div>
          <ChevronDarkRightIcon />
        </div>
        <div
          style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}
          // key={applicationCard?.ProductName}
          onClick={() => history.push('/card-info', { item: currentItem })}
          className="cardApplicationItem__item"
          itemID="applyCardBtnTest"
        >
          <div className="cardApplicationItem__item__first-row">
            <img className="showcase__img" src={iconPeople} alt="" />
            <Typography variant="h3">
              <Box
                style={{ textTransform: 'capitalize' }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={600}
              >
                Yakınım adına başvuracağım
              </Box>
              <Box
                style={{ textTransform: 'capitalize', marginRight: 120 }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={300}
              >
                {web_card_relation_info}
              </Box>
            </Typography>
          </div>
          <ChevronDarkRightIcon />
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default StudentCard;
