import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { FormErrorWrapper, CheckboxUnderlineLabel, DialogWithActions, SuccessIconLarge } from 'Components';
import { Box, Typography, Button, TextField, Link } from '@material-ui/core';
import { useErrorMessage, useLocalization } from 'Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { FormWrapper } from 'Components';
import { formikGlobalConfig, formNames, localStorageKeys } from 'Config';
import { Formik } from 'formik';
import { LotteryFormSchema, LotteryFormSchemaInitialValues, LotteryFormSchemaInputs } from 'Validations';
import { gutters } from 'Theme/Variables';
import { StaticContext } from 'react-router';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import './LotteryForm.scss';
import { fontSizes } from 'Theme/Variables';
import { instagramRaffleContestRequest } from 'Stores/InstagramRaffleContest';
import { WelcomeHeader } from 'Views/Welcome/WelcomeHeader';
import { onGetAgreementRequest, storeAccount } from '../../Stores/Account';
import CryptoJS from 'crypto-js';
import { userStore } from 'Stores/User';
import { InstagramRaffleContestModel } from 'Models';
import { instagramRaffleContest } from 'Services/Queries';
import { routePath } from 'Navigator/routes';
const ICON_SIZE = '144px';
type AdressListParams = RouteComponentProps<{}, StaticContext>;

const LotteryForm: FC<AdressListParams> = ({ location }) => {
  const currentLanguage = localStorage.getItem(localStorageKeys.i18nextLng);
  const {
    web_raffle_instagram_contest_page_title,
    web_raffle_instagram_contest_desc,
    web_raffle_instagram_contest_card_owner_name_surname,
    web_raffle_instagram_contest_tckn,
    web_raffle_instagram_contest_instagram_user_name,
    web_raffle_instagram_contest_aggreement_name_desc,
    web_raffle_instagram_contest_button_desc,
    web_raffle_instagram_contest_popup_title,
    web_raffle_instagram_contest_agg_desc,
    web_raffle_instagram_contest_aggreement_desc,
    web_raffle_instagram_contest_success_page_desc,
    web_raffle_instagram_contest_success_page_button,
    generic_error_msg,
    web_raffle_instagram_contest_warn_message_1456,
    web_raffle_instagram_contest_warn_message_1457,
    web_raffle_instagram_contest_warn_message_1458,
    web_raffle_instagram_contest_warn_message_1459,
    web_btn_okay,
    web_raffle_instagram_contest_success_page_title,
    web_raffle_instagram_contest_warning_popup_header,
  } = useLocalization();
  const { getError, getErrorMessage } = useErrorMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sessionToken } = useSelector(userStore);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const errorMessages = {
    '99': generic_error_msg,
    '1456': web_raffle_instagram_contest_warn_message_1456,
    '1457': web_raffle_instagram_contest_warn_message_1457,
    '1458': web_raffle_instagram_contest_warn_message_1458,
    '1459': web_raffle_instagram_contest_warn_message_1459,
  };

  const generateInstagramEncryptedData = (values: LotteryFormSchemaInputs) => {
    const nameParts = values.nameSurname?.split(' ');
    const namePartCount = nameParts.length;
    const Name = nameParts.slice(0, namePartCount - 1).join(' ');
    const Surname = nameParts[namePartCount - 1];
    const formData = JSON.stringify({
      Name,
      Surname,
      CitizenshipNumber: values.tckn,
      InstagramUserName: values.instagramUserName,
    });
    const encryptionKey = 's175GzTPemOl66uc2IGxV4SlHMUmXSBy';
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse('mDkb1T9mIZ2sAoH3');
    const ciphertext = CryptoJS.AES.encrypt(formData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return ciphertext.toString();
  };

  const handleSubmit = async (values: LotteryFormSchemaInputs) => {
    const InstagramEncryptedData = generateInstagramEncryptedData(values);
    const response: InstagramRaffleContestModel = await instagramRaffleContest({
      id: sessionToken,
      InstagramEncryptedData,
    });
    const { ResponseCode } = response?.data;
    if (ResponseCode == '0') {
      setIsSuccess(true);
    }
    const errorKeys = Object.keys(errorMessages);
    if (errorKeys.includes(ResponseCode)) {
      setErrorMessage(errorMessages[ResponseCode as keyof typeof errorMessages]);
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (setFieldValue?: Function) => {
    if (setFieldValue) {
      setFieldValue('aggrement', true);
    }
    setOpen(false);
  };

  const agreementData: any = useSelector(storeAccount);

  const { agreementContent } = agreementData;

  useEffect(() => {
    dispatch(
      onGetAgreementRequest({
        AgreementType: 15,
        Language: currentLanguage === 'tr' ? 'tr-TR' : 'en-EN',
        isSignupProcess: true,
      })
    );
  }, []);

  const onChangeUserName = (e: ChangeEvent<HTMLInputElement>, setFieldValue: Function) => {
    let inputValue = e.target.value;
    const clearValue = inputValue.replaceAll(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
    if (clearValue.length < 32) {
      setFieldValue('instagramUserName', `@${clearValue}`);
    }
    if (clearValue.length === 0) {
      setFieldValue('instagramUserName', '');
    }
  };

  const closeErrorModal = () => {
    setErrorMessage('');
  };

  const successScreen = () => {
    return (
      <div id="lottery_success">
        <SuccessIconLarge width={ICON_SIZE} height={ICON_SIZE} />
        <Typography variant="h3">
          <Box marginTop={gutters.big} fontWeight={600} textAlign="center">
            {web_raffle_instagram_contest_success_page_title}
          </Box>
        </Typography>
        <Typography align="center">
          <Box fontSize={fontSizes.regular} mt={gutters.regular} color={'#606375'}>
            {web_raffle_instagram_contest_success_page_desc}
          </Box>
        </Typography>
        <Box width={'100%'} marginTop={gutters.large}>
          <Button
            onClick={() => {
              history.push(routePath.welcome);
            }}
            variant="contained"
            fullWidth
            color="secondary"
            type="submit"
          >
            {web_raffle_instagram_contest_success_page_button}
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <>
      <WelcomeHeader />
      {isSuccess ? (
        successScreen()
      ) : (
        <div id="lotteryForm">
          <FormErrorWrapper formName={formNames.addUserAdress} resetFormOnRouteChange>
            <Typography variant="h3" align="center">
              <Box className="lotteryForm__title">{web_raffle_instagram_contest_page_title}</Box>
            </Typography>
            <Typography variant="h3">
              <Box className="lotteryForm__content" fontSize={fontSizes.regular}>
                {web_raffle_instagram_contest_desc}
              </Box>
            </Typography>
            <Formik
              initialValues={LotteryFormSchemaInitialValues}
              validationSchema={LotteryFormSchema}
              onSubmit={handleSubmit}
              {...formikGlobalConfig}
            >
              {(formikProps) => {
                const {
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values: { nameSurname, aggrement, tckn, instagramUserName },
                  errors: {
                    nameSurname: errorNameSurname,
                    aggrement: errorAggrement,
                    tckn: errorTckn,
                    instagramUserName: errorInstagramUserName,
                  },
                  touched: {
                    nameSurname: touchedNameSurname,
                    tckn: touchedTckn,
                    instagramUserName: touchedInstagramUserName,
                    aggrement: touchedAggrement,
                  },
                } = formikProps;
                return (
                  <FormWrapper onSubmit={formikProps.handleSubmit}>
                    <Box>
                      <Box marginBottom={gutters.small}>
                        <TextField
                          className="lotteryForm__adressDetail"
                          fullWidth
                          variant="filled"
                          name="nameSurname"
                          value={nameSurname}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const isValid = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(inputValue) && inputValue.length <= 50;
                            if (isValid) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          label={web_raffle_instagram_contest_card_owner_name_surname}
                          inputProps={{
                            inputMode: 'text',
                            maxLength: 50,
                          }}
                          error={getError(errorNameSurname, touchedNameSurname)}
                          helperText={getErrorMessage(errorNameSurname, touchedNameSurname)}
                          itemID="adressDetailInputTest"
                        />
                      </Box>
                      <Box marginBottom={gutters.small}>
                        <TextField
                          className="lotteryForm__adressDetail"
                          fullWidth
                          variant="filled"
                          name="tckn"
                          value={tckn}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const isValid = /^[0-9]*$/.test(inputValue) && inputValue.length <= 11;
                            if (isValid) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          label={web_raffle_instagram_contest_tckn}
                          inputProps={{
                            inputMode: 'numeric',
                            maxLength: 11,
                          }}
                          error={getError(errorTckn, touchedTckn)}
                          helperText={getErrorMessage(errorTckn, touchedTckn)}
                          itemID="adressDetailInputTest"
                        />
                      </Box>
                      <Box marginBottom={gutters.small}>
                        <TextField
                          className="lotteryForm__adressDetail"
                          fullWidth
                          variant="filled"
                          name="instagramUserName"
                          value={instagramUserName}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeUserName(e, formikProps.setFieldValue)
                          }
                          onBlur={handleBlur}
                          label={web_raffle_instagram_contest_instagram_user_name}
                          inputProps={{
                            inputMode: 'text',
                            style: { textTransform: 'none' },
                          }}
                          error={getError(errorInstagramUserName, touchedInstagramUserName)}
                          helperText={getErrorMessage(errorInstagramUserName, touchedInstagramUserName)}
                          itemID="adressDetailInputTest"
                        />
                      </Box>
                      <Box className="flexAlignCenter">
                        <CheckboxUnderlineLabel
                          text={web_raffle_instagram_contest_aggreement_name_desc}
                          onUnderlineTextClick={handleOpen}
                          verticallyCentered
                          name="aggrement"
                          color="primary"
                          onChange={handleChange}
                          checked={aggrement}
                          defaultChecked={false}
                          errorText={getErrorMessage(errorAggrement, touchedAggrement)}
                          itemID="showAydınlatmaMetniBtnTest"
                        />
                        <DialogWithActions
                          alignTitle="left"
                          closeIcon
                          primaryButton={{
                            label: web_btn_okay,
                            handleClick: () => handleClose(formikProps.setFieldValue),
                          }}
                          open={open}
                          title={web_raffle_instagram_contest_popup_title}
                          text={agreementContent?.AgreementContent || ''}
                          hide={() => handleClose()}
                        />
                        <DialogWithActions
                          alignTitle="left"
                          closeIcon
                          primaryButton={{
                            label: web_btn_okay,
                            handleClick: closeErrorModal,
                          }}
                          open={!!errorMessage}
                          title={web_raffle_instagram_contest_warning_popup_header}
                          text={errorMessage}
                          hide={closeErrorModal}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth
                        itemID="addUserAdressBtnTest"
                      >
                        {web_raffle_instagram_contest_button_desc}
                      </Button>
                    </Box>
                  </FormWrapper>
                );
              }}
            </Formik>
          </FormErrorWrapper>
        </div>
      )}
    </>
  );
};

export default LotteryForm;
