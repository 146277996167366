export enum MasterPassTokenEnum {
  CHECK_MASTERPASSS = 1,
  REGISTER = 2,
  CARD_LIST = 3,
  REGISTER_PURCHASE = 4,
  RECURING_PAYMENT = 5,
  DELETE_CARD = 6,
  INITIATE_RECURRING_PAYMENT = 7,
  OTP = -1,
  RESEND_OTP = -2, //CUSTOM VALUE
  LINK = 22,
}
export enum MasterPassRefType {
  CARD_BALANCE = 2,
  ABONMAN = 7,
  DIGITAL_CARD_BALANCE = 6,
  DIGITAL_ABONMAN_BALANCE = 10,
}
export enum MasterPassFormEnum {
  CHECK_MASTERPASSS = 'checkMP-form',
  UPDATE_USER = 'updateUser-form',
  OTP = 'otp-form',
  DELETE_CARD = 'delete-form',
  LINK_TO_MP = 'linkCardtoMP-form',
  CARD_LIST = 'list-form',
  REGISTER = 'register-form',
  PURCHASE_FORM = 'purchase-form',
  INITIATE_RECURRING_FORM = 'recurringPayment-form',
  LINK_TO_CARD = 'linkCardtoMP-form',
}
export enum MasterPassAccountStatusEnum {
  NO_ACCOUNT = '0000000',
  YES_ACCOUNT_NO_CARD = '0100000',
  BLOCKED_ACCOUNT = '0100100',
  NO_LINKED = '0110001100000000',
}
export enum CardTransactionListTypeEnum {
  ALL,
  PAYMENT,
  LOAD,
}

export enum CardApplicationResponseTypeEnum {
  CANT_TAKE,
  CAN_TAKE,
  ALREADY_HAVE,
  FOREIGN,
}

export enum ApprovalStatusEnum {
  REJECT = 'R',
  PENDING = 'P',
  WAS_CREATED = 'S',
  WAITING_CREATE = 'W',
  CHECK = 'C',
  WAITING_APPLICATION = 'X',
}

export enum ApprovalRejectEnum {
  REJECT,
  INFORMATION_ERROR,
  PHOTO_ERROR,
  REPETETIVE_ERROR,
  EMPTY,
  SCAN_ERROR,
  OTHER_ERROR,
}

export enum PermissionStatus {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied',
}

export enum VIEW_TYPE {
  MAP,
  LIST,
}
