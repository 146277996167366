import { Dispatch, SetStateAction } from 'react';
import { PermissionStatus } from './Enums';

export const checkCameraPermission = async (action: Dispatch<SetStateAction<boolean>>) => {
  if (navigator.permissions && navigator.permissions.query) {
    const result = await navigator.permissions.query({ name: 'camera' });
    const permissionStatus = result.state;
    setPermissionStatus(permissionStatus, action);

    result.onchange = (e: any) => {
      setPermissionStatus(e.currentTarget.state, action);
    };
  } else {
    setPermissionStatus(PermissionStatus.GRANTED, action);
  }
};

export const requestCameraPermission = async (action: Dispatch<SetStateAction<boolean>>) => {
  const permission: MediaStreamConstraints = { video: true, audio: false };
  navigator.getUserMedia(
    { ...permission },
    function (localMediaStream) {
      setPermissionStatus(PermissionStatus.GRANTED, action);
    },
    function (err) {
      console.log(err);
    }
  );
};

export const setPermissionStatus = (state: string, action: Dispatch<SetStateAction<boolean>>) => {
  switch (state) {
    case PermissionStatus.GRANTED:
      action(true);
      break;
    default:
      requestCameraPermission(action);
      break;
  }
};
