import { useQuery } from 'Hooks';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { generateLanguageParam } from 'Utils';
// @ts-ignore
import pdf from '../../Assets/aydinlatmaMetni.pdf';
// @ts-ignore
import pdfEn from '../../Assets/aydinlatmaMetniEn.pdf';

import './Clarification.scss';
type ClarificationProps = {};

const Clarification: FC<ClarificationProps> = ({}) => {
  const { innerWidth } = window;

  const url = useQuery();

  const langFromMobile = url.get('lang');

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const lang = generateLanguageParam();

  const renderLanguage = !langFromMobile ? (lang === 'tr-TR' ? pdf : pdfEn) : langFromMobile === 'tr-TR' ? pdf : pdfEn;

  return (
    <div id="clarification">
      <Document
        className="clarification"
        file={renderLanguage}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            width={innerWidth}
            className="clarification__page"
            scale={1.0}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );
};
export default Clarification;
