import { FC, Fragment, useState } from 'react';
import { DialogWithActions } from 'Components';
import { useLocalization } from 'Hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAlreadyHave,
  closeCantApplyDialog,
  closeCantApplyForeign,
  closeDeletePrivilegedDialog,
  storeCardTransactions,
  closeOnOnlineApplicationDialog,
} from 'Stores/CardTransactions';
import './CardApplication.scss';
import { applicationStore, clearApplicationData, closeEmptyStreetError } from 'Stores/CardApplication';
import { routePath } from 'Navigator/routes';

const CardApplicationErrors = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeApplication, setActiveApplicaton] = useState<boolean>(false);

  const {
    isOpenDeletePrivilegedDialog,
    isOpenCantApplyDialog,
    isOpenAlreadyHave,
    isOpenCantApplyForeign,
    isOpenOnlineAppcationCardDialog,
    onOnlineApplicationCardStatusCode,
  } = useSelector(storeCardTransactions);

  const { emptyStreetError } = useSelector(applicationStore);

  const {
    web_card_avaliable_popup_title,
    web_card_avaliable_popup_desc,
    web_card_avaliable_popup_button,
    web_btn_vazgec,
    web_btn_okay,
    web_cant_apply_popup_title,
    cant_apply_popup_title,
    web_card_already_exists,
    web_cant_apply_card_foreign_people,
    web_cant_apply_this_card,
    web_active_application,
    web_card_incorrect_address_alert_title,
  } = useLocalization();

  const cmsData: any = useLocalization();

  const deletePrivilegedCardsAndCreateNew = () => {
    dispatch(clearApplicationData({ withoutCard: true }));
    dispatch(closeDeletePrivilegedDialog());
    history.push(routePath.cardApplicationSteps);
  };

  return (
    <Fragment>
      <DialogWithActions
        alignTitle="left"
        primaryButton={{ label: web_card_avaliable_popup_button, handleClick: deletePrivilegedCardsAndCreateNew }}
        secondaryButton={{ label: web_btn_vazgec, handleClick: () => dispatch(closeDeletePrivilegedDialog()) }}
        open={isOpenDeletePrivilegedDialog}
        hide={() => dispatch(closeDeletePrivilegedDialog())}
        title={web_card_avaliable_popup_title}
        text={web_card_avaliable_popup_desc}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeCantApplyDialog()),
        }}
        open={isOpenCantApplyDialog}
        hide={() => dispatch(closeCantApplyDialog())}
        title={web_cant_apply_popup_title}
        text={web_cant_apply_this_card}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeAlreadyHave()),
        }}
        open={isOpenAlreadyHave}
        hide={() => dispatch(closeAlreadyHave())}
        title={cant_apply_popup_title}
        text={web_card_already_exists}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeCantApplyForeign()),
        }}
        open={isOpenCantApplyForeign}
        hide={() => dispatch(closeCantApplyForeign())}
        title={cant_apply_popup_title}
        text={web_cant_apply_card_foreign_people}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => setActiveApplicaton(false),
        }}
        open={activeApplication}
        hide={() => () => setActiveApplicaton(false)}
        title={cant_apply_popup_title}
        text={web_active_application}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeOnOnlineApplicationDialog()),
        }}
        open={!!isOpenOnlineAppcationCardDialog}
        hide={() => () => dispatch(closeOnOnlineApplicationDialog())}
        title={cant_apply_popup_title}
        text={cmsData[onOnlineApplicationCardStatusCode || 'Missing Key:' + 'onOnlineApplicationCardStatusCode']}
      />
      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeEmptyStreetError()),
        }}
        open={!!emptyStreetError}
        hide={() => () => dispatch(closeEmptyStreetError())}
        title={web_card_incorrect_address_alert_title}
        text={
          'Adresinizde sokak bilgisi eksik lütfen Hesabım > Bilgilerim > Adres kısmından adresinize sokak bilgisi ekleyiniz.'
        }
      />
    </Fragment>
  );
};
export default CardApplicationErrors;
