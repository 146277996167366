import * as React from 'react';

function TinyCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className="tinyCardIcon"
      {...props}
    >
      <defs>
        <path
          id="wjg0dwqxua"
          d="M11 0c1.657 0 3 1.343 3 3v14c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h8zm2 4.494C10.212 1.66 6 3.038 6 7.11s7 8.518 7 8.518V4.494z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-155 -1731) translate(0 1681) translate(155.028 50)" />
              <g transform="translate(-155 -1731) translate(0 1681) translate(155.028 50) translate(5 2)">
                <mask id="nguw2e3jub" fill="#fff">
                  <use xlinkHref="#wjg0dwqxua" />
                </mask>
                <use fill="#383C52" fillRule="nonzero" xlinkHref="#wjg0dwqxua" />
                <g fill="currentColor" mask="url(#nguw2e3jub)">
                  <path d="M0 0H24V24H0z" transform="translate(-5 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TinyCardIcon;
