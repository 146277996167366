import { FC, useCallback, useState, Fragment } from 'react';
import { ArrowLeftIcon, DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { useHistory, useLocation } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { FAQFooter } from './FAQFooter';
import { FAQDetail } from './FAQDetail';
import { FAQList } from './FAQList';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AccountState, onGetFaqRequest, storeAccount } from 'Stores/Account';
import { accountIsLoading } from 'Stores/Account';
import { useSelector } from 'react-redux';

import './FAQ.scss';
import { storeCmsData } from 'Stores/App';

const FAQ: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(accountIsLoading);
  const cmsData = useSelector(storeCmsData);

  const userData: AccountState = useSelector(storeAccount);
  const data = userData.faq?.data.Questions;

  const { pathname } = useLocation();

  const [step, setStep] = useState<number>(0);
  const [answer, setAnswer] = useState<string>('');
  const [question, setQuestion] = useState<string>('');

  const { web_title_faq } = useLocalization();

  useEffect(() => {
    if (!data && pathname === routePath.faq) {
      dispatch(onGetFaqRequest());
    }

    if (pathname !== routePath.faq) {
      setStep(0);
      dispatch(onGetFaqRequest());
    }
    dispatch(onGetFaqRequest());
  }, [cmsData]);

  const handleClick = (a: string, q: string) => {
    setAnswer(a);
    setQuestion(q);
    setStep(1);
  };

  const List = useCallback(() => <FAQList handleClick={handleClick} />, []);

  return (
    <Fragment>
      {pathname === routePath.faq ? (
        <DashboardLayout backButton backAction={step === 1 ? () => setStep(0) : undefined}>
          <div id="faq">
            <Typography variant="h3" align="center">
              <Box mb={gutters.large} fontWeight={600}>
                {web_title_faq}
              </Box>
            </Typography>
            <Box mb={gutters.big}></Box>
            {!isLoading && (
              <Fragment>
                <Box>
                  {step === 0 && <List />}
                  {step === 1 && <FAQDetail Answer={answer} Question={question} />}
                </Box>
                <FAQFooter />
              </Fragment>
            )}
          </div>
        </DashboardLayout>
      ) : (
        <div id="faq">
          <ArrowLeftIcon
            onClick={() => {
              step === 1 ? setStep(0) : history.goBack();
            }}
          />
          <Typography variant="h3" align="center">
            <Box mb={gutters.large} fontWeight="bold" fontSize={fontSizes.big}>
              {web_title_faq}
            </Box>
          </Typography>
          <Box mb={gutters.big} />
          {step === 0 && <List />}
          {step === 1 && <FAQDetail Answer={answer} Question={question} />}
          <FAQFooter />
        </div>
      )}
    </Fragment>
  );
};

export default FAQ;
