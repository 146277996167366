import { FC } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Dialog, Divider } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { CloseIcon } from 'Components';
import { cardBalanceNormalize } from 'Utils';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { useSelector } from 'react-redux';
import { creditCardTransactionsStore } from 'Stores/CreditCardTransactions';
import moment from 'moment';

import './TransactionDetail.scss';

type TransactionDetailProps = {
  isVisible: boolean;
  hide: () => void;
};

const TransactionDetail: FC<TransactionDetailProps> = ({ isVisible, hide }) => {
  const {
    web_title_transaction_popup,
    web_transaction_popup_transaction_type,
    web_transaction_popup_transaction_fee,
    web_transaction_popup_transaction_date,
    web_lbl_subscription_transaction_fee,
    web_desc_total_amount,
    web_subscription_detail_popup_desc,
  } = useLocalization();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const { commissionAmount } = useSelector(creditCardTransactionsStore);

  const activeCardPrice = cardData?.cardSeasonTicketPrice?.SeasonTicketPrice;

  const transactions = [
    {
      title: web_transaction_popup_transaction_type,
      desc: web_subscription_detail_popup_desc,
    },
    {
      title: web_transaction_popup_transaction_date,
      desc: moment(new Date()).format('DD.MM.YYYY'),
    },
    {
      title: web_transaction_popup_transaction_fee,
      desc: `${cardBalanceNormalize(activeCardPrice)} TL`,
    },
    {
      title: web_lbl_subscription_transaction_fee,
      desc: `${cardBalanceNormalize(commissionAmount)} TL`,
    },
    {
      title: web_desc_total_amount,
      desc: `${cardBalanceNormalize(
        activeCardPrice && activeCardPrice + (commissionAmount ? commissionAmount : 0)
      )} TL`,
    },
  ];

  return (
    <Dialog open={isVisible} onClose={hide}>
      <div className="dialogContainer ">
        <Box id="transactionDetail">
          <CloseIcon onClick={() => hide()} />
          <Typography variant="h3" align="center">
            <Box fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.large}>
              {web_title_transaction_popup}
            </Box>
          </Typography>
        </Box>
        <Box>
          <Divider />
          {transactions.map((item, index) => (
            <Box key={index}>
              <Box padding={fontSizes.regular}>
                <Typography variant="body1">
                  <Box className="transactionDetail__itemTitle">{item?.title}</Box>
                </Typography>
                <Typography variant="h3">
                  <Box fontSize={fontSizes.regular} fontWeight="bold">
                    {item?.desc}
                  </Box>
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </div>
    </Dialog>
  );
};

export default TransactionDetail;
