import * as React from 'react';

function PasswordHideIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      className="passwordShowHideIcon"
    >
      <defs>
        <path
          id="exxebe5d1a"
          d="M17.81 13.39c1.76-1.446 2.902-3.51 3.19-5.77.043-.357-.108-.71-.396-.926-.288-.216-.67-.262-1-.12-.33.141-.561.449-.604.806-.494 3.486-3.479 6.078-7 6.078S5.494 10.866 5 7.38c-.043-.357-.273-.665-.604-.806-.33-.142-.712-.096-1 .12-.288.216-.439.569-.396.926.286 2.258 1.423 4.322 3.18 5.77l-2.3 2.32c-.34.397-.317.988.052 1.358.37.369.961.392 1.358.052l2.61-2.6c.97.49 2.02.802 3.1.92V19c0 .552.448 1 1 1s1-.448 1-1v-3.56c1.08-.118 2.13-.43 3.1-.92l2.61 2.6c.397.34.988.317 1.358-.052.369-.37.392-.961.052-1.358l-2.31-2.32z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-912 -402) translate(490 388) translate(422 14)">
              <path d="M0 0H24V24H0z" />
              <mask id="524s50brxb" fill="#fff">
                <use xlinkHref="#exxebe5d1a" />
              </mask>
              <g fill="#C3C4CB" mask="url(#524s50brxb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PasswordHideIcon;
