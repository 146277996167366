import { axiosHelper } from 'Services/AxiosHelper';
import { onMicroCreditBankProcessBodyData } from 'Config/ServiceConfig';

const onMicroCreditBankProcess = async (params: onMicroCreditBankProcessBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      data: params,
    })
  ).data;

export default onMicroCreditBankProcess;
