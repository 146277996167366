import * as React from 'react';

function BigTransactionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="bigTransactionIcon"
      {...props}
    >
      <defs>
        <path
          id="iq1scgkhla"
          d="M16.667 0c9.204 0 16.666 7.462 16.666 16.667 0 9.204-7.462 16.666-16.666 16.666C7.462 33.333 0 25.871 0 16.667 0 7.462 7.462 0 16.667 0zM14.37 16.237c-.393-.305-.96-.278-1.32.083l-3.552 3.55-.072.046c-.023.017-.046.034-.068.053l-.039.034-.042.004-.116.02c-.416.1-.73.458-.764.895l-.047.05-.083.094c-.28.36-.28.866 0 1.225l.083.095.05.05v.014l.02.114c.097.409.445.721.872.764l3.536 3.536.095.083c.36.28.866.28 1.225 0l.095-.083.942-.943.084-.094c.28-.36.28-.866 0-1.226l-.084-.094-1.172-1.174H24.06l.117-.006c.497-.058.883-.48.883-.994V21l-.006-.117c-.058-.497-.48-.883-.994-.883h-9.976l1.323-1.323.083-.094c.305-.393.277-.96-.083-1.32l-.943-.943zm5.928-9.924c-.392-.39-1.025-.389-1.415.002l-.937.94c-.389.39-.389 1.021 0 1.412l1.326 1.332L9.333 10c-.552 0-1 .448-1 1v1.333c0 .553.448 1 1 1h10.01l-1.177 1.184-.083.094c-.303.392-.275.958.084 1.318l.937.94.094.083c.392.306.959.279 1.32-.081l.002-.003 3.523-3.536c.493-.02.894-.399.95-.882l.004-.08-.011.014c.383-.386.388-1.005.014-1.396-.006-.508-.39-.924-.883-.981L24.013 10l-.03-.027c-.044-.037-.09-.07-.14-.099L20.3 6.316l-.002-.003z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H40V40H0z" transform="translate(-794 -263) translate(726 243) translate(68 20)" />
              <g transform="translate(-794 -263) translate(726 243) translate(68 20) translate(3.333 3.333)">
                <mask id="sh9g648a5b" fill="#fff">
                  <use xlinkHref="#iq1scgkhla" />
                </mask>
                <use fill="#383C52" xlinkHref="#iq1scgkhla" />
                <g fill="currentColor" mask="url(#sh9g648a5b)">
                  <path d="M0 0H40V40H0z" transform="translate(-3.333 -3.333)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BigTransactionIcon;
