import { FC, Fragment, memo, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { AccountState, onGetLoginLogListRequest, storeAccount } from 'Stores/Account';
import { LogItem } from './LogItem';

import './LoginInformation.scss';

type LoginInformationProps = {};

const LoginInformation: FC<LoginInformationProps> = memo(() => {
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const accountData: AccountState = useSelector(storeAccount);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const { loginLogList } = accountData;

  const { web_last_login_information } = useLocalization();

  useEffect(() => {
    dispatch(onGetLoginLogListRequest({ CustomerNumber: customerNumber, SessionToken: sessionToken }));
  }, []);

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="loginInformation">
          <Typography variant="h3" align="center">
            <Box mb={gutters.large} fontWeight={600}>
              {web_last_login_information}
            </Box>
          </Typography>
          {loginLogList &&
            loginLogList.map((item, index) => (
              <LogItem {...item} isLast={index === loginLogList.length - 1} key={index} />
            ))}
        </div>
      </Fragment>
    </DashboardLayout>
  );
});
export default LoginInformation;
