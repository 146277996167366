import * as React from 'react';

function BigCirclePlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      {...props}
    >
      <defs>
        <path
          id="hrxiv7n4ba"
          d="M40 6.667C21.59 6.667 6.667 21.59 6.667 40 6.667 58.41 21.59 73.333 40 73.333c18.41 0 33.333-14.924 33.333-33.333 0-8.84-3.512-17.319-9.763-23.57C57.32 10.179 48.84 6.667 40 6.667zm11 37h-7.333V51c0 2.025-1.642 3.667-3.667 3.667S36.333 53.025 36.333 51v-7.333H29c-2.025 0-3.667-1.642-3.667-3.667s1.642-3.667 3.667-3.667h7.333V29c0-2.025 1.642-3.667 3.667-3.667s3.667 1.642 3.667 3.667v7.333H51c2.025 0 3.667 1.642 3.667 3.667S53.025 43.667 51 43.667z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-680 -468) translate(620 392) translate(51.5 76) translate(8.5)">
                <path d="M0 0H80V80H0z" />
                <mask id="66ifeu4ibb" fill="#fff">
                  <use xlinkHref="#hrxiv7n4ba" />
                </mask>
                <use fill="#383C52" fillRule="nonzero" xlinkHref="#hrxiv7n4ba" />
                <g fill="#D7D8DC" mask="url(#66ifeu4ibb)">
                  <path d="M0 0H80V80H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BigCirclePlus;
