import { Box, Grid } from '@material-ui/core';
import { FC, memo, useState } from 'react';
import { cardItemDetailType } from '../constant';
import { useLocalization } from 'Hooks';
import { SettingsIcon, CopyIcon, ArrowLeftIcon } from 'Components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { splitMifareIdAndAliasNumber } from 'Utils';
import { Tooltip } from 'Views/CardDetails/CardDetailsHeader/Tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './OtherCardDetailsHeader.scss';

type OtherCardDetailsHeaderParams = {
  cardItem: cardItemDetailType;
  cardColorClass?: string;
  cardPath?: string;
};

const OtherCardDetailsHeader: FC<OtherCardDetailsHeaderParams> = memo(({ cardItem, cardColorClass, cardPath }) => {
  const { web_card_detail_card_number, web_card_detail_card_number_copied } = useLocalization();

  const history = useHistory();

  const [show, setShow] = useState(false);

  return (
    <div id="otherCardDetailsHeader">
      {show && <Tooltip title={web_card_detail_card_number_copied} onClose={() => setShow(false)} />}
      <Box className={`otherCardDetailsHeader__${cardColorClass}`}>
        <Box className="otherCardDetailsHeader__container">
          <ArrowLeftIcon onClick={() => history.push(routePath.cards)} className="otherCardDetailsHeader__backIcon" />
          {cardItem?.NickName && <Box className="otherCardDetailsHeader__title">{cardItem.NickName}</Box>}
          {cardItem?.AliasNumber && (
            <Grid container className="otherCardDetailsHeader__inner-container">
              <Grid item xs={12}>
                <Box className="otherCardDetailsHeader__item">
                  <Box className="otherCardDetailsHeader__cardinfo-desc">{web_card_detail_card_number}</Box>
                  <Box display="flex">
                    <Box className="otherCardDetailsHeader__cardinfo-value">
                      {splitMifareIdAndAliasNumber(cardItem?.AliasNumber)}
                    </Box>
                    <CopyToClipboard onCopy={() => {}} text={cardItem?.AliasNumber}>
                      <CopyIcon onClick={() => setShow(true)} />
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          <SettingsIcon
            onClick={() =>
              history.push(routePath.cardSettings, {
                isOtherCard: true,
              })
            }
          />
          <LazyLoadImage src={cardPath} alt="" className="otherCardDetailsHeader__img" effect="opacity" />
        </Box>
      </Box>
    </div>
  );
});

export default OtherCardDetailsHeader;
