import $ from 'jquery';

const parseFormData = (jsonData: any, formId: string) => {
  const htmlString = `
    <form action="" method="POST" id="${formId}" class="form-horizontal">
        ${Object.keys(jsonData)
          .map((key) => `<input type="hidden" name="${key}" value="${jsonData[key]}" />`)
          .join('')}
       
    </form>`;

  const dom = document.createElement('div');
  dom.innerHTML = htmlString;
  return $(dom).find(`#${formId}`);
};

export default parseFormData;
