import * as React from 'react';

function GreenTickBig(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      className="greenTickBigIcon"
      {...props}
    >
      <defs>
        <path
          id="kh26ycvkua"
          d="M30 5C16.193 5 5 16.193 5 30s11.193 25 25 25 25-11.193 25-25c0-6.63-2.634-12.99-7.322-17.678C42.989 7.634 36.63 5 30 5zm10.75 19.025l-11.425 15c-.472.613-1.201.973-1.975.975-.77.004-1.498-.346-1.975-.95l-6.1-7.775c-.55-.706-.68-1.65-.344-2.48.336-.828 1.09-1.414 1.975-1.537.886-.123 1.77.236 2.319.942l4.075 5.2 9.45-12.5c.545-.715 1.43-1.084 2.32-.97.892.115 1.654.696 2 1.525.347.83.225 1.78-.32 2.495v.075z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-690 -260) translate(690 260)">
            <path d="M0 0H60V60H0z" />
            <mask id="2apdq54l1b" fill="#fff">
              <use xlinkHref="#kh26ycvkua" />
            </mask>
            <g fill="#3AC562" mask="url(#2apdq54l1b)">
              <path d="M0 0H60V60H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GreenTickBig;
