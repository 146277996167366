import { axiosHelper } from 'Services/AxiosHelper';
import { OnChangeCustomerInfoModel } from 'Models';
import { onChangeCustomerInfoReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnChangeCustomerInfo';

const onChangeCustomerInfo = async (params: onChangeCustomerInfoReqBodyData): Promise<OnChangeCustomerInfoModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onChangeCustomerInfo;
