// @ts-nocheck
import { getCmsParams, pathURls } from 'Config';
import { axiosHelper } from 'Services/AxiosHelper';
import { GetCmsModel } from 'Models';
import { getApiGWTokenCookie, getEnvironment, isBelbimRequestSuccess } from 'Utils';
import IsUsingTmobCms from 'Utils/IsUsingTmobCms';

const command = 'cms.getCMSTranslations';

const getCms = async ({ languageId }: getCmsParams): Promise<GetCmsModel> =>
  IsUsingTmobCms() && getEnvironment()
    ? await axiosHelper({
        method: 'post',
        data: { languageId, versionNumber: '0' },
        isCmsUrl: true,
      })
    : (
        await axiosHelper({
          method: 'post',
          gatewayData: {
            command,
            token: getApiGWTokenCookie(),
          },
          data: { languageId, versionNumber: '2918' },
          isTmobLabsUrl: true,
        })
      ).data;

export default getCms;
