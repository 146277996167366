import CryptoJS from 'crypto-js';
import { CreateInstructionFromQRAccountBodyData, formNames } from 'Config';
import { CreateInstructionFromQRAccountModel } from 'Models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createInstructionFromQRAccount } from 'Services/Queries';
import { resetFormError, setFormError } from 'Stores/Form';
import { UserState, userStore } from 'Stores/User';
import { isBelbimRequestSuccess } from 'Utils';
import {
  balanceTransferStore,
  createInstructionFromQRAccountFail,
  createInstructionFromQRAccountRequest,
  createInstructionFromQRAccountSuccess,
} from './slices';
import { BalanceTransferState } from './types';
import { push } from 'connected-react-router';
import { routePath } from 'Navigator/routes';

function createHash(text: string): string {
  return CryptoJS.SHA1(text).toString(CryptoJS.enc.Base64);
}

function* handleCreateInstructionFromQRAccountRequestSaga() {
  try {
    yield put(resetFormError());

    const userData: UserState = yield select(userStore);
    const balanceTransferData: BalanceTransferState = yield select(balanceTransferStore);

    const requestBody = {
      Amount: balanceTransferData.transferAmount,
      ClientData: {
        clientKey: 'MobileClient',
        clientPass: 'MobileClient',
        clientConnectionIndex: 0,
      },
      ChannelId: 4,
      CustomerNumber: userData.customerNumber,
      MifareId: balanceTransferData.selectedReceiverCard?.MifareId,
      RandomValue: new Date().getTime().toString(),
      SessionToken: userData.sessionToken,
      MsgHash: '',
    } as CreateInstructionFromQRAccountBodyData;
    requestBody.MsgHash = createHash(
      (requestBody.ClientData?.clientKey || '') +
        requestBody.ClientData?.clientPass +
        requestBody.ClientData?.clientConnectionIndex +
        userData.customerNumber +
        requestBody.SessionToken +
        requestBody.Amount +
        requestBody.RandomValue
    );

    const response: CreateInstructionFromQRAccountModel = yield call(() => createInstructionFromQRAccount(requestBody));
    const { requestSuccess, responseCode } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(createInstructionFromQRAccountSuccess(response));
      yield put(push(routePath.balanceTransferSuccess));
    } else {
      yield put(setFormError({ errorCode: response.data.ResponseDescription, formName: formNames.balanceTransfer }));
      yield put(createInstructionFromQRAccountFail(responseCode));
    }
  } catch (error) {
    yield put(createInstructionFromQRAccountFail('-1'));
  }
}

function* createInstructionFromQRAccountSagaWatcher() {
  yield takeLatest(createInstructionFromQRAccountRequest, handleCreateInstructionFromQRAccountRequestSaga);
}
export default createInstructionFromQRAccountSagaWatcher;
