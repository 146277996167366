import { Box, Button, Link } from '@material-ui/core';
import { DialogTerms, DialogWithInput } from 'Components';
import { Loading } from 'Components/Loading';
import { MasterpassLogo } from 'Components/Svg';
import { localStorageKeys } from 'Config';
import { useLocalization, useModal } from 'Hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { FC, Fragment } from 'react';
import { colors, gutters } from 'Theme/Variables';
import { underlineParser } from 'Utils';

import './MasterpassArea.scss';

const MasterpassArea: FC = () => {
  const { web_desc_registered_credit_cards, web_btn_okay, web_masterpass_link_title } = useLocalization();

  const { beginText, endText, underlineText } = underlineParser(web_desc_registered_credit_cards);

  const { isVisible, show, hide } = useModal();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, [isVisible]);

  const language = localStorage.getItem(localStorageKeys.i18nextLng);

  const masterpassLinkEn = 'https://masterpassturkiye.com/TermsAndConditions.aspx?locale=en-US';
  const masterpassLinkTr = 'https://masterpassturkiye.com/TermsAndConditions.aspx?locale=tr-TR';

  const onLoadFrame = (obj: any) => {
    setLoading(false);
  };

  return (
    <>
      <Box id="masterpass-area">
        <MasterpassLogo />
        <Box ml={gutters.regular} fontSize="13px" color={colors.checkboxLabel}>
          {beginText}
          <Link onClick={show} underline={'always'} color={'textSecondary'}>
            {underlineText}
          </Link>
          {endText}
        </Box>
      </Box>
      <DialogWithInput alignTitle="left" hide={hide} open={isVisible} closeIcon>
        <Fragment>
          {loading && <Loading />}

          <iframe
            onLoad={onLoadFrame}
            style={{ width: '100%', height: 400, border: 0, zIndex: 2 }}
            src={language === 'en' ? masterpassLinkEn : masterpassLinkTr}
            sandbox="allow-forms allow-scripts allow-same-origin allow-presentation"
          />
        </Fragment>
      </DialogWithInput>
    </>
  );
};
export default MasterpassArea;
