import { LoginSchemaInputs } from 'Validations';
import { DeviceId, OSType, Port, AppVersion } from 'Services/Contants';
import { getPublicIpFromStorage } from 'Utils';
export const loginNormalize = (password: string, phoneNumber?: string, countryCode?: string) => {
  const formValues = {
    CountryCode: countryCode,
    CellPhone: phoneNumber,
    Password: password,
    DeviceId,
    OSType,
    IPAdress: getPublicIpFromStorage(),
    Port,
  };
  return formValues;
};

export const onConfirmSmsNormalize = (code: string, phoneNumber?: string, countryCode?: string) => {
  const formValues = {
    CountryCode: countryCode,
    CellPhone: phoneNumber,
    SmsToken: code,
    OSType,
    AppVersion,
    IPAdress: getPublicIpFromStorage(),
    Port,
  };

  return formValues;
};
