import { FC, memo } from 'react';
import { useCardType, useLocalization } from 'Hooks';
import { Box, Button } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useSelector, useDispatch } from 'react-redux';
import { showUserAddedCardDetail, storeCardTransactions } from 'Stores/CardTransactions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './AddCardSuccess.scss';

type AddCardSuccessProps = {
  isActive: boolean;
  isUserCard: boolean;
};

const AddCardSuccess: FC<AddCardSuccessProps> = memo(({ isActive, isUserCard }) => {
  const cardData = useSelector(storeCardTransactions);

  const history = useHistory();
  const dispatch = useDispatch();

  const { btn_go_my_cards, web_btn_see_card_detail } = useLocalization();

  const decideCardItem = () => {
    if (isUserCard && cardData.onSaveCustomerCardModel) {
      return cardData.onSaveCustomerCardModel?.data.ProductCode;
    }
    if (!isUserCard && cardData.mbSaveRegisteredCardModel) {
      return cardData.mbSaveRegisteredCardModel?.data.ProductCode;
    }
  };

  const { cardPath, cardColor } = useCardType(decideCardItem());

  return (
    <div id="addCardSuccess">
      <Box marginTop={gutters.big}>
        <div className="add-card-success__container">
          <div className="add-card-success__img--wrapper">
            <LazyLoadImage
              src={cardPath}
              alt=""
              style={{ opacity: isActive ? '1' : '0.5' }}
              className={`add-card-success__img__${cardColor}`}
              effect="opacity"
            />
            <GreenTickBigFilledIcon />
          </div>
          <Box marginTop={gutters.big} fontWeight="bold">
            <Box fontWeight={600}>
              {!isActive && (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    history.push(routePath.cards);
                  }}
                  itemID="goMyCardsBtnTest"
                >
                  {btn_go_my_cards}
                </Button>
              )}
              {isActive && (
                <Box marginTop={gutters.small}>
                  <Box marginBottom={gutters.small}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        dispatch(
                          showUserAddedCardDetail({
                            isUserCard,
                            mifareId: cardData?.onSaveCustomerCardModel?.data.MifareId
                              ? cardData?.onSaveCustomerCardModel?.data.MifareId
                              : cardData?.mbSaveRegisteredCardModel?.data.MifareId,
                          })
                        );
                      }}
                      itemID="showAddedCardDetailBtnTest"
                    >
                      {web_btn_see_card_detail}
                    </Button>
                  </Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type="submit"
                    onClick={() => {
                      history.push(routePath.cards);
                    }}
                    itemID="goMyCardsBtnTest"
                  >
                    {btn_go_my_cards}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
});

export default AddCardSuccess;
