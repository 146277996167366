import * as React from 'react';

function TransportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="9pgvfdowua"
          d="M1.031 0C.461 0 0 .45 0 1.01v12.548c0 .56.46 1.004 1.031 1.004h2c.036-.018.078-.018.113 0h9.059c.035-.018.076-.018.111 0 .571 0 1.02-.444 1.02-1.004V1.01c0-.56-.449-1.01-1.02-1.01H1.031zm4.014.998h3.032c.243 0 .435.188.435.425v.061c0 .238-.192.426-.435.426H5.045c-.242 0-.435-.188-.435-.426v-.06c0-.238.193-.426.435-.426zM1.852 3.285h9.63c.344 0 .61.266.61.602v4.909c0 .336-.266.608-.61.608h-9.63c-.344 0-.622-.272-.622-.608v-4.91c0-.335.278-.601.622-.601zm.385 7.524c.657 0 1.193.523 1.193 1.174 0 .65-.536 1.18-1.193 1.18-.658 0-1.18-.53-1.18-1.18 0-.651.522-1.174 1.18-1.174zm8.872 0c.658 0 1.18.523 1.18 1.174 0 .65-.522 1.18-1.18 1.18-.657 0-1.193-.53-1.193-1.18 0-.651.536-1.174 1.193-1.174zm-9.903 3.984v1.478c0 .221.176.396.397.396h.97c.22 0 .397-.175.397-.396v-1.478H1.206zm9.17 0v1.478c0 .221.165.396.386.396h.981c.221 0 .398-.175.398-.396v-1.478h-1.765z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H20V20H0z"
                  transform="translate(-850 -174) translate(155 170) translate(687) translate(8 4)"
                />
                <g transform="translate(-850 -174) translate(155 170) translate(687) translate(8 4) translate(3.333 1.667)">
                  <mask id="2s8hanedlb" fill="#fff">
                    <use xlinkHref="#9pgvfdowua" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#9pgvfdowua" />
                  <g fill={props.fill ? props.fill : '#EE3C2D'} mask="url(#2s8hanedlb)">
                    <path d="M0 0H20V20H0z" transform="translate(-3.333 -1.667)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TransportIcon;
