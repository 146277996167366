import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useCardType, useDigitalAccount, useErrorMessage, useLocalization } from 'Hooks';
import { Box, Typography, TextField, Button, FormHelperText, InputAdornment, Link } from '@material-ui/core';
import { fontSizes } from 'Theme/Variables';
import { FormErrorWrapper, FormWrapper } from 'Components';
import { ChooseCard } from './ChooseCard';
import { useDispatch, useSelector } from 'react-redux';
import { CardTransactionsState, setActiveSelectedCard, storeCardTransactions } from 'Stores/CardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { BalanceTransfer, balanceTransferSchemaInitialValues, BalanceTransferSchemaInputs } from 'Validations';
import { formikGlobalConfig, formNames } from 'Config';

import './BalanceTransferStep.scss';
import { Formik } from 'formik';
import {
  balanceTransferStore,
  createInstructionFromQRAccountRequest,
  setSenderCard,
  setTransferAmount,
} from 'Stores/BalanceTransfer';
import { DigitalAccountCard } from 'Components/DigitalAccountCard';

type BalanceTransferStepProps = {};

const BalanceTransferStep: FC<BalanceTransferStepProps> = () => {
  const formikRef: any = useRef();
  const {
    web_btn_next,
    digital_card_to_physical_card_money_transfer_receiver_card,
    digital_card_to_physical_card_money_transfer_amount,
    digital_card_to_physical_card_money_transfer_enter_amount,
    digital_card_to_physical_card_money_transfer_receiver_select_a_card,
    web_money_transfer_sending_account,
  } = useLocalization();

  const dispatch = useDispatch();

  const { getError, getErrorMessage } = useErrorMessage();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const balanceTransferData = useSelector(balanceTransferStore);

  const digitalAccount = useDigitalAccount();
  const userCards: cardItemParams[] = cardData?.cards ?? [];
  const userPrimaryCard = userCards?.filter((item) => item.IsPrimary === true);
  const { cardColor, cardPath } = useCardType(userPrimaryCard[0]?.ProductCode);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  useEffect(() => {
    if (!balanceTransferData.selectedSenderCard && digitalAccount) {
      dispatch(setSenderCard(digitalAccount));
    }
  }, [digitalAccount]);

  useEffect(() => {
    // If user comes this screen from transactions show primary card
    if (userPrimaryCard && userPrimaryCard?.length > 0 && !balanceTransferData.selectedReceiverCard) {
      dispatch(
        setActiveSelectedCard({
          ...userPrimaryCard[0],
          cardColorClass: cardColor || '',
          cardPath: cardPath || '',
        })
      );
    }
  });

  const handleSubmit = (values: BalanceTransferSchemaInputs) => {
    if (balanceTransferData.selectedReceiverCard) {
      if (parseInt(values.amount) > 999) {
        dispatch(setTransferAmount(numberWithCommas(values.amount)) || '');
        dispatch(createInstructionFromQRAccountRequest());
      } else {
        dispatch(setTransferAmount(numberWithCommas(values.amount)) || '');
        dispatch(createInstructionFromQRAccountRequest());
      }
    }
  };

  function numberWithCommas(x: any) {
    // eslint-disable-next-line prettier/prettier
    const tempFirst: number = (x * 100) / 10 / 10;
    const tempNumber: string = tempFirst.toString();
    const parts = tempNumber.split('.');
    var concatNumber = tempNumber;
    // eslint-disable-next-line prettier/prettier
    if (parts.length == 1) {
      concatNumber = concatNumber.concat('.0');
    }
    if (parts.length > 1) {
      concatNumber = parts[0].concat('.');
      concatNumber = concatNumber.concat(parts[1][0]);
    }
    return concatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const setAllBalance = () => {
    formikRef.current.setFieldValue('amount', digitalAccount.balanceTotal.toFixed(2));
    formikRef.current.setFieldError('amount', undefined);
  };

  const isLetters = (str: any) => /^[A-Za-z]*$/.test(str);

  return (
    <FormErrorWrapper formName={formNames.balanceTransfer} resetFormOnRouteChange>
      <div id="balanceTransferStep">
        <Formik
          innerRef={formikRef}
          initialValues={balanceTransferSchemaInitialValues}
          validationSchema={BalanceTransfer(digitalAccount?.balanceTotal || 0)}
          onSubmit={handleSubmit}
          {...formikGlobalConfig}
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              handleSubmit,
              values: { amount },
              errors: { amount: errorAmount },
              touched: { amount: touchedAmount },
            } = formikProps;
            return (
              <Fragment>
                <Box>
                  <Typography variant="h6" align="center">
                    <Box fontWeight="bold" marginBottom={fontSizes.regular} textAlign="left">
                      {web_money_transfer_sending_account}
                    </Box>
                  </Typography>
                  <DigitalAccountCard cursor="default" marginBottom={'24px'} />
                </Box>
                <Box>
                  <ChooseCard
                    selectedCard={balanceTransferData.selectedReceiverCard || undefined}
                    title={digital_card_to_physical_card_money_transfer_receiver_card}
                  />
                  {errorMessage && !balanceTransferData.selectedReceiverCard && (
                    <FormHelperText error>
                      {digital_card_to_physical_card_money_transfer_receiver_select_a_card}
                    </FormHelperText>
                  )}
                </Box>
                <div className="balanceTransferStep__allBalanceButtonContainer">
                  <Typography variant="h6">
                    <Box fontWeight="bold" marginBottom={fontSizes.regular} marginTop={fontSizes.big}>
                      {digital_card_to_physical_card_money_transfer_amount}
                    </Box>
                  </Typography>
                  <Link onClick={setAllBalance} underline="none" style={{ cursor: 'pointer', alignSelf: 'center' }}>
                    <Box
                      className="balanceTransferStep__allBalanceButton"
                      textAlign="center"
                      fontSize={fontSizes.regular}
                    >
                      {/* {`${digital_card_to_physical_card_money_transfer_all_balance} (${digitalCard?.CardBalance?.toFixed(
                        2
                      ).replace('.', ',')} TL)`} */}
                    </Box>
                  </Link>
                </div>
                <Box>
                  <FormWrapper onSubmit={formikProps.handleSubmit}>
                    <Box className="balanceTransferStep__balanceTransferAmount">
                      <TextField
                        name="amount"
                        label={digital_card_to_physical_card_money_transfer_enter_amount}
                        variant="filled"
                        fullWidth
                        value={amount}
                        onChange={(e) => {
                          if (e.target.value.length <= 6 && e.target.value !== '0') {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        error={getError(errorAmount, touchedAmount)}
                        helperText={getErrorMessage(errorAmount, touchedAmount)}
                        type="number"
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment style={{ right: 20, position: 'absolute' }} position="end">
                              TL
                            </InputAdornment>
                          ),
                        }}
                        itemID="balanceTransferAmountInput"
                      />
                    </Box>
                  </FormWrapper>
                </Box>
                <Box style={{ marginTop: '30px' }}>
                  <Button
                    onClick={() => {
                      setErrorMessage(true);
                      handleSubmit();
                    }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    itemID="balanceTransferBtn"
                  >
                    {web_btn_next}
                  </Button>
                </Box>
              </Fragment>
            );
          }}
        </Formik>
      </div>
    </FormErrorWrapper>
  );
};

export default BalanceTransferStep;
