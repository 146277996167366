import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onCheckPhoneNumberBodyData } from 'Config/ServiceConfig';
import { OnCheckPhoneNumberModel } from 'Models';
import { RootState } from 'Stores/index';
import { WelcomeState } from './types';

export const initialState: WelcomeState = {
  isLoading: false,
  error: '',
  onCheckPhoneNumberData: undefined,
  CountryCode: '',
  Cellphone: '',
};

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    onCheckNumberRequest: (state: WelcomeState, { payload }: PayloadAction<onCheckPhoneNumberBodyData>) => {
      state.isLoading = true;
    },
    onCheckNumberRequestSuccess: (state: WelcomeState, action: PayloadAction<OnCheckPhoneNumberModel>) => {
      state.onCheckPhoneNumberData = action.payload;
      state.isLoading = false;
    },
    onCheckNumberRequestError: (state: WelcomeState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setUserNumber: (state: WelcomeState, { payload }: PayloadAction<onCheckPhoneNumberBodyData>) => {
      state.Cellphone = payload.Cellphone;
      state.CountryCode = payload.CountryCode;
    },
    setWelcomeStateNormalize: (state: WelcomeState) => state,
  },
});

export const storeWelcome = (state: RootState) => state.welcome;
export const storeIsLoading = (state: RootState) => state.welcome.isLoading;

export const {
  onCheckNumberRequest,
  onCheckNumberRequestSuccess,
  onCheckNumberRequestError,
  setUserNumber,
  setWelcomeStateNormalize,
} = welcomeSlice.actions;

export default welcomeSlice.reducer;
