import { FC, Fragment, useEffect, useState } from 'react';
import {
  DashboardLayout,
  FormErrorWrapper,
  FormWrapper,
  MaskedTextField,
  MasterPassIcon,
  Tooltip,
  CheckboxUnderlineLabel,
  DialogWithInput,
  DialogWithActions,
} from 'Components';
import {
  CreditCardTransactionsState,
  creditCardTransactionsStore,
  onGetMasterpassTokenRequest,
  resetCommissionAmount,
  setActiveSelectedCreditCard,
  setIsUserAddedCreditCardWithMasterpass,
  setLoadAmount,
  setUserTemporaryCreditCard,
} from 'Stores/CreditCardTransactions';
import { Box, TextField, Typography, Button, Grid } from '@material-ui/core';
import { useLocalization, useErrorMessage, useModal } from 'Hooks';
import { Formik } from 'formik';
import { formikGlobalConfig, formNames, inputMaskTypes, localStorageKeys } from 'Config';
import { gutters } from 'Theme/Variables';
import { AddCreditCardSchema, addCreditCardSchemaInitialValues } from 'Validations';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { expireDateUnmask, MasterPassAccountStatusEnum, MasterPassTokenEnum, spaceRemover } from 'Utils';
import {
  checkMasterPassAccountNormalize,
  masterPassRegisterNormalize,
  userTemporaryCreditCardNormalize,
} from 'Normalize';
import { RouteComponentProps, StaticContext, useHistory } from 'react-router';
import { CardTransactionsState, setLoadBalanceWithoutMasterpass, storeCardTransactions } from 'Stores/CardTransactions';
import { routePath } from 'Navigator/routes';
import './AddCreditCard.scss';
import { masterPassLinkToClientNormalize } from 'Normalize/MasterPassRequestNormalize';
import sanitizeHtml from 'Utils/SanitizeHtml';

type AddCreditCardProps = RouteComponentProps<{}, StaticContext, { isWithoutMp: boolean }>;

const masterpassLinkEn = 'https://masterpassturkiye.com/TermsAndConditions.aspx?locale=en-US';
const masterpassLinkTr = 'https://masterpassturkiye.com/TermsAndConditions.aspx?locale=tr-TR';

const AddCreditCard: FC<AddCreditCardProps> = ({ location: { state } }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const language = localStorage.getItem(localStorageKeys.i18nextLng);

  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const [isOpenRuleDialog, setIsOpenRuleDialog] = useState(false);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const userInUploadTLProcess: boolean = cardData.isUserInUploadTLProcess;
  const userInUploadSubscriptionProcess: boolean = cardData.isUserInUploadSubscriptionProcess;
  const userInAutomaticIntructionProcess: boolean = cardData.isUserInAutomatiInstructionProcess;
  const {
    web_title_add_credit_card,
    web_desc_add_creditcard__upload_tl,
    web_plc_add_credit_card_number,
    web_plc_add_credit_card_name,
    web_plc_add_credit_card_expiration_date_detail,
    web_plc_add_credit_card_cvv,
    web_plc_add_credit_card_expiration_date,
    web_check_masterpass_add_credit_card,
    web_btn_next,
    web_msg_credit_card_cvv,
    web_title_cvv_box,
    web_masterpass_link,
    web_masterpass_link_title,
    masterpass_saved_cards_popup_header,
    masterpass_saved_cards_popup_desc,
    masterpass_saved_cards_popup_btn,
  } = useLocalization();

  const { isVisible, show, hide } = useModal();

  const { getError, getErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (creditCardData.masterPassAccountStatus === MasterPassAccountStatusEnum.NO_LINKED) {
      setIsOpenRuleDialog(true);
    }
  }, []);

  useEffect(() => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CHECK_MASTERPASSS,
        request: {
          data: checkMasterPassAccountNormalize(),
        },
      })
    );
  }, [creditCardData.masterPassAccountStatus]);

  const handleSubmit = (values: typeof addCreditCardSchemaInitialValues) => {
    dispatch(setIsUserAddedCreditCardWithMasterpass(values.addCardToMasterPass));

    dispatch(resetCommissionAmount());
    dispatch(setLoadAmount(0));

    if (!values.addCardToMasterPass) {
      dispatch(setActiveSelectedCreditCard(userTemporaryCreditCardNormalize(values)));
      dispatch(
        setUserTemporaryCreditCard({
          Name: values.cardName,
          Value1: spaceRemover(values.creditCardNumber),
          ExpireDate: expireDateUnmask(values.expireDate),
          Cvv: values.cvv,
        })
      );
      if (userInUploadTLProcess) {
        if (state?.isWithoutMp) {
          dispatch(setLoadBalanceWithoutMasterpass(true));
          history.goBack();
          return;
        }
        history.push(routePath.addBalanceToCard);
      }
      if (userInUploadSubscriptionProcess) {
        history.push(routePath.uploadSubscription);
      }
      if (userInAutomaticIntructionProcess) {
        history.push(routePath.automaticInstruction);
      }
      return;
    }

    const cardNumber = spaceRemover(values?.creditCardNumber);
    const expireDate = expireDateUnmask(values?.expireDate);
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.REGISTER,
        request: {
          data: masterPassRegisterNormalize('', cardNumber, expireDate, values?.cvv, values?.cardName),
        },
      })
    );
  };

  return (
    <DashboardLayout
      backButton={!userInUploadSubscriptionProcess && !userInUploadTLProcess}
      backAction={
        userInUploadTLProcess || userInUploadSubscriptionProcess
          ? () => {
              history.goBack();
            }
          : undefined
      }
    >
      <div id="addCreditCard">
        <FormErrorWrapper formName={formNames.addCreditCard} resetFormOnRouteChange>
          <Typography variant="h3" align="center">
            <Box fontWeight={600} marginBottom={gutters.small}>
              {web_title_add_credit_card}
            </Box>
          </Typography>
          <Box className="addCreditCard__desc">
            <Typography variant="body1" align="center">
              {web_desc_add_creditcard__upload_tl}
            </Typography>
          </Box>
          <Formik
            initialValues={addCreditCardSchemaInitialValues}
            validationSchema={AddCreditCardSchema(
              userInUploadTLProcess || userInUploadSubscriptionProcess || userInAutomaticIntructionProcess
            )}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                setFieldValue,
                values: { creditCardNumber, expireDate, cvv, cardName, addCardToMasterPass },
                errors: {
                  creditCardNumber: errorCreditCardNumber,
                  expireDate: errorExpireDate,
                  cvv: errorCvv,
                  cardName: errorCardName,
                  addCardToMasterPass: errorCardToMasterPass,
                },
                touched: {
                  creditCardNumber: touchedCreditCardNumber,
                  expireDate: touchedExpireDate,
                  cvv: touchedCvv,
                  cardName: touchedCardName,
                  addCardToMasterPass: touchedAddToMasterPass,
                },
              } = formikProps;
              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  <Box className="addCreditCard__inputs">
                    <MaskedTextField
                      name="creditCardNumber"
                      label={web_plc_add_credit_card_number}
                      value={creditCardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 14 }}
                      mask={inputMaskTypes.userCardNo}
                      variant="filled"
                      fullWidth
                      error={getError(errorCreditCardNumber, touchedCreditCardNumber)}
                      helperText={getErrorMessage(errorCreditCardNumber, touchedCreditCardNumber)}
                      itemID="creditCardNumberInputTest"
                    />
                  </Box>
                  <Box className="addCreditCard__inputs addCreditCard__middleBlock">
                    <Grid container className="addCreditCard__innerContainer" spacing={1}>
                      <Grid item xs={7}>
                        <Box className="addCreditCard__expireDate">
                          <MaskedTextField
                            name="expireDate"
                            mask={inputMaskTypes.creditCardExpireDate}
                            label={web_plc_add_credit_card_expiration_date}
                            value={expireDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={web_plc_add_credit_card_expiration_date_detail}
                            error={getError(errorExpireDate, touchedExpireDate)}
                            helperText={getErrorMessage(errorExpireDate, touchedExpireDate)}
                            itemID="creditCardExpireDateInputTest"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box className="addCreditCard__cvc">
                          <MaskedTextField
                            name="cvv"
                            label={web_plc_add_credit_card_cvv}
                            variant="filled"
                            fullWidth
                            value={cvv}
                            mask={inputMaskTypes.cvv}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getError(errorCvv, touchedCvv)}
                            helperText={getErrorMessage(errorCvv, touchedCvv)}
                            inputProps={{ maxLength: 3, inputMode: 'numeric' }}
                            itemID="creditCardCvvInputTest"
                          />
                          <Box className="addCreditCard__tooltip__box">
                            <Tooltip
                              title={web_title_cvv_box}
                              id="addCreditCard__tooltip"
                              desc1={web_msg_credit_card_cvv}
                              calculate
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="addCreditCard__inputs">
                    <TextField
                      name="cardName"
                      label={web_plc_add_credit_card_name}
                      variant="filled"
                      fullWidth
                      value={cardName}
                      onChange={(e) => setFieldValue('cardName', sanitizeHtml(e.target.value))}
                      onBlur={handleBlur}
                      error={getError(errorCardName, touchedCardName)}
                      helperText={getErrorMessage(errorCardName, touchedCardName)}
                      inputProps={{ maxLength: 20 }}
                      itemID="creditCardNameInputTest"
                    />
                  </Box>
                  {creditCardData.masterPassAccountStatus !== MasterPassAccountStatusEnum.NO_LINKED && (
                    <Box className="addCreditCard__checkbox">
                      <CheckboxUnderlineLabel
                        name="addCardToMasterPass"
                        color="primary"
                        errorText={getErrorMessage(
                          errorCardToMasterPass,
                          touchedAddToMasterPass && !addCardToMasterPass
                        )}
                        checked={addCardToMasterPass}
                        onChange={handleChange}
                        onUnderlineTextClick={() => show()}
                        value={addCardToMasterPass}
                        text={web_check_masterpass_add_credit_card}
                        marginBottomGutter="0"
                        className="addCreditCard__checkbox--flex"
                        itemID="creditCardMasterpassCheckboxInputTest"
                      />
                      <MasterPassIcon />
                    </Box>
                  )}
                  <Button variant="contained" color="secondary" fullWidth type="submit" itemID="addCreditCardBtnTest">
                    {web_btn_next}
                  </Button>
                </FormWrapper>
              );
            }}
          </Formik>
        </FormErrorWrapper>
        <DialogWithActions
          alignTitle="left"
          closeIcon
          masterpassLogo
          primaryButton={{
            handleClick: () => {
              setIsOpenRuleDialog(false);
              dispatch(
                onGetMasterpassTokenRequest({
                  CustomerNumber: customerNumber,
                  SessionToken: sessionToken,
                  TokenType: MasterPassTokenEnum.REGISTER,
                  request: {
                    data: masterPassLinkToClientNormalize(),
                    type: MasterPassTokenEnum.LINK,
                  },
                })
              );
            },
            label: masterpass_saved_cards_popup_btn,
          }}
          open={isOpenRuleDialog}
          text={masterpass_saved_cards_popup_desc}
          hide={() => setIsOpenRuleDialog(false)}
          title={masterpass_saved_cards_popup_header}
        />
        <DialogWithInput alignTitle="left" hide={hide} open={isVisible} closeIcon>
          <Fragment>
            <iframe
              style={{ width: '100%', height: 400, border: 0, zIndex: 2 }}
              src={language === 'en' ? masterpassLinkEn : masterpassLinkTr}
              sandbox="allow-forms allow-scripts allow-same-origin allow-presentation"
            />
          </Fragment>
        </DialogWithInput>
      </div>
    </DashboardLayout>
  );
};

export default AddCreditCard;
