import SASSVariables from './scss/_variables.module.scss';

type ThemeVariablesType = {
  grayColor: string;
  lightGrayColor: string;
  whiteGrayColor: string;
  darkGrayColor: string;
  primaryColor: string;
  darkPrimaryColor: string;
  secondaryColor: string;
  alternativeColor: string;
  alternativeColor2: string;
  alternativeColor3: string;
  inputColor: string;
  checkboxLabelColor: string;
  separatorColor: string;
  darkSeparatorColor: string;
  disabledColor: string;
  placeholderColor: string;
  errorBorderColor: string;
  errorBgColor: string;
  errorTextColor: string;
  toastSuccess: string;
  toastSuccessText: string;
  toastError: string;
  toastErrorText: string;
  masterpassOrangeColor: string;
  lightBackgroundColor: string;
  systemGreen: string;
  cardColorsBlue1: string;
  digitalTextColor: string;

  tinyDeviceWidth: string;
  smallDeviceWidth: string;
  regularDeviceWidth: string;
  bigDeviceWidth: string;
  largeDeviceWidth: string;

  tinyDeviceHeight: string;

  smallGutter: string;
  regularGutter: string;
  bigGutter: string;
  biggerGutter: string;
  largeGutter: string;

  fontTiny: string;
  fontSmaller: string;
  fontSmall: string;
  fontRegular: string;
  fontRegularBig: string;
  fontBig: string;
  fontBigger: string;
  fontLargest: string;

  radiusRegular: string;
  radiusLarge: string;
};

const variables = SASSVariables as ThemeVariablesType;

export const colors = {
  gray: variables.grayColor,
  lightGray: variables.lightGrayColor,
  whiteGray: variables.whiteGrayColor,
  darkGray: variables.darkGrayColor,
  primary: variables.primaryColor,
  darkPrimary: variables.darkPrimaryColor,
  secondary: variables.secondaryColor,
  alternative: variables.alternativeColor,
  alternative2: variables.alternativeColor2,
  alternative3: variables.alternativeColor3,
  input: variables.inputColor,
  checkboxLabel: variables.checkboxLabelColor,
  separator: variables.separatorColor,
  darkSeparator: variables.darkSeparatorColor,
  disabled: variables.disabledColor,
  placeholder: variables.placeholderColor,
  errorBorder: variables.errorBorderColor,
  errorBg: variables.errorBgColor,
  errorText: variables.errorTextColor,
  toastSuccess: variables.toastSuccess,
  toastSuccessText: variables.toastSuccessText,
  toastError: variables.toastError,
  toastErrorText: variables.toastErrorText,
  masterpassOrangeColor: variables.masterpassOrangeColor,
  lightBackgroundcolor: variables.lightBackgroundColor,
  systemGreen: variables.systemGreen,
  cardColorsBlue1: variables.cardColorsBlue1,
  digitalTextColor: variables.digitalTextColor,
};

export const gutters = {
  small: variables.smallGutter,
  regular: variables.regularGutter,
  big: variables.bigGutter,
  bigger: variables.biggerGutter,
  large: variables.largeGutter,
};

export const fontSizes = {
  tiny: variables.fontTiny,
  smaller: variables.fontSmaller,
  small: variables.fontSmall,
  regular: variables.fontRegular,
  regularBig: variables.fontRegularBig,
  big: variables.fontBig,
  bigger: variables.fontBigger,
  largest: variables.fontLargest,
};

export const radius = {
  regular: variables.radiusRegular,
  large: variables.radiusLarge,
};

export const deviceWidth = {
  tiny: variables.tinyDeviceWidth,
  small: variables.smallDeviceWidth,
  regular: variables.regularDeviceWidth,
  big: variables.bigDeviceWidth,
  large: variables.largeDeviceWidth,
};

export const deviceHeight = {
  tiny: variables.tinyDeviceHeight,
};
