import { errorMessages } from 'Config';
import * as yup from 'yup';

export const HesSchema = yup.object({
  hesCode: yup.string().required(errorMessages.require).min(16, errorMessages.minHes),
});

export type HesSchemaInputs = yup.InferType<typeof HesSchema>;

export const hesSchemaInitialValues: HesSchemaInputs = {
  hesCode: '',
};
