import { FC } from 'react';
import { SuccessIconLarge } from 'Components';
import { Box, Typography, Button } from '@material-ui/core';
import { gutters } from 'Theme/Variables';

const ICON_SIZE = '144px';

import './DeleteSuccess.scss';

type DeleteSuccessProps = {
  title: string;
  primaryButton?: {
    label: string;
    handleClick: () => void;
  };
  secondaryButton?: {
    label: string;
    handleClick: () => void;
  };
};

const DeleteSuccess: FC<DeleteSuccessProps> = ({ title, primaryButton, secondaryButton }) => {
  return (
    <div id="delete-card-success-container">
      <SuccessIconLarge width={ICON_SIZE} height={ICON_SIZE} />
      <Typography variant="h3">
        <Box marginTop={gutters.big} fontWeight={600} textAlign="center">
          {title}
        </Box>
      </Typography>
      <Box width={'100%'} marginTop={gutters.large}>
        {primaryButton && (
          <Button onClick={primaryButton?.handleClick} variant="contained" fullWidth color="secondary" type="submit">
            {primaryButton.label}
          </Button>
        )}
      </Box>
      <Box width={'100%'} marginTop={gutters.small}>
        {secondaryButton && (
          <Button
            className="delete-card-success-container__secondary_button"
            onClick={secondaryButton?.handleClick}
            variant="contained"
            fullWidth
            color="default"
            type="submit"
          >
            {secondaryButton.label}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default DeleteSuccess;
