import * as React from 'react';

function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="htat5dt8ma">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <path
          id="ezf2h34btb"
          d="M11 0c1.657 0 3 1.343 3 3v14c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h8zm2 4.494C10.212 1.66 6 3.038 6 7.11s7 8.518 7 8.518V4.494z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#htat5dt8ma)" transform="translate(-20 -210) translate(19 62)">
            <g>
              <path d="M0 0H24V24H0z" transform="translate(1 148)" />
              <g transform="translate(1 148) translate(5 2)">
                <mask id="gxbwcz6v8c" fill="#fff">
                  <use xlinkHref="#ezf2h34btb" />
                </mask>
                <use fill="#383C52" fillRule="nonzero" xlinkHref="#ezf2h34btb" />
                <g fill="#383C52" mask="url(#gxbwcz6v8c)">
                  <path d="M0 0H24V24H0z" transform="translate(-5 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LocationIcon;
