import * as React from 'react';

function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="j77agg487a"
          d="M12.707 4.293c.36.36.388.928.083 1.32l-.083.094L7.415 11H19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L19 13H7.415l5.292 5.293c.36.36.388.928.083 1.32l-.083.094c-.36.36-.928.388-1.32.083l-.094-.083-7-7-.073-.082-.007-.008.08.09c-.036-.035-.068-.073-.097-.112-.018-.024-.034-.049-.05-.074l-.021-.037c-.011-.02-.022-.04-.031-.06l-.023-.053-.021-.06-.014-.045-.016-.065-.009-.053-.007-.058L4 12.02v-.038c0-.024.002-.048.004-.071L4 12c0-.05.004-.1.011-.149l.01-.052c.004-.022.009-.043.015-.065l.014-.046.021-.06.023-.051.03-.061.022-.037c.016-.025.032-.05.05-.074l.014-.018c.028-.035.054-.066.083-.094l7-7c.39-.39 1.024-.39 1.414 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-806 -811) translate(603 795) translate(203 16)">
              <path d="M0 0H24V24H0z" />
              <mask id="g51er6tqyb" fill="#fff">
                <use xlinkHref="#j77agg487a" />
              </mask>
              <g fill="#FFF" mask="url(#g51er6tqyb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowDownIcon;
