import { getActiveLanguageForMasterPass, getEnvironment, getPublicIpFromStorage } from 'Utils';

export const masterPassRegisterNormalize = (
  email: string,
  pan: string,
  expiryDate: string,
  cvv: string,
  cardName: string,
  token?: string,
  msisdn?: string,
  reqRefNumber?: string
) => {
  const reqValues = {
    token,
    email: email,
    cvc: cvv,
    accountAliasName: cardName,
    expiryDate: expiryDate,
    rtaPan: pan,
    uiChannelType: '6',
    timeZone: '01',
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    sendSms: 'N',
    referenceNo: reqRefNumber,
    msisdn,
    mobileAccountConfig: 'MWA',
    identityVerificationFlag: 'Y',
    defaultAccount: 'Y',
    cardTypeFlag: '05',
    eActionType: 'A',
    actionType: 'A',
    clientId: getEnvironment() ? '34705878' : '34706580',
    dateTime: new Date(),
    version: '34',
    clientType: '1',
  };
  return reqValues;
};

export const masterPassListCardNormalize = () => {
  const reqValues = {
    token: '',
    msisdn: '',
  };
  return reqValues;
};
export const masterPassOtpNormalize = (code: string) => {
  const reqValues = {
    validationCode: code,
    referenceNo: '',
    sendSms: 'N',
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    pinType: 'otp',
  };
  return reqValues;
};

export const masterPassPaymentNormalize = (loadAmount: number, listAccountName: string) => {
  const macroMerchantId = getEnvironment() ? '05878207920408103103242' : '06580207920724020852449';
  const reqValues = {
    // For masterpass price * 100
    amount: Number((loadAmount * 100).toFixed(2)),
    listAccountName,
    msisdn: '',
    token: '',
    referenceNo: '',
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    macroMerchantId,
    orderNo: '1231231',
    installmentCount: '',
    rewardName: '',
    rewardValue: '',
    cvc: '',
    sendSms: 'Y',
    aav: 'aav',
    clientIp: getPublicIpFromStorage(),
    encCPin: '0',
    encPassword: '',
    sendSmsMerchant: 'Y',
    password: '',
  };

  return reqValues;
};

export const masterPassDeleteCardNormalize = (cardName: string) => {
  const reqValues = {
    token: '',
    accountAliasName: cardName,
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    sendSms: 'N',
    referenceNo: '101252836185',
    msisdn: '',
    fp: '',
    clientId: getEnvironment() ? '34705878' : '34706580',
    dateTime: new Date(),
    version: '34',
    clientType: '1',
  };
  return reqValues;
};

export const masterPassLinkToClientNormalize = () => {
  const reqValues = {
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    sendSms: 'N',
    referenceNo: '',
    token: '',
    cardAliasName: null,
    msisdn: '',
    fp: '',
    clientId: getEnvironment() ? '34705878' : '34706580',
    dateTime: new Date(),
    version: '34',
    clientType: '1',
  };
  return reqValues;
};

export const masterPassRecurringPaymentNormalize = (
  msisdn: string,
  token: string,
  cardName: string,
  amount: string,
  endDate: string,
  actionType: string,
  productId: string,
  referenceNo: string,
  transactionFee: string
) => {
  const reqValues = {
    sendSmsLanguage: getActiveLanguageForMasterPass(),
    sendSms: 'N',
    msisdn: msisdn,
    token: token,
    listAccountName: cardName,
    amount: amount,
    endDate: endDate,
    actionType: actionType,
    productId: productId,
    referenceNo: referenceNo,
    fp: '',
    clientId: getEnvironment() ? '34705878' : '34706580',
    dateTime: new Date(),
    version: '34',
    clientType: '1',
    transactionFee,
  };
  return reqValues;
};
