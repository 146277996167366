import { ChangePasswordSchemaInputs } from 'Validations';

export const changePasswordNormalize = (
  values: ChangePasswordSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    OldPassword: values.oldPassword,
    NewPassword: values.password,
    ConfirmPassword: values.rePassword,
  };
  return formValues;
};
