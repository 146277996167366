import * as React from 'react';

function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      className="locationIcon"
    >
      <defs>
        <path
          id="lay9v7dz6a"
          d="M8 0c4.387 0 7.956 3.533 8 7.92 0 5.48-7 11.58-7.35 11.84-.374.32-.926.32-1.3 0C7.05 19.5 0 13.4 0 7.92.044 3.533 3.613 0 8 0zm0 4C6.067 4 4.5 5.567 4.5 7.5S6.067 11 8 11c.928 0 1.818-.369 2.475-1.025C11.13 9.318 11.5 8.428 11.5 7.5 11.5 5.567 9.933 4 8 4zm0 2c.828 0 1.5.672 1.5 1.5S8.828 9 8 9s-1.5-.672-1.5-1.5S7.172 6 8 6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-836 -786) translate(820 772) translate(16 14)" />
              <g transform="translate(-836 -786) translate(820 772) translate(16 14) translate(4 2)">
                <mask id="grzp3bam3b" fill="currentColor">
                  <use xlinkHref="#lay9v7dz6a" />
                </mask>
                <use fill="currentColor" fillRule="nonzero" xlinkHref="#lay9v7dz6a" />
                <g fill="currentColor" mask="url(#grzp3bam3b)">
                  <path d="M0 0H24V24H0z" transform="translate(-4 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LocationIcon;
