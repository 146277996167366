import { axiosHelper } from 'Services/AxiosHelper';
import { organizationTypeListBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';
import { OrganizationTypeListModel } from 'Models';

const command = 'b2c.RIOrganizationTypeList';

const organizationTypeList = async (params: organizationTypeListBodyData): Promise<OrganizationTypeListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default organizationTypeList;
