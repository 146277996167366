import { errorMessages } from 'Config';
import moment from 'moment';
import * as yup from 'yup';

export const FilterTransactionSchema = yup.lazy(({ min, max, startAtDate, endDate }) =>
  yup.object({
    min: yup
      .number()
      .min(0)
      .max(999)
      .test('min', errorMessages.minAmountNotBiggerThanMax, (min?: number) => {
        min = min || 0;
        if (max) {
          return min <= max;
        } else {
          return true;
        }
      }),
    max: yup
      .number()
      .min(0)
      .max(999)
      .test('max', errorMessages.minAmountNotBiggerThanMax, (max?: number) => {
        max = max || 0;
        if (min && max) {
          return max >= min;
        } else {
          return true;
        }
      }),
    startAtDate: yup
      .date()
      .nullable()
      .max(new Date(), errorMessages.notAfterCurrent)
      .test('startAtDate', errorMessages.endDateNotBefore, (startAtDate) => {
        return !moment(startAtDate).isAfter(endDate);
      }),
    endDate: yup
      .date()
      .nullable()
      .max(new Date(), errorMessages.notAfterCurrent)
      .test('endDate', errorMessages.endDateNotBefore, (endDate) => {
        return !moment(startAtDate).isAfter(endDate);
      }),
  })
);

export const addFilterSchema: InitialValues = {
  startAtDate: null,
  endDate: null,
  min: undefined,
  max: undefined,
};
type InitialValues = {
  startAtDate?: Date | null;
  endDate?: Date | null;
  min?: number;
  max?: number;
};
