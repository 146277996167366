import { FC, Fragment, useState } from 'react';
import { DashboardLayout, Map, MapPopup } from 'Components';
import { useCustomerLocation, useLocalization } from 'Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { MapInterface } from './MapInterface';
import { MapMask } from './MapMask';
import { useEffect } from 'react';
import { getPinsNormalize } from 'Normalize';
import { PinsItemModel } from 'Models';
import { languageId } from 'Utils';
import {
  getPinsRequest,
  getTagsRequest,
  MapsState,
  mapStore,
  resetSettings,
  setDiscoverCurrentMapLocation,
  setDiscoverSelectedPin,
  setUserLocation,
} from 'Stores/Maps';

import './Discover.scss';

type DiscoverProps = {};

const Discover: FC<DiscoverProps> = ({}) => {
  const dispatch = useDispatch();

  const userState: MapsState = useSelector(mapStore);

  const {
    discoverTags,
    discoverSelectedTag,
    userLocation,
    discoverSelectedPin,
    discoverCurrentMapLocation,
  } = userState;

  const { latitude, longitude } = useCustomerLocation();

  const [page, setPage] = useState<number>(1);

  //reset settings first join
  useEffect(() => {
    dispatch(resetSettings());
  }, []);

  //set user location to store
  useEffect(() => {
    const userGrantedLocation = latitude !== 0 && longitude !== 0;
    const userLocationIsAlreadyHave = userLocation.latitude === 41.00527;
    const userLocationMustSet = userGrantedLocation && userLocationIsAlreadyHave;

    if (userLocationMustSet) {
      const location = { latitude, longitude };
      dispatch(setUserLocation(location));
      dispatch(setDiscoverCurrentMapLocation(location));
    }
  }, [latitude, longitude]);

  useEffect(() => {
    dispatch(getTagsRequest());
    dispatch(
      getPinsRequest(
        getPinsNormalize({
          TagId: discoverSelectedTag,
          LanguageId: Number(languageId()),
          Latitude: discoverCurrentMapLocation.latitude,
          Longitude: discoverCurrentMapLocation.longitude,
          Distance: 1.5,
          // TownId: null,
        })
      )
    );
  }, [discoverSelectedTag, discoverCurrentMapLocation]);

  return (
    <DashboardLayout marginTop={0} noContainer>
      <Fragment>
        <div id="discover">
          {page === 1 && <MapMask setPage={setPage} />}
          {page === 2 && <MapInterface />}

          <Map onClickFunction={(e: PinsItemModel) => dispatch(setDiscoverSelectedPin(e))} height="585px" />
        </div>
        {discoverSelectedPin && (
          <MapPopup
            reset={() => dispatch(setDiscoverSelectedPin(undefined))}
            title={discoverSelectedPin?.Name}
            desc={discoverSelectedPin?.Address}
          />
        )}
        {/* 
        <FilterDialog
          setSelectedTown={setSelectedTown}
          selectedTown={selectedTown}
          setSelectedCity={setSelectedCity}
          selectedCity={selectedCity}
          isOpen={isOpen}
          close={setIsOpen}
        /> */}
      </Fragment>
    </DashboardLayout>
  );
};
export default Discover;
