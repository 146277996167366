import { FC, Fragment, useEffect, useState } from 'react';
import { DashboardLayout } from 'Components';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearCreditStatus,
  closeBankSecureModal,
  creditWarningMessageModalClose,
  MicroCreditState,
  microCreditStore,
} from 'Stores/MicroCredit';

import './MicroCredit.scss';
import MicroCreditOperation from './MicroCreditOperation/MicroCreditOperation';
import { UserState, userStore } from 'Stores/User';
import { MicroCrediSuccessLoadBalance } from './MicroCrediSuccessLoadBalance/AddCardSuccessLoadBalance';

type MicroCreditProps = {};

const MicroCredit: FC<MicroCreditProps> = ({}) => {
  const dispatch = useDispatch();

  const microCreditData: MicroCreditState = useSelector(microCreditStore);
  const creditStatus = microCreditData?.creditStatus;

  const userData: UserState = useSelector(userStore);
  const isMiniApp = userData?.isMiniAppLogin;

  useEffect(() => {
    dispatch(clearCreditStatus());
  }, []);

  return (
    <DashboardLayout backButton={!!!isMiniApp}>
      <Fragment>
        {creditStatus !== 'P' && <MicroCreditOperation />}
        {creditStatus === 'P' && <MicroCrediSuccessLoadBalance />}
      </Fragment>
    </DashboardLayout>
  );
};
export default MicroCredit;
