import handleFormData from './MasterPassHtlmHelper';

const MasterPassServices = {
  setClientId: window?.MFS !== undefined && window?.MFS?.setClientId,
  listCards: window?.MFS !== undefined && window?.MFS?.listCards,
  resendOtp: window?.MFS !== undefined && window?.MFS?.resendOtp,
  getLastToken: window?.MFS !== undefined && window?.MFS?.getLastToken,
  setAddress: window?.MFS !== undefined && window?.MFS?.setAddress,

  register: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.register(handleFormData(json, formId), response),
  checkMasterPass: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.checkMasterPass(handleFormData(json, formId), response),
  validateTransaction: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.validateTransaction(handleFormData(json, formId), response),
  purchase: (formId: string, json: any, response: any) => {
    if (json.rtaPan) {
      window?.MFS !== undefined && window?.MFS?.directPurchase(handleFormData(json, formId), response);
    } else {
      window?.MFS !== undefined && window?.MFS?.purchase(handleFormData(json, formId), response);
    }
  },
  initiateRecurringPayment: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.initiateRecurringPayment(handleFormData(json, formId), response),
  deleteCard: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.deleteCard(handleFormData(json, formId), response),
  linkCardToClient: (formId: string, json: any, response: any) =>
    window?.MFS !== undefined && window?.MFS?.linkCardToClient(handleFormData(json, formId), response),
};

export default MasterPassServices;
