import React, { FC, memo } from 'react';
import { Typography, Box, Button, Link } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorMessage, useLocalization, useModal } from 'Hooks';
import { FormWrapper, MaskedTextField, DialogTerms } from 'Components';
import { formikGlobalConfig, inputMaskTypes } from 'Config';
import { Formik } from 'formik';
import { hesSchemaInitialValues, HesSchema, HesSchemaInputs } from 'Validations';
import { agreementsTypes, generateLanguageParam, maskHesCode, underlineParser } from 'Utils';
import { insertOrUpdateHesCodeRequest } from 'Stores/User/slices';
import { hesCodeNormalize } from 'Normalize';
import { userStore } from 'Stores/User/slices';
import { AccountState, onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { UserState } from 'Stores/User';

import './UnregisteredHes.scss';

type UnregisteredHesProps = {
  hesCodeProp?: string | null;
};

const UnregisteredHes: FC<UnregisteredHesProps> = memo(({ hesCodeProp }) => {
  const {
    web_uyari_hes_guncelleme,
    web_btn_kaydet,
    web_btn_guncelle,
    plc_hes_kodu,
    web_btn_hes_nasil_alirim,
    web_title_ibb_clarification_text,
  } = useLocalization();
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const accountStore: AccountState = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  const { getError, getErrorMessage } = useErrorMessage();
  const { isVisible, show, hide } = useModal();
  const { beginText, endText, underlineText } = underlineParser(web_uyari_hes_guncelleme);

  const handleSubmit = (values: HesSchemaInputs) => {
    dispatch(insertOrUpdateHesCodeRequest(hesCodeNormalize(maskHesCode(values.hesCode), customerNumber, sessionToken)));
  };

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  return (
    <div id="hesUnregistered">
      <Formik
        initialValues={hesSchemaInitialValues}
        validationSchema={HesSchema}
        onSubmit={handleSubmit}
        {...formikGlobalConfig}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            values: { hesCode },
            errors: { hesCode: errorHesCode },
            touched: { hesCode: touchedHesCode },
          } = formikProps;
          return (
            <FormWrapper onSubmit={formikProps.handleSubmit}>
              <Box className="hesUnregistered-inputContainer">
                <MaskedTextField
                  name="hesCode"
                  value={hesCode.toUpperCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask={inputMaskTypes.hes}
                  label={plc_hes_kodu}
                  inputMode="text"
                  error={getError(errorHesCode, touchedHesCode)}
                  helperText={getErrorMessage(errorHesCode, touchedHesCode)}
                  className="hesUnregistered-inputContainer__input"
                  itemID="hesCodeInputTest"
                  {...(!!hesCodeProp && { autoFocus: true })}
                />
                <Button variant="contained" color="secondary" type="submit" itemID="addHesCodeBtnTest">
                  {!!hesCodeProp ? web_btn_guncelle : web_btn_kaydet}
                </Button>
              </Box>
            </FormWrapper>
          );
        }}
      </Formik>

      <Box className="hesUnregistered__description">
        <Typography variant="body1" align="center">
          {beginText}
          <Link
            variant="body1"
            onClick={() => {
              handleAgreementSelect(agreementsTypes.IBB_AYDINLATMA_METNI);
              show();
            }}
            underline="always"
            color="textSecondary"
            itemID="showIbbAydınlatmaMetniBtnTest"
          >
            {underlineText}
          </Link>
          {endText}
        </Typography>
      </Box>
      <DialogTerms
        open={isVisible}
        hide={hide}
        headerTextAlign="left"
        title={web_title_ibb_clarification_text}
        isButtonOkay
        content={agreementContent?.AgreementContent}
      />
      <Button
        variant="contained"
        className="buttonWhite"
        type="submit"
        fullWidth
        href="https://hayatevesigar.saglik.gov.tr"
        itemID="showHowToGetHesCodeBtnTest"
        target="_blank"
      >
        {web_btn_hes_nasil_alirim}
      </Button>
    </div>
  );
});

export default UnregisteredHes;
