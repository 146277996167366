import { FC, memo, useState } from 'react';
import { useLocalization, useModal } from 'Hooks';
import { Box } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { DialogUserJobs } from './DialogUserJobs';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { jobsData } from './DialogUserJobs/DialogUserJobs';
import { onInıtDataStore } from 'Stores/App';
import { AccountState, showHideUserJobDialog, storeAccount } from 'Stores/Account';

import './UserJob.scss';

type UserJobProps = {};

const UserJob: FC<UserJobProps> = memo(() => {
  const dispatch = useDispatch();

  const { web_label_job, web_communication_job_null } = useLocalization();

  const userData: UserState = useSelector(userStore);
  const onInıtData = useSelector(onInıtDataStore);

  const userCurrentJob = userData.job;

  const accountState: AccountState = useSelector(storeAccount);
  const isJobDialogOpen: boolean = accountState.isUserJobDialogOpen;

  const jobs: jobsData[] | undefined = onInıtData.JobList;

  const userJob = jobs?.find((item: jobsData) => item.JobCode === userCurrentJob);

  return (
    <Box id="userJob">
      <InfoItem
        title={web_label_job}
        value={userCurrentJob ? userJob?.JobName : web_communication_job_null}
        onClick={() => dispatch(showHideUserJobDialog(true))}
        add={!Boolean(userCurrentJob)}
        valueClass="user-jobs__desc"
      />

      <DialogUserJobs
        isOpen={isJobDialogOpen}
        hide={() => dispatch(showHideUserJobDialog(false))}
        currentJob={userCurrentJob}
      />
    </Box>
  );
});
export default UserJob;
