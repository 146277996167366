const EVAMSdkEventTypes = {
  ISTANBUL_KART_EKLEME: 'İstanbulkartın online/mobil kanal aracılığı ile dijital olarak müşteri üzerine kaydedilmesi',
  KULLANICI_LOGIN: 'Login (İlk login detayıyla-Hangi işletim sistemi, hangi versiyon)',
  KAYITLI_ISTANBUL_KARTA_TL_YUKLEME: 'Kayıtlı İstanbulkarta TL yükleme',
  YONETTIGI_KARTLARA_TL_YUKLEME: 'Yönettiği kartlar alanına istanbulkart ekleme',
  ISTANBULKART_SILME: 'İstanbulkart silme',
  ABONMAN_YUKLEME: 'Abonman Yükleme',
  HIZLI_ISLEM_ILE_BASKA_ISTANBUL_KARTA_ABONMAN_YUKLEME: 'Hızlı işlem ile başka İstanbulkarta abonman yükleme',
  TICARI_ILETI_IZNI_ALINMASI: 'Ticari ileti izni alınması',
  ACIK_RIZA_VERILMESI: 'Açık rıza verilmesi',
  HIZLI_ISLEM_ILE_BASKA_IST_KARTA_TL_YUKLEME: 'Hızlı işlem ile başka İstanbulkarta TL yükleme',
};

export default EVAMSdkEventTypes;
