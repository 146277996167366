import { spaceRemover } from 'Utils';
import { AddCardSchemaInputs } from 'Validations';

export const saveUserCardNormalize = (values: AddCardSchemaInputs, customerNumber?: number, sessionToken?: string) => {
  const { cardNumber, seriNo, cardName } = values;
  return {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: seriNo,
    AliasNumber: spaceRemover(cardNumber),
    NickName: cardName,
    TypeOfSave: 3,
  };
};

export const saveUserCardWithSeriNoNormalize = (
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { seriNo, cardName } = values;
  return {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: seriNo,
    NickName: cardName,
    TypeOfSave: 3,
  };
};

export const saveUserCardWithCardNoNormalize = (
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { cardNumber, cardName } = values;
  return {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    AliasNumber: spaceRemover(cardNumber),
    NickName: cardName,
    TypeOfSave: 3,
  };
};

export const addUserTemprorayCardNormalize = (
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { cardNumber, seriNo, cardName } = values;

  return {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: seriNo ? seriNo : '',
    AliasNumber: cardNumber ? spaceRemover(cardNumber) : '',
    NickName: cardName,
  };
};

export const saveUserCardAfterTransactionsNormalize = (
  activeSelectedCard: any,
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { cardName } = values;
  return {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: activeSelectedCard.MifareId,
    AliasNumber: activeSelectedCard.AliasNumber,
    NickName: cardName,
    TypeOfSave: 3,
  };
};
