import * as React from 'react';

function WaitingCardRedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="69px"
      height="33px"
      viewBox="0 0 69 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Group</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <polygon id="Rectangle" fill="#C22026" points="63 5 69 0 69 28 63 33"></polygon>
          <path
            d="M6,0 L69,0 L69,0 L69,28 L6,28 C2.6862915,28 -6.69961511e-15,25.3137085 0,22 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z"
            id="Rectangle"
            fill="#FF5959"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default WaitingCardRedIcon;
