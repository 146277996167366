import { FC } from 'react';
import { useCardType, useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { useHistory } from 'react-router';
import { TransactionDetail } from './TransactionDetail';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import { CardTransactionsState, setAddCardStep, setInActiveCard, storeCardTransactions } from 'Stores/CardTransactions';
import { cardBalanceNormalize } from 'Utils';
import { CreditCardTransactionsState, creditCardTransactionsStore } from 'Stores/CreditCardTransactions';

import './MicroCrediSuccessLoadBalance.scss';
import { MicroCreditState, microCreditStore } from 'Stores/MicroCredit';

type MicroCrediSuccessLoadBalanceProps = {};

const MicroCrediSuccessLoadBalance: FC<MicroCrediSuccessLoadBalanceProps> = ({}) => {
  const {
    web_btn_success_upload_tl,
    web_desc1_success_upload_tl,
    web_desc2_success_upload_tl,
    web_btn_add_to_my_cards,
    card_colour_digital_card_activation_control,
    web_tl_success_current_balance,
    web_tl_success_homepage_btn,
    web_micro_credit_transaction_approved,
    web_micro_credit_card_details_btn,
  } = useLocalization();
  const history = useHistory();

  const { isVisible, show, hide } = useModal();

  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const microCreditData: MicroCreditState = useSelector(microCreditStore);
  const microCreditStatus = microCreditData?.creditStatus;

  //const userActiveDigitalCard = cardData?.cards[0];

  const userActiveDigitalCard: any = cardData?.cards.filter(function (data) {
    return data.ProductCode == 'DGK_STD' || data.ProductCode == 'DGK_ORC' || data.ProductCode == 'DGK_ORT';
  });

  const { isUserAddingNewCardInTransactions, activeSelectedCard }: any = userActiveDigitalCard[0];

  const { cardColor, cardPath } = useCardType(userActiveDigitalCard[0]?.ProductCode);

  const microCredtCardInfo = useCardType(userActiveDigitalCard[0]?.ProductCode);

  const isDijitalCard = card_colour_digital_card_activation_control.includes(userActiveDigitalCard[0]?.ProductCode);

  return (
    <div id="microCrediSuccessLoadBalance">
      <Box marginTop={gutters.big}>
        <div className="add-card-success-load-balance__img--wrapper">
          <img
            src={microCreditStatus == 'P' ? microCredtCardInfo.cardPath : cardPath}
            alt=""
            className={`add-card-success-load-balance__img--wrapper__${
              microCreditStatus == 'P' ? microCredtCardInfo.cardColor : cardColor
            }`}
          />
          <GreenTickBigFilledIcon />
        </div>
        {microCreditStatus == 'P' && (
          <Box className="add-card-success-load-balance__desc">
            <Typography variant="body1" align="center">
              <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
                {web_micro_credit_transaction_approved}
              </Box>
            </Typography>
          </Box>
        )}
        {!isDijitalCard && microCreditStatus !== 'P' && (
          <Box className="add-card-success-load-balance__desc">
            <Link underline="none" style={{ cursor: 'unset' }}>
              <Box textAlign="center" color={colors.alternative3} fontSize={fontSizes.regular}>
                {web_desc1_success_upload_tl}
              </Box>
            </Link>
            <Typography variant="body1" align="center">
              <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
                {web_desc2_success_upload_tl}
              </Box>
            </Typography>
          </Box>
        )}
        {isDijitalCard && microCreditStatus !== 'P' && (
          <Typography variant="body1" align="center">
            <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
              {web_tl_success_current_balance}:{' '}
              <Box component="span" fontWeight="bold" color={colors.toastSuccessText}>
                {cardBalanceNormalize(activeSelectedCard?.CardBalance + creditCardData.loadAmount)}
              </Box>
            </Box>
          </Typography>
        )}
        <div className="add-card-success-load-balance__container">
          <Box fontWeight="bold">
            {microCreditStatus == 'P' && (
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    dispatch(setInActiveCard(userActiveDigitalCard[0]));
                    history.push(routePath.cardDetails);
                  }}
                  itemID="addCardToMyCardsBtnTest"
                >
                  {web_micro_credit_card_details_btn}
                </Button>
              </Box>
            )}
            {isUserAddingNewCardInTransactions && !isDijitalCard ? (
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    dispatch(setAddCardStep(0));
                    history.push(routePath.addCard);
                  }}
                  itemID="addCardToMyCardsBtnTest"
                >
                  {web_btn_add_to_my_cards}
                </Button>
                <Box marginTop={gutters.small}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type="submit"
                    onClick={() => show()}
                    itemID="seeUploadDetailBtnTest"
                  >
                    {web_btn_success_upload_tl}
                  </Button>
                </Box>
              </Box>
            ) : (
              microCreditStatus !== 'P' && (
                <Button
                  variant={isDijitalCard ? 'outlined' : 'contained'}
                  color="secondary"
                  fullWidth
                  type="submit"
                  onClick={() => show()}
                  itemID="seeUploadDetailBtnTest"
                >
                  {web_btn_success_upload_tl}
                </Button>
              )
            )}
            {isDijitalCard && (
              <Box marginTop={gutters.small}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  type="submit"
                  onClick={() => history.push(routePath.home)}
                  itemID="seeUploadDetailBtnTest"
                >
                  {web_tl_success_homepage_btn}
                </Button>
              </Box>
            )}
            {microCreditStatus == 'P' && !isDijitalCard && (
              <Box marginTop={gutters.small}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  type="submit"
                  onClick={() => history.push(routePath.home)}
                  itemID="seeUploadDetailBtnTest"
                >
                  {web_tl_success_homepage_btn}
                </Button>
              </Box>
            )}
          </Box>
        </div>
      </Box>
      <Box>
        <TransactionDetail isVisible={isVisible} hide={hide} />
      </Box>
    </div>
  );
};

export default MicroCrediSuccessLoadBalance;
