import { axiosHelper } from 'Services/AxiosHelper';
import { OnConfirmSmsModel } from 'Models';
import { onConfirmSmsBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnConfirmSms';

const onConfirmSms = async (params: onConfirmSmsBodyData): Promise<OnConfirmSmsModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onConfirmSms;
