import { axiosHelper } from 'Services/AxiosHelper';
import { OnSetPasswordModel } from 'Models';
import { onSetPasswordBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnSetPassword';

const onSetPassword = async (params: onSetPasswordBodyData): Promise<OnSetPasswordModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onSetPassword;
