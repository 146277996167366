import { FC, Fragment, memo } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { fontSizes, gutters } from 'Theme/Variables';
import { useSelector } from 'react-redux';
import { storeAccount } from 'Stores/Account';
import Linkify from 'react-linkify';
import './AgreementsDetail.scss';

type AgreementsDetailProps = RouteComponentProps<{}, StaticContext, any>;

const AgreementsDetail: FC<AgreementsDetailProps> = memo(({ location: { state } }) => {
  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  const {
    web_privacy_policy,
    web_clarification_text,
    web_cookie_policy,
    web_framework_contract,
    web_ibb_clarification_text,
    web_contracts_acikriza,
    web_istanbulkart_terms_of_use,
  } = useLocalization();

  const parseTitle = (): string => {
    switch (state.titleId) {
      case 7:
        return web_clarification_text;
      case 6:
        return web_framework_contract;
      case 5:
        return web_ibb_clarification_text;
      case 4:
        return web_contracts_acikriza;
      case 2:
        return web_istanbulkart_terms_of_use;
      default:
        return '';
    }
  };
  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="agreementDetail">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{parseTitle()}</Box>
          </Typography>
          <Typography>
            <Box mt={gutters.large} fontSize={fontSizes.regular}>
              <Linkify
                componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
                  const isLink = decoratedText.includes('https');
                  return isLink ? (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  ) : (
                    decoratedText
                  );
                }}
              >
                {agreementContent?.AgreementContent}
              </Linkify>
            </Box>
          </Typography>
        </div>
      </Fragment>
    </DashboardLayout>
  );
});
export default AgreementsDetail;
