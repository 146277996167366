import { refTrnType } from 'Views/AddBalanceToCard/constant';

const expireDateFixer = (cvv: string, type?: number) => {
  try {
    if (type === refTrnType.yeniKartBasvurusu || refTrnType.ikinciKargoBasvurusu) {
      const newCvv = cvv?.replace('/', '');
      return newCvv[2] + newCvv[3] + newCvv[0] + newCvv[1];
    }
    return cvv?.replace('/', '');
  } catch (error) {
    return cvv?.replace('/', '');
  }
};
export default expireDateFixer;
