import { FC, memo } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Link, Grid } from '@material-ui/core';
import { colors, fontSizes } from 'Theme/Variables';

import './InfoItem.scss';

type InfoItemProps = {
  title: string;
  value?: string;
  onClick?: () => void;
  valueClass?: string;
  add?: boolean;
};

const InfoItem: FC<InfoItemProps> = memo(({ title, value, onClick, valueClass, add }) => {
  const { web_account_edit, web_btn_add } = useLocalization();
  return (
    <Grid id="infoItem" container>
      <Grid item xs={10}>
        <Box className="infoItem__title">{title}</Box>
        <Typography variant="h3">
          <Box
            fontSize={fontSizes.regular}
            fontWeight="bold"
            className={`infoItem__desc ${valueClass}`}
            style={{ maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all' }}
          >
            {value}
          </Box>
        </Typography>
      </Grid>
      <Grid xs={2} item>
        {onClick && (
          <Link underline="none" onClick={onClick}>
            <Box color={colors.primary} className="infoItem__edit">
              {add ? web_btn_add : web_account_edit}
            </Box>
          </Link>
        )}
      </Grid>
    </Grid>
  );
});
export default InfoItem;
