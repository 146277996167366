import * as yup from 'yup';
import { errorMessages } from 'Config';

const AddCardStep = yup.object({
  seriNo: yup.string().required(errorMessages.require).min(14, errorMessages.minSeriNo),
  cardNumber: yup.string().required(errorMessages.require).min(19, errorMessages.minCardNo),
  cardName: yup.string().required(errorMessages.require),
});

const AddPassiveCardStep = yup.object({
  cardNumber: yup.string().required(errorMessages.require).min(19, errorMessages.minCardNo),
  cardName: yup.string().required(errorMessages.require),
});

const AddPassiveCardWithSeriNoStep = yup.object({
  seriNo: yup.string().required(errorMessages.require).min(14, errorMessages.minSeriNo),
  cardName: yup.string().required(errorMessages.require),
});

export const AddCardSchema = [AddCardStep, AddPassiveCardStep, AddPassiveCardWithSeriNoStep];

export type AddCardSchemaInputs = yup.InferType<
  typeof AddCardStep & typeof AddPassiveCardStep & typeof AddPassiveCardWithSeriNoStep
>;

export const AddCardSchemaInitialValues: AddCardSchemaInputs = {
  seriNo: '',
  cardNumber: '',
  cardName: '',
};
