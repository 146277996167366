import {
  Box,
  FormControlLabel,
  FormHelperText,
  Checkbox as MaterialUICheckBox,
  CheckboxProps as MaterialUICheckBoxProps,
  Typography,
} from '@material-ui/core';
import { colors, gutters } from 'Theme/Variables';
import { FunctionComponent } from 'react';

type CheckboxProps = {
  errorText?: string;
  label: string | JSX.Element;
  marginBottomGutter?: string;
} & MaterialUICheckBoxProps;

const Checkbox: FunctionComponent<CheckboxProps> = ({
  errorText,
  label,
  marginBottomGutter = gutters.regular,
  ...props
}) => {
  return (
    <Box marginBottom={marginBottomGutter}>
      <FormControlLabel
        style={{ marginLeft: 0 }}
        control={<MaterialUICheckBox style={errorText ? { color: colors.errorText } : {}} {...props} />}
        label={typeof label === 'string' ? <Typography variant={'body1'}>{label}</Typography> : label}
      />
      {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </Box>
  );
};

export default Checkbox;
