import * as React from 'react';

function ChevronDarkRightIconFaq(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect width="24" height="24" fill="#fff" opacity="0" />
          <path
            d="M8.29,5.29a1,1,0,0,1,1.32-.08l.1.08,6,6a1,1,0,0,1,.08,1.32l-.08.1-6,6a1,1,0,0,1-1.42,0,1,1,0,0,1-.08-1.32l.08-.1L13.59,12,8.29,6.71a1,1,0,0,1-.08-1.32Z"
            fill="#383c52"
          />
        </g>
      </g>
    </svg>
  );
}

export default ChevronDarkRightIconFaq;
