import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { InstagramIcon, FacebookIcon, TwitterIcon } from 'Components';
import { fontSizes, gutters } from 'Theme/Variables';
import { useLocalization } from 'Hooks';
import { isAndroid, isIOS } from 'react-device-detect';

import './SocialMedia.scss';

type SocialMediaType = {
  title: string;
};

var mobileAppUrl = isAndroid ? 'fb://istanbulkart' : 'fb://page/istanbulkart';

var html = `<!DOCTYPE html>
<html>
  <head>
    <script type="text/javascript">
        setTimeout(function () { window.location = "https://www.facebook.com/istanbulkart"; }, 25);
	      window.location = "${mobileAppUrl}";
    </script>
  </head>
</html>`;

const SocialMedia: FC<SocialMediaType> = ({ title }) => {
  const { web_instagram_link_web, web_facebook_link_web, web_twitter_link_web } = useLocalization();

  const openSocialMedia = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const openFacebook = (url: string) => {
    if (!isAndroid && !isIOS) {
      openSocialMedia(url);
      return;
    }
    var wnd = window.open('about:blank', '', '_blank');
    wnd!.document.write(html);
  };

  return (
    <div id="social-media">
      <Typography className="social-media__title" variant="h3">
        <Box fontSize={fontSizes.regular} fontWeight={600}>
          {title}
        </Box>
      </Typography>
      <Box display="flex" flexDirection="row" marginTop={gutters.small} className="social-media__row">
        <Box itemID="instragramBtnTest">
          <InstagramIcon
            onClick={() => openSocialMedia(web_instagram_link_web)}
            className="social-media__icon social-media__insta"
          />
        </Box>
        <Box itemID="twitterBtnTest">
          <TwitterIcon onClick={() => openSocialMedia(web_twitter_link_web)} className="social-media__icon" />
        </Box>
        <Box itemID="facebookBtnTest">
          <FacebookIcon onClick={() => openFacebook(web_facebook_link_web)} className="social-media__icon" />
        </Box>
      </Box>
    </div>
  );
};

export default SocialMedia;
