import * as React from 'react';

function DarkPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="743kylzona"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C17.196 3.054 14.652 2 12 2zm3.3 11.1h-2.2v2.2c0 .608-.492 1.1-1.1 1.1-.608 0-1.1-.492-1.1-1.1v-2.2H8.7c-.608 0-1.1-.492-1.1-1.1 0-.608.492-1.1 1.1-1.1h2.2V8.7c0-.608.492-1.1 1.1-1.1.608 0 1.1.492 1.1 1.1v2.2h2.2c.608 0 1.1.492 1.1 1.1 0 .608-.492 1.1-1.1 1.1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-596 -434) translate(580 420) translate(16 14)">
              <path d="M0 0H24V24H0z" />
              <mask id="0c9b8qgl9b" fill="#fff">
                <use xlinkHref="#743kylzona" />
              </mask>
              <use fill="#383C52" fillRule="nonzero" xlinkHref="#743kylzona" />
              <g fill="#383C52" mask="url(#0c9b8qgl9b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DarkPlusIcon;
