import { Box, FormHelperText, Typography } from '@material-ui/core';
import { InputOneCharBox } from 'Components';
import { FormikProps } from 'formik';
import { useErrorMessage, useLocalization } from 'Hooks';
import { FC, memo, useEffect } from 'react';
import { gutters, fontSizes } from 'Theme/Variables';
import { LoginSchemaInputs } from 'Validations';
import ReCAPTCHA from 'react-google-recaptcha';

import './LoginPasswordStep.scss';
import { useDispatch } from 'react-redux';
import { setReCaptchaToken } from 'Stores/User';
import { useHistory } from 'react-router-dom';

type LoginPasswordStepProps = {
  formikProps: FormikProps<LoginSchemaInputs>;
};

const CAPTCHA_KEYS = [
  {
    // Prod
    domain: 'bireysel.istanbulkart.istanbul',
    key: '6LeoTRclAAAAABva4CEBXtLMYltrxcTRDS1TgC5j',
  },
  {
    // PreProd
    domain: 'istanbulkart-web-preprod.web.app',
    key: '6LdAtrMeAAAAAP8eM1XVMjs1DKu--eTddUEzl36L',
  },
  {
    // Staging
    skip: true,
    domain: 'istanbulkart-tmob-test.web.app',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
  {
    // Staging
    skip: true,
    domain: 'istanbulkart-web-test.web.app',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
  {
    // Local
    skip: true,
    domain: 'localhost',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
];

const LoginPasswordStep: FC<LoginPasswordStepProps> = memo(
  ({
    formikProps: {
      setFieldValue,
      values: { password },
      errors: { password: errorPassword },
      touched: { password: touchedPassword },
    },
  }) => {
    const dispatch = useDispatch();
    const { web_title_password_screen, web_desc_password_screen } = useLocalization();
    const { getErrorMessage } = useErrorMessage();
    const history = useHistory();
    const hostName = window.location.hostname;
    const captchaConfig = CAPTCHA_KEYS.find((item) => item.domain === hostName);

    function onChange(value: string | null) {
      dispatch(setReCaptchaToken(value));
    }

    useEffect(() => {
      history.listen((location) => {
        if (location.pathname !== '/login') {
          dispatch(setReCaptchaToken(null));
        }
      });
    }, []);

    return (
      <div id="login-password-step">
        <Typography variant="h5" align="center">
          <Box fontWeight={600} marginBottom={gutters.regular}>
            {web_title_password_screen}
          </Box>
        </Typography>
        <Typography variant="body1" align="center" component="span">
          <Box marginBottom={gutters.big} fontSize={fontSizes.regular}>
            {/**TODO-CSS */}
            <p className="login-password-desc"> {web_desc_password_screen}</p>
          </Box>
        </Typography>
        <InputOneCharBox
          value={password}
          setValue={(val) => setFieldValue('password', val)}
          amount={6}
          isPassword
          showPasswordToggle
        />
        <Box marginBottom={gutters.regular}>
          <FormHelperText error>{getErrorMessage(errorPassword, touchedPassword)}</FormHelperText>
        </Box>
      </div>
    );
  }
);

export default LoginPasswordStep;
