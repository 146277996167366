import { FC } from 'react';
import { LoadingGiftCards } from 'Components';
import './Loading.scss';

type LoadingProps = {};

const Loading: FC<LoadingProps> = () => {
  return (
    <div id="loading">
      <LoadingGiftCards />
    </div>
  );
};

export default Loading;
