import { FC, memo } from 'react';
import { ChevronDarkRightIcon, CreditCardWithPlusIcon, MasterpassLogo, CloseIcon, DarkPlusIcon } from 'Components';
import { useCreditCardType, useLocalization, useModal } from 'Hooks';
import { Box, Typography, Dialog } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { SelectedCreditCard } from './SelectedCreditCard';
import { MasterPassCardType } from 'Models';
import { CreditCardItem } from 'Views/AddBalanceToCard/LoadBalance/ChooseCreditCard/CreditCardItem';

import './ChooseCreditCard.scss';

type ChooseCreditCardProps = {
  selectedCreditCard?: MasterPassCardType;
  creditCardList?: MasterPassCardType[];
};

const ChooseCreditCard: FC<ChooseCreditCardProps> = memo(({ selectedCreditCard, creditCardList }) => {
  const {
    web_title_payment_instrument,
    web_choose_bank_card_desc,
    web_choose_bank_card,
    web_title_creditcard_popup,
    web_desc_creditcard_popup,
    web_btn_enter_new_card_creditcard_popup,
  } = useLocalization();

  const { isVisible, show, hide } = useModal();
  const history = useHistory();

  return (
    <div id="chooseCreditCard">
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="h6">
          <Box fontWeight="bold" marginBottom={fontSizes.regular}>
            {web_title_payment_instrument}
          </Box>
        </Typography>
        <MasterpassLogo />
      </Box>
      {!selectedCreditCard && (
        <Box className="chooseCreditCard__container" onClick={() => show()}>
          <Box display="flex" alignItems="center">
            <CreditCardWithPlusIcon />
            <Box>
              <Typography variant="h6">
                <Box fontWeight="bold" className="chooseCreditCard__toBeLoaded">
                  {web_choose_bank_card}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box>{web_choose_bank_card_desc}</Box>
              </Typography>
            </Box>
          </Box>
          <ChevronDarkRightIcon />
        </Box>
      )}
      {selectedCreditCard && (
        <Box onClick={show}>
          <SelectedCreditCard creditCardItem={selectedCreditCard} hide={() => {}} isDialog={false} />
        </Box>
      )}
      <Dialog open={isVisible} onClose={hide}>
        <div className="dialogContainer chooseCardCreditCard__dialog">
          <CloseIcon onClick={() => hide()} />
          <Box>
            <Typography variant="h3" align="center">
              <Box fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.regular}>
                {web_title_creditcard_popup}
              </Box>
            </Typography>
            <Typography variant="body1" align="center">
              <Box fontSize={fontSizes.regular} marginBottom={gutters.big}>
                {web_desc_creditcard_popup}
              </Box>
            </Typography>
            <Box className="chooseCardCreditCard__dialog__container">
              {creditCardList &&
                creditCardList.map((item, index) => (
                  <CreditCardItem key={index} creditCardItem={item} isDialog hide={hide} />
                ))}
            </Box>
            <div
              onClick={() => {
                history.push(routePath.addCreditCard);
              }}
              itemID="addCreditCardToUploadSubscriptionBtnTest"
            >
              <Box className="chooseCardCreditCard__dialog--addButton">
                <DarkPlusIcon />
                <Box flex={1}>
                  <Typography variant="h6" align="center">
                    <Box fontWeight={600}>{web_btn_enter_new_card_creditcard_popup}</Box>
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  );
});

export default ChooseCreditCard;
