import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Stores/index';
import { FormState } from './types';

export const securityErrors = {
  505: '505',
  506: '506',
  507: '507',
};

export const initialState: FormState = {
  errorCode: '',
  formName: '',
};

const FormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormError: (state: FormState, { payload: { errorCode, formName } }: PayloadAction<FormState>) => {
      state.errorCode = errorCode;
      state.formName = formName;
    },
    resetFormError: (state: FormState) => {
      state.errorCode = '';
      state.formName = '';
    },
    resetFormStore: (state: FormState) => initialState,
  },
});

export const storeFormError = (state: RootState) => state.form;

export const { setFormError, resetFormError, resetFormStore } = FormSlice.actions;
export default FormSlice.reducer;
