import { FC, Fragment, memo } from 'react';
import { AlertIcon, ChevronRedRightIcon, CloseIcon } from 'Components';
import { Box, Dialog, Typography, Link } from '@material-ui/core';
import { useLocalization, useModal } from 'Hooks';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { TaksListItem } from 'Models/InstrunctionListReq';
import { InstructionItem } from 'Stores/CardTransactions';

import './WaitingInstructions.scss';

type WaitingInstructionsProps = {
  cardWaitingInstructions?: InstructionItem[] | TaksListItem[] | any; // Typescript gives error always so make it any
  isAbonman?: boolean;
};

const WaitingInstructions: FC<WaitingInstructionsProps> = memo(({ cardWaitingInstructions, isAbonman }) => {
  const {
    web_title_load_pending_instruction,
    web_desc_load_pending_instruction,
    web_desc2_load_pending_instruction,
    web_card_detail_waiting_instruction,
    web_card_detail_complete_instruction,
    web_waiting_subscription,
    web_waiting_subscription_popup_header,
    web_waiting_subscription_popup_blue_text,
  } = useLocalization();
  const { isVisible, show, hide } = useModal();

  return (
    <Fragment>
      <div id="waitingInstructions" onClick={() => show()} itemID="seeCardWaitingInstructionsBtnTest">
        <Box display="flex" alignItems="center">
          <Box>
            <AlertIcon />
          </Box>
          <Box fontSize={fontSizes.small} marginLeft={fontSizes.small}>
            {cardWaitingInstructions && cardWaitingInstructions?.length === 1 && (
              <Box>
                <Box fontSize={fontSizes.regular} fontWeight="bold" color={colors.toastErrorText}>
                  {isAbonman ? web_waiting_subscription : web_card_detail_waiting_instruction} -{' '}
                  {cardWaitingInstructions[0]?.Amount
                    ? cardWaitingInstructions[0]?.Amount
                    : cardWaitingInstructions[0]?.TaskAmount}{' '}
                  TL
                </Box>
                <Typography variant="subtitle2">
                  {isAbonman ? web_waiting_subscription : web_card_detail_complete_instruction}
                </Typography>
              </Box>
            )}

            {cardWaitingInstructions && cardWaitingInstructions?.length > 1 && (
              <Box>
                <Box fontSize={fontSizes.regular} fontWeight="bold" color={colors.toastErrorText}>
                  {cardWaitingInstructions?.length} {web_card_detail_waiting_instruction}
                </Box>
                <span className="amountLabel">
                  {cardWaitingInstructions &&
                    cardWaitingInstructions.map((item: any, index: number) => (
                      <Typography variant="subtitle2" component="span" key={index}>
                        {index !== 0 && <span> + </span>}
                        {item?.Amount ? item?.Amount : item?.TaskAmount} TL
                      </Typography>
                    ))}
                </span>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <ChevronRedRightIcon />
        </Box>
      </div>
      <Dialog open={isVisible} onClose={hide} keepMounted={false} disablePortal={true}>
        <Box className="dialogContainer">
          <CloseIcon onClick={() => hide()} />
          <Box>
            <Typography variant="h6">
              <Box textAlign="center" fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.big}>
                {isAbonman ? web_waiting_subscription_popup_header : web_title_load_pending_instruction}
              </Box>
            </Typography>
            <Link underline="none" style={{ cursor: 'unset' }}>
              <Box color={colors.primary} marginBottom={gutters.big} textAlign="center">
                {isAbonman ? web_waiting_subscription_popup_blue_text : web_desc_load_pending_instruction}
              </Box>
            </Link>
            <Typography variant="subtitle2">
              <Box fontSize={fontSizes.regular} textAlign="center">
                {web_desc2_load_pending_instruction}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
});

export default WaitingInstructions;
