import { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

import './InfoBox.scss';

type InfoBoxProps = BoxProps & {
  title: string;
  value: string;
  tl?: boolean;
  iban?: boolean;
  spaceBetween?: 'large' | 'small';
};

const InfoBox: FC<InfoBoxProps> = ({ title, value, tl, iban, spaceBetween, ...boxProps }) => {
  return (
    <Box {...boxProps} className="digital-account-info-box">
      <Box
        className={`digital-account-info-box__title ${
          spaceBetween
            ? spaceBetween === 'large'
              ? 'digital-account-info-box__marginLarge'
              : 'digital-account-info-box__marginSmall'
            : ''
        }`}
      >
        {title}
      </Box>
      <Box
        color={'#fff'}
        fontWeight={700}
        className={`digital-account-info-box__value${iban ? ' digital-account-info-box__value__iban' : ''}`}
      >
        {`${value} `}
        {tl && <span className="digital-account-info-box__tl-text">TL</span>}
      </Box>
    </Box>
  );
};

export default InfoBox;
