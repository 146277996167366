const maskHesCode = (text: string) => {
  if (text?.includes('-')) {
    const maskedText = text?.split(' - ').join('');
    return maskedText;
  } else {
    const maskedText = text?.match(/.{1,4}/g)?.join(' - ');

    return maskedText;
  }
};

export default maskHesCode;
