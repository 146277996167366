import { Box } from '@material-ui/core';
import { newsItem } from 'Models/GetNewsModel';
import { FC, memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './NewsItem.scss';

export type NewsItemProps = {
  newsItem: newsItem;
  onClick: () => void;
  itemID: string;
};

const NewsItem: FC<NewsItemProps> = memo(({ newsItem, onClick, itemID }) => {
  return (
    <Box id="news-item" onClick={onClick} itemID={itemID}>
      <LazyLoadImage src={newsItem?.WebCoverPictureUrl} effect="opacity" />
    </Box>
  );
});
export default NewsItem;
