import { FC } from 'react';
import { ChevronDarkRightIcon, TickIcon } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { cardBalanceNormalize, splitMifareIdAndAliasNumber } from 'Utils';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { useCardType } from 'Hooks';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './SelectedCard.scss';
import { setReceiverCard } from 'Stores/BalanceTransfer';

type SelectedCardProps = {
  cardItem: cardItemParams;
  hide?: Function;
  isSelected?: boolean;
  isDialog: boolean;
  disabled?: boolean;
};

const SelectedCard: FC<SelectedCardProps> = ({ cardItem, hide, isSelected, isDialog, disabled }) => {
  const dispatch = useDispatch();
  //To show greenn border check if addedTemporary card is the same with selected card
  const { cardPath, cardColor } = useCardType(cardItem?.ProductCode);

  return (
    <div
      id="selectedCard"
      onClick={() => {
        if (!disabled) {
          dispatch(setReceiverCard(cardItem));
          hide && hide();
        }
      }}
      itemID="chooseCardToUploadTLBtnTest"
    >
      <Box
        className={`selectedCard__container selectedCard__${cardColor} ${
          isSelected ? 'selectedCard__container--active' : ''
        }`}
      >
        <Box display="flex">
          <Box display="flex">
            <LazyLoadImage className="selectedCard--img" src={cardPath} alt="" effect="opacity" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            marginLeft={gutters.regular}
            flex={1}
          >
            {cardItem?.NickName && <Box className="selectedCard--title">{cardItem?.NickName}</Box>}
            {cardItem?.MaskedName && cardItem?.MaskedSurname && (
              <Box className="selectedCard--title">
                {cardItem?.MaskedName} {cardItem?.MaskedSurname}
              </Box>
            )}
            {cardItem?.AliasNumber && (
              <Box className="selectedCard--aliasNumber">{splitMifareIdAndAliasNumber(cardItem?.AliasNumber)}</Box>
            )}
            {(cardItem?.CardBalance || cardItem?.CardBalance === 0) && cardItem?.CardBalance.toString() && (
              <Typography variant="h6">
                <Box fontWeight="bold" fontSize={fontSizes.regularBig}>
                  {cardBalanceNormalize(cardItem?.CardBalance)} TL
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
        {isDialog && isSelected && <TickIcon />}
        {!disabled && !isDialog && <ChevronDarkRightIcon />}
      </Box>
    </div>
  );
};

export default SelectedCard;
