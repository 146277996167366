const agreementsTypes = {
  GIZLILIK_POLITIKASI: 16,
  ISTANBULKART_KULLANIM: 2,
  TICARI_ELEKTRONIK_ILETISIM_IZNI: 3,
  ACIK_RIZA_METNI: 4,
  IBB_AYDINLATMA_METNI: 5,
  UYGULAMA_KULLANIM_KOSULLARI: 6,
  CERCEVE_SOZLESMESI: 6,
  AYDINLATMA_METNI: 7,
};

export default agreementsTypes;
