import { FC, useState, useRef, useCallback, LegacyRef, ChangeEvent } from 'react';
import { BigCirclePlus, CameraIcon, DialogWithActions, PhotoStepPopup } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography, Button } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { checkCameraPermission, getBase64ImageType, getImageFromDevice } from 'Utils';

import Webcam from 'react-webcam';
import moment from 'moment';

import './AddPhotoStep.scss';
import { ApplicationProcessState, applicationStore, incStep, setApplicationData } from 'Stores/CardApplication';
import { useDispatch, useSelector } from 'react-redux';
import CameraContainer from 'Assets/Images/cameraContainer.png';

type AddPhotoStepProps = {};

const AddPhotoStep: FC<AddPhotoStepProps> = () => {
  const dispatch = useDispatch();

  const webcamRef: any = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const store: ApplicationProcessState = useSelector(applicationStore);
  const { activeApplicationData } = store;

  const [isPermissionGranted, setIsPermissionGrandted] = useState<boolean>(false);
  const [isOpenCamera, setIsOpenCamera] = useState<boolean>(false);
  const [isOpenPhotoDialog, setIsOpenPhotoDialog] = useState<boolean>(false);
  const [isOpenRuleDialog, setIsOpenRuleDialog] = useState<boolean>(false);
  const [isOpenIntroDialog, setIsOpenIntroDialog] = useState<boolean>(false);

  const [photoSrc, setPhotoSrc] = useState<string | undefined>(activeApplicationData?.DocumentData);
  const [imageType, setImageType] = useState<string | undefined>(activeApplicationData?.MimeType);

  const {
    web_card_application_add_photo,
    web_card_application_click_to_add,
    web_photo_rules_link_btn,
    web_btn_next,
    web_photo_rules_popup_title,
    web_photo_adding_method_pc,
    web_photo_adding_method_title,
    web_photo_adding_method_webcam,
    web_take_photo_btn,
    web_btn_vazgec,
    web_photo_click_to_change,
    web_photo_rules_popup_desc_new,
    web_photo_rules_popup_btn,
    web_btn_okay,
  } = useLocalization();

  const capture = useCallback(() => {
    const imageSrc: string = webcamRef?.current?.getScreenshot();
    setImageType(getBase64ImageType(imageSrc));
    setIsOpenCamera(false);
    setPhotoSrc(imageSrc);
  }, [webcamRef]);

  const getCameraPermission = async () => {
    setIsOpenCamera(true);
    await checkCameraPermission(setIsPermissionGrandted);
  };

  const confirmImage = () => {
    dispatch(
      setApplicationData({
        FileName: String(moment(new Date()).format('ddMMyyyyhhmmss')), //+ `.${imageType}`,
        DocumentData: photoSrc,
        MimeType: imageType,
      })
    );
    dispatch(incStep());
  };

  const choosedFromPc = async (event: ChangeEvent<HTMLInputElement>) => {
    const { type, src } = await getImageFromDevice(event);
    setImageType(type);
    src !== '' && setPhotoSrc(src);
    setIsOpenPhotoDialog(false);
  };

  return (
    <div id="addPhotoStep">
      <Typography variant="h3" align="center">
        <Box mt={gutters.bigger} fontWeight={600}>
          {web_card_application_add_photo}
        </Box>
      </Typography>
      <input onChange={choosedFromPc} ref={inputRef} hidden accept="image/png, image/jpg, image/jpeg" type="file" />
      <div className="addPhotoStep">
        <div
          onClick={() => !isOpenCamera && !photoSrc && setIsOpenIntroDialog(true)}
          className="addPhotoStep__area"
          itemID="openCameraDialogBtnTest"
        >
          {photoSrc ? (
            <>
              <img src={photoSrc} className="addPhotoStep__img" />
              <div
                itemID="changePhotoBtnTest"
                onClick={() => {
                  setIsOpenPhotoDialog(true);
                }}
                className="addPhotoStep__change-image"
              >
                {web_photo_click_to_change}
              </div>
            </>
          ) : !isOpenCamera || !isPermissionGranted ? (
            <>
              <BigCirclePlus />
              <Typography variant="h3" align="center">
                <Box color={colors.darkGray} fontSize={fontSizes.smaller}>
                  {web_card_application_click_to_add}
                </Box>
              </Typography>
            </>
          ) : (
            <div className="cameraContainer">
              <Webcam
                ref={webcamRef}
                videoConstraints={{
                  width: 200,
                  height: 256,
                  facingMode: 'user',
                }}
                screenshotFormat="image/jpeg"
                width={200}
                height={250}
                className="webcam"
                audio={false}
              />
              <img src={CameraContainer} />
            </div>
          )}
        </div>

        <Typography variant="h3" align="center">
          {!!photoSrc ? (
            <Box
              onClick={() => setIsOpenRuleDialog(true)}
              className="addPhotoStep__rules"
              mt={gutters.big}
              mb={gutters.big}
              color={colors.primary}
              fontSize={fontSizes.smaller}
              itemID="openPhotoRuleBtnTest"
            >
              {web_photo_rules_link_btn}
            </Box>
          ) : (
            <div style={{ height: 54 }}></div>
          )}
        </Typography>
        {!isOpenCamera || !isPermissionGranted ? (
          <Button
            disabled={!!!photoSrc}
            onClick={() => photoSrc && confirmImage()}
            variant="contained"
            color="secondary"
            fullWidth
            itemID="loadImageBtnTest"
          >
            {web_btn_next}
          </Button>
        ) : (
          <>
            <Button
              startIcon={<CameraIcon />}
              onClick={capture}
              variant="outlined"
              color="secondary"
              fullWidth
              itemID="takePhotoBtnTest"
            >
              {web_take_photo_btn}
            </Button>
            <Box mt={gutters.small} />
            <Button
              style={{ color: 'red' }}
              onClick={() => setIsOpenCamera(false)}
              variant="text"
              color="default"
              fullWidth
              itemID="closeCameraBtnTest"
            >
              {web_btn_vazgec}
            </Button>
          </>
        )}
      </div>
      <DialogWithActions
        alignTitle="left"
        closeIcon
        primaryButton={{
          handleClick: () => {
            inputRef.current?.click();
          },
          label: web_photo_adding_method_pc,
        }}
        secondaryButton={{
          handleClick: () => {
            setPhotoSrc(undefined);
            getCameraPermission();
            setIsOpenPhotoDialog(false);
          },
          label: web_photo_adding_method_webcam,
          type: 'contained',
        }}
        open={isOpenPhotoDialog}
        hide={() => setIsOpenPhotoDialog(false)}
        title={web_photo_adding_method_title}
      />
      <PhotoStepPopup
        isOpen={isOpenIntroDialog || isOpenRuleDialog}
        buttonText={isOpenRuleDialog ? web_btn_okay : web_photo_rules_popup_btn}
        onClick={() => {
          setIsOpenIntroDialog(false);
          if (!isOpenRuleDialog) {
            setIsOpenPhotoDialog(true);
          }
          setIsOpenRuleDialog(false);
        }}
      />
    </div>
  );
};
export default AddPhotoStep;
