import * as React from 'react';

function FacebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="37px"
      height="37px"
      viewBox="0 0 37 37"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>26686400-E7AB-4084-8475-89ECA2C6A8C6</title>
      <g id="Hesabım---bize-ulaşın" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop---Bize-Ulaşın---1.1" transform="translate(-491.000000, -633.000000)">
          <g id="Group-3" transform="translate(491.000000, 633.000000)">
            <rect id="Rectangle" fill="#1877F2" fillRule="nonzero" x="0" y="0" width="37" height="37" rx="18.5"></rect>
            <path
              d="M27,7 L27,31.615 L21.4911085,31.6153846 L21.491,37 L15.764,37 L15.7647996,30.8461538 L11,30.846 L11,15 C11,10.581722 14.581722,7 19,7 L27,7 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M37,18.6130598 C37,8.33334316 28.7172754,0 18.5,0 C8.28272461,0 0,8.33334316 0,18.6130598 C0,27.903376 6.76518262,35.603657 15.609375,37 L15.609375,23.9933974 L10.9121094,23.9933974 L10.9121094,18.6130598 L15.609375,18.6130598 L15.609375,14.5123701 C15.609375,9.84747195 18.3712949,7.27072648 22.5970996,7.27072648 C24.6211514,7.27072648 26.7382812,7.63426281 26.7382812,7.63426281 L26.7382812,12.2148205 L24.4054746,12.2148205 C22.1073193,12.2148205 21.390625,13.6495893 21.390625,15.1215479 L21.390625,18.6130598 L26.5214844,18.6130598 L25.7012695,23.9933974 L21.390625,23.9933974 L21.390625,37 C30.2348174,35.603657 37,27.903376 37,18.6130598"
              id="Fill-1"
              fill="#1877F2"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FacebookIcon;
