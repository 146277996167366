import { FC, Fragment, useEffect, useState } from 'react';
import { Box, Button, Radio, Typography } from '@material-ui/core';
import { DashboardLayout } from 'Components';
import { useLocalization, useLanguage } from 'Hooks';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'Stores/App';
import { generateLanguageParam, scrollTop } from 'Utils';
import { onInıtDataRequest } from 'Stores/App';
import { useHistory } from 'react-router-dom';
import { localStorageKeys } from 'Config';
import { showToastify } from 'Stores/Toastify';
import { fontSizes, gutters } from 'Theme/Variables';

import './ChangeLanguage.scss';

let oldUpdateText: string | null = null;

const ChangeLanguage: FC = () => {
  const {
    web_account_website_language,
    web_account_website_language_turkish,
    web_account_website_language_english,
    web_btn_approve,
    web_account_website_language_update_text,
  } = useLocalization();

  const i18n = useLanguage();
  const dispatch = useDispatch();
  const history = useHistory();

  const [languageId, setLanguageId] = useState<string>();
  const language = localStorage.getItem(localStorageKeys.i18nextLng) === 'en' ? 'tr' : 'en';

  useEffect(() => {
    if (oldUpdateText === null) {
      oldUpdateText = web_account_website_language_update_text;
    } else if (oldUpdateText !== web_account_website_language_update_text) {
      onChangedLanguage();
    }
    setLanguageId(language === 'tr' ? 'en' : 'tr');
    scrollTop();
  }, [web_account_website_language_update_text]);

  const data = [
    {
      title: web_account_website_language_turkish,
      id: 'tr',
      itemID: 'changeLanguageTrBtnTest',
    },
    {
      title: web_account_website_language_english,
      id: 'en',
      itemID: 'changeLanguageEnBtnTest',
    },
  ];

  const onChangedLanguage = () => {
    oldUpdateText = null;
    dispatch(
      showToastify({
        toastMessage: web_account_website_language_update_text,
        type: 'success',
      })
    );
    history.goBack();
  };

  const handleSubmit = () => {
    i18n.changeLanguage(languageId);
    dispatch(setLanguage());
    dispatch(onInıtDataRequest(generateLanguageParam()));
    if (language !== languageId) {
      onChangedLanguage();
    }
  };

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="change-language">
          <Typography variant="h3" align="center">
            <Box marginBottom={5} fontWeight={600}>
              {web_account_website_language}
            </Box>
          </Typography>
          {data.map((e) => (
            <div key={e.id} className="change-language__check" onClick={() => setLanguageId(e.id)} itemID={e.itemID}>
              <Radio checked={languageId === e.id} color="primary" />
              <Typography variant="h3" align="center">
                <Box fontWeight={600} marginLeft={gutters.small} fontSize={fontSizes.regular}>
                  {e.title}
                </Box>
              </Typography>
            </div>
          ))}
          <Box marginTop={gutters.regular}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              itemID="changeLanguageBtnTest"
            >
              {web_btn_approve}
            </Button>
          </Box>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};

export default ChangeLanguage;
