export const mobileMasterpassConnect = ({ sendSmsLanguage, referenceNo, token, msisdn }: any) => {
  const reqValues = {
    sendSmsLanguage,
    sendSms: 'N',
    referenceNo,
    token,
    cardAliasName: null,
    msisdn,
    fp: '',
    clientId: '34706580',
    dateTime: new Date(),
    version: '34',
    clientType: '1',
  };
  return reqValues;
};
