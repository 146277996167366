import { FC, Fragment, useEffect, useState } from 'react';
import { DashboardLayout, DialogWithActions, MaskedTextField } from 'Components';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { FormWrapper } from 'Components';
import './CardInfo.scss';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userStore } from '../../../Stores/User';
import {
  closeAlreadyHave,
  onOnlineApplicationRequest,
  resetRelationData,
  setRelationData,
  storeCardTransactions,
} from '../../../Stores/CardTransactions';
import OnGetCustodyRelationQuery from 'Services/Queries/OnGetCustodyRelation';
import { setActiveCard } from 'Stores/CardApplication';
import { routePath } from 'Navigator/routes';
import { useFormik } from 'formik';
import CardInfoValidationSchema from './CardInfoValidationSchema';
import { inputMaskTypes } from 'Config';
import CardApplicationErrors from '../CartApplicationErrors';
type CardApplicationItemProps = {
  item: OnOnlineApplicationCardQueryItemModel;
  checkAlreadyApplicationStatus: (event: OnOnlineApplicationCardQueryItemModel) => void;
};

type FormFields = {
  tckn: string;
  birthDate: string;
};

const CardInfo: FC<CardApplicationItemProps> = ({ item, checkAlreadyApplicationStatus }) => {
  const dispatch = useDispatch();
  const { customerNumber, sessionToken, citizenshipNumber } = useSelector(userStore);
  const translations = useLocalization();

  const {
    web_card_application_title,
    web_card_already_exists,
    cant_apply_popup_title,
    web_btn_okay,
    web_cant_apply_popup_title,
    web_cant_apply_this_card,
    web_plc_dogum_tarihi_formati,
    web_plc_birthday,
    web_plc_tckn,
  } = translations;
  const [birthDateError, setBirthDateError] = useState('');
  const [cantyApplyError, setCantApplyError] = useState('');
  const { getError, getErrorMessage } = useErrorMessage();
  const [showCantApplyDialog, setShowCantApplyDialog] = useState(false);

  const onSubmit = async (values: FormFields) => {
    const [D, M, Y] = values.birthDate.split('.');
    const finalBirthDate = `${Y}-${M}-${D}T00:00:00`;
    const CustodyTckn = values.tckn;
    const BirthDate = finalBirthDate;

    const response = await OnGetCustodyRelationQuery({
      CustomerNumber: customerNumber,
      SessionToken: sessionToken,
      LoginUserTckn: citizenshipNumber,
      CustodyTckn,
      BirthDate,
    });

    const ResponseCode = response?.data?.ResponseCode;

    if (ResponseCode === '997') {
      setShowCantApplyDialog(true);
      return;
    }

    if (ResponseCode === '1160') {
      setBirthDateError('Doğum tarihi bilgisi hatalı.');
      return;
    }

    if (ResponseCode === '1142') {
      setCantApplyError(web_cant_apply_this_card);
      return;
    }

    const HasCustodyRelation = response?.data?.HasCustodyRelation;
    const HashData = response?.data?.HashData;
    const CustodyCustomerNumber = response?.data?.CustomerNumber;

    if (HasCustodyRelation) {
      const isSportCard = currentItem?.ProductCode === 'IKT_SPR';
      dispatch(setActiveCard(currentItem));
      dispatch(setRelationData({ CustodyTckn, HashData, HasCustodyRelation, BirthDate, CustodyCustomerNumber }));
      if (!isSportCard) {
        history.push(routePath.cardApplicationSteps, { item: currentItem, relationShipApply: true });
      } else {
        dispatch(
          onOnlineApplicationRequest({
            request: {
              SubProductCode: null,
              ProductCode: currentItem?.ProductCode,
              CitizenshipNumber: citizenshipNumber,
              CustomerNumber: CustodyCustomerNumber,
              SessionToken: sessionToken,
              LoginUserTckn: citizenshipNumber,
              CustodyTckn,
              HasCustodyRelation,
              HashData,
              CustodyCustomerNumber,
              applicationForRelative: CustodyTckn ? true : false,
            },
            product: currentItem,
            relationShipApply: true,
          })
        );
      }
    }
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik<FormFields>({
    initialValues: {
      tckn: '',
      birthDate: '',
    },
    validationSchema: CardInfoValidationSchema,
    onSubmit,
  });

  const { isOpenAlreadyHave } = useSelector(storeCardTransactions);

  useEffect(() => {
    dispatch(resetRelationData());
  }, [dispatch]);

  const history = useHistory<any>();
  const currentItem = history?.location?.state?.item as OnOnlineApplicationCardQueryItemModel;

  return (
    <DashboardLayout backButton>
      <Fragment>
        <CardApplicationErrors />
        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => setBirthDateError(''),
          }}
          open={!!birthDateError}
          hide={() => setBirthDateError('')}
          title={web_cant_apply_popup_title}
          text={birthDateError}
        />
        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => setCantApplyError(''),
          }}
          open={!!cantyApplyError}
          hide={() => setCantApplyError('')}
          title={web_cant_apply_popup_title}
          text={cantyApplyError}
        />
        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => setShowCantApplyDialog(false),
          }}
          open={!!showCantApplyDialog}
          hide={() => setShowCantApplyDialog(false)}
          title={web_cant_apply_popup_title}
          text={translations?.[997]}
        />
        <div className="form-content" id="cardApplication">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{web_card_application_title}</Box>
          </Typography>
          <Typography variant="h3" align="center">
            <Box mb={gutters.big} color={colors.checkboxLabel} fontSize={fontSizes.regular} mt={gutters.small}>
              Kart başvurusunu kim için yapmak istiyorsun?
            </Box>
          </Typography>
          <Typography variant="h3">
            <Box fontWeight={600}>Kişisel bilgiler</Box>
          </Typography>
          <FormWrapper onSubmit={handleSubmit}>
            <Box marginTop={gutters.regular}>
              <MaskedTextField
                name="tckn"
                mask={inputMaskTypes.tcNo}
                label="T.C. Kimlik Numarası (Yakınının)"
                value={values.tckn}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors.tckn, touched.tckn)}
                helperText={getErrorMessage(errors.tckn, touched.tckn)}
                itemID="tcNoInputTest"
              />
            </Box>
            <Box marginTop={gutters.regular}>
              <MaskedTextField
                name="birthDate"
                mask={inputMaskTypes.date}
                label="Doğum Tarihi (Yakınının)"
                value={values.birthDate}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={web_plc_dogum_tarihi_formati}
                error={getError(errors.birthDate, touched.birthDate)}
                helperText={getErrorMessage(errors.birthDate, touched.birthDate)}
                itemID="birtDateInputTest"
              />
            </Box>
            <Button
              style={{ marginTop: 15 }}
              className="submit-button"
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              itemID="changeUserMailBtnTest"
            >
              Devam Et
            </Button>
          </FormWrapper>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default CardInfo;
