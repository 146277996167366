import { FC, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { SelectWithDialog } from '../SelectWithDialog';
import { CardTransactionListTypeEnum } from 'Utils';
import { useState } from 'react';

import './ListHeader.scss';

type ListHeaderProps = {
  changeTransactionType?: any;
  transactionTypeValue?: CardTransactionListTypeEnum;
  historyTypeValue?: number;
  changeHistoryType?: any;
  openFilter: any;
  badgeCount?: number;
  isOtherCard: boolean;
};

const ListHeader: FC<ListHeaderProps> = ({
  changeTransactionType,
  transactionTypeValue,
  historyTypeValue,
  changeHistoryType,
  openFilter,
  badgeCount,
  isOtherCard,
}) => {
  const {
    web_btn_date_card_transactions,
    web_btn_tranaction_type_card_transactions,
    web_card_transactions_all,
    web_load_card_transactions,
    web_spending_card_transactions,
    web_this_month_card_transactions,
    web_3months_card_transactions,
    web_6months_card_transactions,
    web_9months_card_transactions,
    web_title_detail_search,
    web_this_week_card_transactions,
    web_max_transactions,
    web_digital_account_transactions_custom,
  } = useLocalization();

  const [historyLabel, setHistoryLabel] = useState<string>('');
  const [transLabel, setTransLabel] = useState<string>('');

  useEffect(() => {
    historyLabel === '' && setHistoryLabel(web_this_week_card_transactions);
    if (!transLabel) {
      setTransLabel(web_card_transactions_all);
    }
    if (transactionTypeValue === CardTransactionListTypeEnum.LOAD) {
      setTransLabel(web_load_card_transactions);
    }
  }, []);

  const historyData = [
    {
      label: web_this_week_card_transactions,
      value: 7,
      id: 7,
    },
    {
      label: web_this_month_card_transactions,
      value: 30,
      id: 30,
    },
    {
      label: web_3months_card_transactions,
      value: 90,
      id: 90,
    },
    { label: web_digital_account_transactions_custom, value: 0, id: 0, hidden: true },
    // {
    //   label: web_6months_card_transactions,
    //   value: 180,
    //   id: 180,
    // },
    // web_max_transactions == '9'
    //   ? {
    //       label: web_9months_card_transactions,
    //       value: 270,
    //       id: 270,
    //     }
    //   : undefined,
  ];
  const transactionData = [
    {
      label: web_card_transactions_all,
      value: CardTransactionListTypeEnum.ALL,
      id: 0,
    },
    {
      label: web_load_card_transactions,
      value: CardTransactionListTypeEnum.LOAD,
      id: 1,
    },
    {
      label: web_spending_card_transactions,
      value: CardTransactionListTypeEnum.PAYMENT,
      id: 2,
    },
  ];

  return (
    <Grid className="transactionListHeader" spacing={1} container>
      <Grid item xs={6} sm={5}>
        <Box>
          <SelectWithDialog
            selectedValue={historyTypeValue}
            onChange={(value: number, label: string) => {
              setHistoryLabel(label);
              changeHistoryType(value);
            }}
            bodyData={historyData}
            dialogTitle={web_btn_date_card_transactions}
            label={web_btn_date_card_transactions}
            text={historyData.find((x) => x.value === historyTypeValue)?.label ?? ''}
          />
        </Box>
      </Grid>
      <Grid item xs={6} sm={5}>
        <Box>
          <SelectWithDialog
            bodyData={transactionData}
            onChange={(value: number, label: string) => {
              changeTransactionType(value);
              setTransLabel(label);
            }}
            selectedValue={transactionTypeValue}
            dialogTitle={web_btn_tranaction_type_card_transactions}
            label={web_btn_tranaction_type_card_transactions}
            text={transLabel}
            isOtherCard={isOtherCard}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <Button onClick={openFilter} className="transactionListHeader__button" variant="outlined" fullWidth>
            {badgeCount !== 0 && <div className="transactionListHeader__button__badge">{badgeCount}</div>}
            <Typography variant="caption">
              <Box fontSize={'11px'} fontWeight={500}>
                {web_title_detail_search}
              </Box>
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListHeader;
