export const deleteRegisteredTransactionNormalize = (
  mifareId: string,
  customerNumber?: number,
  sessionToken?: string
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: mifareId,
  };
  return formValues;
};
