import { FC } from 'react';
import { ChevronDarkRightIcon } from 'Components';
import { useCardType } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { fontSizes, gutters } from 'Theme/Variables';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router';
import './CardApplicationItem.scss';
import { routePath } from 'Navigator/routes';

type CardApplicationItemProps = {
  item: OnOnlineApplicationCardQueryItemModel;
  checkAlreadyApplicationStatus: (event: OnOnlineApplicationCardQueryItemModel) => void;
};

const CardApplicationItem: FC<CardApplicationItemProps> = ({ item, checkAlreadyApplicationStatus }) => {
  const { cardPath } = useCardType(item.ProductCode);
  const history = useHistory();

  const handleCardClick = () => {
    if (item.ProductCode === 'IKT_ORC' || item.ProductCode === 'IKT_SPR') {
      history.push(routePath.studentCard, { item });
    } else {
      checkAlreadyApplicationStatus(item);
    }
  };
  return (
    <div>
      <div
        key={item.ProductName}
        onClick={handleCardClick}
        className="cardApplicationItem__item"
        itemID="applyCardBtnTest"
      >
        <div className="cardApplicationItem__item__first-row">
          <LazyLoadImage className="cardApplicationItem__item__img" src={cardPath} effect="opacity" />
          <Typography variant="h3" align="center">
            <Box
              style={{ textTransform: 'capitalize' }}
              ml={gutters.regular}
              fontSize={fontSizes.regular}
              fontWeight={600}
            >
              {item.ProductName.toLocaleLowerCase('tr-TR')}
            </Box>
          </Typography>
        </div>
        <ChevronDarkRightIcon />
      </div>
    </div>
  );
};
export default CardApplicationItem;
