import { FunctionComponent, memo, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalization } from 'Hooks';
import { fontSizes, gutters } from 'Theme/Variables';
import { Box, Typography } from '@material-ui/core';
import { RegisteredHes } from './RegisteredHes';
import { UnregisteredHes } from './UnregisteredHes';
import { DashboardLayout, FormErrorWrapper } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { decrementHesCodeState, incrementHesCodeState } from 'Stores/User/slices';
import { formNames } from 'Config';
import { resetFormError } from 'Stores/Form/slices';

import './Hes.scss';

type HesParams = {};
type HesProps = RouteComponentProps<HesParams>;

const Hes: FunctionComponent<HesProps> = memo(() => {
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const hesCode = userData?.hesCode;

  const hesCodeState = userData.hesCodeState;

  const { web_title_hes_islemleri, web_desc_hes_islemleri_guncelle, web_desc_hes_guncelle } = useLocalization();

  useEffect(() => {
    dispatch(resetFormError());
    if (hesCode && hesCode !== '') {
      dispatch(decrementHesCodeState());
    } else {
      dispatch(incrementHesCodeState());
    }
  }, []);

  return (
    <DashboardLayout backButton>
      <div id="hes">
        <FormErrorWrapper
          formName={formNames.hes}
          resetFormOnRouteChange
          hideError={hesCodeState === 0}
          step={hesCodeState}
        >
          <Typography variant="h3" align="center">
            <Box fontWeight={600} marginBottom={gutters.small}>
              {web_title_hes_islemleri}
            </Box>
          </Typography>
          <Typography variant="body1" align="center" component="span">
            <Box fontSize={fontSizes.regular} className="hes__title">
              {hesCodeState === 0 ? web_desc_hes_islemleri_guncelle : web_desc_hes_guncelle}
            </Box>
          </Typography>
          {hesCodeState === 0 ? <RegisteredHes hesCode={hesCode || ''} /> : <UnregisteredHes hesCodeProp={hesCode} />}
        </FormErrorWrapper>
      </div>
    </DashboardLayout>
  );
});
export default Hes;
