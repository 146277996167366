import { FC, Fragment, useEffect, useState } from 'react';
import { CopyIcon, DashboardLayout, SuccessIconLarge, TooltipCustomCenter } from 'Components';
import { useCardType, useLocalization, useModal } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { routePath } from 'Navigator/routes';
import './BalanceTransferSuccess.scss';
import { CardTransactionsState, setInActiveCard, storeCardTransactions } from 'Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { TransactionDetail } from './TransactionDetail';
import { balanceTransferStore } from 'Stores/BalanceTransfer';
import { cardItemDetailType } from 'Views/CardDetails/constant';

type BalanceTransferSuccessProps = RouteComponentProps<{}, StaticContext>;

const BalanceTransferSuccess: FC<BalanceTransferSuccessProps> = () => {
  const {
    digital_card_to_physical_card_money_transfer_header,
    digital_card_to_physical_card_money_transfer_blue_text,
    digital_card_to_physical_card_money_transfer_desc,
    digital_card_to_physical_card_money_transfer_card_detail_button,
    digital_card_to_physical_card_money_transfer_see_transaction_detail_button,
  } = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isVisible, show, hide } = useModal();

  const balanceTransferData = useSelector(balanceTransferStore);
  const activeSelectedCard = balanceTransferData.selectedReceiverCard;
  const { cardPath } = useCardType(activeSelectedCard?.ProductCode);

  return (
    <DashboardLayout>
      <Fragment>
        <div id="balanceTransferSuccess">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{digital_card_to_physical_card_money_transfer_header}</Box>
          </Typography>
          <div className="balanceTransferSuccess__card-area">
            <img className="balanceTransferSuccess__card-area__img" src={cardPath} />
            <SuccessIconLarge className="balanceTransferSuccess__card-area__success" />
          </div>
          <Typography align="center">
            <Box fontSize={fontSizes.regular} mt={gutters.regular} color={colors.cardColorsBlue1}>
              {digital_card_to_physical_card_money_transfer_blue_text}
            </Box>
          </Typography>
          <Typography align="center">
            <Box fontSize={fontSizes.regular} mt={gutters.regular}>
              {digital_card_to_physical_card_money_transfer_desc}
            </Box>
          </Typography>
          <Box mt={gutters.big} />
          <Button
            onClick={() => {
              if (activeSelectedCard) {
                const cardDetail: cardItemDetailType = {
                  cardOwnerType: 'user',
                  ActivationStatus: activeSelectedCard.ActivationStatus,
                  AliasNumber: activeSelectedCard.AliasNumber,
                  CardBalance: activeSelectedCard.CardBalance,
                  CardId: activeSelectedCard.CardId,
                  CardStatusCode: activeSelectedCard.CardStatusCode,
                  IsPrimary: activeSelectedCard.IsPrimary,
                  MifareId: activeSelectedCard.MifareId,
                  NickName: activeSelectedCard.NickName || '',
                  ProductCode: activeSelectedCard.ProductCode,
                  ProductCodeDesc: activeSelectedCard.ProductCodeDesc,
                  VisaExpireDate: activeSelectedCard.VisaExpireDate,
                  SeasonTicketLimit: activeSelectedCard.SeasonTicketLimit,
                  SeasonTicketExpireDate: activeSelectedCard.SeasonTicketExpireDate,
                  SeasonTicketDay: activeSelectedCard.SeasonTicketDay,
                  SeasonTicketLoadable: activeSelectedCard.SeasonTicketLoadable,
                };
                dispatch(setInActiveCard(cardDetail));
                history.push(routePath.cardDetails);
              }
            }}
            style={{ width: 280, marginBottom: '10px' }}
            variant="contained"
            color="secondary"
          >
            {digital_card_to_physical_card_money_transfer_card_detail_button}
          </Button>
          <Button onClick={show} style={{ width: 280 }} variant="outlined" color="secondary" fullWidth>
            {digital_card_to_physical_card_money_transfer_see_transaction_detail_button}
          </Button>
        </div>
        <Box>
          <TransactionDetail isVisible={isVisible} hide={hide} />
        </Box>
      </Fragment>
    </DashboardLayout>
  );
};
export default BalanceTransferSuccess;
