import { localStorageKeys } from 'Config';
import moment from 'moment';

export type months = {
  tr: string[];
  en: string[];
};

const months: months = {
  tr: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const getCurrentMonth = (date: string) => {
  const currentMonth: number = moment(date).locale('tr').month();
  const currentLang: string = localStorageKeys.i18nextLng;
  if (currentLang === 'en') {
    return months['en'][currentMonth];
  } else {
    return months['tr'][currentMonth];
  }
};
