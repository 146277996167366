import { Box, Typography } from '@material-ui/core';
import { Tooltip } from 'Components';
import { useLocalization } from 'Hooks';
import { FC } from 'react';
import { gutters } from 'Theme/Variables';

import './UserCardInfoItem.scss';

type UserCardInfoItemProps = {
  title?: string;
  value?: string | null;
  children?: JSX.Element;
  func?: () => void;
  pointer?: boolean;
  visible?: boolean;
  tooltip?: boolean;
  itemID: string;
};

const UserCardInfoItem: FC<UserCardInfoItemProps> = ({
  title,
  value,
  children,
  func,
  pointer,
  visible,
  tooltip,
  itemID,
}) => {
  const {
    web_card_settings_favourite_title,
    web_card_settings_favourite_desc1,
    web_card_settings_favourite_desc2,
  } = useLocalization();
  if (!visible) {
    return null;
  }
  return (
    <div style={{ cursor: pointer ? 'pointer' : 'auto' }} onClick={func} id="user-card-info-item" itemID={itemID}>
      <Box textOverflow="ellipsis" overflow="hidden" flex={1} flexDirection="column" display="flex">
        <Typography variant="subtitle2">
          <Box>{title}</Box>
        </Typography>

        <Typography variant="h3">
          <Box
            display="flex"
            position="relative"
            flexDirection="row"
            alignItems="center"
            fontWeight={600}
            maxWidth="80%"
          >
            {value}
            {tooltip && (
              <Tooltip
                ml={gutters.small}
                iconType="?"
                id="user-card-info-item__tooltip"
                title={web_card_settings_favourite_title}
                desc1={web_card_settings_favourite_desc1}
                desc2={web_card_settings_favourite_desc2}
              />
            )}
          </Box>
        </Typography>
      </Box>
      <Box width="auto" className="rightttttt" display="flex" alignItems="center">
        {children}
      </Box>
    </div>
  );
};

export default UserCardInfoItem;
