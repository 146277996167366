import * as yup from 'yup';
import { errorMessages } from 'Config';

export const UpdateMailAdressSchema = yup.object({
  email: yup.string().required(errorMessages.require).email(errorMessages.mailFormat),
});

export type UpdateMailAdressSchemaInput = yup.InferType<typeof UpdateMailAdressSchema>;

export const UpdateMailAdressSchemaInputInitialValues = (mail: string | undefined) => {
  return { email: mail ? mail : '' };
};
