import * as yup from 'yup';
import { errorMessages } from 'Config';

export const UpdateCommercialStatusSchema = (viaMail?: boolean, mail?: string) => {
  return yup.object({
    mail:
      viaMail && !mail
        ? yup.string().email(errorMessages.mailFormat).required(errorMessages.require)
        : yup.string().email(errorMessages.mailFormat),
  });
};

export type WelcomeSchemaInputs = signUpInitialValues;

export const welcomeSchemaInitialValues: WelcomeSchemaInputs = {
  mail: '',
};

export type signUpInitialValues = {
  mail: string;
};
