import * as React from 'react';

function SuccessIconLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="144"
      height="144"
      viewBox="0 0 144 144"
      {...props}
    >
      <defs>
        <path
          id="uekbiidrpa"
          d="M72 12c-33.137 0-60 26.863-60 60s26.863 60 60 60 60-26.863 60-60c0-15.913-6.321-31.174-17.574-42.426C103.174 18.32 87.913 12 72 12zm25.8 45.66l-27.42 36c-1.133 1.471-2.883 2.336-4.74 2.34-1.847.01-3.595-.831-4.74-2.28L46.26 75.06c-1.318-1.693-1.633-3.962-.826-5.95.808-1.988 2.615-3.395 4.74-3.69 2.126-.295 4.248.567 5.566 2.26l9.78 12.48 22.68-30c1.308-1.715 3.43-2.602 5.57-2.327 2.139.275 3.968 1.67 4.8 3.66.831 1.99.538 4.272-.77 5.987v.18z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-648 -200) translate(648 200)">
            <path d="M0 0H144V144H0z" />
            <mask id="kk2x9c04kb" fill="#fff">
              <use xlinkHref="#uekbiidrpa" />
            </mask>
            <g fill="#3AC562" mask="url(#kk2x9c04kb)">
              <path d="M0 0H144V144H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SuccessIconLarge;
