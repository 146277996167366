import { axiosHelper } from 'Services/AxiosHelper';
import { GetCityListModel } from 'Models';
import { getCityListReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'b2c.GetCityList';

const getCityList = async (params: getCityListReqBodyData): Promise<GetCityListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default getCityList;
