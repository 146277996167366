import { errorMessages } from 'Config';
import * as yup from 'yup';

export const OrganizationTypeSchema = yup.object({
  organizationTypeLabel: yup.string().required(errorMessages.require),
  organizationLabel: yup.string().required(errorMessages.require),
});

export type OrganizationTypeSchemaInputs = yup.InferType<typeof OrganizationTypeSchema>;

export const OrganizationTypeSchemaInitialValues = (typeLabel?: string, organizationLabel?: string) => {
  return {
    organizationTypeLabel: typeLabel ? typeLabel : '',
    organizationLabel: organizationLabel ? organizationLabel : '',
  };
};
