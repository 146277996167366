import { FC, useCallback } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { MailIconBlue } from 'Components';

import './FAQFooter.scss';
import { useLocation } from 'react-router';
import { routePath } from 'Navigator/routes';

type FAQFooterProps = {};

const FAQFooter: FC<FAQFooterProps> = ({}) => {
  const { pathname } = useLocation();
  const { web_faq_coluld_not_answer, web_faq_write_to_us, web_desc_write_to_us } = useLocalization();
  const onSendMail = useCallback(() => {
    window.open(`mailto:${web_desc_write_to_us}`);
  }, []);
  const isWelcome = pathname !== routePath.faq;
  return (
    <div id="faqFooter">
      <Typography>
        <Box fontSize={fontSizes.regular} textAlign="center">
          {web_faq_coluld_not_answer}
        </Box>
      </Typography>
      <div className="faqFooter__btn" onClick={onSendMail} itemID="writeUsBtnTest">
        <MailIconBlue fill={colors.secondary} className="faqFooter__btn__icon" />
        <Box width="100%">
          <Typography align="center" variant="h6">
            <Box fontWeight={600} mr={4} fontSize={fontSizes.regular}>
              {web_faq_write_to_us}
            </Box>
          </Typography>
        </Box>
      </div>
      {isWelcome && <Box mb={gutters.large} />}
    </div>
  );
};
export default FAQFooter;
