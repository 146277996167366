import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetPinsBodyData, OnGetApplicationCenterListBodyData } from 'Config/ServiceConfig';
import { ApplicationCenterListModel, GetPinsModel, GetTagsModel } from 'Models';
import { getPins, getTags } from 'Services/Queries';
import onGetApplicationCenterList from 'Services/Queries/OnGetApplicationCenterList';
import { isBelbimRequestSuccess } from 'Utils';
import {
  getPinsFail,
  getPinsRequest,
  getPinsSuccess,
  getTagsRequest,
  onGetApplicationCenterListSuccess,
  onGetApplicationCenterListFail,
  onGetApplicationCenterListRequest,
} from '.';
import { getTagsFail, getTagsSuccess } from './slices';

function* handleGetTagsRequest() {
  try {
    const response: GetTagsModel = yield call(() => getTags());
    yield put(getTagsSuccess(response.data));
  } catch (error) {
    yield put(getTagsFail());
  }
}

function* handleGetPinsRequest({ payload }: PayloadAction<GetPinsBodyData>) {
  try {
    const response: GetPinsModel = yield call(() => getPins(payload));
    yield put(getPinsSuccess(response.data));
  } catch (error) {
    yield put(getPinsFail());
  }
}

function* handleOnGetApplicationCenterList({ payload }: PayloadAction<OnGetApplicationCenterListBodyData>) {
  try {
    const response: ApplicationCenterListModel = yield call(() => onGetApplicationCenterList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onGetApplicationCenterListSuccess(response.data.ApplicationCenterList));
    } else {
      yield put(onGetApplicationCenterListFail());
    }
  } catch (error) {
    yield put(onGetApplicationCenterListFail());
  }
}

function* mapsSagaWatcher() {
  yield takeLatest(getTagsRequest, handleGetTagsRequest);
  yield takeLatest(getPinsRequest, handleGetPinsRequest);
  yield takeLatest(onGetApplicationCenterListRequest, handleOnGetApplicationCenterList);
}

export default mapsSagaWatcher;
