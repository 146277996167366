import { FC } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { fontSizes } from 'Theme/Variables';
import { DarkPlusIcon } from 'Components';

import './AddNewAdress.scss';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

type AddNewAdressProps = {};
const AddNewAdress: FC<AddNewAdressProps> = ({}) => {
  const { web_enter_newadress } = useLocalization();
  const history = useHistory();
  return (
    <div id="addNewAdress" onClick={() => history.push(routePath.userAdressDetail)}>
      <DarkPlusIcon />
      <Typography variant="h3">
        <Box fontWeight="bold" fontSize={fontSizes.regular}>
          {web_enter_newadress}
        </Box>
      </Typography>
      <Box />
    </div>
  );
};
export default AddNewAdress;
