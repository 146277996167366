import { FC, Fragment, useEffect, useState } from 'react';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Box, Button, Switch, TextField, Typography } from '@material-ui/core';
import {
  DashboardLayout,
  DialogWithInput,
  FormWrapper,
  FormErrorWrapper,
  CopyIcon,
  ChevronDarkRightIcon,
  TooltipCustomCenter,
  DialogWithActions,
} from 'Components';
import { UserCardInfoItem } from './UserCardInfoItem';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDefaultCardRequest,
  changeCardNickNameRequest,
  onChangeRegisterCardNickNameRequest,
} from 'Stores/CardTransactions';
import { userStore } from 'Stores/User/slices';
import { RouteComponentProps, useHistory, StaticContext } from 'react-router';
import { closeInputDialog, openInputDialog, storeDialog } from 'Stores/Dialog';
import { formikGlobalConfig, formNames } from 'Config';
import { cardItemDetailType } from 'Views/CardDetails/constant';
import { Formik } from 'formik';
import {
  ChangeCardNickNameSchema,
  ChangeCardNickNameSchemaInput,
  ChangeCardNickNameSchemaInputInitialValues,
} from 'Validations';
import {
  changeCardNickNameNormalize,
  deleteCustomerCardByClosedNormalize,
  deleteRegisteredTransactionNormalize,
  onChangeRegisterCardNickNameNormalize,
  setDefaultCardNormalize,
} from 'Normalize';
import { routePath } from 'Navigator/routes';
import {
  onDeleteCustomerCardByClosedRequest,
  onDeleteRegisteredCardTransactionRequest,
  resetDeleteCardStep,
  setDeleteCardStep,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { DeleteSuccess } from 'Views/CardDelete/DeleteSuccess';
import { UserState } from 'Stores/User';

import './UserCardSettings.scss';
type UserCardSettingsProps = RouteComponentProps<{}, StaticContext, UserCardSettingsParams>;
type UserCardSettingsParams = {
  isOtherCard: boolean;
};

const UserCardSettings: FC<UserCardSettingsProps> = ({ location: { state } }) => {
  const {
    web_card_settings_header,
    web_card_settings_card_name,
    web_card_settings_edit,
    web_card_settings_serial_no,
    web_card_settings_make_favorite_card,
    web_card_settings_lost_stolen_card,
    web_card_settings_delete_card,
    web_card_settings_update_card_name_header,
    web_card_settings_update_card_name_btn,
    web_card_settings_copied_serial_no,
    web_card_settings_delete_card_are_you_sure_header,
    web_card_settings_delete_card_are_you_sure_desc,
    web_card_settings_delete_card_are_you_sure_ok_btn,
    web_card_settings_delete_card_are_you_sure_cancel_btn,
    web_delete_registered_card_msg,
    web_card_settings_delete_card_success_go_card_btn,
    web_settings_lost_stolen_pop_up_hdr,
    web_settings_lost_stolen_pop_up_txt,
    web_settings_lost_stolen_popup_btn1,
    web_settings_lost_stolen_popup_btn2,
    web_settings_lost_stolen_success,
    web_card_settings_delete_card_success_new_card_btn,
    web_settings_lost_stolen_pop_up_txt_blnc,
    card_colour_digital_card_activation_control,
  } = useLocalization();

  const isOtherCard = state;

  const dispatch = useDispatch();

  const history = useHistory();

  const { inputDialogOpen } = useSelector(storeDialog);

  const { cardDeleteStep, cards } = useSelector(storeCardTransactions);

  const store = useSelector(storeCardTransactions);
  const card: cardItemDetailType | any = store.activeInProcessCard ? store.activeInProcessCard : [];
  const cardList: any = store?.cards ?? [];
  const otherCards = store?.otherCards ?? [];

  const isDijitalCard = card_colour_digital_card_activation_control?.includes(card?.ProductCode);

  const userData: UserState = useSelector(userStore);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const [fav, setFav] = useState<boolean>(card?.IsPrimary);
  const [show, setShow] = useState<boolean>(false);
  const [actionOpen, setActionOpen] = useState<boolean>(false);
  const [stolenOpen, setStolenOpen] = useState<boolean>(false);

  useEffect(() => {
    const cardNoRemoved = cardList.some((item: any) => item.CardId === card?.CardId);
    const otherCardNoRemoved = otherCards ? otherCards.some((item: any) => item.CardId === card?.CardId) : false;
    if (isOtherCard && !otherCardNoRemoved) {
      history.goBack();
    } else if (!cardNoRemoved && !isOtherCard) {
      history.goBack();
    }
    dispatch(setDeleteCardStep(-1));
  }, []);

  const { getError, getErrorMessage } = useErrorMessage();

  const handleSubmit = (values: ChangeCardNickNameSchemaInput) => {
    !isOtherCard
      ? dispatch(
          changeCardNickNameRequest(changeCardNickNameNormalize(values, card.MifareId, customerNumber, sessionToken))
        )
      : dispatch(
          onChangeRegisterCardNickNameRequest(
            onChangeRegisterCardNickNameNormalize(values, card.MifareId, customerNumber, sessionToken)
          )
        );
  };
  const onSetFavorite = async () => {
    const isFav = !fav;
    setFav((prev) => !prev);
    if (isFav) {
      dispatch(setDefaultCardRequest(setDefaultCardNormalize(card.MifareId, customerNumber, sessionToken)));
    } else {
      dispatch(setDefaultCardRequest(setDefaultCardNormalize('', customerNumber, sessionToken)));
    }
  };

  const onDeleteCard = () => {
    if (card.ActivationStatus && card.CardStatusCode === 'A') {
      dispatch(resetDeleteCardStep());
      history.push(routePath.cardDelete, card);
    } else {
      setActionOpen(true);
    }
  };

  const onDeleteTransaction = () => {
    if (card.CardStatusCode === 'A') {
      dispatch(
        onDeleteCustomerCardByClosedRequest(
          deleteCustomerCardByClosedNormalize(card.MifareId, 0, customerNumber, sessionToken)
        )
      );
    } else {
      dispatch(
        onDeleteRegisteredCardTransactionRequest(
          deleteRegisteredTransactionNormalize(card.MifareId, customerNumber, sessionToken)
        )
      );
    }
    setActionOpen(false);
  };

  const onStolenTransaction = () => {
    dispatch(
      onDeleteCustomerCardByClosedRequest(
        deleteCustomerCardByClosedNormalize(card.MifareId, 2, customerNumber, sessionToken)
      )
    );
    setStolenOpen(false);
  };

  const data = [
    {
      title: web_card_settings_card_name,
      value: card?.NickName,
      children: <EditText func={() => dispatch(openInputDialog())} text={web_card_settings_edit} />,
      visible: true,
      itemID: 'cardNameBtnTest',
    },
    {
      title: web_card_settings_serial_no,
      value: card?.MifareId,
      children: <ClipBoard setShow={setShow} mifareId={card?.MifareId} />,
      visible: true,
      itemID: 'serialNumberBtnTest',
    },
    {
      value: web_card_settings_make_favorite_card,
      children: <SwitchComp func={onSetFavorite} checked={fav} />,
      tooltip: true,
      visible: !isOtherCard,
      itemID: 'makeFavoriteBtnTest',
    },
    {
      value: web_card_settings_lost_stolen_card,
      children: <Chevron />,
      func: () => setStolenOpen(true),
      pointer: true,
      visible: !isOtherCard && !isDijitalCard,
      itemID: 'stolenCardBtnTest',
    },
    {
      value: web_card_settings_delete_card,
      children: <Chevron />,
      func: onDeleteCard,
      pointer: true,
      visible: !isDijitalCard,
      itemID: 'deleteCardBtnTest',
    },
  ];

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="card-settings">
          {cardDeleteStep === -1 && (
            <Typography variant="h3" align="center">
              <Box fontWeight={600}>{web_card_settings_header}</Box>
            </Typography>
          )}

          {cardDeleteStep === -1 && (
            <Box display="flex" justifyContent="center" flexDirection="column" marginBottom={12} marginTop={5}>
              {show && (
                <TooltipCustomCenter
                  show={show}
                  title={web_card_settings_copied_serial_no}
                  onClose={() => setShow(false)}
                />
              )}

              <FormErrorWrapper formName={formNames.cardDelete} resetFormOnRouteChange>
                {data.map((e) => (
                  <UserCardInfoItem key={e.value} {...e} />
                ))}
              </FormErrorWrapper>
            </Box>
          )}
          {cardDeleteStep === 0 && (
            <DeleteSuccess
              title={isOtherCard ? web_delete_registered_card_msg : web_settings_lost_stolen_success}
              primaryButton={{
                label: web_card_settings_delete_card_success_go_card_btn,
                handleClick: () => history.push(routePath.cards),
              }}
              secondaryButton={{
                label: web_card_settings_delete_card_success_new_card_btn,
                handleClick: () => history.push(routePath.cardApplication),
              }}
            />
          )}
          {cardDeleteStep === 2 && (
            <DeleteSuccess
              title={web_settings_lost_stolen_success}
              primaryButton={{
                label: web_card_settings_delete_card_success_go_card_btn,
                handleClick: () => history.push(routePath.cards),
              }}
              secondaryButton={{
                label: web_card_settings_delete_card_success_new_card_btn,
                handleClick: () => history.push(routePath.cards),
              }}
            />
          )}
          <DialogWithInput
            open={inputDialogOpen}
            title={web_card_settings_update_card_name_header}
            hide={() => dispatch(closeInputDialog())}
            closeIcon
          >
            <FormErrorWrapper resetFormOnRouteChange formName={formNames.cardUpdateNickname}>
              <Formik
                initialValues={ChangeCardNickNameSchemaInputInitialValues(card?.NickName)}
                validationSchema={ChangeCardNickNameSchema}
                onSubmit={handleSubmit}
                {...formikGlobalConfig}
              >
                {(formikProps) => {
                  const {
                    handleChange,
                    handleBlur,
                    values: { cardNickName },
                    errors: { cardNickName: cardNickNameError },
                    touched: { cardNickName: touchedNickName },
                  } = formikProps;

                  return (
                    <FormWrapper onSubmit={formikProps.handleSubmit}>
                      <Box>
                        <TextField
                          className="card-settings__input"
                          variant="filled"
                          fullWidth
                          name="cardNickName"
                          value={cardNickName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={web_card_settings_card_name}
                          error={getError(cardNickNameError, touchedNickName && cardNickName.length === 0)}
                          helperText={getErrorMessage(cardNickNameError, touchedNickName && cardNickName.length === 0)}
                          inputProps={{ maxLength: 20 }}
                        />
                        <Button variant="contained" color="secondary" fullWidth type="submit" itemID="updateBtnTest">
                          {web_card_settings_update_card_name_btn}
                        </Button>
                      </Box>
                    </FormWrapper>
                  );
                }}
              </Formik>
            </FormErrorWrapper>
          </DialogWithInput>
          <DialogWithActions
            open={actionOpen}
            hide={() => setActionOpen(false)}
            closeIcon
            alignTitle="left"
            title={web_card_settings_delete_card_are_you_sure_header}
            text={web_card_settings_delete_card_are_you_sure_desc}
            primaryButton={{
              label: web_card_settings_delete_card_are_you_sure_ok_btn,
              handleClick: onDeleteTransaction,
            }}
            secondaryButton={{
              label: web_card_settings_delete_card_are_you_sure_cancel_btn,
              handleClick: () => setActionOpen(false),
            }}
          />
          {/* Stolen */}
          <DialogWithActions
            open={stolenOpen}
            hide={() => setStolenOpen(false)}
            closeIcon
            alignTitle="left"
            title={web_settings_lost_stolen_pop_up_hdr}
            text={
              card.CardBalance > 0
                ? web_settings_lost_stolen_pop_up_txt_blnc?.replace('{0}', `${card.CardBalance}`)
                : web_settings_lost_stolen_pop_up_txt
            }
            primaryButton={{
              label: web_settings_lost_stolen_popup_btn1,
              handleClick: onStolenTransaction,
            }}
            secondaryButton={{
              label: web_settings_lost_stolen_popup_btn2,
              handleClick: () => setStolenOpen(false),
            }}
          />
        </div>
      </Fragment>
    </DashboardLayout>
  );
};

const EditText = ({ text, func }: any) => (
  <Typography color="primary" variant="body1">
    <Box onClick={func} className="card-settings__pointer" itemID="editTestBtnTest">
      {text}
    </Box>
  </Typography>
);
const ClipBoard = ({ setShow, mifareId }: any) => (
  <CopyToClipboard onCopy={() => setShow(true)} text={mifareId}>
    <CopyIcon className="card-settings__pointer" />
  </CopyToClipboard>
);
const Chevron = () => <ChevronDarkRightIcon />;
const SwitchComp = ({ checked, func }: any) => (
  <Switch onClick={func} checked={checked} color="primary" itemID="makeFavoriteSwitchBtnTest" />
);

export default UserCardSettings;
