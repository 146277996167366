import * as React from 'react';

function FoodPointIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="37"
      viewBox="0 0 31 37"
      {...props}
    >
      <defs>
        <path
          id="yrra8b387a"
          d="M9.24 0C10.558 0 12 2.252 12 4.283c0 1.625-.784 2.622-1.906 2.954l.143 9.23c.018.591-.445 1.071-1.015 1.071-.57 0-1.015-.48-1.015-1.07l.142-9.231c-1.104-.35-1.887-1.33-1.887-2.954C6.462 2.253 7.922 0 9.24 0zM.467 0C.73 0 .934.209.934.474v3.963c0 .114.094.208.206.208h.504c.112 0 .206-.094.206-.208V.493c0-.265.205-.474.467-.474.243 0 .448.209.467.455v3.963c0 .114.094.208.206.208h.504c.112 0 .206-.094.206-.208V.493c0-.265.205-.474.467-.474.243 0 .448.209.448.455V5.29c0 .796-.598 1.479-1.382 1.555l.13 9.594c.02.606-.467 1.1-1.065 1.1-.598 0-1.065-.494-1.065-1.1l.15-9.613C.617 6.75 0 6.086 0 5.27V.474C0 .209.206 0 .467 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#67DBFF"
                fillRule="nonzero"
                d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691c6.072-6.008 6.072-15.795.033-21.803z"
                transform="translate(-543.000000, -305.000000) translate(254.000000, 286.000000) translate(289.000000, 19.000000)"
              />
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-543.000000, -305.000000) translate(254.000000, 286.000000) translate(289.000000, 19.000000) translate(3.500000, 4.000000)"
                />
                <g transform="translate(-543.000000, -305.000000) translate(254.000000, 286.000000) translate(289.000000, 19.000000) translate(3.500000, 4.000000) translate(6.000000, 3.000000)">
                  <mask id="gfqirkyneb" fill="#fff">
                    <use xlinkHref="#yrra8b387a" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#yrra8b387a" />
                  <g fill="#1581BE" mask="url(#gfqirkyneb)">
                    <path d="M0 0H24V24H0z" transform="translate(-6.000000, -3.000000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FoodPointIcon;
