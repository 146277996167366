import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Dialog, DialogProps, TextField, DialogContent, DialogTitle } from '@material-ui/core';
import './DialogCountryCode.scss';
import { gutters } from 'Theme/Variables';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';
import { useSelector } from 'react-redux';
import { storeCountryCodes } from 'Stores/App/slices';
import { turkishCharacterNormalize } from 'Utils';
import { format } from './constant';

type ContryCodeData = {
  CountryCode: string;
  CountryCodeDesc: string;
};

type DialogCountryCodeProps = {
  selectedCountryCode: string;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
  hide: () => void;
} & DialogProps;

const DialogCountryCode: FunctionComponent<DialogCountryCodeProps> = ({
  setCountryCode,
  hide,
  selectedCountryCode,
  ...props
}) => {
  const { web_plc_search_country } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const dataCountries: ContryCodeData[] | undefined = useSelector(storeCountryCodes);
  const itemClickHandle = (code: string) => {
    setCountryCode(code);
    hide();
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogCountryCode" onClose={onClose} {...props} scroll="paper" keepMounted={false}>
      <DialogTitle className="dialogCountryCode-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_plc_search_country}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
          itemID="searchCountryTest"
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCountryCode-container">
          {!searchKey && (
            <Box marginBottom={gutters.big}>
              <CountryBlock
                dataCountries={dataCountries?.slice(0, 4)}
                searchKey={searchKey}
                selectedCountryCode={selectedCountryCode}
                itemClickHandle={itemClickHandle}
              />
            </Box>
          )}
          <CountryBlock
            dataCountries={
              turkishCharacterNormalize(searchKey) ? dataCountries : dataCountries?.slice(4, dataCountries.length)
            }
            searchKey={searchKey}
            selectedCountryCode={selectedCountryCode}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CountryBlock: FunctionComponent<{
  dataCountries: ContryCodeData[] | undefined;
  searchKey: string;
  selectedCountryCode: string;
  itemClickHandle: (code: string) => void;
}> = ({ dataCountries, searchKey, selectedCountryCode, itemClickHandle }) => {
  // check for special character

  return (
    <ul className="dialogCountryCode-container__list">
      {!!dataCountries &&
        dataCountries.length > 0 &&
        dataCountries
          .filter(({ CountryCode, CountryCodeDesc }) => {
            if ((searchKey.match(format) || format.test(searchKey)) && searchKey) {
              return '';
            } else {
              return (
                turkishCharacterNormalize(CountryCodeDesc).includes(turkishCharacterNormalize(searchKey)) ||
                CountryCode?.includes(searchKey)
              );
            }
          })
          .map(({ CountryCodeDesc, CountryCode }, index) => (
            <CountryItem
              key={index}
              title={CountryCodeDesc}
              code={CountryCode}
              isSelected={CountryCode === selectedCountryCode}
              onClick={() => itemClickHandle(CountryCode)}
            />
          ))}
    </ul>
  );
};

const CountryItem: FunctionComponent<{
  title: string;
  code: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, code, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseCountryTest">
      <div
        className={classNames({
          'dialogCountryCode-container__list-item': true,
          'dialogCountryCode-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">
          {title} ({code})
        </span>
        {isSelected && <BlueTickIcon />}
      </div>
      <hr />
    </li>
  );
};

export default DialogCountryCode;
