import { FC, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Dialog, Grid, IconButton } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import trLocale from 'date-fns/locale/tr';
import { useErrorMessage, useLocalization } from 'Hooks';
import { fontSizes, gutters } from 'Theme/Variables';
import { Formik } from 'formik';
import { formikGlobalConfig, inputMaskTypes, localStorageKeys } from 'Config';
import { CalendarIcon, MaskedTextField, FormWrapper, DialogCloseComponent, CloseCircleIcon } from 'Components';
import { addFilterSchema, FilterTransactionSchema } from 'Validations';

import './FilterWithDialog.scss';

import moment from 'moment';

type FilterWithDialogProps = {
  handleFilterChange: Function;
  open: boolean;
  close: any;
  min?: number;
  max?: number;
  startAtDate?: Date | null;
  endDate?: Date | null;
};

const FilterWithDialog: FC<FilterWithDialogProps> = ({
  handleFilterChange,
  open,
  close,
  min,
  max,
  startAtDate,
  endDate,
}) => {
  const formikRef: any = useRef();

  const [isOpenStartPicker, setIsOpenStartPicker] = useState<boolean>(false);
  const [isOpenEndPicker, setIsOpenEndPicker] = useState<boolean>(false);

  const {
    web_title_detail_search,
    web_date_detail_search,
    web_amount_detail_search,
    web_btn_apply_filters,
    web_plc_start_detail_search,
    web_plc_end_detail_search,
    web_plc_min_detail_search,
    web_plc_max_detail_search,
    web_max_transactions,
  } = useLocalization();

  const { getError, getErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (formikRef.current) {
      if (!!!min) {
        formikRef.current.setFieldValue('min', undefined);
      }
      if (!!!max) {
        formikRef.current.setFieldValue('max', undefined);
      }
      if (!!!endDate) {
        formikRef.current.setFieldValue('endDate', null);
      }
      if (!!!startAtDate) {
        formikRef.current.setFieldValue('startAtDate', null);
      }
    }
  }, [formikRef, open]);

  const handleSubmit = (values: typeof addFilterSchema, { resetForm, setFieldValue }: any) => {
    handleFilterChange(values);
  };

  let lang = localStorage.getItem(localStorageKeys.i18nextLng);

  return (
    <Dialog keepMounted={true} open={open} onClose={close}>
      <DialogCloseComponent closeFunction={close} />
      <div className="dialogContainer">
        <Box textAlign="center">
          <Typography variant="h5">
            <Box fontWeight={600} marginTop={-2} marginBottom={gutters.large}>
              {web_title_detail_search}
            </Box>
          </Typography>
        </Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === 'tr' ? trLocale : undefined}>
          <Formik
            innerRef={formikRef}
            initialValues={addFilterSchema}
            validationSchema={FilterTransactionSchema}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,

                values: { min, max, endDate, startAtDate },
                errors: { min: minError, max: maxError, startAtDate: errorStartAtDate, endDate: errorEndDate },
                touched: { min: touchedMin, max: touchedMax, startAtDate: touchedStartAtDate, endDate: touchedEndDate },
              } = formikProps;

              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  <Typography variant="h3">
                    <Box mb={gutters.small} fontSize={fontSizes.small} fontWeight={600}>
                      {web_date_detail_search}
                    </Box>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <KeyboardDatePicker
                        id="startAtDate"
                        name="startAtDate"
                        fullWidth
                        autoOk
                        lang="tr"
                        disableToolbar
                        clearable
                        inputProps={{ style: { paddingLeft: 12 } }}
                        value={startAtDate}
                        onBlur={handleBlur}
                        error={getError(errorStartAtDate, false)}
                        helperText={getErrorMessage(errorStartAtDate, false)}
                        label={web_plc_start_detail_search}
                        style={{ overflow: 'hidden' }}
                        inputVariant="filled"
                        format="yyyy-MM-dd"
                        variant="inline"
                        itemID="chooseDateBtnTest"
                        minDate={moment().subtract(parseInt('3') * 30, 'd')}
                        maxDate={new Date()}
                        open={isOpenStartPicker}
                        onClick={() => setIsOpenStartPicker((prev) => !prev)}
                        onClose={() => setIsOpenStartPicker(false)}
                        onChange={(value) => {
                          !startAtDate && formikProps.setFieldTouched('startAtDate', true, true);
                          formikProps.setFieldValue('startAtDate', value, true);
                        }}
                        InputProps={{
                          fullWidth: true,
                          readOnly: true,
                          endAdornment: (
                            <IconButton onClick={() => formikProps.setFieldValue('startAtDate', null, true)}>
                              <CloseCircleIcon />
                            </IconButton>
                          ),
                        }}
                        keyboardIcon={startAtDate === null ? <CalendarIcon /> : <div />}
                        InputAdornmentProps={{
                          position: startAtDate !== null ? 'start' : 'end',
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <KeyboardDatePicker
                        name="endDate"
                        id="endDate"
                        inputProps={{ style: { paddingLeft: 12 } }}
                        style={{ overflow: 'hidden' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        inputVariant="filled"
                        fullWidth
                        autoOk
                        clearable
                        //check this
                        contentEditable={false}
                        minDate={moment().subtract(parseInt(web_max_transactions) * 30, 'd')}
                        maxDate={new Date()}
                        open={isOpenEndPicker}
                        itemID="chooseDateBtnTest"
                        label={web_plc_end_detail_search}
                        value={endDate}
                        error={getError(errorEndDate, touchedEndDate)}
                        helperText={getErrorMessage(errorEndDate, touchedEndDate)}
                        onClick={() => setIsOpenEndPicker((prev) => !prev)}
                        onClose={() => setIsOpenEndPicker(false)}
                        onChange={(value) => {
                          !endDate && formikProps.setFieldTouched('endDate', true, true);
                          formikProps.setFieldValue('endDate', value, true);
                        }}
                        InputProps={{
                          fullWidth: true,
                          readOnly: true,
                          endAdornment: (
                            <IconButton onClick={() => formikProps.setFieldValue('endDate', null, true)}>
                              <CloseCircleIcon />
                            </IconButton>
                          ),
                        }}
                        keyboardIcon={endDate === null ? <CalendarIcon /> : <div />}
                        InputAdornmentProps={{
                          position: endDate !== null ? 'start' : 'end',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h3">
                    <Box mt={gutters.regular} mb={gutters.small} fontSize={fontSizes.small} fontWeight={600}>
                      {web_amount_detail_search}
                    </Box>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <MaskedTextField
                        mask={inputMaskTypes.plc}
                        name="min"
                        error={getError(minError, false)}
                        helperText={getErrorMessage(minError, false)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={min}
                        fullWidth
                        label={web_plc_min_detail_search}
                        variant="filled"
                        itemID="minAmountBtnTest"
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <MaskedTextField
                        mask={inputMaskTypes.plc}
                        name="max"
                        error={getError(maxError, touchedMax)}
                        helperText={getErrorMessage(maxError, touchedMax)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={max}
                        fullWidth
                        label={web_plc_max_detail_search}
                        variant="filled"
                        itemID="maxAmountBtnTest"
                      />
                    </Grid>
                  </Grid>
                  <Box mt={gutters.regular} />
                  <Button type="submit" variant="contained" fullWidth color="secondary" itemID="applyFilterBtnTest">
                    {web_btn_apply_filters}
                  </Button>
                </FormWrapper>
              );
            }}
          </Formik>
        </MuiPickersUtilsProvider>
      </div>
    </Dialog>
  );
};
export default FilterWithDialog;
