import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={226}
      height={36}
      viewBox="0 0 226 36"
      xmlns="http://www.w3.org/2000/svg"
      className="istanbulKartLogo"
      {...props}
    >
      <title>{'FF69EF88-78E7-4989-B19A-088730AFD72C'}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M52.73 34.695v-6.081h-3.04c-2.01 0-3.216-1.005-3.216-2.714V15.8h6.257V9.768h-6.257V.118h-6.885v9.65H34.06v6.03h5.528v10.504c0 5.378 3.116 8.393 8.544 8.393h4.598zm75.955.603c6.835 0 11.358-5.226 11.358-13.067 0-7.84-4.523-13.066-11.358-13.066-3.619 0-6.584 1.809-7.388 4.422h-.15V.118h-6.886v34.577h5.98l.905-3.87h.151c.804 2.664 3.77 4.473 7.388 4.473zm55.482-.603v-6.483h-3.67c-1.507 0-2.412-.854-2.412-2.312V.12H171.2v26.183c0 5.378 3.116 8.393 8.544 8.393h4.423zM8.059 6.54V.119H1.1v6.42h6.96zm58.95 28.76c3.619 0 6.584-1.81 7.438-4.473h.151v.05l.905 3.82h5.98V9.767h-5.98l-.905 3.82h-.15c-.855-2.614-3.82-4.423-7.439-4.423-6.834 0-11.358 5.227-11.358 13.067s4.524 13.066 11.358 13.066zm-45.11 0c6.332 0 10.604-3.318 10.604-8.243 0-3.668-2.513-6.131-7.237-7.036l-4.423-.904c-1.708-.302-2.463-1.056-2.463-2.161 0-1.458 1.308-2.413 3.368-2.413 2.211 0 3.87 1.206 4.221 2.966l5.78-1.76c-1.156-3.92-5.076-6.583-9.85-6.583-6.232.05-10.454 3.267-10.454 8.041 0 3.87 2.714 6.382 7.79 7.438l4.422.854c1.408.301 1.91.905 1.91 1.91 0 1.508-1.507 2.513-3.82 2.513-2.864 0-4.924-1.357-5.075-3.418l-5.88.905c.703 4.724 5.176 7.89 11.106 7.89zm71.381-.604V21.277c0-3.367 2.01-5.68 5.076-5.68 2.815 0 4.775 2.162 4.775 5.378v13.72h6.884V18.613c0-5.68-3.568-9.448-8.995-9.448-3.72 0-6.735 1.81-7.589 4.523h-.15l-.906-3.92h-5.98v24.927h6.885zm59.2.603c3.669 0 6.685-1.81 7.539-4.473h.2l.906 3.87h5.98V9.768h-6.885v13.418c0 3.418-2.01 5.68-5.127 5.68-2.864 0-4.774-2.162-4.774-5.378V9.768h-6.885V25.85c0 5.679 3.619 9.448 9.046 9.448zM8.021 34.695V9.768H1.137v24.927H8.02zm60.597-5.679c-3.569 0-5.93-2.714-5.93-6.784 0-4.071 2.361-6.785 5.93-6.785 3.618 0 5.98 2.714 5.98 6.785 0 4.07-2.362 6.784-5.98 6.784zm58.459 0c-3.569 0-5.93-2.714-5.93-6.785 0-4.07 2.361-6.784 5.93-6.784 3.568 0 5.93 2.714 5.93 6.784 0 4.071-2.362 6.785-5.93 6.785z"
          fill="#1581be"
        />
        <path
          d="M191.134 9.246c1.89-4 5.127-7.238 9.128-9.128h-9.128v9.128zm34.536 0V.118h-9.127a19.16 19.16 0 019.127 9.128zm-17.268 23.41s.13-.082.363-.237l.195-.13c2.704-1.82 14.332-10.095 14.332-17.751 0-8.664-8.96-11.596-14.89-5.567-6.753-5.93-14.89-3.064-14.89 5.567 0 7.627 11.629 15.923 14.333 17.75l.195.13c.234.156.362.238.362.238zm17.268 1.998v-9.127a19.16 19.16 0 01-9.127 9.127h9.127zm-25.408 0a19.16 19.16 0 01-9.128-9.127v9.127h9.128z"
          fill="#55D3FF"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
