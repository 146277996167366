import { Dialog } from '@material-ui/core';
import { DialogCloseComponent, Loading } from 'Components';
import { fibaBankUrl } from 'Config';
import { useInterval, useLocalization } from 'Hooks';
import { FC, LegacyRef, useState } from 'react';
import { useEffect } from 'react';
import { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPaymentStatusRequest,
  close3DSecureModal,
  creditCardTransactionsStore,
} from 'Stores/CreditCardTransactions';
import { checkOnmicroCreditStatus, microCreditStore } from 'Stores/MicroCredit';
import { showToastify } from 'Stores/Toastify';
import { userStore } from 'Stores/User';

import './Modal3DSecure.scss';

type Modal3DSecureProps = {
  link: string;
  open: boolean;
  paymentNo?: string;
  close: () => void;
  isFibabank?: boolean;
};

const Modal3DSecure: FC<Modal3DSecureProps> = ({ link, open, close, isFibabank }) => {
  const { msg_timeout } = useLocalization();

  const dispatch = useDispatch();
  const userData = useSelector(userStore);
  const microCreditData = useSelector(microCreditStore);
  const creditStatus = microCreditData?.creditStatus;
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const orderId = microCreditData?.orderId;
  const orderStatus = microCreditData?.orderStatus;

  const [checkOverTime, setCheckOverTime] = useState<boolean>(true);

  const { paymentStatus, activePayment } = useSelector(creditCardTransactionsStore);

  const iframeRef: LegacyRef<HTMLIFrameElement> | undefined = createRef();

  const isLinkIncludeHtml = link?.includes('<html');

  const [loading, setLoading] = useState(true);

  // Limit to request for 3 minutes

  const [remainingSeconds, setRemainingSeconds] = useState<number>(isFibabank ? 1200 : 180);

  useInterval(() => {
    if (remainingSeconds > 0) {
      setRemainingSeconds(remainingSeconds - 1);
    }
  }, 1000);

  useEffect(() => {
    if (remainingSeconds === 0) {
      setCheckOverTime(false);
      dispatch(close3DSecureModal());
      dispatch(showToastify({ toastMessage: msg_timeout, type: 'error' }));
      setRemainingSeconds(isFibabank ? 1200 : 180);
    } // eslint-disable-next-line
  }, [remainingSeconds]);

  useEffect(() => {
    if (checkOverTime) {
      setTimeout(() => {
        activePayment &&
          dispatch(
            checkPaymentStatusRequest({
              CustomerNumber: customerNumber,
              SessionToken: sessionToken,
              PaymentNumber: activePayment,
            })
          );
      }, 5000);
    }
  }, [paymentStatus, activePayment]);

  useEffect(() => {
    if (checkOverTime) {
      setTimeout(() => {
        orderId &&
          fibaBankUrl &&
          dispatch(
            checkOnmicroCreditStatus({
              CustomerNumber: customerNumber,
              SessionToken: sessionToken,
              OrderId: orderId,
            })
          );
      }, 5000);
    }
  }, [orderStatus, orderId]);

  const onLoadFrame = (obj: any) => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <Dialog open={open} onClose={close} keepMounted={false} id="modal3DSecure" disableBackdropClick>
      <div style={{ paddingBottom: 0 }} className="dialogContainer">
        <DialogCloseComponent closeFunction={close} />
        {loading && <Loading />}
        <iframe
          id="frame"
          ref={iframeRef}
          onLoad={onLoadFrame}
          srcDoc={isLinkIncludeHtml ? link : undefined}
          src={!isLinkIncludeHtml ? link : undefined}
          sandbox="allow-forms allow-scripts allow-same-origin allow-presentation"
        />
      </div>
    </Dialog>
  );
};

export default Modal3DSecure;
