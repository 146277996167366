import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { generateLanguageParam, MasterPassTokenEnum } from 'Utils';
import { FormErrorWrapper, MasterPassIcon } from 'Components';
import { mobileMasterpassConnectNormalize } from 'Normalize';
import { onInıtDataRequest, setLanguage } from 'Stores/App';
import { Typography, Box, Button } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { useLocalization, useQuery } from 'Hooks';
import { FC, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formNames } from 'Config';

import i18n from 'i18n';

import './MobileMasterpassConnect.scss';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
type MasterPassConnectType = {};

const MasterpassConnect: FC<MasterPassConnectType> = memo(() => {
  const dispatch = useDispatch();
  const query = useQuery();

  const { push } = useHistory();

  const msisdn = query.get('msisdn');
  const lang = query.get('lang');
  const token = query.get('token');
  const cmsLang = query.get('cmsLang');
  const referenceNo = query.get('referenceNo');

  const {
    web_title_add_registered_credit_card,
    web_desc_add_registered_credit_card,
    web_btn_add_registered_credit_card,
  } = useLocalization();

  useEffect(() => {
    const isUserDataDismiss = !msisdn || !lang || !token || !cmsLang || !referenceNo;

    if (isUserDataDismiss) {
      push(routePath.welcome);
    }

    if (cmsLang) {
      i18n.changeLanguage(cmsLang || '');
      dispatch(setLanguage());
      dispatch(onInıtDataRequest(generateLanguageParam()));
    }
  }, []);

  const connectUserToClient = () => {
    dispatch(
      onGetMasterpassForMobileRequest({
        TokenType: MasterPassTokenEnum.LINK,
        request: {
          data: mobileMasterpassConnectNormalize({
            sendSmsLanguage: lang,
            referenceNo,
            token,
            msisdn,
          }),
        },
      })
    );
  };

  return (
    <div id="mobileMasterpassConnect">
      <FormErrorWrapper formName={formNames.masterpassConnect} resetFormOnRouteChange>
        <Box textAlign="center" fontWeight={600}>
          <MasterPassIcon height="25px" width="150px" />
        </Box>
        <Typography variant="h3">
          <Box marginTop={gutters.big} textAlign="center" fontWeight={600}>
            {web_title_add_registered_credit_card}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box fontSize={fontSizes.regular} marginTop={gutters.small} textAlign="center">
            {web_desc_add_registered_credit_card}
          </Box>
        </Typography>
        <Box onClick={connectUserToClient} marginTop={gutters.large}>
          <Button variant="contained" color="secondary" fullWidth type="submit" itemID="masterpassConnectBtnTest">
            {web_btn_add_registered_credit_card}
          </Button>
        </Box>
      </FormErrorWrapper>
    </div>
  );
});

export default MasterpassConnect;
