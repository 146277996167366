import { FC, memo } from 'react';
import { Grid, Box } from '@material-ui/core';
import { ChevronDarkRightIcon, WaitingCardRedIcon, WaitingCardRedShortIcon } from 'Components';
import { colors, fontSizes } from 'Theme/Variables';
import { useCardType, useLocalization } from 'Hooks';
import { cardItemParams } from './constant';
import classNames from 'classnames';
import { cardBalanceNormalize, splitMifareIdAndAliasNumber } from 'Utils';
import { useSelector } from 'react-redux';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { localStorageKeys } from 'Config';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './CardItem.scss';

type CardItemProps = {
  cardItem: cardItemParams;
  isUserOtherCard?: boolean;
  onClick?: () => void;
  itemID: string;
};

type waitingInstructionsProps = {
  MifareId: string;
  TaskCount: number;
  InstructionCount: number;
};

const CardItem: FC<CardItemProps> = memo(({ cardItem, isUserOtherCard, onClick }) => {
  const { web_btn_waiting_card, web_btn_waiting_instruction } = useLocalization();

  const cardIsActive = cardItem?.CardStatusCode === 'A' ? true : false;

  const { cardPath, cardColor } = useCardType(cardItem?.ProductCode);

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const waitingInstructions: waitingInstructionsProps[] | undefined = cardData?.cardInstructionList;

  const cardWaitingInstructionsCount = waitingInstructions?.find((item) => item.MifareId === cardItem.MifareId);

  const lang = localStorage.getItem(localStorageKeys.i18nextLng);

  return (
    <Grid item lg={4} sm={6} xs={12} onClick={onClick}>
      <div id="cardItem">
        <div
          className={classNames(`card-item__${cardColor}`, {
            'card-item__container': true,
            'card-item__not--active': !cardIsActive && !isUserOtherCard,
          })}
        >
          <Box display="flex">
            <img src={cardPath} alt="" />
          </Box>
          <Box overflow="hidden" marginLeft={fontSizes.big} flex={1}>
            {cardItem?.NickName && (
              <Box fontSize={fontSizes.small} color={colors.secondary} style={{ marginBottom: '3px' }}>
                {cardItem?.NickName}
              </Box>
            )}
            {!cardItem?.NickName && cardItem?.AliasNumber && (
              <Box fontSize={fontSizes.small} color={colors.secondary} style={{ marginBottom: '3px' }}>
                {splitMifareIdAndAliasNumber(cardItem?.AliasNumber)}
              </Box>
            )}
            {/*   {!isUserOtherCard && cardItem?.SeasonTicketLimit && cardItem?.SeasonTicketExpireDate && (
              <Box style={{ marginBottom: '3px' }} color={colors.checkboxLabel} fontSize={fontSizes.small}>
                <Box fontSize={fontSizes.small} style={{ marginBottom: '3px' }} component="span">
                  {cardItem?.SeasonTicketLimit} /
                </Box>
                <Box fontSize={fontSizes.small} style={{ marginBottom: '3px' }} component="span">
                  {' '}
                  {cardItem?.SeasonTicketExpireDate}
                </Box>
              </Box>
            )} */}
            {!isUserOtherCard && cardItem?.CardBalance.toString() && (
              <Box fontSize={fontSizes.regularBig} color={colors.secondary} fontWeight="bold">
                {cardBalanceNormalize(cardItem?.CardBalance)} TL
              </Box>
            )}
          </Box>
          <Box>
            <ChevronDarkRightIcon />
          </Box>
        </div>
        {!isUserOtherCard && !cardIsActive && (
          <div className="card-item__waiting-card">
            <div className="card-item__waiting-card-wrapper">
              <WaitingCardRedShortIcon />
              <Box
                fontSize={fontSizes.tiny}
                color="white"
                className="card-item__waiting-card-desc-passive"
                style={lang === 'en' ? { top: '2px' } : { top: '6px' }}
              >
                {web_btn_waiting_card}
              </Box>
            </div>
          </div>
        )}
        {cardWaitingInstructionsCount && cardIsActive && (
          <div className="card-item__waiting-card">
            <div className="card-item__waiting-card-wrapper">
              <WaitingCardRedIcon />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="card-item__waiting-card-desc"
                style={{ left: '2px' }}
              >
                <Box fontSize={fontSizes.tiny} className="card-item__waiting-card-desc__number">
                  {cardWaitingInstructionsCount?.InstructionCount + cardWaitingInstructionsCount?.TaskCount}
                </Box>
                <Box fontSize={fontSizes.tiny} color="white">
                  {web_btn_waiting_instruction}
                </Box>
              </Box>
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
});

export default CardItem;
