import { get } from 'lodash';
import { GetCmsModel } from 'Models';
import { useSelector } from 'react-redux';
import { storeCmsData } from 'Stores/App';

const useLocalization = () => {
  const cmsData = useSelector(storeCmsData);

  return get(cmsData, 'CmsKeys') as GetCmsModel['data']['CmsKeys'];
};

export default useLocalization;
