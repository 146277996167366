import { GetPinsBodyData } from 'Config/ServiceConfig';

export const getPinsNormalize = (values: GetPinsBodyData) => {
  const formValues: GetPinsBodyData = {
    Distance: values.Distance,
    LanguageId: values.LanguageId,
    Latitude: values.Latitude,
    Longitude: values.Longitude,
    SubCategoryId: values.SubCategoryId,
    TagId: values.TagId,
    TownId: values.TownId,
  };
  return formValues;
};
