import { FC, memo, useState } from 'react';
import { CopyIcon, DashboardLayout, LocationIcon, TickIcon } from 'Components';
import { useLocalization, useModal } from 'Hooks';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { CampaignConditions } from './CampaignConditions';
import { useDispatch, useSelector } from 'react-redux';
import { showToastify } from 'Stores/Toastify';
import { Tooltip } from 'Views/CardDetails/CardDetailsHeader/Tooltip';
import { AccountState, CampaignItem, storeAccount } from 'Stores/Account';
import { CampaignStepsFollow } from './CampaignStepsFollow';
import { CampaignMap } from './CampaignMap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import Linkify from 'react-linkify';
import moment from 'moment';

import 'react-lazy-load-image-component/src/effects/opacity.css';

import './Campaigns.scss';

type CampaignsProps = {};

const Campaigns: FC<CampaignsProps> = memo(() => {
  const {
    web_campaign_join,
    web_campaign_campaign_conditions,
    web_campaign_participated_success,
    web_campaign_joined,
    web_campaign_code_created_success,
    web_campaign_code_ready,
    web_campaign_copy,
    web_campaign_get_campaign_code,
    web_capaign_code_copied,
    web_campaign_show_on_map,
    web_campaign_date,
    web_campaign_period_of_use,
    web_campaign_last,
    web_campaign_days,
    web_campaign_day,
    web_campaign_date_end,
  } = useLocalization();
  const { isVisible, show, hide } = useModal();

  const { isVisible: isVisibleMap, show: showMap, hide: hideMap } = useModal();

  const accountState: AccountState = useSelector(storeAccount);
  const userActiveCampaign: CampaignItem | undefined = accountState.activeCampaign;

  const campaignRemainingDay = userActiveCampaign?.RemainingDayCount;

  const [showCopy, setShowCopy] = useState(false);
  const [join, setJoin] = useState<boolean>(false);
  const [takeCode, setTakeCode] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleJoinCampaign = () => {
    dispatch(
      showToastify({
        toastMessage: web_campaign_participated_success,
        type: 'success',
      })
    );
    setJoin(true);
  };
  const handleTakeCampaignCode = () => {
    dispatch(
      showToastify({
        toastMessage: web_campaign_code_created_success,
        type: 'success',
      })
    );
    setTakeCode(true);
  };

  const decideCampaignDateText = () => {
    if (campaignRemainingDay && campaignRemainingDay > 0 && campaignRemainingDay !== 0) {
      return `${web_campaign_last} ${campaignRemainingDay} ${web_campaign_days}`;
    }
    if (campaignRemainingDay === 0) {
      return `${web_campaign_last} ${web_campaign_day}`;
    }
    if (campaignRemainingDay && campaignRemainingDay < 0) {
      return web_campaign_date_end;
    }
  };

  const description = userActiveCampaign?.Description.split('•');

  return (
    <DashboardLayout>
      <div id="campaigns">
        {showCopy && <Tooltip title={web_capaign_code_copied} onClose={() => setShowCopy(false)} />}
        <Typography variant="h3">
          <Box className="campaigns__title" fontWeight={700}>
            {userActiveCampaign?.HeadLine}
          </Box>
        </Typography>
        <Box marginBottom={gutters.regular}>
          {userActiveCampaign?.WebDetailVideoUrl ? (
            <ReactPlayer
              className="react-player"
              url={userActiveCampaign?.WebDetailVideoUrl}
              width="100%"
              height="100%"
              controls
            />
          ) : (
            <LazyLoadImage
              src={userActiveCampaign?.WebDetailPictureUrl}
              alt=""
              className="campaigns__showcase"
              effect="opacity"
            />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={userActiveCampaign?.Conditions && userActiveCampaign?.Conditions !== '' ? 7 : 12}>
            <Typography variant="body1">
              <Box fontSize={fontSizes.regular}>
                <Linkify
                  componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
                    const isLink = decoratedText.includes('https');
                    return isLink ? (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    ) : (
                      decoratedText
                    );
                  }}
                >
                  {userActiveCampaign?.Description}
                </Linkify>
              </Box>
            </Typography>
          </Grid>
          {userActiveCampaign?.Conditions && userActiveCampaign?.Conditions !== '' && (
            <Grid item xs={12} sm={5}>
              <Box marginTop={gutters.small} className="campaigns__joinContainer">
                {/*  <Box marginBottom={gutters.small}>
                {(userActiveCampaign?.Type === 0 || userActiveCampaign?.Type === 2) &&
                  (join ? (
                    <Box className="campaigns__joinedCampaign">
                      <Box marginRight={fontSizes.regular}>
                        <TickIcon />
                      </Box>
                      <Box fontSize={fontSizes.regular} fontWeight="bold" color={colors.toastSuccessText}>
                        {web_campaign_joined}
                      </Box>
                    </Box>
                  ) : (
                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleJoinCampaign()}>
                      {web_campaign_join}
                    </Button>
                  ))}
                {userActiveCampaign?.Type === 1 &&
                  (takeCode ? (
                    <Box className="campaigns__takeCode">
                      <Box marginRight={fontSizes.regular}>
                        <Box className="campaigns__takeCode__codeReady">{web_campaign_code_ready}</Box>
                        <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.toastSuccessText}>
                          {userActiveCampaign?.CampaignCode}
                        </Box>
                      </Box>
                      <Box color={colors.toastSuccessText}>
                        <CopyToClipboard onCopy={() => {}} text="98821313">
                          <CopyIcon onClick={() => setShowCopy(true)} />
                        </CopyToClipboard>
                        <Box className="campaigns__takeCode__copy">{web_campaign_copy}</Box>
                      </Box>
                    </Box>
                  ) : (
                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleTakeCampaignCode()}>
                      {web_campaign_get_campaign_code}
                    </Button>
                  ))}
              </Box>
                 {userActiveCampaign?.Location && (
                <Box marginBottom={gutters.small} onClick={showMap}>
                  <Button variant="outlined" color="secondary" fullWidth type="submit" style={{ display: 'block' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <LocationIcon />
                      <Box>{web_campaign_show_on_map}</Box>
                      <Box></Box>
                    </Box>
                  </Button>
                </Box>
              )} */}
                <Box>
                  <CampaignMap isVisible={isVisibleMap} hide={hideMap} />
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  type="submit"
                  onClick={() => show()}
                  itemID="campaignConditionsBtnTest"
                >
                  {web_campaign_campaign_conditions}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box>
          <CampaignConditions isVisible={isVisible} hide={hide} userActiveCampaign={userActiveCampaign} />
        </Box>
        {/*  {userActiveCampaign?.Type === 2 && (
          <Box>
            <CampaignStepsFollow />
          </Box>
        )} */}
        <Box marginTop={gutters.regular}>
          <Grid container spacing={2}>
            {(campaignRemainingDay || campaignRemainingDay === 0) && (
              <Grid item xs={12} sm={6}>
                <Box className="campaigns__dates">
                  <Box>
                    <Typography variant="body1">
                      <Box fontSize={13} marginBottom={0.5}>
                        {web_campaign_date}
                      </Box>
                    </Typography>
                    <Typography variant="h3">
                      <Box fontSize={fontSizes.regular} fontWeight="bold">
                        {moment(userActiveCampaign?.Finish).format('DD.MM.YYYY')}
                      </Box>
                    </Typography>
                  </Box>
                  <Box fontSize={fontSizes.regular} color={colors.toastSuccessText} fontWeight="bold">
                    {decideCampaignDateText()}
                  </Box>
                </Box>
              </Grid>
            )}
            {userActiveCampaign?.DiscountFinish && userActiveCampaign?.DiscountStart && (
              <Grid item xs={12} sm={6}>
                <Box className="campaigns__dates">
                  <Box>
                    <Typography variant="body1">
                      <Box fontSize={13} marginBottom={0.5}>
                        {web_campaign_period_of_use}
                      </Box>
                    </Typography>
                    <Typography variant="h3">
                      <Box fontSize={fontSizes.regular} fontWeight="bold">
                        {moment(userActiveCampaign?.DiscountStart).format('DD.MM.YYYY')} -{' '}
                        {moment(userActiveCampaign?.DiscountFinish).format('DD.MM.YYYY')}
                      </Box>
                    </Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    </DashboardLayout>
  );
});
export default Campaigns;
