import { ChangeCardNickNameSchemaInput } from 'Validations';

export const changeCardNickNameNormalize = (
  values: ChangeCardNickNameSchemaInput,
  mifareId: string,
  customerNumber?: number,
  sessionToken?: string
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: mifareId,
    CardNickName: values.cardNickName.trim(),
  };
  return formValues;
};
