import { FC, Fragment, useEffect } from 'react';
import { DashboardLayout, DialogWithActions } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardTransactionsState,
  closeAlreadyHave,
  closeCantApplyDialog,
  closeCantApplyForeign,
  closeDeletePrivilegedDialog,
  onCustomerApplicationListRequest,
  onOnlineApplicationCardRequest,
  onOnlineApplicationRequest,
  storeCardTransactions,
  closeOnOnlineApplicationDialog,
  resetRelationData,
} from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';
import { CardApplicationItem } from './CardApplicationItem';

import './CardApplication.scss';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { ApprovalStatusEnum } from 'Utils';
import { useState } from 'react';
import {
  clearApplicationData,
  resetCardApplicationStatus,
  setCampusIdRequest,
  setInstitutionTypeRequest,
} from 'Stores/CardApplication';
import { routePath } from 'Navigator/routes';

type CardApplicationProps = {};

const CardApplication: FC<CardApplicationProps> = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const [activeApplication, setActiveApplicaton] = useState<boolean>(false);

  let {
    istanbulCardTypeList,
    isOpenDeletePrivilegedDialog,
    isOpenCantApplyDialog,
    isOpenAlreadyHave,
    isOpenCantApplyForeign,
    customerCardApplicationList,
    isOpenOnlineAppcationCardDialog,
    onOnlineApplicationCardStatusCode,
  } = useSelector(storeCardTransactions);

  const {
    web_card_application_title,
    web_card_application_desc,
    web_card_avaliable_popup_title,
    web_card_avaliable_popup_desc,
    web_card_avaliable_popup_button,
    web_btn_vazgec,
    web_btn_okay,
    web_cant_apply_popup_title,
    cant_apply_popup_title,
    web_card_already_exists,
    web_cant_apply_card_foreign_people,
    web_cant_apply_this_card,
    web_active_application,
  } = useLocalization();

  const cmsData: any = useLocalization();

  useEffect(() => {
    dispatch(resetRelationData());
    dispatch(
      onOnlineApplicationCardRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      onCustomerApplicationListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        ProductCode: '',
      })
    );
    dispatch(setInstitutionTypeRequest(''));
    dispatch(setCampusIdRequest(0));
    dispatch(resetCardApplicationStatus());
    dispatch(clearApplicationData({ withoutCard: false }));
    /* yukarıdaki işlem ikinci kargo talebinden sonra eklenmiştir. eklenmesinin sebebi kullanıcı eğer ikinci kargolama
    sürecini başlatıp tekrardan yeni kart başvurusu yapmaya dönerse ücret alanında sıfır lira ödeme gözükmektedir.
    bunu engelleme için yukarıdaki işlem kullanılmıştır. */
  }, []);

  const checkAlreadyApplicationStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    const alreadyData = customerCardApplicationList?.find((item) => item.ProductCode === Product.ProductCode);

    if (alreadyData) {
      if (alreadyData?.ApprovalStatus === ApprovalStatusEnum.WAS_CREATED) {
        checkCardStatus(Product);
      } else {
        setActiveApplicaton(true);
      }
    } else {
      checkCardStatus(Product);
    }
  };

  const checkCardStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    dispatch(
      onOnlineApplicationRequest({
        request: { CustomerNumber: customerNumber, SessionToken: sessionToken, ProductCode: Product.ProductCode },
        product: Product,
      })
    );
  };

  const deletePrivilegedCardsAndCreateNew = () => {
    dispatch(clearApplicationData({ withoutCard: true }));
    dispatch(closeDeletePrivilegedDialog());
    history.push(routePath.cardApplicationSteps);
  };

  istanbulCardTypeList = istanbulCardTypeList?.filter(
    (product) => (!product.ProductCode && product.SubProductCode) || (product.ProductCode && !product.SubProductCode)
  );
  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="cardApplication">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{web_card_application_title}</Box>
          </Typography>
          <Typography variant="h3" align="center">
            <Box mb={gutters.big} color={colors.checkboxLabel} fontSize={fontSizes.regular} mt={gutters.small}>
              {web_card_application_desc}
            </Box>
          </Typography>
          {istanbulCardTypeList &&
            istanbulCardTypeList.map((item) => (
              <CardApplicationItem
                key={item.ProductName}
                checkAlreadyApplicationStatus={checkAlreadyApplicationStatus}
                item={item}
              />
            ))}
        </div>

        <DialogWithActions
          alignTitle="left"
          primaryButton={{ label: web_card_avaliable_popup_button, handleClick: deletePrivilegedCardsAndCreateNew }}
          secondaryButton={{ label: web_btn_vazgec, handleClick: () => dispatch(closeDeletePrivilegedDialog()) }}
          open={isOpenDeletePrivilegedDialog}
          hide={() => dispatch(closeDeletePrivilegedDialog())}
          title={web_card_avaliable_popup_title}
          text={web_card_avaliable_popup_desc}
        />

        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => dispatch(closeCantApplyDialog()),
          }}
          open={isOpenCantApplyDialog}
          hide={() => dispatch(closeCantApplyDialog())}
          title={web_cant_apply_popup_title}
          text={web_cant_apply_this_card}
        />

        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => dispatch(closeAlreadyHave()),
          }}
          open={isOpenAlreadyHave}
          hide={() => dispatch(closeAlreadyHave())}
          title={cant_apply_popup_title}
          text={web_card_already_exists}
        />

        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => dispatch(closeCantApplyForeign()),
          }}
          open={isOpenCantApplyForeign}
          hide={() => dispatch(closeCantApplyForeign())}
          title={cant_apply_popup_title}
          text={web_cant_apply_card_foreign_people}
        />

        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => setActiveApplicaton(false),
          }}
          open={activeApplication}
          hide={() => () => setActiveApplicaton(false)}
          title={cant_apply_popup_title}
          text={web_active_application}
        />

        <DialogWithActions
          alignTitle="left"
          primaryButton={{
            label: web_btn_okay,
            handleClick: () => dispatch(closeOnOnlineApplicationDialog()),
          }}
          open={!!isOpenOnlineAppcationCardDialog}
          hide={() => () => dispatch(closeOnOnlineApplicationDialog())}
          title={cant_apply_popup_title}
          text={cmsData[onOnlineApplicationCardStatusCode || 'Missing Key:' + 'onOnlineApplicationCardStatusCode']}
        />
      </Fragment>
    </DashboardLayout>
  );
};
export default CardApplication;
