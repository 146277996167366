import * as React from 'react';

function DarkAvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <defs>
        <path
          id="zl72sz9uxa"
          d="M12 13c3.866 0 7 3.134 7 7 0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1 0-3.866 3.134-7 7-7zm0-10c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-171 -393) translate(155 370) translate(16 22.014) translate(0 1)">
                <path d="M0 0H24V24H0z" />
                <mask id="uvq1xzgr8b" fill="#fff">
                  <use xlinkHref="#zl72sz9uxa" />
                </mask>
                <g fill="#383C52" mask="url(#uvq1xzgr8b)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DarkAvatarIcon;
