import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import packageJson from '../../../package.json';
import { fetchVersionNumber, storeApp } from 'Stores/App';

export type ICacheBusterProps = PropsWithChildren<any>;

const CacheBuster: React.FC<ICacheBusterProps> = (props) => {
  const dispatch: Dispatch = useDispatch();
  const appData = useSelector(storeApp);

  useEffect(() => {
    dispatch(fetchVersionNumber(packageJson.version));
  }, [dispatch]);

  return appData.cacheLoading ? null : props.children;
};

export default CacheBuster;
