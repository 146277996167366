import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onMicroCreditCheckStatusBodyData } from 'Config/ServiceConfig';
import { OnMicroCreditCheckStatusModel } from 'Models';
import { RootState } from 'Stores';
import { MicroCreditState } from './types';

const initialState: MicroCreditState = {
  loading: false,
  bankSecureModal: false,
  modalDirectUrl: '',
  microCreditToken: '',
  microCreditRefreshToken: '',
  creditWarningMessageModal: false,
  creditWarningMessage: ',',
  creditStatus: '',
  orderId: '',
  orderStatus: false,
  applicationStatusWarningModal: false,
  applicationStatus65Modal: false,
  creditWarning874Modal: false,
  creditWarnig861Modal: false,
  creditWarningPModal: false,
};

const microCreditactions = createSlice({
  name: 'microCreditactions',
  initialState,
  reducers: {
    getTokenForMicroCreditLoginRequest: (state: MicroCreditState) => {
      state.loading = true;
    },
    getTokenForMicroCreditLoginSuccess: (state: MicroCreditState, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.microCreditToken = payload.token;
      state.microCreditRefreshToken = payload.refreshToken;
    },
    getTokenForMicroCreditLoginFail: (state: MicroCreditState) => {
      state.loading = false;
    },
    onMicroCreditBankProcessRequest: (state: MicroCreditState) => {
      state.loading = true;
    },
    onMicroCreditBankProcessSuccess: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      state.orderId = payload;
      state.loading = false;
    },
    onMicroCreditBankProcessFail: (state: MicroCreditState) => {
      state.loading = false;
    },
    openBankSecureModal: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      state.bankSecureModal = true;
      state.modalDirectUrl = payload;
    },
    closeBankSecureModal: (state: MicroCreditState) => {
      state.bankSecureModal = false;
    },
    creditWarningMessageModalOpen: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      if (payload === '874') {
        state.creditWarning874Modal = true;
      }
      if (payload === '861') state.creditWarnig861Modal = true;
      if (payload === 'P') state.creditWarningPModal = true;
    },
    creditWarningMessageModalClose: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      if (payload === '874') {
        state.creditWarning874Modal = false;
      }
      if (payload === '861') state.creditWarnig861Modal = false;
      if (payload === 'P') state.creditWarningPModal = false;
    },
    checkOnmicroCreditStatus: (
      state: MicroCreditState,
      { payload }: PayloadAction<onMicroCreditCheckStatusBodyData>
    ) => {},
    checkPaymentStatusRequestResult: (
      state: MicroCreditState,
      { payload }: PayloadAction<OnMicroCreditCheckStatusModel>
    ) => {
      state.creditStatus = payload.data.CreditStatus;
      const orderCheck = payload.data.CreditStatus == 'P' ? true : false;
      state.orderStatus = orderCheck ? orderCheck : !state.orderStatus;
      state.creditStatus = payload.data.CreditStatus;
    },
    clearCreditStatus: (state: MicroCreditState) => {
      state.creditStatus = '';
    },
    applicationStatusWarningModalOpen: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      if (payload === '65') {
        state.applicationStatus65Modal = true;
      } else {
        state.applicationStatusWarningModal = true;
      }
    },
    applicationStatusWarningModalClose: (state: MicroCreditState, { payload }: PayloadAction<string>) => {
      if (payload === '65') {
        state.applicationStatus65Modal = false;
      } else {
        state.applicationStatusWarningModal = false;
      }
    },
  },
});

export const microCreditStore = (state: RootState) => state.microCredit;

export const {
  getTokenForMicroCreditLoginRequest,
  getTokenForMicroCreditLoginSuccess,
  getTokenForMicroCreditLoginFail,
  openBankSecureModal,
  closeBankSecureModal,
  onMicroCreditBankProcessRequest,
  onMicroCreditBankProcessSuccess,
  onMicroCreditBankProcessFail,
  creditWarningMessageModalOpen,
  creditWarningMessageModalClose,
  checkOnmicroCreditStatus,
  checkPaymentStatusRequestResult,
  clearCreditStatus,
  applicationStatusWarningModalOpen,
  applicationStatusWarningModalClose,
} = microCreditactions.actions;
export default microCreditactions.reducer;
