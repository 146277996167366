import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateInstructionFromQRAccountModel } from 'Models';
import { RootState } from 'Stores';
import { SelectedCardType } from 'Views/BalanceTransfer/BalanceTransferStep/ChooseCard/constant';
import { BalanceTransferState } from './types';

const initialState: BalanceTransferState = {
  Amount: '',
  CustomerNumber: 0,
  InsertDate: '',
  InstructionId: 0,
  InstructionStatus: 0,
  InstructionType: 0,
  MifareId: '',
  OrderId: '',
  PmtAccountId: 0,
  ResponseCode: '0',
  ResponseSubCode: '',
  ResponseDescription: '',
  loading: false,

  selectedSenderCard: null,
  selectedReceiverCard: null,
  transferAmount: '',
};

const balanceTransferSlice = createSlice({
  name: 'balanceTransferSlice',
  initialState,
  reducers: {
    createInstructionFromQRAccountRequest: (state: BalanceTransferState) => {
      state.loading = true;
    },
    createInstructionFromQRAccountSuccess: (
      state: BalanceTransferState,
      { payload }: PayloadAction<CreateInstructionFromQRAccountModel>
    ) => {
      state.Amount = payload.data.Amount;
      state.CustomerNumber = payload.data.CustomerNumber;
      state.InsertDate = payload.data.InsertDate;
      state.InstructionId = payload.data.InstructionId;
      state.InstructionStatus = payload.data.InstructionStatus;
      state.InstructionType = payload.data.InstructionType;
      state.MifareId = payload.data.MifareId;
      state.OrderId = payload.data.OrderId;
      state.PmtAccountId = payload.data.PmtAccountId;
      state.loading = false;
    },
    createInstructionFromQRAccountFail: (state: BalanceTransferState, { payload }: PayloadAction<string>) => {
      state.ResponseCode = payload;
      state.loading = false;
    },
    balanceTransferReset: (state: BalanceTransferState) => {
      state.Amount = '';
      state.CustomerNumber = 0;
      state.InsertDate = '';
      state.InstructionId = 0;
      state.InstructionStatus = 0;
      state.InstructionType = 0;
      state.MifareId = '';
      state.OrderId = '';
      state.PmtAccountId = 0;
      state.ResponseCode = '0';
      state.ResponseSubCode = '';
      state.ResponseDescription = '';
      state.loading = false;

      state.selectedSenderCard = null;
      state.selectedReceiverCard = null;
      state.transferAmount = '';
    },
    setSenderCard: (state: BalanceTransferState, { payload }: PayloadAction<any>) => {
      state.selectedSenderCard = payload;
      state.loading = false;
    },
    setReceiverCard: (state: BalanceTransferState, { payload }: PayloadAction<any>) => {
      state.selectedReceiverCard = payload;
      state.loading = false;
    },
    setTransferAmount: (state: BalanceTransferState, { payload }: PayloadAction<any>) => {
      state.transferAmount = payload;
      state.loading = false;
    },
  },
});

export const balanceTransferStore = (state: RootState) => state.balanceTransfer;
export const balanceTransferLoading = (state: RootState) => state.balanceTransfer.loading;

export const {
  createInstructionFromQRAccountRequest,
  createInstructionFromQRAccountSuccess,
  createInstructionFromQRAccountFail,
  balanceTransferReset,
  setSenderCard,
  setReceiverCard,
  setTransferAmount,
} = balanceTransferSlice.actions;

export default balanceTransferSlice.reducer;
