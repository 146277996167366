import { FC, Fragment, memo } from 'react';
import { ChevronDarkRightIcon, ContactPermissionIcon, ContractsIcon, DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import './Permissions.scss';
import { routePath } from 'Navigator/routes';
import { useHistory } from 'react-router-dom';
import { gutters } from 'Theme/Variables';
type PermissionsProps = {};
type PermissionsItemType = {
  title: string;
  Icon: any;
  onClick: () => void;
  itemID: string;
};

const Permissions: FC<PermissionsProps> = ({}) => {
  const { push } = useHistory();
  const {
    web_account_my_permissions,
    web_account_explicit_consent_text,
    web_my_account_communication_permission,
  } = useLocalization();

  const data = [
    {
      title: web_my_account_communication_permission,
      Icon: ContactPermissionIcon,
      onClick: () => push(routePath.contactPermission),
      itemID: 'goContactPermissionPageBtnTest',
    },
    {
      title: web_account_explicit_consent_text,
      Icon: ContractsIcon,
      onClick: () => push(routePath.explicitConsentConfirm),
      itemID: 'explicitConsentBtnTest',
    },
  ];
  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="permissions">
          <Typography variant="h3" align="center">
            <Box mb={gutters.large} fontWeight={600}>
              {web_account_my_permissions}
            </Box>
          </Typography>
          {data.map(({ title, Icon, onClick, itemID }, index) => (
            <PermissionItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} />
          ))}
        </div>
      </Fragment>
    </DashboardLayout>
  );
};

const PermissionItem: FC<PermissionsItemType> = memo(({ title, Icon, onClick }) => {
  return (
    <div className="permission__item" onClick={onClick}>
      <Box textAlign="center" display="flex" alignItems="center">
        <Icon />
        <Typography variant="h6" align="center">
          <Box className="permission__item__title">{title}</Box>
        </Typography>
      </Box>
      <ChevronDarkRightIcon />
    </div>
  );
});
export default Permissions;
