import * as React from 'react';

function BalanceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm-.78 3H7.766v4.16L5 8.104v1.12c.061-.011.132-.03.212-.053l.12-.036 1.455-.515a9.81 9.81 0 0 0 .977-.356v.623L5 9.846v1.12l2.525-.871.24-.09v6.954a7.168 7.168 0 0 0 3.28-.41 7.081 7.081 0 0 0 2.193-1.28 6.366 6.366 0 0 0 1.659-2.027 6.502 6.502 0 0 0 .866-3.254h-1.475c0 .51-.067 1.02-.202 1.53-.32 1.114-.953 2.044-1.899 2.791a5.713 5.713 0 0 1-2.966 1.227V9.508c.036-.024.08-.048.128-.071l4.258-1.476c.024 0 .037-.024.037-.071V6.823l-2.028.73-2.395.817v-.604l4.423-1.548v-1.12L9.22 6.645V3z"
          id="2tgp7h0dfa"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(2 2)">
          <mask id="51t2owns3b" fill="#fff">
            <use xlinkHref="#2tgp7h0dfa" />
          </mask>
          <use fill="#1547e1" xlinkHref="#2tgp7h0dfa" />
          <g mask="url(#51t2owns3b)" fill="currentColor">
            <path d="M-2-2h24v24H-2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BalanceIcon;
