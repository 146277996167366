// @ts-nocheck
import { axiosHelper } from 'Services/AxiosHelper';
import { GetFaqModel } from 'Models';
import { getApiGWTokenCookie } from 'Utils';

const command = 'cms.getWebFaq';

const getFaq = async (languageId: string): Promise<GetFaqModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: { languageId },
      isFaqUrl: true,
    })
  ).data;

export default getFaq;
