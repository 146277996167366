import { FC, memo } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useLocalization } from 'Hooks';
import { maskHesCode } from 'Utils';
import { useDispatch } from 'react-redux';
import { incrementHesCodeState } from 'Stores/User/slices';

import './RegisteredHes.scss';

type RegisteredHesProps = {
  hesCode: string;
};

const RegisteredHes: FC<RegisteredHesProps> = memo(({ hesCode }) => {
  const { web_btn_guncelle } = useLocalization();
  const dispatch = useDispatch();
  return (
    <div id="hesRegistered">
      <Typography variant="h2" align="center">
        <Box fontWeight={600} marginBottom={gutters.big}>
          {maskHesCode(hesCode)}
        </Box>
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => dispatch(incrementHesCodeState())}
        itemID="updateHesCodeBtnTest"
      >
        {web_btn_guncelle}
      </Button>
    </div>
  );
});

export default RegisteredHes;
