import { FC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { fontSizes } from 'Theme/Variables';

import './InformationRow.scss';

type InformationRowProps = {
  name?: string;
  desc?: string;
  topDivider?: boolean;
  botDivider?: boolean;
};
const InformationRow: FC<InformationRowProps> = ({ name, desc, topDivider, botDivider }) => {
  return (
    <div id="informationRow">
      {topDivider && <Box className="informationRow__divider" />}
      <Box className="informationRow__row">
        <div className="informationRow__row__area">
          <Typography>
            <Box color={'#888a97'} fontSize={fontSizes.smaller}>
              {name}
            </Box>
          </Typography>
        </div>
        <div className="informationRow__row__area">
          <Typography variant="h3">
            <Box fontWeight={600} fontSize={fontSizes.small}>
              {desc}
            </Box>
          </Typography>
        </div>
        <div style={{ flex: 0.2 }} className="informationRow__row__area" />
      </Box>
      {botDivider && <Box className="informationRow__divider" />}
    </div>
  );
};
export default InformationRow;
