import { axiosHelper } from 'Services/AxiosHelper';
import { InsertOrUpdateHesCodeModel } from 'Models';
import { insertOrUpdateHesCodeBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'b2c.RIUpdateCustomerHesCode';

const InsertOrUpdateHesCode = async (params: insertOrUpdateHesCodeBodyData): Promise<InsertOrUpdateHesCodeModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default InsertOrUpdateHesCode;
