import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Stores/index';
import { ToastifyState } from './types';

export const initialState: ToastifyState = {
  showToast: false,
  toastMessage: undefined,
  type: undefined,
};

const ToastifySlice = createSlice({
  name: 'toastify',
  initialState,
  reducers: {
    showToastify: (state: ToastifyState, { payload }: PayloadAction<{ toastMessage: any; type: string }>) => {
      state.showToast = true;
      state.toastMessage = payload.toastMessage;
      state.type = payload.type;
    },
    hideToastify: (state: ToastifyState) => {
      state.showToast = false;
      state.toastMessage = undefined;
      state.type = undefined;
    },
    resetToastifyStore: (state: ToastifyState) => initialState,
  },
});

export const storeTostify = (state: RootState) => state.toastify;

export const { showToastify, hideToastify, resetToastifyStore } = ToastifySlice.actions;

export default ToastifySlice.reducer;
