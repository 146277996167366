import { Clarification } from 'Views/Clarification';
import { RouteProps } from 'react-router-dom';
import { Agreements } from 'Views/Agreements';
import { Contact } from 'Views/Contact';
import { ChangePassword } from 'Views/ChangePassword';
import { ForgotPassword } from 'Views/ForgotPassword';
import { Hes } from 'Views/Hes';
import { Home } from 'Views/Home';
import { Login } from 'Views/Login';
import { MyAccount } from 'Views/MyAccount';
import { Signup } from 'Views/Signup';
import { Transactions } from 'Views/Transactions';
import { UserAddCard } from 'Views/UserAddCard';
import { UserCards } from 'Views/UserCards';
import { Welcome } from 'Views/Welcome';
import { ChangeLanguage } from 'Views/ChangeLanguage';
import { ContactPermission } from 'Views/ContactPermission';
import { UserCardSettings } from 'Views/UserCardSettings';
import { CardDetails } from 'Views/CardDetails';
import { OtherCardDetails } from 'Views/OtherCardDetails';
import { CardDelete } from 'Views/CardDelete';
import { CreditCard } from 'Views/CreditCard';
import { AddCreditCard } from 'Views/AddCreditCard';
import { AddBalanceToCard } from 'Views/AddBalanceToCard';
import { CreditCardDetail } from 'Views/CreditCardDetail';
import { UploadSubscription } from 'Views/UploadSubscription';
import { Maintenance } from 'Views/Maintenance';
import { CardTransactionHistory } from 'Views/CardTransactionHistory';
import { Campaigns } from 'Views/Campaigns';
import { FAQ } from 'Views/FAQ';
import { UserInfo } from 'Views/UserInfo';
import { AgreementsDetail } from 'Views/AgreementsDetail';
import { UserAdressPage } from 'Views/UserAdressPage';
import { UserAdressDetail } from 'Views/UserAdressDetail';
import { AutomaticInstruction } from 'Views/AutomaticInstruction';
import { UserAutomaticInstructions } from 'Views/UserAutomaticInstructions';
import { LoginInformation } from 'Views/LoginInformation';
import { CardApplication } from 'Views/CardApplication';
import { CardApplicationSteps } from 'Views/CardApplicationSteps';
import { Discover } from 'Views/Discover';
import { EmailValidation } from 'Views/EmailValidation';
import { News } from 'Views/News';
import { CardApplications } from 'Views/CardApplications';
import { WelcomeFaq } from 'Views/WelcomeFaq';
import { WelcomeContact } from 'Views/WelcomeContact';
import { CardApplicationsDetail } from 'Views/CardApplicationsDetail';
import { IbmPoints } from 'Views/IbmPoints';
import { MobileAddCreditCard } from 'Views/MobileAddCreditCard';
import { CardApplicationUpdateSuccess } from 'Views/CardApplicationUpdateSuccess';
import { MobilePayment } from 'Views/MobilPayment';
import { ContactPermissionSuccess } from 'Views/ContactPermissionSuccess';
import { MobileAccountOtp } from 'Views/MobileAccountOtp';
import { MobileMasterpassConnect } from 'Views/MobileMasterpassConnect';
import { Permissions } from 'Views/Permissions';
import { ExplicitConsentConfirm } from 'Views/ExplicitConsentConfirm';
import { Access } from 'Views/Access';
import { WelcomeIbmPoints } from 'Views/WelcomeIbmPoints';
import { MiniApp } from 'Views/MiniApp';
import { BalanceTransfer } from 'Views/BalanceTransfer';
import { BalanceTransferSuccess } from 'Views/BalanceTransferSuccess';
import { HomeAgreementUpdate } from 'Views/HomeAgreementUpdate';
import { MicroCredit } from 'Views/MicroCredit';
import { FibaBankSuccessPage } from 'Views/FibaBankSuccessPage';
import { FibaBankFailPage } from 'Views/FibaBankFailPage';
import { FibabankDocumentApprovePage } from 'Views/FibabankDocumentApprovePage';
import { MicroCreditMiniApp } from 'Views/MicroCreditMiniApp';
import MobilePaymentOrder from 'Views/MobilePaymentOrder/MobilePaymentOrder';
import { LotteryForm } from 'Views/LotteryForm';
import StudentCard from '../Views/CardApplication/StudentCard/StudentCard';
import CardInfo from '../Views/CardApplication/CardInfo/CardInfo';
import ProductType from '../Views/CardApplication/ProductType/ProductType';
/* import {
  RequestAndComplaintScreen,
  RequestAndComplaintDetailScreen,
  RequestAndComplaintCreateScreen,
  RequestAndComplaintSuccessScreen,
} from 'Views/RequestAndComplaint'; */
import { HomeAgreementEcplicit } from 'Views/HomeAgreementEcplicit';
import { VirtualCardCreate } from 'Views/VirtualCardCreate';
import { DigitalAccount } from 'Views/DigitalAccount';
import { DigitalAccountSettings } from 'Views/DigitalAccountSettings';

type PathType =
  | '/'
  | '/login'
  | '/home'
  | '/transactions/hes'
  | '/transactions'
  | '/signup'
  | '/forgot-password'
  | '/old-user-password'
  | '/account/change-password'
  | '/cards'
  | '/cards/add-card'
  | '/cards/card-settings'
  | '/transactions'
  | '/my-account'
  | '/my-account/agreements'
  | '/my-account/agreements/agreements-detail'
  | '/contact'
  | '/my-account'
  | '/my-account/change-language'
  | '/my-account/contact-permission'
  | '/my-account/credit-cards'
  | '/my-account/credit-cards/detail'
  | '/cards/card-details'
  | '/cards/other-card-details'
  | '/cards/card-delete'
  | '/cards/add-credit-card'
  | '/cards/add-balance-to-card'
  | '/cards/upload-subscription'
  | '/maintenance'
  | '/card-transaction-history'
  | '/discover'
  | '/campaigns'
  | '/faq'
  | '/my-account/informations'
  | '/my-account/adress'
  | '/my-account/adress-detail'
  | '/cards/automatic-instruction'
  | '/transactions/automatic-instuctions'
  | '/my-account/login-information'
  | '/card-application'
  | '/card-application-steps'
  | '/card-applications-detail'
  | '/email-validation'
  | '/clarification-text'
  | '/news'
  | '/card-applications'
  | '/welcome-faq'
  | '/welcome-contact'
  | '/ibm-points'
  | '/mobile-add-credit-card'
  | '/card-application-update-success'
  | '/mobile-payment'
  | '/contact-permission-success'
  | '/mobile-account-otp'
  | '/mobile-masterpass-connect'
  | '/my-account/permissions'
  | '/my-account/explicit-consent-confirm'
  | '/access'
  | '/welcome/ibm-points'
  | '/mini-app'
  | '/transactions/balanceTransfer'
  | '/transactions/balanceTransfer/balanceTransferSuccess'
  | '/home-agreement-update'
  | '/home-agreement-ecplicit'
  | '/transactions/microCreditOperation'
  | '/fibaBankSuccessPage'
  | '/fibaBankFailPage'
  | '/fibabankDocumentApprove'
  | '/microcredit'
  | '/fibaBankSuccessPage&shouldNotReturn'
  | '/mobilePaymentOrder'
  | '/applicant'
  | '/product-type'
  | '/card-info'
  | '/request-and-complaint'
  | '/request-and-complaint/create'
  | '/request-and-complaint/detail'
  | '/request-and-complaint/success'
  | '/virtual-card-create'
  | '/lottery-form'
  | '/cards/digital-card'
  | '/cards/digital-card/settings';
type RoutesType = {
  path?: PathType;
  isPrivate?: boolean;
} & RouteProps;

export const routePath: {
  [key in
    | 'welcome'
    | 'home'
    | 'transactions'
    | 'hes'
    | 'login'
    | 'signup'
    | 'forgotPassword'
    | 'oldUserPassword'
    | 'changePassword'
    | 'cards'
    | 'addCard'
    | 'cardSettings'
    | 'transactions'
    | 'contact'
    | 'myAccount'
    | 'changeLanguage'
    | 'agreements'
    | 'agreementsDetail'
    | 'contact'
    | 'contactPermission'
    | 'creditCards'
    | 'cardDetails'
    | 'otherCardDetails'
    | 'cardDelete'
    | 'addCreditCard'
    | 'creditCardDetail'
    | 'addBalanceToCard'
    | 'uploadSubscription'
    | 'maintenance'
    | 'cardTransactionHistory'
    | 'discover'
    | 'campaigns'
    | 'faq'
    | 'userInfo'
    | 'userAdress'
    | 'userAdressDetail'
    | 'automaticInstruction'
    | 'userAutomaticInstructions'
    | 'loginInformation'
    | 'cardApplication'
    | 'cardApplicationSteps'
    | 'emailValidation'
    | 'clarificationText'
    | 'news'
    | 'cardApplications'
    | 'welcomeFaq'
    | 'welcomeContact'
    | 'cardApplicationsDetail'
    | 'ibmPoints'
    | 'mobileAddCreditCard'
    | 'studentCard'
    | 'productType'
    | 'cardInfo'
    | 'cardApplicationUpdateSuccess'
    | 'mobilePayment'
    | 'contactPermissionSuccess'
    | 'mobileAccountOtp'
    | 'mobileMasterpassConnect'
    | 'permissions'
    | 'explicitConsentConfirm'
    | 'access'
    | 'welcomeIbmPoints'
    | 'miniApp'
    | 'balanceTransfer'
    | 'balanceTransferSuccess'
    | 'microCreditOperation'
    | 'homeAgreementUpdate'
    | 'HomeAgreementEcplicit'
    | 'fibaBankSuccessPage'
    | 'fibaBankFailPage'
    | 'fibabankDocumentApprove'
    | 'microCreditMiniApp'
    | 'fibaBankSuccessPageshouldNotReturn'
    | 'mobilePaymentOrder'
    | 'requestAndComplaint'
    | 'requestAndComplaintCreate'
    | 'requestAndComplaintDetail'
    | 'requestAndComplaintSuccess'
    | 'virtualCardCreate'
    | 'lotteryForm'
    | 'digitalCard'
    | 'digitalCardSettings']: PathType;
} = {
  welcome: '/',
  home: '/home',
  hes: '/transactions/hes',
  transactions: '/transactions',
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  oldUserPassword: '/old-user-password',
  changePassword: '/account/change-password',
  cards: '/cards',
  addCard: '/cards/add-card',
  cardSettings: '/cards/card-settings',
  contact: '/contact',
  myAccount: '/my-account',
  changeLanguage: '/my-account/change-language',
  agreements: '/my-account/agreements',
  agreementsDetail: '/my-account/agreements/agreements-detail',
  creditCards: '/my-account/credit-cards',
  contactPermission: '/my-account/contact-permission',
  cardDetails: '/cards/card-details',
  otherCardDetails: '/cards/other-card-details',
  cardDelete: '/cards/card-delete',
  addCreditCard: '/cards/add-credit-card',
  addBalanceToCard: '/cards/add-balance-to-card',
  creditCardDetail: '/my-account/credit-cards/detail',
  uploadSubscription: '/cards/upload-subscription',
  maintenance: '/maintenance',
  cardTransactionHistory: '/card-transaction-history',
  discover: '/discover',
  campaigns: '/campaigns',
  faq: '/faq',
  userInfo: '/my-account/informations',
  userAdress: '/my-account/adress',
  userAdressDetail: '/my-account/adress-detail',
  automaticInstruction: '/cards/automatic-instruction',
  userAutomaticInstructions: '/transactions/automatic-instuctions',
  loginInformation: '/my-account/login-information',
  cardApplication: '/card-application',
  cardApplicationSteps: '/card-application-steps',
  emailValidation: '/email-validation',
  contactPermissionSuccess: '/contact-permission-success',
  clarificationText: '/clarification-text',
  news: '/news',
  cardApplications: '/card-applications',
  welcomeContact: '/welcome-contact',
  welcomeFaq: '/welcome-faq',
  cardApplicationsDetail: '/card-applications-detail',
  ibmPoints: '/ibm-points',
  mobileAddCreditCard: '/mobile-add-credit-card',
  cardApplicationUpdateSuccess: '/card-application-update-success',
  mobilePayment: '/mobile-payment',
  mobileAccountOtp: '/mobile-account-otp',
  mobileMasterpassConnect: '/mobile-masterpass-connect',
  permissions: '/my-account/permissions',
  explicitConsentConfirm: '/my-account/explicit-consent-confirm',
  access: '/access',
  welcomeIbmPoints: '/welcome/ibm-points',
  miniApp: '/mini-app',
  balanceTransfer: '/transactions/balanceTransfer',
  balanceTransferSuccess: '/transactions/balanceTransfer/balanceTransferSuccess',
  homeAgreementUpdate: '/home-agreement-update',
  HomeAgreementEcplicit: '/home-agreement-ecplicit',
  microCreditOperation: '/transactions/microCreditOperation',
  fibaBankSuccessPage: '/fibaBankSuccessPage',
  fibaBankFailPage: '/fibaBankFailPage',
  fibabankDocumentApprove: '/fibabankDocumentApprove',
  microCreditMiniApp: '/microcredit',
  fibaBankSuccessPageshouldNotReturn: '/fibaBankSuccessPage&shouldNotReturn',
  mobilePaymentOrder: '/mobilePaymentOrder',
  lotteryForm: '/lottery-form',
  studentCard: '/applicant',
  productType: '/product-type',
  cardInfo: '/card-info',
  requestAndComplaint: '/request-and-complaint',
  requestAndComplaintCreate: '/request-and-complaint/create',
  requestAndComplaintDetail: '/request-and-complaint/detail',
  requestAndComplaintSuccess: '/request-and-complaint/success',
  virtualCardCreate: '/virtual-card-create',
  digitalCard: '/cards/digital-card',
  digitalCardSettings: '/cards/digital-card/settings',
};

export const routes: RoutesType[] = [
  {
    isPrivate: false,
    path: routePath.contactPermissionSuccess,
    component: ContactPermissionSuccess,
    exact: true,
  },
  {
    path: routePath.welcome,
    component: Welcome,
    exact: true,
    isPrivate: true, // değiş
  },
  {
    path: routePath.access,
    component: Access,
    exact: true,
  },
  {
    path: routePath.home,
    component: Home,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.hes,
    component: Hes,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.login,
    component: Login,
    exact: true,
    isPrivate: true, // değiş
  },
  {
    path: routePath.signup,
    component: Signup,
    exact: true,
    isPrivate: true, // değiş
  },
  {
    path: routePath.forgotPassword,
    component: ForgotPassword,
    exact: true,
    isPrivate: true, // değiş
  },
  {
    path: routePath.changePassword,
    component: ChangePassword,
    exact: true,
    isPrivate: true, // değiş
  },
  {
    path: routePath.cards,
    component: UserCards,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.addCard,
    component: UserAddCard,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.transactions,
    component: Transactions,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.myAccount,
    component: MyAccount,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.agreements,
    component: Agreements,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.agreementsDetail,
    component: AgreementsDetail,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.contact,
    component: Contact,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.changeLanguage,
    component: ChangeLanguage,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.contactPermission,
    component: ContactPermission,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.cardSettings,
    component: UserCardSettings,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.cardDetails,
    component: CardDetails,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.otherCardDetails,
    component: OtherCardDetails,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.cardDelete,
    component: CardDelete,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.creditCards,
    component: CreditCard,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.addCreditCard,
    component: AddCreditCard,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.addBalanceToCard,
    component: AddBalanceToCard,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.creditCardDetail,
    component: CreditCardDetail,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.uploadSubscription,
    component: UploadSubscription,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.cardTransactionHistory,
    component: CardTransactionHistory,
    isPrivate: true,
    exact: true,
  },
  {
    path: routePath.maintenance,
    component: Maintenance,
    exact: true,
  },
  {
    path: routePath.campaigns,
    component: Campaigns,
    isPrivate: true,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.faq,
    component: FAQ,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.userInfo,
    component: UserInfo,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.userAdress,
    component: UserAdressPage,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.userAdressDetail,
    component: UserAdressDetail,
    exact: true,
  },
  /*   {
    isPrivate: true,
    path: routePath.automaticInstruction,
    component: AutomaticInstruction,
    exact: true,
  }, */
  /*  {
    isPrivate: true,
    path: routePath.userAutomaticInstructions,
    component: UserAutomaticInstructions,
    exact: true,
  }, */
  {
    isPrivate: true,
    path: routePath.loginInformation,
    component: LoginInformation,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardApplication,
    component: CardApplication,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardApplicationSteps,
    component: CardApplicationSteps,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.discover,
    component: Discover,
    exact: true,
  },
  {
    path: routePath.emailValidation,
    component: EmailValidation,
    exact: true,
  },
  {
    path: routePath.clarificationText,
    component: Clarification,
    exact: true,
  },

  {
    isPrivate: true,
    path: routePath.news,
    component: News,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardApplications,
    component: CardApplications,
    exact: true,
  },
  {
    path: routePath.welcomeContact,
    component: WelcomeContact,
    exact: true,
  },
  {
    path: routePath.welcomeFaq,
    component: WelcomeFaq,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardApplicationsDetail,
    component: CardApplicationsDetail,
    exact: true,
  },
  {
    path: routePath.ibmPoints,
    component: IbmPoints,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.studentCard,
    component: StudentCard,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.productType,
    component: ProductType,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardInfo,
    component: CardInfo,
    exact: true,
  },
  {
    path: routePath.mobileAddCreditCard,
    component: MobileAddCreditCard,
    exact: true,
  },
  {
    path: routePath.virtualCardCreate,
    component: VirtualCardCreate,
    exact: true,
  },
  {
    path: routePath.mobilePayment,
    component: MobilePayment,
    exact: true,
  },
  {
    path: routePath.mobileAccountOtp,
    component: MobileAccountOtp,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.cardApplicationUpdateSuccess,
    component: CardApplicationUpdateSuccess,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.permissions,
    component: Permissions,
    exact: true,
  },
  {
    path: routePath.mobileAccountOtp,
    component: MobileAccountOtp,
    exact: true,
  },
  {
    path: routePath.mobileMasterpassConnect,
    component: MobileMasterpassConnect,
    exact: true,
  },
  {
    isPrivate: true,
    path: routePath.explicitConsentConfirm,
    component: ExplicitConsentConfirm,
    exact: true,
  },
  {
    path: routePath.welcomeIbmPoints,
    component: WelcomeIbmPoints,
    exact: true,
  },
  {
    path: routePath.miniApp,
    component: MiniApp,
    exact: true,
  },
  {
    path: routePath.balanceTransfer,
    component: BalanceTransfer,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.balanceTransferSuccess,
    component: BalanceTransferSuccess,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.homeAgreementUpdate,
    component: HomeAgreementUpdate,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.HomeAgreementEcplicit,
    component: HomeAgreementEcplicit,
    exact: true,
    isPrivate: true,
  },

  {
    path: routePath.microCreditOperation,
    component: MicroCredit,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.fibaBankSuccessPage,
    component: FibaBankSuccessPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.fibaBankFailPage,
    component: FibaBankFailPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.fibabankDocumentApprove,
    component: FibabankDocumentApprovePage,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.microCreditMiniApp,
    component: MicroCreditMiniApp,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.mobilePaymentOrder,
    component: MobilePaymentOrder,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.fibaBankSuccessPageshouldNotReturn,
    component: FibaBankSuccessPage,
    exact: true,
    isPrivate: false,
    //Bunun yapılmasının sebebi istanbulsenin üzerinden uygulamayı webview olarak çağırıldığından fibabankın mobil uygulama
    //olarak anlaması ve sürecin mobil gibi devam etmesi. Yukarıda url'i gönderip parametre olarak shouldnotreturn alarak
    //sürecin aslında web tarafından geldiğinin anlaşılmasını sağlamaktır.
  },
  /*   {
    path: routePath.requestAndComplaint,
    component: RequestAndComplaintScreen,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.requestAndComplaintCreate,
    component: RequestAndComplaintCreateScreen,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.requestAndComplaintDetail,
    component: RequestAndComplaintDetailScreen,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.requestAndComplaintSuccess,
    component: RequestAndComplaintSuccessScreen,
    exact: true,
    isPrivate: false,
  }, */
  {
    path: routePath.lotteryForm,
    component: LotteryForm,
    exact: true,
    isPrivate: false,
  },
  {
    path: routePath.digitalCard,
    component: DigitalAccount,
    exact: true,
    isPrivate: true,
  },
  {
    path: routePath.digitalCardSettings,
    component: DigitalAccountSettings,
    exact: true,
    isPrivate: true,
  },
];
