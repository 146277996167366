import { FC, useEffect, useState } from 'react';
import { ChevronDownIcon, MaskedTextField, Checkbox, FormErrorWrapper } from 'Components';
import { Box, TextField, Button } from '@material-ui/core';
import { useErrorMessage, useLocalization, useModal } from 'Hooks';
import { FormWrapper } from 'Components';
import { formikGlobalConfig, formNames, inputMaskTypes } from 'Config';
import { Formik } from 'formik';
import { AddAdressInitialValues, AddAdressCardApplicationSchema } from 'Validations';
import { gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountState,
  getDistrictListRequest,
  getStreetListRequest,
  getTownListRequest,
  getTownOtherListRequest,
  onUpdateCustomerAddressRequest,
  setActiveUserAdress,
  storeAccount,
} from 'Stores/Account';
import classNames from 'classnames';
import { DialogAdressType } from 'Views/UserAdressDetail/DialogAdressType';
import { DialogCity } from 'Views/UserAdressDetail/DialogCity';
import { DialogTown } from 'Views/UserAdressDetail/DialogTown';
import { DialogDistrict } from 'Views/UserAdressDetail/DialogDistrict';
import { DialogStreet } from 'Views/UserAdressDetail/DialogStreet';
import { onInıtDataStore } from 'Stores/App';
import { cityListItem } from 'Models/GetCityListModel';
import { adressTypeData } from 'Views/UserAdressDetail/DialogAdressType/DialogAdressType';
import { citiesData } from 'Views/UserAdressDetail/DialogCity/DialogCity';
import { districtItemProps } from 'Views/UserAdressDetail/DialogDistrict/DialogDistrict';
import { townListItem } from 'Views/UserAdressDetail/DialogTown/DialogTown';
import { streetItemProp } from 'Views/UserAdressDetail/DialogStreet/DialogStreet';
import { UserState, userStore } from 'Stores/User';
import { townOtherListItem } from 'Models/GetTownListOtherModel';
import { addCustomerAdressNormalize } from 'Normalize';
import { adressTransactionTypes } from 'Views/UserAdressDetail/constant';

import './Adress.scss';
import { incStep } from 'Stores/CardApplication';

type AdressListProps = {
  AddressCode: string;
  AddressCodeDesc: string;
};

type UserAdressDetailProps = {
  fillExportedData: (e: any) => void;
  onSubmit?: () => void;
  address?: {
    AddressType?: any;
    CountryCode?: number;
    City?: string;
    CityCode?: number;
    Town?: string;
    TownCode?: number | string;
    District?: string;
    Street?: string;
    AddressDetail?: string;
    PostalCode?: string | number;
  };
};

const UserAdressDetail: FC<UserAdressDetailProps> = ({ fillExportedData, address, onSubmit }) => {
  const {
    web_address_type,
    web_address_city,
    web_address_district,
    web_address_avenue,
    web_address_detail_info,
    web_address_post_code,
    web_btn_next,
    web_address_town,
    web_save_adress_also_my_account,
  } = useLocalization();

  const [isSaveAdress, setIsSaveAdress] = useState<boolean>(address ? true : false);

  const dispatch = useDispatch();

  const accountStore: AccountState = useSelector(storeAccount);
  const initData = useSelector(onInıtDataStore);
  const adressTypeData: adressTypeData[] | undefined = initData.AdressList;
  const addressType = adressTypeData?.find((it) => it.AddressCode == address?.AddressType);

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData.customerNumber;
  const sessionToken = userData.sessionToken;

  const userActiveAdress = accountStore?.activeUserAdress;
  const adressList: AdressListProps[] = initData.AdressList;

  const cities: cityListItem[] | undefined = accountStore.cityList;
  const otherTownList: townOtherListItem[] | undefined = accountStore.otherTownList;
  const townList: townListItem[] | undefined = accountStore.townList;
  const district: districtItemProps[] | undefined = accountStore.districtList;

  // İnitial Values
  const initialAdress = {
    adressType: address ? address?.AddressType : userActiveAdress?.adressType,
    cityCode: address ? address?.CityCode : userActiveAdress?.cityCode,
    town: address ? address?.Town || address?.TownCode?.toString() : userActiveAdress?.town,
    district: address ? address?.District : userActiveAdress?.district,
  };

  const initialAdressValue = adressList.find((item: AdressListProps) => item.AddressCode === initialAdress?.adressType);
  const initialCity =
    cities && cities.find((item: cityListItem) => item.CityId === initialAdress?.cityCode?.toString());
  const initialTown =
    townList &&
    townList.find(
      (item: townListItem) =>
        item?.ilceAdi?.toLocaleLowerCase('tr-TR').trim() === initialAdress?.town?.toLocaleLowerCase('tr-TR').trim()
    );
  const initialDistrict =
    district &&
    district.find(
      (item: districtItemProps) =>
        item?.mahalleAdi?.toLocaleLowerCase('tr-TR')?.trim() ===
        initialAdress?.district?.toLocaleLowerCase('tr-TR')?.trim()
    );

  // Buradaki kodu görünce aklında kötü şeyler geçirme hepsi belbim backendinden kaynaklı Dataları farklı servisten cekıp string esleştirip farklı servisten cektiğimiz kod numarası gönderilmektedir.

  const [selectedAdressType, setSelectedAdressType] = useState<any>(addressType?.AddressCode);
  const [selectedCityCode, setSelectedCityCode] = useState<any>();

  const { getError, getErrorMessage } = useErrorMessage();

  const { show: showAdressType, hide: hideAdressType, isVisible: isVisibleAdressType } = useModal();
  const { show: showCity, hide: hideCity, isVisible: isVisibleCity } = useModal();
  const { show: showDistrict, hide: hideDistrict, isVisible: isVisibleDistrict } = useModal();
  const { show: showTown, hide: hideTown, isVisible: isVisibleTown } = useModal();
  const { show: showStreet, hide: hideStreet, isVisible: isVisibleStreet } = useModal();

  useEffect(() => {
    if (!!initialCity) {
      dispatch(getTownListRequest(initialCity.CityId));
      setSelectedCityCode(initialCity.CityId);
      setSelectedAdressType(initialAdressValue?.AddressCode);
    }
  }, []);

  useEffect(() => {
    if (!!initialTown) {
      dispatch(getDistrictListRequest(initialTown.kayitNo.toString()));
      dispatch(
        getTownOtherListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          CityId: selectedCityCode,
        })
      );
    }
  }, [dispatch, initialTown]);

  useEffect(() => {
    if (!!initialDistrict) {
      dispatch(getStreetListRequest(initialDistrict.kayitNo.toString()));
    }
  }, [dispatch, initialDistrict]);

  const handleSubmit = (values: any) => {
    const otherTownCode = otherTownList?.find(
      (item: townOtherListItem) => item.TownName.toLocaleLowerCase('tr-TR') === values.town.toLocaleLowerCase('tr-TR')
    );
    if (isSaveAdress) {
      const req = addCustomerAdressNormalize(
        values,
        customerNumber,
        sessionToken,
        parseInt(selectedCityCode),
        otherTownCode?.TownName,
        otherTownCode?.TownId,
        selectedAdressType,
        address ? adressTransactionTypes.updatedAdress : adressTransactionTypes.addAdress
      );
      dispatch(onUpdateCustomerAddressRequest({ ...req, callback: onSubmit }));
    }
    fillExportedData({
      adressType: 9,
      cityCode: parseInt(selectedCityCode),
      townCode: otherTownCode?.TownId,
      town: otherTownCode?.TownName,
      district: values.district,
      street: values.street,
      adressDetail: values.adressDetail,
      postCode: values.postCode,
    });

    // Calback işlemi redux saga da gönderildiği için bu step işlemleri yapılmaması gerekiyor
    if (typeof onSubmit === 'function') {
      return;
    }

    dispatch(setActiveUserAdress(values));
    if (!isSaveAdress) {
      dispatch(incStep());
    }
  };

  const editAddressCity =
    address && cities ? cities.find((item: cityListItem) => item.CityId === address?.CityCode?.toString()) : undefined;

  return (
    <div id="userAdressDetail">
      <FormErrorWrapper formName={formNames.addUserAdress} resetFormOnRouteChange>
        <Formik
          initialValues={AddAdressInitialValues(
            addressType?.AddressCode,
            editAddressCity?.CityName,
            (address?.Town || address?.TownCode)?.toString().toLocaleLowerCase('tr-TR'),
            address?.District,
            address?.Street,
            address?.AddressDetail,
            address?.PostalCode?.toString()
          )}
          validationSchema={AddAdressCardApplicationSchema(isSaveAdress)}
          onSubmit={handleSubmit}
          {...formikGlobalConfig}
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              setFieldValue,
              values: { adressType, city, town, district, street, adressDetail, postCode },
              errors: {
                adressType: errorAdressType,
                city: errorCity,
                town: errorTown,
                district: errorDistrict,
                street: errorStreet,
                adressDetail: errorAdressDetail,
                postCode: errorPostCode,
              },
              touched: {
                adressType: touchedAdressType,
                city: touchedCity,
                town: touchedTown,
                district: touchedDistrict,
                street: touchedStreet,
                adressDetail: touchedAdressDetail,
                postCode: touchedPostCode,
              },
            } = formikProps;

            const fields = [
              {
                index: 1,
                name: 'city',
                value: city,
                error: errorCity,
                touched: touchedCity && !city,
                arrowDown: true,
                label: web_address_city,
                isDisabled: false,
                onClick: () => {
                  showCity();
                },
                itemID: 'chooseCityCardApplicationInputTest',
              },
              {
                index: 2,
                name: 'town',
                value: town,
                error: errorTown,
                touched: touchedTown && !town,
                arrowDown: true,
                label: web_address_town,
                isDisabled: !city,
                onClick: () => {
                  city && showTown();
                },
                itemID: 'chooseTownCardApplicationInputTest',
              },
              {
                index: 3,
                name: 'district',
                value: district,
                error: errorDistrict,
                touched: touchedDistrict && !district,
                arrowDown: true,
                label: web_address_district,
                isDisabled: !town,
                onClick: () => {
                  town && showDistrict();
                },
                itemID: 'chooseDistrictCardApplicationInputTest',
              },
              {
                index: 4,
                name: 'street',
                value: street,
                error: errorStreet,
                touched: touchedStreet && !street,
                arrowDown: true,
                label: web_address_avenue,
                isDisabled: !district,
                onClick: () => {
                  district && showStreet();
                },
                itemID: 'adressStreetInputTest',
              },
            ];

            return (
              <FormWrapper onSubmit={formikProps.handleSubmit}>
                <Box>
                  {fields.map((item: any) => (
                    <Box
                      marginBottom={gutters.small}
                      onClick={() => {
                        item.onClick();
                      }}
                      style={{ cursor: 'pointer' }}
                      key={item.index}
                      className={classNames({
                        'userAdressDetail--disabled': item.isDisabled,
                      })}
                    >
                      <TextField
                        variant="filled"
                        fullWidth
                        name={item.name}
                        value={item.value}
                        onBlur={handleBlur}
                        label={item.label}
                        InputLabelProps={{
                          shrink: item.value ? true : false,
                        }}
                        inputMode="text"
                        error={getError(item.error, item.touched)}
                        helperText={getErrorMessage(item.error, item.touched)}
                        InputProps={{
                          endAdornment: <ChevronDownIcon />,
                          readOnly: true,
                        }}
                        itemID={item.itemID}
                      />
                    </Box>
                  ))}

                  <DialogAdressType
                    isOpen={isVisibleAdressType}
                    hide={hideAdressType}
                    setSelectedValue={(adressItem: adressTypeData) => {
                      setSelectedAdressType(adressItem.AddressCode);
                      setFieldValue('adressType', adressItem.AddressCodeDesc);
                    }}
                    selectedValue={adressType}
                  />
                  <DialogCity
                    isOpen={isVisibleCity}
                    hide={hideCity}
                    setSelectedValue={(cityItem: citiesData) => {
                      setSelectedCityCode(cityItem.CityId);
                      setFieldValue('city', cityItem.CityName);
                      setFieldValue('town', '');
                      setFieldValue('district', '');
                      setFieldValue('street', '');
                    }}
                    selectedValue={city}
                  />
                  <DialogTown
                    isOpen={isVisibleTown}
                    hide={hideTown}
                    setSelectedValue={(townItem: townListItem) => {
                      setFieldValue('town', townItem?.ilceAdi?.toLocaleLowerCase('tr-TR'));
                      setFieldValue('district', '');
                      setFieldValue('street', '');
                    }}
                    selectedValue={town}
                  />
                  <DialogDistrict
                    isOpen={isVisibleDistrict}
                    hide={hideDistrict}
                    setSelectedValue={(districtItem: districtItemProps) => {
                      setFieldValue('district', districtItem?.mahalleAdi?.toLocaleLowerCase('tr-TR'));
                      setFieldValue('street', '');
                    }}
                    selectedValue={district}
                  />
                  <DialogStreet
                    isOpen={isVisibleStreet}
                    hide={hideStreet}
                    setSelectedValue={(streetItem: streetItemProp) => {
                      setFieldValue('street', streetItem?.csbmAdi?.toLocaleLowerCase('tr-TR'));
                    }}
                    selectedValue={street}
                  />

                  <Box marginBottom={gutters.small}>
                    <TextField
                      className="userAdressDetail__adressDetail"
                      fullWidth
                      variant="filled"
                      name="adressDetail"
                      value={adressDetail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={web_address_detail_info}
                      inputMode="text"
                      multiline
                      rows={4}
                      error={getError(errorAdressDetail, touchedAdressDetail)}
                      helperText={getErrorMessage(errorAdressDetail, touchedAdressDetail)}
                    />
                  </Box>

                  <Box marginBottom={gutters.big}>
                    <MaskedTextField
                      variant="filled"
                      fullWidth
                      name="postCode"
                      mask={inputMaskTypes.postCode}
                      value={postCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={web_address_post_code}
                      error={getError(errorPostCode, touchedPostCode)}
                      helperText={getErrorMessage(errorPostCode, touchedPostCode)}
                      className="userAdressDetail__postCode"
                    />
                  </Box>
                  <Checkbox
                    name="viaMail"
                    color="primary"
                    disabled={!!address}
                    checked={address ? true : isSaveAdress}
                    onChange={() => {
                      setIsSaveAdress((prev) => !prev);
                    }}
                    label={web_save_adress_also_my_account}
                    marginBottomGutter={gutters.small}
                  />
                  {isSaveAdress && !address && (
                    <Box
                      marginBottom={gutters.small}
                      onClick={() => (address ? {} : showAdressType())}
                      style={{ cursor: 'pointer' }}
                      // className={classNames({
                      //   'userAdressDetail--disabled': !!userActiveAdress,
                      // })}
                    >
                      <TextField
                        variant="filled"
                        fullWidth
                        name="adressType"
                        value={adressType}
                        onBlur={handleBlur}
                        label={web_address_type}
                        InputLabelProps={{
                          shrink: adressType ? true : false,
                        }}
                        disabled
                        inputMode="text"
                        error={getError(errorAdressType, touchedAdressType && !adressType)}
                        helperText={getErrorMessage(errorAdressType, touchedAdressType && !adressType)}
                        InputProps={{
                          endAdornment: <ChevronDownIcon />,
                          style: { pointerEvents: 'none' },
                        }}
                      />
                    </Box>
                  )}
                  <Button variant="contained" color="secondary" type="submit" fullWidth>
                    {web_btn_next}
                  </Button>
                </Box>
              </FormWrapper>
            );
          }}
        </Formik>
      </FormErrorWrapper>
    </div>
  );
};
export default UserAdressDetail;
