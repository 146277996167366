import { FunctionComponent } from 'react';
import { Typography, Box } from '@material-ui/core';
import { MaskedTextField } from 'Components';
import { fontSizes, gutters } from 'Theme/Variables';
import { FormikProps } from 'formik';
import { ForgotPasswordSchemaInputs } from 'Validations';
import { useErrorMessage, useLineBreaks, useLocalization } from 'Hooks';
import { inputMaskTypes } from 'Config';

type ForgotPasswordIdentityStepProps = {
  formikProps: FormikProps<ForgotPasswordSchemaInputs>;
  userType?: string;
  description: string;
};

const ForgotPasswordIdentityStep: FunctionComponent<ForgotPasswordIdentityStepProps> = ({
  formikProps: {
    values: { tcNo, birthDate },
    errors: { tcNo: errorTcNo, birthDate: errorBirthDate },
    touched: { tcNo: touchedTcNo, birthDate: touchedBirthDate },
    handleChange,
    handleBlur,
  },
  userType,
  description,
}) => {
  const {
    web_eskiuye_title,
    web_title_forgot_password,
    web_plc_tckn,
    web_plc_birthday,
    web_plc_dogum_tarihi_formati,
  } = useLocalization();

  const { getError, getErrorMessage } = useErrorMessage();
  const parsedDescription = useLineBreaks(description);

  return (
    <>
      <Typography variant="h5" align="center">
        <Box fontWeight={600} marginBottom={!!userType ? gutters.regular : gutters.large}>
          {!!userType ? web_eskiuye_title : web_title_forgot_password}
        </Box>
      </Typography>
      {description && (
        <Typography variant="body1" align="center" component="span">
          <Box marginBottom={gutters.big} fontSize={fontSizes.regular} whiteSpace="pre-wrap">
            {parsedDescription}
          </Box>
        </Typography>
      )}
      <Box marginBottom={gutters.big}>
        <MaskedTextField
          name="tcNo"
          mask={inputMaskTypes.tcNo}
          label={web_plc_tckn}
          value={tcNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(errorTcNo, touchedTcNo)}
          helperText={getErrorMessage(errorTcNo, touchedTcNo)}
          itemID="tcNoInputTest"
        />
      </Box>
      <Box marginBottom={gutters.big}>
        <MaskedTextField
          name="birthDate"
          mask={inputMaskTypes.date}
          label={web_plc_birthday}
          value={birthDate}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={web_plc_dogum_tarihi_formati}
          error={getError(errorBirthDate, touchedBirthDate)}
          helperText={getErrorMessage(errorBirthDate, touchedBirthDate)}
          itemID="birtDateInputTest"
        />
      </Box>
    </>
  );
};

export default ForgotPasswordIdentityStep;
