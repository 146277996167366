import { Box, Button, Link, Typography } from '@material-ui/core';
import { Checkbox, QuestionMarkIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AccountState,
  onUpdateOnlinePermissionRequest,
  setUserAcceptedNewVersion,
  setUserAcceptedNewVersionV2,
  storeAccount,
} from 'Stores/Account';
import { onInıtDataStore } from 'Stores/App';
import { updateAgreementList, UserState, userStore } from 'Stores/User';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { generateLanguageParam, paranthesisNormalize } from 'Utils';

import './UpdateAgreements.scss';

const UpdateAgreements: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const accountState: AccountState = useSelector(storeAccount);
  const onInıtData = useSelector(onInıtDataStore);
  const userAccepted = accountState.userAcceptedNewVersion;
  const userAcceptedV2 = accountState.userAcceptedNewVersionV2;
  const { AgreementList } = onInıtData;
  const { currentAgreementList } = userData;

  const {
    web_updated_contracts_header,
    web_updated_contracts_info,
    web_updated_contracts_info_desc,
    web_account_explicit_consent_text_info,
    web_clarification_text,
    web_read_accept,
    web_contracts_read_and_accept,
  } = useLocalization();

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const confirmAgreements = () => {
    const agreement1 = currentAgreementList.find((item: any) => item.AgreementType === 1);
    const agreement2 = currentAgreementList.find((item: any) => item.AgreementType === 2);
    const agreement5 = currentAgreementList.find((item: any) => item.AgreementType === 5);

    const agreement4 = userAcceptedV2
      ? AgreementList.find((item: any) => item.AgreementType === 4)?.AgreementVersion //currentAgreementList.find((item: any) => item.AgreementType === 4)?.AgreementVersion
      : currentAgreementList.find((item: any) => item.AgreementType === 4)?.AgreementVersion;

    dispatch(
      onUpdateOnlinePermissionRequest({
        Language: generateLanguageParam(),
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        OnlinePermissionMessage: {
          1: agreement1 ? AgreementList.find((item: any) => item.AgreementType === 1)?.AgreementVersion : undefined,
          2: agreement2 ? AgreementList.find((item: any) => item.AgreementType === 2)?.AgreementVersion : undefined,
          4: agreement4,
          5: agreement5 ? AgreementList.find((item: any) => item.AgreementType === 5)?.AgreementVersion : undefined,
          6: AgreementList.find((item: any) => item.AgreementType === 6)?.AgreementVersion,
          7: AgreementList.find((item: any) => item.AgreementType === 7)?.AgreementVersion,
        },
      })
    );
    dispatch(updateAgreementList(onInıtData?.AgreementList));
  };

  const checkExplicittVersion = () => {
    const explicitCurrentAcceptedVersion = currentAgreementList.find((item: any) => item.AgreementType === 4);
    const explicitApplicationVersion = AgreementList.find((item: any) => item.AgreementType === 4);
    return explicitCurrentAcceptedVersion !== explicitApplicationVersion;
  };

  return (
    <div id="updateAgreements">
      <div className="updateAgreements">
        <Typography variant="h3">
          <Box marginTop={gutters.big} textAlign="center" fontWeight={600}>
            {web_updated_contracts_header}
          </Box>
        </Typography>
        <Box className="updateAgreements__alert-box">
          <QuestionMarkIcon color={colors.secondary} width="32px" height="32px" />
          <Box paddingLeft={gutters.regular}>
            <Typography variant="h3">
              <Box fontSize={fontSizes.regular} fontWeight={600}>
                {web_updated_contracts_info}
              </Box>
            </Typography>
            <Typography>
              <Box mt={gutters.small}>{web_updated_contracts_info_desc}</Box>
            </Typography>
          </Box>
        </Box>
        <Box mt={gutters.big} className="updateAgreements__check-container">
          <Checkbox
            name="explicit"
            color="primary"
            onClick={() => {
              if (!userAccepted) {
                history.push(routePath.homeAgreementUpdate);
              } else {
                dispatch(setUserAcceptedNewVersion(false));
              }
            }}
            checked={userAccepted}
            label={
              <Typography variant="h3" align="center">
                <Box fontSize={fontSizes.regular} fontWeight={600}>
                  <Link
                    underline="always"
                    color="secondary"
                    href={routePath.homeAgreementUpdate}
                    itemID="showSmsPermissionBtnTest"
                  >
                    {paranthesisNormalize(web_clarification_text)}
                  </Link>{' '}
                  {web_contracts_read_and_accept}
                </Box>
              </Typography>
            }
            marginBottomGutter="0"
          />
        </Box>
        {checkExplicittVersion() && (
          <Box mt={gutters.small} className="updateAgreements__check-container">
            <Checkbox
              name="explicit"
              color="primary"
              onClick={() => {
                if (!userAcceptedV2) {
                  history.push(routePath.HomeAgreementEcplicit);
                } else {
                  dispatch(setUserAcceptedNewVersionV2(false));
                }
              }}
              checked={userAcceptedV2}
              label={
                <Typography variant="h3" align="center">
                  <Box fontSize={fontSizes.regular} fontWeight={600}>
                    <Link
                      underline="always"
                      color="secondary"
                      href={routePath.HomeAgreementEcplicit}
                      itemID="showSmsPermissionBtnTest"
                    >
                      {paranthesisNormalize(web_account_explicit_consent_text_info)}
                    </Link>{' '}
                    {web_contracts_read_and_accept}
                  </Box>
                </Typography>
              }
              marginBottomGutter="0"
            />
          </Box>
        )}
        <Box mt={gutters.big} />
        <Button
          className={!userAccepted ? 'disabled' : ''}
          disabled={!userAccepted}
          variant="contained"
          fullWidth
          color="secondary"
          onClick={confirmAgreements}
        >
          Devam et
        </Button>
      </div>
    </div>
  );
};

export default UpdateAgreements;
