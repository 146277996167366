import { CampaignItem } from 'Stores/Account';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  campaignsReqBodyData,
  CommonUpdateCommercialStatus4MailBodyData,
  getCityListReqBodyData,
  getTownListOtherReqBodyData,
  getTownListReqBodyData,
  onChangePasswordCustomerReqBodyData,
  onConfirmEmailReqBodyData,
  onGetAgreementContentReqBodyData,
  OnGetApplicationCenterListBodyData,
  onGetCustomerInfoReqBodyData,
  onLoginLogListReqBodyData,
  onUpdateCustomerAddressReqBodyData,
} from 'Config';
import {
  agreementType,
  GetCampaignsModel,
  GetCityListModel,
  GetDistrictListModel,
  GetNewsModel,
  GetStreetListModel,
  GetTownListModel,
  GetTownListOtherModel,
  onLoginLogListItemModel,
} from 'Models';
import FaqModel from 'Models/GetFaqModel';
import { RootState } from 'Stores/index';
import { AccountState, activeUserAdressType } from './types';
import { newsItem } from 'Models/GetNewsModel';
import { OnUpdateOnlinePermissionBodyData } from 'Config/ServiceConfig';

export const initialState: AccountState = {
  userName: '',
  loading: false,
  error: null,
  step: 0,
  agreementContent: undefined,
  faq: undefined,
  loginLogList: undefined,
  campaigns: undefined,
  activeCampaign: undefined,
  activeUserAdress: undefined,
  isUserJobDialogOpen: false,
  isIbanDialogOpen: false,
  isPhoneNumberDialogOpen: false,
  userNewNumberForUpdate: {
    countryCode: undefined,
    cellPhone: undefined,
  },
  isMailDialogOpen: false,
  isMailInfoDialogOpen: false,
  emailValidationStep: 0,
  townList: undefined,
  otherTownList: undefined,
  cityList: undefined,
  districtList: undefined,
  streetList: undefined,
  news: undefined,
  activeNews: undefined,
  activeCategory: 0,
  isWarningDeleteAdressModalOpen: false,
  onUpdateCommercialStatus4MailResult: undefined,
  onUpdateOnlinePermissionResponse: undefined,
  userAcceptedNewVersion: false,
};

const ToastifySlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // Change Password
    onChangePasswordCustomerRequest: (
      state: AccountState,
      { payload }: PayloadAction<onChangePasswordCustomerReqBodyData>
    ) => {
      state.loading = true;
    },
    onChangePasswordCustomerRequestSuccess: (state: AccountState) => {
      state.loading = false;
    },
    onChangePasswordCustomerRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //GET AGREEMENT DETAILS
    onGetAgreementRequest: (state: AccountState, { payload }: PayloadAction<onGetAgreementContentReqBodyData>) => {
      state.loading = true;
    },
    onGetAgreementRequestSuccess: (state: AccountState, { payload }: PayloadAction<agreementType>) => {
      state.loading = false;
      state.agreementContent = payload;
    },
    onGetAgreementRequestFail: (state: AccountState) => {
      state.loading = false;
    },
    //GET FAQS
    onGetFaqRequest: (state: AccountState) => {
      state.loading = true;
    },
    onGetFaqRequestSuccess: (state: AccountState, { payload }: PayloadAction<FaqModel>) => {
      state.loading = false;
      state.faq = payload;
    },
    onGetFaqRequestFail: (state: AccountState) => {
      state.loading = false;
    },
    //GET LOGIN LOG LIST
    onGetLoginLogListRequest: (state: AccountState, { payload }: PayloadAction<onLoginLogListReqBodyData>) => {
      state.loading = true;
    },
    onGetLoginLogListRequestSuccess: (
      state: AccountState,
      { payload }: PayloadAction<Array<onLoginLogListItemModel>>
    ) => {
      state.loading = false;
      state.loginLogList = payload;
    },
    onGetLoginLogListRequestFail: (state: AccountState) => {
      state.loading = false;
    },
    getCampaignsRequest: (state: AccountState, { payload }: PayloadAction<campaignsReqBodyData>) => {
      state.loading = true;
    },
    getCampaignsRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetCampaignsModel>) => {
      state.loading = false;
      state.campaigns = payload.data.Campaigns;
    },
    getCampaignsRequestFail: (state: AccountState) => {
      state.loading = false;
    },
    setActiveCampaign: (state: AccountState, { payload }: PayloadAction<CampaignItem | undefined>) => {
      state.loading = false;
      state.activeCampaign = payload;
    },
    onGetCustomerInfoRequest: (state: AccountState, { payload }: PayloadAction<onGetCustomerInfoReqBodyData>) => {
      state.loading = true;
    },
    onGetCustomerInfoRequestSuccess: (state: AccountState) => {
      state.loading = false;
    },
    onGetCustomerInfoRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setActiveUserAdress: (state: AccountState, { payload }: PayloadAction<activeUserAdressType | undefined>) => {
      state.activeUserAdress = payload;
    },
    showHideIbanDialog: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isIbanDialogOpen = payload;
    },
    showHideUserJobDialog: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isUserJobDialogOpen = payload;
    },
    showHideUserPhoneDialog: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isPhoneNumberDialogOpen = payload;
    },
    setUserNewNumberForUpdate: (
      state: AccountState,
      { payload }: PayloadAction<{ countryCode?: string; cellPhone?: string }>
    ) => {
      state.userNewNumberForUpdate.cellPhone = payload.cellPhone;
      state.userNewNumberForUpdate.countryCode = payload.countryCode;
    },
    getCityListRequest: (state: AccountState, { payload }: PayloadAction<getCityListReqBodyData>) => {
      state.loading = true;
    },
    getCityListRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetCityListModel>) => {
      state.loading = false;
      state.cityList = payload.data.CityList;
    },
    getCityListRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTownListRequest: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.loading = true;
    },
    getTownListRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetTownListModel>) => {
      state.loading = false;
      state.townList = payload;
    },
    getTownListRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    showHideUserMailDialog: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isMailDialogOpen = payload;
    },
    showHideUserMailInfoDialog: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isMailInfoDialogOpen = payload;
    },
    setEmailValidationStep: (state: AccountState, { payload }: PayloadAction<number>) => {
      state.emailValidationStep = payload;
    },
    onConfirmEmailRequest: (state: AccountState, { payload }: PayloadAction<onConfirmEmailReqBodyData>) => {
      state.loading = true;
    },
    onConfirmEmailRequestSuccess: (state: AccountState) => {
      state.loading = false;
    },
    onConfirmEmailRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDistrictListRequest: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.loading = true;
    },
    getDistrictListRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetDistrictListModel>) => {
      state.loading = false;
      state.districtList = payload;
    },
    getDistrictListRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getStreetListRequest: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.loading = true;
    },
    getStreetListRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetStreetListModel>) => {
      state.loading = false;
      state.streetList = payload;
    },
    getStreetListRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getNewsRequest: (state: AccountState) => {
      state.loading = true;
    },
    getNewsRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetNewsModel>) => {
      state.loading = false;
      state.news = payload.data.NewsList;
    },
    getNewsRequestFail: (state: AccountState) => {
      state.loading = false;
    },
    setActiveNews: (state: AccountState, { payload }: PayloadAction<newsItem>) => {
      state.activeNews = payload;
    },
    setActiveCategory: (state: AccountState, { payload }: PayloadAction<number>) => {
      state.activeCategory = payload;
    },
    onUpdateCustomerAddressRequest: (
      state: AccountState,
      { payload }: PayloadAction<onUpdateCustomerAddressReqBodyData & { callback?: () => void }>
    ) => {
      state.loading = true;
    },
    onUpdateCustomerAddressRequestSuccess: (state: AccountState) => {
      state.loading = false;
    },
    onUpdateCustomerAddressRequestFail: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.loading = false;
    },
    showHideDeleteAdressWarningModal: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isWarningDeleteAdressModalOpen = payload;
    },
    getTownOtherListRequest: (state: AccountState, { payload }: PayloadAction<getTownListOtherReqBodyData>) => {
      state.loading = true;
    },
    getTownOtherListRequestSuccess: (state: AccountState, { payload }: PayloadAction<GetTownListOtherModel>) => {
      state.loading = false;
      state.otherTownList = payload.data.TownList;
    },
    getTownOtherListRequestFail: (state: AccountState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setTownList: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.townList = payload;
    },
    setTownOtherList: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.otherTownList = payload;
    },
    setDistrictList: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.districtList = payload;
    },
    setStreetList: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.streetList = payload;
    },
    setNormalizeAdressParameters: (state: AccountState) => state,
    resetAccountStore: (state: AccountState) => initialState,
    onUpdateCommercialStatus4MailRequest: (
      state: AccountState,
      { payload }: PayloadAction<CommonUpdateCommercialStatus4MailBodyData>
    ) => {
      state.loading = true;
    },
    onUpdateCommercialStatus4MailResponse: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.onUpdateCommercialStatus4MailResult = payload;
      state.loading = false;
    },
    onUpdateCommercialStatus4MailFail: (state: AccountState) => {
      state.onUpdateCommercialStatus4MailResult = 'error';
      state.loading = false;
    },
    onUpdateOnlinePermissionRequest: (
      state: AccountState,
      { payload }: PayloadAction<OnUpdateOnlinePermissionBodyData>
    ) => {
      state.loading = true;
    },
    onUpdateOnlinePermissionSuccess: (state: AccountState, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.onUpdateOnlinePermissionResponse = payload;
    },
    onUpdateOnlinePermissionFail: (state: AccountState) => {
      state.loading = false;
    },
    setUserAcceptedNewVersion: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.userAcceptedNewVersion = payload;
    },
    setUserAcceptedNewVersionV2: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.userAcceptedNewVersionV2 = payload;
    },
  },
});

export const storeAccount = (state: RootState) => state.account;

export const accountIsLoading = (state: RootState) => state.account.loading;

export const {
  onChangePasswordCustomerRequest,
  onChangePasswordCustomerRequestSuccess,
  onChangePasswordCustomerRequestFail,
  onGetAgreementRequest,
  onGetAgreementRequestSuccess,
  onGetAgreementRequestFail,
  onGetFaqRequest,
  onGetFaqRequestSuccess,
  onGetFaqRequestFail,
  onGetLoginLogListRequest,
  onGetLoginLogListRequestSuccess,
  onGetLoginLogListRequestFail,
  getCampaignsRequest,
  getCampaignsRequestSuccess,
  getCampaignsRequestFail,
  setActiveCampaign,
  onGetCustomerInfoRequest,
  onGetCustomerInfoRequestSuccess,
  onGetCustomerInfoRequestFail,
  setActiveUserAdress,
  showHideIbanDialog,
  showHideUserJobDialog,
  showHideUserPhoneDialog,
  setUserNewNumberForUpdate,
  getCityListRequest,
  getCityListRequestSuccess,
  getCityListRequestFail,
  getTownListRequest,
  getTownListRequestSuccess,
  getTownListRequestFail,
  showHideUserMailDialog,
  showHideUserMailInfoDialog,
  setEmailValidationStep,
  onConfirmEmailRequest,
  onConfirmEmailRequestSuccess,
  onConfirmEmailRequestFail,
  getDistrictListRequest,
  getDistrictListRequestSuccess,
  getDistrictListRequestFail,
  getStreetListRequest,
  getStreetListRequestSuccess,
  getStreetListRequestFail,
  getNewsRequest,
  getNewsRequestSuccess,
  getNewsRequestFail,
  setActiveNews,
  setActiveCategory,
  onUpdateCustomerAddressRequest,
  onUpdateCustomerAddressRequestSuccess,
  onUpdateCustomerAddressRequestFail,
  showHideDeleteAdressWarningModal,
  getTownOtherListRequest,
  getTownOtherListRequestSuccess,
  getTownOtherListRequestFail,
  setTownList,
  setTownOtherList,
  setDistrictList,
  setStreetList,
  setNormalizeAdressParameters,
  resetAccountStore,
  onUpdateCommercialStatus4MailRequest,
  onUpdateCommercialStatus4MailResponse,
  onUpdateCommercialStatus4MailFail,
  onUpdateOnlinePermissionRequest,
  onUpdateOnlinePermissionSuccess,
  onUpdateOnlinePermissionFail,
  setUserAcceptedNewVersion,
  setUserAcceptedNewVersionV2,
} = ToastifySlice.actions;

export default ToastifySlice.reducer;
