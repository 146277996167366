import { FC, memo } from 'react';
import { useLocalization } from 'Hooks';
import { Box } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { UserState, userStore } from 'Stores/User';
import { useSelector } from 'react-redux';

type UserCitizenshipNumberProps = {};

const UserCitizenshipNumber: FC<UserCitizenshipNumberProps> = memo(() => {
  const { web_label_id_number } = useLocalization();
  const userData: UserState = useSelector(userStore);
  const userCitizenshipNumber: string | undefined = userData.citizenshipNumber;
  return (
    <Box id="userCitizenshipNumber">
      <InfoItem title={web_label_id_number} value={userCitizenshipNumber} />
    </Box>
  );
});
export default UserCitizenshipNumber;
