import { onlineShoppingLoanBodyData } from 'Config/ServiceConfig';
import { axiosHelper } from 'Services/AxiosHelper';

const onLineShoppingLoan = async (data: onlineShoppingLoanBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      data,
      isFibaBankUrl: true,
    })
  ).data;

export default onLineShoppingLoan;
