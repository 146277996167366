import { FC } from 'react';
import { ChevronDarkRightIcon, TickIcon } from 'Components';
import { Box } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { maskCreditCardNumber } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  creditCardTransactionsStore,
  resetCommissionAmount,
  setActiveSelectedCreditCard,
  setIsUserAddedCreditCardWithMasterpass,
  setLoadAmount,
  setUserTemporaryCreditCard,
} from 'Stores/CreditCardTransactions';
import { resetFormError } from 'Stores/Form';
import classNames from 'classnames';
import { useCreditCardType } from 'Hooks';

import './SelectedCreditCard.scss';

type SelectedCreditCardProps = {
  creditCardItem: any;
  hide: Function;
  isDialog: boolean;
  logoUrl?: string;
  brandUrl?: string;
};

const SelectedCreditCard: FC<SelectedCreditCardProps> = ({ creditCardItem, hide, isDialog, logoUrl, brandUrl }) => {
  const dispatch = useDispatch();

  //To show green border check if addedTemporary card is the same with selected card
  const { userTempCreditCard, activeSelectedCreditCard } = useSelector(creditCardTransactionsStore);
  // Because of using the same component if logo and brand url doesnt come use this
  const { creditCardBrandUrl, creditCardLogoUrl } = useCreditCardType(creditCardItem);

  const decideIsSelected = () => {
    const activeCard = activeSelectedCreditCard.Value1.slice(0, 6) + activeSelectedCreditCard.Value1.slice(14, 16);
    const cardItem = creditCardItem?.Value1.slice(0, 6) + creditCardItem?.Value1.slice(14, 16);
    return activeCard === cardItem;
  };

  return (
    <div
      id="selectedCreditCard"
      onClick={() => {
        dispatch(setActiveSelectedCreditCard(creditCardItem));
        if (isDialog) {
          dispatch(resetCommissionAmount());
          dispatch(resetFormError());
          dispatch(setLoadAmount(0));
          dispatch(setUserTemporaryCreditCard(undefined));
          dispatch(setIsUserAddedCreditCardWithMasterpass(true));
        }
        hide();
      }}
      itemID="chooseCreditCardToUploadTLBtnTest"
    >
      <Box
        className={classNames({
          selectedCreditCard__container: true,
          'selectedCreditCard__container--active': decideIsSelected(),
          'selectedCreditCard--addedTempCard': userTempCreditCard && !isDialog,
        })}
      >
        <Box display="flex" alignItems="center">
          <Box marginRight={gutters.regular} width={66}>
            <img src={logoUrl ? logoUrl : creditCardLogoUrl} alt="" className="selectedCreditCard--cardLogo" />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" flex={1}>
            {creditCardItem?.Name && <Box className="selectedCreditCard--title">{creditCardItem?.Name}</Box>}
            {creditCardItem?.Value1 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="selectedCreditCard--cardNumber">{maskCreditCardNumber(creditCardItem?.Value1)}</Box>
                <span className="selectedCreditCard--dot" />
                <img className="selectedCreditCard--brand" src={brandUrl ? brandUrl : creditCardBrandUrl} alt="" />
              </Box>
            )}
          </Box>
        </Box>
        {isDialog && decideIsSelected() && <TickIcon />}
        {!isDialog && <ChevronDarkRightIcon />}
      </Box>
    </div>
  );
};

export default SelectedCreditCard;
