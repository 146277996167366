import { reverseBirthDate, generateLanguageParam } from 'Utils';
import { SignupSchemaInputs, ForgotPasswordSchemaInputs } from 'Validations';
import { AgreementListProps } from 'Views/Signup/Signup';

export const signupNormalizeIdentityStep = (values: SignupSchemaInputs, phoneNumber?: string, countryCode?: string) => {
  const { tcNo, birthDate } = values;

  return {
    CitizenshipNumber: tcNo,
    CountryCode: countryCode,
    Cellphone: phoneNumber,
    BirthDate: reverseBirthDate(birthDate),
    PropertyType: 4,
    OnlineCustomerLanguage: generateLanguageParam(),
  };
};

export const signupNormalizeInfoStep = (values: SignupSchemaInputs, phoneNumber?: string, countryCode?: string) => {
  const { tcNo, birthDate, mail, name, surname } = values;
  return {
    CitizenshipNumber: tcNo,
    Name: name,
    Surname: surname,
    BirthDate: reverseBirthDate(birthDate),
    CountryCode: countryCode,
    Mail: mail ? mail : null,
    Cellphone: phoneNumber,
    OnlineCustomerLanguage: generateLanguageParam(),
    PropertyType: 1,
  };
};

export const signupNormalizeInfoContactStep = (
  agreementList: AgreementListProps[],
  values: SignupSchemaInputs,
  phoneNumber?: string,
  countryCode?: string
) => {
  const { tcNo, birthDate, name, surname, mail, viaSms, viaMail, mail2, termsAccepted } = values;

  const generateOnlinePermissionMessage = () => {
    const permission = {
      // '1': agreementList[0]?.AgreementVersion,
      //'2': agreementList[0]?.AgreementVersion,
      '3': viaMail || viaSms ? agreementList.find((item) => item.AgreementType === 3)?.AgreementVersion : undefined,
      '4': termsAccepted ? agreementList.find((item) => item.AgreementType === 4)?.AgreementVersion : undefined,
      //'5': agreementList[3]?.AgreementVersion,
      '6': agreementList.find((item) => item.AgreementType === 6)?.AgreementVersion,
      '7': agreementList.find((item) => item.AgreementType === 7)?.AgreementVersion,
    };
    return permission;
  };
  const formData = {
    CitizenshipNumber: tcNo,
    Name: name,
    Surname: surname,
    BirthDate: reverseBirthDate(birthDate),
    Mail: mail ? mail : mail2 ? mail2 : null,
    CountryCode: countryCode,
    Cellphone: phoneNumber,
    OnlinePermissionMessage: generateOnlinePermissionMessage(),
    OnlineCustomerLanguage: generateLanguageParam(),
    PropertyType: 3,
    // Language: generateLanguageParam(),
  };
  return {
    ...formData,
    ...((viaMail || viaSms) && {
      CommercialStatusChannelType: viaSms && !viaMail ? 1 : !viaSms && viaMail ? 2 : 3,
    }),
  };
};

export const onSetPasswordNormalize = (
  values: SignupSchemaInputs | ForgotPasswordSchemaInputs,
  phoneNumber?: string,
  countryCode?: string
) => {
  const { password, rePassword } = values;
  return {
    CountryCode: countryCode,
    Cellphone: phoneNumber,
    Password: password,
    ConfirmPassword: rePassword,
    PasswordResetFlag: 6,
    OnlineCustomerLanguage: generateLanguageParam(),
  };
};
