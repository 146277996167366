import { FC, useState } from 'react';
import { DialogWithActions } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Radio, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';

import './MicroCreditItem.scss';
import { FibaBankLogoIcon } from 'Components/Svg';

type MicroCreditItemProps = {
  item: any;
  id: string;
  setId: Function;
};

const MicroCreditItem: FC<MicroCreditItemProps> = ({ item, id, setId }) => {
  const {
    web_micro_credit_bank_rate,
    web_micro_credit_campaign_detail,
    web_micro_credit_campaign_conditions,
    web_micro_credit_campaign_detail_desc,
    web_micro_credit_campaign_detail_desc2,
    web_micro_credit_campaign_detail_desc3,
    web_micro_credit_campaign_detail_desc4,
    web_micro_credit_interest_new,
    web_micro_credit_interest_old,
  } = useLocalization();

  const [activeApplication, setActiveApplicaton] = useState<boolean>(false);

  return (
    <div key={item.id} className="cardApplicationItem__item" itemID="applyCardBtnTest">
      <div className="cardApplicationItem__item__first-row">
        <Radio checked={id === item.id} color="primary" onClick={() => setId(item.id)} />
        <Typography variant="h3">
          <Box
            style={{ textTransform: 'capitalize' }}
            ml={gutters.regular}
            fontSize={fontSizes.regular}
            fontWeight={600}
          >
            <FibaBankLogoIcon />
          </Box>
          <Box
            style={{ textTransform: 'capitalize' }}
            ml={gutters.regular}
            fontSize={fontSizes.smaller}
            fontWeight="bold"
            onClick={() => setActiveApplicaton(true)}
          >
            {web_micro_credit_campaign_detail} {'>'}
          </Box>
        </Typography>
      </div>
      <Box>
        <Box fontSize={fontSizes.tiny} fontWeight={500}>
          {web_micro_credit_bank_rate}
        </Box>
        <Box
          style={{ textDecoration: 'line-through' }}
          color={colors.darkGray}
          fontSize={fontSizes.small}
          fontWeight="bold"
        >
          {'%' + web_micro_credit_interest_old}
        </Box>
        <Box color={colors.toastSuccessText} fontSize={fontSizes.small} fontWeight="bold">
          {'%' + web_micro_credit_interest_new}
        </Box>
      </Box>
      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: 'Tamam',
          handleClick: () => setActiveApplicaton(false),
        }}
        open={activeApplication}
        hide={() => () => setActiveApplicaton(false)}
        title={web_micro_credit_campaign_conditions}
        text={
          web_micro_credit_campaign_detail_desc +
          web_micro_credit_campaign_detail_desc2 +
          web_micro_credit_campaign_detail_desc3 +
          web_micro_credit_campaign_detail_desc4
        }
      />
    </div>
  );
};
export default MicroCreditItem;
