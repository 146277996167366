import React, { Dispatch, FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { radius } from 'Theme/Variables';
import './StepWrapper.scss';
import { ArrowLeftIcon } from 'Components';
import { scrollTop } from 'Utils';

type StepperProps = {
  children: React.ReactNode;
  handleBack?: {
    step: number;
    setStep?: any;
    setStepDispatch?: () => {
      payload: undefined;
      type: string;
    };
    onStepBack?: Function;
  };
  noStyle?: boolean;
  backIconClassName?: string;
};

const StepWrapper: FunctionComponent<StepperProps> = ({ children, handleBack, noStyle, backIconClassName }) => {
  return (
    <Box borderRadius={radius.regular} {...(!noStyle && { className: 'stepWrapper' })}>
      {!!handleBack && handleBack.step > 0 && (
        <ArrowLeftIcon
          onClick={() => {
            if (handleBack.setStep) {
              handleBack.onStepBack ? handleBack.onStepBack() : null;
              handleBack.setStep((prevState: any) => prevState - 1);
            } else {
              handleBack.setStepDispatch ? handleBack.setStepDispatch() : null;
            }
            scrollTop();
          }}
          className={backIconClassName}
        />
      )}
      {children}
    </Box>
  );
};

export default StepWrapper;
