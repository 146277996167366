import { axiosHelper } from 'Services/AxiosHelper';
import { OnGetCardListModel } from 'Models';
import { getRegisteredCardTransactionBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnRegisteredCardList';

const getRegisteredCardTransaction = async (
  params: getRegisteredCardTransactionBodyData
): Promise<OnGetCardListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default getRegisteredCardTransaction;
