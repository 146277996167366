import * as yup from 'yup';
import { errorMessages } from 'Config';

export const PrePasswordSchema = yup.lazy(({ countryCode }) =>
  yup.object({
    phone: yup
      .string()
      .required(errorMessages.require)
      .min(countryCode === '90' ? 12 : 17, countryCode === '90' ? errorMessages.minPhone : ''),
    countryCode: yup.string().required(),
    tcNo: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  })
);
