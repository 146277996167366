import {
  BigPlusIcon,
  ArrowUpIcon,
  DashboardLayout,
  AbonmanIcon,
  BigTransactionIcon,
  TimeIcon,
  HesCodeIcon,
  AbonmanCardIcon,
} from 'Components';
import { FC, memo, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardTransactionsState,
  mbGetOtherCardListRequest,
  onGetCardListRequest,
  resetAddCardStep,
  setAddCardStep,
  setDigitalCardActive,
  setIsUserAddingNewCardInTransactions,
  setIsUserInUploadSubscriptionProcess,
  setIsUserInUploadTLProcess,
  setLoadBalanceWithoutMasterpass,
  setNormalizeCardTransactionStates,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { creditCardTransactionsStore, onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { UserState, userStore } from 'Stores/User';
import { MasterPassAccountStatusEnum, MasterPassTokenEnum } from 'Utils';
import { masterPassListCardNormalize } from 'Normalize';

import './Transactions.scss';
import { balanceTransferReset } from 'Stores/BalanceTransfer';
import { FavoriteIcon } from 'Components/Svg';

type TransactionsProps = {};

type TransactionItemType = {
  title: string;
  Icon: any;
  onClick: () => void;
  itemID?: string;
  isNew?: string;
};

const Transactions: FC<TransactionsProps> = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const { creditCardList, masterPassAccountStatus } = useSelector(creditCardTransactionsStore);
  const userCards: cardItemParams[] = cardData?.cards ?? [];
  const userOtherCards: cardItemParams[] = cardData?.otherCards;
  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const isMiniApp = userData?.isMiniAppLogin;

  const userSubscriptionLoadableCards = userCards
    ? userCards.filter((item) => item.CardStatusCode === 'A' && item.SeasonTicketLoadable === true)
    : [];

  const {
    web_link_btn_islemler_homepage,
    web_transactions_card_management,
    web_transactions_others,
    web_transactions_add_new_card,
    web_transactions_upload_tl,
    web_transactions_upload_subscription,
    web_transactions_transfer_money,
    web_transactions_automatically_instructions,
    web_transactions_hes_code,
    web_transactions_card_application,
    web_transactions_card_applications,
    web_transactions_my_automatically_instructions,
    web_micro_credit_transaction_type,
    web_micro_credit_on_off,
    new_feature,
  } = useLocalization();

  useEffect(() => {
    dispatch(setLoadBalanceWithoutMasterpass(false));
    dispatch(setNormalizeCardTransactionStates());
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CARD_LIST,
        request: {
          data: masterPassListCardNormalize(),
        },
      })
    );
    dispatch(
      onGetCardListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      mbGetOtherCardListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
  }, []);

  const cardManagements: TransactionItemType[] = [
    {
      title: web_transactions_add_new_card,
      Icon: BigPlusIcon,
      onClick: () => {
        history.push(routePath.addCard);
        dispatch(resetAddCardStep());
      },
      itemID: 'addNewCardBtnTest',
    },
    {
      title: web_transactions_upload_tl,
      Icon: ArrowUpIcon,
      onClick: () => {
        dispatch(setIsUserInUploadTLProcess(true));
        /* if (userCards.length === 0 && !userOtherCards) {
          dispatch(setAddCardStep(2));
          history.push(routePath.addCard);
          dispatch(setIsUserAddingNewCardInTransactions(true));
        } else */
        if (masterPassAccountStatus === MasterPassAccountStatusEnum.NO_LINKED) {
          dispatch(setDigitalCardActive());
          history.push(routePath.addBalanceToCard);
        } else if (creditCardList?.length === 0) {
          history.push(routePath.addCreditCard);
        } else {
          dispatch(setDigitalCardActive());
          history.push(routePath.addBalanceToCard);
        }
      },
      itemID: 'uploadTLBtnTest',
    },
    {
      title: web_micro_credit_transaction_type,
      Icon: ArrowUpIcon,
      onClick: () => {
        history.push(routePath.microCreditOperation);
      },
      itemID: 'webMimcroCreditBtnTest',
    },
    {
      title: web_transactions_upload_subscription,
      Icon: AbonmanIcon,
      onClick: () => {
        dispatch(setIsUserInUploadSubscriptionProcess(true));
        if (userSubscriptionLoadableCards.length === 0) {
          dispatch(setAddCardStep(2));
          history.push(routePath.addCard);
          dispatch(setIsUserAddingNewCardInTransactions(true));
        } else if (masterPassAccountStatus === MasterPassAccountStatusEnum.NO_LINKED) {
          history.push(routePath.addBalanceToCard);
        } else if (creditCardList?.length === 0) {
          history.push(routePath.addCreditCard);
        } else {
          history.push(routePath.uploadSubscription);
        }
      },
      itemID: 'uploadsubscriptionBtnTest',
    },
    {
      title: web_transactions_transfer_money,
      Icon: BigTransactionIcon,
      onClick: () => {
        dispatch(balanceTransferReset());
        history.push(routePath.balanceTransfer);
      },
    },
    // Faz-2 İçin saklanmaktadır
    /*   {
      title: web_transactions_automatically_instructions,
      Icon: TimeIcon,
      onClick: () => history.push(routePath.automaticInstruction),
    },
    {
      title: web_transactions_my_automatically_instructions,
      Icon: TimeIcon,
      onClick: () => history.push(routePath.userAutomaticInstructions),
    }, */
  ];

  const others: TransactionItemType[] = [
    // {
    //   title: web_transactions_hes_code,
    //   Icon: HesCodeIcon,
    //   onClick: () => history.push(routePath.hes),
    //   itemID: 'hesCodeUpdateBtnTest',
    // },
    {
      title: web_transactions_card_application,
      Icon: AbonmanCardIcon,
      onClick: () => history.push(routePath.cardApplication),
      itemID: 'cardApplyBtnTest',
    },
    {
      title: web_transactions_card_applications,
      Icon: AbonmanCardIcon,
      onClick: () => history.push(routePath.cardApplications),
      itemID: 'cardApplicationsBtnTest',
    },
  ];

  return (
    <DashboardLayout>
      <div id="transactions">
        <Typography variant="h6" align="center" className="transactions__mobileTitle">
          <Box fontWeight={600} textAlign="center" fontSize={fontSizes.regularBig} marginBottom={gutters.regular}>
            {web_link_btn_islemler_homepage}
          </Box>
        </Typography>
        <Box className="transactions__title">{web_transactions_card_management}</Box>
        <Grid container spacing={1}>
          {cardManagements.map(
            ({ title, Icon, onClick, itemID, isNew }, index) => (
              /*belbim tarafından abonman yükle butonunun tanımlanan cms keyi ile kontrolünün sağlanması
                için gelen talep doğrultusunda aşağıdaki geliştirilme yapılmıştı. Aynı durum tekrar istenirse
                yorum satırı açılabilir.
              */

              // (web_micro_credit_on_off == '0' && itemID === 'webMimcroCreditBtnTest') ||
              // (web_micro_credit_on_off == '1' && itemID === 'uploadsubscriptionBtnTest') ? (
              //   ''
              // ) : (
              <TransactionItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} isNew={isNew} />
            )
            //)
          )}
        </Grid>
        <Box className="transactions__title transactions__others">{web_transactions_others}</Box>
        <Grid container spacing={1}>
          {others.map(({ title, Icon, onClick, itemID }, index) =>
            (isMiniApp && itemID === 'cardApplyBtnTest') || (isMiniApp && itemID === 'cardApplicationsBtnTest') ? (
              ''
            ) : (
              <TransactionItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} />
            )
          )}
        </Grid>
      </div>
    </DashboardLayout>
  );
});

export default Transactions;

const TransactionItem: FC<TransactionItemType> = memo(({ title, Icon, onClick, itemID, isNew }) => {
  return (
    <Grid item onClick={onClick} xs={6} sm={3} lg={2}>
      <div className="transactions__item" style={{ position: 'relative' }}>
        {isNew ? (
          <Box color={colors.errorText} style={{ position: 'absolute', zIndex: 99, top: 10, right: 8 }}>
            <Box style={{ position: 'absolute', zIndex: 99 }}>{isNew}</Box>
            <Box style={{ zIndex: 99, marginLeft: 35 }}>
              <FavoriteIcon />
            </Box>
          </Box>
        ) : null}

        <Box textAlign="center">
          <Icon />
        </Box>
        <Typography variant="h6" align="center">
          <Box fontWeight={600} fontSize={fontSizes.small} className="transactions__item__title">
            {title}
          </Box>
        </Typography>
      </div>
    </Grid>
  );
});
