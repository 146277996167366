import * as React from 'react';

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="125"
      height="125"
      viewBox="0 0 125 125"
      {...props}
    >
      <defs>
        <path
          id="7zuq1oc6ma"
          d="M98.958 20.833H26.042c-8.63 0-15.625 6.996-15.625 15.625v52.084c0 8.63 6.995 15.625 15.625 15.625h72.916c8.63 0 15.625-6.996 15.625-15.625V36.458c0-8.63-6.995-15.625-15.625-15.625zm0 10.417L65.104 54.531c-1.611.93-3.597.93-5.208 0L26.042 31.25h72.916z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-98.000000, -86.000000) translate(98.000000, 86.000000)">
            <path d="M0 0H125V125H0z" />
            <mask id="o97bgbxxab" fill="#fff">
              <use xlinkHref="#7zuq1oc6ma" />
            </mask>
            <g fill="#EBEBED" mask="url(#o97bgbxxab)">
              <path d="M0 0H125V125H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MailIcon;
