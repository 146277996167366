import { FC, useState, useEffect, memo } from 'react';
import { ChevronDownIcon, DashboardLayout, DialogWithActions, MaskedTextField, FormErrorWrapper } from 'Components';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { useErrorMessage, useLocalization, useModal } from 'Hooks';
import { FormWrapper } from 'Components';
import { formikGlobalConfig, formNames, inputMaskTypes } from 'Config';
import { Formik } from 'formik';
import { AddAdressSchema, AddAdressInitialValues } from 'Validations';
import { gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { DialogCity } from './DialogCity';
import { onInıtDataStore } from 'Stores/App';
import {
  AccountState,
  getDistrictListRequest,
  getStreetListRequest,
  getTownListRequest,
  getTownOtherListRequest,
  onUpdateCustomerAddressRequest,
  showHideDeleteAdressWarningModal,
  storeAccount,
} from 'Stores/Account';
import { cityListItem } from 'Models/GetCityListModel';
import { DialogAdressType } from './DialogAdressType';
import { DialogDistrict } from './DialogDistrict';
import { DialogTown } from './DialogTown';
import { DialogStreet } from './DialogStreet';
import { citiesData } from './DialogCity/DialogCity';
import { adressTypeData } from './DialogAdressType/DialogAdressType';
import { townListItem } from 'Models/GetTownListModel';
import { districtItemProps } from './DialogDistrict/DialogDistrict';
import { streetItemProp } from './DialogStreet/DialogStreet';
import classNames from 'classnames';
import { UserState, userStore } from 'Stores/User';
import { addCustomerAdressNormalize, deleteCustomerAdressNormalize, updateCustomerAdressNormalize } from 'Normalize';
import { adressTransactionTypes } from './constant';
import { townOtherListItem } from 'Models/GetTownListOtherModel';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';

import './UserAdressDetail.scss';
import sanitizeHtml from 'Utils/SanitizeHtml';

type AdressListProps = {
  AddressCode: string;
  AddressCodeDesc: string;
};

type UserAdressDetailProps = {
  userMustHaveContactAddress?: boolean;
};

type AdressListParams = RouteComponentProps<{}, StaticContext, UserAdressDetailProps>;

const UserAdressDetail: FC<AdressListParams> = memo(({ location: { state } }) => {
  const {
    web_btn_kaydet,
    web_address_type,
    web_address_city,
    web_address_district,
    web_address_avenue,
    web_address_detail_info,
    web_address_post_code,
    web_add_address_title,
    web_btn_vazgec,
    web_delete_address_popup_title,
    web_delete_address_popup_desc,
    web_delete_address,
    web_address_town,
    web_btn_delete_address,
    web_communication_adress,
  } = useLocalization();

  const dispatch = useDispatch();

  const history = useHistory();
  const store: any = history?.location?.state && history?.location?.state;
  const accountStore: AccountState = useSelector(storeAccount);
  const initData = useSelector(onInıtDataStore);
  const Custody = store?.Custody;
  const stepData = store?.stepData;
  const userData: UserState = useSelector(userStore);
  const isWarningModalOpen = accountStore.isWarningDeleteAdressModalOpen;

  const customerNumber = userData.customerNumber;
  const sessionToken = userData.sessionToken;

  const userActiveAdress = accountStore?.activeUserAdress;
  const adressList: AdressListProps[] = initData.AdressList;

  const cities: cityListItem[] | undefined = accountStore.cityList;
  const otherTownList: townOtherListItem[] | undefined = accountStore.otherTownList;
  const townList: townListItem[] | undefined = accountStore.townList;
  const district: districtItemProps[] | undefined = accountStore.districtList;

  const isAdressTypeRequiredFromCardApplication = state?.userMustHaveContactAddress;

  // İnitial Values

  const initialAdressType = adressList.find(
    (item: AdressListProps) =>
      Number(item.AddressCode) ===
      (!!isAdressTypeRequiredFromCardApplication ? 9 : Number(userActiveAdress?.adressType))
  );
  const initialCity = cities && cities.find((item: cityListItem) => item.CityId === userActiveAdress?.cityCode);
  const initialTown =
    townList &&
    townList.find(
      (item: townListItem) =>
        item.ilceAdi.toLocaleLowerCase('tr-TR').trim() === userActiveAdress?.town.toLocaleLowerCase('tr-TR').trim()
    );
  const initialDistrict =
    district &&
    district.find(
      (item: districtItemProps) =>
        item.mahalleAdi.toLocaleLowerCase('tr-TR').trim() ===
        userActiveAdress?.district.toLocaleLowerCase('tr-TR').trim()
    );

  // Buradaki kodu görünce aklında kötü şeyler geçirme hepsi belbim backendinden kaynaklı Dataları farklı servisten cekıp string esleştirip farklı servisten cektiğimiz kod numarası gönderilmektedir.

  const [selectedAdressType, setSelectedAdressType] = useState<any>();
  const [selectedCityCode, setSelectedCityCode] = useState<any>();

  const { getError, getErrorMessage } = useErrorMessage();

  const { show: showAdressType, hide: hideAdressType, isVisible: isVisibleAdressType } = useModal();
  const { show: showCity, hide: hideCity, isVisible: isVisibleCity } = useModal();
  const { show: showDistrict, hide: hideDistrict, isVisible: isVisibleDistrict } = useModal();
  const { show: showTown, hide: hideTown, isVisible: isVisibleTown } = useModal();
  const { show: showStreet, hide: hideStreet, isVisible: isVisibleStreet } = useModal();

  useEffect(() => {
    if (!!initialCity) {
      dispatch(getTownListRequest(initialCity.CityId));
      setSelectedCityCode(initialCity.CityId);
    }
    setSelectedAdressType(String(initialAdressType?.AddressCode));
  }, []);

  useEffect(() => {
    if (!!initialTown) {
      dispatch(getDistrictListRequest(initialTown.kayitNo.toString()));
    }
    dispatch(
      getTownOtherListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CityId: selectedCityCode,
      })
    );
  }, [initialTown, selectedCityCode]);

  useEffect(() => {
    if (!!initialDistrict) {
      dispatch(getStreetListRequest(initialDistrict.kayitNo.toString()));
    }
  }, [dispatch, initialDistrict]);

  const handleSubmit = (values: any) => {
    const otherTownCode = otherTownList?.find(
      (item: townOtherListItem) => item.TownName.toLocaleLowerCase('tr-TR') === values.town.toLocaleLowerCase('tr-TR')
    );

    // Backend decided to use two different service to add adress
    if (!userActiveAdress) {
      dispatch(
        onUpdateCustomerAddressRequest(
          addCustomerAdressNormalize(
            values,
            customerNumber,
            sessionToken,
            parseInt(selectedCityCode),
            otherTownCode?.TownName,
            otherTownCode?.TownId,
            !!isAdressTypeRequiredFromCardApplication ? '9' : selectedAdressType,
            adressTransactionTypes.addAdress,
            Custody,
            stepData
          )
        )
      );
    } else {
      dispatch(
        onUpdateCustomerAddressRequest(
          updateCustomerAdressNormalize(
            values,
            customerNumber,
            sessionToken,
            parseInt(selectedCityCode),
            otherTownCode?.TownId,
            selectedAdressType,
            adressTransactionTypes.updatedAdress,
            Custody,
            stepData
          )
        )
      );
    }
  };

  const handleDeleteAdress = () => {
    dispatch(
      onUpdateCustomerAddressRequest(
        deleteCustomerAdressNormalize(
          customerNumber,
          sessionToken,
          userActiveAdress?.adressType,
          adressTransactionTypes.deleteAdress
        )
      )
    );
  };

  return (
    <DashboardLayout backAction={() => history.goBack()}>
      <div id="userAdressDetail">
        <FormErrorWrapper formName={formNames.addUserAdress} resetFormOnRouteChange>
          <Typography variant="h3" align="center">
            <Box className="userAdressDetail__title">
              {userActiveAdress ? web_communication_adress : web_add_address_title}
            </Box>
          </Typography>
          {/** "9" iletişim Adresi */}
          <Formik
            initialValues={AddAdressInitialValues(
              initialAdressType?.AddressCodeDesc,
              userActiveAdress && initialCity?.CityName,
              userActiveAdress?.town.toLocaleLowerCase('tr-TR'),
              userActiveAdress?.district.toLocaleLowerCase('tr-TR'),
              userActiveAdress?.street.toLocaleLowerCase('tr-TR'),
              userActiveAdress?.adressDetail.toLocaleLowerCase('tr-TR'),
              userActiveAdress?.postalCode
            )}
            validationSchema={AddAdressSchema}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                setFieldValue,
                values: { adressType, city, town, district, street, adressDetail, postCode },
                errors: {
                  adressType: errorAdressType,
                  city: errorCity,
                  town: errorTown,
                  district: errorDistrict,
                  street: errorStreet,
                  adressDetail: errorAdressDetail,
                  postCode: errorPostCode,
                },
                touched: {
                  adressType: touchedAdressType,
                  city: touchedCity,
                  town: touchedTown,
                  district: touchedDistrict,
                  street: touchedStreet,
                  adressDetail: touchedAdressDetail,
                  postCode: touchedPostCode,
                },
              } = formikProps;

              const fields = [
                {
                  index: 0,
                  name: 'adressType',
                  value: adressType,
                  error: errorAdressType,
                  touched: touchedAdressType && !adressType,
                  arrowDown: true,
                  label: web_address_type,
                  isDisabled: !!userActiveAdress || !!isAdressTypeRequiredFromCardApplication,
                  onClick: () => {
                    !!isAdressTypeRequiredFromCardApplication || userActiveAdress ? undefined : showAdressType();
                  },
                  itemID: 'adressTypeInputTest',
                },
                {
                  index: 1,
                  name: 'city',
                  value: city,
                  error: errorCity,
                  touched: touchedCity && !city,
                  arrowDown: true,
                  label: web_address_city,
                  isDisabled: false,
                  onClick: () => {
                    showCity();
                  },
                  itemID: 'adressCityInputTest',
                },
                {
                  index: 2,
                  name: 'town',
                  value: town,
                  error: errorTown,
                  touched: touchedTown && !town,
                  arrowDown: true,
                  label: web_address_town,
                  isDisabled: !city,
                  onClick: () => {
                    city && showTown();
                  },
                  itemID: 'adressTownInputTest',
                },
                {
                  index: 3,
                  name: 'district',
                  value: district,
                  error: errorDistrict,
                  touched: touchedDistrict && !district,
                  arrowDown: true,
                  label: web_address_district,
                  isDisabled: !town,
                  onClick: () => {
                    town && showDistrict();
                  },
                  itemID: 'adressDistrictInputTest',
                },
                {
                  index: 4,
                  name: 'street',
                  value: street,
                  error: errorStreet,
                  touched: touchedStreet && !street,
                  arrowDown: true,
                  label: web_address_avenue,
                  isDisabled: !district,
                  onClick: () => {
                    district && showStreet();
                  },
                  itemID: 'adressStreetInputTest',
                },
              ];

              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  <Box>
                    {fields.map((item: any) => (
                      <Box
                        marginBottom={gutters.small}
                        onClick={(e) => {
                          e.stopPropagation();
                          item.onClick();
                        }}
                        style={{ cursor: 'pointer' }}
                        key={item.index}
                        className={classNames({
                          'userAdressDetail--disabled': item.isDisabled,
                        })}
                      >
                        <TextField
                          variant="filled"
                          fullWidth
                          name={item.name}
                          value={item.value}
                          onBlur={handleBlur}
                          label={item.label}
                          InputLabelProps={{
                            shrink: item.value ? true : false,
                          }}
                          disabled
                          inputMode="text"
                          error={getError(item.error, item.touched)}
                          helperText={getErrorMessage(item.error, item.touched)}
                          InputProps={{
                            endAdornment: <ChevronDownIcon />,
                            style: { pointerEvents: 'none' },
                          }}
                          itemID={item.itemID}
                        />
                      </Box>
                    ))}
                    <DialogAdressType
                      isOpen={isVisibleAdressType}
                      hide={hideAdressType}
                      setSelectedValue={(adressItem: adressTypeData) => {
                        setSelectedAdressType(adressItem.AddressCode);
                        setFieldValue('adressType', adressItem.AddressCodeDesc);
                      }}
                      selectedValue={adressType}
                    />
                    <DialogCity
                      isOpen={isVisibleCity}
                      hide={hideCity}
                      setSelectedValue={(cityItem: citiesData) => {
                        setSelectedCityCode(cityItem.CityId);
                        setFieldValue('city', cityItem.CityName);
                        setFieldValue('town', '');
                        setFieldValue('district', '');
                        setFieldValue('street', '');
                      }}
                      selectedValue={city}
                    />
                    <DialogTown
                      isOpen={isVisibleTown}
                      hide={hideTown}
                      setSelectedValue={(townItem: townListItem) => {
                        setFieldValue('town', townItem?.ilceAdi?.toLocaleLowerCase('tr-TR'));
                        setFieldValue('district', '');
                        setFieldValue('street', '');
                      }}
                      selectedValue={town}
                    />
                    <DialogDistrict
                      isOpen={isVisibleDistrict}
                      hide={hideDistrict}
                      setSelectedValue={(districtItem: districtItemProps) => {
                        setFieldValue('district', districtItem?.mahalleAdi?.toLocaleLowerCase('tr-TR'));
                        setFieldValue('street', '');
                      }}
                      selectedValue={district}
                    />
                    <DialogStreet
                      isOpen={isVisibleStreet}
                      hide={hideStreet}
                      setSelectedValue={(streetItem: streetItemProp) => {
                        setFieldValue('street', streetItem?.csbmAdi?.toLocaleLowerCase('tr-TR'));
                      }}
                      selectedValue={street}
                    />
                    <Box marginBottom={gutters.small}>
                      <TextField
                        className="userAdressDetail__adressDetail"
                        fullWidth
                        variant="filled"
                        name="adressDetail"
                        inputProps={{ maxLength: 300, style: { minHeight: 70 } }}
                        value={adressDetail}
                        onChange={(e) => setFieldValue('adressDetail', sanitizeHtml(e.target.value))}
                        onBlur={handleBlur}
                        label={web_address_detail_info}
                        inputMode="text"
                        multiline
                        // rowsMax={3}
                        // rows={4}
                        error={getError(errorAdressDetail, touchedAdressDetail)}
                        helperText={getErrorMessage(errorAdressDetail, touchedAdressDetail)}
                        itemID="adressDetailInputTest"
                      />
                    </Box>
                    <Box marginBottom={gutters.big}>
                      <MaskedTextField
                        variant="filled"
                        fullWidth
                        name="postCode"
                        mask={inputMaskTypes.postCode}
                        value={postCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={web_address_post_code}
                        error={getError(errorPostCode, touchedPostCode)}
                        helperText={getErrorMessage(errorPostCode, touchedPostCode)}
                        className="userAdressDetail__postCode"
                        itemID="adressPostCodeInputTest"
                      />
                    </Box>
                    <Button variant="contained" color="secondary" type="submit" fullWidth itemID="addUserAdressBtnTest">
                      {web_btn_kaydet}
                    </Button>
                    {userActiveAdress && (
                      <Box marginTop={gutters.small}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={() => dispatch(showHideDeleteAdressWarningModal(true))}
                          itemID="deleteUserAdressBtnTest"
                        >
                          {web_btn_delete_address}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </FormWrapper>
              );
            }}
          </Formik>

          <DialogWithActions
            closeIcon
            title={web_delete_address_popup_title}
            text={web_delete_address_popup_desc}
            primaryButton={{
              label: web_delete_address,
              handleClick: () => handleDeleteAdress(),
            }}
            secondaryButton={{
              label: web_btn_vazgec,
              handleClick: () => dispatch(showHideDeleteAdressWarningModal(false)),
            }}
            hide={() => dispatch(showHideDeleteAdressWarningModal(false))}
            open={isWarningModalOpen}
            alignTitle="left"
            textMarginBottom={gutters.big}
          />
        </FormErrorWrapper>
      </div>
    </DashboardLayout>
  );
});
export default UserAdressDetail;
