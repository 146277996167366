import { Link, Box } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { FC } from 'react';
import { fontSizes } from 'Theme/Variables';
import classNames from 'classnames';
import { Avatar } from 'Components';
import { useSelector } from 'react-redux';
import { userStore } from 'Stores/User/slices';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './ProfileAvatar.scss';

type ProfileAvatarProps = {
  isHomePage?: boolean;
};

const ProfileAvatar: FC<ProfileAvatarProps> = ({ isHomePage }) => {
  const history = useHistory();

  const { web_hesabım_profil_homepage } = useLocalization();

  const userData = useSelector(userStore);
  const isResetPassword = userData?.resetPassword;

  const name = get(userData, 'name');
  const surname = get(userData, 'surname');

  return (
    <div
      id="profile"
      onClick={() => (isResetPassword ? undefined : history.push(routePath.myAccount))}
      itemID="navigateAccountBtnTest"
    >
      <Box
        display="flex"
        className={classNames({
          'profileAvatar--blue': isHomePage,
          'profileAvatar--white': !isHomePage,
        })}
      >
        <Avatar />
        <Box fontSize={fontSizes.regular}>
          <Box display="flex">
            <Box className="user--name" fontWeight="bold">
              {name?.toLocaleLowerCase('tr-TR')}
            </Box>
            <Box className="user--name" fontWeight="bold">
              {surname?.toLocaleLowerCase('tr-TR')}
            </Box>
          </Box>
          <Box fontSize={fontSizes.small} fontWeight="bold">
            <Link underline="none">{web_hesabım_profil_homepage}</Link>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default ProfileAvatar;
