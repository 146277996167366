export const getCommissionByBinNumberNormalize = (
  loadAmount: number,
  binNumber?: string,
  cardAmount?: number,
  customerNumber?: number,
  sessionToken?: string
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    BinNumber: binNumber,
    LoadAmount: loadAmount,
    CardAmount: cardAmount,
    RefTrnType: '1',
  };
  return formValues;
};
