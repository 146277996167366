import { FC, Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import {
  getCardTransactionListNormalize,
  registerCardTransactionNormalize,
  registeredCardTransactionDetailNormalize,
} from 'Normalize';
import { useDispatch, useSelector } from 'react-redux';
import { userStore } from 'Stores/User';
import { StaticContext } from 'react-router';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { ListEmpty } from './ListEmpty';
import { ListHeader } from './ListHeader';
import { TransactionDetail } from './TransactionDetail';
import { CardTransactionListTypeEnum } from 'Utils';
import { RouteComponentProps } from 'react-router';
import { cardItemDetailType } from 'Views/CardDetails/constant';
import { LastTransactionItem } from 'Views/CardDetails/CardTransactions/LastTransactionItem';
import {
  onGetCardTransactionListRequest,
  onRegisterCardTransactionRequest,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { FilterWithDialog } from './FilterWithDialog';
import { addFilterSchema } from 'Validations';
import { Pagination } from './Pagination';

import './CardTransactionHistory.scss';

type CardTransactionHistoryParam = {} & cardItemDetailType;

type CardTransactionProps = RouteComponentProps<{}, StaticContext, CardTransactionHistoryParam>;

const CardTransactionHistory: FC<CardTransactionProps> = ({ location: { state } }) => {
  const dispatch = useDispatch();
  const activeCard = state;

  const {
    web_title_card_transactions,
    web_title_transaction_detail_popup,
    web_transaction_total_pages,
    web_max_transactions,
    web_dijital_account_transactions,
  } = useLocalization();

  const userData = useSelector(userStore);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [transactionType, setTransactionType] = useState<CardTransactionListTypeEnum>(
    activeCard?.isOtherCard ? CardTransactionListTypeEnum.LOAD : CardTransactionListTypeEnum.ALL
  );

  // web_max_transactions keyi cmsten kontrol ediliyor 6 ay ise default 6 ay 9 ise 9 ay
  const [historyType, setHistoryType] = useState<number>(7);

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();

  const cardTransactionData = useSelector(storeCardTransactions);
  const totalPageCount = cardTransactionData?.paginationCount;
  const cardTransactions = cardTransactionData?.activeCardTransactions;

  useEffect(() => {
    if (!state) {
      dispatch(
        onGetCardTransactionListRequest(
          getCardTransactionListNormalize({
            CustomerNumber: customerNumber,
            SessionToken: sessionToken,
            PageIndex: page,
            TrnType: transactionType,
            beforeValue: historyType,
            StartDate: startDate && startDate?.toString(),
            EndDate: endDate && endDate?.toString(),
            MinTrnAmount: min,
            MaxTrnAmount: max,
          })
        )
      );
      return;
    }

    if (state.isOtherCard) {
      dispatch(
        onRegisterCardTransactionRequest(
          registeredCardTransactionDetailNormalize({
            MifareId: activeCard.MifareId,
            CustomerNumber: customerNumber,
            SessionToken: sessionToken,
            PageIndex: page,
            beforeValue: historyType,
            StartDate: startDate && startDate?.toString(),
            EndDate: endDate && endDate?.toString(),
            MinTrnAmount: min,
            MaxTrnAmount: max,
          })
        )
      );
    } else {
      dispatch(
        onGetCardTransactionListRequest(
          getCardTransactionListNormalize({
            CardId: activeCard.CardId,
            CustomerNumber: customerNumber,
            SessionToken: sessionToken,
            PageIndex: page,
            TrnType: transactionType,
            beforeValue: historyType,
            StartDate: startDate && startDate?.toString(),
            EndDate: endDate && endDate?.toString(),
            MinTrnAmount: min,
            MaxTrnAmount: max,
          })
        )
      );
    }
  }, [page, transactionType, historyType, startDate, endDate, min, max]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [transactionType, historyType, startDate, endDate, min, max, totalPageCount]);

  const handleFilterChange = (values: typeof addFilterSchema) => {
    values.startAtDate ? setStartDate(values.startAtDate) : setStartDate(undefined);
    values.endDate ? setEndDate(values.endDate) : setEndDate(undefined);
    values.min ? setMin(values.min) : setMin(undefined);
    values.max ? setMax(values.max) : setMax(undefined);
    if (values.startAtDate || values.endDate) {
      setHistoryType(0);
    } else if (!values.startAtDate && !values.endDate && historyType == 0) {
      setHistoryType(7);
    }
    setOpenFilter(false);
  };

  const calculateBadgeCount = () => {
    let count = 0;
    if (min || max) {
      count++;
    }
    if (startDate || endDate) {
      count++;
    }
    return count;
  };
  return (
    <DashboardLayout backButton>
      <Fragment>
        <TransactionDetail
          {...selectedTransaction}
          open={openDetail}
          handleClose={() => setOpenDetail(false)}
          dialogTitle={web_title_transaction_detail_popup}
          isOtherCard={!!activeCard?.isOtherCard}
        />
        <FilterWithDialog
          min={min}
          max={max}
          startAtDate={startDate}
          endDate={endDate}
          close={() => setOpenFilter(false)}
          open={openFilter}
          handleFilterChange={handleFilterChange}
        />
        <div id="cardTransactionHistory">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{web_dijital_account_transactions}</Box>
          </Typography>
          <ListHeader
            badgeCount={calculateBadgeCount()}
            openFilter={() => setOpenFilter(true)}
            transactionTypeValue={transactionType}
            changeTransactionType={setTransactionType}
            historyTypeValue={historyType}
            changeHistoryType={(value: any) => {
              setHistoryType(value);
              setStartDate(undefined);
              setEndDate(undefined);
            }}
            isOtherCard={!!activeCard?.isOtherCard}
          />
          <Box mt={gutters.regular}>
            {cardTransactions?.length &&
              cardTransactions.map((item, index) => {
                return (
                  <LastTransactionItem
                    key={index.toString()}
                    onHandleClick={() => {
                      setSelectedTransaction(item);
                      setOpenDetail(true);
                    }}
                    transactionItem={item}
                    itemID="lastTransactionsItemBtnTest"
                  />
                );
              })}
            {((cardTransactions?.length && cardTransactions.length === 0) || cardTransactions === null) && (
              <ListEmpty />
            )}

            {!((cardTransactions?.length && cardTransactions.length === 0) || cardTransactions === null) && (
              <Fragment>
                <Box display="flex" mt={gutters.large} mb={gutters.small} justifyContent="center">
                  <Pagination changeCurrentPage={setPage} currentPage={page} totalPageCount={totalPageCount} />
                </Box>
                {totalPageCount && totalPageCount > 0 ? (
                  <Typography variant="h3" align="center">
                    <Box color={colors.checkboxLabel} fontSize={fontSizes.regular}>
                      {web_transaction_total_pages.replace('{0}', totalPageCount ? String(totalPageCount) : '0')}
                    </Box>
                  </Typography>
                ) : (
                  ''
                )}
              </Fragment>
            )}
          </Box>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};

export default CardTransactionHistory;
