import { FC, useState } from 'react';
import { useLocalization, useQuery } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { Countdown, FormErrorWrapper, FormWrapper, InputOneCharBox, MasterpassLogo } from 'Components';
import { gutters } from 'Theme/Variables';
import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { masterPassOtpNormalize } from 'Normalize/MasterPassRequestNormalize';
import { FormState, resetFormError, storeFormError } from 'Stores/Form';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { StaticContext } from 'react-router';
import { MasterPassTokenEnum } from 'Utils';
import { formNames } from 'Config';

import './MobileAccountOtp.scss';

type MobileAccountOtpProps = RouteComponentProps<{}, StaticContext>;

const MobileAccountOtp: FC<MobileAccountOtpProps> = ({ location: { state } }: any) => {
  const dispatch = useDispatch();

  const data = JSON.parse(state.json);

  const referenceNo = data.referenceNo;
  const lang = state.lang;

  const { web_title_otp_screen, web_btn_accept } = useLocalization();

  const [value, setValue] = useState<string>('');
  const [isCountingDown, setIsCountingDown] = useState<boolean>(true);

  const formState: FormState = useSelector(storeFormError);
  const errorCode: string = formState?.errorCode;

  const onCountEnd = () => {
    setIsCountingDown(false);
    setValue('');
  };

  const onResetTimer = () => {
    setIsCountingDown(true);
    dispatch(resetFormError());
    dispatch(
      onGetMasterpassForMobileRequest({
        TokenType: MasterPassTokenEnum.RESEND_OTP,
        request: {
          data: masterPassOtpNormalize(''),
        },
      })
    );
  };

  const handleButtonClick = () => {
    if (isCountingDown) {
      dispatch(
        onGetMasterpassForMobileRequest({
          TokenType: MasterPassTokenEnum.OTP,
          request: {
            data: {
              validationCode: value,
              referenceNo: referenceNo,
              sendSms: 'N',
              sendSmsLanguage: lang,
              pinType: 'otp',
            },
          },
        })
      );
    }
  };

  return (
    <div id="mobileAccountOtp">
      <div className="mobileAccountOtp">
        <FormErrorWrapper formName={formNames.dialogOTP} resetFormOnRouteChange>
          <Typography variant="h5" align="center">
            <Box fontWeight={600} marginBottom={gutters.regular}>
              {web_title_otp_screen}
            </Box>
            <Box marginBottom={gutters.regular}>
              <MasterpassLogo width="100px" height="18px" />
            </Box>
          </Typography>
          <FormWrapper>
            <InputOneCharBox value={value} amount={6} setValue={setValue} disabled={!isCountingDown} />
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleButtonClick}
              itemID="dialogOtpBtnTest"
            >
              {web_btn_accept}
            </Button>
          </FormWrapper>
          <Countdown
            countdownSeconds={120}
            onCountEnd={onCountEnd}
            onResetTimer={onResetTimer}
            errorForReset={errorCode === '1150'}
          />
        </FormErrorWrapper>
      </div>
    </div>
  );
};
export default MobileAccountOtp;
