import { FC, memo } from 'react';
import { useLocalization } from 'Hooks';
import { Box } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { useSelector } from 'react-redux';
import { userStore } from 'Stores/User';
import { get } from 'lodash';
import moment from 'moment';

type UserBirthDateProps = {};

const UserBirthDate: FC<UserBirthDateProps> = memo(() => {
  const { web_label_birth_date } = useLocalization();
  const userData = useSelector(userStore);

  const birthDate = get(userData, 'birthDate');

  return (
    <Box id="userBirthDate">
      <InfoItem title={web_label_birth_date} value={moment(new Date(birthDate)).format('DD.MM.YYYY')} />
    </Box>
  );
});

export default UserBirthDate;
