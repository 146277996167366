import { FC, useEffect, useLayoutEffect } from 'react';
import { ArrowLeftIcon, Container } from 'Components';
import { DashboardFooter } from './DashboardFooter';
import { DashboardHeader } from './DashboardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { Box } from '@material-ui/core';
import { userStore } from 'Stores/User';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { MasterpassServicess } from 'Utils';
import { FormState, storeFormError } from 'Stores/Form';

import './DashboardLayout.scss';
import { AppState, storeApp } from 'Stores/App';

type DashboardLayoutProps = {
  children: JSX.Element;
  noContainer?: boolean;
  backButton?: boolean;
  marginTop?: string | number;
  backAction?: Function;
  ds?: boolean;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  noContainer,
  backButton,
  marginTop,
  backAction,
  ds,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(userStore);

  const history = useHistory();
  const sessionToken = userData?.sessionToken;
  const isResetPassword = userData?.resetPassword;

  const appState: AppState = useSelector(storeApp);
  const formState: FormState = useSelector(storeFormError);

  const error = formState.errorCode;
  const { agreementUpdateRequired } = appState;

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (agreementUpdateRequired) {
      history.push(routePath.home);
    }
    if (!sessionToken && !ds) {
      history.push(routePath.welcome);
    }
    if (MasterpassServicess.setClientId === undefined) {
      history.push(routePath.welcome);
    }
    if (isResetPassword) {
      history.push(routePath.changePassword);
    }
  }, []);

  useEffect(() => {
    scroll.scrollToTop({
      duration: 1,
      smooth: true,
    });
  }, [pathname, error]);

  return (
    <div id="dashboardLayout">
      <DashboardHeader />
      {noContainer ? (
        <Box className="dashboardLayout-childrenContainer" style={{ marginTop }}>
          {(backButton || backAction) && (
            <ArrowLeftIcon
              onClick={() => {
                if (backAction) {
                  backAction();
                } else {
                  dispatch(goBack());
                }
              }}
              className="dashboardLayout-childrenContainer__back"
            />
          )}
          {children}
        </Box>
      ) : (
        <Container>
          <Box className="dashboardLayout-childrenContainer" style={{ marginTop }}>
            {(backButton || backAction) && (
              <ArrowLeftIcon
                onClick={() => {
                  if (backAction) {
                    backAction();
                  } else {
                    dispatch(goBack());
                  }
                }}
                className="dashboardLayout-childrenContainer__back"
              />
            )}
            {children}
          </Box>
        </Container>
      )}
      <DashboardFooter />
    </div>
  );
};

export default DashboardLayout;
