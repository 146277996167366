import { PinsItemModel } from 'Models';

const calculateTagsCount = (pointList: PinsItemModel[]) => {
  const otherCount = pointList.filter((item) => item.Tags.includes(1)).length;
  const travelCount = pointList.filter((item) => item.Tags.includes(2)).length;
  const foodCount = pointList.filter((item) => item.Tags.includes(3)).length;
  const loadCount = pointList.filter((item) => item.Tags.includes(4)).length;
  const marketCount = pointList.filter((item) => item.Tags.includes(5)).length;

  return { otherCount, travelCount, foodCount, loadCount, marketCount };
};

export default calculateTagsCount;
