import { useLocalization } from 'Hooks';
import { GetCmsModel } from 'Models';
import { BinItem } from 'Models/GetBinListModel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CreditCardTransactionsState, creditCardTransactionsStore } from 'Stores/CreditCardTransactions';

const useCreditCardType = (creditCardItem: any) => {
  // Dont use useState it gets in loop
  const { cardbrand_v, cardbrand_m, cardbrand_j, cardbrand_t } = useLocalization();

  const localKeys: GetCmsModel['data']['CmsKeys'] = useLocalization();

  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const creditCardBinList: BinItem[] | undefined = creditCardData.creditCardBinList;

  let creditCardLogoUrl = '';
  let creditCardBrandUrl = '';
  let creditCardBrand = '';

  const decideBrand = (): string => {
    let cardBrand = '';
    creditCardBinList?.forEach((item: BinItem) => {
      if (item.BinNumber === creditCardItem?.Value1?.slice(0, 6)) {
        cardBrand = item?.CardBrand;
        // @ts-ignore: Unreachable code error
        creditCardLogoUrl = localKeys[item.BankName];
      }
    });
    return cardBrand;
  };

  const brands = {
    V: 'V',
    M: 'M',
    J: 'J',
    T: 'T',
  };
  switch (decideBrand()) {
    case brands.V:
      creditCardBrandUrl = cardbrand_v;
      creditCardBrand = brands.V;
      break;
    case brands.M:
      creditCardBrandUrl = cardbrand_m;
      creditCardBrand = brands.M;
      break;
    case brands.J:
      creditCardBrandUrl = cardbrand_j;
      creditCardBrand = brands.J;
      break;
    case brands.T:
      creditCardBrandUrl = cardbrand_t;
      creditCardBrand = brands.T;
      break;
    default:
      break;
  }
  return { creditCardBrandUrl, creditCardLogoUrl, creditCardBrand };
};

export default useCreditCardType;
