import * as React from 'react';

function AbonmanCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="abonmanCardIcon"
      {...props}
    >
      <defs>
        <path
          id="m0x34ivb5a"
          d="M18.333 0c2.762 0 5 2.239 5 5v23.333c0 2.762-2.238 5-5 5H5c-2.761 0-5-2.238-5-5V5c0-2.761 2.239-5 5-5h13.333zm3.334 7.49C17.02 2.766 10 5.063 10 11.852c0 6.788 11.667 14.196 11.667 14.196V7.49z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H40V40H0z" transform="translate(-414 -468) translate(346 448) translate(68 20)" />
              <g transform="translate(-414 -468) translate(346 448) translate(68 20) translate(8.333 3.333)">
                <mask id="gkfn2ytz6b" fill="#fff">
                  <use xlinkHref="#m0x34ivb5a" />
                </mask>
                <use fill="#383C52" fillRule="nonzero" xlinkHref="#m0x34ivb5a" />
                <g fill="currentColor" mask="url(#gkfn2ytz6b)">
                  <path d="M0 0H40V40H0z" transform="translate(-8.333 -3.333)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AbonmanCardIcon;
