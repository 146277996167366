import * as React from 'react';

function TopUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="gjbwtvqrta"
          d="M10 18.333c4.602 0 8.333-3.73 8.333-8.333 0-4.602-3.73-8.333-8.333-8.333-4.602 0-8.333 3.73-8.333 8.333 0 2.21.878 4.33 2.44 5.893 1.563 1.562 3.683 2.44 5.893 2.44zM6.925 8.45l2.5-2.383c.036-.036.079-.064.125-.084.04-.035.084-.063.133-.083.202-.09.432-.09.634 0 .102.04.195.1.275.175l2.5 2.5c.326.327.326.857 0 1.183-.327.327-.857.327-1.184 0l-1.075-1.083v4.658c0 .46-.373.834-.833.834-.46 0-.833-.373-.833-.834V8.617L8.075 9.658c-.334.318-.862.305-1.18-.029-.317-.334-.304-.861.03-1.179z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-586 -174) translate(155 170) translate(423) translate(8 4)">
                <path d="M0 0H20V20H0z" />
                <mask id="4q49m4z4vb" fill="#fff">
                  <use xlinkHref="#gjbwtvqrta" />
                </mask>
                <g fill={props.fill ? props.fill : '#5C71E5'} mask="url(#4q49m4z4vb)">
                  <path d="M0 0H20V20H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TopUpIcon;
