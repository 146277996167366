import { all, fork, put, take } from 'redux-saga/effects';
import { onAppInit, cmsRequest, cmsRequestSuccess, apiGWTokenRequest, publicIpRequest } from './App';
import appSaga from './App/sagas';
import welcomeSaga from './Welcome/sagas';
import signUpSagas from './SignUp/sagas';
import userSagas from './User/sagas';
import errorFormSagaWatcher from './Form/sagas';
import accountSagaWatcher from './Account/sagas';
import cardTransactionsSagaWatcher from './CardTransactions/sagas';
import creditCardTransactionsSagaWatcher from './CreditCardTransactions/sagas';
import transactionsSagaWatcher from './Transactions/sagas';
import applicationSagaWatcher from './CardApplication/sagas';
import balanceTransferSagaWatcher from './BalanceTransfer/sagas';
import mapsSagaWatcher from './Maps/sagas';
import microCreditSagaWatcher from './MicroCredit/sagas';
import instagramRaffleContestSagasWatcher from './InstagramRaffleContest/sagas';

export function* initialSaga() {
  yield put(cmsRequest());
  yield take(cmsRequestSuccess);
  yield put(publicIpRequest());
  yield put(apiGWTokenRequest());

  // Indicate that the app is initialized
  yield put(onAppInit());
}

function* rootSaga() {
  yield fork(initialSaga);
  // Add Sagas Here
  yield all(
    [
      appSaga,
      welcomeSaga,
      signUpSagas,
      userSagas,
      errorFormSagaWatcher,
      accountSagaWatcher,
      cardTransactionsSagaWatcher,
      creditCardTransactionsSagaWatcher,
      transactionsSagaWatcher,
      applicationSagaWatcher,
      mapsSagaWatcher,
      balanceTransferSagaWatcher,
      microCreditSagaWatcher,
      instagramRaffleContestSagasWatcher,
    ].map(fork)
  );
}

export default rootSaga;
