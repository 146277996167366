import { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useErrorMessage, useLocalization, useResize } from 'Hooks';
import useOnScreen from 'Hooks/useOnScreen';
import { SignupSchemaInputs } from 'Validations';
import { FormikProps } from 'formik';
import { ArrowDownIcon, Checkbox, FormErrorWrapper, FullPageWrapper } from 'Components';
import { agreementsTypes, generateLanguageParam, scrollTop } from 'Utils';
import { formNames } from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, onGetAgreementRequest, setUserAcceptedNewVersion, storeAccount } from 'Stores/Account';

import '../Signup/SingupConditionsStep/SingupConditionsStep.scss';
import { useHistory } from 'react-router-dom';

type SingupConditionsStepProps = {};

const SingupConditionsStep: FunctionComponent<SingupConditionsStepProps> = ({}) => {
  const {
    web_version_clarification_text_read_accept,
    web_title_sozlesmeler,
    web_check_sozlesmeler,
    web_btn_next,
    web_btn_scroll,
  } = useLocalization();
  const { getErrorMessage } = useErrorMessage();
  const dispatch = useDispatch();
  const history = useHistory();

  const accountState: AccountState = useSelector(storeAccount);
  const userAccepted = accountState.userAcceptedNewVersion;

  const containerRef = useRef<any>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useResize(containerRef);
  const buttonContainerIsVisible = useOnScreen(buttonContainerRef);

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  useEffect(() => {
    dispatch(
      onGetAgreementRequest({
        AgreementType: agreementsTypes.AYDINLATMA_METNI,
        Language: generateLanguageParam(),
        isSignupProcess: true,
      })
    );
    scrollTop();
    // setTouched({});
    // eslint-disable-next-line
  }, []);

  return (
    <FullPageWrapper>
      <div id="signupConditions">
        <div className="signupConditions-container" ref={containerRef}>
          <Typography variant="h5" align="center">
            <Box className="signupConditions-title">{web_title_sozlesmeler}</Box>
          </Typography>
          <Box marginBottom={gutters.big}>
            <Typography variant="subtitle2">{agreementContent?.AgreementContent}</Typography>
          </Box>
          <div className="signupConditions-button-container" ref={buttonContainerRef}>
            <FormErrorWrapper formName={formNames.signup} resetFormOnRouteChange>
              <Box className="checkbox-error">
                <Checkbox
                  name="clarificationAccepted"
                  color="primary"
                  onClick={() => {
                    dispatch(setUserAcceptedNewVersion(!userAccepted));
                  }}
                  checked={userAccepted}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  label={web_version_clarification_text_read_accept}
                  // errorText={getErrorMessage(errorConditionsAccepted, touchedConditionsAccepted)}
                  itemID="signUpInfoConditionsAcceptedInputTest"
                />
              </Box>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                itemID="signUpNextBtnTest"
              >
                {web_btn_next}
              </Button>
            </FormErrorWrapper>
          </div>
          {!buttonContainerIsVisible && (
            <div
              className="signupConditions-dialog"
              style={{
                width: width || 0,
              }}
              onClick={() => buttonContainerRef.current?.scrollIntoView()}
            >
              <Typography variant="body2" component="div" className="signupConditions-dialog__inner flexAlignCenter">
                <Box>{web_btn_scroll}</Box>
                <ArrowDownIcon />
              </Typography>
            </div>
          )}
        </div>
      </div>
    </FullPageWrapper>
  );
};

export default SingupConditionsStep;
