import { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Container } from 'Components/Container';
import { gutters } from 'Theme/Variables';
import { useLocalization } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { useHistory } from 'react-router-dom';
import { QuestionMarkIcon, NavigationIcon, PhoneIcon, IbbGrayIcon, BelbimGrayIcon, TinyCardIcon } from 'Components/Svg';
import { userStore } from 'Stores/User';
import { useSelector } from 'react-redux';

import './DashboardFooter.scss';

const DashboardFooter: FC = () => {
  const {
    web_footer_istanbulkart_hakkinda,
    web_footer_yukleme_harcama_noktalari,
    web_footer_sik_sorulan_sorular,
    web_footer_bize_ulasin,
    web_about_istanbulkart,
  } = useLocalization();

  const history = useHistory();
  const userData = useSelector(userStore);
  const isResetPassword = userData?.resetPassword;

  const handleIstanbulkartHakkinda = () => {
    window.open(web_about_istanbulkart);
  };

  return (
    <div id="dashboardFooter">
      <Container>
        <Grid container>
          <Grid lg={3} sm={5} xs={12} direction="column" item container>
            <Box display="flex" alignItems="center" marginBottom={gutters.regular} className="dashboardFooter--links">
              <TinyCardIcon />
              <Box
                marginLeft={gutters.small}
                onClick={() => handleIstanbulkartHakkinda()}
                itemID="istanbulkartHakkındaBtnTest"
              >
                <Typography>{web_footer_istanbulkart_hakkinda}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={gutters.regular}
              className="dashboardFooter--links"
              onClick={() => history.push(routePath.discover)}
              itemID="discoverBtnTest"
            >
              <NavigationIcon />
              <Box marginLeft={gutters.small}>
                <Typography> {web_footer_yukleme_harcama_noktalari}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid lg={3} sm={4} xs={12} direction="column" item container>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={gutters.regular}
              className="dashboardFooter--links"
              onClick={isResetPassword ? undefined : () => history.push(routePath.contact)}
              itemID="faqBtnTest"
            >
              <PhoneIcon />
              <Box marginLeft={gutters.small}>
                <Typography>{web_footer_bize_ulasin}</Typography>
              </Box>
            </Box>
            <Box
              onClick={() => history.push(routePath.faq)}
              display="flex"
              alignItems="center"
              marginBottom={gutters.regular}
              className="dashboardFooter--links"
              itemID="contactBtnTest"
            >
              <QuestionMarkIcon />
              <Box marginLeft={gutters.small}>
                <Typography> {web_footer_sik_sorulan_sorular}</Typography>
              </Box>
            </Box>
            {/* <Box
              onClick={() => history.push(routePath.lotteryForm)}
              display="flex"
              alignItems="center"
              marginBottom={gutters.regular}
              className="dashboardFooter--links"
              itemID="contactBtnTest"
            >
              <QuestionMarkIcon />
              <Box marginLeft={gutters.small}>
                <Typography>Çekilişe katıl</Typography>
              </Box>
            </Box> */}
          </Grid>
          <Grid lg={6} sm={3} xs={12} item container className="dashboardFooter__right--logo">
            <Box alignItems="center" display="flex">
              <IbbGrayIcon />
              <Box marginLeft={gutters.regular}>
                <BelbimGrayIcon />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DashboardFooter;
