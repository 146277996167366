import { errorMessages } from 'Config';
import * as yup from 'yup';

export const BalanceTransfer = (cardAmount: number) =>
  yup.object({
    amount: yup
      .string()
      .required(errorMessages.require)
      .test('checkMaxValue', errorMessages.checkMaxValue, (amount?: string | null) => {
        return cardAmount >= parseFloat(amount || '');
      }),
  });

const BalanceTransferForType = BalanceTransfer(0);

export type BalanceTransferSchemaInputs = yup.InferType<typeof BalanceTransferForType>;

export const balanceTransferSchemaInitialValues: BalanceTransferSchemaInputs = {
  amount: '',
};
