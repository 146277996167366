import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Box, Button, Link } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { gutters } from 'Theme/Variables';
import { Formik, FormikProps } from 'formik';
import './Signup.scss';
import {
  decrementSignupStep,
  incrementSignupStep,
  onCreateOnlineCustomerRequest,
  onSetPasswordRequest,
  storeSingupStep,
  resetSignupStep,
} from 'Stores/SignUp/slices';
import { StaticContext } from 'react-router';
import { InfoCondition, SingupInfoStep } from './SingupInfoStep';
import { SignupContactStep } from './SignupContactStep';
import { SignupIdentityStep } from './SignupIdentityStep';
import {
  DialogTerms,
  FormErrorWrapper,
  FormWrapper,
  FullPageWrapper,
  InfoIcon,
  MultiStepProgress,
  PasswordForm,
  PasswordFormInfo,
  StepWrapper,
} from 'Components';
import {
  SignupSchemaInputs,
  SignupSchema,
  signupSchemaInitialValues,
  SingupConditionsStepSchema,
  SingupInfoStepSchema,
  SignupContactStepSchema,
  SignupIdentityStepSchema,
  PasswordSchema,
} from 'Validations';
import { useLocalization, useModal } from 'Hooks';
import { agreementsTypes, generateLanguageParam, scrollTop, underlineParser } from 'Utils';
import { SingupConditionsStep } from './SingupConditionsStep';
import {
  signupNormalizeIdentityStep,
  signupNormalizeInfoContactStep,
  onSetPasswordNormalize,
  signupNormalizeInfoStep,
} from 'Normalize';
import { formikGlobalConfig, formNames } from 'Config';
import { get } from 'lodash';
import { onInıtDataStore } from 'Stores/App';
import { onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { routePath } from 'Navigator/routes';
import { signUpInitialValues } from 'Validations/SingupSchema';
import { Login } from 'Views/Login';

export type AgreementListProps = {
  AgreementType: number;
  AgreementVersion: number;
  Required: boolean;
  ReadOnly: boolean;
};

type SignupParams = {};
type SignupProps = RouteComponentProps<{}, StaticContext, SignupParams>;

const Signup: FC<SignupProps> = (props) => {
  const { state } = props.location;
  const [clarificationIsOpen, setClarificationIsOpen] = useState<boolean>(false);
  const [loginControl, setLoginControl] = useState<boolean>(false);
  const {
    web_progressbar_iletisim,
    web_progressbar_kimlik,
    web_progressbar_sifre,
    web_btn_next,
    web_iletisim2_info,
    web_password_rules_notall,
    web_title_commercial_electronic_communication_permission_text,
    web_iletisim2_info2,
  } = useLocalization();

  const { underlineText } = underlineParser(web_iletisim2_info);
  const { underlineText: underlineTextOther } = underlineParser(web_iletisim2_info2);

  const progressItems = [web_progressbar_kimlik, web_progressbar_iletisim, web_progressbar_sifre];

  const dispatch = useDispatch();
  const phoneNumber = get(state, 'phoneNumber');
  const countryCode = get(state, 'countryCode');
  const step = useSelector(storeSingupStep);

  const history = useHistory();

  const onInıtData = useSelector(onInıtDataStore);
  const agreementList: AgreementListProps[] = onInıtData?.AgreementList;

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  const { isVisible: isVisibleEmail, show: showEmail, hide: hideEmail } = useModal();
  const { isVisible: isVisibleSms, show: showSms, hide: hideSms } = useModal();

  const isConditionStep = step === 3;
  const activeStepIndicator = step === 0 ? 0 : step < 4 ? 1 : 2;

  useEffect(() => {
    dispatch(resetSignupStep());
    if (!phoneNumber || !countryCode) {
      setLoginControl(true);
    } else {
      setLoginControl(false);
    }
  }, [state]);

  useEffect(() => {
    scrollTop();
  }, [step]);

  const renderStep = (formikProps: FormikProps<signUpInitialValues>) => {
    switch (step) {
      case 0:
        return <SignupIdentityStep formikProps={formikProps} />;
      case 1:
        return clarificationIsOpen ? (
          <InfoCondition setClarificationIsOpen={setClarificationIsOpen} formikProps={formikProps} />
        ) : (
          <SingupInfoStep setOpen={setClarificationIsOpen} formikProps={formikProps} />
        );
      case 2:
        return <SignupContactStep formikProps={formikProps} />;

      case 3:
        return <SingupConditionsStep formikProps={formikProps} />;
      case 4:
        return <PasswordForm formikProps={formikProps} />;

      default:
        break;
    }
  };

  const decideSignUpSchema = () => {
    switch (step) {
      case 0:
        return SignupIdentityStepSchema;
      case 1:
        return SingupInfoStepSchema(agreementList[agreementsTypes.ACIK_RIZA_METNI - 1]?.Required);
      case 2:
        return SignupContactStepSchema;
      case 3:
        return SingupConditionsStepSchema(agreementList[agreementsTypes.UYGULAMA_KULLANIM_KOSULLARI - 1]?.Required);
      case 4:
        return PasswordSchema;
      default:
        break;
    }
  };

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  const handleSubmit = (values: SignupSchemaInputs) => {
    if (step === 0) {
      dispatch(onCreateOnlineCustomerRequest(signupNormalizeIdentityStep(values, phoneNumber, countryCode)));
    }
    if (step === 1) {
      dispatch(onCreateOnlineCustomerRequest(signupNormalizeInfoStep(values, phoneNumber, countryCode)));
    }
    if (step === 2) {
      dispatch(incrementSignupStep());
      handleAgreementSelect(agreementsTypes.UYGULAMA_KULLANIM_KOSULLARI);
    }
    if (step === 3) {
      dispatch(
        onCreateOnlineCustomerRequest(signupNormalizeInfoContactStep(agreementList, values, phoneNumber, countryCode))
      );
    }
    if (step === 4) {
      dispatch(onSetPasswordRequest(onSetPasswordNormalize(values, phoneNumber, countryCode)));
    }
  };

  if (loginControl) {
    return <Login {...props} />;
  }
  return (
    <div id="signup">
      <FullPageWrapper>
        <StepWrapper
          noStyle={isConditionStep || clarificationIsOpen}
          {...(isConditionStep && {
            backIconClassName: 'backIcon',
          })}
          {...(step !== 4 && {
            handleBack: {
              step,
              setStep: step === 1 ? () => {} : undefined,
              onStepBack: () => {
                step === 1 && setClarificationIsOpen(false);
              },
              setStepDispatch: () => dispatch(decrementSignupStep()),
            },
          })}
        >
          <FormErrorWrapper formName={formNames.signup} step={step} hideError={step === 3} resetFormOnRouteChange>
            <>
              {!isConditionStep && !clarificationIsOpen && (
                <MultiStepProgress titles={progressItems} activeStep={activeStepIndicator} />
              )}
              <Formik
                initialValues={signupSchemaInitialValues}
                validationSchema={decideSignUpSchema()}
                onSubmit={handleSubmit}
                {...formikGlobalConfig}
              >
                {(formikProps) => (
                  <FormWrapper onSubmit={formikProps.handleSubmit}>
                    {renderStep(formikProps)}
                    {!isConditionStep && !clarificationIsOpen && (
                      <Button variant="contained" color="secondary" fullWidth type="submit" itemID="signUpNextBtn">
                        {web_btn_next}
                      </Button>
                    )}
                  </FormWrapper>
                )}
              </Formik>
              {step === 2 && (
                <Box>
                  <Box marginTop={gutters.big} className="flexAlignCenter">
                    <InfoIcon className="iconRightPadding" />
                    <Link
                      variant="body2"
                      onClick={() => {
                        handleAgreementSelect(agreementsTypes.TICARI_ELEKTRONIK_ILETISIM_IZNI);
                        showEmail();
                      }}
                      underline="always"
                      color="textSecondary"
                      itemID="showTicariElektronikIletişimIzniBtn"
                    >
                      {underlineText}
                    </Link>
                  </Box>
                  <Box marginTop={gutters.small} className="flexAlignCenter">
                    <InfoIcon className="iconRightPadding" />
                    <Link
                      variant="body2"
                      onClick={() => {
                        handleAgreementSelect(agreementsTypes.TICARI_ELEKTRONIK_ILETISIM_IZNI);
                        showSms();
                      }}
                      underline="always"
                      color="textSecondary"
                      itemID="showTicariElektronikIletişimIzniBtnTest"
                    >
                      {underlineTextOther}
                    </Link>
                  </Box>
                </Box>
              )}
              {step === 4 && <PasswordFormInfo rules={web_password_rules_notall} />}
              {/** 61OF61 special karakter comes from data to split all content into two part - sms- mail */}
              <DialogTerms
                open={isVisibleEmail}
                hide={hideEmail}
                headerTextAlign="left"
                isButtonOkay
                title={web_title_commercial_electronic_communication_permission_text}
                content={agreementContent?.AgreementContent.split('61OF61')[1]}
              />
              <DialogTerms
                open={isVisibleSms}
                hide={hideSms}
                headerTextAlign="left"
                isButtonOkay
                title={web_title_commercial_electronic_communication_permission_text}
                content={agreementContent?.AgreementContent.split('61OF61')[0]}
              />
            </>
          </FormErrorWrapper>
        </StepWrapper>
      </FullPageWrapper>
    </div>
  );
};

export default Signup;
