import { stringToLocation } from 'Utils';

const mapDirection = ({
  discoverIsDirectionEnable,
  directionsService,
  directionsRenderer,
  mapRender,
  userLocation,
  discoverSelectedPin,
}: any) => {
  if (discoverIsDirectionEnable && mapRender) {
    directionsRenderer.setMap(mapRender);
    const origin = { lat: userLocation.latitude, lng: userLocation.longitude };
    const destination = {
      lat: discoverSelectedPin?.Latitude ? stringToLocation(discoverSelectedPin?.Latitude) : 0,
      lng: discoverSelectedPin?.Longitude ? stringToLocation(discoverSelectedPin?.Longitude) : 0,
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }
};

export default mapDirection;
