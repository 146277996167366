import * as React from 'react';

function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className="lockIcon"
      {...props}
    >
      <defs>
        <path
          id="o9mz3w3sba"
          d="M8 .11c2.21 0 4 1.79 4 4V6h1c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V9c0-1.657 1.343-3 3-3h1V4.11c0-2.21 1.79-4 4-4zM8 10c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3zm0 2c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zM8 2c-1.134.033-2.028.976-2 2.11V6h4V4.11c.028-1.134-.866-2.077-2-2.11z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-171 -713) translate(155 690) translate(16 22.014) translate(0 1)"
                />
                <g transform="translate(-171 -713) translate(155 690) translate(16 22.014) translate(0 1) translate(4 2)">
                  <mask id="o2uqbmactb" fill="#fff">
                    <use xlinkHref="#o9mz3w3sba" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#o9mz3w3sba" />
                  <g fill="currentColor" mask="url(#o2uqbmactb)">
                    <path d="M0 0H24V24H0z" transform="translate(-4 -2)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LockIcon;
