import createSagaMiddleware from 'redux-saga';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { createBrowserHistory } from 'history';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { AppState } from './App';
import { WelcomeState } from './Welcome';
import { SignUpState } from './SignUp';
import { UserState } from './User';
import { DialogState } from './Dialog';
import { FormState } from './Form';
import { ToastifyState } from './Toastify';
import { AccountState } from './Account';
import { CardTransactionsState } from './CardTransactions';
import { CreditCardTransactionsState } from './CreditCardTransactions';
import { TransactionsState } from './Transactions';
import { ApplicationProcessState } from './CardApplication/types';
import { MapsState } from './Maps/types';
import { BalanceTransferState } from './BalanceTransfer';
import { MicroCreditState } from './MicroCredit';
import { instagramRaffleContestState } from './InstagramRaffleContest';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

// serializableCheck and immutableCheck should be set to true after their issue has been solved
const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    immutableCheck: false,
    serializableCheck: false,
  }),
  sagaMiddleware,
  routerMiddleware(history),
];

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export interface RootState {
  app: AppState;
  welcome: WelcomeState;
  signUp: SignUpState;
  router: RouterState;
  user: UserState;
  dialog: DialogState;
  form: FormState;
  toastify: ToastifyState;
  account: AccountState;
  cardTransactions: CardTransactionsState;
  creditCardTransactions: CreditCardTransactionsState;
  transactions: TransactionsState;
  application: ApplicationProcessState;
  balanceTransfer: BalanceTransferState;
  maps: MapsState;
  microCredit: MicroCreditState;
  instagramRaffleContest: instagramRaffleContestState;
}

export default store;
