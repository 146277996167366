import Navigator from './Navigator';
import { useCallback, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core';
import { materialUITheme } from 'Theme/MaterialUITheme';
// import { firebaseAnalytics } from 'firebase.config';
import { CacheBuster } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { onInıtDataRequest, storeApp } from 'Stores/App';
import { generateLanguageParam, getEnvironment, MasterpassServicess } from 'Utils';

import 'react-toastify/dist/ReactToastify.css';

const isDev = getEnvironment();

const clientId = isDev ? '34705878' : '34706580';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  const { cacheLoading, isLatestVersion } = useSelector(storeApp);

  const refreshCacheAndReload = useCallback(async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name)));
    }
    window.location.reload();
  }, []);

  useEffect(() => {
    if (MasterpassServicess?.setClientId) {
      MasterpassServicess?.setClientId(clientId);
      !isDev && MasterpassServicess?.setAddress('https://ui.masterpassturkiye.com/v2');
    }
  }, [MasterpassServicess]);

  useEffect(() => {
    if (!cacheLoading && !isLatestVersion) {
      refreshCacheAndReload();
    }
  }, [isLatestVersion, cacheLoading, refreshCacheAndReload]);

  useEffect(() => {
    dispatch(onInıtDataRequest(generateLanguageParam()));
    // firebaseAnalytics();
    window.EvamSdkObject && window.EvamSdkObject.consentAccepted();
  }, []);

  return (
    <CacheBuster>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={materialUITheme}>
          <Navigator />
        </ThemeProvider>
      </QueryClientProvider>
    </CacheBuster>
  );
};

export default App;
