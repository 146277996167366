import { FC, Fragment, memo, useLayoutEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { userStore } from 'Stores/User/slices';
import {
  onGetCardListRequest,
  mbGetOtherCardListRequest,
  storeCardTransactions,
  setClearError,
  setActiveInstructionListEmpty,
  setActiveCardTransactionListEmpty,
  CardTransactionsState,
  setInActiveCard,
  setNormalizeCardTransactionStates,
  onCountInstructionListRequest,
  onGetAccountWithCardsRequest,
} from 'Stores/CardTransactions';
import { NoCard } from './NoCard';
import { AllCards } from './AllCards';
import { UserState } from 'Stores/User';
import { onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { masterPassListCardNormalize } from 'Normalize';
import { MasterPassTokenEnum } from 'Utils';

type UserCardsProps = {};

const UserCards: FC<UserCardsProps> = memo(() => {
  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const userCards = cardData?.cards;
  const userOtherCards = cardData?.otherCards;

  const isLoading = cardData?.loading;
  const otherCardsIsLoading = cardData?.otherCardStateLoading;

  useLayoutEffect(() => {
    dispatch(setInActiveCard(undefined));
    dispatch(setActiveInstructionListEmpty());
    dispatch(setActiveCardTransactionListEmpty());
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CARD_LIST,
        request: {
          data: masterPassListCardNormalize(),
        },
      })
    );
    dispatch(
      onGetCardListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      mbGetOtherCardListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      onCountInstructionListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(setNormalizeCardTransactionStates());
    return () => {
      dispatch(setClearError());
    };
  }, []);

  return (
    <DashboardLayout>
      <Fragment>
        <AllCards />
        {/** While loading the state because of component not exist , it doesn't show other cards loading.So this has to be this way */}
        {/**!isLoading && !otherCardsIsLoading && !userOtherCards && <NoCard />*/}
      </Fragment>
    </DashboardLayout>
  );
});

export default UserCards;
