import axios from 'axios';
import { get } from 'lodash';

const url = 'https://api.ipify.org?format=json';

const getPublicId = async () => {
  const response = await axios.get(url);

  return get(response, 'data.ip');
};

export default getPublicId;
