import { FC, memo } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { FormErrorWrapper, MasterPassIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { fontSizes, gutters } from 'Theme/Variables';
import { onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { MasterPassTokenEnum } from 'Utils';
import { masterPassLinkToClientNormalize } from 'Normalize/MasterPassRequestNormalize';
import { UserState, userStore } from 'Stores/User';
import { useDispatch, useSelector } from 'react-redux';
import { formNames } from 'Config';

import './MasterPassConnect.scss';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

type MasterPassConnectType = {};

const MasterpassConnect: FC<MasterPassConnectType> = memo(() => {
  const dispatch = useDispatch();
  const {
    push,
    location: { pathname },
  } = useHistory();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const {
    web_title_add_registered_credit_card,
    web_desc_add_registered_credit_card,
    web_btn_add_registered_credit_card,
    web_enter_new_cardd,
  } = useLocalization();

  const connectUserToClient = () => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.REGISTER,
        request: {
          data: masterPassLinkToClientNormalize(),
          type: MasterPassTokenEnum.LINK,
        },
      })
    );
  };

  const newCardWithoutMasterPass = () => {
    push(routePath.addCreditCard, { isWithoutMp: true });
  };

  return (
    <div id="masterPassConnect">
      <FormErrorWrapper formName={formNames.masterpassConnect} resetFormOnRouteChange>
        <Box textAlign="center" fontWeight={600}>
          <MasterPassIcon height="25px" width="150px" />
        </Box>
        <Typography variant="h3">
          <Box marginTop={gutters.big} textAlign="center" fontWeight={600}>
            {web_title_add_registered_credit_card}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box fontSize={fontSizes.regular} marginTop={gutters.small} textAlign="center">
            {web_desc_add_registered_credit_card}
          </Box>
        </Typography>
        <Box onClick={connectUserToClient} marginTop={gutters.large}>
          <Button variant="contained" color="secondary" fullWidth type="submit" itemID="masterpassConnectBtnTest">
            {web_btn_add_registered_credit_card}
          </Button>
        </Box>
        {pathname === routePath.addBalanceToCard ? (
          <Box onClick={newCardWithoutMasterPass} marginTop={gutters.small}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              type="submit"
              itemID="masterpassConnectSecondBtnTest"
            >
              {web_enter_new_cardd}
            </Button>
          </Box>
        ) : (
          <div></div>
        )}
      </FormErrorWrapper>
    </div>
  );
});

export default MasterpassConnect;
