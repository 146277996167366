import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ChevronDarkRightIconFaq } from 'Components';
import './FAQItem.scss';

type FAQItemProps = {
  Question: string;
  Answer: string;
  handleClick: (a: string, q: string) => void;
};

const FAQItem: FC<FAQItemProps> = ({ Answer, Question, handleClick }) => {
  return (
    <div onClick={() => handleClick(Answer, Question)} id="faqItem" itemID="faqItem">
      <Typography variant="h6">
        <Box fontSize="15px" fontWeight={600}>
          {Question}
        </Box>
      </Typography>
      <Box>
        <ChevronDarkRightIconFaq />
      </Box>
    </div>
  );
};
export default FAQItem;
