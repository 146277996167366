import { axiosHelper } from 'Services/AxiosHelper';
import { OnChangePasswordCustomerReqModel } from 'Models';
import { onChangePasswordCustomerReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnChangePassword4CustomerReq';

const onChangePasswordCustomerReq = async (
  params: onChangePasswordCustomerReqBodyData
): Promise<OnChangePasswordCustomerReqModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onChangePasswordCustomerReq;
