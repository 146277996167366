import { Box, Typography } from '@material-ui/core';
import { FC, memo } from 'react';
import { colors, fontSizes } from 'Theme/Variables';
import { cardBalanceNormalize } from 'Utils';
import { getCurrentMonth } from './constant';
import moment from 'moment';

import './LastTransactionItem.scss';

type LastTransactionItemProps = {
  transactionItem: {
    TrnId: number;
    TrnCode: string;
    TrnCodeDescription: string;
    TrnAmount: number;
    CardBalanceNew: number;
    TrnStatus: string;
    TrnDate: string;
    TrnDescription: string;
    Description?: string;
    Amount?: number;
    TrnTicketCount?: number;
  };
  itemID?: string;
  onHandleClick?: () => void;
};

const LastTransactionItem: FC<LastTransactionItemProps> = memo(({ transactionItem, onHandleClick }) => {
  const { TrnCodeDescription, TrnAmount, TrnDate, TrnCode, Description, Amount, TrnTicketCount } = transactionItem;
  return (
    <div onClick={onHandleClick} id="lastTransactionItem" itemID="lastTransactionItemBtnTest">
      <Box display="flex" alignItems="center">
        <Box className="lastTransactionItem__date">
          <Typography variant="h1">
            <Box fontWeight="bold" fontSize={fontSizes.big} textAlign="center">
              {moment(TrnDate).format('D')}
            </Box>
          </Typography>
          <Typography variant="h1">
            <Box fontSize={fontSizes.smaller}>{getCurrentMonth(TrnDate)}</Box>
          </Typography>
        </Box>
        <Box className="lastTransactionItem__desc">
          <Typography variant="h6">
            <Box fontWeight="bold" fontSize={fontSizes.small}>
              {TrnCodeDescription ? TrnCodeDescription : Description}
            </Box>
          </Typography>
          <Typography variant="subtitle2">{transactionItem?.TrnDescription}</Typography>
        </Box>
      </Box>
      <Box className="lastTransactionItem__price">
        <Typography variant="h6">
          <Box fontWeight="bold" fontSize={fontSizes.small} display="flex">
            {`${cardBalanceNormalize(TrnAmount ? TrnAmount : 0)} TL`}
            {/* {TrnCode
              ? `${transactionTypeParser(TrnCode) ? '+' : '-'} ${TrnTicketCount} ${web_subscription_used_quantity_lbl}`
              : `${cardBalanceNormalize(TrnAmount ? TrnAmount : 0)} TL`} */}
          </Box>
        </Typography>
      </Box>
    </div>
  );
});

export default LastTransactionItem;
