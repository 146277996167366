import { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components/Svg';

import './TypesDialog.scss';
import { CardTransactionListTypeEnum, turkishCharacterNormalize } from 'Utils';
import { OrganizationTypeListItemModel } from 'Models';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';

type TypesDialogProps = {
  dialogTitle?: string;
  bodyData?: OrganizationTypeListItemModel[];
  selectedValue?: any;
  onChange?: any;
  open: boolean;
  close: any;
};

const TypesDialog: FC<TypesDialogProps> = ({ dialogTitle, bodyData, selectedValue, onChange, close, open }) => {
  const { web_account_search } = useLocalization();

  const handleChanges = (type: CardTransactionListTypeEnum, label: string) => {
    onChange(type, label);
  };
  const [searchKey, setSearchKey] = useState<string>('');

  const lowerCaseSearchKey = searchKey.toLocaleLowerCase();

  useEffect(() => {
    setSearchKey('');
  }, [close]);

  const onClose = () => {
    setSearchKey('');
    close(false);
  };

  return (
    <Dialog id="dialogCity" onClose={onClose} scroll="paper" keepMounted={false} open={open}>
      <DialogTitle className="dialogCity-search">
        <CloseIcon onClick={() => close(false)} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCity-container">
          <JobBlock
            types={bodyData}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={lowerCaseSearchKey}
            itemClickHandle={handleChanges}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
const JobBlock: FC<{
  types: OrganizationTypeListItemModel[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ types, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogCity-container__list">
      {!!types &&
        types.length > 0 &&
        types
          .filter((item: OrganizationTypeListItemModel) =>
            turkishCharacterNormalize(item.Name)
              .toLocaleLowerCase()
              .includes(turkishCharacterNormalize(lowerCaseSearchKey))
          )
          .map((item: OrganizationTypeListItemModel, index) => (
            <Item
              key={index}
              title={item.Name}
              isSelected={item.Name === selectedValue}
              onClick={() => itemClickHandle(item.OrganizationTypeRecordId, item.Name)}
            />
          ))}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="cardApplyOrganizationTypeBtnTest">
      <div
        className={classNames({
          'dialogCity-container__list-item': true,
          'dialogCity-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default TypesDialog;
