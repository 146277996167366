import React, { FunctionComponent } from 'react';
import './SemiContainer.scss';

type SemiContainerProps = {
  children: JSX.Element | JSX.Element[];
};

const SemiContainer: FunctionComponent<SemiContainerProps> = ({ children }) => {
  return <div className="semi-container">{children}</div>;
};

export default SemiContainer;
