import * as React from 'react';

function QuestionMarkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="questionMarkIcon"
      {...props}
    >
      <defs>
        <path
          id="mbualbwi3a"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C17.196 3.054 14.652 2 12 2zm0 16c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm1-5.16V14c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1 .828 0 1.5-.672 1.5-1.5S12.828 8 12 8s-1.5.672-1.5 1.5c0 .552-.448 1-1 1s-1-.448-1-1c-.007-1.804 1.358-3.318 3.153-3.497 1.795-.179 3.432 1.036 3.781 2.806.349 1.77-.705 3.516-2.434 4.031z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-320 -1349) translate(0 1299) translate(320 50)">
              <path d="M0 0H24V24H0z" />
              <mask id="g1tqdbbovb" fill="#fff">
                <use xlinkHref="#mbualbwi3a" />
              </mask>
              <g fill="currentColor" mask="url(#g1tqdbbovb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default QuestionMarkIcon;
