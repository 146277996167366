import * as React from 'react';

function TimeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="timeIcon"
      {...props}
    >
      <defs>
        <path
          id="o3jk7hy98a"
          d="M20 3.333C10.795 3.333 3.333 10.795 3.333 20c0 9.205 7.462 16.667 16.667 16.667 9.205 0 16.667-7.462 16.667-16.667 0-4.42-1.756-8.66-4.882-11.785C28.66 5.089 24.42 3.333 20 3.333zm6.667 18.334H20c-.92 0-1.667-.747-1.667-1.667v-6.667c0-.92.747-1.666 1.667-1.666s1.667.746 1.667 1.666v5h5c.92 0 1.666.747 1.666 1.667s-.746 1.667-1.666 1.667z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-984 -263) translate(916 243) translate(68 20)">
              <path d="M0 0H40V40H0z" />
              <mask id="1za5cdn50b" fill="#fff">
                <use xlinkHref="#o3jk7hy98a" />
              </mask>
              <g fill="currentColor" mask="url(#1za5cdn50b)">
                <path d="M0 0H40V40H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TimeIcon;
