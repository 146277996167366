import { FC } from 'react';
import { useCardType, useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { TransactionDetail } from './TransactionDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setAddCardStep, storeCardTransactions } from 'Stores/CardTransactions';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './UploadSubscriptionSuccess.scss';

type UploadSubscriptionSuccessProps = {};

const UploadSubscriptionSuccess: FC<UploadSubscriptionSuccessProps> = ({}) => {
  const history = useHistory();

  const { isVisible, show, hide } = useModal();

  const dispatch = useDispatch();

  const cardData = useSelector(storeCardTransactions);

  const { isUserAddingNewCardInTransactions, activeSelectedCard }: any = cardData;

  const {
    web_desc_subscription_success,
    web_desc2_subscription_success,
    web_btn_subscription_success,
    web_btn_add_to_my_cards,
  } = useLocalization();

  const { cardColor, cardPath } = useCardType(activeSelectedCard?.ProductCode);

  return (
    <div id="uploadSubscriptionSuccess">
      <Box marginTop={gutters.big}>
        <div className="upload--subs__img--wrapper">
          <img src={cardPath} alt="" className={`upload--subs__img--wrapper__${cardColor}`} />
          <GreenTickBigFilledIcon />
        </div>
        <Box className="upload--subs__desc">
          <Link underline="none" style={{ cursor: 'unset' }}>
            <Box textAlign="center" color={colors.alternative3} fontSize={fontSizes.regular}>
              {web_desc_subscription_success}
            </Box>
          </Link>
          <Typography variant="body1" align="center">
            <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
              {web_desc2_subscription_success}
            </Box>
          </Typography>
        </Box>
        <div className="upload--subs__container">
          {isUserAddingNewCardInTransactions ? (
            <Box>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  dispatch(setAddCardStep(0));
                  history.push(routePath.addCard);
                }}
              >
                {web_btn_add_to_my_cards}
              </Button>
              <Box marginTop={gutters.small}>
                <Button variant="outlined" color="secondary" fullWidth type="submit" onClick={() => show()}>
                  {web_btn_subscription_success}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" fullWidth type="submit" onClick={() => show()}>
              {web_btn_subscription_success}
            </Button>
          )}
        </div>
      </Box>
      <Box>
        <TransactionDetail isVisible={isVisible} hide={hide} />
      </Box>
    </div>
  );
};

export default UploadSubscriptionSuccess;
