import { axiosHelper } from 'Services/AxiosHelper';
import { OnLoginModel } from 'Models';
import { onLoginBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnLogin';

const onLogin = async (params: onLoginBodyData): Promise<OnLoginModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onLogin;
