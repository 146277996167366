import { FC, useState, Dispatch, useEffect, SetStateAction, useCallback } from 'react';
import ChevronRightIcon from 'Components/Svg/ChevronRedRightIcon';

import './Pagination.scss';
import { useLocalization } from 'Hooks';
import { PaginationLeft, PaginationRight } from 'Components/Svg';
import { colors } from 'Theme/Variables';

type PaginationProps = {
  totalPageCount?: number;
  currentPage?: number;
  changeCurrentPage: Dispatch<SetStateAction<number>>;
};

const Pagination: FC<PaginationProps> = ({ totalPageCount, currentPage, changeCurrentPage }) => {
  const [currentBlock, setCurrentBlock] = useState<number>(1);
  const totalBlock = totalPageCount ? Math.ceil(totalPageCount / 5) : 0;

  const hasNextBlock = () => {
    return currentBlock < totalBlock;
  };

  const hasBeforeBlock = () => {
    return currentBlock - 1 > 0;
  };

  const getDecimalPart = () => {
    const tempDecimalNumber = totalPageCount ? totalPageCount % 5 : 0;
    return tempDecimalNumber;
  };

  const createArray = () => {
    const startCount = currentBlock * 5;
    const loopCount = currentBlock <= totalBlock ? 5 : getDecimalPart();
    const pageArray: number[] = [startCount - 4];
    for (let i = 1; i < loopCount; i++) {
      const count = pageArray[0] + i;
      if (totalPageCount && count <= totalPageCount) {
        pageArray.push(count);
      }
    }
    return pageArray;
  };
  useEffect(() => {
    setCurrentBlock(1);
    const currentSet = createArray();
    changeCurrentPage(currentSet[0]);
  }, [totalPageCount]);

  useEffect(() => {
    const currentSet = createArray();
    changeCurrentPage(currentSet[0]);
  }, [currentBlock]);

  const renderItem = useCallback(() => {
    return totalPageCount
      ? Array.from(createArray(), (item, _) => {
          return (
            <div
              onClick={() => changeCurrentPage(item)}
              itemID="paginationItemBtnTest"
              style={{
                color: currentPage === item ? '#fff' : colors.secondary,
                backgroundColor: currentPage === item ? '#3ac7f3' : '#fff',
              }}
              className="pagination__item"
              key={item}
            >
              {item}
            </div>
          );
        })
      : '';
  }, [currentPage, totalPageCount]);

  return (
    <div id="pagination">
      {hasBeforeBlock() && (
        <div
          onClick={() => hasBeforeBlock() && setCurrentBlock((prev) => prev - 1)}
          className="pagination__item"
          itemID="paginationLeftBtnTest"
        >
          <PaginationLeft />
        </div>
      )}
      {renderItem()}
      {hasNextBlock() && (
        <div
          onClick={() => hasNextBlock() && setCurrentBlock((prev) => prev + 1)}
          className="pagination__item"
          itemID="paginationrightBtnTest"
        >
          <PaginationRight />
        </div>
      )}
    </div>
  );
};
export default Pagination;
