import { FC, Fragment, memo } from 'react';
import { DashboardLayout, Map, MapPopup } from 'Components';
import { useCustomerLocation } from 'Hooks';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { ApplicationCenterListItemModel } from 'Models';
import { mapCalculateDistance } from 'Utils';
import { IbmMap } from './IbmMap';

import './IbmPoints.scss';
import { useLocation } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import {
  MapsState,
  mapStore,
  setUserLocation,
  onGetApplicationCenterListRequest,
  resetSettings,
  setDiscoverSelectedPin,
  setIbmSelectedPin,
} from 'Stores/Maps';
import { MapInterface } from 'Views/Discover/MapInterface';

type IbmPointsProps = {};

const IbmPoints: FC<IbmPointsProps> = memo(() => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const userData: UserState = useSelector(userStore);
  const mapState: MapsState = useSelector(mapStore);

  const { userLocation, ibmSelectedPin } = mapState;
  const { customerNumber } = userData;

  const { latitude, longitude } = useCustomerLocation();

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(resetSettings());
  }, []);

  const getPoints = () => {
    dispatch(onGetApplicationCenterListRequest({ CustomerNumber: customerNumber }));
  };

  //set user location to store
  useEffect(() => {
    const userGrantedLocation = latitude !== 0 && longitude !== 0;
    const userLocationIsAlreadyHave = userLocation.latitude === 41.00527;
    const userLocationMustSet = userGrantedLocation && userLocationIsAlreadyHave;

    if (userLocationMustSet) {
      const location = { latitude, longitude };
      dispatch(setUserLocation(location));
    }
  }, [latitude, longitude]);

  useEffect(() => {
    getPoints();
  }, []);

  return pathname === routePath.ibmPoints ? (
    <DashboardLayout ds marginTop={0} noContainer>
      <Fragment>
        <MapInterface />
        <IbmMap
          onClickFunction={(e: ApplicationCenterListItemModel) => dispatch(setIbmSelectedPin(e))}
          height="585px"
        />
        {ibmSelectedPin && (
          <MapPopup
            reset={() => dispatch(setIbmSelectedPin(undefined))}
            title={ibmSelectedPin?.ApplicationCenterName}
            desc={ibmSelectedPin?.Description}
          />
        )}
      </Fragment>
    </DashboardLayout>
  ) : (
    <Fragment>
      <IbmMap onClickFunction={(e: ApplicationCenterListItemModel) => dispatch(setIbmSelectedPin(e))} height="585px" />
      {ibmSelectedPin && (
        <MapPopup
          // setIsDirection={setIsDirection}
          reset={() => dispatch(setIbmSelectedPin(undefined))}
          title={ibmSelectedPin?.ApplicationCenterName}
          desc={ibmSelectedPin?.Description}
        />
      )}
    </Fragment>
  );
});
export default IbmPoints;
