import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Stores/index';
import { TransactionsState } from './types';

export const initialState: TransactionsState = {
  isLoading: false,
  userAutomaticInstructionsStep: 0,
  isDeleteInstructionModalOpen: false,
  error: undefined,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setUserAutomaticInstructionsStep: (state: TransactionsState, { payload }: PayloadAction<number>) => {
      state.userAutomaticInstructionsStep = payload;
    },
    setIsDeleteInstructionModalOpen: (state: TransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isDeleteInstructionModalOpen = payload;
    },
    resetTransactionsStore: (state: TransactionsState) => initialState,
  },
});

export const storeTransactions = (state: RootState) => state.transactions;
export const storeTransactionsLoading = (state: RootState) => state.transactions.isLoading;

export const {
  setUserAutomaticInstructionsStep,
  setIsDeleteInstructionModalOpen,
  resetTransactionsStore,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
