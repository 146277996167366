import * as React from 'react';

function ChevronDarkRightIcon({ color = '#383C52', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="ChevronDarkRightIcon"
      {...props}
    >
      <defs>
        <path
          id="d79jcrmvfa"
          d="M8.293 5.293c.36-.36.928-.388 1.32-.083l.094.083 6 6c.36.36.388.928.083 1.32l-.083.094-6 6c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L13.585 12 8.293 6.707c-.36-.36-.388-.928-.083-1.32l.083-.094z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-339 -280) translate(29 223) translate(310 57)">
              <path d="M0 0H24V24H0z" />
              <mask id="kjevdmunpb" fill="#fff">
                <use xlinkHref="#d79jcrmvfa" />
              </mask>
              <g fill={color} mask="url(#kjevdmunpb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChevronDarkRightIcon;
