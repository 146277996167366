import { axiosHelper } from 'Services/AxiosHelper';
import { changeCardNickNameBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'b2c.ChangeCardNickName';
const changeCardNickName = async (params: changeCardNickNameBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      headers: { 'Content-Type': 'application/json;charset-UTF-8' },
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default changeCardNickName;
