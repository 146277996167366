import * as React from 'react';

function CreditCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className="creditCardIcon"
      {...props}
    >
      <defs>
        <path
          id="to5ci15vga"
          d="M17 0H3C1.343 0 0 1.343 0 3v8c0 1.657 1.343 3 3 3h14c1.657 0 3-1.343 3-3V3c0-1.657-1.343-3-3-3zM9 10H5c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1zm6 0h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1zm3-6H2V3c0-.552.448-1 1-1h14c.552 0 1 .448 1 1v1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-171 -553) translate(155 530) translate(16 22.014) translate(0 1)"
                />
                <g transform="translate(-171 -553) translate(155 530) translate(16 22.014) translate(0 1) translate(2 5)">
                  <mask id="2dld2qgxab" fill="#fff">
                    <use xlinkHref="#to5ci15vga" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#to5ci15vga" />
                  <g fill="currentColor" mask="url(#2dld2qgxab)">
                    <path d="M0 0H24V24H0z" transform="translate(-2 -5)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CreditCardIcon;
