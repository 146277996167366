import * as React from 'react';

function PhotoSuccessIcon(props: React.SVGProps<SVGSVGElement>) {
  const randomId = Math.floor(Math.random() * 1000) + '_' + Math.floor(Math.random() * 100);
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M15,2.5 C8.09644063,2.5 2.5,8.09644063 2.5,15 C2.5,21.9035594 8.09644063,27.5 15,27.5 C21.9035594,27.5 27.5,21.9035594 27.5,15 C27.5,11.6847939 26.1830395,8.50536997 23.8388348,6.16116524 C21.49463,3.8169605 18.3152061,2.5 15,2.5 Z M20.375,12.0125 L14.6625,19.5125 C14.4265092,19.8190587 14.0618708,19.9990701 13.675,20.0000182 C13.2902746,20.0020724 12.9260276,19.8268651 12.6875,19.525 L9.6375,15.6375 C9.36285208,15.2847002 9.29728337,14.8121769 9.46549297,14.3979249 C9.63370257,13.983673 10.0101354,13.6906271 10.452993,13.6291749 C10.8958505,13.5677227 11.3378521,13.7472002 11.6125,14.1 L13.65,16.7 L18.375,10.45 C18.647415,10.0927344 19.0897358,9.90793903 19.5353444,9.96522455 C19.980953,10.0225101 20.3621508,10.3131734 20.5353444,10.7277246 C20.708538,11.1422757 20.647415,11.6177344 20.375,11.975 L20.375,12.0125 Z"
          id={`path-${randomId}`}
        ></path>
      </defs>
      <g id="Kart-basvurusu---1.-fotografli-kartlar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Alternatif-1.0-mobile-with-text---2" transform="translate(-286.000000, -427.000000)">
          <g id="Group-7" transform="translate(286.000000, 427.000000)">
            <ellipse id="Oval" fill="#FFFFFF" cx="14.8333333" cy="13.32" rx="8" ry="9.6"></ellipse>
            <g id="element/dot/1">
              <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
              <mask id={`mask-${randomId}`} fill="white">
                <use xlinkHref={`#path-${randomId}`}></use>
              </mask>
              <g id="Shape" fill-rule="nonzero"></g>
              <g id="brand/color/secondary" mask={`url(#mask-${randomId})`} fill="#3AC562">
                <rect id="brand/color/system/green" x="0" y="0" width="30" height="30"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhotoSuccessIcon;
