import { FC, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components/Svg';

import { CardTransactionListTypeEnum } from 'Utils';
import { OrganizationListItemModel } from 'Models';
import { useLocalization } from 'Hooks';
import classNames from 'classnames';
import './NamesDialog.scss';
import { FixedSizeList } from 'react-window';

type NamesDialogProps = {
  dialogTitle?: string;
  bodyData?: OrganizationListItemModel[];
  selectedValue?: any;
  onChange?: any;
  open: boolean;
  close: any;
};

const NamesDialog: FC<NamesDialogProps> = ({ dialogTitle, bodyData, selectedValue, onChange, close, open }) => {
  const { web_account_search } = useLocalization();

  const handleChanges = (type: CardTransactionListTypeEnum, label: string) => {
    onChange(type, label);
  };
  const [searchKey, setSearchKey] = useState<string>('');
  const filteredData = useMemo(
    () =>
      bodyData?.filter((it) => it.OrganizationName.toLocaleLowerCase()?.includes(searchKey?.toLocaleLowerCase())) ?? [],
    [searchKey]
  );

  const onClose = () => {
    setSearchKey('');
    close(false);
  };

  return (
    <Dialog id="dialogCity" onClose={onClose} scroll="paper" keepMounted={false} open={open}>
      <DialogTitle className="dialogCity-search">
        <CloseIcon onClick={() => close(false)} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCity-container">
          <OrganizationBlock
            types={!!searchKey ? filteredData : bodyData}
            searchKey={searchKey}
            selectedValue={selectedValue}
            itemClickHandle={handleChanges}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
const OrganizationBlock: FC<{
  types: OrganizationListItemModel[] | undefined;
  searchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ types, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogCity-container__list">
      {!!types && types.length > 0 && (
        <FixedSizeList height={530} width="100%" itemCount={!!types.length ? types.length : 0} itemSize={52}>
          {({ index, style }) => {
            const item = types[index];
            return (
              <Item
                className="virtual_list"
                style={style}
                key={item.OrganizationId.toString() + index}
                title={item.OrganizationName}
                isSelected={item.OrganizationName === selectedValue}
                onClick={() => itemClickHandle(item.OrganizationId, item.OrganizationName)}
              />
            );
          }}
        </FixedSizeList>
      )}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
  style: any;
  className?: string;
}> = ({ title, onClick, isSelected, style, className }) => {
  return (
    <li style={style} onClick={() => onClick()} itemID="chooseCardApplyOrganizationLabelNameBtnTest">
      <div
        className={classNames({
          'dialogCity-container__list-item': true,
          'dialogCity-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default NamesDialog;
