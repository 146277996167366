import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { Dialog, DialogProps, TextField, DialogContent, DialogTitle } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';
import { turkishCharacterNormalize } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import { onInıtDataStore } from 'Stores/App';
import { onChangeCustomerInfoRequest, UserState, userStore } from 'Stores/User';
import { userInfoTypes } from 'Views/UserInfo/constant';

import './DialogUserJobs.scss';

export type jobsData = {
  JobName: string;
  JobCode: string;
};

type DialogUserJobsProps = {
  hide: () => void;
  isOpen: boolean;
  currentJob?: string;
};

const DialogUserJobs: FunctionComponent<DialogUserJobsProps> = memo(({ hide, isOpen, currentJob }) => {
  const dispatch = useDispatch();

  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const lowerCaseSearchKey = searchKey.toLocaleLowerCase();

  const userData: UserState = useSelector(userStore);

  const customerNumber: number | undefined = userData.customerNumber;
  const sessionToken: string | undefined = userData.sessionToken;

  const onInıtData = useSelector(onInıtDataStore);

  const jobs: jobsData[] | undefined = onInıtData.JobList;

  const itemClickHandle = (jobCode: string) => {
    dispatch(
      onChangeCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        Job: jobCode,
        TypeOfField: userInfoTypes.job,
      })
    );
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogUserJobs" onClose={onClose} scroll="paper" keepMounted={false} open={isOpen}>
      <DialogTitle className="dialogUserJobs-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogUserJobs-container">
          <JobBlock
            jobs={jobs}
            searchKey={searchKey}
            currentJob={currentJob}
            lowerCaseSearchKey={lowerCaseSearchKey}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
});

const JobBlock: FunctionComponent<{
  jobs: jobsData[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  currentJob?: string;
  itemClickHandle: (jobCode: string) => void;
}> = memo(({ jobs, lowerCaseSearchKey, currentJob, itemClickHandle }) => {
  return (
    <ul className="dialogUserJobs-container__list">
      {!!jobs &&
        jobs.length > 0 &&
        jobs
          .filter(({ JobName }) =>
            turkishCharacterNormalize(JobName)
              .toLocaleLowerCase()
              .includes(turkishCharacterNormalize(lowerCaseSearchKey))
          )
          .map(({ JobName, JobCode }, index) => (
            <JobItem
              key={index}
              title={JobName}
              isSelected={JobCode === currentJob}
              onClick={() => itemClickHandle(JobCode)}
            />
          ))}
    </ul>
  );
});

const JobItem: FunctionComponent<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = memo(({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="changeUserJobBtnTest">
      <div
        className={classNames({
          'dialogUserJobs-container__list-item': true,
          'dialogUserJobs-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
});

export default DialogUserJobs;
