import { FC, useCallback, useEffect } from 'react';
import { ArrowLeftIcon } from 'Components';
import { Box, Button, Typography } from '@material-ui/core';

import './MobilePaymentOrder.scss';
import { gutters } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLanguage, useLocalization, useQuery } from 'Hooks';
import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { MasterPassTokenEnum, generateLanguageParam } from 'Utils';
import { masterPassRecurringPaymentNormalize } from 'Normalize/MasterPassRequestNormalize';
import { routePath } from 'Navigator/routes';
import { onInıtDataRequest, setLanguage } from 'Stores/App';

type MobilePaymentOrderProps = {};

const MobilePaymentOrder: FC<MobilePaymentOrderProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useQuery();
  const i18n = useLanguage();

  const {
    web_automatic_instruction_edit_page_title,
    web_automatic_instruction_apply_instruction,
    web_automatic_instruction_card_to_be_loaded,
    web_automatic_instruction_payment_instrument,
    web_automatic_instruction_lower_limit,
    web_automatic_instruction_loading_amount,
    web_automatic_instruction_edit_dipnot,
    web_automatic_instruction_edit_apply_instruction,
    web_automatic_instruction_edit_giveup_instruction,
    web_instruction_transaction_fee,
  } = useLocalization();

  const cardName = url.get('cardName');
  const cardNo = url.get('cardNo');
  const nickName = url.get('nickName');
  const aliasNumber = url.get('aliasNumber');
  const lowerCredit = url.get('lowerCredit');
  const endDate = url.get('endDate');
  const phoneNumber = url.get('phoneNumber');
  const credit = url.get('credit');
  const amount = url.get('amount');
  const language = url.get('language');
  const actionType = url.get('actionType');
  const productId = url.get('productId');
  const token = url.get('token');
  const reqRefNumber = url.get('reqRefNumber');
  const transactionFee = url.get('transactionFee');

  const handleBackAction = useCallback(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        navigateBack: true,
      })
    );
  }, []);

  const handleSubmit = () => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        submitClick: true,
      })
    );
    setTimeout(() => {
      dispatch(
        onGetMasterpassForMobileRequest({
          TokenType: MasterPassTokenEnum.INITIATE_RECURRING_PAYMENT,
          request: {
            data: masterPassRecurringPaymentNormalize(
              phoneNumber || '',
              token || '',
              cardName || '',
              amount || '',
              endDate || '',
              actionType || '',
              productId || '',
              reqRefNumber || '',
              transactionFee || ''
            ),
          },
        })
      );
    }, 1000);
  };

  useEffect(() => {
    if (!phoneNumber || !token) {
      history.push(routePath.welcome);
    }
    if (language) {
      i18n.changeLanguage(language || '');
      dispatch(setLanguage());
      dispatch(onInıtDataRequest(generateLanguageParam()));
    }
  }, []);

  const getInfoText = useCallback(() => {
    const infotext = web_automatic_instruction_edit_dipnot.split(/(http[s]?:\/\/\S+)/g);
    const infoTextFiltered = infotext.map((item, index) => {
      if (item.startsWith('http://') || item.startsWith('https://')) {
        return (
          <a
            style={{
              color: '#383c52',
            }}
            target="_blank"
            href={item}
            key={index}
          >
            {item}
          </a>
        );
      } else {
        return item;
      }
    });

    return infoTextFiltered;
  }, []);

  return (
    <Box className="paymentOrderWrapper">
      <div id="paymentOrder">
        <Box className="paymentOrder__header">
          <ArrowLeftIcon onClick={handleBackAction} />
          <Typography variant="h3" align="center">
            <Box fontWeight={600} marginBottom={gutters.small} marginTop={gutters.regular}>
              {actionType === 'U'
                ? web_automatic_instruction_edit_page_title
                : web_automatic_instruction_apply_instruction}
            </Box>
          </Typography>
        </Box>
        <Box className="paymentOrder__content">
          <Box className="content__row">
            <Box className="content__row__left">
              <p className="content__row__title">{web_automatic_instruction_card_to_be_loaded}</p>
            </Box>
            <Box className="content__row__right">
              <p className="content__row__value">{nickName}</p>
              <span className="content__row__value__desc">{aliasNumber}</span>
            </Box>
          </Box>
          <Box className="content__row">
            <Box className="content__row__left">
              <p className="content__row__title">{web_automatic_instruction_payment_instrument}</p>
            </Box>
            <Box className="content__row__right">
              <p className="content__row__value">{cardName}</p>
              <span className="content__row__value__desc">{cardNo}</span>
            </Box>
          </Box>
          <Box className="content__row">
            <Box className="content__row__left">
              <p className="content__row__title">{web_automatic_instruction_loading_amount}</p>
            </Box>
            <Box className="content__row__right">
              <p className="content__row__value">{credit}</p>
            </Box>
          </Box>
          <Box className="content__row">
            <Box className="content__row__left">
              <p className="content__row__title">{web_automatic_instruction_lower_limit}</p>
            </Box>
            <Box className="content__row__right">
              <p className="content__row__value">{lowerCredit}</p>
            </Box>
          </Box>
          <Box className="content__row">
            <Box className="content__row__left">
              <p className="content__row__title">{web_instruction_transaction_fee}</p>
            </Box>
            <Box className="content__row__right">
              <p className="content__row__value">{transactionFee}</p>
            </Box>
          </Box>
          <Box className="content__row borderBottom">
            <p className="content__row__info__text">{getInfoText()}</p>
          </Box>
        </Box>
      </div>
      <div className="button__container">
        <Button
          className="lighttheme"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleBackAction}
          itemID="dialogOtpBtnTest"
        >
          {web_automatic_instruction_edit_giveup_instruction}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSubmit}
          type="submit"
          itemID="dialogOtpBtnTest"
        >
          {web_automatic_instruction_edit_apply_instruction}
        </Button>
      </div>
    </Box>
  );
};

export default MobilePaymentOrder;
