import * as React from 'react';

function PointListMapIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="bvwjagqfsa"
          d="M20.41 5.89l-4-1.8h-.82L12 5.7 8.41 4.09h-.05L8.24 4h-.6l-4 1.8c-.41.157-.669.562-.64 1V19c0 .34.174.656.46.84.161.104.348.16.54.16.141 0 .281-.031.41-.09L8 18.3l3.59 1.61h.05c.228.107.492.107.72 0h.05L16 18.3l3.59 1.61c.129.059.269.09.41.09.192 0 .379-.056.54-.16.286-.184.46-.5.46-.84V6.8c0-.393-.232-.749-.59-.91zM9 6.55l2 .89v10l-2-.89v-10zm10 10.9l-2-.89v-10l2 .89v10z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-1121.000000, -231.000000) translate(1105.000000, 222.000000) translate(16.000000, 9.000000)">
              <path d="M0 0H24V24H0z" />
              <mask id="2j2srml5jb" fill="#fff">
                <use xlinkHref="#bvwjagqfsa" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#bvwjagqfsa" />
              <g fill="#383C52" mask="url(#2j2srml5jb)">
                <path d="M0 0H24V24H0z" transform="translate(0.142136, 0.142136)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PointListMapIcon;
