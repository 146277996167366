import { FC, Fragment, useState } from 'react';
import { ChevronDarkRightIcon, DashboardLayout, DialogWithActions } from 'Components';
import { useCardType, useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CustomerCardApplicationListItemModel } from 'Models';
import './ProductType.scss';
import iconProfile from '../../../Assets/Icons/icon-profile.svg';
import iconPeople from '../../../Assets/Icons/icon-people.svg';
import { useHistory } from 'react-router';
import { ApprovalStatusEnum } from '../../../Utils';
import {
  CardTransactionsState,
  onOnlineApplicationRequest,
  storeCardTransactions,
} from '../../../Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from '../../../Stores/User';

type CardApplicationItemProps = {
  checkAlreadyApplicationStatus: (event: OnOnlineApplicationCardQueryItemModel) => void;
  OnOnlineApplicationCardQueryItemModel: (event: OnOnlineApplicationCardQueryItemModel) => void;
};

const ProductType: FC<CardApplicationItemProps> = ({ OnOnlineApplicationCardQueryItemModel }) => {
  // const { cardPath } = useCardType(item.ProductCode);
  const { web_card_application_title, web_card_application_desc } = useLocalization();
  const history = useHistory<any>();
  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const [activeApplication, setActiveApplicaton] = useState<boolean>(false);
  // console.log('ITEM', item);
  // console.log('history', history.location.state.item);
  const currentItem = history?.location?.state?.item as OnOnlineApplicationCardQueryItemModel;
  const { customerCardApplicationList } = cardData;
  const checkCardStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    dispatch(
      onOnlineApplicationRequest({
        request: { CustomerNumber: customerNumber, SessionToken: sessionToken, ProductCode: Product.ProductCode },
        product: Product,
      })
    );
  };
  const checkAlreadyApplicationStatus = (Product: OnOnlineApplicationCardQueryItemModel) => {
    const alreadyData = customerCardApplicationList?.find((item) => item.ProductCode === Product.ProductCode);

    if (alreadyData) {
      if (alreadyData?.ApprovalStatus === ApprovalStatusEnum.WAS_CREATED) {
        checkCardStatus(Product);
      } else {
        setActiveApplicaton(true);
      }
    } else {
      checkCardStatus(Product);
    }
  };
  return (
    <DashboardLayout backButton>
      <Fragment>
        <Typography variant="h3" align="center">
          <Box fontWeight={600}>{web_card_application_title}</Box>
        </Typography>
        <Typography variant="h3" align="center">
          <Box mb={gutters.big} color={colors.checkboxLabel} fontSize={fontSizes.regular} mt={gutters.small}>
            {web_card_application_desc}?
          </Box>
        </Typography>
        <div
          style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}
          // key={applicationCard?.ProductName}
          onClick={() => checkAlreadyApplicationStatus(currentItem)}
          className="cardApplicationItem__item"
          itemID="applyCardBtnTest"
        >
          <div className="cardApplicationItem__item__first-row">
            <img className="showcase__img" src={iconProfile} alt="" />
            <Typography variant="h3" align="center">
              <Box
                style={{ textTransform: 'capitalize' }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={600}
              >
                Kendim adına başvuracağım
              </Box>
              <Box
                style={{ textTransform: 'capitalize', marginRight: 120 }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={300}
              >
                Lorem ipsum
              </Box>
            </Typography>
          </div>
          <ChevronDarkRightIcon />
        </div>
        <div
          style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}
          // key={applicationCard?.ProductName}
          onClick={() => history.push('/card-info', { item: currentItem })}
          className="cardApplicationItem__item"
          itemID="applyCardBtnTest"
        >
          <div className="cardApplicationItem__item__first-row">
            <img className="showcase__img" src={iconPeople} alt="" />
            <Typography variant="h3" align="center">
              <Box
                style={{ textTransform: 'capitalize' }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={600}
              >
                Yakınım adına başvuracağım
              </Box>
              <Box
                style={{ textTransform: 'capitalize', marginRight: 120 }}
                ml={gutters.regular}
                fontSize={fontSizes.regular}
                fontWeight={300}
              >
                Lorem ipsum
              </Box>
            </Typography>
          </div>
          <ChevronDarkRightIcon />
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default ProductType;
