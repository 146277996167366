import { FC, memo } from 'react';
import { useLocalization } from 'Hooks';
import { Box } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { useSelector } from 'react-redux';
import { userStore } from 'Stores/User';
import { get } from 'lodash';

import './UserName.scss';

type UserNameProps = {};

const UserName: FC<UserNameProps> = memo(() => {
  const { web_label_name_surname } = useLocalization();
  const userData = useSelector(userStore);

  const name = get(userData, 'name');
  const surname = get(userData, 'surname');

  return (
    <Box id="userName">
      <InfoItem
        title={web_label_name_surname}
        value={`${name?.toLocaleLowerCase('tr-TR')} ${surname?.toLocaleLowerCase('tr-TR')}`}
        valueClass="nameSurname"
      />
    </Box>
  );
});
export default UserName;
