import { FC, memo } from 'react';
import { InfoItem } from '../InfoItem';
import { FormWrapper, InputPhone, DialogWithInput, FormErrorWrapper } from 'Components';
import { Box, Button } from '@material-ui/core';
import {
  UpdatePhoneNumberSchemaInitialValues,
  UpdatePhoneNumberSchema,
  UpdatePhoneNumberSchemaInput,
} from 'Validations';
import { Formik } from 'formik';
import { useErrorMessage, useLocalization } from 'Hooks';
import { formikGlobalConfig, formNames } from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { storeWelcome, WelcomeState } from 'Stores/Welcome';
import { normalizePhoneNumber } from './constant';
import { AccountState, showHideUserPhoneDialog, storeAccount } from 'Stores/Account';
import { onChangeCustomerInfoRequest, UserState, userStore } from 'Stores/User';
import { userInfoTypes } from '../constant';
import { spaceRemover } from 'Utils';

type UserPhoneNumberProps = {};

const UserPhoneNumber: FC<UserPhoneNumberProps> = memo(() => {
  const { getError, getErrorMessage } = useErrorMessage();
  const { web_label_phone_number, web_btn_kaydet } = useLocalization();

  const dispatch = useDispatch();

  const accountState: AccountState = useSelector(storeAccount);
  const phoneDialogIsOpen: boolean = accountState?.isPhoneNumberDialogOpen;

  const userData: UserState = useSelector(userStore);
  const customerNumber: number | undefined = userData?.customerNumber;
  const sessionToken: string | undefined = userData?.sessionToken;

  const welcomeData: WelcomeState = useSelector(storeWelcome);
  const countryCode: string = welcomeData?.CountryCode;
  const phoneNumber: string = welcomeData?.Cellphone;

  const handleSubmit = (values: UpdatePhoneNumberSchemaInput) => {
    dispatch(
      onChangeCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CountryCode: values.countryCode,
        Cellphone: spaceRemover(values.phone),
        TypeOfField: userInfoTypes.phone,
      })
    );
  };

  return (
    <Box id="userPhoneNumber">
      <InfoItem
        title={web_label_phone_number}
        value={normalizePhoneNumber(countryCode, phoneNumber)}
        onClick={() => dispatch(showHideUserPhoneDialog(true))}
      />
      <DialogWithInput
        open={phoneDialogIsOpen}
        title={web_label_phone_number}
        hide={() => dispatch(showHideUserPhoneDialog(false))}
        closeIcon
      >
        <FormErrorWrapper resetFormOnRouteChange formName={formNames.updateUserPhoneNumber}>
          <Formik
            // For initial value need 2 spaces for validation
            initialValues={UpdatePhoneNumberSchemaInitialValues(` ${phoneNumber} `, countryCode)}
            validationSchema={UpdatePhoneNumberSchema}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {({
              values: { phone, countryCode },
              handleChange,
              setFieldValue,
              handleBlur,
              touched: { phone: touchedPhone },
              errors: { phone: errorPhone },
              handleSubmit,
            }) => {
              return (
                <FormWrapper onSubmit={handleSubmit}>
                  <Box className="userPhoneNumber__input">
                    <InputPhone
                      name="phone"
                      value={spaceRemover(phone)}
                      onChange={handleChange}
                      setCountryCode={setFieldValue}
                      countryCode={countryCode}
                      countryCodeField={'countryCode'}
                      resetPhone={() => setFieldValue('phone', '')}
                      error={getError(errorPhone, touchedPhone)}
                      helperText={getErrorMessage(errorPhone, touchedPhone)}
                      onBlur={handleBlur}
                      itemID="changeUserPhoneInputTest"
                    />
                  </Box>
                  <Button variant="contained" color="secondary" type="submit" fullWidth itemID="changeUserPhoneBtnTest">
                    {web_btn_kaydet}
                  </Button>
                </FormWrapper>
              );
            }}
          </Formik>
        </FormErrorWrapper>
      </DialogWithInput>
    </Box>
  );
});
export default UserPhoneNumber;
