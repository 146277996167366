import { FC } from 'react';
import { DarkPlusIcon, CreditCardIconLarge } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { gutters } from 'Theme/Variables';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

import './NoCreditCard.scss';

type NoCardsProps = {};

const NoCards: FC<NoCardsProps> = () => {
  const history = useHistory();

  const {
    web_title_no_registered_credit_card,
    web_desc_no_registered_credit_card,
    web_btn_add_credit_card,
  } = useLocalization();

  return (
    <div id="noCreditCards">
      <Box marginBottom={gutters.regular}>
        <CreditCardIconLarge />
      </Box>
      <Typography variant="h3" align="center">
        <Box fontWeight={600} marginBottom={gutters.regular}>
          {web_title_no_registered_credit_card}
        </Box>
      </Typography>
      <Box>
        <Typography variant="body1" align="center">
          {web_desc_no_registered_credit_card}
        </Typography>
      </Box>
      <div className="add--card" onClick={() => history.push(routePath.addCreditCard)} itemID="addCreditCardBtnTest">
        <DarkPlusIcon />
        <Box flex={1}>
          <Typography variant="h6" align="center">
            <Box fontWeight={600}>{web_btn_add_credit_card}</Box>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default NoCards;
