import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  onAddApplicationBodyData,
  onGetShippingLocationInfoListBodyData,
  onOnlineApplicationQueryBodyData,
  OnUpdateApplicationBodyData,
  onUpdateCustomerAddressReqBodyData,
} from 'Config';
import {
  OnGetShippingLocationInfoListModel,
  OnOnlineApplicationCardQueryItemModel,
  OnUpdateCustomerAdressModel,
  ProductPriceModel,
} from 'Models';
import { RootState } from 'Stores';
import { ApplicationProcessState } from './types';

const initialState: ApplicationProcessState = {
  loading: false,
  deliveryId: 0,
  activeApplicationData: {
    CustomerNumber: undefined,
    SessionToken: undefined,
    ProductCode: undefined,
    SubProductCode: undefined,
    ApplicationReasonId: 0,
    OrganizationId: undefined,
    OrganizationTypeId: undefined,
    AddressDetail: {
      AddressType: 9,
      CountryCode: 90,
      CityCode: 34,
      City: undefined,
      TownCode: undefined,
      Town: undefined,
      District: undefined,
      Street: undefined,
      AddressDetail: undefined,
      PostalCode: undefined,
    },
    MimeType: '',
    FileName: undefined,
    ReceiptNumber: '',
    DocumentData: undefined,
    ApplicationCenterId: undefined,
    ShippingFee: 9.3,
    IsFree: false,
    ExpenseAmount: undefined,
    ShippingLocationDetailId: 0,
  },
  cardApplicationDetailData: undefined,
  updateApplicationData: undefined,
  updateApplicationId: undefined,
  step: 0,
  isShipping: true,
  applicationCard: undefined,
  paymentInformationData: undefined,
  productPriceResponse: undefined,
  h: undefined,
  activeCardShippingFee: undefined,
  activeUpStateShippingFee: undefined,
  onGetShippingLocationInfoList: undefined,
  campusId: 0,
  institutionType: '',
  applicationEmtpyAdressError: false,
  cardApplicationStatus: 0,
  emptyStreetError: false,
  subProductList: [],
};

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setApplicationData: (state: ApplicationProcessState, { payload }: PayloadAction<onAddApplicationBodyData>) => {
      state.activeApplicationData = { ...state.activeApplicationData, ...payload };
    },
    clearApplicationData: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<{ withoutCard: boolean; withoutStep?: boolean }>
    ) => {
      const test = 1;
      state.activeApplicationData = {
        CustomerNumber: undefined,
        SessionToken: undefined,
        ProductCode: undefined,
        SubProductCode: undefined,
        ApplicationReasonId: 0,
        OrganizationId: undefined,
        OrganizationTypeId: undefined,
        AddressDetail: {
          AddressType: 9,
          CountryCode: 90,
          CityCode: 34,
          City: undefined,
          TownCode: undefined,
          Town: undefined,
          District: undefined,
          Street: undefined,
          AddressDetail: undefined,
          PostalCode: undefined,
        },
      };
      if (!payload.withoutStep) state.step = 0;
      state.updateApplicationId = undefined;
      state.isShipping = true;
      state.paymentInformationData = undefined;
      state.productPriceResponse = undefined;
      state.deliveryId = 0;
      if (!payload.withoutCard) state.h = undefined;
      if (!payload.withoutCard) state.applicationCard = undefined;
    },
    setDeliveryId: (state: ApplicationProcessState, { payload }: PayloadAction<number>) => {
      state.deliveryId = payload;
      state.isShipping = payload === 0 || payload === 2;
      if (payload === 0) {
        state.activeApplicationData = {
          ...state.activeApplicationData,
          ApplicationCenterId: 0,
          ShippingFee: 9.3,
          ShippingLocationDetailId: 0,
        };
        state.campusId = 0;
      }
      if (payload === 1) {
        state.activeApplicationData = {
          ...state.activeApplicationData,
          ShippingFee: 0,
          ShippingLocationDetailId: 0,
        };
        state.campusId = 0;
      }
      if (payload === 2) {
        state.activeApplicationData = {
          ...state.activeApplicationData,
          ShippingFee: 0,
          ApplicationCenterId: 0,
        };
      }
    },
    setActiveCard: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<OnOnlineApplicationCardQueryItemModel>
    ) => {
      state.applicationCard = payload;
    },
    incStep: (state: ApplicationProcessState) => {
      state.step = state.step + 1;
    },
    decStep: (state: ApplicationProcessState) => {
      state.step = state.step - 1;
    },
    onAddApplicationRequest: (state: ApplicationProcessState) => {
      state.loading = true;
    },
    onAddApplicationSuccess: (state: ApplicationProcessState, { payload }: PayloadAction<number | undefined>) => {
      state.loading = false;
      state.updateApplicationId = payload;
      //state.campusId = 0;
    },
    onAddApplicationFail: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    setPaymentInformationData: (state: ApplicationProcessState, { payload }: PayloadAction<any>) => {
      state.paymentInformationData = payload;
    },
    clearPaymentInformationData: (state: ApplicationProcessState) => {
      state.paymentInformationData = undefined;
    },
    getProductPriceRequest: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<onOnlineApplicationQueryBodyData>
    ) => {
      state.loading = true;
    },
    getProductPriceSuccess: (state: ApplicationProcessState, { payload }: PayloadAction<ProductPriceModel>) => {
      state.loading = false;
      state.productPriceResponse = payload;
    },
    getProductPriceFail: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    setUpdateApplicationId: (state: ApplicationProcessState, { payload }: PayloadAction<number>) => {
      state.updateApplicationId = payload;
    },
    onUpdateApplicationRequest: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<OnUpdateApplicationBodyData | any>
    ) => {
      state.loading = true;
    },
    onUpdateApplicationSuccess: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    onUpdateApplicationFail: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    setH: (state: ApplicationProcessState, { payload }: PayloadAction<string>) => {
      state.h = payload;
    },
    setActiveShippingPrice: (state: ApplicationProcessState, { payload }: PayloadAction<Array<number>>) => {
      state.activeCardShippingFee = payload[0];
      state.activeUpStateShippingFee = payload[1];
    },
    onGetShippingLocationInfoListRequest: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<onGetShippingLocationInfoListBodyData>
    ) => {},
    onGetShippingLocationInfoListSuccess: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<OnGetShippingLocationInfoListModel>
    ) => {
      state.onGetShippingLocationInfoList = payload;
    },
    onGetShippingLocationInfoListFail: (state: ApplicationProcessState) => {},
    setCampusIdRequest: (state: ApplicationProcessState, { payload }: PayloadAction<number>) => {
      state.campusId = payload;
    },
    setInstitutionTypeRequest: (state: ApplicationProcessState, { payload }: PayloadAction<string>) => {
      state.institutionType = payload;
    },
    openApplicationEmtpyAdressError: (state: ApplicationProcessState) => {
      state.applicationEmtpyAdressError = true;
    },
    closeApplicationEmtpyAdressError: (state: ApplicationProcessState) => {
      state.applicationEmtpyAdressError = false;
    },
    setCardApplicationStatus: (state: ApplicationProcessState, { payload }: PayloadAction<number>) => {
      state.cardApplicationStatus = payload;
    },
    openEmptyStreetError: (state) => {
      state.emptyStreetError = true;
    },
    closeEmptyStreetError: (state) => {
      state.emptyStreetError = false;
    },
    resendCargoRequest: (
      state: ApplicationProcessState,
      { payload }: PayloadAction<onUpdateCustomerAddressReqBodyData>
    ) => {
      state.loading = true;
    },
    resendCargoSuccess: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    resendCargoFail: (state: ApplicationProcessState) => {
      state.loading = false;
    },
    resetCardApplicationStatus: (state: ApplicationProcessState) => {
      state.cardApplicationStatus = 0;
    },
    getSubProductList: (state: any, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.subProduct = payload;
    },
    onGetSubProductListSuccess: (state: ApplicationProcessState, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.subProductList = payload;
    },
  },
});

export const applicationStore = (state: RootState) => state.application;

export const {
  setApplicationData,
  setDeliveryId,
  clearApplicationData,
  setActiveCard,
  incStep,
  decStep,
  onAddApplicationRequest,
  onAddApplicationSuccess,
  onAddApplicationFail,
  setPaymentInformationData,
  clearPaymentInformationData,
  getProductPriceRequest,
  getProductPriceSuccess,
  getProductPriceFail,
  setUpdateApplicationId,
  onUpdateApplicationRequest,
  onUpdateApplicationSuccess,
  onUpdateApplicationFail,
  setH,
  setActiveShippingPrice,
  onGetShippingLocationInfoListRequest,
  onGetShippingLocationInfoListSuccess,
  onGetShippingLocationInfoListFail,
  setCampusIdRequest,
  setInstitutionTypeRequest,
  openApplicationEmtpyAdressError,
  closeApplicationEmtpyAdressError,
  setCardApplicationStatus,
  resendCargoRequest,
  resendCargoSuccess,
  resendCargoFail,
  resetCardApplicationStatus,
  openEmptyStreetError,
  getSubProductList,
  closeEmptyStreetError,
  onGetSubProductListSuccess,
} = applicationSlice.actions;

export default applicationSlice.reducer;
