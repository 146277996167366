import { Typography, Box, Checkbox } from '@material-ui/core';
import {
  DashboardLayout,
  DialogWithActions,
  DialogWithInput,
  FormErrorWrapper,
  MasterPassIcon,
  MasterpassLogo,
} from 'Components';
import { useLocalization } from 'Hooks';
import { MasterPassCardType } from 'Models';
import { FC, Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { StaticContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { UserState, userStore } from 'Stores/User';
import { MasterPassTokenEnum } from 'Utils';
import { masterPassDeleteCardNormalize } from 'Normalize';
import { formNames } from 'Config';

import './CreditCardDetail.scss';

type RowProps = {
  title?: string;
  value?: string;
  m?: boolean;
};

type ActionProps = {
  title?: string;
  func: () => void;
};

type CreditCardDetailsParams = {} & MasterPassCardType;

type CreditCardDetailsProps = RouteComponentProps<{}, StaticContext, CreditCardDetailsParams>;

const CreditCardDetail: FC<CreditCardDetailsProps> = ({ location: { state } }) => {
  const dispatch = useDispatch();
  const card = state;
  const userData: UserState = useSelector(userStore);

  const [open, setOpen] = useState<boolean>(false);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const {
    web_title_credit_card_info,
    web_card_name_credit_card_info,
    web_card_no_credit_card_info,
    web_delete_credit_card_info,
    web_title_delete_credit_card_box,
    web_desc_delete_credit_card_box,
    web_btn_approve,
    web_btn_vazgec,
  } = useLocalization();

  const openDialog = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    const item: MasterPassCardType = card;
    dispatch(
      onGetMasterpassTokenRequest({
        TokenType: MasterPassTokenEnum.DELETE_CARD,
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        request: {
          data: masterPassDeleteCardNormalize(item.Name ? item.Name : ''),
        },
      })
    );
    setOpen(false);
  };

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="credit-card-detail">
          <FormErrorWrapper formName={formNames.creditCardDetail} resetFormOnRouteChange>
            <Typography variant="h3">
              <Box marginTop={gutters.big} textAlign="center" fontWeight={600}>
                {web_title_credit_card_info}
              </Box>
            </Typography>
            <Row title={web_card_name_credit_card_info} value={card.Name} />
            <Row title={web_card_no_credit_card_info} value={card.Value1} m />
            <Action func={openDialog} title={web_delete_credit_card_info} />
          </FormErrorWrapper>
          <Box textAlign="right">
            <MasterpassLogo className="credit-card-detail__masterpassLogo" />
          </Box>
        </div>
        <DialogWithActions
          alignTitle="left"
          masterpassLogo
          closeIcon
          text={web_desc_delete_credit_card_box}
          open={open}
          hide={() => setOpen(false)}
          title={web_title_delete_credit_card_box}
          primaryButton={{
            label: web_btn_approve,
            handleClick: handleDelete,
          }}
          secondaryButton={{
            label: web_btn_vazgec,
            handleClick: () => setOpen(false),
          }}
        />
      </Fragment>
    </DashboardLayout>
  );
};

export default CreditCardDetail;

const Row = (props: RowProps) => (
  <Box marginTop={props.m ? gutters.small : gutters.large} className="credit-card-detail__box">
    <Typography>
      <Box fontSize="13px" fontWeight="normal" color={colors.checkboxLabel}>
        {props.title}
      </Box>
    </Typography>
    <Typography variant="h3">
      <Box marginTop="5px" fontSize={fontSizes.regular} fontWeight={600}>
        {props.value}
      </Box>
    </Typography>
  </Box>
);

const Action = (props: ActionProps) => (
  <Typography variant="h3">
    <Box onClick={props.func} className="credit-card-detail__action" itemID="showCreditCardDetailBtnTest">
      {props.title}
    </Box>
  </Typography>
);
