import axios, { AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import {
  ApiParamTypes,
  belbimUrl,
  tmobLabsUrl,
  tmobLabsURLStrings,
  ApiBodyDataTypes,
  vposUrl,
  faqUrl,
  campaignUrl,
  newsUrl,
  tagsUrl,
  pinUrl,
  fibaBankUrl,
  fibaBankgetApp,
} from 'Config';
import { getApiGWRefreshToken, getApiGWToken } from 'Services/Queries';

import {
  getApiGWRefreshTokenCookie,
  getEnvironment,
  getServiceQueue,
  getTokenStatus,
  resetServiceQueue,
  setApiGWCookies,
  setServiceQueue,
  setTokenRefreshed,
} from 'Utils';
import { cmsUrl } from 'Config/ServiceConfig';

type AxiosConfig = {
  url?: tmobLabsURLStrings;
  method: 'get' | 'post';
  headers?: AxiosRequestConfig['headers'];
  parameters?: ApiParamTypes;
  data?: ApiBodyDataTypes;
  isTmobLabsUrl?: boolean;
  isVposUrl?: boolean;
  isFaqUrl?: boolean;
  isCampaignUrl?: boolean;
  isNewsUrl?: boolean;
  isTagsUrl?: boolean;
  isPinUrl?: boolean;
  isFibaBankUrl?: boolean;
  isCmsUrl?: boolean;
  isFibaBankgetApp?: boolean;
  gatewayData?: {
    command: string;
    token: string;
  };
} & AxiosRequestConfig;

const axiosInstance = axios.create();

const isDev = getEnvironment();

const fibaBankUserName = isDev ? '0/UeWY1n/J2h4Uxmnp3sIQ==' : 'm3S6wGMb7s1v8mENdTrDdw==';
const fibaBankPassword = isDev ? 'AUURh5dWSCuE9n+vU+61iw==' : 'HBu4O9Cy/lFim1K4Nab/oQ==';

export const axiosHelper = async ({
  isTmobLabsUrl,
  parameters,
  isVposUrl,
  gatewayData,
  isFaqUrl,
  isCampaignUrl,
  isNewsUrl,
  isTagsUrl,
  isPinUrl,
  isFibaBankUrl,
  isCmsUrl,
  isFibaBankgetApp,
  ...config
}: AxiosConfig) => {
  return await axiosInstance({
    ...config,
    headers: {
      'Access-Control-Allow-Origin': '*',
      userName: isFibaBankUrl || isFibaBankgetApp ? fibaBankUserName : '',
      password: isFibaBankUrl || isFibaBankgetApp ? fibaBankPassword : '',
    },
    params: parameters,
    timeout: 30000,
    validateStatus: function (status) {
      return status === 302 || (status >= 200 && status < 300);
    },
    ...(isTmobLabsUrl && {
      data: config.data,
    }),
    ...(gatewayData && {
      data: {
        command: gatewayData.command,
        id: gatewayData.token,
        data: config.data,
      },
    }),
    baseURL: isTmobLabsUrl
      ? tmobLabsUrl
      : isVposUrl
      ? vposUrl
      : isFaqUrl
      ? faqUrl
      : isCampaignUrl
      ? campaignUrl
      : isNewsUrl
      ? newsUrl
      : isTagsUrl
      ? tagsUrl
      : isPinUrl
      ? pinUrl
      : isFibaBankUrl
      ? fibaBankUrl
      : isFibaBankgetApp
      ? fibaBankgetApp
      : isCmsUrl
      ? cmsUrl
      : belbimUrl,
  });
};

axiosInstance.interceptors.response.use(
  async (response) => {
    const status = get(response, 'data.status');
    const statusDetail = get(response, 'data.statusDetail');
    /* NOTE:Bu düzenlemeyi servislerden farklı hatalar alındığında sonsuz istek atmasını engellemek için yaptım.
    Burada farklı buglarda oluşabilir. Bu kısmın detaylı review edilmesi gerekiyor.
    */
    if (status === 'failed' && statusDetail === 'application.userIsNotLoggedIn') {
      response.status = 401;
      const getApiGwTokenResult = await getApiGWToken();
      const { token, refreshToken } = getApiGwTokenResult;
      setApiGWCookies(token, refreshToken);
      const originalRequest = response.config;
      originalRequest.data = { ...JSON.parse(originalRequest.data), id: token };

      return await axiosInstance.request(response.config);
    }

    return response;
  },
  (error) => Promise.reject(error)
);
