import * as React from 'react';

function PhoneCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.14214 14.1421C4.14214 19.665 8.61929 24.1421 14.1421 24.1421C19.665 24.1421 24.1421 19.665 24.1421 14.1421C24.1421 8.61927 19.665 4.14212 14.1421 4.14212C11.49 4.14212 8.94643 5.19569 7.07107 7.07105C5.1957 8.94642 4.14214 11.49 4.14214 14.1421ZM16.6389 18.7405C12.8212 18.7355 9.72764 15.6358 9.72269 11.8106C9.72269 10.6673 10.6476 9.74054 11.7886 9.74054C11.9046 9.73971 12.0204 9.75026 12.1344 9.77209C12.2445 9.78843 12.3528 9.81556 12.4577 9.85309C12.6078 9.90586 12.7188 10.0342 12.7496 10.1906L13.3649 12.8905C13.3986 13.0395 13.3546 13.1953 13.2481 13.3045C13.1898 13.3675 13.1853 13.372 12.6329 13.66C13.0752 14.6324 13.8514 15.4133 14.82 15.8605C15.1119 15.3025 15.1164 15.298 15.1793 15.2395C15.2883 15.1328 15.4438 15.0887 15.5924 15.1225L18.2871 15.739C18.438 15.7741 18.5602 15.8846 18.6104 16.0315C18.6483 16.1383 18.6769 16.2482 18.6957 16.36C18.7138 16.4731 18.7228 16.5875 18.7227 16.702C18.7054 17.8399 17.7746 18.7505 16.6389 18.7405Z"
        fill="#1AAADC"
      />
    </svg>
  );
}

export default PhoneCircle;
