import { FC, Fragment } from 'react';
import {
  ArrowLeftIcon,
  ChevronDarkRightIcon,
  Container,
  DashboardLayout,
  MultiStepProgress,
  StepWrapper,
} from 'Components';
import { useCardName, useCardType, useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { colors, gutters } from 'Theme/Variables';
import AddPhotoStep from './AddPhotoStep/AddPhotoStep';
import ProductTypeStep from './ProductTypeStep/ProductTypeStep';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { DeliveryStep } from './DeliveryStep';
import { PaymentStep } from './PaymentStep';
import { OrganisationStep } from './OrganisationStep';
import { useDispatch, useSelector } from 'react-redux';
import { ReportStep } from './ReportStep';
import { useEffect } from 'react';
import { UserState, userStore } from 'Stores/User';
import { getCityListRequest, getTownOtherListRequest, onGetCustomerInfoRequest } from 'Stores/Account';
import { onOnlineApplicationRequest, storeCardTransactions } from 'Stores/CardTransactions';
import { applicationStore, decStep, getProductPriceRequest, getSubProductList } from 'Stores/CardApplication';
import CardApplicationErrors from 'Views/CardApplication/CartApplicationErrors';
import { CardProductCode } from 'Models/OnOnlineApplicationCardQueryModel';

import './CardApplicationSteps.scss';
import { routePath } from 'Navigator/routes';

type CardApplicationStepsProps = RouteComponentProps<{}, StaticContext>;

const CardApplicationSteps: FC<CardApplicationStepsProps> = ({ location: { state } }: any) => {
  const history = useHistory<any>();
  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const { HideOrganizationStep, CustodyCustomerNumber, HasCustodyRelation } = useSelector(storeCardTransactions);
  const { activeApplicationData, applicationCard, step, cardApplicationStatus } = useSelector(applicationStore);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const historyItemType = history.location.state?.item;
  const relationShipApply = history.location.state?.relationShipApply ?? false;
  const { cardColor, cardCode } = useCardType(applicationCard?.ProductCode || historyItemType?.ProductCode);
  const currentCard = applicationCard || historyItemType;
  const CardPrintType = currentCard?.CardPrintType;
  const isTeacherCard = currentCard?.ProductCode === 'IKT_ORT';
  const productName = useCardName(currentCard?.ProductCode, currentCard?.SubProductCode, true);

  const isUpdateCard = !!activeApplicationData?.CustomerNumber;
  const {
    web_card_application_progressbar_1,
    web_card_application_progressbar_2,
    web_card_application_progressbar_3,
  } = useLocalization();
  useEffect(() => {
    dispatch(
      onGetCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );

    if (applicationCard?.ProductCode) {
      dispatch(
        getProductPriceRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          ProductCode: applicationCard?.ProductCode || '',
          SubProductCode: applicationCard?.SubProductCode || '',
          CustodyCustomerNumber: CustodyCustomerNumber ? CustodyCustomerNumber : undefined,
        })
      );
      dispatch(
        getSubProductList({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          ProductCode: applicationCard?.ProductCode || '',
        })
      );
      dispatch(
        getSubProductList({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          ProductCode: applicationCard?.ProductCode || '',
        })
      );
    }

    dispatch(
      getTownOtherListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CityId: String(activeApplicationData?.AddressDetail?.CityCode),
      })
    );
  }, [activeApplicationData?.AddressDetail]);
  useEffect(() => {
    if (
      !activeApplicationData?.CustomerNumber &&
      !activeApplicationData?.ProductCode &&
      !activeApplicationData?.SubProductCode &&
      !activeApplicationData?.SessionToken &&
      step != 0
    ) {
      history.push(routePath.cardApplication);
    }
    dispatch(
      getCityListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    if (!isUpdateCard && history.location?.state?.status == true && history.location?.state?.custody == undefined) {
      dispatch(
        onOnlineApplicationRequest({
          request: {
            CustomerNumber: customerNumber,
            SessionToken: sessionToken,
            ProductCode: currentCard?.ProductCode,
            SubProductCode: history.location?.state.stepData?.SubProductCode || null,
          },
          product: currentCard,
        })
      );
    }
  }, []);

  const organizationStep = {
    children: <OrganisationStep key="0" />,
    showTypes: '3',
    title: 'Kurum Bilgileri',
  };

  const data = [
    {
      children: <ProductTypeStep relationShipApply={relationShipApply} key="-1" />,
      showTypes: '0, 3',
      title: 'Ürün Tipi',
    },
    {
      children: <ReportStep key="123" />,
      showTypes: '4',
      title: 'Kullanıcı Bilgileri',
    },
    ...(!HideOrganizationStep ? [organizationStep] : []),
    {
      children: <AddPhotoStep key="1" />,
      showTypes: '0, 1, 3, 4, 5',
      title: web_card_application_progressbar_1,
    },
    {
      children: <DeliveryStep key="4" />,
      showTypes: '0, 1, 2, 3, 4, 5',
      title: web_card_application_progressbar_2,
    },
    {
      children: <PaymentStep key="5" />,
      showTypes: '0, 1, 2, 3, 4, 5',
      title: web_card_application_progressbar_3,
    },
  ];

  const createStepData = () => {
    const type = CardPrintType;
    const tempData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const inner = element.showTypes.includes(String(type));
      if (inner) {
        tempData.push(element);
      }
    }
    return tempData;
  };

  const createTitleData = () => {
    const type = CardPrintType;
    const tempData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const inner = element.showTypes.includes(String(type));
      if (inner) {
        tempData.push(element.title);
      }
    }
    return tempData;
  };

  const goBack = () => {
    if (isUpdateCard) {
      if (step === 1) {
        history.goBack();
        return;
      }

      if (
        (applicationCard?.ProductCode === CardProductCode.OGRENCI ||
          applicationCard?.ProductCode === CardProductCode.OGRETMEN) &&
        step === 2
      ) {
        history.goBack();
        return;
      }
    }
    if (step === 0) {
      history.goBack();
    } else {
      dispatch(decStep());
    }
  };

  const liveData = createStepData();
  const liveTitles = createTitleData();
  return (
    <DashboardLayout marginTop={0} noContainer>
      <Fragment>
        <CardApplicationErrors />
        <div id="cardApplicationSteps">
          <div style={{ backgroundColor: cardCode ? cardCode : cardColor }} className="cardApplicationSteps__bg" />
          <Container>
            {cardApplicationStatus === 6 &&
            (step === 1 ||
              ((applicationCard?.ProductCode === CardProductCode.OGRENCI ||
                applicationCard?.ProductCode === CardProductCode.OGRETMEN) &&
                step === 2)) ? (
              <div></div>
            ) : (
              <ArrowLeftIcon onClick={() => goBack()} className="cardApplicationSteps__back" />
            )}

            <div className="cardApplicationSteps">
              <Typography variant="h3" align="center">
                <Box mt={gutters.big} mb={gutters.big} color={colors.whiteGray} fontWeight={600}>
                  {productName}
                </Box>
              </Typography>
              <StepWrapper noStyle>
                <div className="cardApplicationSteps__stepper">
                  <MultiStepProgress titles={liveTitles} activeStep={step} />
                </div>
                {liveData.map((item, index) => step === index && item.children)}
              </StepWrapper>
            </div>
          </Container>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default CardApplicationSteps;
