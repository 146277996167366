import { localStorageKeys } from 'Config';

const languageId = () => {
  const language = localStorage.getItem(localStorageKeys.i18nextLng);
  const languageId = language === 'en' ? '1' : '2';
  if (!language) {
    localStorage.setItem(localStorageKeys.i18nextLng, languageId === '1' ? 'en' : 'tr');
  }
  return languageId;
};

export default languageId;
