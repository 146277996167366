import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { InfoIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { gutters } from 'Theme/Variables';

import './ListEmpty.scss';

const ListEmpty: FC = () => {
  const { web_transaction_not_found } = useLocalization();
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <InfoIcon width={64} height={64} />

      <Typography variant="h3" align="center">
        <Box className="cardTransactionHistoryEmpty__container" marginTop={gutters.regular}>
          {web_transaction_not_found}
        </Box>
      </Typography>
    </Box>
  );
};
export default ListEmpty;
