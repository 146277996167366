import * as React from 'react';

function AbonmanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="abonmanIcon"
      {...props}
    >
      <defs>
        <path
          id="ovp54crfpa"
          d="M26.417 11.666h6.321c2.17 0 3.929 1.76 3.929 3.93v18.333c0 2.17-1.76 3.928-3.929 3.928H22.262c-2.17 0-3.929-1.759-3.929-3.928v-7.974c4.855-1.716 8.334-6.346 8.334-11.788 0-.857-.086-1.693-.25-2.5zm-.227 9.313c0 5.245 8.864 10.96 9.16 11.15l.007.004V17.551c-3.65-3.71-9.167-1.906-9.167 3.428zM14.167 3.333C20.15 3.333 25 8.183 25 14.167 25 20.15 20.15 25 14.167 25c-2.874 0-5.629-1.141-7.66-3.173-2.032-2.032-3.174-4.787-3.174-7.66 0-5.983 4.85-10.834 10.834-10.834zm.411 5.504c-.262-.116-.56-.116-.823 0-.064.025-.122.062-.173.108-.06.026-.116.062-.163.108l-3.25 3.099c-.434.412-.45 1.099-.038 1.533.413.433 1.1.45 1.533.037l1.42-1.354V18.5c0 .598.484 1.083 1.083 1.083.598 0 1.083-.485 1.083-1.083v-6.056l1.398 1.408c.424.425 1.113.425 1.538 0 .425-.424.425-1.113 0-1.538l-3.25-3.25c-.103-.098-.225-.176-.358-.227z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-604 -263) translate(536 243) translate(68 20)">
              <path d="M0 0H40V40H0z" />
              <mask id="dhxjk7anlb" fill="#fff">
                <use xlinkHref="#ovp54crfpa" />
              </mask>
              <use fill="#383C52" fillRule="nonzero" xlinkHref="#ovp54crfpa" />
              <g fill="currentColor" mask="url(#dhxjk7anlb)">
                <path d="M0 0H40V40H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AbonmanIcon;
