import { FC, Fragment, useEffect } from 'react';
import { Container, SemiContainer, IstanbulDarkLogo, MailIcon, GreenTickBigIcon, ErrorCircleIcon } from 'Components';
import { useLocalization, useQuery } from 'Hooks';
import { Box, Typography, Button } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { AccountState, onConfirmEmailRequest, setEmailValidationStep, storeAccount } from 'Stores/Account';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './EmailValidation.scss';

type EmailValidationProps = {};
const EmailValidation: FC<EmailValidationProps> = ({}) => {
  const {
    email_update_link_desc,
    email_update_link_button,
    email_update_link_success_title,
    email_update_link_success_desc,
    email_update_link_unsuccessful_title,
    email_update_link_unsuccessful_desc1,
    email_update_link_unsuccessful_desc2,
  } = useLocalization();

  const url = useQuery();
  const queryParam = url.get('data');

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!queryParam) {
      history.push(routePath.welcome);
    }
    dispatch(setEmailValidationStep(0));
  }, []);

  const accountState: AccountState = useSelector(storeAccount);
  const step: number = accountState?.emailValidationStep;

  const handleSubmit = () => {
    dispatch(
      onConfirmEmailRequest({
        EmailData: queryParam,
      })
    );
  };

  return (
    <div id="emailValidation">
      <Box className="emailValidation__header">
        <Container>
          <IstanbulDarkLogo onClick={() => history.push(routePath.welcome)} />
        </Container>
      </Box>
      <SemiContainer>
        <Fragment>
          {step === 0 && (
            <Box>
              <Box className="emailValidation__icon">
                <MailIcon />
              </Box>
              <Typography variant="h3">
                <Box fontWeight="bold" marginBottom={gutters.large}>
                  {email_update_link_desc}
                </Box>
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleSubmit}
                itemID="emailUpdateLinkBtnTest"
              >
                {email_update_link_button}
              </Button>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Box>
                <GreenTickBigIcon />
              </Box>
              <Typography variant="h3">
                <Box fontWeight="bold" marginBottom={gutters.small} fontSize={fontSizes.regularBig}>
                  {email_update_link_success_title}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box fontSize={fontSizes.regular}>{email_update_link_success_desc}</Box>
              </Typography>
            </Box>
          )}
          {step === 2 && (
            <Box>
              <Box>
                <ErrorCircleIcon />
              </Box>
              <Typography variant="h3">
                <Box fontWeight="bold" marginBottom={gutters.small} fontSize={fontSizes.regularBig}>
                  {email_update_link_unsuccessful_title}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box fontSize={fontSizes.regular} marginBottom={gutters.small}>
                  {email_update_link_unsuccessful_desc1}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box fontSize={fontSizes.regular} marginBottom={gutters.small}>
                  {email_update_link_unsuccessful_desc2}
                </Box>
              </Typography>
            </Box>
          )}
        </Fragment>
      </SemiContainer>
    </div>
  );
};
export default EmailValidation;
