import * as yup from 'yup';
import { errorMessages } from 'Config';

export const ChangeCardNickNameSchema = yup.object({
  cardNickName: yup.string().required(errorMessages.require).min(1, errorMessages.require),
});

export type ChangeCardNickNameSchemaInput = yup.InferType<typeof ChangeCardNickNameSchema>;

export const ChangeCardNickNameSchemaInputInitialValues = (nickName: string) => {
  return { cardNickName: nickName };
};
