import * as React from 'react';

function ArrowUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="arrowUpIcon"
      {...props}
    >
      <defs>
        <path
          id="98pzv5lvza"
          d="M20 36.667c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 4.42 1.756 8.66 4.882 11.785C11.34 34.911 15.58 36.667 20 36.667zM13.85 16.9l5-4.767c.072-.07.157-.127.25-.166.078-.071.169-.128.267-.167.403-.178.863-.178 1.266 0 .205.08.392.198.55.35l5 5c.654.654.654 1.713 0 2.367-.653.653-1.713.653-2.366 0l-2.15-2.167v9.317c0 .92-.747 1.666-1.667 1.666s-1.667-.746-1.667-1.666v-9.434l-2.183 2.084c-.667.635-1.723.609-2.358-.059-.635-.667-.61-1.723.058-2.358z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-414 -263) translate(346 243) translate(68 20)">
              <path d="M0 0H40V40H0z" />
              <mask id="cjxkp6ce5b" fill="#fff">
                <use xlinkHref="#98pzv5lvza" />
              </mask>
              <g fill="currentColor" mask="url(#cjxkp6ce5b)">
                <path d="M0 0H40V40H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowUpIcon;
