import * as yup from 'yup';
import { errorMessages } from 'Config';

export const UpdateIbanNumberSchema = yup.object({
  ibanNumber: yup.string().required(errorMessages.require).min(32, errorMessages.minIban),
});

export type UpdateIbanNumberSchemaInput = yup.InferType<typeof UpdateIbanNumberSchema>;

export const UpdateIbanNumberSchemaInputInitialValues = (iban: string) => {
  return { ibanNumber: iban };
};
