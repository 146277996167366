import { Box, Typography } from '@material-ui/core';
import { FC, memo } from 'react';

import './TransactionButton.scss';

type TransactionButtonProps = {
  title: string;
  Icon: any;
  onClick: () => void;
  itemID: string;
};

const TransactionButton: FC<TransactionButtonProps> = memo(({ title, Icon, onClick }) => {
  return (
    <div id="transactionButton">
      <div className="transactionButton__item" onClick={onClick}>
        <Box textAlign="center" display="flex" alignItems="center">
          <Icon />
          <Typography variant="h6" align="center">
            <Box className="transactionButton__item__title">{title}</Box>
          </Typography>
        </Box>
      </div>
    </div>
  );
});

export default TransactionButton;
