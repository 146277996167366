import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { colors, gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { TypesDialog } from './TypesDialog';
import { NamesDialog } from './NamesDialog';
import {
  organizationListRequest,
  organizationListSuccess,
  organizationTypeListRequest,
  UserState,
  userStore,
} from 'Stores/User';
import { formikGlobalConfig, onAddApplicationBodyData } from 'Config';
import { FormWrapper, SelectArrowDownIcon } from 'Components';
import { Formik } from 'formik';
import { OrganizationTypeSchema, OrganizationTypeSchemaInitialValues } from 'Validations';
import classNames from 'classnames';

import './OrganisationStep.scss';
import {
  ApplicationProcessState,
  applicationStore,
  incStep,
  setApplicationData,
  setInstitutionTypeRequest,
} from 'Stores/CardApplication';

type OrganisationStepProps = {};

const OrganisationStep: FC<OrganisationStepProps> = ({}) => {
  const {
    web_card_application_with_organisation_title,
    web_card_application_organisation_type,
    web_card_application_organisation_name,
  } = useLocalization();

  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const store: ApplicationProcessState = useSelector(applicationStore);

  const { activeApplicationData } = store;
  const OrganizationId = activeApplicationData?.OrganizationId;
  const OrganizationTypeId = activeApplicationData?.OrganizationTypeId;

  const [selectedOrganizationTypeLabel, setSelectedOrganizationTypeLabel] = useState<string>(
    web_card_application_organisation_type
  );
  const [selectedNameLabel, setSelectedNameLabel] = useState<string>(web_card_application_organisation_name);
  const [isOpenTypesDialog, setIsOpenTypesDialog] = useState<boolean>(false);
  const [isOpenOrganizationDialog, setIsOpenOrganizationDialog] = useState<boolean>(false);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const organizationTypeList = userData?.organizationsTypes;
  const organizationList = userData?.organizationsList;

  const { getError, getErrorMessage } = useErrorMessage();

  const getAlreadyNames = () => {
    const typeResult = organizationTypeList?.find((item) => item.OrganizationTypeRecordId === OrganizationTypeId);
    const organizationResult = organizationList?.find((item) => item.OrganizationId === OrganizationId);
    if (typeResult) {
      setSelectedOrganizationTypeLabel(typeResult.Name);
    }
    if (organizationResult) {
      setSelectedNameLabel(organizationResult?.OrganizationName);
    } else {
      setSelectedNameLabel(web_card_application_organisation_name);
    }
  };

  const valuesAlreadyHave = () => {
    getAlreadyNames();
  };

  const getOrganizationTypeList = () => {
    !organizationTypeList &&
      dispatch(
        organizationTypeListRequest({
          SessionToken: sessionToken,
          CustomerNumber: customerNumber,
        })
      );
  };

  const getOrganizationList = () => {
    OrganizationTypeId
      ? dispatch(
          organizationListRequest({
            SessionToken: sessionToken,
            CustomerNumber: customerNumber,
            CityCode: 34,

            OrganizationTypeId: OrganizationTypeId,
          })
        )
      : dispatch(organizationListSuccess(undefined));
  };

  useEffect(() => {
    valuesAlreadyHave();
    getOrganizationTypeList();
    getOrganizationList();
  }, [OrganizationTypeId, OrganizationId]);

  const handleChangeType = (id: number | undefined) => {
    dispatch(setApplicationData({ OrganizationTypeId: id, OrganizationId: undefined }));
  };
  const handleChangeOrganization = (id: number | undefined) => {
    dispatch(setApplicationData({ OrganizationId: id }));
  };

  const handleSubmit = () => {
    dispatch(incStep());
    dispatch(setInstitutionTypeRequest(selectedOrganizationTypeLabel));
  };

  return (
    <div id="organisationStep">
      <Typography variant="h3">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {web_card_application_with_organisation_title}
        </Box>
      </Typography>

      <Formik
        initialValues={OrganizationTypeSchemaInitialValues(
          selectedOrganizationTypeLabel !== web_card_application_organisation_type ? selectedOrganizationTypeLabel : '',
          selectedNameLabel !== web_card_application_organisation_name ? selectedNameLabel : ''
        )}
        enableReinitialize
        validationSchema={OrganizationTypeSchema}
        onSubmit={handleSubmit}
        {...formikGlobalConfig}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            values: { organizationTypeLabel, organizationLabel },
            errors: { organizationTypeLabel: errorOrganizationTypeLabel, organizationLabel: errorOrganizationLabel },
            touched: {
              organizationTypeLabel: touchedOrganizationTypeLabel,
              organizationLabel: touchedOrganizationLabel,
            },
          } = formikProps;

          return (
            <FormWrapper onSubmit={formikProps.handleSubmit}>
              <TypesDialog
                selectedValue={OrganizationTypeId}
                bodyData={organizationTypeList}
                open={isOpenTypesDialog}
                close={setIsOpenTypesDialog}
                onChange={(value: number, label: string) => {
                  handleChangeType(value);
                  setIsOpenTypesDialog(false);
                  formikProps.setFieldValue('organizationTypeLabel', label, true);
                  formikProps.setFieldValue('organizationLabel', '', true);
                }}
              />
              <Box onClick={() => setIsOpenTypesDialog(true)}>
                <TextField
                  variant="filled"
                  label={web_card_application_organisation_type}
                  fullWidth
                  onBlur={handleBlur}
                  InputProps={{
                    fullWidth: true,
                    readOnly: true,
                    endAdornment: <SelectArrowDownIcon />,
                  }}
                  error={getError(errorOrganizationTypeLabel, touchedOrganizationTypeLabel)}
                  helperText={getErrorMessage(errorOrganizationTypeLabel, touchedOrganizationTypeLabel)}
                  onChange={handleChange}
                  name="organizationLabel"
                  id="organizationLabel"
                  value={organizationTypeLabel}
                  itemID="cardApplyOrganizationLabelInputTest"
                />
              </Box>
              <Box mt={gutters.small} />
              <NamesDialog
                selectedValue={OrganizationId}
                bodyData={organizationList}
                onChange={(value: number, label: string) => {
                  handleChangeOrganization(value);
                  setIsOpenOrganizationDialog(false);
                  formikProps.setFieldValue('organizationLabel', label, true);
                }}
                open={isOpenOrganizationDialog}
                close={setIsOpenOrganizationDialog}
              />
              <Box
                className={classNames({
                  'organisationStep--disabled': !!!organizationTypeLabel,
                })}
                onClick={() => (!!organizationTypeLabel ? setIsOpenOrganizationDialog(true) : undefined)}
              >
                <TextField
                  variant="filled"
                  label={web_card_application_organisation_name}
                  fullWidth
                  onBlur={handleBlur}
                  InputProps={{
                    fullWidth: true,
                    readOnly: true,
                    endAdornment: <SelectArrowDownIcon />,
                    style: {
                      backgroundColor: !!!organizationTypeLabel ? colors.lightGray : undefined,
                    },
                  }}
                  error={getError(errorOrganizationLabel, touchedOrganizationLabel)}
                  helperText={getErrorMessage(errorOrganizationLabel, touchedOrganizationLabel)}
                  onChange={handleChange}
                  name="organizationLabel"
                  id="organizationLabel"
                  value={organizationLabel}
                  itemID="cardApplyOrganizationLabelInputTest"
                />
              </Box>

              <Box mt={gutters.small} />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                itemID="nextCardApplyOrganizationStepBtnTest"
              >
                Devam Et
              </Button>
            </FormWrapper>
          );
        }}
      </Formik>
    </div>
  );
};
export default OrganisationStep;
