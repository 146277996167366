import { FC, useState } from 'react';
import { TextField } from '@material-ui/core';
import { CloseIcon, SearchIcon } from 'Components';
import { formikGlobalConfig } from 'Config';
import { Formik } from 'formik';
import { useLocalization } from 'Hooks';
import { useSelector } from 'react-redux';
import { AccountState, storeAccount } from 'Stores/Account';
import { gutters } from 'Theme/Variables';
import { turkishCharacterNormalize } from 'Utils';

type SearchProps = {
  handleChange: any;
};

const SearchComp: FC<SearchProps> = (props) => {
  const { web_faq_search_topic } = useLocalization();

  const userData: AccountState = useSelector(storeAccount);
  const data = userData.faq?.data?.Questions;

  // const [tempData, setTempData] = useState(data);

  return (
    <Formik
      enableReinitialize
      initialValues={{ search: '' }}
      validationSchema={{ search: '' }}
      onSubmit={() => {}}
      {...formikGlobalConfig}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleReset,
          values: { search },
        } = formikProps;
        return (
          <TextField
            style={{ marginBottom: gutters.big }}
            name="search"
            label={web_faq_search_topic}
            variant="filled"
            value={search}
            onChange={(e) => {
              props.handleChange(
                data &&
                  data.filter(
                    ({ Question, Answer }) =>
                      turkishCharacterNormalize(Question)
                        .toLocaleLowerCase()
                        .includes(turkishCharacterNormalize(e.target.value.toLowerCase())) ||
                      Answer.includes(e.target.value)
                  ),
                e.target.value !== ''
              );
              handleChange(e);
            }}
            InputProps={{
              endAdornment:
                search === '' ? (
                  <SearchIcon />
                ) : (
                  <CloseIcon
                    onClick={() => {
                      props.handleChange('');
                      handleReset();
                    }}
                  />
                ),
            }}
            fullWidth
            itemID="faqSearchInputTest"
          />
        );
      }}
    </Formik>
  );
};
export default SearchComp;
