import { localStorageKeys } from 'Config';

export const generateLanguageParam = () => {
  const language = localStorage.getItem(localStorageKeys.i18nextLng);

  const upperCaseLanguage = language?.toUpperCase();

  return `${language}-${upperCaseLanguage}`;
};

export default generateLanguageParam;
