import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Typography, Box, TextField, Link } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { FormikProps } from 'formik';
import { SignupSchemaInputs } from 'Validations';
import { useErrorMessage, useLocalization, useModal } from 'Hooks';
import { CheckboxUnderlineLabel, DialogTerms, InfoIcon } from 'Components';
import { agreementsTypes, generateLanguageParam, underlineParser } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import { onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { SingupConditionsStep } from '../SingupConditionsStep';

type SingupInfoStepProps = {
  formikProps: FormikProps<SignupSchemaInputs>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const SingupInfoStep: FunctionComponent<SingupInfoStepProps> = (props) => {
  const {
    formikProps: {
      values: { name, surname, mail, termsAccepted, clarificationAccepted },
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldError,
      errors: {
        name: errorName,
        surname: errorSurname,
        mail: errorMail,
        termsAccepted: errorTermsAccepted,
        clarificationAccepted: errorClarificationAccepted,
      },
      touched: {
        name: touchedName,
        surname: touchedSurname,
        mail: touchedMail,
        termsAccepted: touchedTermsAccepted,
        clarificationAccepted: touchedClarificationAccepted,
      },
      setTouched,
    },
    setOpen,
  } = props;
  const {
    web_iletisim_title,
    web_plc_name,
    web_plc_surname,
    web_plc_mail,
    web_iletisim_check,
    web_iletisim_info,
    web_title_explicit_consent_text,
    web_title_clarification_text,
  } = useLocalization();

  const { isVisible, show, hide } = useModal();
  const { isVisible: infoModalIsVisible, show: infoModalShow, hide: infoModalHide } = useModal();

  //const { beginText, endText, underlineText } = underlineParser(web_iletisim_info);
  const { getError, getErrorMessage } = useErrorMessage();

  const dispatch = useDispatch();

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  useEffect(() => {
    setTouched({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // TODO: made this because of ios tab bug, it should be fixed later.
    if (termsAccepted) {
      setFieldError('termsAccepted', '');
    }
    // eslint-disable-next-line
  }, [termsAccepted]);
  const makeList = (text: string) => {
    if (!text || text.trim() === '') {
      return '';
    }

    var startIndex = text.indexOf('{#');
    var result = '';

    while (startIndex !== -1) {
      var endIndex = text.indexOf('#}', startIndex + 2);
      if (endIndex !== -1) {
        var fieldText = text.substring(startIndex + 2, endIndex).trim();
        var fieldHTML =
          '<ul style="list-style: outside;padding-left:1em"><li class="list-item">' + fieldText + '</li></ul>';
        result += text.substring(0, startIndex) + fieldHTML;
        text = text.substring(endIndex + 2);
        startIndex = text.indexOf('{#');
      } else {
        break;
      }
    }

    result += text;
    return result;
  };

  const makeLink = (str: string) => {
    if (str && str.includes('{%')) {
      let mySubString = str.substring(str.lastIndexOf('{%') + 2, str.lastIndexOf('%}'));
      let deneme = str.replace('{%', `<a href=${mySubString} target="_blank">`).replace('%}', '</a>');
      return makeList(deneme);
    } else {
      return makeList(str);
    }
  };

  return (
    <>
      <Typography variant="h5" align="center">
        <Box fontWeight={600} marginBottom={gutters.big} marginTop={gutters.big}>
          <h4
            dangerouslySetInnerHTML={{
              __html: makeLink(web_iletisim_title),
            }}
          />
        </Box>
      </Typography>
      <Box marginBottom={gutters.regular}>
        <TextField
          name="name"
          label={web_plc_name}
          variant="filled"
          type="text"
          fullWidth
          value={name}
          onChange={({ target }) => {
            setFieldValue('name', target.value.replace(/[0-9]/g, ''));
          }}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue('name', name.trim());
          }}
          error={getError(errorName, touchedName)}
          helperText={getErrorMessage(errorName, touchedName)}
          inputProps={{ maxLength: 50 }}
          itemID="signUpUserNameInputTest"
        />
      </Box>
      <Box marginBottom={gutters.regular}>
        <TextField
          name="surname"
          label={web_plc_surname}
          variant="filled"
          fullWidth
          value={surname}
          onChange={({ target }) => setFieldValue('surname', target.value.replace(/[0-9]/g, ''))}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue('surname', surname.trim());
          }}
          error={getError(errorSurname, touchedSurname)}
          helperText={getErrorMessage(errorSurname, touchedSurname)}
          inputProps={{ maxLength: 50 }}
          itemID="signUpUserSurnameInputTest"
        />
      </Box>
      <Box marginBottom={gutters.regular}>
        <TextField
          name="mail"
          label={web_plc_mail}
          variant="filled"
          fullWidth
          value={mail}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue('mail', mail?.trim());
          }}
          error={getError(errorMail, touchedMail)}
          helperText={getErrorMessage(errorMail, touchedMail)}
          inputProps={{ maxLength: 50 }}
          itemID="signUpUserMailInputTest"
        />
      </Box>
      <Box className="flexAlignCenter">
        <CheckboxUnderlineLabel
          text={web_iletisim_info}
          name="clarificationAccepted"
          color="primary"
          checked={clarificationAccepted}
          // onChange={handleChange}
          onClick={() => {
            setOpen(true);
            handleAgreementSelect(agreementsTypes.AYDINLATMA_METNI);
          }}
          onBlur={handleBlur}
          defaultChecked={true}
          errorText={getErrorMessage(errorClarificationAccepted, touchedClarificationAccepted)}
          onUnderlineTextClick={() => {
            setOpen(true);
            handleAgreementSelect(agreementsTypes.AYDINLATMA_METNI);
            // infoModalShow();
          }}
          itemID="showAydınlatmaMetniBtnTest"
        />
      </Box>
      <Box marginBottom={gutters.big} className="checkbox-error">
        <CheckboxUnderlineLabel
          text={web_iletisim_check}
          name="termsAccepted"
          color="primary"
          checked={termsAccepted}
          onChange={handleChange}
          onBlur={handleBlur}
          defaultChecked={true}
          errorText={getErrorMessage(errorTermsAccepted, touchedTermsAccepted)}
          onUnderlineTextClick={() => {
            handleAgreementSelect(agreementsTypes.ACIK_RIZA_METNI);
            show();
          }}
          itemID="signUpTermsAcceptedInputTest"
        />
      </Box>

      <DialogTerms
        open={isVisible}
        hide={hide}
        handleClickButton={() => setFieldValue('termsAccepted', true)}
        headerTextAlign="left"
        title={web_title_explicit_consent_text}
        content={agreementContent?.AgreementContent}
      />
      <DialogTerms
        open={infoModalIsVisible}
        hide={infoModalHide}
        headerTextAlign="left"
        title={web_title_clarification_text}
        content={agreementContent?.AgreementContent}
      />
    </>
  );
};

export default SingupInfoStep;
