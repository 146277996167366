const cardBalanceNormalize = (balance = 0) => {
  let [integerPart, decimalPart] = balance.toFixed(2).split('.');

  // Insert dots as thousands separators
  integerPart = integerPart
    .split('')
    .reverse()
    .reduce((acc, digit, i) => {
      return digit + (i && i % 3 === 0 ? '.' : '') + acc;
    }, '');
  return `${integerPart},${decimalPart}`;
};

export default cardBalanceNormalize;
