import * as yup from 'yup';
import { dateValidation } from 'Utils';
import { errorMessages } from 'Config';
import { PasswordSchema } from './PasswordSchema';

const ForgotPasswordIdentityStep = yup.object({
  tcNo: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  birthDate: yup
    .string()
    .required(errorMessages.require)
    .min(10, errorMessages.minBirthDate)
    .test('dateValidation', errorMessages.dateFormat, (value) => dateValidation(value)),
});

export const ForgotPasswordSchema = [ForgotPasswordIdentityStep, PasswordSchema];

export type ForgotPasswordSchemaInputs = yup.InferType<typeof ForgotPasswordIdentityStep & typeof PasswordSchema>;

export const forgotPasswordSchemaInitialValues: ForgotPasswordSchemaInputs = {
  birthDate: '',
  tcNo: '',
  password: '',
  rePassword: '',
};
