import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstagramRaffleContestModel } from 'Models';
import { RootState } from '../index';
import { instagramRaffleContestState } from './types';

export const initialState: instagramRaffleContestState = {
  loading: true,
};

export const instagramRaffleContestSlice = createSlice({
  name: 'instagramRaffleContest',
  initialState,
  reducers: {
    onInit: (state) => {},
    instagramRaffleContestRequest: (state: instagramRaffleContestState, action: PayloadAction<any>) => {
      state.loading = true;
    },
    instagramRaffleContestRequestSuccess: (
      state: instagramRaffleContestState,
      action: PayloadAction<InstagramRaffleContestModel>
    ) => {
      state.loading = false;
    },
  },
});

export const {
  onInit,
  instagramRaffleContestRequest,
  instagramRaffleContestRequestSuccess,
} = instagramRaffleContestSlice.actions;

export const checkCustomerInfromationStore = (state: RootState) => state.instagramRaffleContest;

export default instagramRaffleContestSlice.reducer;
