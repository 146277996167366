import {
  campaignsReqBodyData,
  CommonUpdateCommercialStatus4MailBodyData,
  getTownListOtherReqBodyData,
  onConfirmEmailReqBodyData,
  onUpdateCustomerAddressReqBodyData,
  OnUpdateOnlinePermissionBodyData,
} from './../../Config/ServiceConfig';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  formNames,
  getCityListReqBodyData,
  getTownListReqBodyData,
  localStorageKeys,
  onChangePasswordCustomerReqBodyData,
  onGetAgreementContentReqBodyData,
  onGetCustomerInfoReqBodyData,
  onLoginLogListReqBodyData,
} from 'Config';
import { goBack, push, RouterState } from 'connected-react-router';
import {
  OnChangePasswordCustomerReqModel,
  FaqModel,
  onLoginLogListModel,
  onGetAgreementContentModel,
  onLoginLogListItemModel,
  GetCampaignsModel,
  OnGetCustomerInfoModel,
  GetCityListModel,
  GetTownListModel,
  OnConfirmEmailModel,
  GetDistrictListModel,
  GetStreetListModel,
  GetNewsModel,
  OnUpdateCustomerAdressModel,
  GetTownListOtherModel,
} from 'Models';
import { routePath } from 'Navigator/routes';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { onChangePasswordCustomerReq } from 'Services/Mutations';
import {
  onGetAgreementContent,
  onLoginLogList,
  getFaq,
  getCampaigns,
  onGetCustomerInfo,
  getCityList,
  getTownList,
  onConfirmEmail,
  getDistrictList,
  getStreetList,
  getNews,
  onUpdateCustomerAdress,
  getTownListOther,
  onUpdateCommercialStatus4Mail,
  onUpdateOnlinePermission,
} from 'Services/Queries';
import { setFormError } from 'Stores/Form/slices';
import { showToastify } from 'Stores/Toastify/slices';
import {
  addToCurrentAgreementList,
  removeFromCurrentAgreementList,
  setFalseUserResetPassword,
  setUserAdditionalInfo,
  UserState,
  userStore,
} from 'Stores/User';
import { generateLanguageParam, isBelbimRequestSuccess } from 'Utils';
import {
  onChangePasswordCustomerRequest,
  onChangePasswordCustomerRequestSuccess,
  onChangePasswordCustomerRequestFail,
  onGetAgreementRequestFail,
  onGetAgreementRequestSuccess,
  onGetFaqRequest,
  onGetFaqRequestSuccess,
  onGetFaqRequestFail,
  onGetAgreementRequest,
  onGetLoginLogListRequestSuccess,
  onGetLoginLogListRequestFail,
  onGetLoginLogListRequest,
  getCampaignsRequest,
  getCampaignsRequestSuccess,
  getCampaignsRequestFail,
  onGetCustomerInfoRequest,
  onGetCustomerInfoRequestSuccess,
  onGetCustomerInfoRequestFail,
  getCityListRequest,
  getCityListRequestSuccess,
  getCityListRequestFail,
  getTownListRequest,
  getTownListRequestSuccess,
  getTownListRequestFail,
  onConfirmEmailRequest,
  onConfirmEmailRequestSuccess,
  onConfirmEmailRequestFail,
  setEmailValidationStep,
  getDistrictListRequest,
  getDistrictListRequestSuccess,
  getDistrictListRequestFail,
  getStreetListRequest,
  getStreetListRequestSuccess,
  getStreetListRequestFail,
  getNewsRequest,
  getNewsRequestSuccess,
  getNewsRequestFail,
  onUpdateCustomerAddressRequest,
  onUpdateCustomerAddressRequestSuccess,
  onUpdateCustomerAddressRequestFail,
  showHideDeleteAdressWarningModal,
  getTownOtherListRequest,
  getTownOtherListRequestSuccess,
  getTownOtherListRequestFail,
  setTownList,
  setTownOtherList,
  setDistrictList,
  setStreetList,
  setNormalizeAdressParameters,
} from './slices';
import { adressTransactionTypes } from 'Views/UserAdressDetail/constant';
import { useHistory } from 'react-router-dom';
import {
  onUpdateCommercialStatus4MailFail,
  onUpdateCommercialStatus4MailRequest,
  onUpdateCommercialStatus4MailResponse,
  onUpdateOnlinePermissionFail,
  onUpdateOnlinePermissionRequest,
  onUpdateOnlinePermissionSuccess,
} from '.';
import { incStep } from 'Stores/CardApplication';
import i18n from 'i18n';
import { onInıtDataRequest, setLanguage } from 'Stores/App';
import { data } from 'jquery';

function* handleOnChangePasswordCustomerRequest({ payload }: PayloadAction<onChangePasswordCustomerReqBodyData>) {
  try {
    const response: OnChangePasswordCustomerReqModel = yield call(() => onChangePasswordCustomerReq(payload));

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);
    const userData: UserState = yield select(userStore);
    const isResetPassword = userData.resetPassword;
    if (requestSuccess) {
      if (isResetPassword) {
        yield put(setFalseUserResetPassword());
      }
      yield put(onChangePasswordCustomerRequestSuccess());
      yield put(push(routePath.home));
      yield put(
        showToastify({
          toastMessage: 'web_msg_change_password_success',
          type: 'success',
        })
      );
    } else {
      yield put(onChangePasswordCustomerRequestFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.changePassword,
        })
      );
    }
  } catch (error: any) {
    yield put(onChangePasswordCustomerRequestFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.changePassword }));
  }
}

// GET Agreement detail
function* handleOnGetAgreementRequest({ payload }: PayloadAction<onGetAgreementContentReqBodyData>) {
  try {
    const response: onGetAgreementContentModel = yield call(() => onGetAgreementContent(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(onGetAgreementRequestSuccess(response.data));
      if (!payload.isSignupProcess) {
        yield put(push(routePath.agreementsDetail, { titleId: payload.AgreementType }));
      }
    } else {
      yield put(onGetAgreementRequestFail());
    }
  } catch (error) {
    yield put(onGetAgreementRequestFail());
  }
}

function* handleOnGetFaqRequest() {
  try {
    const language = localStorage.getItem(localStorageKeys.i18nextLng);
    const languageId = language === 'en' ? '1' : '2';
    const response: FaqModel = yield call(() => getFaq(languageId));

    if (response?.data?.Questions && response?.data?.Questions?.length > 0) {
      yield put(onGetFaqRequestSuccess(response));
    } else {
      yield put(onGetFaqRequestFail());
    }
  } catch (error) {
    yield put(onGetFaqRequestFail());
  }
}

function* handleOnGetNewsRequest() {
  try {
    const language = localStorage.getItem(localStorageKeys.i18nextLng);
    const languageId = language === 'en' ? '1' : '2';
    const response: GetNewsModel = yield call(() =>
      getNews({
        LanguageId: languageId,
      })
    );
    if (response?.data?.NewsList && response?.data?.NewsList?.length > 0) {
      yield put(getNewsRequestSuccess(response));
    } else {
      yield put(getNewsRequestFail());
    }
  } catch (error) {
    yield put(getNewsRequestFail());
  }
}

function* handleOnLoginLogListRequest({ payload }: PayloadAction<onLoginLogListReqBodyData>) {
  try {
    const response: onLoginLogListModel = yield call(() => onLoginLogList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(onGetLoginLogListRequestSuccess(response.data.LoginLogList));
    } else {
      yield put(onGetLoginLogListRequestFail());
    }
  } catch (error) {
    yield put(onGetLoginLogListRequestFail());
  }
}

function* handleGetCampaignsRequestSaga({ payload }: PayloadAction<campaignsReqBodyData>) {
  try {
    const response: GetCampaignsModel = yield call(() => getCampaigns(payload));

    if (response?.data.Campaigns && response?.data.Campaigns?.length > 0) {
      yield put(getCampaignsRequestSuccess(response));
    } else {
      yield put(getCampaignsRequestFail());
    }
  } catch (error) {
    yield put(getCampaignsRequestFail());
  }
}

function* handleOnGetCustomerInfoRequestSaga({ payload }: PayloadAction<onGetCustomerInfoReqBodyData>) {
  try {
    const response: OnGetCustomerInfoModel = yield call(() => onGetCustomerInfo(payload));
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(onGetCustomerInfoRequestSuccess());
      yield put(setUserAdditionalInfo(response));
    } else {
      yield put(onGetCustomerInfoRequestFail(errorMessage));
    }
  } catch (error: any) {
    yield put(onGetCustomerInfoRequestFail(error));
  }
}

function* handleOnGetCityListRequestSaga({ payload }: PayloadAction<getCityListReqBodyData>) {
  try {
    const response: GetCityListModel = yield call(() => getCityList(payload));
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(getCityListRequestSuccess(response));
    } else {
      yield put(getCityListRequestFail(errorMessage));
    }
  } catch (error: any) {
    yield put(getCityListRequestFail(error));
  }
}

function* handleOnGetTownOtherListRequestSaga({ payload }: PayloadAction<getTownListOtherReqBodyData>) {
  try {
    const response: GetTownListOtherModel = yield call(() => getTownListOther(payload));

    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(getTownOtherListRequestSuccess(response));
    } else {
      yield put(getTownOtherListRequestFail(errorMessage));
    }
  } catch (error: any) {
    yield put(getTownOtherListRequestFail(error));
  }
}
function* handleOnGetTownListRequestSaga({ payload }: PayloadAction<string>) {
  try {
    const response: GetTownListModel = yield call(() => getTownList(payload));

    yield put(getTownListRequestSuccess(response));
  } catch (error: any) {
    yield put(getTownListRequestFail(error));
  }
}

function* handleOnGetDistrictListRequestSaga({ payload }: PayloadAction<string>) {
  try {
    const response: GetDistrictListModel = yield call(() => getDistrictList(payload));
    yield put(getDistrictListRequestSuccess(response));
  } catch (error: any) {
    yield put(getDistrictListRequestFail(error));
  }
}

function* handleOnGetStreetListRequestSaga({ payload }: PayloadAction<string>) {
  try {
    const response: GetStreetListModel = yield call(() => getStreetList(payload));
    yield put(getStreetListRequestSuccess(response));
  } catch (error: any) {
    yield put(getStreetListRequestFail(error));
  }
}

function* handleOnConfirmEmailRequestSaga({ payload }: PayloadAction<onConfirmEmailReqBodyData>) {
  try {
    const response: OnConfirmEmailModel = yield call(() => onConfirmEmail(payload));
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(onConfirmEmailRequestSuccess());
      yield put(setEmailValidationStep(1));
    } else {
      yield put(onConfirmEmailRequestFail(errorMessage));
      yield put(setEmailValidationStep(2));
    }
  } catch (error: any) {
    yield put(onConfirmEmailRequestFail(error));
    yield put(setEmailValidationStep(2));
  }
}

function* handleOnUpdateAdressRequestSaga({
  payload,
}: PayloadAction<onUpdateCustomerAddressReqBodyData & { callback?: () => void }>) {
  try {
    const routerState: RouterState = yield select((state: any) => state.router);
    let pathname = routerState?.location?.pathname;
    const userMustHaveContactAddress = (routerState?.location as any)?.state?.userMustHaveContactAddress;

    const { callback: _, ...request } = payload;
    const response: OnUpdateCustomerAdressModel = yield call(() => onUpdateCustomerAdress(request));
    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onUpdateCustomerAddressRequestSuccess());
      if (payload.ProcessType === adressTransactionTypes.addAdress) {
        yield put(
          showToastify({
            toastMessage: 'msg_web_address_added',
            type: 'success',
          })
        );
      }
      if (payload.ProcessType === adressTransactionTypes.deleteAdress) {
        yield put(showHideDeleteAdressWarningModal(false));
        yield put(
          showToastify({
            toastMessage: 'web_delete_address_success_msg',
            type: 'success',
          })
        );
      }
      if (payload.ProcessType === adressTransactionTypes.updatedAdress) {
        yield put(
          showToastify({
            toastMessage: 'msg_web_address_updated',
            type: 'success',
          })
        );
      }

      if (pathname !== routePath.cardApplicationSteps) {
        if (userMustHaveContactAddress) {
          yield put(
            push(routePath.cardApplicationSteps, {
              status: true,
              custody: payload.custody,
              stepData: payload?.stepData,
            })
          );
        } else {
          yield put(goBack());
        }
      } else {
        yield put(
          onGetCustomerInfoRequest({
            CustomerNumber: payload.CustomerNumber,
            SessionToken: payload.SessionToken,
          })
        );
        yield put(incStep());
      }

      payload?.callback?.();
    } else {
      yield put(onUpdateCustomerAddressRequestFail(errorMessage));
      yield put(showHideDeleteAdressWarningModal(false));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.addUserAdress,
        })
      );
    }
  } catch (error: any) {
    yield put(showHideDeleteAdressWarningModal(false));
    yield put(onUpdateCustomerAddressRequestFail(error));
  }
}

function* handleOnUpdateOnlinePermissionRequestSaga({ payload }: PayloadAction<OnUpdateOnlinePermissionBodyData>) {
  try {
    const response: OnConfirmEmailModel = yield call(() => onUpdateOnlinePermission(payload));
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onUpdateOnlinePermissionSuccess(response));
      yield put(showToastify({ toastMessage: 'web_acikriza_success', type: 'success' }));
      if (payload.OnlinePermissionMessage[4] === -1) {
        yield put(removeFromCurrentAgreementList(4));
      } else {
        yield put(
          addToCurrentAgreementList({
            AgreementType: 4,
            AgreementVersion: payload.OnlinePermissionMessage[4],
            ReadOnly: false,
            Required: false,
          })
        );
      }
      yield put(onInıtDataRequest(generateLanguageParam()));
    } else {
      yield put(showToastify({ toastMessage: errorMessage, type: 'error' }));
      yield put(onUpdateOnlinePermissionFail());
    }
  } catch (error) {
    yield put(onUpdateOnlinePermissionFail());
  }
}

function* handleSetNormalizeAdressParameters() {
  try {
    yield put(setTownList(undefined));
    yield put(setTownOtherList(undefined));
    yield put(setDistrictList(undefined));
    yield put(setStreetList(undefined));
  } catch (error) {
    console.log(error);
  }
}
function* handleOnUpdateCommercial({ payload }: PayloadAction<CommonUpdateCommercialStatus4MailBodyData>) {
  try {
    const response: OnConfirmEmailModel = yield call(() => onUpdateCommercialStatus4Mail(payload));
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);

    yield put(onUpdateCommercialStatus4MailResponse(response));
  } catch (error) {
    yield put(onUpdateCommercialStatus4MailFail());
  }
}

setNormalizeAdressParameters;
// Watchers
function* accountSagaWatcher() {
  yield takeLatest(setNormalizeAdressParameters, handleSetNormalizeAdressParameters);
  yield takeLatest(getTownOtherListRequest, handleOnGetTownOtherListRequestSaga);
  yield takeLatest(getStreetListRequest, handleOnGetStreetListRequestSaga);
  yield takeLatest(getDistrictListRequest, handleOnGetDistrictListRequestSaga);
  yield takeLatest(onConfirmEmailRequest, handleOnConfirmEmailRequestSaga);
  yield takeLatest(getCityListRequest, handleOnGetCityListRequestSaga);
  yield takeLatest(getTownListRequest, handleOnGetTownListRequestSaga);
  yield takeLatest(onGetFaqRequest, handleOnGetFaqRequest);
  yield takeLatest(getNewsRequest, handleOnGetNewsRequest);
  yield takeLatest(getCampaignsRequest, handleGetCampaignsRequestSaga);
  yield takeLatest(onChangePasswordCustomerRequest, handleOnChangePasswordCustomerRequest);
  yield takeLatest(onGetAgreementRequest, handleOnGetAgreementRequest);
  yield takeLatest(onGetLoginLogListRequest, handleOnLoginLogListRequest);
  yield takeLatest(onGetCustomerInfoRequest, handleOnGetCustomerInfoRequestSaga);
  yield takeLatest(onUpdateCustomerAddressRequest, handleOnUpdateAdressRequestSaga);
  yield takeLatest(onUpdateCommercialStatus4MailRequest, handleOnUpdateCommercial);
  yield takeLatest(onUpdateOnlinePermissionRequest, handleOnUpdateOnlinePermissionRequestSaga);
}

// eslint-disable-next-line
export default accountSagaWatcher;
function* RootState(RootState: any) {
  throw new Error('Function not implemented.');
}
