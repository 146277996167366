export const deleteCustomerCardByClosedNormalize = (
  mifareId: string,
  cardCloseReason: number,
  customerNumber?: number,
  sessionToken?: string
) => {
  const formValues = {
    CustomerNumber: customerNumber,
    SessionToken: sessionToken,
    MifareId: mifareId,
    CardCloseReason: cardCloseReason,
  };
  return formValues;
};
