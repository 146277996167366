import { FC, Fragment, memo } from 'react';
import { useLocalization, useModal } from 'Hooks';
import { Box, Dialog, Link, Typography } from '@material-ui/core';
import { AlertIcon, ChevronRedRightIcon, CloseIcon } from 'Components';
import { colors, fontSizes, gutters } from 'Theme/Variables';

import './CardVisaWarning.scss';
import moment from 'moment';

export type cardVisaInfoProps = {
  VisaExpireDate: string;
};

type CardVisaWarningProps = {
  cardVisaInfo: cardVisaInfoProps;
};

const CardVisaWarning: FC<CardVisaWarningProps> = memo(({ cardVisaInfo }) => {
  const {
    web_desc2_load_pending_instruction,
    web_complete_visa_with_nfc,
    web_complete_visa_desc,
    web_visa_last_days,
    web_waiting_visa_detail_popup_desc_blue_text,
    web_visa_component_second_warnings,
  } = useLocalization();
  const { isVisible, show, hide } = useModal();

  const lastVisaDays = moment(cardVisaInfo?.VisaExpireDate).diff(moment(new Date()), 'days');

  function createMarkup() {
    // if (lastVisaDays < 0) {
    //   return {
    //     __html: `${web_visa_component_second_warnings}`,
    //   };
    // } else {
    // return {
    //   // __html: `${web_visa_last_days.replace('{0}', ` <span>${lastVisaDays === 0 ? '' : lastVisaDays}</span>`)}`,
    //   __html: `${web_visa_last_days.replace('{0}', ` <span>${lastVisaDays}</span>`)}`,
    // };
    if (lastVisaDays <= 0) {
      return {
        __html: `${web_visa_component_second_warnings}`,
      };
    } else {
      return {
        __html: `${web_visa_last_days.replace('{0}', ` <span>${lastVisaDays}</span>`)}`,
      };
    }
    // }
  }

  return (
    <Fragment>
      <div id="cardVisaWarning" onClick={() => show()}>
        <Box display="flex" alignItems="center">
          <Box>
            <AlertIcon />
          </Box>
          <Box marginLeft={fontSizes.small}>
            <Box
              fontSize={fontSizes.regular}
              color={colors.toastErrorText}
              fontWeight="bold"
              dangerouslySetInnerHTML={createMarkup()}
            />

            <Typography variant="subtitle2" component="span">
              {web_complete_visa_desc}
            </Typography>
          </Box>
        </Box>
        <Box>
          <ChevronRedRightIcon />
        </Box>
      </div>
      <Dialog open={isVisible} onClose={hide} keepMounted={false} disablePortal={true}>
        <Box className="dialogContainer">
          <CloseIcon onClick={() => hide()} />
          <Box>
            <Typography variant="h6">
              <Box textAlign="center" fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.big}>
                {web_complete_visa_with_nfc}
              </Box>
            </Typography>
            <Link underline="none" style={{ cursor: 'unset' }}>
              <Box color={colors.primary} marginBottom={gutters.big} textAlign="center">
                {web_waiting_visa_detail_popup_desc_blue_text}
              </Box>
            </Link>
            <Typography variant="subtitle2">
              <Box fontSize={fontSizes.regular} textAlign="center">
                {web_desc2_load_pending_instruction}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
});
export default CardVisaWarning;
