import { FC } from 'react';
import { useCardType, useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigFilledIcon } from 'Components';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';

import './FibabankDocumentApprovePage.scss';

type FibabankDocumentApprovePageProps = {};

const FibabankDocumentApprovePage: FC<FibabankDocumentApprovePageProps> = ({}) => {
  const { micro_credit_transaction_approved } = useLocalization();
  const history = useHistory();

  return (
    <div id="addCardSuccessLoadBalance">
      <Box marginTop={gutters.big}>
        <div className="add-card-success-load-balance__img--wrapper">
          <img
            src={'/static/media/digital_tr.82535037.png'}
            alt=""
            className={`add-card-success-load-balance__img--wrapper__${'blue'}`}
          />
          <GreenTickBigFilledIcon />
        </div>
        {
          //BURASI KALACAK}
        }
        <Box className="add-card-success-load-balance__desc">
          <Typography variant="body1" align="center">
            <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} marginTop={gutters.big}>
              {micro_credit_transaction_approved}
            </Box>
          </Typography>
        </Box>
        <div className="add-card-success-load-balance__container">
          <Box fontWeight="bold"></Box>
        </div>
      </Box>
    </div>
  );
};

export default FibabankDocumentApprovePage;
