import { FC, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Box, TextField, Typography, Button } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { onAddApplicationBodyData } from 'Config';
import { UserState, userStore } from 'Stores/User';
import {
  CardTransactionsState,
  checkApplicationTypeFourFail,
  checkApplicationTypeFourRequest,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { FormErrorWrapper } from 'Components';

import './ReportStep.scss';
import { ApplicationProcessState, applicationStore, incStep } from 'Stores/CardApplication';

type ReportStepProps = {};

const ReportStep: FC<ReportStepProps> = () => {
  const dispatch = useDispatch();

  const {
    web_card_application_report_number_title,
    web_card_application_report_number_desc,
    web_btn_next,
    msg_web_zorunlu_check,
  } = useLocalization();

  const { getError, getErrorMessage } = useErrorMessage();

  const store: ApplicationProcessState = useSelector(applicationStore);
  const userData: UserState = useSelector(userStore);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const { applicationCard } = store;
  const requiredParams = applicationCard?.RequiredFields;

  const [fields, _] = useState<any>(requiredParams);
  const [values, setValues] = useState<Array<string>>([]);
  const [isErrorValidation, setIsErrorValidation] = useState<boolean>(false);

  const createRequest = useCallback(() => {
    if (applicationCard) {
      const adress = applicationCard?.QueryService;
      const requiredParams = applicationCard?.RequiredFields;
      let tempData: any = [];
      for (let index = 0; index < requiredParams.length; index++) {
        const element = requiredParams[index];
        //@ts-ignore
        tempData[element.FieldName] = values[index];
      }
      tempData = { ...tempData, adress: adress, CustomerNumber: customerNumber, SessionToken: sessionToken };
      dispatch(checkApplicationTypeFourRequest(tempData));
    }
  }, [fields, values]);

  const handleChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  };

  const handleSubmit = useCallback(() => {
    setIsErrorValidation(true);
    const isEmpty = values.findIndex((item) => item === '');
    if (values.length === fields.length && isEmpty === -1) {
      createRequest();
    }
  }, [values]);

  useEffect(() => {
    dispatch(checkApplicationTypeFourFail());
  }, []);

  // useEffect(() => {
  //   if (isReportNumbers) {
  //     dispatch(incStep());
  //   }
  //   setIsErrorValidation(false);
  // }, [values, isReportNumbers]);

  const renderItem = useCallback(() => {
    return (
      fields &&
      fields.map((_: any, index: number) => {
        return (
          <Box key={index.toString()} mt={gutters.small}>
            <TextField
              onTouchEnd={handleSubmit}
              onChange={({ target }) => handleChange(target.value, index)}
              error={isErrorValidation && !!!values[index]}
              helperText={getErrorMessage('require', isErrorValidation && !values[index])}
              label={web_card_application_report_number_desc}
              defaultValue={values[index] && values[index] !== '' ? values[index] : ''}
              variant="filled"
              fullWidth
            />
          </Box>
        );
      })
    );
  }, [values, isErrorValidation]);

  return (
    <div id="reportStep">
      <FormErrorWrapper formName="reportStepForm">
        <Typography variant="h3">
          <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
            {web_card_application_report_number_title}
          </Box>
        </Typography>
        {renderItem()}
        <Box mt={gutters.big} />
        <Button onClick={handleSubmit} fullWidth variant="contained" color="secondary">
          {web_btn_next}
        </Button>
      </FormErrorWrapper>
    </div>
  );
};
export default ReportStep;
