import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { History } from 'history';
import app from './App/slices';
import welcome from './Welcome/slices';
import signUp from './SignUp/slices';
import user from './User/slices';
import dialog from './Dialog/slices';
import form from './Form/slices';
import toastify from './Toastify/slices';
import account from './Account/slices';
import cardTransactions from './CardTransactions/slices';
import creditCardTransactions from './CreditCardTransactions/slices';
import transactions from './Transactions/slices';
import application from './CardApplication/slices';
import balanceTransfer from './BalanceTransfer/slices';
import maps from './Maps/slices';
import microCredit from './MicroCredit/slices';
import instagramRaffleContest from './InstagramRaffleContest/slices';

// Add Reducers Here
const rootReducer = (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    signUp,
    app,
    welcome,
    user,
    dialog,
    form,
    toastify,
    account,
    cardTransactions,
    creditCardTransactions,
    transactions,
    application,
    balanceTransfer,
    maps,
    microCredit,
    instagramRaffleContest,
  });

export default rootReducer;
