import Desktop1 from 'Assets/Images/usingPhoto/desktop/item1.png';
import Desktop2 from 'Assets/Images/usingPhoto/desktop/item2.png';
import Desktop3 from 'Assets/Images/usingPhoto/desktop/item3.png';
import Desktop4 from 'Assets/Images/usingPhoto/desktop/item4.png';
import Desktop5 from 'Assets/Images/usingPhoto/desktop/item5.png';
import Mobile1 from 'Assets/Images/usingPhoto/mobile/item1.png';
import Mobile2 from 'Assets/Images/usingPhoto/mobile/item2.png';
import Mobile3 from 'Assets/Images/usingPhoto/mobile/item3.png';
import Mobile4 from 'Assets/Images/usingPhoto/mobile/item4.png';
import Mobile5 from 'Assets/Images/usingPhoto/mobile/item5.png';

export const UsingPhotoDesktop = {
  Item1: Desktop1,
  Item2: Desktop2,
  Item3: Desktop3,
  Item4: Desktop4,
  Item5: Desktop5,
};

export const UsingPhotoMobile = {
  Item1: Mobile1,
  Item2: Mobile2,
  Item3: Mobile3,
  Item4: Mobile4,
  Item5: Mobile5,
};
