import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';
import onKillCustomerSessionAll from './OnKillCustomerSessionAll';

const command = 'login.lgOut';

const logout = async ({ sessionToken, customerNumber }: any) => {
  const params = { SessionToken: sessionToken, CustomerNumber: customerNumber };
  const res = await onKillCustomerSessionAll(params);
  if (res) {
    const result = await (
      await axiosHelper({
        method: 'post',
        gatewayData: {
          command,
          token: getApiGWTokenCookie(),
        },
      })
    ).data;
  }
};

export default logout;
