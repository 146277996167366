import { FC } from 'react';
import { ChevronDarkRightIcon, TickIcon } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { cardBalanceNormalize, splitMifareIdAndAliasNumber } from 'Utils';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { useCardType } from 'Hooks';
import {
  setActiveSelectedCard,
  setIsUserAddingNewCardInTransactions,
  setTempCardInfo,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { setFormError } from 'Stores/Form';
import { formNames } from 'Config';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './SelectedCard.scss';

type SelectedCardProps = {
  cardItem: cardItemParams;
  hide?: Function;
  isSelected?: boolean;
  isDialog: boolean;
};

const SelectedCard: FC<SelectedCardProps> = ({ cardItem, hide, isSelected, isDialog }) => {
  const dispatch = useDispatch();
  //To show greenn border check if addedTemporary card is the same with selected card
  const { cardPath, cardColor } = useCardType(cardItem?.ProductCode);
  const cardData = useSelector(storeCardTransactions);
  const { activeSelectedCard, isUserAddingNewCardInTransactions, tempCardInfo }: any = cardData;
  const isAddedTemporaryCard = cardItem?.MifareId === activeSelectedCard?.MifareId;

  return (
    <div
      id="selectedCard"
      onClick={() => {
        dispatch(setActiveSelectedCard(cardItem));
        if (isDialog) {
          dispatch(setIsUserAddingNewCardInTransactions(false));
          dispatch(setTempCardInfo(undefined));
          dispatch(setFormError({ errorCode: '', formName: formNames.addBalanceToCard }));
        }
        hide && hide();
      }}
      itemID="chooseCardToUploadTLBtnTest"
    >
      <Box
        className={`selectedCard__container selectedCard__${cardColor} ${
          isSelected ? 'selectedCard__container--active' : ''
        } ${
          isAddedTemporaryCard && isUserAddingNewCardInTransactions && tempCardInfo ? 'selectedCard--addedTempCard' : ''
        }`}
      >
        <Box display="flex">
          <Box display="flex">
            <LazyLoadImage className="selectedCard--img" src={cardPath} alt="" effect="opacity" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            marginLeft={gutters.regular}
            flex={1}
          >
            <Box className="selectedCard--title">
              {cardItem?.NickName ? cardItem.NickName : splitMifareIdAndAliasNumber(cardItem.AliasNumber)}
            </Box>
            {cardItem?.MaskedName && cardItem?.MaskedSurname && (
              <Box className="selectedCard--title">
                {cardItem?.MaskedName} {cardItem?.MaskedSurname}
              </Box>
            )}
            {(cardItem?.CardBalance || cardItem?.CardBalance === 0) && cardItem?.CardBalance.toString() && (
              <Typography variant="h6">
                <Box fontWeight="bold" fontSize={fontSizes.regularBig}>
                  {cardBalanceNormalize(cardItem?.CardBalance)} TL
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
        {isDialog && isSelected && <TickIcon />}
        {!isDialog && <ChevronDarkRightIcon />}
      </Box>
    </div>
  );
};

export default SelectedCard;
