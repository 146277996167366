import * as React from 'react';

function ContactPermissionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      {...props}
      className="contactPermission"
    >
      <defs>
        <path
          id="02l1bwogga"
          d="M5.453 7c.194-.001.388.016.578.053.184.027.365.072.54.135.251.088.437.302.488.562l1.028 4.502c.056.248-.017.508-.195.69-.098.105-.105.113-1.028.593.739 1.621 2.036 2.923 3.654 3.669.143-.274.245-.467.32-.607l.078-.144c.112-.2.14-.227.202-.284.182-.178.442-.252.69-.195L16.31 17c.252.059.456.243.54.488.064.178.111.361.143.548.03.188.045.38.045.57-.029 1.897-1.584 3.416-3.481 3.399-6.378-.008-11.547-5.177-11.555-11.555C2.002 8.545 3.547 7 5.453 7zM16 2c1.591 0 3.117.632 4.243 1.757C21.368 4.883 22 6.41 22 8c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6zm3.015 3.605c-.237-.027-.473.06-.618.232l-2.518 2.986-1.086-1.242c-.146-.169-.382-.255-.618-.225s-.436.17-.526.367c-.09.198-.055.424.092.592l1.625 1.858c.127.144.321.228.526.227.206 0 .4-.086.527-.233l3.044-3.584v-.018c.145-.17.177-.398.085-.596-.092-.198-.295-.337-.533-.364z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-171 -473) translate(155 450) translate(16 22.014) translate(0 1)">
                <path d="M0 0H24V24H0z" />
                <mask id="zg68fjy2ob" fill="#fff">
                  <use xlinkHref="#02l1bwogga" />
                </mask>
                <use fill="#D8D8D8" fillRule="nonzero" xlinkHref="#02l1bwogga" />
                <g fill="currentColor" mask="url(#zg68fjy2ob)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ContactPermissionIcon;
