import { Grid, Box, Typography } from '@material-ui/core';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import { useLocalization } from 'Hooks';
import { TransactionButton } from './TransactionButton';
import { AbonmanIcon, ArrowUpIcon, BigTransactionIcon, TimeIcon, BalanceIcon } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { LastTransactionItem } from './LastTransactionItem';
import { fontSizes, gutters } from 'Theme/Variables';
import { cardItemDetailType, findAbonmanTypes } from '../constant';
import { userStore } from 'Stores/User/slices';
import {
  getInstructionListRequest,
  onGetCardTransactionListRequest,
  storeCardTransactions,
  cardIsLoading,
  setActiveSelectedCard,
  setIsUserInUploadTLProcess,
  setIsUserInAutomaticInstructionProcess,
  onCheckCardIsUploadableSubsRequest,
  CardTransactionsState,
  InstructionItem,
  getCardVisaRequest,
  CardVisaProps,
} from 'Stores/CardTransactions';
import { getCardTransactionListNormalize, getInstructionListNormalize } from 'Normalize';
import { WaitingInstructions } from './WaitingInstructions';
import { routePath } from 'Navigator/routes';
import { useHistory } from 'react-router';
import { CreditCardTransactionsState, creditCardTransactionsStore } from 'Stores/CreditCardTransactions';
import { TransactionDetail } from 'Views/CardTransactionHistory/TransactionDetail';
import { CantUploadSubsModal } from './CantUploadSubsModal';
import { UserState } from 'Stores/User';
import { getDateFromSelectedFilter, MasterPassAccountStatusEnum } from 'Utils';
import { TaksListItem } from 'Models/InstrunctionListReq';
import { CardVisaWarning } from './CardVisaWarning';

import './CardTransactions.scss';
import moment from 'moment';
import { balanceTransferReset } from 'Stores/BalanceTransfer';

type CardTransactionsProps = {
  cardColorClass?: string;
  cardItem: cardItemDetailType;
  cardPath?: string;
};

const CardTransactions: FC<CardTransactionsProps> = memo(({ cardColorClass, cardItem, cardPath }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData: UserState = useSelector(userStore);
  const cardTransactionData: CardTransactionsState = useSelector(storeCardTransactions);
  const creditCardStoreData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const isLoading = useSelector(cardIsLoading);

  const userCreditCards = creditCardStoreData.creditCardList;
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const cardTransactions = cardTransactionData?.activeCardTransactions;
  const cardWaitingInstructions: InstructionItem[] | undefined = cardTransactionData?.activeCardWaitingInstructions;
  const accountStatus = creditCardStoreData?.masterPassAccountStatus;
  // Filter cards according to TaskTypeId
  const activeCardTasklist: TaksListItem[] | undefined = findAbonmanTypes(cardTransactionData?.cardTaskList || []);
  // Check ıf user has type of taskType 3
  const isCardHasVisa: boolean | undefined = cardTransactionData?.cardTaskList?.some(
    (item: TaksListItem) => item.TaskTypeId === 3
  );

  const cardVisaDate: CardVisaProps | undefined = cardTransactionData.cardVisaExpire;

  const cardIsActive = cardItem?.CardStatusCode === 'A' ? true : false;

  const [selectedTransaction, setSelectedTransaction] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const {
    web_card_detail_upload_tl,
    web_card_detail_money_transfer,
    web_card_detail_upload_subscriptipn,
    web_card_detail_last_transactions,
    web_card_detail_all_transactions,
    web_desc_card_detail_last_transactions,
    web_desc1_passive_card,
    web_desc2_passive_card,
    web_card_detail_automatic_instruction,
    web_title_transaction_detail_popup,
    web_passive_card_has_added,
    web_micro_credit_on_off,
    web_micro_credit_digitalcard_btn,
  } = useLocalization();

  useEffect(() => {
    dispatch(
      onGetCardTransactionListRequest(
        getCardTransactionListNormalize({
          CardId: cardItem?.CardId,
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          PageIndex: 1,
          PageSize: 4,
          TrnType: 0,
          beforeValue: 90,
          EndDate: moment().add(1, 'd').format('YYYY-MM-DD'),
        })
      )
    );
    dispatch(
      getCardVisaRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        MifareId: cardItem?.MifareId,
      })
    );
    dispatch(getInstructionListRequest(getInstructionListNormalize(cardItem?.MifareId, customerNumber, sessionToken)));
  }, []);

  const isDigitalCard = () => {
    return (
      cardItem.ProductCode === 'DGK_ORC' || cardItem.ProductCode === 'DGK_ORT' || cardItem.ProductCode === 'DGK_STD'
    );
  };

  return (
    <div id="cardTransactions">
      <Box>
        <TransactionDetail
          {...selectedTransaction}
          open={isOpenDetail}
          handleClose={() => setIsOpenDetail(false)}
          dialogTitle={web_title_transaction_detail_popup}
        />
      </Box>
      <Box>
        <CantUploadSubsModal />
      </Box>
      <Box></Box>
      <Box className="cardTransactions__container">
        {cardIsActive && (
          <Fragment>
            <Box className={`cardTransactions__${cardColorClass}`}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TransactionButton
                    title={web_card_detail_upload_tl}
                    Icon={ArrowUpIcon}
                    itemID="uploadTLBtnTest"
                    onClick={() => {
                      dispatch(setIsUserInUploadTLProcess(true));
                      dispatch(
                        setActiveSelectedCard({
                          ...cardItem,
                          cardColorClass,
                          cardPath,
                        })
                      );
                      if (
                        (userCreditCards && userCreditCards?.length > 0) ||
                        accountStatus === MasterPassAccountStatusEnum.NO_LINKED
                      ) {
                        history.push(routePath.addBalanceToCard);
                      } else {
                        history.push(routePath.addCreditCard);
                      }
                    }}
                  />
                </Grid>
                {
                  //eğer dijital kart detayında abonman yükleme butonunun kaldırılması talebi gelirse
                  //  && cardItem?.ProductCode !== 'DGK_STD' && kodu eklenmelidir
                }
                {cardItem?.SeasonTicketLoadable && (
                  <Grid item xs={6}>
                    <TransactionButton
                      title={web_card_detail_upload_subscriptipn}
                      Icon={AbonmanIcon}
                      itemID="uploadSubscriptionBtnTest"
                      onClick={() =>
                        dispatch(
                          onCheckCardIsUploadableSubsRequest({
                            ...cardItem,
                            cardColorClass,
                            cardPath,
                          })
                        )
                      }
                    />
                  </Grid>
                )}
                {isDigitalCard() && (
                  <Grid item xs={6}>
                    <TransactionButton
                      title={web_card_detail_money_transfer}
                      Icon={BigTransactionIcon}
                      onClick={() => {
                        dispatch(balanceTransferReset());
                        history.push(routePath.balanceTransfer);
                      }}
                      itemID={'moneyTransferBtnTest'}
                    />
                  </Grid>
                )}

                {isDigitalCard() && web_micro_credit_on_off == '1' && (
                  <Grid item xs={6}>
                    <TransactionButton
                      title={web_micro_credit_digitalcard_btn}
                      Icon={BalanceIcon}
                      onClick={() => {
                        history.push(routePath.microCreditOperation);
                      }}
                      itemID={'microCreditBtn'}
                    />
                  </Grid>
                )}
                {/* <Grid item xs={6}>
                  <TransactionButton
                    title={web_card_detail_automatic_instruction}
                    Icon={TimeIcon}
                    onClick={() => {
                      dispatch(setIsUserInAutomaticInstructionProcess(true));
                      dispatch(
                        setActiveSelectedCard({
                          ...cardItem,
                          cardColorClass,
                          cardPath,
                        })
                      );
                      if (userCreditCards && userCreditCards?.length !== 0) {
                        history.push(routePath.automaticInstruction);
                      } else {
                        history.push(routePath.addCreditCard);
                      }
                    }}
                  />
                </Grid> */}
                {cardVisaDate && isCardHasVisa && !isLoading && (
                  <Grid xs={12} item>
                    <CardVisaWarning cardVisaInfo={{ VisaExpireDate: cardItem.VisaExpireDate }} />
                  </Grid>
                )}

                {cardWaitingInstructions && cardWaitingInstructions?.length > 0 && !isLoading && (
                  <Grid xs={12} item>
                    <WaitingInstructions cardWaitingInstructions={cardWaitingInstructions} />
                  </Grid>
                )}
                {activeCardTasklist && activeCardTasklist?.length > 0 && !isLoading && (
                  <Grid xs={12} item>
                    <WaitingInstructions cardWaitingInstructions={activeCardTasklist} isAbonman />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box className="cardTransactions__last-transactions">
              <Typography variant="h3">
                <Box fontWeight="bold" fontSize={fontSizes.regularBig} marginBottom={gutters.regular}>
                  {web_card_detail_last_transactions}
                </Box>
              </Typography>
              {cardTransactions && cardTransactions?.length > 0
                ? cardTransactions.map((item, index) => (
                    <LastTransactionItem
                      onHandleClick={() => {
                        setSelectedTransaction(item);
                        setIsOpenDetail(true);
                      }}
                      transactionItem={item}
                      key={index}
                    />
                  ))
                : !isLoading && <Typography variant="subtitle2">{web_desc_card_detail_last_transactions}</Typography>}
              {cardTransactions && cardTransactions?.length >= 4 && (
                <Box textAlign="center">
                  <Typography variant="h3">
                    <Box
                      onClick={() => history.push(routePath.cardTransactionHistory, cardItem)}
                      className="cardTransactions__see--transactions"
                      itemID="seeAllTransactionsBtnTest"
                    >
                      {web_card_detail_all_transactions}
                    </Box>
                  </Typography>
                </Box>
              )}
            </Box>
          </Fragment>
        )}
        {!cardIsActive && (
          <Fragment>
            <Typography variant="h3">
              <Box fontWeight="bold" fontSize={fontSizes.regularBig} marginBottom={gutters.regular}>
                {web_passive_card_has_added}
              </Box>
            </Typography>
            <Box marginBottom={gutters.regular}>
              <Typography variant="subtitle2">{web_desc1_passive_card}</Typography>
            </Box>

            <Typography variant="subtitle2">{web_desc2_passive_card}</Typography>
          </Fragment>
        )}
      </Box>
    </div>
  );
});

export default CardTransactions;
