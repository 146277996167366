import React, { FC, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import { useQuery, useInterval, useLocalization } from 'Hooks';
import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { MasterPassTokenEnum } from 'Utils';
import { routePath } from 'Navigator/routes';
import './MobilePayment.scss';
import { masterPassPaymentNormalize } from 'Normalize';

interface MobilePaymentProps {}

interface ActiveSelectedCreditCard {
  rtaPan: string;
  expiryDate: string;
  cvv: string;
}

const MobilePayment: FC<MobilePaymentProps> = () => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(180);
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useQuery();
  const { masterpass_hash_key, masterpass_hash_id } = useLocalization();

  useInterval(() => {
    if (remainingSeconds > 0) {
      setRemainingSeconds(remainingSeconds - 1);
    }
  }, 1000);

  const decryptData = useCallback(
    (encryptedData: string) => {
      let decData = CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Utf8);
      return CryptoJS.AES.decrypt(decData, masterpass_hash_key || 'default_key', {
        iv: CryptoJS.enc.Hex.parse(masterpass_hash_id || 'default_iv'),
      }).toString(CryptoJS.enc.Utf8);
    },
    [masterpass_hash_key, masterpass_hash_id]
  );

  const processPayment = useCallback((json: any) => {
    const activeSelectedCreditCard: ActiveSelectedCreditCard | null =
      json.rtaPan && json.expiryDate && json.cvc
        ? {
            rtaPan: json.rtaPan,
            expiryDate: json.expiryDate,
            cvv: json.cvc,
          }
        : null;

    dispatch(
      onGetMasterpassForMobileRequest({
        activeSelectedCreditCard,
        TokenType: MasterPassTokenEnum.REGISTER_PURCHASE,
        referenceNo: json.referenceNo,
        PaymentNumber: json.paymentNumber,
        token: json.token,
        returnUrl: json.returnUrl,
        request: {
          data: masterPassPaymentNormalize(
            Number(json.loadAmount) + Number(json.commissionAmount),
            json.creditCardName
          ),
          phoneNumberForMobile: json.phone,
        },
      })
    );
  }, []);
  useEffect(() => {
    const fetchData = () => {
      const data = url.get('data');
      if (!data) {
        history.push(routePath.welcome);
        return;
      }

      try {
        const decryptedData = decryptData(data);
        const json = JSON.parse(decryptedData);
        processPayment(json);
      } catch (error) {
        console.error('Error processing payment data:', error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, [decryptData, processPayment]);

  return <div id="mobilePayment"></div>;
};

export default MobilePayment;
