import * as React from 'react';

function SelectArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="e6l03h76pa"
          d="M5.293 8.293c.36-.36.928-.388 1.32-.083l.094.083L12 13.585l5.293-5.292c.36-.36.928-.388 1.32-.083l.094.083c.36.36.388.928.083 1.32l-.083.094-6 6c-.36.36-.928.388-1.32.083l-.094-.083-6-6c-.39-.39-.39-1.024 0-1.414z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-865 -327) translate(445 297) translate(239 16) translate(181 14)">
                <path d="M0 0H24V24H0z" />
                <mask id="fnhtsva9ob" fill="#fff">
                  <use xlinkHref="#e6l03h76pa" />
                </mask>
                <g fill="#C3C4CB" mask="url(#fnhtsva9ob)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SelectArrowDownIcon;
