import { DialogOTP } from 'Components';
import { ToastContainer, Slide } from 'react-toastify';
import { useLocalization } from 'Hooks';
import { DialogWithActions } from 'Components/DialogWithActions';
import { useDispatch, useSelector } from 'react-redux';
import { closeBlockedDialog, storeDialog, close505ErrorDialog } from 'Stores/Dialog';
import { ToastMessageWrapper, Loading } from 'Components';
import { useHistory } from 'react-router';
import { routePath } from './routes';
import { appIsLoading } from 'Stores/App';
import { storeIsLoading } from 'Stores/Welcome';
import { userIsLoading, userStore } from 'Stores/User';
import { signUpIsLoading } from 'Stores/SignUp';
import {
  cardIsLoading,
  showCardFetchFailModalClose,
  otherCardIsLoading,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { accountIsLoading } from 'Stores/Account';
import { creditCardIsLoading } from 'Stores/CreditCardTransactions';
import { storeTransactionsLoading } from 'Stores/Transactions';
import { applicationStore } from 'Stores/CardApplication';
import { mapLoading, mapStore } from 'Stores/Maps';
import { RouterState } from 'connected-react-router';
import { balanceTransferLoading } from 'Stores/BalanceTransfer';
const GlobalComponents = () => {
  const {
    web_blocked_user_with_sc_3_desc,
    web_blocked_user_with_sc_3_btn,
    web_generic_error_header,
    web_generic_error_desc,
    web_generic_error_btn,
    web_error505506507,
  } = useLocalization();

  const history = useHistory();
  const dispatch = useDispatch();

  const storeLoading: boolean = useSelector(storeIsLoading);
  const userLoading: boolean = useSelector(userIsLoading);
  const signUpLoading: boolean = useSelector(signUpIsLoading);
  const cardLoading: boolean = useSelector(cardIsLoading);
  const otherCardsStateIsLoading: boolean = useSelector(otherCardIsLoading);
  const appLoading: boolean = useSelector(appIsLoading);
  const accountLoading: boolean = useSelector(accountIsLoading);
  const creditCardLoading: boolean = useSelector(creditCardIsLoading);
  const transactionLoading: boolean = useSelector(storeTransactionsLoading);
  const balanceTransferLoadingState: boolean = useSelector(balanceTransferLoading);
  const { loading } = useSelector(applicationStore);
  const mapsLoading: boolean = useSelector(mapLoading);

  const routerState: RouterState = useSelector((state: any) => state.router);
  let pathname = routerState?.location?.pathname;

  const { isMiniAppLogin } = useSelector(userStore);

  const { isBlockedDialogOpen, is505ErrorDialogOpen } = useSelector(storeDialog);
  const { isShowCardFetchFailModalOpen } = useSelector(storeCardTransactions);

  return (
    <>
      {(accountLoading ||
        creditCardLoading ||
        storeLoading ||
        userLoading ||
        cardLoading ||
        signUpLoading ||
        otherCardsStateIsLoading ||
        appLoading ||
        transactionLoading ||
        mapsLoading ||
        balanceTransferLoadingState ||
        loading) &&
        pathname !== routePath.mobilePayment && <Loading /> && <Loading />}
      <ToastMessageWrapper />
      <DialogOTP />
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="top-center"
        transition={Slide}
        limit={1}
        newestOnTop
        autoClose={5000}
      />
      <DialogWithActions
        title=""
        text={web_blocked_user_with_sc_3_desc}
        primaryButton={{
          label: web_blocked_user_with_sc_3_btn,
          handleClick: () => dispatch(closeBlockedDialog()),
        }}
        hide={() => dispatch(closeBlockedDialog())}
        open={isBlockedDialogOpen}
      />
      <DialogWithActions
        disableClickOutside
        title=""
        text={web_error505506507}
        primaryButton={{
          label: web_blocked_user_with_sc_3_btn,
          handleClick: () => {
            history.push(routePath.welcome);
            dispatch(close505ErrorDialog());
            if (isShowCardFetchFailModalOpen) {
              dispatch(showCardFetchFailModalClose());
            }
          },
        }}
        hide={() => {
          history.push(routePath.welcome);
          dispatch(close505ErrorDialog());
          if (isShowCardFetchFailModalOpen) {
            dispatch(showCardFetchFailModalClose());
          }
        }}
        open={is505ErrorDialogOpen}
      />
      {!isMiniAppLogin && (
        <DialogWithActions
          disableClickOutside
          title={web_generic_error_header}
          text={web_generic_error_desc}
          primaryButton={{
            label: web_generic_error_btn,
            handleClick: () => {
              dispatch(showCardFetchFailModalClose());
              history.push(routePath.welcome);
            },
          }}
          hide={() => dispatch(showCardFetchFailModalClose())}
          open={isShowCardFetchFailModalOpen && !is505ErrorDialogOpen}
        />
      )}
    </>
  );
};

export default GlobalComponents;
