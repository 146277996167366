import { ApplicationCenterListItemModel } from 'Models';
import mapCalculateDistance from './MapCalculateDistance';

type Type = Array<ApplicationCenterListItemModel & { distance?: number }>;

type functionType = {
  lat: number;
  lng: number;
  pointList?: Array<ApplicationCenterListItemModel>;
};

const closeIbmPoints = ({ lat, lng, pointList }: functionType) => {
  const tempArray: Type = [];

  if (!!!pointList) {
    return;
  }

  for (let index = 0; index < pointList.length; index++) {
    const element = pointList[index];
    const location = {
      pointLatitude: element.Latitude,
      pointLongitude: element.Longitude,
    };
    const distance = mapCalculateDistance({
      latitude: lat,
      longitude: lng,
      ...location,
    });

    tempArray.push({ ...element, distance: Number(distance) });
    tempArray[index].distance = Number(distance);
  }

  let sortData = tempArray.sort((first, second) => first.distance! - second.distance!);

  for (let index = 0; index < sortData.length; index++) {
    delete sortData[index].distance;
  }
  const result: Array<ApplicationCenterListItemModel> = sortData;

  return result;
};

export default closeIbmPoints;
