const fix3Dhtml = (str: string) => {
  str = str?.replace(/(\r\n|\n|\r)/gm, '');
  str = str = str?.replace(/\s+/g, ' ');
  str = str?.replaceAll(`"`, `'`);
  str = str?.replace(`'url'`, `"url"`);
  str = str?.replace(`'<`, `"<`);
  str = str?.replace(`</html>'`, `</html>"`);
  str = str?.replace(`'PaymentNumber'`, `"PaymentNumber"`);
  str = str?.replace(`:'`, `:"`);
  str = str?.replace(`'}`, `"}`);
  return JSON.parse(str);
};

export default fix3Dhtml;
