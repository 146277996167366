import * as React from 'react';

function AlertIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="das9y1h4ka"
          d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C15.196 1.054 12.652 0 10 0zm0 15c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm1-4c0 .552-.448 1-1 1s-1-.448-1-1V6c0-.552.448-1 1-1s1 .448 1 1v5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-266 -609) translate(250 588) translate(16 21)" />
              <g transform="translate(-266 -609) translate(250 588) translate(16 21) translate(2 2)">
                <mask id="rrl9e503vb" fill="#fff">
                  <use xlinkHref="#das9y1h4ka" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#das9y1h4ka" />
                <g fill="#EE3C2D" mask="url(#rrl9e503vb)">
                  <path d="M0 0H24V24H0z" transform="translate(-2 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AlertIcon;
