import { FC } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Link } from '@material-ui/core';
import { colors, fontSizes } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './AdressItem.scss';

type AdressItemProps = {
  adressDetail?: string;
  district?: string;
};
const AdressItem: FC<AdressItemProps> = ({ adressDetail, district }) => {
  const { web_account_edit } = useLocalization();

  const history = useHistory();

  return (
    <div id="adressItem">
      <Box flex={1}>
        <Typography variant="h3">
          <Box className="adressItem__title">{district?.toLocaleLowerCase('tr-TR')}</Box>
        </Typography>
        <Typography
          className="adressItem__adress"
          variant="body1"
          style={{ maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all' }}
        >
          {adressDetail}
        </Typography>
      </Box>
      <Box>
        <Link
          underline="none"
          onClick={() =>
            history.push(routePath.userAdressDetail, {
              isData: true,
            })
          }
        >
          <Box color={colors.primary} className="adressItem__edit">
            {web_account_edit}
          </Box>
        </Link>
      </Box>
    </div>
  );
};
export default AdressItem;
