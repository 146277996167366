import { FC } from 'react';
import { DashboardLayout } from 'Components';
import { ContactWrapper } from './ContactWrapper';
import './Contact.scss';

type ContactType = {};

const Contact: FC<ContactType> = () => {
  return (
    <DashboardLayout backButton>
      <ContactWrapper />
    </DashboardLayout>
  );
};

export default Contact;
