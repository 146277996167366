import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPinsBodyData, OnGetApplicationCenterListBodyData } from 'Config/ServiceConfig';
import { ApplicationCenterListItemModel, GetPinsModel, GetTagsModel, PinsItemModel } from 'Models';
import { RootState } from 'Stores';
import { VIEW_TYPE } from 'Utils';
import { MapsState } from './types';

export const initialState: MapsState = {
  zoom: 12,
  loading: false,
  userLocation: { latitude: 41.00527, longitude: 28.97696 },
  discoverAllPins: [],
  discoverCurrentMapLocation: { latitude: 41.00527, longitude: 28.97696 },
  discoverSelectedPin: undefined,
  discoverViewType: VIEW_TYPE.MAP,
  discoverTags: undefined,
  discoverSelectedTag: 0,
  discoverIsDirectionEnable: false,
  discoverSelectedPinFromList: undefined,
  applicationCenterList: undefined,
  ibmSelectedPin: undefined,
  ibmSelectedPinFromList: undefined,
};

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    getTagsRequest: (state: MapsState) => {
      state.loading = true;
    },
    getTagsSuccess: (state: MapsState, { payload }: PayloadAction<GetTagsModel['data']>) => {
      state.loading = false;
      state.discoverTags = { ...payload, 0: 'Tümü' };
    },
    getTagsFail: (state: MapsState) => {
      state.loading = false;
    },
    getPinsRequest: (state: MapsState, { payload }: PayloadAction<GetPinsBodyData>) => {
      state.loading = true;
    },
    getPinsSuccess: (state: MapsState, { payload }: PayloadAction<GetPinsModel['data']>) => {
      state.loading = false;
      state.discoverAllPins = payload.Pins;
    },
    getPinsFail: (state: MapsState) => {
      state.loading = false;
    },
    setUserLocation: (state: MapsState, { payload }: PayloadAction<typeof initialState.userLocation>) => {
      state.userLocation = payload;
    },
    setDiscoverSelectedTag: (state: MapsState, { payload }: PayloadAction<number>) => {
      state.discoverSelectedTag = payload;
    },
    setDiscoverViewType: (state: MapsState) => {
      const currentViewIsMap = state.discoverViewType === VIEW_TYPE.MAP;
      state.discoverViewType = currentViewIsMap ? VIEW_TYPE.LIST : VIEW_TYPE.MAP;
      state.discoverSelectedPin = undefined;
      state.discoverIsDirectionEnable = false;
    },
    setDiscoverSelectedPin: (state: MapsState, { payload }: PayloadAction<PinsItemModel | undefined>) => {
      state.discoverSelectedPin = payload;
      state.discoverIsDirectionEnable = false;
    },
    setDiscoverCurrentMapLocation: (
      state: MapsState,
      { payload }: PayloadAction<typeof initialState.discoverCurrentMapLocation>
    ) => {
      state.discoverCurrentMapLocation = payload;
    },
    setDiscoverIsDirectionEnable: (state: MapsState, { payload }: PayloadAction<boolean>) => {
      state.discoverIsDirectionEnable = payload;
    },
    resetSettings: (state: MapsState) => {
      state.discoverAllPins = [];
      state.discoverCurrentMapLocation = { latitude: 41.00527, longitude: 28.97696 };
      state.discoverViewType = VIEW_TYPE.MAP;
      state.zoom = 12;
      state.discoverIsDirectionEnable = false;
      state.discoverSelectedPinFromList = undefined;
    },
    setDiscoverSelectedPinFromList: (
      state: MapsState,
      { payload }: PayloadAction<typeof initialState.discoverSelectedPinFromList>
    ) => {
      state.discoverSelectedPinFromList = payload;
    },
    onGetApplicationCenterListRequest: (
      state: MapsState,
      { payload }: PayloadAction<OnGetApplicationCenterListBodyData>
    ) => {
      state.loading = true;
    },
    onGetApplicationCenterListSuccess: (
      state: MapsState,
      { payload }: PayloadAction<Array<ApplicationCenterListItemModel>>
    ) => {
      state.loading = false;
      state.applicationCenterList = payload;
    },
    onGetApplicationCenterListFail: (state: MapsState) => {
      state.loading = false;
    },
    setIbmSelectedPin: (state: MapsState, { payload }: PayloadAction<ApplicationCenterListItemModel | undefined>) => {
      state.ibmSelectedPin = payload;
    },
    setIbmSelectedPinFromList: (
      state: MapsState,
      { payload }: PayloadAction<typeof initialState.ibmSelectedPinFromList>
    ) => {
      state.ibmSelectedPinFromList = payload;
    },
  },
});
export const mapStore = (state: RootState) => state.maps;
export const mapLoading = (state: RootState) => state.maps.loading;

export const {
  getTagsRequest,
  getTagsSuccess,
  getTagsFail,
  getPinsRequest,
  getPinsSuccess,
  getPinsFail,
  setUserLocation,
  setDiscoverSelectedTag,
  setDiscoverViewType,
  setDiscoverSelectedPin,
  setDiscoverCurrentMapLocation,
  setDiscoverIsDirectionEnable,
  resetSettings,
  setDiscoverSelectedPinFromList,
  onGetApplicationCenterListRequest,
  onGetApplicationCenterListSuccess,
  onGetApplicationCenterListFail,
  setIbmSelectedPin,
  setIbmSelectedPinFromList,
} = mapSlice.actions;

export default mapSlice.reducer;
