import * as React from 'react';

function ApplicationEmptyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="85"
      height="85"
      viewBox="0 0 85 85"
      {...props}
    >
      <defs>
        <path
          id="mxtawyqsqa"
          d="M38.958 0c5.868 0 10.625 4.757 10.625 10.625v49.583c0 5.868-4.757 10.625-10.625 10.625H10.625C4.757 70.833 0 66.076 0 60.208V10.625C0 4.757 4.757 0 10.625 0h28.333zm7.084 15.916C36.169 5.878 21.25 10.76 21.25 25.186c0 14.425 24.792 30.167 24.792 30.167V15.916z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H85V85H0z" transform="translate(-678.000000, -273.000000) translate(678.000000, 273.000000)" />
            <g transform="translate(-678.000000, -273.000000) translate(678.000000, 273.000000) translate(17.708333, 7.083333)">
              <mask id="sa4atexpsb" fill="#fff">
                <use xlinkHref="#mxtawyqsqa" />
              </mask>
              <use fill="#383C52" fillRule="nonzero" xlinkHref="#mxtawyqsqa" />
              <g fill="#D7D8DC" mask="url(#sa4atexpsb)">
                <path d="M0 0H85V85H0z" transform="translate(-17.708333, -7.083333)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ApplicationEmptyIcon;
