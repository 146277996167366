import { errorMessageKeys } from 'Config';
import { useLocalization } from 'Hooks';

const useErrorMessage = () => {
  const {
    msg_web_10_character,
    msg_web_birthday_error,
    msg_web_email_error,
    msg_web_password_match_error,
    msg_web_password_rules_6character,
    msg_web_tckn_size_error,
    msg_web_zorunlu_alan,
    msg_web_birthday_check,
    msg_web_zorunlu_check,
    msg_web_hes_size,
    web_msg_card_number_16char,
    web_msg_serial_number_14char,
    web_msg_credit_card_kart_number_min_size,
    web_msg_credit_card_expiration_min_size,
    web_msg_credit_card_expiration_month_limit,
    web_msg_min_cvv,
    web_msg_iban_24_character,
    web_msg_end_date_check,
    web_msg_max_amount_check,
    web_msg_end_date_current_time_check,
    web_postcode_validation,
    digital_card_to_physical_card_money_transfer_invalid_balance_error,
    web_help_money_transfer_wishes_complaints_create_entry_invalid_date_warn,
    web_help_money_transfer_wishes_complaints_create_entry_invalid_hour_warn,
  } = useLocalization();
  const errorMessagesLocalization: { [key in errorMessageKeys]: string } = {
    require: msg_web_zorunlu_alan,
    mailFormat: msg_web_email_error,
    dateFormat: msg_web_birthday_check,
    minTc: msg_web_tckn_size_error,
    minBirthDate: msg_web_birthday_error,
    minPassword: msg_web_password_rules_6character,
    minPhone: msg_web_10_character,
    passwordMatch: msg_web_password_match_error,
    checkboxTrue: msg_web_zorunlu_check,
    minHes: msg_web_hes_size,
    minCardNo: web_msg_card_number_16char,
    minSeriNo: web_msg_serial_number_14char,
    minCreditCard: web_msg_credit_card_kart_number_min_size,
    minExpireDate: web_msg_credit_card_expiration_min_size,
    invalidExpireDate: web_msg_credit_card_expiration_month_limit,
    minCvv: web_msg_min_cvv,
    minIban: web_msg_iban_24_character,
    endDateNotBefore: web_msg_end_date_check,
    notAfterCurrent: web_msg_end_date_current_time_check,
    minAmountNotBiggerThanMax: web_msg_max_amount_check,
    minPostCode: web_postcode_validation,
    checkMaxValue: digital_card_to_physical_card_money_transfer_invalid_balance_error,
    invalidDate: web_help_money_transfer_wishes_complaints_create_entry_invalid_date_warn,
    invalidHour: web_help_money_transfer_wishes_complaints_create_entry_invalid_hour_warn,
  };

  const getErrorMessage = (error?: string, touched?: boolean) => {
    if (touched && !!error) {
      return errorMessagesLocalization[error as errorMessageKeys];
    }
    return '';
  };

  const getError = (error?: string, touched?: boolean) => {
    if (touched && !!error) {
      return true;
    }
    return false;
  };

  return { getError, getErrorMessage };
};

export default useErrorMessage;
