import { masterPassCheckAccountReqBodyData } from 'Config';
import { getActiveLanguageForMasterPass } from 'Utils';

export const checkMasterPassAccountNormalize = (): masterPassCheckAccountReqBodyData => {
  const formValues: masterPassCheckAccountReqBodyData = {
    sendSms: 'N',
    userId: '',
    token: '',
    referenceNo: '',
    sendSmsLanguage: getActiveLanguageForMasterPass(),
  };
  return formValues;
};
