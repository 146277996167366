import { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { onLoginLogListItemModel } from 'Models';
import { colors, fontSizes } from 'Theme/Variables';
import moment from 'moment';
import { useLocalization } from 'Hooks';

import './LogItem.scss';

type LogItemProps = {
  isLast: boolean;
};

const LogItem: FC<onLoginLogListItemModel & LogItemProps> = memo(({ InsertDate, LoginStatus, isLast }) => {
  const { web_successful_login, web_unsuccessful_login } = useLocalization();
  const date = moment(InsertDate).format('DD.MM.YYYY/HH:mm');
  return (
    <>
      <div id="loginLogItem">
        <Typography variant="h3">
          <Box fontWeight={600} fontSize={fontSizes.regular}>
            {date}
          </Box>
        </Typography>
        <Typography variant="h3">
          <Box
            color={LoginStatus === 'Başarılı' ? colors.systemGreen : colors.toastErrorText}
            fontWeight={600}
            fontSize={fontSizes.regular}
          >
            {LoginStatus === 'Başarılı' ? web_successful_login : web_unsuccessful_login}
          </Box>
        </Typography>
      </div>
      {!isLast && <div className="loginLogItem__divider" />}
    </>
  );
});
export default LogItem;
