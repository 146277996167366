import * as React from 'react';

function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className="logoutIcon"
      {...props}
    >
      <defs>
        <path
          id="39jziqshha"
          d="M3 0c.552 0 1 .448 1 1s-.448 1-1 1H2v12h1c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zm9.61 3.18c.45-.316 1.072-.209 1.39.24l2.82 4c.25.356.243.833-.02 1.18l-3 4c-.189.252-.485.4-.8.4-.216 0-.427-.07-.6-.2-.212-.16-.352-.396-.39-.659-.037-.262.03-.529.19-.741L14 9H6c-.552 0-1-.448-1-1s.448-1 1-1h8.09l-1.72-2.42c-.155-.217-.217-.487-.172-.75.045-.263.194-.497.412-.65z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-751 -793) translate(735 770) translate(16 22.014) translate(0 1)"
                />
                <g transform="translate(-751 -793) translate(735 770) translate(16 22.014) translate(0 1) translate(3 4)">
                  <mask id="rvaclrdgtb" fill="#fff">
                    <use xlinkHref="#39jziqshha" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#39jziqshha" />
                  <g fill="currentColor" mask="url(#rvaclrdgtb)">
                    <path d="M0 0H26.667V26.667H0z" transform="translate(-3 -4)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoutIcon;
