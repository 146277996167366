import * as React from 'react';

function IconList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="vgc7r12tra"
          d="M1.858 10.858c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16.06 0c.519 0 .94.42.94.94v.12c0 .519-.421.94-.94.94H5.798c-.52 0-.94-.421-.94-.94v-.12c0-.52.42-.94.94-.94h12.12zm-16.06-5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16.06 0c.519 0 .94.42.94.94v.12c0 .519-.421.94-.94.94H5.798c-.52 0-.94-.421-.94-.94v-.12c0-.52.42-.94.94-.94h12.12zm-16.06-5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16.06 0c.519 0 .94.42.94.94v.12c0 .519-.421.94-.94.94H5.798c-.52 0-.94-.421-.94-.94v-.12c0-.52.42-.94.94-.94h12.12z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-1121 -231) translate(1105 222) translate(16 9)" />
              <g transform="translate(-1121 -231) translate(1105 222) translate(16 9) translate(2.142 5.142)">
                <mask id="ftlcoqjvwb" fill="#fff">
                  <use xlinkHref="#vgc7r12tra" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#vgc7r12tra" />
                <g fill="#383C52" mask="url(#ftlcoqjvwb)">
                  <path d="M0 0H24V24H0z" transform="translate(-2 -5)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconList;
