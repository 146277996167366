import { useLocalization } from 'Hooks';
import BlueCard from 'Assets/Images/cardTypes/blue.png';
import RedCard from 'Assets/Images/cardTypes/red.png';
import DarkBlueCard from 'Assets/Images/cardTypes/darkblue.png';
import GreenCard from 'Assets/Images/cardTypes/green.png';
import OrangeCard from 'Assets/Images/cardTypes/orange.png';
import CityCard from 'Assets/Images/cardTypes/city.png';
import DigitalCardTr from 'Assets/Images/cardTypes/digital_tr.png';
import DigitalCardEn from 'Assets/Images/cardTypes/digital_en.png';
import DigitalCardTrGreen from 'Assets/Images/cardTypes/digital_tr_green.png';
import DigitalCardEnGreen from 'Assets/Images/cardTypes/digital_en_green.png';
import VirtualCardPlus from 'Assets/Images/cardTypes/virtual_plus.png';
import { generateLanguageParam } from 'Utils';

const useCardType = (productCode = 'IKT_STD') => {
  // Dont use useState it gets in loop
  const {
    card_colour_blue,
    card_colour_green,
    card_colour_orange,
    card_colour_pdks_card,
    card_colour_red,
    card_colour_city,
    card_colour_digital_card,
    card_colour_reduced_digital_card,
  } = useLocalization();

  const colors = {
    red: 'red',
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    darkblue: 'darkblue',
    city: 'city',
    digital: 'digital',
  };
  let cardPath = RedCard;
  let cardColor = colors.red;
  let cardCode = '';

  if (card_colour_reduced_digital_card.includes(productCode)) {
    const lang = generateLanguageParam();
    cardPath = lang === 'tr-TR' ? DigitalCardTrGreen : DigitalCardEnGreen;
    cardColor = colors.green;
  } else if (card_colour_red.includes(productCode)) {
    cardPath = RedCard;
    cardColor = colors.red;
    cardCode = '#e23629';
  } else if (card_colour_blue.includes(productCode)) {
    cardPath = BlueCard;
    cardColor = colors.blue;
    cardCode = '#1581be';
  } else if (card_colour_green.includes(productCode)) {
    cardPath = GreenCard;
    cardColor = colors.green;
    cardCode = '#179b92';
  } else if (card_colour_orange.includes(productCode)) {
    cardPath = OrangeCard;
    cardColor = colors.orange;
  } else if (card_colour_pdks_card.includes(productCode)) {
    cardPath = DarkBlueCard;
    cardColor = colors.darkblue;
  } else if (card_colour_city.includes(productCode)) {
    cardPath = CityCard;
    cardColor = colors.city;
  } else if (card_colour_digital_card.includes(productCode)) {
    const lang = generateLanguageParam();
    cardPath = lang === 'tr-TR' ? DigitalCardTr : DigitalCardEn;
    cardColor = colors.digital;
  }

  // TODO these are hardcoded, should be dynamic and possibly come from cms like the rest
  if (productCode === 'IKP_SNL') {
    cardPath = VirtualCardPlus;
    cardColor = 'virtual';
  }
  return { cardPath, cardColor, cardCode };
};

export default useCardType;
