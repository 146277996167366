import * as React from 'react';

function MaintenanceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="300px"
      height="409px"
      viewBox="0 0 300 409"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="200" height="308.900524" rx="24"></rect>
        <filter x="-40.0%" y="-22.7%" width="180.0%" height="151.8%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="25" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.843137255   0 0 0 0 0.847058824   0 0 0 0 0.862745098  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g id="Bakımdayız" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop---Bakım-1.1" transform="translate(-570.000000, -154.000000)">
          <g id="Group" transform="translate(620.000000, 194.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Mask">
              <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-1"></use>
              <use fill="#D7D8DC" fillRule="evenodd" xlinkHref="#path-1"></use>
            </g>
            <path
              d="M68.0628272,224.5 L131.898954,161.280712 C137.047515,162.9951 142.553442,163.936252 148.283762,163.936252 C176.839785,163.936252 200,140.838654 200,112.338764 C200,106.470042 199.004778,100.837645 197.195094,95.580464 L162.154543,130.54196 L129.085737,97.5477607 L163.660881,63.0526946 C158.803199,61.5456084 153.640094,60.7350573 148.283762,60.7329843 C119.719427,60.7329843 96.5675233,83.8347281 96.5675233,112.332545 C96.5675233,119.35594 97.9741318,126.045578 100.525558,132.148551 L68.0628272,164 L68.0628272,224.5 Z"
              id="Path"
              fill="#AFB1BA"
              fillRule="nonzero"
              mask="url(#mask-2)"
              transform="translate(134.031414, 142.616492) scale(-1, 1) translate(-134.031414, -142.616492) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MaintenanceIcon;
