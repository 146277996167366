import { Grid, Box } from '@material-ui/core';
import { FC, memo } from 'react';
import { CampaignItem } from 'Stores/Account';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './CardItem.scss';

export type CardItemProps = {
  campaignItem: CampaignItem;
  onClick: () => void;
  itemID: string;
};

const CardItem: FC<CardItemProps> = memo(({ campaignItem, onClick, itemID }) => {
  return (
    <Box id="card-item" onClick={onClick} itemID={itemID}>
      <LazyLoadImage src={campaignItem?.WebCoverPictureUrl} effect="opacity" />
    </Box>
  );
});
export default CardItem;
