import React, { FC, Fragment, useEffect } from 'react';
import {
  DashboardLayout,
  FormErrorWrapper,
  FormWrapper,
  MaskedTextField,
  PasswordForm,
  PasswordFormInfo,
} from 'Components';
import { formNames, formikGlobalConfig } from 'Config';
import { Box, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useErrorMessage, useLocalization } from 'Hooks';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';
import { ChangePasswordSchema, ChangePasswordSchemaInitialValues, ChangePasswordSchemaInputs } from 'Validations';
import { disableCopyPaste, scrollTop } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import { onChangePasswordCustomerRequest } from 'Stores/Account/slices';
import { userStore } from 'Stores/User/slices';
import { changePasswordNormalize } from 'Normalize';
import { resetFormError } from 'Stores/Form/slices';
import { UserState } from 'Stores/User';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

import './ChangePassword.scss';

type ChangePasswordProps = {};

const ChangePassword: FC<ChangePasswordProps> = () => {
  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const history = useHistory();

  const sessionToken = userData?.sessionToken;
  const customerNumber = userData?.customerNumber;

  const isResetPassword = userData?.resetPassword;

  useEffect(() => {
    dispatch(dispatch(resetFormError()));
    scrollTop();
  }, []);

  const {
    web_eskiuye_title,
    web_btn_accept,
    web_plc_change_password_current_password,
    web_desc_password_rules_all,
    web_renew_password_rule_desc,
    web_renew_password_header,
    web_renew_password_info,
    web_renew_password_desc,
  } = useLocalization();
  const { getError, getErrorMessage } = useErrorMessage();

  const handleSubmit = (values: ChangePasswordSchemaInputs) => {
    dispatch(onChangePasswordCustomerRequest(changePasswordNormalize(values, customerNumber, sessionToken)));
  };

  const defaultProps = {
    mask: '999999',
    passwordInput: true,
    onCopy: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => disableCopyPaste(e),
    onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => disableCopyPaste(e),
  };

  return (
    <DashboardLayout backButton backAction={isResetPassword ? () => history.push(routePath.welcome) : undefined}>
      <div id="change-password">
        <FormErrorWrapper formName={formNames.changePassword}>
          <Fragment>
            <Typography variant="h3" className="changePassword__title">
              <Box fontWeight={600}>{isResetPassword ? web_renew_password_header : web_eskiuye_title}</Box>
              {isResetPassword && (
                <Box>
                  <Typography variant="body1" align="center">
                    <Box marginTop={gutters.small}>{web_renew_password_info}</Box>
                  </Typography>
                  <Typography variant="body1" align="center">
                    {web_renew_password_desc}
                  </Typography>
                </Box>
              )}
            </Typography>

            <Formik
              initialValues={ChangePasswordSchemaInitialValues}
              validationSchema={ChangePasswordSchema}
              onSubmit={handleSubmit}
              {...formikGlobalConfig}
            >
              {(formikProps) => {
                const {
                  handleChange,
                  handleBlur,
                  values: { oldPassword },
                  errors: { oldPassword: errorOldPassword },
                  touched: { oldPassword: touchedOldPassword },
                } = formikProps;

                return (
                  <FormWrapper onSubmit={formikProps.handleSubmit}>
                    <Box marginBottom={gutters.regular}>
                      <MaskedTextField
                        name="oldPassword"
                        label={web_plc_change_password_current_password}
                        value={oldPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(errorOldPassword, touchedOldPassword)}
                        helperText={getErrorMessage(errorOldPassword, touchedOldPassword)}
                        itemID="passwordInputTest"
                        {...defaultProps}
                      />
                    </Box>
                    <PasswordForm formikProps={formikProps} notShowTitle />
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      itemID="changePasswordBtnTest"
                    >
                      {web_btn_accept}
                    </Button>
                  </FormWrapper>
                );
              }}
            </Formik>
            <PasswordFormInfo rules={isResetPassword ? web_renew_password_rule_desc : web_desc_password_rules_all} />
          </Fragment>
        </FormErrorWrapper>
      </div>
    </DashboardLayout>
  );
};

export default ChangePassword;
