import { FC, Fragment, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Grid, Typography } from '@material-ui/core';
import './PointListIbm.scss';
import { ApplicationCenterListItemModel, PinsItemModel } from 'Models';
import { Pagination } from '@material-ui/lab';
import { useState } from 'react';
import { fontSizes, gutters } from 'Theme/Variables';

import { useDispatch, useSelector } from 'react-redux';
import { MapsState, mapStore, setDiscoverSelectedPinFromList, setIbmSelectedPinFromList } from 'Stores/Maps';

type PointListProps = {
  onSelectedPointFromList: (item: ApplicationCenterListItemModel) => void;
};
const PointList: FC<PointListProps> = ({ onSelectedPointFromList }) => {
  const {} = useLocalization();
  const dispatch = useDispatch();

  const mapState: MapsState = useSelector(mapStore);
  const { applicationCenterList } = mapState;

  const [perItem, setPerItem] = useState<ApplicationCenterListItemModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    dispatch(setIbmSelectedPinFromList(undefined));
  }, []);

  useEffect(() => {
    //
    if (applicationCenterList) {
      const mustStartIndex = 5 * currentPage;
      const mustEndIndex = mustStartIndex + 5;
      let tempData: ApplicationCenterListItemModel[] = [];
      for (let index = mustStartIndex; index < mustEndIndex; index++) {
        const element = applicationCenterList[index];
        element && tempData.push(element);
      }
      setPerItem(tempData);
    }
  }, [currentPage, applicationCenterList]);

  const getCount = () => {
    return applicationCenterList ? Math.floor(applicationCenterList.length / 5) : 0;
  };
  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <div id="pointList">
      <Grid className="pointList__container" spacing={1} container>
        {perItem.length !== 0 &&
          perItem.map((item) => (
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Box className="pointList__item-container">
                <Box>
                  <Typography variant="h3">
                    <Box fontSize={fontSizes.small} fontWeight={600}>
                      {item.ApplicationCenterName.toLocaleLowerCase('tr-TR')}
                    </Box>
                  </Typography>

                  <Typography>
                    <Box mt={gutters.small} fontSize="13px">
                      {item.Description && item.Description.toLocaleLowerCase('tr-TR')}
                    </Box>
                  </Typography>
                </Box>
                {/* right side */}
                <Box onClick={() => onSelectedPointFromList(item)} className="pointList__item-container__btn">
                  Göster
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>

      <Pagination
        className="pointList__pagination"
        onChange={handleChange}
        page={currentPage}
        count={getCount()}
        size="small"
      />
    </div>
  );
};

export default PointList;
