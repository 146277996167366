import * as React from 'react';

function WhiteTickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="w81zvzmvsa"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C17.196 3.054 14.652 2 12 2zm4.3 7.61l-4.57 6c-.189.245-.48.39-.79.39-.308.002-.6-.139-.79-.38l-2.44-3.11c-.22-.282-.272-.66-.138-.992.135-.331.436-.565.79-.615.355-.049.708.095.928.377l1.63 2.08 3.78-5c.218-.286.572-.434.928-.388.357.046.662.279.8.61.139.332.09.712-.128.998v.03z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-564 -46) translate(548 30) translate(16 16)">
              <path d="M0 0H24V24H0z" />
              <mask id="2j9xhiispb" fill="#fff">
                <use xlinkHref="#w81zvzmvsa" />
              </mask>
              <g fill="#FFF" mask="url(#2j9xhiispb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WhiteTickIcon;
