import { GetTagsModel } from 'Models';
import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie, languageId } from 'Utils';

const command = 'cms.getPinTags';

const getTags = async (): Promise<GetTagsModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: {
        LanguageId: languageId(),
      },
      isTagsUrl: true,
    })
  ).data;

export default getTags;
