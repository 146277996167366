import { memo, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { FC } from 'react';
import { cardItemDetailType } from '../constant';
import { useLocalization, useResize } from 'Hooks';
import { cardBalanceNormalize, splitMifareIdAndAliasNumber } from 'Utils';
import { SettingsIcon, CopyIcon, ArrowLeftIcon } from 'Components';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { routePath } from 'Navigator/routes';
import { Tooltip } from './Tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import moment from 'moment';

import './CardDetailsHeader.scss';

type CardDetailsHeaderParams = {
  cardItem: cardItemDetailType;
  cardColorClass?: string;
  cardPath?: string;
};

const CardDetailsHeader: FC<CardDetailsHeaderParams> = memo(({ cardItem, cardColorClass, cardPath }) => {
  const {
    web_card_detail_card_number,
    web_card_detail_card_balance,
    web_card_detail_remaining_use,
    web_card_detail_subscription_end_date,
    web_card_detail_card_number_copied,
    web_card_detail_cvv,
    web_card_detail_expire_date,
  } = useLocalization();

  const [show, setShow] = useState(false);

  const myRef = useRef<any>();

  const { width } = useResize(myRef);

  const history = useHistory();

  const isAbonman = cardItem?.SeasonTicketLoadable;

  const cardIsActive = cardItem?.CardStatusCode === 'A' ? true : false;

  const isTinyDevice = width < 640;

  const gridSize = isTinyDevice ? 12 : 6;

  const expireDate = () => {
    return moment(cardItem?.CardExpiryDate).format('MM/YY');
  };

  return (
    <div id="cardDetailsHeader" ref={myRef}>
      {show && <Tooltip title={web_card_detail_card_number_copied} onClose={() => setShow(false)} />}
      <Box className={`cardDetailsHeader__${cardColorClass}`}>
        <Box className="cardDetailsHeader__container">
          <ArrowLeftIcon onClick={() => history.push(routePath.cards)} className="cardDetailsHeader__backIcon" />
          {cardItem?.NickName && (
            <Box className="cardDetailsHeader__title" component="div">
              {cardItem?.NickName}
            </Box>
          )}
          <Grid
            container
            className={classNames({
              'cardDetailsHeader__inner-container': true,
              cardDetailsHeader__minHeightNormalize: !cardItem?.NickName && isTinyDevice,
            })}
          >
            {!cardIsActive && cardItem?.AliasNumber && (
              <Grid item xs={12}>
                <Box className="cardDetailsHeader__item">
                  <Box className="cardDetailsHeader__cardinfo-desc">{web_card_detail_card_number}</Box>
                  <Box display="flex" alignItems="center">
                    <Box className="cardDetailsHeader__cardinfo-value">
                      {splitMifareIdAndAliasNumber(cardItem?.AliasNumber)}
                    </Box>
                    <CopyToClipboard onCopy={() => {}} text={cardItem?.AliasNumber}>
                      <CopyIcon onClick={() => setShow(true)} />
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Grid>
            )}
            {/**TODO "0" */}
            {cardIsActive && cardItem?.SeasonTicketExpireDate && (
              <Grid container>
                {!!cardItem && (
                  <Grid xs={gridSize}>
                    <Box className="cardDetailsHeader__item">
                      <Box className="cardDetailsHeader__cardinfo-desc">{web_card_detail_card_balance}</Box>
                      <Box className="cardDetailsHeader__cardinfo-value">
                        {cardBalanceNormalize(cardItem?.CardBalance)} TL
                      </Box>
                    </Box>
                  </Grid>
                )}
                {cardItem?.AliasNumber && (
                  <Grid xs={gridSize}>
                    <Box className="cardDetailsHeader__item">
                      <Box className="cardDetailsHeader__cardinfo-desc">{web_card_detail_card_number}</Box>
                      <Box display="flex" alignItems="center">
                        <Box className="cardDetailsHeader__cardinfo-value">
                          {splitMifareIdAndAliasNumber(cardItem?.AliasNumber)}
                        </Box>
                        <CopyToClipboard onCopy={() => {}} text={cardItem?.AliasNumber}>
                          <CopyIcon onClick={() => setShow(true)} />
                        </CopyToClipboard>
                      </Box>
                    </Box>
                  </Grid>
                )}

                {(cardItem?.CardCvv || cardItem?.CardCvv !== 0) && (
                  <Grid xs={gridSize}>
                    <Box className="cardDetailsHeader__item">
                      <Box className="cardDetailsHeader__cardinfo-desc">{web_card_detail_cvv}</Box>
                      <Box className="cardDetailsHeader__cardinfo-value">{cardItem?.CardCvv}</Box>
                    </Box>
                  </Grid>
                )}

                {cardItem?.CardExpiryDate !== '0001-01-01T00:00:00' && (
                  <Grid xs={gridSize}>
                    <Box className="cardDetailsHeader__cardinfo-desc">{web_card_detail_expire_date}</Box>
                    <Box className="cardDetailsHeader__cardinfo-value">{expireDate()}</Box>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Box itemID="seeCardSettingsBtnTest">
            <SettingsIcon onClick={() => history.push(routePath.cardSettings)} />
          </Box>
          <LazyLoadImage src={cardPath} alt="" className="cardDetailsHeader__img" effect="opacity" />
        </Box>
      </Box>
    </div>
  );
});

export default CardDetailsHeader;
