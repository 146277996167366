import { FC, useEffect } from 'react';
import { FormWrapper, MaskedTextField, Tooltip, Modal3DSecure, FormErrorWrapper } from 'Components';
import { Box, TextField, Typography, Button, Grid } from '@material-ui/core';
import { useLocalization, useErrorMessage } from 'Hooks';
import { Formik } from 'formik';
import { formikGlobalConfig, formNames, inputMaskTypes } from 'Config';
import { fontSizes, gutters } from 'Theme/Variables';
import { AddCreditCardSchema, addCreditCardSchemaInitialValues } from 'Validations';
import { useDispatch, useSelector } from 'react-redux';
import { close3DSecureModal, creditCardTransactionsStore } from 'Stores/CreditCardTransactions';
import { userStore } from 'Stores/User';
import { InformationRow } from 'Views/CardApplicationsDetail/InformationRow';
import {
  ApplicationProcessState,
  applicationStore,
  getProductPriceRequest,
  onAddApplicationRequest,
  resendCargoRequest,
  setPaymentInformationData,
} from 'Stores/CardApplication';

import './PaymentStep.scss';
import { resetFormError } from 'Stores/Form';
import { AccountState, onUpdateCustomerAddressRequest, storeAccount } from 'Stores/Account';
import { adressTransactionTypes } from 'Views/UserAdressDetail/constant';
import { townOtherListItem } from 'Models/GetTownListOtherModel';
import { storeCardTransactions } from 'Stores/CardTransactions';
import sanitizeHtml from 'Utils/SanitizeHtml';

type PaymentStepProps = {};
const PaymentStep: FC<PaymentStepProps> = ({}) => {
  const dispatch = useDispatch();
  const {
    web_card_appcation_enter_debit_card,
    web_plc_add_credit_card_number,
    web_plc_add_credit_card_cvv,
    web_plc_add_credit_card_expiration_date,
    web_plc_add_credit_card_expiration_date_detail,
    web_title_cvv_box,
    web_msg_credit_card_cvv,
    web_card_application_owner_name,
    web_card_application_go_to_pay,
    web_card_application_amount,
    web_card_application_card_cost,
    web_card_application_shipping_cost,
    web_card_application_total_cost,
    web_free_shipping,
  } = useLocalization();

  const { getError, getErrorMessage } = useErrorMessage();

  const store: ApplicationProcessState = useSelector(applicationStore);
  const userData = useSelector(userStore);
  const accountData: AccountState = useSelector(storeAccount);
  const { CustodyCustomerNumber, HasCustodyRelation } = useSelector(storeCardTransactions);

  const { is3DSecureModalOpen, vsPosBelbim3DsecureUrl, activePayment } = useSelector(creditCardTransactionsStore);
  const { sessionToken, customerNumber } = userData;
  const { otherTownList } = accountData;
  const {
    isShipping,
    activeApplicationData,
    applicationCard,
    productPriceResponse,
    activeCardShippingFee,
    activeUpStateShippingFee,
    cardApplicationStatus,
  } = store;

  const SHIPPING_FEE =
    activeApplicationData?.AddressDetail?.CityCode === 34 ? activeCardShippingFee : activeUpStateShippingFee;

  const productPrice = cardApplicationStatus === 6 ? 0 : productPriceResponse?.data.ProductPrice.toFixed(2);

  const handleSubmit = (values: any) => {
    if (cardApplicationStatus === 6) {
      const otherTownCode = otherTownList?.find(
        (item: townOtherListItem) => item.TownName === activeApplicationData?.AddressDetail?.TownCode
      );
      dispatch(setPaymentInformationData(values));
      dispatch(
        resendCargoRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          AddressType: '9',
          AddressDetail: activeApplicationData?.AddressDetail?.AddressDetail,
          CityCode: activeApplicationData?.AddressDetail?.CityCode,
          City: 'ISTANBUL',
          //City: activeApplicationData?.AddressDetail?.City,
          TownCode: otherTownCode?.TownId,
          District: activeApplicationData?.AddressDetail?.District,
          PostalCode: activeApplicationData?.AddressDetail?.PostalCode?.toString(),
          Street: activeApplicationData?.AddressDetail?.Street,
          ProcessType: adressTransactionTypes.updatedAdress,
        })
      );
    } else {
      dispatch(setPaymentInformationData(values));
      dispatch(onAddApplicationRequest());
    }
  };

  useEffect(() => {
    dispatch(resetFormError());
    dispatch(
      getProductPriceRequest({
        ProductCode: applicationCard?.ProductCode!,
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CustodyCustomerNumber: CustodyCustomerNumber ? CustodyCustomerNumber : undefined,
      })
    );
  }, []);

  return (
    <div id="paymentStep">
      <Typography variant="h3">
        <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
          {web_card_appcation_enter_debit_card}
        </Box>
      </Typography>
      <FormErrorWrapper formName={formNames.paymentStep}>
        <Formik
          initialValues={addCreditCardSchemaInitialValues}
          validationSchema={AddCreditCardSchema(true)}
          onSubmit={handleSubmit}
          {...formikGlobalConfig}
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              setFieldValue,
              values: { creditCardNumber, expireDate, cvv, cardName, addCardToMasterPass },
              errors: {
                creditCardNumber: errorCreditCardNumber,
                expireDate: errorExpireDate,
                cvv: errorCvv,
                cardName: errorCardName,
                addCardToMasterPass: errorCardToMasterPass,
              },
              touched: {
                creditCardNumber: touchedCreditCardNumber,
                expireDate: touchedExpireDate,
                cvv: touchedCvv,
                cardName: touchedCardName,
                addCardToMasterPass: touchedAddToMasterPass,
              },
            } = formikProps;
            return (
              <FormWrapper onSubmit={formikProps.handleSubmit}>
                <Box className="paymentStep__inputs">
                  <TextField
                    name="cardName"
                    label={web_card_application_owner_name}
                    variant="filled"
                    fullWidth
                    value={cardName}
                    onChange={(e) => setFieldValue('cardName', sanitizeHtml(e.target.value))}
                    onBlur={handleBlur}
                    error={getError(errorCardName, touchedCardName)}
                    helperText={getErrorMessage(errorCardName, touchedCardName)}
                    inputProps={{ maxLength: 55 }}
                    itemID="cardApplyCreditCardNameInputTest"
                  />
                </Box>
                <Box className="paymentStep__inputs">
                  <MaskedTextField
                    name="creditCardNumber"
                    label={web_plc_add_credit_card_number}
                    value={creditCardNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 14 }}
                    mask={inputMaskTypes.userCardNo}
                    variant="filled"
                    fullWidth
                    error={getError(errorCreditCardNumber, touchedCreditCardNumber)}
                    helperText={getErrorMessage(errorCreditCardNumber, touchedCreditCardNumber)}
                    itemID="cardApplyCreditCardNumberInputTest"
                  />
                </Box>
                <Box className="paymentStep__inputs">
                  <Grid container className="paymentStep__innerContainer">
                    <Grid item xs={6} sm={7}>
                      <Box className="paymentStep__expireDate">
                        <MaskedTextField
                          name="expireDate"
                          mask={inputMaskTypes.creditCardExpireDate}
                          label={web_plc_add_credit_card_expiration_date}
                          value={expireDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={web_plc_add_credit_card_expiration_date_detail}
                          error={getError(errorExpireDate, touchedExpireDate)}
                          helperText={getErrorMessage(errorExpireDate, touchedExpireDate)}
                          itemID="cardApplyCreditCardExpireDateInputTest"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <Box className="paymentStep__cvc">
                        <MaskedTextField
                          name="cvv"
                          label={web_plc_add_credit_card_cvv}
                          variant="filled"
                          fullWidth
                          value={cvv}
                          mask={inputMaskTypes.cvv}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getError(errorCvv, touchedCvv)}
                          helperText={getErrorMessage(errorCvv, touchedCvv)}
                          inputProps={{ maxLength: 3, inputMode: 'numeric' }}
                          itemID="cardApplyCreditCardCvvInputTest"
                        />
                        <Box className="paymentStep__tooltip__box">
                          <Tooltip
                            title={web_title_cvv_box}
                            id="paymentStep__tooltip"
                            desc1={web_msg_credit_card_cvv}
                            calculate
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="h3">
                  <Box mb={gutters.regular} mt={gutters.big} fontWeight={600}>
                    {web_card_application_amount}
                  </Box>
                </Typography>
                <InformationRow
                  topDivider
                  desc={`${productPrice}`}
                  name={`${web_card_application_card_cost} (${productPriceResponse?.data.ApplicationReasonName})`}
                />
                {isShipping && SHIPPING_FEE && SHIPPING_FEE > 0 ? (
                  <InformationRow topDivider desc={`${SHIPPING_FEE}`} name={web_card_application_shipping_cost} />
                ) : (
                  <InformationRow topDivider desc={web_free_shipping} name={web_card_application_shipping_cost} />
                )}

                <Typography align="center">
                  <Box mt={gutters.big} fontSize={fontSizes.tiny}>
                    {web_card_application_total_cost}
                  </Box>
                </Typography>
                <Typography variant="h3" align="center">
                  <Box mb={gutters.big} fontSize={fontSizes.big} fontWeight={600}>
                    {isShipping ? (Number(productPrice) + Number(SHIPPING_FEE || 0)).toFixed(2) : Number(productPrice)}{' '}
                    TL
                  </Box>
                </Typography>
                <Button fullWidth variant="contained" color="secondary" type="submit" itemID="goToPayCardApplyBtnTest">
                  {web_card_application_go_to_pay}
                </Button>
              </FormWrapper>
            );
          }}
        </Formik>
      </FormErrorWrapper>
      {is3DSecureModalOpen && (
        <Modal3DSecure
          open={is3DSecureModalOpen}
          link={vsPosBelbim3DsecureUrl}
          paymentNo={activePayment}
          close={() => dispatch(close3DSecureModal())}
        />
      )}
    </div>
  );
};
export default PaymentStep;
