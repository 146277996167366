import { FC, memo, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { AccountState, storeAccount } from 'Stores/Account';
import { Box } from '@material-ui/core';

import { FAQItem } from '../FAQItem';
import { FAQSearch } from '../FAQSearch';

import './FAQList.scss';

type FAQListProps = {
  handleClick: (a: string, q: string) => void;
};

const FAQList: FC<FAQListProps> = ({ handleClick }) => {
  const userData: AccountState = useSelector(storeAccount);
  const data = userData.faq?.data.Questions;

  const [res, setRes] = useState<any>();

  useEffect(() => {
    setRes(data);
  }, [data]);

  const filteredData = useMemo(() => res, [res]);

  const handleChange = (filteredData: any, isAvaliable: boolean) => {
    if (isAvaliable) {
      setRes(filteredData);
    } else {
      setRes(data);
    }
  };

  return (
    <Box>
      <FAQSearch handleChange={handleChange} />
      {filteredData?.map((item: any, index: any) => {
        return <FAQItem key={index} handleClick={() => handleClick(item.Answer, item.Question)} {...item} />;
      })}
    </Box>
  );
};
export default memo(FAQList);
