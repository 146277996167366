import { FC, memo } from 'react';
import { useLocalization } from 'Hooks';
import { Box } from '@material-ui/core';
import { InfoItem } from '../InfoItem';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { UserState, userStore } from 'Stores/User';
import { useSelector } from 'react-redux';

type UserAdressProps = {};

const UserAdress: FC<UserAdressProps> = memo(() => {
  const { web_label_address, web_communication_address_null } = useLocalization();

  const userData: UserState = useSelector(userStore);
  const userAdressList = userData.adressList;

  const history = useHistory();
  return (
    <Box id="userAdress">
      <InfoItem
        title={web_label_address}
        value={userAdressList ? userAdressList[0].AddressDetail : web_communication_address_null}
        onClick={() => history.push(routePath.userAdress)}
        add={!userAdressList}
        valueClass="adressDescription"
      />
    </Box>
  );
});
export default UserAdress;
