import {
  GetApiGWTokenModel,
  GetApplicationStatusModel,
  GetTokenForMicroCreditLoginModel,
  OnlineShoppingLoan,
  OnMicroCreditBankProcessModel,
  OnMicroCreditCheckStatusModel,
} from 'Models';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  openBankSecureModal,
  onMicroCreditBankProcessRequest,
  creditWarningMessageModalOpen,
  onMicroCreditBankProcessSuccess,
  checkOnmicroCreditStatus,
  checkPaymentStatusRequestResult,
  applicationStatusWarningModalOpen,
} from './slices';

import {
  getApiGWRefreshToken,
  getApplicationStatus,
  getTokenForLogin,
  loginWithToken,
  onLineShoppingLoan,
  onMicroCreditBankProcess,
  onMicroCreditCheckStatus,
} from 'Services/Queries';
import { getApiGWTokenCookie, getEnvironment, getPublicIpFromStorage, isBelbimRequestSuccess } from 'Utils';
import { Port } from 'Services/Contants';
import { UserState, userStore } from 'Stores/User';
import { closeBankSecureModal, MicroCreditState, microCreditStore } from '.';
import { onMicroCreditBankProcessBodyData, onMicroCreditCheckStatusBodyData } from 'Config/ServiceConfig';
import { PayloadAction } from '@reduxjs/toolkit';

const isDev = getEnvironment();
const fibaBankRedirectUrl = isDev
  ? 'https://testib.fibabanka.com.tr/?waitingslink=true'
  : 'https://internetbankaciligi.fibabanka.com.tr/?waitingslink=true';

function* handleOnMicroCreditBankProcessRequest() {
  const userData: UserState = yield select(userStore);
  const isMiniApp = userData?.isMiniAppLogin;
  const { customerNumber, sessionToken } = userData;
  const payloadData: onMicroCreditBankProcessBodyData = {
    command: 'RI.OnMicroCreditBankProcess',
    id: getApiGWTokenCookie(),
    data: {
      CustomerNumber: customerNumber,
      SessionToken: sessionToken,
      IpAddress: getPublicIpFromStorage(),
      Port: Port,
    },
  };

  if (isMiniApp) {
    payloadData.data.ChannelType = 'ISTANBULSENİN';
  }

  const onMicroCreditBankProcessResult: OnMicroCreditBankProcessModel = yield call(() =>
    onMicroCreditBankProcess(payloadData)
  );

  if (
    onMicroCreditBankProcessResult.data?.ResponseCode == '874' ||
    onMicroCreditBankProcessResult.data?.ResponseCode == '861'
  ) {
    if (onMicroCreditBankProcessResult.data?.ResponseCode == '874') {
      yield put(creditWarningMessageModalOpen('874'));
    }
    if (onMicroCreditBankProcessResult.data?.ResponseCode == '861') {
      yield put(creditWarningMessageModalOpen('861'));
    }
  } else {
    if (onMicroCreditBankProcessResult.data?.CreditStatus == 'P') {
      yield put(creditWarningMessageModalOpen('P'));
    } else {
      if (onMicroCreditBankProcessResult.status == 'success') {
        yield put(onMicroCreditBankProcessSuccess(onMicroCreditBankProcessResult.data.OrderId));
        const requestData = {
          UID: 'T99102',
          CTID: '1629381207058',
          orderId: onMicroCreditBankProcessResult.data.OrderId,
          companyName: 'BELBIM',
          FCID: '120',
          TCID: '000',
        };
        const getApplicationStatusResult: GetApplicationStatusModel = yield call(() =>
          getApplicationStatus(requestData)
        );
        if (
          getApplicationStatusResult.applicationStatus == '62' ||
          getApplicationStatusResult.applicationStatus == '58' ||
          getApplicationStatusResult.applicationStatus == '61' ||
          getApplicationStatusResult.applicationStatus == '65'
        ) {
          if (getApplicationStatusResult.applicationStatus == '58') window.location.href = fibaBankRedirectUrl;
          if (
            getApplicationStatusResult.applicationStatus == '62' ||
            getApplicationStatusResult.applicationStatus == '61'
          ) {
            yield put(applicationStatusWarningModalOpen('62'));
          }
          if (getApplicationStatusResult.applicationStatus == '65') {
            yield put(applicationStatusWarningModalOpen('65'));
          }
        } else {
          const fibaBankData = {
            orderId: onMicroCreditBankProcessResult.data.OrderId,
            companyName: 'BELBIM',
            productType: '3',
            amount: 0,
            currency: 949,
            successRedirectUrl: isMiniApp
              ? 'https://bireysel.istanbulkart.istanbul/fibaBankSuccessPage&shouldNotReturn'
              : 'https://bireysel.istanbulkart.istanbul/fibaBankSuccessPage',
            failRedirectUrl: 'https://bireysel.istanbulkart.istanbul/fibaBankFailPage',
          };
          const onLineShoppingLoanResult: OnlineShoppingLoan = yield call(() => onLineShoppingLoan(fibaBankData));
          yield put(openBankSecureModal(onLineShoppingLoanResult.redirectURL));
        }
      }
    }
  }
}

function* handleCheckOnmicroCreditStatus({ payload }: PayloadAction<onMicroCreditCheckStatusBodyData>) {
  try {
    const onMicroCreditCheckStatusResult: OnMicroCreditCheckStatusModel = yield call(() =>
      onMicroCreditCheckStatus(payload)
    );
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(onMicroCreditCheckStatusResult);
    if (requestSuccess) {
      if (onMicroCreditCheckStatusResult.data.CreditStatus == 'P') {
        yield put(closeBankSecureModal());
      }
      yield put(checkPaymentStatusRequestResult(onMicroCreditCheckStatusResult));
    }
  } catch (error: any) {
    console.log('onMicroCreditCheckStatusResult', error);
  }
}

function* microCreditSagaWatcher() {
  yield takeLatest(onMicroCreditBankProcessRequest, handleOnMicroCreditBankProcessRequest);
  yield takeLatest(checkOnmicroCreditStatus, handleCheckOnmicroCreditStatus);
}

export default microCreditSagaWatcher;
