import * as yup from 'yup';
import { errorMessages } from 'Config';

const PasswordStep = yup.object({
  password: yup.string().required(errorMessages.require).min(6, errorMessages.minPassword),
});
const PhoneStep = yup.lazy(({ countryCode }) =>
  yup.object({
    phone: yup
      .string()
      .required(errorMessages.require)
      .min(countryCode === '90' ? 12 : 17, countryCode === '90' ? errorMessages.minPhone : ''),
    countryCode: yup.string().required(),
  })
);

export type LoginSchemaInputs = yup.InferType<typeof PhoneStep & typeof PasswordStep>;

export const loginSchemaInitialValues: LoginSchemaInputs = {
  phone: '',
  countryCode: '90',
  password: '',
};

export const LoginSchema = [PhoneStep, PasswordStep];
