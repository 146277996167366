import { FC } from 'react';
import { AbonmanCardIcon, ChevronDarkRightIcon, CloseIcon, DarkPlusIcon } from 'Components';
import { useLocalization, useModal } from 'Hooks';
import { Box, Typography, Dialog } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { SelectedCardType } from './constant';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setAddCardStep, setIsUserAddingNewCardInTransactions, storeCardTransactions } from 'Stores/CardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { SelectedCard } from './SelectedCard';

import './ChooseCard.scss';
import { filterDigitalCards } from 'Views/UserCards/AllCards/constant';

type ChooseCardProps = {
  selectedCard?: SelectedCardType;
  title?: string;
  disabled?: boolean;
};

const ChooseCard: FC<ChooseCardProps> = ({ selectedCard, title, disabled }) => {
  const {
    digital_card_to_physical_card_money_transfer_select_card,
    digital_card_to_physical_card_money_transfer_select_topup_card,
    web_btn_enter_new_card_creditcard_popup,
    web_title_my_cards,
    web_money_transfer_pop_up_header,
  } = useLocalization();
  const cardData = useSelector(storeCardTransactions);
  const dispatch = useDispatch();

  const userCards: cardItemParams[] = filterDigitalCards(cardData?.cards);

  const { isVisible, show, hide } = useModal();
  const history = useHistory();

  return (
    <div id="chooseCard">
      <Typography variant="h6" align="center">
        <Box fontWeight="bold" marginBottom={fontSizes.regular} textAlign="left">
          {title}
        </Box>
      </Typography>
      {!selectedCard && (
        <Box className="chooseCard__container" onClick={() => disabled || show()}>
          <Box display="flex" alignItems="center">
            <AbonmanCardIcon />
            <Box>
              <Typography variant="h6">
                <Box fontWeight="bold" className="chooseCard__toBeLoaded">
                  {digital_card_to_physical_card_money_transfer_select_card}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box>{digital_card_to_physical_card_money_transfer_select_topup_card}</Box>
              </Typography>
            </Box>
          </Box>
          {!disabled ? <ChevronDarkRightIcon /> : null}
        </Box>
      )}
      {selectedCard && (
        <Box onClick={!disabled ? show : undefined}>
          <SelectedCard cardItem={selectedCard} isDialog={false} disabled={disabled} />
        </Box>
      )}
      <Dialog open={isVisible} onClose={hide}>
        <div className="dialogContainer chooseCard__dialog">
          <CloseIcon onClick={hide} />
          <Box>
            <Typography variant="h3" align="center">
              <Box fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.regular}>
                {web_money_transfer_pop_up_header}
              </Box>
            </Typography>
            <Box className="chooseCard__dialog__container">
              {userCards && userCards?.length > 0 && (
                <Box>
                  <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.gray}>
                    {web_title_my_cards}
                  </Box>
                  {/** IF user Has favorite card it has to be first */}
                  {userCards &&
                    userCards?.length > 0 &&
                    userCards
                      .filter((item) => item?.CardStatusCode === 'A' && item?.IsPrimary === true)
                      .map((item, index) => (
                        <SelectedCard
                          key={index}
                          cardItem={item}
                          isSelected={selectedCard?.MifareId === item?.MifareId ? true : false}
                          isDialog
                          hide={hide}
                        />
                      ))}
                  {userCards &&
                    userCards
                      .filter((item) => item?.CardStatusCode === 'A' && item?.IsPrimary !== true)
                      .map((item, index) => (
                        <SelectedCard
                          key={index}
                          cardItem={item}
                          isSelected={selectedCard?.MifareId === item?.MifareId ? true : false}
                          isDialog
                          hide={hide}
                        />
                      ))}
                </Box>
              )}
            </Box>
            <div
              onClick={() => {
                history.push(routePath.addCard);
                dispatch(setAddCardStep(2));
                dispatch(setIsUserAddingNewCardInTransactions(true));
              }}
              itemID="addNewCardToUploadTLBtnTest"
            >
              <Box className="chooseCard__dialog--addButton">
                <DarkPlusIcon />
                <Box flex={1}>
                  <Typography variant="h6" align="center">
                    <Box fontWeight={600}>{web_btn_enter_new_card_creditcard_popup}</Box>
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  );
};

export default ChooseCard;
