import { axiosHelper } from 'Services/AxiosHelper';
import { GetNewsModel } from 'Models';
import { newsReqBodyData } from 'Config/ServiceConfig';
import { getApiGWTokenCookie, getEnvironment } from 'Utils';
import IsUsingTmobCms from 'Utils/IsUsingTmobCms';

const command = 'cms.getNews';

const getNews = async (params: newsReqBodyData): Promise<GetNewsModel> =>
  IsUsingTmobCms() && getEnvironment()
    ? await axiosHelper({
        method: 'post',
        data: {
          LanguageId: params.LanguageId,
        },
        isNewsUrl: true,
      })
    : (
        await axiosHelper({
          method: 'post',
          gatewayData: {
            command,
            token: getApiGWTokenCookie(),
          },
          data: params,
          isTmobLabsUrl: true,
        })
      ).data;

export default getNews;
