import { axiosHelper } from 'Services/AxiosHelper';
import { GetCityListModel } from 'Models';
import { getApiGWTokenCookie } from 'Utils';
import { onMicroCreditCheckStatusBodyData } from 'Config/ServiceConfig';

const command = 'RI.OnMicroCreditCheckStatus';

const onMicroCreditCheckStatus = async (params: onMicroCreditCheckStatusBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onMicroCreditCheckStatus;
