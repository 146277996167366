import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCmsModel, OnInıtModel } from 'Models';
import { RootState } from 'Stores/index';
import { AppState, cacheBusterType } from './types';

export const initialState: AppState = {
  isInitialized: false,
  isLoading: true,
  hasAccess: false,
  cmsData: undefined,
  portNumber: undefined,
  onInıtData: undefined,
  error: undefined,
  globalIsLoading: true,
  isMaintenance: false,
  isLatestVersion: true, // for cache -- default value = false
  cacheLoading: false, // for cache -- default value = true
  agreementUpdateRequired: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    onAppInit: (state: AppState) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.globalIsLoading = false;
    },
    appIsMaintenance: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.isMaintenance = payload;
    },
    setAccessTrue: (state: AppState) => {
      state.hasAccess = true;
    },
    setAccessFalse: (state: AppState) => {
      state.hasAccess = false;
    },
    setLanguage: (state: AppState) => state,
    cmsRequest: (state: AppState) => {
      state.isLoading = true;
    },
    cmsRequestSuccess: (state: AppState, action: PayloadAction<GetCmsModel['data']>) => {
      state.cmsData = action.payload;
      state.isLoading = false;
    },

    apiGWTokenRequest: (state: AppState) => {
      state.isLoading = true;
    },

    publicIpRequest: (state: AppState) => state,
    onInıtDataRequest: (state: AppState, action: PayloadAction<string>) => {},
    onInıtDataRequestSuccess: (state: AppState, action: PayloadAction<OnInıtModel>) => {
      state.isLoading = false;
      state.onInıtData = action.payload.data;
    },
    onInıtDataRequestError: (state: AppState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setGlobalLoadingTrue: (state: AppState) => {
      state.globalIsLoading = true;
    },
    setGlobalLoadingFalse: (state: AppState) => {
      state.globalIsLoading = false;
    },
    setVersionStatus: (state: AppState, { payload }: PayloadAction<cacheBusterType>) => {
      state.cacheLoading = payload.loading;
      state.isLatestVersion = payload.isLatestVersion;
    },
    setUpdateAgreementRequired: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.agreementUpdateRequired = payload;
    },
    fetchVersionNumber: (state: AppState, { payload }: PayloadAction<string>) => state,
    resetAllStore: (state: AppState) => {},
  },
});

export const storeIsInit = (state: RootState) => state.app.isInitialized && !state.app.isLoading;
export const storeCmsData = (state: RootState) => state.app.cmsData;
export const storeHasAccess = (state: RootState) => state.app.hasAccess;
export const storeCountryCodes = (state: RootState) => state.app.onInıtData?.PhoneList;
export const appIsLoading = (state: RootState) => state.app.isLoading;
export const globalIsLoading = (state: RootState) => state.app.globalIsLoading;
export const connectedRouterStore = (state: RootState) => state.router;
export const maintenanceStore = (state: RootState) => state.app.isMaintenance;
export const onInıtDataStore = (state: RootState) => state.app.onInıtData;
export const storeApp = (state: RootState) => state.app;

export const {
  onAppInit,
  setAccessTrue,
  setLanguage,
  cmsRequest,
  cmsRequestSuccess,
  apiGWTokenRequest,
  publicIpRequest,
  onInıtDataRequest,
  onInıtDataRequestSuccess,
  onInıtDataRequestError,
  setGlobalLoadingTrue,
  setGlobalLoadingFalse,
  appIsMaintenance,
  setAccessFalse,
  setVersionStatus,
  fetchVersionNumber,
  resetAllStore,
  setUpdateAgreementRequired,
} = appSlice.actions;

export default appSlice.reducer;
