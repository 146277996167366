import React, { FunctionComponent, useCallback, useEffect } from 'react';

type FormWrapperProps = {} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const FormWrapper: FunctionComponent<FormWrapperProps> = ({ children, ...props }) => {
  const keyDownHandler = useCallback((event: any) => {
    if (
      (event.keyCode === 13 || event.keyCode === 9) &&
      event.target.nodeName === 'INPUT' &&
      !event.target.offsetParent.className.includes('MuiPaper-root')
    ) {
      const form = event.target.form;

      if (form) {
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1]?.focus();
      }

      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
    // eslint-disable-next-line
  }, []);

  return (
    <form {...props} autoComplete="off">
      {children}
    </form>
  );
};

export default FormWrapper;
