import { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { gutters } from 'Theme/Variables';
import { CreditCardListItem } from './CreditCardListItem';
import { DarkPlusIcon, MasterpassArea } from 'Components';
import { MasterPassGetCardList } from 'Models';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

import './CreditCardList.scss';

type CreditCardListProps = {
  data: MasterPassGetCardList[];
};

const CreditCardList: FC<CreditCardListProps> = ({ data }) => {
  const { web_title_registered_credit_cards, web_btn_add_credit_card } = useLocalization();
  const history = useHistory();
  return (
    <div id="credit-card-list">
      <Typography variant="h3">
        <Box className="credit-card-list__title" marginBottom={gutters.large} fontWeight={600} textAlign="center">
          {web_title_registered_credit_cards}
        </Box>
      </Typography>
      {data.map((item) => (
        <div onClick={() => history.push(routePath.creditCardDetail, item)} key={item.UniqueId}>
          <CreditCardListItem {...item} />
        </div>
      ))}
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<DarkPlusIcon />}
        onClick={() => history.push(routePath.addCreditCard)}
        type="submit"
        className="rootAbsolute"
        itemID="addCreditCardBtnTest"
      >
        {web_btn_add_credit_card}
      </Button>
      <MasterpassArea />
    </div>
  );
};

export default CreditCardList;
