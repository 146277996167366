import { Fragment, FunctionComponent, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ForgotPasswordIdentityStep } from './ForgotPasswordIdentityStep';
import { Formik, FormikProps } from 'formik';
import { useLocalization } from 'Hooks';
import { push } from 'connected-react-router';
import {
  FormWrapper,
  FullPageWrapper,
  PasswordForm,
  PasswordFormInfo,
  PasswordFormSuccess,
  StepWrapper,
  FormErrorWrapper,
} from 'Components';
import { onSetPasswordRequest } from 'Stores/SignUp/slices';
import { onSendTokenChangePasswordRequest, resetForgotPasswordFormStep, userStore } from 'Stores/User/slices';
import { sendTokenChangePasswordNormalize, onSetPasswordNormalize } from 'Normalize';
import { ForgotPasswordSchema, forgotPasswordSchemaInitialValues, ForgotPasswordSchemaInputs } from 'Validations';
import { scrollTop } from 'Utils';
import { formikGlobalConfig, formNames } from 'Config';
import { get } from 'lodash';
import { routePath } from 'Navigator/routes';

import './ForgotPassword.scss';

type ForgotPasswordParams = {
  countryCode?: string;
  phoneNumber?: string;
  userType?: string;
  descriptionKey?: string;
};
type ForgotPasswordProps = RouteComponentProps<ForgotPasswordParams>;

export const userTypes = {
  active: '1',
  waitingForSmsApproval: '2',
  Blocked: '3',
  waitingForEmailApproval: '4',
  blockedFromWrongAccess: '5',
  contant: '6',
  waitingForSetPassword: '7',
  oldCustomer: '8',
};

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({ location: { state } }) => {
  const {
    web_btn_next,
    web_btn_sign_in,
    web_desc_password_rules_all,
    web_bloklu_uye_desc,
    web_sifre_bekleyen_uye_desc,
    web_eskiuye_desc,
    web_password_rules_notall,
  } = useLocalization();

  const dispatch = useDispatch();

  const { forgotPasswordFormStep } = useSelector(userStore);

  const history = useHistory();

  const phoneNumber = get(state, 'phoneNumber');
  const countryCode = get(state, 'countryCode');
  const userType = get(state, 'userType');
  const descriptionKey = get(state, 'descriptionKey');

  const identityStepDescriptionPicker = () => {
    switch (descriptionKey) {
      case 'web_bloklu_uye_desc':
        return web_bloklu_uye_desc;
      case 'web_sifre_bekleyen_uye_desc':
        return web_sifre_bekleyen_uye_desc;
      case 'web_eskiuye_desc':
        return web_eskiuye_desc;
      default:
        return '';
    }
  };

  const renderStep = (formikProps: FormikProps<ForgotPasswordSchemaInputs>) => {
    switch (forgotPasswordFormStep) {
      case 0:
        return (
          <ForgotPasswordIdentityStep
            formikProps={formikProps}
            userType={userType}
            description={identityStepDescriptionPicker()}
          />
        );
      case 1:
        return <PasswordForm formikProps={formikProps} userType={userType} />;
      case 2:
        return <PasswordFormSuccess />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (forgotPasswordFormStep !== 0) {
      dispatch(resetForgotPasswordFormStep());
    }
    if (!phoneNumber || !countryCode) {
      history.push(routePath.welcome);
    }
  }, []);

  const handleSubmit = (values: ForgotPasswordSchemaInputs) => {
    scrollTop();
    if (forgotPasswordFormStep === 0) {
      dispatch(
        onSendTokenChangePasswordRequest(
          sendTokenChangePasswordNormalize(values.tcNo, values.birthDate, phoneNumber, countryCode)
        )
      );
    }
    if (forgotPasswordFormStep === 1) {
      dispatch(onSetPasswordRequest(onSetPasswordNormalize(values, phoneNumber, countryCode)));
    }
    if (forgotPasswordFormStep === 2) {
      dispatch(push(routePath.welcome));
    }
  };

  return (
    <div id="forgotPassword">
      <FullPageWrapper>
        <StepWrapper>
          <FormErrorWrapper formName={formNames.forgotPassword} step={forgotPasswordFormStep} resetFormOnRouteChange>
            <Fragment>
              <Formik
                initialValues={forgotPasswordSchemaInitialValues}
                validationSchema={ForgotPasswordSchema[forgotPasswordFormStep]}
                onSubmit={handleSubmit}
                {...formikGlobalConfig}
              >
                {(formikProps) => {
                  const { handleSubmit } = formikProps;

                  return (
                    <FormWrapper onSubmit={handleSubmit}>
                      {renderStep(formikProps)}
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        itemID="forgotPasswordBtnTest"
                      >
                        {forgotPasswordFormStep !== 2 ? web_btn_next : web_btn_sign_in}
                      </Button>
                    </FormWrapper>
                  );
                }}
              </Formik>
              {forgotPasswordFormStep === 1 && (
                <PasswordFormInfo
                  rules={
                    userType && userType !== userTypes.blockedFromWrongAccess
                      ? web_password_rules_notall
                      : web_desc_password_rules_all
                  }
                />
              )}
            </Fragment>
          </FormErrorWrapper>
        </StepWrapper>
      </FullPageWrapper>
    </div>
  );
};

export default ForgotPassword;
