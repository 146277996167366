import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import firebase from 'firebase/app';
import { Loading } from 'Components';
import 'firebase/analytics';
// import { firebaseConfig } from './firebase.config';
import './i18n';
import { Provider } from 'react-redux';
import reduxStore from 'Stores';
import ReactGA from 'react-ga4';

// firebase.initializeApp(firebaseConfig);
const TRACKING_ID = 'G-R5HJZMWTJ7'; // ANALYTICS TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <Provider store={reduxStore}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
