import { FC, Fragment, useEffect } from 'react';
import { DashboardLayout, DialogWithActions, Modal3DSecure } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicationStatusWarningModalClose,
  closeBankSecureModal,
  creditWarningMessageModalClose,
  MicroCreditState,
  microCreditStore,
} from 'Stores/MicroCredit';
import { UserState, userStore } from 'Stores/User';
import { MicroCreditItem } from '../MicroCreditItem';

import './MicroCreditOperation.scss';
import { useState } from 'react';
import { onMicroCreditBankProcessRequest } from 'Stores/MicroCredit';

type CardApplicationProps = {};

const MicroCreditOperation: FC<CardApplicationProps> = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const microCreditData: MicroCreditState = useSelector(microCreditStore);

  const {
    bankSecureModal,
    modalDirectUrl,
    applicationStatusWarningModal,
    applicationStatus65Modal,
    creditWarning874Modal,
    creditWarnig861Modal,
    creditWarningPModal,
  } = microCreditData;

  const {
    web_transaction_list_micro_credit,
    web_micro_credit_header,
    web_btn_okay,
    web_micro_credit_desription,
    web_micro_credit_campaign_header,
    web_micro_credit_pending_msg,
    web_micro_credit_fiba_redirect_msg,
    web_micro_credit_fiba_redirect_eft_msg,
    web_micro_credit_responsecode_currentbalance_msg,
    web_micro_credit_responsecode_monthlybalance_msg,
    web_micro_credit_transaction_approved,
  } = useLocalization();

  const [id, setId] = useState('');

  const bankInfo = [
    {
      id: 1,
      name: 'first',
    },
  ];

  const LoginButton = () => {
    dispatch(onMicroCreditBankProcessRequest());
  };

  return (
    <Fragment>
      <div id="microCredit">
        <Typography variant="h3" align="center">
          <Box fontWeight={600}>{web_transaction_list_micro_credit}</Box>
        </Typography>
        <Typography variant="h3">
          <Box marginTop={gutters.big} fontWeight="bold" fontSize={fontSizes.regular}>
            {web_micro_credit_header}
          </Box>
        </Typography>
        <Typography variant="h3">
          <Box mb={gutters.big} color={colors.checkboxLabel} fontSize={fontSizes.small} mt={gutters.small}>
            {web_micro_credit_desription}
          </Box>
        </Typography>
        <Typography variant="h3">
          <Box marginBottom={gutters.big} fontWeight="bold" fontSize={fontSizes.regular}>
            {web_micro_credit_campaign_header}
          </Box>
        </Typography>
        {bankInfo.map((item) => (
          <MicroCreditItem key={item.id} item={item} id={id} setId={setId} />
        ))}

        {/* <Box marginTop={gutters.big} fontWeight="bold" fontSize={fontSizes.regular}>
            Diğer Krediler
          </Box> */}

        {/* {bankInfoOther.map((item) => (
            <MicroCreditItem key={item.id} item={item} id={id} setId={setId} />
          ))} */}

        <Box marginTop={gutters.bigger} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => LoginButton()}
            variant="contained"
            disabled={id.length == 0}
            fullWidth
            type="submit"
            itemID="changeLanguageBtnTest"
            style={{
              width: '328px',
              height: '52px',
              backgroundColor: id.length == 0 ? '#d7d8dc' : '#383c52',
              color: 'white',
            }}
          >
            Devam et
          </Button>
        </Box>
      </div>

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(creditWarningMessageModalClose('874')),
        }}
        open={creditWarning874Modal}
        hide={() => dispatch(creditWarningMessageModalClose('874'))}
        title={'Uyarı'}
        text={web_micro_credit_responsecode_currentbalance_msg}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(creditWarningMessageModalClose('861')),
        }}
        open={creditWarnig861Modal}
        hide={() => dispatch(creditWarningMessageModalClose('861'))}
        title={'Uyarı'}
        text={web_micro_credit_responsecode_monthlybalance_msg}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(creditWarningMessageModalClose('P')),
        }}
        open={creditWarningPModal}
        hide={() => dispatch(applicationStatusWarningModalClose('P'))}
        title={'Uyarı'}
        text={web_micro_credit_transaction_approved}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(applicationStatusWarningModalClose('62')),
        }}
        open={applicationStatusWarningModal}
        hide={() => dispatch(applicationStatusWarningModalClose('62'))}
        title={'Uyarı'}
        text={web_micro_credit_fiba_redirect_msg}
      />

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(applicationStatusWarningModalClose('65')),
        }}
        open={applicationStatus65Modal}
        hide={() => dispatch(applicationStatusWarningModalClose('65'))}
        title={'Uyarı'}
        text={web_micro_credit_fiba_redirect_eft_msg}
      />

      {bankSecureModal && (
        <Modal3DSecure
          isFibabank
          open={bankSecureModal}
          link={modalDirectUrl}
          close={() => dispatch(closeBankSecureModal())}
        />
      )}
    </Fragment>
  );
};
export default MicroCreditOperation;
