import { FC, Fragment, useEffect, useState } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Typography } from '@material-ui/core';
import { LoadBalance } from './LoadBalance';
import { AddCardSuccessLoadBalance } from './AddCardSuccessLoadBalance';
import { useDispatch, useSelector } from 'react-redux';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { MasterPassAccountStatusEnum, MasterPassTokenEnum } from 'Utils';
import { UserState, userStore } from 'Stores/User';
import {
  CreditCardTransactionsState,
  creditCardTransactionsStore,
  onGetMasterpassTokenRequest,
} from 'Stores/CreditCardTransactions';
import { masterPassListCardNormalize } from 'Normalize';
import { MasterpassConnect } from 'Views/MasterpassConnect';

import './AddBalanceToCard.scss';

const AddBalanceToCard: FC = () => {
  const {
    web_title_upload_tl,
    web_title_success_upload_tl,
    card_colour_digital_card_activation_control,
    web_success_tl_uploaded,
  } = useLocalization();

  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const isOtherCardsLoading = cardData?.otherCardStateLoading;
  const step: number = cardData?.uploadTLStep;

  const userData: UserState = useSelector(userStore);
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);
  const accountStatus = creditCardData?.masterPassAccountStatus;

  const loadBalanceWithoutMasterpass = cardData?.loadBalanceWithoutMasterpass;

  const isDigitalAccount = cardData.isDigitalActive;

  const renderTitle = () => {
    switch (step) {
      case 0:
        return web_title_upload_tl;
      case 1:
        if (isDigitalAccount) {
          return `${creditCardData.loadAmount} TL ${web_success_tl_uploaded}`;
        } else {
          return web_title_success_upload_tl;
        }
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CARD_LIST,
        request: {
          data: masterPassListCardNormalize(),
        },
      })
    );
  }, [accountStatus]);

  const userMustMasterpassConnection =
    accountStatus === MasterPassAccountStatusEnum.NO_LINKED && !loadBalanceWithoutMasterpass;

  return (
    <DashboardLayout backButton={step === 0}>
      <div id="addBalanceToCard">
        {userMustMasterpassConnection ? (
          <MasterpassConnect />
        ) : (
          <>
            <Typography variant="h3" className="addBalanceToCard__title">
              {renderTitle()}
            </Typography>
            <Fragment>
              {step === 0 && !isOtherCardsLoading && <LoadBalance />}
              {step === 1 && <AddCardSuccessLoadBalance />}
            </Fragment>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AddBalanceToCard;
