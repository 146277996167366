import * as React from 'react';

function OtherPointIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="37"
      viewBox="0 0 31 37"
      {...props}
    >
      <defs>
        <path
          id="3wojvukyla"
          d="M2 0c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm7 0c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm7 0c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#179B92"
                fillRule="nonzero"
                d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691c6.072-6.008 6.072-15.795.033-21.803z"
                transform="translate(-809.000000, -471.000000) translate(254.000000, 286.000000) translate(555.000000, 185.000000)"
              />
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-809.000000, -471.000000) translate(254.000000, 286.000000) translate(555.000000, 185.000000) translate(3.500000, 4.000000)"
                />
                <g transform="translate(-809.000000, -471.000000) translate(254.000000, 286.000000) translate(555.000000, 185.000000) translate(3.500000, 4.000000) translate(3.000000, 10.000000)">
                  <mask id="5d7cxbsagb" fill="#fff">
                    <use xlinkHref="#3wojvukyla" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#3wojvukyla" />
                  <g fill="#62D4D4" mask="url(#5d7cxbsagb)">
                    <path d="M0 0H24V24H0z" transform="translate(-4.000000, -11.000000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OtherPointIcon;
