import { FC, memo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { BlueTickIcon, AlertBlueIcon, ChevronBlueRightIcon } from 'Components';
import { gutters, fontSizes, colors } from 'Theme/Variables';
import { WarnAndTipsItemProps } from '../WarnAndTips';

import './WarnAndTipsItem.scss';

const WarnAndTipsItem: FC<WarnAndTipsItemProps> = memo(({ title, onClick, description, isSuccess, itemID }) => (
  <Box>
    <div className="warn-and-tips-item">
      <Box onClick={() => onClick()} width="100%" itemID={itemID}>
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <Box alignItems="center" justifyContent="space-between" display="flex">
            {isSuccess ? <BlueTickIcon /> : <AlertBlueIcon />}
            <Box marginLeft={gutters.small}>
              <Box
                className="warn-and-tips-item--title"
                fontSize={fontSizes.regularBig}
                color={colors.secondary}
                fontWeight="bold"
                style={{ marginBottom: '6px' }}
              >
                {title}
              </Box>
              <Typography className="warn-and-tips-item--desc">{description}</Typography>
            </Box>
          </Box>
          <ChevronBlueRightIcon />
        </Box>
      </Box>
    </div>
  </Box>
));

export default WarnAndTipsItem;
