import { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

type PublicRouteProps = {
  component: any;
} & RouteProps;

const PublicRoute: FunctionComponent<PublicRouteProps> = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
