import { Box, createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import { GrayInfoIcon, QuestionMarkIcon } from 'Components';
import { colors } from 'Theme/Variables';
import { useEffect } from 'react';

type TooltipProps = {
  title?: string;
  desc1?: string;
  desc2?: string;
  ml?: string;
  iconType?: string;
  id: string;
  calculate?: boolean;
};

const Tooltip: FC<TooltipProps> = ({ title, desc1, desc2, id, iconType, ml, calculate }) => {
  const { innerHeight, innerWidth } = window;

  const tinyDevice = innerWidth <= 639;
  const regularDevice = innerWidth <= 959 && innerWidth > 639;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      popover: {
        pointerEvents: 'none',
      },
      paper: {
        width: 250,
        overflowX: 'unset',
        overflowY: 'unset',
        backgroundColor: 'rgba(56,60,82,0.9)', //colors.secondary,
        padding: 16,
        marginLeft: !calculate ? -8 : regularDevice ? -97 : tinyDevice ? 2 : -8,
        marginTop: -7,

        '&::before': {
          content: '""',
          position: 'absolute',
          marginRight: !calculate ? 123 : tinyDevice || regularDevice ? 5 : 123,
          bottom: 0,
          right: 0,
          width: 10,
          height: 10,
          backgroundColor: 'rgba(56,60,82,0.9)',
          boxShadow: theme.shadows[1],
          transform: 'translate(-50%, 50%) rotate(135deg)',
          clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
        },
      },
    })
  );

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let open = Boolean(anchorEl);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" id="tooltipComp">
      <Box
        display="flex"
        zIndex={1}
        marginLeft={ml ? ml : 0}
        alignItems="center"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
      >
        {iconType === '?' ? <QuestionMarkIcon /> : <GrayInfoIcon />}
      </Box>
      <Popover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus={true}
      >
        <Box>
          <Typography>
            <Box color="#fff" fontWeight="bold" className="tooltipComp__title">
              {title}
            </Box>
          </Typography>
          <Typography className="tooltipComp__desc1">
            <Box color="#fff">{desc1}</Box>
          </Typography>

          <Typography className="tooltipComp__desc2">
            <Box color="#fff">{desc2}</Box>
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default Tooltip;
