import { Dispatch, FC, SetStateAction } from 'react';
import { DarkPlusIcon, BlueCheckIcon, DialogCloseComponent } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { AdressItemType } from 'Models/OnGetCustomerInfoModel';
import { colors, fontSizes, gutters } from 'Theme/Variables';

import './AdressListDialog.scss';

type AdressListDialogProps = {
  open: boolean;
  close: Dispatch<SetStateAction<boolean>>;
  adressList: Array<AdressItemType>;
  currentAdress: AdressItemType;
  setCurrentAdress: (e: AdressItemType) => void;
  setIsAddNewAdress: Dispatch<SetStateAction<boolean>>;
};
const AdressListDialog: FC<AdressListDialogProps> = ({
  setIsAddNewAdress,
  setCurrentAdress,
  currentAdress,
  open,
  close,
  adressList,
}) => {
  const { web_my_adress, web_delivery_address, web_enter_new_address } = useLocalization();

  return (
    <div id="adressListDialog">
      <Dialog scroll="paper" keepMounted={false} open={open} onClose={() => close((prev) => !prev)}>
        <DialogCloseComponent closeFunction={() => close((prev) => !prev)} />
        <div className="dialogContainer">
          <Box textAlign={'center'}>
            <Typography variant="h5">
              <Box fontWeight={600} marginBottom={gutters.regular}>
                {web_my_adress}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="dialogWithActions-textContainer" component="div">
              <Box fontSize={fontSizes.regular} marginBottom={gutters.regular} textAlign={'center'}>
                {web_delivery_address}
              </Box>
            </Typography>
          </Box>
          {adressList.map(
            (item) =>
              Number(item.AddressType) !== 7 && (
                <Box
                  key={item.CityCode.toString()}
                  onClick={() => {
                    close(false);
                    setCurrentAdress(item);
                  }}
                  borderColor={item === currentAdress ? colors.secondary : colors.separator}
                  className="adressListDialog__item-container"
                  itemID="chooseAdressCardApplicationBtnTest"
                >
                  <Box>
                    <Box>
                      <Typography variant="h3">
                        <Box fontWeight={600} fontSize={fontSizes.regular}>
                          {item.Town}
                        </Box>
                      </Typography>
                      <Typography variant="h3">
                        <Box
                          mt={1}
                          color={colors.checkboxLabel}
                          fontSize={fontSizes.small}
                          style={{ maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all' }}
                          className="adressListDialog__adressDetail"
                        >
                          {item.AddressDetail}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                  {item === currentAdress && <BlueCheckIcon fill={colors.secondary} />}
                </Box>
              )
          )}
          <Button
            fullWidth
            className="adressListDialog__btn"
            startIcon={<DarkPlusIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => setIsAddNewAdress(true)}
            itemID="addNewAdressCardApplicationBtnTest"
          >
            {web_enter_new_address}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default AdressListDialog;
