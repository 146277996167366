import * as React from 'react';

function ErrorCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="144"
      height="144"
      viewBox="0 0 144 144"
      {...props}
      className="errorCircleIcon"
    >
      <defs>
        <path
          id="sojsmq6sya"
          d="M60 0C26.863 0 0 26.863 0 60s26.863 60 60 60 60-26.863 60-60c0-15.913-6.321-31.174-17.574-42.426C91.174 6.32 75.913 0 60 0zm16.26 67.74c1.136 1.127 1.775 2.66 1.775 4.26 0 1.6-.64 3.133-1.775 4.26-1.127 1.136-2.66 1.775-4.26 1.775-1.6 0-3.133-.64-4.26-1.775L60 68.46l-7.74 7.8c-1.127 1.136-2.66 1.775-4.26 1.775-1.6 0-3.133-.64-4.26-1.775-1.136-1.127-1.775-2.66-1.775-4.26 0-1.6.64-3.133 1.775-4.26l7.8-7.74-7.8-7.74c-2.353-2.353-2.353-6.167 0-8.52 2.353-2.353 6.167-2.353 8.52 0l7.74 7.8 7.74-7.8c2.353-2.353 6.167-2.353 8.52 0 2.353 2.353 2.353 6.167 0 8.52L68.46 60l7.8 7.74z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H144V144H0z" transform="translate(-88.000000, -86.000000) translate(88.000000, 86.000000)" />
            <g transform="translate(-88.000000, -86.000000) translate(88.000000, 86.000000) translate(12.000000, 12.000000)">
              <mask id="enjjyahpnb" fill="#fff">
                <use xlinkHref="#sojsmq6sya" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#sojsmq6sya" />
              <g fill="#FF5959" mask="url(#enjjyahpnb)">
                <path d="M0 0H144V144H0z" transform="translate(-12.000000, -12.000000)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ErrorCircleIcon;
