import * as React from 'react';

function TwitterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="37px"
      height="37px"
      style={{ borderRadius: 100 }}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <rect fill="#000000" x="0" y="0" width="48" height="48" rx="14"></rect>
          <g transform="translate(8, 9)" fill="#FFFFFF">
            <path d="M0,0.271324 L9.46425839,0.271324 L18.2123727,12.1121 L29.1015155,0 L31.6887453,0.0452206 L19.5022112,13.8155 L32,30.7287 L22.539528,30.7287 L14.2826335,19.6944 L4.19592547,31 L1.64986832,31 L13.0302609,18.0814 L0,0.271324 Z M8.57183602,2.01993 L3.57346584,2.01993 L23.5706832,28.9348 L28.4790062,28.9348 L8.57183602,2.01993 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TwitterIcon;
