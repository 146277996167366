import { FormError } from 'Components/FormError';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { resetFormError, storeFormError } from 'Stores/Form';

type FormErrorWrapperProps = {
  children: JSX.Element | JSX.Element[];
  formName: string;
  step?: number;
  hideError?: boolean;
  resetFormOnRouteChange?: boolean;
};

const FormErrorWrapper: FunctionComponent<FormErrorWrapperProps> = ({
  formName,
  children,
  step,
  hideError,
  resetFormOnRouteChange,
  ...props
}) => {
  const dispatch = useDispatch();
  const { errorCode, formName: formNameStore } = useSelector(storeFormError);
  const { pathname } = useLocation();

  useEffect(() => {
    if (resetFormOnRouteChange && !!errorCode) {
      dispatch(resetFormError());
    }
  }, [pathname]);

  useEffect(() => {
    if ((step || step === 0) && !!errorCode) {
      dispatch(resetFormError());
    }
  }, [step]);
  // 505-506 oturum kodu hatalı
  return (
    <div {...props}>
      {errorCode &&
        errorCode !== '505' &&
        errorCode !== '506' &&
        errorCode !== '507' &&
        formName === formNameStore &&
        !hideError && <FormError cmsCode={errorCode} />}
      {children}
    </div>
  );
};

export default FormErrorWrapper;
