import * as React from 'react';

function CreditCardIconLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="85"
      height="85"
      viewBox="0 0 85 85"
      {...props}
    >
      <defs>
        <path
          id="a3l6d9sxka"
          d="M60.208 0H10.625C4.757 0 0 4.757 0 10.625v28.333c0 5.868 4.757 10.625 10.625 10.625h49.583c5.868 0 10.625-4.757 10.625-10.625V10.625C70.833 4.757 66.076 0 60.208 0zM31.875 35.417H17.708c-1.956 0-3.541-1.586-3.541-3.542 0-1.956 1.585-3.542 3.541-3.542h14.167c1.956 0 3.542 1.586 3.542 3.542 0 1.956-1.586 3.542-3.542 3.542zm21.25 0h-7.083c-1.956 0-3.542-1.586-3.542-3.542 0-1.956 1.586-3.542 3.542-3.542h7.083c1.956 0 3.542 1.586 3.542 3.542 0 1.956-1.586 3.542-3.542 3.542zm10.625-21.25H7.083v-3.542c0-1.956 1.586-3.542 3.542-3.542h49.583c1.956 0 3.542 1.586 3.542 3.542v3.542z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H85V85H0z" transform="translate(-678 -200) translate(678 200)" />
            <g transform="translate(-678 -200) translate(678 200) translate(7.083 17.708)">
              <mask id="a2sdz3jgmb" fill="#fff">
                <use xlinkHref="#a3l6d9sxka" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#a3l6d9sxka" />
              <g fill="#D7D8DC" mask="url(#a2sdz3jgmb)">
                <path d="M0 0H85V85H0z" transform="translate(-7.083 -17.708)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CreditCardIconLarge;
