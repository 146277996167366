import * as yup from 'yup';
import { errorMessages } from 'Config';

export const PasswordSchema = yup.object({
  password: yup.string().required(errorMessages.require).min(6, errorMessages.minPassword),
  rePassword: yup
    .string()
    .required(errorMessages.require)
    .min(6, errorMessages.minPassword)
    .when('password', {
      is: (password: string) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], errorMessages.passwordMatch),
    }),
});
