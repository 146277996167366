import { Box, BoxProps } from '@material-ui/core';
import { FC, Fragment, useEffect } from 'react';

import './CopyTooltip.scss';

type CopyTooltipProps = BoxProps & {
  title: string;
  onClose: () => void;
};

const CopyTooltip: FC<CopyTooltipProps> = ({ title, onClose, ...boxProps }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 3000);
  }, [onClose]);
  return (
    <Box id="copyTooltip" {...boxProps}>
      {title}
    </Box>
  );
};

export default CopyTooltip;
