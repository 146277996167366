import {
  DashboardLayout,
  DialogTerms,
  InfoIcon,
  Checkbox,
  DialogWithActions,
  FormErrorWrapper,
  FormWrapper,
} from 'Components';
import { useErrorMessage, useLocalization, useModal } from 'Hooks';
import { Box, Typography, Link, Button, TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';
import { agreementsTypes, generateLanguageParam, paranthesisNormalize } from 'Utils';
import { onChangeCustomerInfoRequest, onUpdateCommercialStatusRequest, UserState, userStore } from 'Stores/User';
import { formikGlobalConfig, formNames } from 'Config';
import { onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { Formik } from 'formik';
import { UpdateCommercialStatusSchema, welcomeSchemaInitialValues, WelcomeSchemaInputs } from 'Validations';
import { userInfoTypes } from 'Views/UserInfo/constant';
import { MailInfoDialog } from 'Views/UserInfo/UserMailAdress/MailInfoDialog';

import './ContactPermission.scss';
import { showToastify } from 'Stores/Toastify';
import { useHistory } from 'react-router';

type ContactPermissionProps = {};

const ContactPermission: FC<ContactPermissionProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [values, setValues] = useState<any>();

  const userData: UserState = useSelector(userStore);

  const sessionToken = userData?.sessionToken;
  const customerNumber = userData?.customerNumber;
  const userMail = userData?.email;

  const permission = userData?.commercialStatusChannelType;

  const {
    web_account_contact_info_header,
    web_account_contact_info_permission_text,
    web_btn_kaydet,
    web_account_contact_info_sms,
    web_account_contact_info_email,
    web_account_contact_info_update_text,
    web_communication_cancel_email_desc,
    web_communication_cancel_email_title,
    web_communication_cancel_sms_desc,
    web_communication_cancel_sms_title,
    web_email_obligation_desc,
    web_email_obligation,
    web_account_contact_info_permission_text_mail,
    web_email_activation_popup_header,
    web_email_activation_popup_desc,
    web_email_activation_popup_approve,
    web_email_activation_popup_cancel,
  } = useLocalization();

  const { isVisible: isVisibleEmail, show: showEmail, hide: hideEmail } = useModal();
  const { isVisible: isVisibleSms, show: showSms, hide: hideSms } = useModal();
  const { isVisible: isVisiblePermission, show: showPermisson, hide: hidePermission } = useModal();

  const [defaultSms, setDefaultSms] = useState(permission === 1 || permission === 3 ? true : false);
  const [defaultMail, setDefaultMail] = useState(permission === 2 || permission === 3 ? true : false);

  const [viaSms, setViaSms] = useState<boolean | undefined>(permission === 1 || permission === 3 ? true : false);
  const [viaMail, setViaMail] = useState<boolean | undefined>(permission === 2 || permission === 3 ? true : false);

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  const [title, setTitle] = useState<string>('');
  const [desc, setDesc] = useState<string>('');

  const handleSubmit = (valuesForm: WelcomeSchemaInputs) => {
    if (defaultSms === viaSms && defaultMail === viaMail) {
      dispatch(showToastify({ toastMessage: web_account_contact_info_update_text, type: 'success' }));
      history.goBack();
      return;
    }
    setValues(valuesForm);
    if (defaultMail === viaMail) {
      send();
    } else {
      setIsOpenDialog(true);
    }
  };

  const send = () => {
    setIsOpenDialog(false);
    if (!userMail && viaMail) {
      dispatch(
        onChangeCustomerInfoRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          Email: values.mail,
          TypeOfField: userInfoTypes.mail,
        })
      );
    }
    dispatch(
      onUpdateCommercialStatusRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CommercialStatusChannelType: viaSms && !viaMail ? 1 : !viaSms && viaMail ? 2 : 3,
        Language: generateLanguageParam(),
      })
    );
  };

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  const { getError, getErrorMessage } = useErrorMessage();

  return (
    <DashboardLayout backButton>
      <div id="contactPermission">
        <FormErrorWrapper formName={formNames.contactPermission} resetFormOnRouteChange>
          <Typography variant="h3" align="center">
            <Box fontWeight={600} className="contactPermission__title">
              {web_account_contact_info_header}
            </Box>
          </Typography>
          <Box
            className="contactPermission__checkBorder"
            marginBottom={gutters.small}
            onClick={() => {
              if (permission === 1 || permission === 3) {
                setTitle(web_communication_cancel_sms_title);
                setDesc(web_communication_cancel_sms_desc);
                showPermisson();
              } else {
                setViaSms(!viaSms);
              }
            }}
            itemID="smsPermissionBtnTest"
          >
            <Checkbox
              name="viaSms"
              color="primary"
              checked={viaSms}
              label={
                <Typography variant="h6" align="center">
                  <Box fontWeight={600}>{web_account_contact_info_sms}</Box>
                </Typography>
              }
              marginBottomGutter="0"
            />
          </Box>
          <Box
            className="contactPermission__checkBorder"
            marginBottom={gutters.big}
            onClick={() => {
              if (permission === 2 || permission === 3) {
                setTitle(web_communication_cancel_email_title);
                setDesc(web_communication_cancel_email_desc);
                showPermisson();
              } else {
                setViaMail(!viaMail);
              }
            }}
            itemID="mailPermissionBtnTest"
          >
            <Checkbox
              name="viaMail"
              color="primary"
              checked={viaMail}
              label={
                <Typography variant="h6" align="center">
                  <Box fontWeight={600}>{web_account_contact_info_email}</Box>
                </Typography>
              }
              marginBottomGutter="0"
            />
          </Box>
          <Formik
            initialValues={welcomeSchemaInitialValues}
            validationSchema={UpdateCommercialStatusSchema(viaMail, userMail)}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                values: { mail },
                errors: { mail: errorMail },
                touched: { mail: touchedMail },
                setFieldValue,
              } = formikProps;

              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  {!userMail && viaMail && (
                    <Box>
                      <TextField
                        name="mail"
                        label={web_email_obligation}
                        variant="filled"
                        fullWidth
                        value={mail}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFieldValue('mail', mail?.trim());
                        }}
                        error={getError(errorMail, touchedMail)}
                        helperText={getErrorMessage(errorMail, touchedMail)}
                        inputProps={{ maxLength: 50 }}
                      />
                      <Box marginBottom={gutters.regular} marginTop={gutters.small}>
                        <Typography variant="body1" gutterBottom>
                          {web_email_obligation_desc}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className="contactPermission__button"
                    type="submit"
                    itemID="updatePermissionBtnTest"
                  >
                    {web_btn_kaydet}
                  </Button>
                  <MailInfoDialog mail={mail} />
                </FormWrapper>
              );
            }}
          </Formik>
          <Box className="flexAlignCenter" marginBottom={gutters.small}>
            <InfoIcon className="iconRightPadding" />
            <Link
              variant="body1"
              onClick={() => {
                handleAgreementSelect(agreementsTypes.TICARI_ELEKTRONIK_ILETISIM_IZNI);
                showEmail();
              }}
              underline="always"
              color="textSecondary"
              itemID="showMailPermissionBtnTest"
            >
              {paranthesisNormalize(web_account_contact_info_permission_text_mail)}
            </Link>
          </Box>
          <Box className="flexAlignCenter">
            <InfoIcon className="iconRightPadding" />
            <Link
              variant="body1"
              onClick={() => {
                showSms();
              }}
              underline="always"
              color="textSecondary"
              itemID="showSmsPermissionBtnTest"
            >
              {paranthesisNormalize(web_account_contact_info_permission_text)}
            </Link>
          </Box>

          <DialogTerms
            open={isVisibleEmail}
            hide={hideEmail}
            headerTextAlign="left"
            isButtonOkay
            title={web_account_contact_info_permission_text_mail}
            content={agreementContent?.AgreementContent.split('61OF61')[1]}
          />
          <DialogTerms
            open={isVisibleSms}
            hide={hideSms}
            headerTextAlign="left"
            isButtonOkay
            title={web_account_contact_info_permission_text}
            content={agreementContent?.AgreementContent.split('61OF61')[0]}
          />
          <DialogWithActions
            open={isVisiblePermission}
            hide={hidePermission}
            id="dialogTerms"
            keepMounted={false}
            title={title}
            text={desc}
            closeIcon
            alignText="center"
          />
        </FormErrorWrapper>
        <DialogWithActions
          primaryButton={{ label: web_email_activation_popup_approve, handleClick: send }}
          secondaryButton={{ label: web_email_activation_popup_cancel, handleClick: () => setIsOpenDialog(false) }}
          hide={() => setIsOpenDialog(false)}
          open={isOpenDialog}
          text={web_email_activation_popup_desc}
          title={web_email_activation_popup_header}
        />
      </div>
    </DashboardLayout>
  );
};

export default ContactPermission;
