import { axiosHelper } from 'Services/AxiosHelper';
import { OnConfirmLoginSmsModel } from 'Models';
import { onConfirmLoginSmsBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnConfirmLoginSms';

const onConfirmLoginSms = async (params: onConfirmLoginSmsBodyData): Promise<OnConfirmLoginSmsModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onConfirmLoginSms;
