import * as React from 'react';

function TravelPointIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="37"
      viewBox="0 0 31 37"
      {...props}
    >
      <defs>
        <path
          id="564zw3csua"
          d="M1.238 0C.552 0 0 .54 0 1.212V16.27c0 .671.552 1.205 1.238 1.205h2.4c.042-.022.093-.022.135 0h10.87c.042-.022.092-.022.134 0 .686 0 1.223-.534 1.223-1.205V1.212C16 .54 15.463 0 14.777 0H1.237zm4.816 1.197h3.639c.29 0 .522.226.522.511v.073c0 .285-.231.511-.522.511H6.054c-.29 0-.522-.226-.522-.511v-.073c0-.285.231-.51.522-.51zM2.222 3.942H13.78c.412 0 .73.319.73.722v5.89c0 .404-.318.73-.73.73H2.222c-.412 0-.746-.326-.746-.73v-5.89c0-.403.334-.722.746-.722zm.462 9.029c.79 0 1.432.627 1.432 1.409 0 .78-.643 1.416-1.432 1.416-.789 0-1.416-.635-1.416-1.416 0-.782.627-1.41 1.416-1.41zm10.647 0c.79 0 1.417.627 1.417 1.409 0 .78-.628 1.416-1.417 1.416S11.9 15.16 11.9 14.38c0-.782.642-1.41 1.431-1.41zM1.447 17.75v1.775c0 .264.212.474.477.474h1.163c.265 0 .477-.21.477-.474v-1.774H1.447zm11.005 0v1.775c0 .264.197.474.462.474h1.178c.265 0 .477-.21.477-.474v-1.774h-2.117z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#FF756A"
                fillRule="nonzero"
                d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691c6.072-6.008 6.072-15.795.033-21.803z"
                transform="translate(-254.000000, -462.000000) translate(254.000000, 286.000000) translate(0.000000, 176.000000)"
              />
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-254.000000, -462.000000) translate(254.000000, 286.000000) translate(0.000000, 176.000000) translate(3.500000, 4.000000)"
                />
                <g transform="translate(-254.000000, -462.000000) translate(254.000000, 286.000000) translate(0.000000, 176.000000) translate(3.500000, 4.000000) translate(4.000000, 2.000000)">
                  <mask id="an0rwjedob" fill="#fff">
                    <use xlinkHref="#564zw3csua" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#564zw3csua" />
                  <g fill="#C22026" mask="url(#an0rwjedob)">
                    <path d="M0 0H24V24H0z" transform="translate(-4.000000, -2.000000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TravelPointIcon;
