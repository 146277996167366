import * as React from 'react';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="closeIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.6129 5.2097L6.70711 5.29289L12 10.585L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0676 5.65338 19.0953 6.22061 18.7903 6.6129L18.7071 6.70711L13.415 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3466 19.0676 17.7794 19.0953 17.3871 18.7903L17.2929 18.7071L12 13.415L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.93241 18.3466 4.90468 17.7794 5.2097 17.3871L5.29289 17.2929L10.585 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.65338 4.93241 6.22061 4.90468 6.6129 5.2097Z"
        fill="#383C52"
      />
    </svg>
  );
}

export default CloseIcon;
