import axios from 'axios';
import { get } from 'lodash';

const getDistrictList = async (param: string) => {
  const url = `https://api.ibb.gov.tr/ibb-adres-api/adres/ilce/mahalle/${param}`;

  const response = await axios.get(url);

  return get(response, 'data');
};

export default getDistrictList;
