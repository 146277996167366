import './InputPhone.scss';
import React, { FunctionComponent, InputHTMLAttributes, PropsWithChildren } from 'react';
import { Box, InputBaseComponentProps } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useLocalization, useModal } from 'Hooks';
import { DialogCountryCode } from './DialogCountryCode';
import { ChevronDownIcon, MaskedTextField } from 'Components';
import { inputMaskTypes } from 'Config';

type InputPhoneProps = {
  setCountryCode: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  countryCode: string;
  countryCodeField: string;
  resetPhone: Function;
} & InputHTMLAttributes<HTMLInputElement> &
  PropsWithChildren<InputBaseComponentProps>;

const InputPhone: FunctionComponent<InputPhoneProps> = ({
  setCountryCode,
  countryCode,
  countryCodeField,
  resetPhone,
  ...props
}) => {
  const { web_plc_phone_number } = useLocalization();
  const { isVisible, show, hide } = useModal();

  const onChangeCountryCode = (value: React.SetStateAction<string>) => {
    resetPhone();
    setCountryCode(countryCodeField, value);
  };

  return (
    <Box marginBottom={gutters.regular} id="input-phone">
      <span className="input-text" onClick={show}>
        +{countryCode}
        <ChevronDownIcon />
      </span>
      <MaskedTextField
        mask={countryCode === '90' ? inputMaskTypes.trPhone : inputMaskTypes.intPhone}
        label={web_plc_phone_number}
        className={'overflowUnset ' + props.className}
        itemID="countryCodeTest"
        {...props}
      />
      <DialogCountryCode
        open={isVisible}
        hide={hide}
        id="dialogCountryCode"
        selectedCountryCode={countryCode}
        setCountryCode={(value) => onChangeCountryCode(value)}
      />
    </Box>
  );
};

export default InputPhone;
