import { errorMessages } from 'Config';
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

export const AddAdressSchema = yup.object({
  adressType: yup.string().nullable().required(errorMessages.require),
  city: yup.string().required(errorMessages.require),
  district: yup.string().required(errorMessages.require),
  town: yup.string().required(errorMessages.require),
  street: yup.string().required(errorMessages.require),
  adressDetail: yup.string().required(errorMessages.require),
  postCode: yup.string().required(errorMessages.minPostCode).min(5, errorMessages.minPostCode),
});

const ivalidTexts = ['null', 'yok'];

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    invalidTexts(errorMessage: string): StringSchema<TType, TContext>;
  }
}

yup.addMethod(yup.string, 'invalidTexts', function (errorMessage: string) {
  return this.test(`invalid-texts`, errorMessage, function (value?: string) {
    const { path, createError } = this;

    return (
      (!!value && !ivalidTexts.includes(value.toLocaleLowerCase()) && value?.length > 0) ||
      createError({ path, message: errorMessage })
    );
  });
});

export const AddAdressCardApplicationSchema = (isAdressTypeRequired: boolean) => {
  return yup.object({
    adressType: isAdressTypeRequired ? yup.string().required(errorMessages.require) : yup.string().nullable(),
    city: yup.string().invalidTexts(errorMessages.require).required(errorMessages.require),
    district: yup.string().invalidTexts(errorMessages.require).required(errorMessages.require),
    town: yup.string().invalidTexts(errorMessages.require).required(errorMessages.require),
    street: yup.string().invalidTexts(errorMessages.require).required(errorMessages.require),
    adressDetail: yup.string().invalidTexts(errorMessages.require).required(errorMessages.require),
    postCode: yup.string().required(errorMessages.minPostCode).min(5, errorMessages.minPostCode),
  });
};

export type AddAdressSchemaInputs = yup.InferType<typeof AddAdressSchema>;

export const AddAdressInitialValues = (
  adressType?: string,
  city?: string,
  town?: string,
  district?: string,
  street?: string,
  adressDetail?: string,
  postalCode?: string
) => {
  return {
    adressType: adressType,
    city: city?.toUpperCase() != 'YOK' ? city : null,
    town: town?.toUpperCase() != 'YOK' ? town : null,
    district: district?.toUpperCase() != 'YOK' ? district : null,
    street: street?.toUpperCase() != 'YOK' ? street : '',
    adressDetail: adressDetail?.toUpperCase() != 'YOK' ? adressDetail : '',
    postCode: postalCode?.toUpperCase() != 'YOK' ? postalCode : '',
  };
};
