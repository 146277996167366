import { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { MaskedTextField } from 'Components';
import { FormikProps } from 'formik';
import { SignupSchemaInputs } from 'Validations';
import { useErrorMessage, useLocalization } from 'Hooks';
import { inputMaskTypes } from 'Config';

type SignupIdentityStepProps = {
  formikProps: FormikProps<SignupSchemaInputs>;
};

const SignupIdentityStep: FunctionComponent<SignupIdentityStepProps> = ({
  formikProps: {
    values: { tcNo, birthDate },
    handleChange,
    errors: { tcNo: errorTcNo, birthDate: errorBirthDate },
    touched: { tcNo: touchedTcNo, birthDate: touchedBirthDate },
    handleBlur,
  },
}) => {
  const {
    web_kimlik_title,
    web_plc_tckn,
    web_plc_birthday,
    web_kimlik_desc1,
    web_kimlik_desc2,
    web_plc_dogum_tarihi_formati,
  } = useLocalization();
  const { getError, getErrorMessage } = useErrorMessage();

  return (
    <>
      <Typography variant="h5" align="center">
        <Box fontWeight={600} marginBottom={gutters.big} marginTop={gutters.big}>
          {web_kimlik_title}
        </Box>
      </Typography>

      <Box marginBottom={gutters.regular}>
        <MaskedTextField
          name="tcNo"
          value={tcNo}
          onChange={handleChange}
          onBlur={handleBlur}
          mask={inputMaskTypes.tcNo}
          label={web_plc_tckn}
          error={getError(errorTcNo, touchedTcNo)}
          helperText={getErrorMessage(errorTcNo, touchedTcNo)}
          itemID="signUpTcNoInputBtnTest"
        />
      </Box>
      <Box marginBottom={gutters.big}>
        <MaskedTextField
          name="birthDate"
          mask={inputMaskTypes.date}
          label={web_plc_birthday}
          value={birthDate}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={web_plc_dogum_tarihi_formati}
          error={getError(errorBirthDate, touchedBirthDate)}
          helperText={getErrorMessage(errorBirthDate, touchedBirthDate)}
          itemID="signUpBirthDateInputBtnTest"
        />
      </Box>
      <Typography variant="h6" align="center">
        <Box fontWeight={600} marginBottom={gutters.small}>
          {web_kimlik_desc1}
        </Box>
      </Typography>
      <Box marginBottom={gutters.big}>
        <Typography variant="body1" align="center">
          {web_kimlik_desc2}
        </Typography>
      </Box>
    </>
  );
};

export default SignupIdentityStep;
