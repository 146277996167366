import { Link, Grid, Box } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { FC, memo, useState } from 'react';
import { CardItem } from './CardItem';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountState,
  CampaignItem,
  setActiveCampaign,
  setActiveCategory,
  setActiveNews,
  storeAccount,
} from 'Stores/Account';
import classNames from 'classnames';
import Masonry from 'react-masonry-css';
import { NewsItem } from './NewsItem';
import { newsItem } from 'Models/GetNewsModel';

import './NewsAndCampaigns.scss';

type NewsAndCampaignsProps = {};

type TabItemProps = {
  title?: string;
  onClick: () => void;
  index?: number;
  activeIndex?: number;
  itemID: string;
  isShown?: boolean;
};

const NewsAndCampaigns: FC<NewsAndCampaignsProps> = memo(() => {
  const {
    web_tumu_homepage,
    web_kampanyalar_homepage,
    web_haberler_homepage,
    web_katildiklarim_homepage,
  } = useLocalization();

  const accountState: AccountState = useSelector(storeAccount);
  const userCampaigns = accountState?.campaigns;
  const userNews = accountState?.news;

  const activeSelectedCategory = accountState?.activeCategory;

  const dispatch = useDispatch();

  const history = useHistory();

  const tabs: TabItemProps[] = [
    {
      title: web_tumu_homepage,
      onClick: () => dispatch(setActiveCategory(0)),
      itemID: 'newsAndCampaignsAllBtnTest',
      isShown: true,
    },
    {
      title: web_kampanyalar_homepage,
      onClick: () => dispatch(setActiveCategory(1)),
      itemID: 'campaignsBtnTest',
      isShown: userCampaigns && userCampaigns?.length > 0,
    },
    /*  {
      title: web_katildiklarim_homepage,
      onClick: () => setActiveCategory(2),
    }, */
    {
      title: web_haberler_homepage,
      onClick: () => dispatch(setActiveCategory(2)),
      itemID: 'newsBtnTest',
      isShown: userNews && userNews?.length > 0,
    },
  ];
  const breakpointColumnsObj = {
    default: 4,
    639: 2,
  };

  return (
    <div>
      <div id="news-and-campaigns">
        <div className="tabs">
          {tabs.map(
            ({ title, onClick, itemID, isShown }, index) =>
              isShown && (
                <TabItem
                  title={title}
                  onClick={onClick}
                  key={index}
                  index={index}
                  activeIndex={activeSelectedCategory}
                  itemID={itemID}
                />
              )
          )}
        </div>

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {/* array of JSX items */}
          {userCampaigns &&
            (activeSelectedCategory === 0 || activeSelectedCategory === 1) &&
            userCampaigns.map((item: CampaignItem, index: number) => (
              <CardItem
                campaignItem={item}
                onClick={() => {
                  history.push(routePath.campaigns);
                  dispatch(setActiveCampaign(item));
                }}
                key={index}
                itemID="campaignItem"
              />
            ))}
          {userNews &&
            (activeSelectedCategory === 0 || activeSelectedCategory === 2) &&
            userNews.map((item: newsItem, index: number) => (
              <NewsItem
                newsItem={item}
                onClick={() => {
                  dispatch(setActiveNews(item));
                  history.push(routePath.news);
                }}
                key={index}
                itemID="newsItemBtnTest"
              />
            ))}
        </Masonry>
      </div>
    </div>
  );
});
export default NewsAndCampaigns;

const TabItem: FC<TabItemProps> = memo(({ title, onClick, index, activeIndex, itemID }) => (
  <Link
    underline="none"
    onClick={onClick}
    itemID={itemID}
    className={classNames({
      'tab-item': true,
      'tab-item--active': activeIndex === index,
    })}
  >
    {title}
  </Link>
));
