import * as React from 'react';

function ContractsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      {...props}
    >
      <defs>
        <path
          id="zad86os43a"
          d="M19.74 7.33l-4.44-5c-.19-.21-.458-.33-.74-.33h-8c-.671-.008-1.318.25-1.798.72-.48.469-.754 1.109-.762 1.78v15c.008.671.282 1.311.762 1.78.48.47 1.127.728 1.798.72h10.88c.671.008 1.318-.25 1.798-.72.48-.469.754-1.109.762-1.78V8c0-.248-.093-.487-.26-.67zM9 12h3c.552 0 1 .448 1 1s-.448 1-1 1H9c-.552 0-1-.448-1-1s.448-1 1-1zm6 6H9c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1zm-.29-10c-.429-.042-.745-.42-.71-.85V4l3.74 4h-3.03z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-751 -713) translate(735 690) translate(16 22.014) translate(0 1)">
                <path d="M0 0H24V24H0z" />
                <mask id="1kv5gdqrnb" fill="#fff">
                  <use xlinkHref="#zad86os43a" />
                </mask>
                <g fill="currentColor" mask="url(#1kv5gdqrnb)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ContractsIcon;
