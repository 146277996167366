import * as React from 'react';

function IstanbulDarkLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188"
      height="30"
      viewBox="0 0 188 30"
      className="istanbulDarkLogo"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#383C52" fillRule="nonzero">
          <g>
            <path
              d="M43.136.601V5.67h-2.53c-1.672 0-2.676.837-2.676 2.261v8.418h5.206v5.026H37.93v8.041h-5.728v-8.04h-4.6v-5.027h4.6V7.595c0-4.481 2.592-6.994 7.108-6.994h3.826zM106.33.1c5.686 0 9.45 4.355 9.45 10.889 0 6.533-3.764 10.888-9.45 10.888-3.01 0-5.478-1.507-6.147-3.685h-.125v11.224H94.33V.601h4.975l.753 3.225h.125C100.853 1.606 103.32.1 106.33.1zM152.49.6v5.403h-3.052c-1.254 0-2.007.712-2.007 1.926v21.485h-5.728V7.595c0-4.481 2.592-6.994 7.108-6.994h3.68zM5.97 24.065v5.35H.177v-5.35H5.97zM55.015.099c3.01 0 5.477 1.507 6.188 3.727h.126v-.042l.752-3.183h4.976v20.773h-4.976l-.752-3.183h-.126c-.71 2.178-3.178 3.685-6.188 3.685-5.687 0-9.45-4.355-9.45-10.889C45.566 4.454 49.329.1 55.016.1zm-37.533 0c5.27 0 8.823 2.764 8.823 6.868 0 3.057-2.09 5.11-6.02 5.864l-3.68.753c-1.422.251-2.05.88-2.05 1.8 0 1.216 1.088 2.011 2.802 2.011 1.84 0 3.22-1.005 3.512-2.47l4.81 1.465c-.963 3.267-4.224 5.486-8.197 5.486-5.184-.042-8.696-2.722-8.696-6.7 0-3.225 2.257-5.319 6.48-6.199l3.68-.712c1.17-.25 1.59-.753 1.59-1.591 0-1.257-1.255-2.094-3.179-2.094-2.383 0-4.098 1.13-4.223 2.848l-4.892-.754C8.828 2.737 12.55.099 17.483.099zM76.873.6v11.182c0 2.806 1.673 4.733 4.223 4.733 2.342 0 3.973-1.801 3.973-4.481V.6h5.728v13.402c0 4.733-2.969 7.873-7.485 7.873-3.094 0-5.603-1.507-6.313-3.769h-.126l-.753 3.267h-4.975V.601h5.728zM126.128.1c3.052 0 5.561 1.507 6.271 3.727h.168l.753-3.225h4.975v20.773h-5.728V10.192c0-2.848-1.673-4.733-4.265-4.733-2.384 0-3.972 1.801-3.972 4.482v11.433H118.6V7.972c0-4.732 3.01-7.873 7.527-7.873zM5.938.6v20.773H.21V.601h5.728zm50.416 4.733c-2.969 0-4.934 2.261-4.934 5.653 0 3.393 1.965 5.654 4.934 5.654 3.01 0 4.976-2.261 4.976-5.654 0-3.392-1.966-5.653-4.976-5.653zm48.638 0c-2.97 0-4.934 2.261-4.934 5.654 0 3.392 1.965 5.654 4.934 5.654 2.969 0 4.934-2.262 4.934-5.654 0-3.393-1.965-5.654-4.934-5.654z"
              transform="translate(-155 -35) translate(155 35) matrix(1 0 0 -1 0 29.514)"
            />
            <path
              d="M158.288 21.272c1.572 3.334 4.265 6.032 7.594 7.607h-7.594v-7.607zm28.734 0v7.607h-7.595c3.33-1.575 6.023-4.273 7.595-7.607zM172.655 1.764s12.388 7.879 12.388 15.099c0 7.22-7.455 9.663-12.388 4.639-5.619 4.941-12.388 2.553-12.388-4.64 0-7.192 12.388-15.098 12.388-15.098zM187.022.099v7.606c-1.572-3.334-4.266-6.032-7.595-7.606h7.595zm-21.14 0c-3.329 1.574-6.022 4.272-7.594 7.606V.099h7.594z"
              transform="translate(-155 -35) translate(155 35) matrix(1 0 0 -1 0 28.977)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IstanbulDarkLogo;
