import { FC, memo } from 'react';
import { MailIcon, CloseIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography, Dialog } from '@material-ui/core';
import { AccountState, showHideUserMailInfoDialog, storeAccount } from 'Stores/Account';
import { fontSizes, gutters } from 'Theme/Variables';
import { useDispatch, useSelector } from 'react-redux';

type MailInfoDialogProps = {
  mail?: string;
};
const MailInfoDialog: FC<MailInfoDialogProps> = memo(({ mail }) => {
  const { email_update_popup_success_title, email_update_popup_success_desc } = useLocalization();

  const accountState: AccountState = useSelector(storeAccount);
  const mailInfoDialogIsOpen: boolean = accountState.isMailInfoDialogOpen;

  const dispatch = useDispatch();

  function createMarkup() {
    return {
      __html: `${email_update_popup_success_desc.replace(
        '{0}',
        ` <span style="font-weight:bold;" >
                  ${mail}
                </span>`
      )}`,
    };
  }
  return (
    <Dialog open={mailInfoDialogIsOpen} onClose={() => dispatch(showHideUserMailInfoDialog(false))}>
      <div className="dialogContainer">
        <CloseIcon onClick={() => dispatch(showHideUserMailInfoDialog(false))} />
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box marginBottom={gutters.regular}>
            <MailIcon />
          </Box>
          <Typography variant="h3" align="center">
            <Box fontSize={fontSizes.big} fontWeight="bold" marginBottom={gutters.regular}>
              {email_update_popup_success_title}
            </Box>
          </Typography>
          <Typography variant="body1" align="center">
            <Box fontSize={fontSizes.regular} dangerouslySetInnerHTML={createMarkup()} />
          </Typography>
        </Box>
      </div>
    </Dialog>
  );
});
export default MailInfoDialog;
