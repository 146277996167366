import * as React from 'react';

function LanguageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className="languageIcon"
      {...props}
    >
      <defs>
        <path
          id="h7jg6ratxa"
          d="M19 3H5C3.343 3 2 4.343 2 6v15c0 .36.196.693.51.87.15.085.318.13.49.13.18 0 .356-.048.51-.14L8 19.14c.166-.099.357-.147.55-.14H19c1.657 0 3-1.343 3-3V6c0-1.657-1.343-3-3-3zM8 12c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm4 0c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm4 0c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-751 -633) translate(735 610) translate(16 22.014) translate(0 1)">
                <path d="M0 0H24V24H0z" />
                <mask id="w893a09sqb" fill="#fff">
                  <use xlinkHref="#h7jg6ratxa" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#h7jg6ratxa" />
                <g fill="currentColor" mask="url(#w893a09sqb)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LanguageIcon;
