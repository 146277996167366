import * as React from 'react';

function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="searchIcon"
      {...props}
    >
      <defs>
        <path
          id="0pzlnaj25a"
          d="M20.71 19.29l-3.4-3.39c1.097-1.398 1.692-3.123 1.69-4.9 0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.777.002 3.502-.593 4.9-1.69l3.39 3.4c.188.19.443.296.71.296.267 0 .522-.107.71-.296.19-.188.296-.443.296-.71 0-.267-.107-.522-.296-.71zM5 11c0-3.314 2.686-6 6-6s6 2.686 6 6-2.686 6-6 6-6-2.686-6-6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-561 -288) translate(119 207) translate(50 67) translate(392 14)">
                <path d="M0 0H24V24H0z" />
                <mask id="a7cmizz5ab" fill="#fff">
                  <use xlinkHref="#0pzlnaj25a" />
                </mask>
                <g fill="#C3C4CB" mask="url(#a7cmizz5ab)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
