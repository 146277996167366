import { Box } from '@material-ui/core';
import { DashboardLayout, DialogWithActions, FormErrorWrapper, StepWrapper } from 'Components';
import { formNames } from 'Config';
import { useLocalization } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { deleteCustomerCardByClosedNormalize } from 'Normalize';
import {
  onDeleteCustomerCardByClosedRequest,
  resetDeleteCardStep,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';
import { cardItemDetailType } from 'Views/CardDetails/constant';
import { FC, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, StaticContext, useHistory } from 'react-router';
import { DeleteError } from './DeleteError';
import { DeleteOptions } from './DeleteOptions';
import DeleteSuccess from './DeleteSuccess/DeleteSuccess';

import './CardDelete.scss';

type UserCardSettingsParams = {} & cardItemDetailType;

type UserCardSettingsProps = RouteComponentProps<{}, StaticContext, UserCardSettingsParams>;

const CardDelete: FC<UserCardSettingsProps> = ({ location: { state } }) => {
  const cardItem = state;

  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [primaryLabel, setPrimaryLabel] = useState<string>('');
  const [secondaryLabel, setSecondaryLabel] = useState<string>('');
  const [requestType, setRequestType] = useState<number>(0);

  const userData: UserState = useSelector(userStore);
  const { cardDeleteStep } = useSelector(storeCardTransactions);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    web_card_settings_delete_card_deformed_card_btn,
    web_card_settings_delete_card_cancel_my_card_btn,
    web_card_settings_delete_card_are_you_sure_header,
    web_card_settings_delete_card_are_you_sure_both_desc,
    web_card_settings_delete_card_are_you_sure_both_ok_btn,
    web_card_settings_delete_card_are_you_sure_cancel_btn,
    web_card_settings_delete_card_balance_not_empty_desc2,
    web_card_settings_delete_card_success_header,
    web_card_settings_delete_card_success_go_card_btn,
    web_card_settings_delete_card_success_both_header,
    web_card_settings_delete_card_success_new_card_btn,
  } = useLocalization();

  const cardDeformedOpenDialog = () => {
    if (cardItem.CardBalance > 0) {
      setDialogDescription(`${cardItem.CardBalance} ${web_card_settings_delete_card_balance_not_empty_desc2}`);
    } else {
      setDialogDescription(web_card_settings_delete_card_are_you_sure_both_desc);
    }
    setDialogTitle(web_card_settings_delete_card_are_you_sure_header);
    setPrimaryLabel(web_card_settings_delete_card_are_you_sure_both_ok_btn);
    setSecondaryLabel(web_card_settings_delete_card_are_you_sure_cancel_btn);
    setDialogOpen(true);
    setRequestType(1);
  };

  const cancelCard = () => {
    if (cardItem.CardBalance > 0) {
      setDialogDescription(`${cardItem.CardBalance} ${web_card_settings_delete_card_balance_not_empty_desc2}`);
    } else {
      setDialogDescription(web_card_settings_delete_card_are_you_sure_both_desc);
    }
    setDialogTitle(web_card_settings_delete_card_are_you_sure_header);
    setPrimaryLabel(web_card_settings_delete_card_are_you_sure_both_ok_btn);
    setSecondaryLabel(web_card_settings_delete_card_are_you_sure_cancel_btn);
    setDialogOpen(true);
    setRequestType(3);
  };

  const onDeleteCard = () => {
    dispatch(
      onDeleteCustomerCardByClosedRequest(
        deleteCustomerCardByClosedNormalize(cardItem.MifareId, requestType, customerNumber, sessionToken)
      )
    );
    setDialogOpen(false);
  };

  const data = [
    {
      title: web_card_settings_delete_card_deformed_card_btn,
      func: cardDeformedOpenDialog,
      itemID: 'cardDeformedBtnTest',
    },
    {
      title: web_card_settings_delete_card_cancel_my_card_btn,
      func: cancelCard,
      itemID: 'cancelCardBtnTest',
    },
  ];

  const renderStep = () => {
    switch (cardDeleteStep) {
      case 0:
        return <DeleteOptions productCode={cardItem.ProductCode} items={data} />;
      case 1:
        return (
          <DeleteSuccess
            title={web_card_settings_delete_card_success_both_header}
            primaryButton={{
              label: web_card_settings_delete_card_success_new_card_btn,
              handleClick: () => history.push(routePath.cardApplication),
            }}
            secondaryButton={{
              label: web_card_settings_delete_card_success_go_card_btn,
              handleClick: () => history.push(routePath.cards),
            }}
          />
        );
      case 2:
        return <DeleteSuccess title={web_card_settings_delete_card_success_both_header} />;
      case 3:
        return (
          <DeleteSuccess
            title={web_card_settings_delete_card_success_both_header}
            primaryButton={{
              label: web_card_settings_delete_card_success_new_card_btn,
              handleClick: () => {
                history.push(routePath.cardApplication);
              },
            }}
            secondaryButton={{
              label: web_card_settings_delete_card_success_go_card_btn,
              handleClick: () => history.push(routePath.cards),
            }}
          />
        );
      case 4:
        return (
          <DeleteSuccess
            title={web_card_settings_delete_card_success_header}
            primaryButton={{
              label: web_card_settings_delete_card_success_go_card_btn,
              handleClick: () => history.push(routePath.cards),
            }}
          />
        );
      case 5:
        return <DeleteError currentBalance={cardItem.CardBalance} />;
      default:
        break;
    }
  };

  return (
    <DashboardLayout backButton={cardDeleteStep !== 5}>
      <Fragment>
        <div id="card-delete">
          <StepWrapper
            noStyle
            {...(cardDeleteStep === 5 && {
              handleBack: {
                step: cardDeleteStep,
                setStepDispatch: () => dispatch(resetDeleteCardStep()),
              },
            })}
          >
            <div className="card-delete__inner">
              <FormErrorWrapper formName={formNames.cardDelete} resetFormOnRouteChange>
                <Box marginBottom={12}>{renderStep()}</Box>
              </FormErrorWrapper>
            </div>
          </StepWrapper>
        </div>
        <DialogWithActions
          open={dialogOpen}
          alignTitle="left"
          hide={() => setDialogOpen(false)}
          title={dialogTitle}
          text={dialogDescription}
          primaryButton={{
            label: primaryLabel,
            handleClick: onDeleteCard,
          }}
          secondaryButton={{
            label: secondaryLabel,
            handleClick: () => setDialogOpen(false),
          }}
        />
      </Fragment>
    </DashboardLayout>
  );
};
export default CardDelete;
