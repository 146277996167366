import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkVposReqBodyData, getBinListReqBodyData, onGetCommissionByBinNumberReqBodyData } from 'Config';
import { CheckVposPaymentModel, GetBinListModel, MasterPassCardType } from 'Models';
import generateTLVTokenModel from 'Models/GenerateTLVToken';
import store, { RootState } from 'Stores';
import { fix3Dhtml } from 'Utils';
import { CreditCardTransactionsState, MasterPassTokenActionType, tempCard } from './types';

const initialState: CreditCardTransactionsState = {
  token: undefined,
  creditCardList: undefined,
  isConnection: false,
  loading: false,
  clientId: '34706580',
  mmid: '05878207920408103103242',
  isHaveAccount: false,
  commissionAmount: undefined,
  loadAmount: 0,
  userTempCreditCard: undefined,
  tempMasterPassCard: undefined,
  activeSelectedCreditCard: undefined,
  vsPosBelbim3DsecureUrl: undefined,
  is3DSecureModalOpen: false,
  isUserAddedCreditCardWithMasterpass: true,
  creditCardBinList: undefined,
  activePayment: '',
  paymentStatus: false,
  masterPassAccountStatus: '',
};

const creditCardTransactions = createSlice({
  name: 'creditCardTransactions',
  initialState,
  reducers: {
    //get masterpass token
    onGetMasterpassTokenRequest: (state: CreditCardTransactionsState, { payload }: MasterPassTokenActionType) => {
      state.loading = true;
    },
    onGetMasterpassTokenRequestSuccess: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<generateTLVTokenModel>
    ) => {
      state.token = payload.data.Token;
      state.loading = false;
    },
    onGetMasterpassTokenRequestFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
    },
    onGetMasterpassForMobileRequest: (state: CreditCardTransactionsState, { payload }: MasterPassTokenActionType) => {
      state.loading = true;
    },
    onGetMasterpassForMobileRequestSuccess: (state: CreditCardTransactionsState) => {
      state.loading = false;
    },
    onGetMasterpassForMobileRequestFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
    },
    onGetCreditCardListSuccess: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<MasterPassCardType[]>
    ) => {
      state.creditCardList = payload;
      state.loading = false;
    },
    onGetCreditCardListFail: (state: CreditCardTransactionsState) => {
      state.creditCardList = [];
      state.loading = false;
    },

    //CHECK MASTERPASS
    onIsUserHaveAccount: (state: CreditCardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isHaveAccount = payload;
    },
    //ADD TO CARD MASTERPASS
    onDeleteMasterPassRequest: (state: CreditCardTransactionsState) => {
      state.loading = true;
    },
    onDeleteMasterPassSuccess: (state: CreditCardTransactionsState) => {
      state.loading = false;
    },
    onDeleteMasterPassFail: (state: CreditCardTransactionsState) => {
      state.loading = false;
    },
    //ADD TO CARD MASTERPASS OTP
    onAddCardOTPRequest: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = true;
    },
    onAddCardOtpSuccess: (state: CreditCardTransactionsState) => {
      state.loading = false;
    },
    onAddCardOtpFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
    },
    onGetCommissionByBinNumberRequest: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<onGetCommissionByBinNumberReqBodyData>
    ) => {
      state.loading = true;
    },
    onGetCommissionByBinNumberRequestSuccess: (state: CreditCardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.commissionAmount = payload;
    },
    onGetCommissionByBinNumberRequestFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    resetCommissionAmount: (state: CreditCardTransactionsState) => {
      state.commissionAmount = undefined;
    },
    setUserTemporaryCreditCard: (state: CreditCardTransactionsState, { payload }: PayloadAction<any>) => {
      state.userTempCreditCard = payload;
    },

    vsPosBelbimPaymentRequest: (state: CreditCardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    vsPosBelbimPaymentRequestSuccess: (state: CreditCardTransactionsState, { payload }: PayloadAction<any>) => {
      /* Belbim banka ödemelerinin geri dönüşleri stabil olmadığı için böyle bir
       * yol izlemek zorunda kalındı.
       */
      try {
        const json = fix3Dhtml(payload);
        state.loading = false;
        state.vsPosBelbim3DsecureUrl = json.url;
        state.activePayment = json.PaymentNumber;
      } catch (error) {
        state.vsPosBelbim3DsecureUrl = payload.url;
        state.activePayment = payload.PaymentNumber;
        state.loading = false;
      }
    },
    vsPosBelbimPaymentRequestFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    setActiveSelectedCreditCard: (state: CreditCardTransactionsState, { payload }: PayloadAction<any>) => {
      console.log('payload', payload);
      state.activeSelectedCreditCard = payload;
    },
    setLoadAmount: (state: CreditCardTransactionsState, { payload }: PayloadAction<number>) => {
      state.loadAmount = payload;
    },
    open3DSecureModal: (state: CreditCardTransactionsState) => {
      state.is3DSecureModalOpen = true;
    },
    close3DSecureModal: (state: CreditCardTransactionsState) => {
      state.is3DSecureModalOpen = false;
    },
    setIsUserAddedCreditCardWithMasterpass: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUserAddedCreditCardWithMasterpass = payload;
    },
    getBinListRequest: (state: CreditCardTransactionsState, { payload }: PayloadAction<getBinListReqBodyData>) => {
      state.loading = true;
    },
    getBinListRequestSuccess: (state: CreditCardTransactionsState, { payload }: PayloadAction<GetBinListModel>) => {
      state.loading = false;
      state.creditCardBinList = payload.data.BinList;
    },
    getBinListRequestFail: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    checkPaymentStatusRequest: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<checkVposReqBodyData>
    ) => {},
    checkPaymentStatusRequestResult: (
      state: CreditCardTransactionsState,
      { payload }: PayloadAction<CheckVposPaymentModel>
    ) => {
      const status = payload.data.IsSuccess;
      state.paymentStatus = status ? status : !state.paymentStatus;
      state.activePayment = status ? undefined : state.activePayment;
    },

    setMasterPassAccountStatus: (state: CreditCardTransactionsState, { payload }: PayloadAction<string>) => {
      state.masterPassAccountStatus = payload;
    },
    setCreditCardList: (state: CreditCardTransactionsState, { payload }: PayloadAction<undefined>) => {
      state.creditCardList = payload;
    },
    setLoadingForMasterpass: (state: CreditCardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setTempMasterPassCard: (state: CreditCardTransactionsState, { payload }: PayloadAction<tempCard>) => {
      console.log('setTempMasterPassCard', payload);
      state.tempMasterPassCard = payload;
    },
    setTempMasterPassCardActive: (state: CreditCardTransactionsState) => {
      console.log('state.tempMasterPassCard', state.tempMasterPassCard);
      state.activeSelectedCreditCard = state.tempMasterPassCard;
      state.userTempCreditCard = state.tempMasterPassCard;
    },
    resetCreditCardTransactionStore: (state: CreditCardTransactionsState) => initialState,
  },
});
export const creditCardTransactionsStore = (state: RootState) => state.creditCardTransactions;

export const creditCardIsLoading = (state: RootState) => state.creditCardTransactions.loading;

export const {
  onGetMasterpassTokenRequest,
  onGetMasterpassTokenRequestSuccess,
  onGetMasterpassTokenRequestFail,
  onDeleteMasterPassRequest,
  onDeleteMasterPassSuccess,
  onDeleteMasterPassFail,
  onAddCardOTPRequest,
  onAddCardOtpSuccess,
  onAddCardOtpFail,
  onGetCommissionByBinNumberRequest,
  onGetCommissionByBinNumberRequestSuccess,
  onGetCommissionByBinNumberRequestFail,
  resetCommissionAmount,
  onGetCreditCardListSuccess,
  onGetCreditCardListFail,
  setUserTemporaryCreditCard,
  setActiveSelectedCreditCard,
  setLoadAmount,
  vsPosBelbimPaymentRequest,
  vsPosBelbimPaymentRequestSuccess,
  vsPosBelbimPaymentRequestFail,
  open3DSecureModal,
  close3DSecureModal,
  setIsUserAddedCreditCardWithMasterpass,
  getBinListRequest,
  getBinListRequestSuccess,
  getBinListRequestFail,
  checkPaymentStatusRequest,
  checkPaymentStatusRequestResult,
  setMasterPassAccountStatus,
  setCreditCardList,
  setLoadingForMasterpass,
  onGetMasterpassForMobileRequest,
  onGetMasterpassForMobileRequestSuccess,
  onGetMasterpassForMobileRequestFail,
  resetCreditCardTransactionStore,
  setTempMasterPassCard,
  setTempMasterPassCardActive,
} = creditCardTransactions.actions;
export default creditCardTransactions.reducer;
