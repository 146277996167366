import { FC, memo, useEffect, useState } from 'react';
import { DashboardLayout, FormErrorWrapper } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { CardOwner } from './CardOwner';
import { AddCard } from './AddCard';
import { AddCardSuccess } from './AddCardSuccess';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetAddCardStep,
  setIsUserAddingNewCardInTransactions,
  storeCardTransactions,
} from 'Stores/CardTransactions/slices';
import { gutters } from 'Theme/Variables';
import { formNames } from 'Config';
import { CardTransactionsState } from 'Stores/CardTransactions';
import { useHistory } from 'react-router-dom';
import { CardBelongSomeElseModel } from './CardBelongSomeElseModel';

import './UserAddCard.scss';

type UserAddCardProps = {};

const UserAddCard: FC<UserAddCardProps> = memo(() => {
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [isUserCard, setIsUserCard] = useState<boolean>(true);

  const history = useHistory();

  const cardTransactionsStore: CardTransactionsState = useSelector(storeCardTransactions);

  const step = cardTransactionsStore?.addCardStep;

  const isUserAddingCardFromTransactions = cardTransactionsStore?.isUserAddingNewCardInTransactions;
  const userTemprorayAddedCard = cardTransactionsStore?.tempCardInfo;

  useEffect(() => {
    /*   if (!isUserAddingCardFromTransactions) {
      dispatch(resetAddCardStep());
    } */
  }, []);

  const {
    web_title_add_istanbulkart,
    web_desc_who_is_owner_of_card,
    web_desc_add_istanbulkart,
    web_desc_success_add_istanbulkart,
    web_desc1_passive_card_success,
    web_title_success_add_istanbulkart,
    web_title_passive_card_succes,
    web_desc2_passive_card_success,
    web_desc_upload_tl_add_istanbulkart,
  } = useLocalization();

  const renderDescriptionKey = () => {
    switch (step) {
      case 0:
        return web_desc_who_is_owner_of_card;
      case 1:
        if (isUserAddingCardFromTransactions) {
          return web_desc_upload_tl_add_istanbulkart;
        } else {
          return web_desc_add_istanbulkart;
        }
      case 2:
      case 3:
        return '';
      case 4:
        if (isActive) {
          return web_desc_success_add_istanbulkart;
        } else {
          return web_desc1_passive_card_success;
        }
      default:
        break;
    }
  };

  const renderTitleKey = () => {
    switch (step) {
      case 0:
      case 1:
      case 2:
      case 3:
        return web_title_add_istanbulkart;
      case 4:
        if (isActive) {
          return web_title_success_add_istanbulkart;
        } else {
          return web_title_passive_card_succes;
        }
      default:
        break;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <CardOwner setOtherCard={() => setIsUserCard(false)} />;
      case 1:
      case 2:
      case 3:
        return <AddCard step={step} setPassive={() => setIsActive(false)} isUserCard={isUserCard} />;
      case 4:
        return <AddCardSuccess isActive={isActive} isUserCard={isUserCard} />;
      default:
        break;
    }
  };

  const handleBack = () => {
    if (userTemprorayAddedCard) {
      history.goBack();
    } else {
      dispatch(setIsUserAddingNewCardInTransactions(false));
      history.goBack();
    }
  };

  const handleInternalBack = () => {
    if (!isUserAddingCardFromTransactions && step != 0) {
      dispatch(resetAddCardStep());
    } else {
      history.goBack();
    }
  };

  return (
    <DashboardLayout backButton backAction={isUserAddingCardFromTransactions ? handleBack : handleInternalBack}>
      <div id="userAddCard">
        <FormErrorWrapper formName={formNames.addCard} step={step} resetFormOnRouteChange>
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{renderTitleKey()}</Box>
          </Typography>
          <Box className="userAddCard__title">
            <Typography variant="body1" align="center">
              {renderDescriptionKey()}
            </Typography>
            <Box marginTop={gutters.regular}>
              <Typography variant="body1" align="center">
                {step === 4 && !isActive && web_desc2_passive_card_success}
              </Typography>
            </Box>
          </Box>
          <Box>{renderStep()}</Box>
        </FormErrorWrapper>
        <CardBelongSomeElseModel setOtherCard={() => setIsUserCard(false)} setActive={() => setIsActive(true)} />
      </div>
    </DashboardLayout>
  );
});

export default UserAddCard;
