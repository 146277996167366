import { FC, Fragment, memo, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Typography } from '@material-ui/core';
import { LoadSubscription } from './LoadSubscription';
import { UploadSubscriptionSuccess } from './UploadSubscriptionSuccess';
import { useDispatch, useSelector } from 'react-redux';
import { CardTransactionsState, storeCardTransactions } from 'Stores/CardTransactions';
import { MasterPassAccountStatusEnum, MasterpassServicess, MasterPassTokenEnum } from 'Utils';
import { UserState, userStore } from 'Stores/User';
import {
  CreditCardTransactionsState,
  creditCardTransactionsStore,
  onGetMasterpassTokenRequest,
} from 'Stores/CreditCardTransactions';
import { masterPassListCardNormalize } from 'Normalize';
import { MasterpassConnect } from 'Views/MasterpassConnect';

import './UploadSubscription.scss';

type UploadSubscriptionParams = {};

const UploadSubscription: FC<UploadSubscriptionParams> = memo(() => {
  const { web_title_upload_subscription, web_tile_subscription_success } = useLocalization();

  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const isOtherCardsLoading = cardData?.otherCardStateLoading;
  const step: number = cardData?.uploadSubscriptionStep;

  const userData: UserState = useSelector(userStore);

  const creditCardData: CreditCardTransactionsState = useSelector(creditCardTransactionsStore);

  const accountStatus = creditCardData?.masterPassAccountStatus;

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  useEffect(() => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CARD_LIST,
        request: {
          data: masterPassListCardNormalize(),
        },
      })
    );
  }, [accountStatus]);

  return (
    <DashboardLayout backButton={step === 0}>
      <div id="uploadSubscription">
        {accountStatus === MasterPassAccountStatusEnum.NO_LINKED ? (
          <MasterpassConnect />
        ) : (
          <Fragment>
            <Typography variant="h3" className="uploadSubscription__title">
              {step === 0 ? web_title_upload_subscription : web_tile_subscription_success}
            </Typography>

            <Fragment>
              {step === 0 && !isOtherCardsLoading && <LoadSubscription />}
              {step === 1 && <UploadSubscriptionSuccess />}
            </Fragment>
          </Fragment>
        )}
      </div>
    </DashboardLayout>
  );
});

export default UploadSubscription;
