import maskHesCode from 'Utils/MaskHesCode';

export const hesCodeNormalize = (hesCode?: string, customerNumber?: number, sessionToken?: string) => {
  const formValues = {
    CustomerNumber: customerNumber,
    Sessiontoken: sessionToken,
    HesCode: hesCode,
  };
  return formValues;
};
