import * as React from 'react';

function BigPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="bigPlusIcon"
      {...props}
    >
      <defs>
        <path
          id="1fg8g9j1ta"
          d="M20 3.333C10.795 3.333 3.333 10.795 3.333 20c0 9.205 7.462 16.667 16.667 16.667 9.205 0 16.667-7.462 16.667-16.667 0-4.42-1.756-8.66-4.882-11.785C28.66 5.089 24.42 3.333 20 3.333zm5.5 18.5h-3.667V25.5c0 1.013-.82 1.833-1.833 1.833s-1.833-.82-1.833-1.833v-3.667H14.5c-1.013 0-1.833-.82-1.833-1.833s.82-1.833 1.833-1.833h3.667V14.5c0-1.013.82-1.833 1.833-1.833s1.833.82 1.833 1.833v3.667H25.5c1.013 0 1.833.82 1.833 1.833s-.82 1.833-1.833 1.833z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-224 -263) translate(156 243) translate(68 20)">
              <path d="M0 0H40V40H0z" />
              <mask id="xtrmzf1ahb" fill="#fff">
                <use xlinkHref="#1fg8g9j1ta" />
              </mask>
              <use fill="#383C52" fillRule="nonzero" xlinkHref="#1fg8g9j1ta" />
              <g fill="currentColor" mask="url(#xtrmzf1ahb)">
                <path d="M0 0H40V40H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BigPlusIcon;
