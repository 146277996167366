import * as React from 'react';

function PeginationRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="lc5qnhd80a"
          d="M8.293 5.293c.36-.36.928-.388 1.32-.083l.094.083 6 6c.36.36.388.928.083 1.32l-.083.094-6 6c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L13.585 12 8.293 6.707c-.36-.36-.388-.928-.083-1.32l.083-.094z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-852.000000, -1229.000000) translate(556.000000, 1221.000000) translate(288.000000, 0.000000) translate(8.000000, 8.000000)">
                <path d="M0 0H24V24H0z" />
                <mask id="j1aib0xjgb" fill="#fff">
                  <use xlinkHref="#lc5qnhd80a" />
                </mask>
                <g fill="#383C52" mask="url(#j1aib0xjgb)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PeginationRight;
