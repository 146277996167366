import * as React from 'react';

function LoadPointIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="37"
      viewBox="0 0 31 37"
      {...props}
    >
      <defs>
        <path
          id="g6y3h4znga"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12c0 2.652 1.054 5.196 2.929 7.071C6.804 20.946 9.348 22 12 22zM8.31 10.14l3-2.86c.043-.042.094-.076.15-.1.047-.042.101-.076.16-.1.242-.107.518-.107.76 0 .123.048.235.119.33.21l3 3c.392.392.392 1.028 0 1.42-.392.392-1.028.392-1.42 0L13 10.41V16c0 .552-.448 1-1 1s-1-.448-1-1v-5.66l-1.31 1.25c-.4.381-1.034.365-1.415-.035s-.365-1.034.035-1.415z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#4EB6E7"
                fillRule="nonzero"
                d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691c6.072-6.008 6.072-15.795.033-21.803z"
                transform="translate(-612.000000, -342.000000) translate(254.000000, 286.000000) translate(358.000000, 56.000000)"
              />
              <g transform="translate(-612.000000, -342.000000) translate(254.000000, 286.000000) translate(358.000000, 56.000000) translate(3.500000, 4.000000)">
                <path d="M0 0H24V24H0z" />
                <mask id="shd5khvvxb" fill="#fff">
                  <use xlinkHref="#g6y3h4znga" />
                </mask>
                <g fill="#37479E" mask="url(#shd5khvvxb)">
                  <path d="M0 0H24V24H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LoadPointIcon;
