import { FC } from 'react';
import { AlertIcon } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { colors, gutters } from 'Theme/Variables';

import './DeleteError.scss';

const ICON_SIZE = '144px';

type DeleteErrorProps = {
  currentBalance?: number;
};

const DeleteError: FC<DeleteErrorProps> = ({ currentBalance }) => {
  const {
    web_card_settings_delete_card_cant_be_deleted,
    web_card_settings_delete_card_cant_be_deleted_current_balance,
  } = useLocalization();
  return (
    <div id="delete-card-error-container">
      <AlertIcon width={ICON_SIZE} height={ICON_SIZE} />
      <Typography variant="h3">
        <Box marginTop={gutters.big} fontWeight={600} textAlign="center">
          {web_card_settings_delete_card_cant_be_deleted}
        </Box>
      </Typography>
      <Typography variant="h3">
        <Box marginTop={gutters.big} color={colors.toastErrorText} fontWeight={600} textAlign="center">
          {web_card_settings_delete_card_cant_be_deleted_current_balance + ' ' + currentBalance}
        </Box>
      </Typography>
    </div>
  );
};

export default DeleteError;
