import * as React from 'react';

function PeopleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="qqz33ki4ta"
          d="M4.32 10.543c2.61-1.083 5.617-.488 7.62 1.507 1.508-1.167 3.548-1.375 5.26-.536 1.712.84 2.798 2.58 2.8 4.486 0 .552-.448 1-1 1h-5c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1 .004-2.827 1.708-5.374 4.32-6.457zM15 4c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM7 0c2.21 0 4 1.79 4 4S9.21 8 7 8 3 6.21 3 4s1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-462 -409) translate(446 385) translate(16 24)" />
              <g transform="translate(-462 -409) translate(446 385) translate(16 24) translate(2 3)">
                <mask id="6zxogdpx0b" fill="#fff">
                  <use xlinkHref="#qqz33ki4ta" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#qqz33ki4ta" />
                <g fill="#1AAADC" mask="url(#6zxogdpx0b)">
                  <path d="M0 0H24V24H0z" transform="translate(-2 -3)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PeopleIcon;
