import { FC, memo } from 'react';
import { PeopleIcon, AvatarIcon, ChevronBlueRightIcon } from 'Components';
import { Box, Typography } from '@material-ui/core';
import { gutters, fontSizes, colors } from 'Theme/Variables';
import { useLocalization } from 'Hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  incrementAddCardStep,
  mbSaveRegisterCardRequest,
  onSaveCustomerCardRequest,
  setAddCardStep,
  storeCardTransactions,
} from 'Stores/CardTransactions/slices';
import { saveCardAfterTransactionsNormalize, saveUserCardAfterTransactionsNormalize } from 'Normalize';
import { UserState, userStore } from 'Stores/User';

import './CardOwner.scss';

type CardOwnerProps = {
  setOtherCard: () => void;
};

const CardOwner: FC<CardOwnerProps> = memo(({ setOtherCard }) => {
  const dispatch = useDispatch();

  const {
    web_btn_title_my_card,
    web_btn_title_others_card,
    web_btn_desc_my_card,
    web_btn_desc_others_card,
  } = useLocalization();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const cardData = useSelector(storeCardTransactions);
  const { activeSelectedCard, tempCardInfo, isUserAddingNewCardInTransactions }: any = cardData;

  const keys = [
    {
      title: web_btn_title_my_card,
      description: web_btn_desc_my_card,
      handleClick: () => {
        if (isUserAddingNewCardInTransactions) {
          dispatch(
            onSaveCustomerCardRequest(
              saveUserCardAfterTransactionsNormalize(activeSelectedCard, tempCardInfo, customerNumber, sessionToken)
            )
          );
        } else {
          dispatch(incrementAddCardStep());
        }
      },
      itemID: 'addMineCardBtnTest',
    },
    {
      title: web_btn_title_others_card,
      description: web_btn_desc_others_card,
      handleClick: () => {
        if (isUserAddingNewCardInTransactions) {
          dispatch(
            mbSaveRegisterCardRequest(saveCardAfterTransactionsNormalize(tempCardInfo, customerNumber, sessionToken))
          );
          setOtherCard();
        } else {
          dispatch(setAddCardStep(2));
          setOtherCard();
        }
      },
      itemID: 'addSomebodyCardBtnTest',
    },
  ];

  return (
    <div id="cardOwner">
      {keys.map(({ title, description, handleClick, itemID }, index) => (
        <div className="card--owner" onClick={handleClick} key={index} itemID={itemID}>
          <Box>
            <Box alignItems="center" justifyContent="space-between" display="flex">
              <Box alignItems="center" justifyContent="space-between" display="flex">
                {index === 0 ? <AvatarIcon /> : <PeopleIcon />}
                <Box marginLeft={gutters.regular}>
                  <Box
                    className="card--owner__title"
                    fontSize={fontSizes.regularBig}
                    color={colors.secondary}
                    fontWeight="bold"
                  >
                    {title}
                  </Box>
                  <Typography className="card--owner__desc">{description}</Typography>
                </Box>
              </Box>
              <ChevronBlueRightIcon />
            </Box>
          </Box>
        </div>
      ))}
    </div>
  );
});

export default CardOwner;
