import { PayloadAction } from '@reduxjs/toolkit';
import { formNames, onCreateOnlineCustomerBodyData, onSetPasswordBodyData } from 'Config';
import { OnCreateOnlineCustomerModel, OnSetPasswordModel } from 'Models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { onCreateOnlineCustomer, onSetPassword } from 'Services/Mutations';
import { setFormError } from 'Stores/Form';
import { generateLanguageParam, isBelbimRequestSuccess, sendEVAMCustomEvent } from 'Utils';
import {
  incrementSignupStep,
  onCreateOnlineCustomerFail,
  onCreateOnlineCustomerRequest,
  onCreateOnlineCustomerSuccess,
  onSetPasswordFail,
  onSetPasswordRequest,
  onSetPasswordSuccess,
  storeSingupStep,
} from './slices';
import { forgotPasswordFormStep, incrementForgotPasswordFormStep, userStore } from '../User/slices';
import { push } from 'connected-react-router';
import { routePath } from 'Navigator/routes';
import EVAMSdkEventTypes from 'Utils/EVAMSdkEventTypes';
import { UserState } from 'Stores/User';

// Workers
function* handleOnCreateOnlineCustomerRequestSaga({ payload }: PayloadAction<onCreateOnlineCustomerBodyData>) {
  try {
    const signUpStep: number = yield select(storeSingupStep);

    const userState: UserState = yield select(userStore);
    const h = payload.PropertyType === 3 ? userState.h : undefined;
    const Language = payload.PropertyType === 3 ? generateLanguageParam() : undefined;

    const onCreateOnlineCustomerResult: OnCreateOnlineCustomerModel = yield call(() =>
      onCreateOnlineCustomer({ ...payload, h, Language })
    );

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onCreateOnlineCustomerResult);

    if (requestSuccess) {
      yield put(onCreateOnlineCustomerSuccess(onCreateOnlineCustomerResult));
      yield put(incrementSignupStep());
      if (signUpStep === 3) {
        const evamSdkData = {
          customer_no: 'Signup olunurken bu parametre bulunmuyor.',
          CommercialStatusChannelType: payload?.CommercialStatusChannelType,
          EmailAdress: payload?.Mail,
          CellPhone: `+${payload.CountryCode} ${payload.Cellphone}`,
        };

        sendEVAMCustomEvent(EVAMSdkEventTypes.TICARI_ILETI_IZNI_ALINMASI, evamSdkData);

        const evamSdkOtherData = {
          customer_no: payload.CitizenshipNumber,
          OnlinePermissionMessage: payload.OnlinePermissionMessage,
        };

        sendEVAMCustomEvent(EVAMSdkEventTypes.ACIK_RIZA_VERILMESI, evamSdkOtherData);
      }
    } else {
      yield put(onCreateOnlineCustomerFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.signup }));
    }
  } catch (error: any) {
    yield put(setFormError({ errorCode: error, formName: formNames.signup }));
    yield put(onCreateOnlineCustomerFail(error));
  }
}

function* handleOnSetPasswordRequestSaga({ payload }: PayloadAction<onSetPasswordBodyData>) {
  try {
    const userData: UserState = yield select(userStore);
    const h = userData.h;

    const onSetPasswordResult: OnSetPasswordModel = yield call(() => onSetPassword({ ...payload, h }));

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onSetPasswordResult);

    const forgotPasswordStep: number = yield select(forgotPasswordFormStep);
    const signUpStep: number = yield select(storeSingupStep);

    if (requestSuccess) {
      yield put(onSetPasswordSuccess(onSetPasswordResult));
      // Both component are using the same services in different steps
      //Forgot Password process step-1 and Signup process step 4
      if (forgotPasswordStep === 1) {
        yield put(incrementForgotPasswordFormStep());
      }
      if (signUpStep === 4) {
        yield put(push(routePath.welcome));
      }
    } else {
      yield put(onSetPasswordFail(errorMessage));
      if (forgotPasswordStep === 1) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.forgotPassword,
          })
        );
      } else {
        yield put(setFormError({ errorCode: responseCode, formName: formNames.signup }));
      }
    }
  } catch (error: any) {
    yield put(setFormError({ errorCode: error, formName: formNames.signup }));
  }
}

// Watchers
function* signUpSagaWatcher() {
  yield takeLatest(onCreateOnlineCustomerRequest, handleOnCreateOnlineCustomerRequestSaga);
  yield takeLatest(onSetPasswordRequest, handleOnSetPasswordRequestSaga);
}

export default signUpSagaWatcher;
