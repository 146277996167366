import * as React from 'react';

function CreditCardWithPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="creditCardWithPlusIcon"
    >
      <defs>
        <path
          id="7oo3qnguxa"
          d="M7 10c1.326 0 2.598.527 3.536 1.464C11.473 12.402 12 13.674 12 15c0 2.761-2.239 5-5 5s-5-2.239-5-5 2.239-5 5-5zm0 2c-.414 0-.75.336-.75.75v1.5h-1.5c-.414 0-.75.336-.75.75s.336.75.75.75h1.5v1.5c0 .414.336.75.75.75s.75-.336.75-.75v-1.5h1.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.5v-1.5c0-.414-.336-.75-.75-.75zm12.45-7c1.352 0 2.458 1.061 2.545 2.402l.005.17v6.857c0 1.363-1.052 2.478-2.382 2.566L19.45 17h-6.792c.222-.625.342-1.298.342-2 0-.5-.061-.984-.176-1.448.386-.08.676-.424.676-.838 0-.437-.324-.797-.743-.85l-.107-.007h-.538C11.056 10.143 9.162 9 7 9c-.701 0-1.374.12-2 .341v-1.77c0-1.363 1.052-2.478 2.382-2.566L7.55 5h11.9zm-1.7 6.857h-1.7c-.47 0-.85.384-.85.857 0 .437.324.798.743.85l.107.007h1.7c.47 0 .85-.383.85-.857 0-.473-.38-.857-.85-.857zm1.7-5.143H7.55c-.433 0-.79.327-.843.75L6.7 7.57v.858h13.6V7.57c0-.473-.38-.857-.85-.857z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-461 -477) translate(445 451) translate(16 26)">
              <path d="M0 0H24V24H0z" />
              <mask id="fgwj57j82b" fill="#fff">
                <use xlinkHref="#7oo3qnguxa" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#7oo3qnguxa" />
              <g fill="#383C52" mask="url(#fgwj57j82b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CreditCardWithPlusIcon;
