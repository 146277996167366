const inputMaskTypes = {
  trPhone: '999 999 9999',
  intPhone: '999999999999999',
  tcNo: '99999999999',
  date: '99.99.9999',
  time: '99:99',
  hes: '**** - **** - **',
  userCardNo: '9999 9999 9999 9999',
  creditCardExpireDate: '99/99',
  cvv: '999',
  iban: 'TR99 9999 9999 9999 9999 9999 99',
  plc: '999',
  lowerLimit: '999',
  postCode: '99999',
};

export default inputMaskTypes;
