import * as React from 'react';

function CloseWhiteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className="closeIconWhite"
      {...props}
    >
      <defs>
        <path
          id="smnokkd6fa"
          d="M23.384 6.616c.45.45.485 1.16.104 1.65l-.104.118L16.767 15l6.617 6.616c.488.488.488 1.28 0 1.768-.45.45-1.16.485-1.65.104l-.118-.104L15 16.767l-6.616 6.617c-.488.488-1.28.488-1.768 0-.45-.45-.485-1.16-.104-1.65l.104-.118L13.233 15 6.616 8.384c-.488-.488-.488-1.28 0-1.768.45-.45 1.16-.485 1.65-.104l.118.104L15 13.233l6.616-6.617c.488-.488 1.28-.488 1.768 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-715 -28) translate(715 28)">
            <path d="M0 0H30V30H0z" />
            <mask id="1ztls03yzb" fill="#fff">
              <use xlinkHref="#smnokkd6fa" />
            </mask>
            <g fill="#FFF" mask="url(#1ztls03yzb)">
              <path d="M0 0H30V30H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CloseWhiteIcon;
