import { axiosHelper } from 'Services/AxiosHelper';
import { OnGetCardTransactionListModel } from 'Models';
import { onGetCardTransactionListBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnGetCardTransactionList';

const onGetCardTransactionList = async (
  params: onGetCardTransactionListBodyData
): Promise<OnGetCardTransactionListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onGetCardTransactionList;
