import { Dispatch, FC } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Grid } from '@material-ui/core';
import { FoodIcon, CloseIcon, ShoppingIcon, TopUpIcon, TransportIcon, OtherIcon } from 'Components';
import { colors } from 'Theme/Variables';
import { SetStateAction } from 'react';
import { getTagsCount } from 'Utils';
import { MapsState, mapStore, setDiscoverSelectedTag } from 'Stores/Maps';
import { useDispatch, useSelector } from 'react-redux';

import './MapMask.scss';

type MapMaskProps = {
  setPage: Dispatch<SetStateAction<number>>;
};
const MapMask: FC<MapMaskProps> = ({ setPage }) => {
  const dispatch = useDispatch();
  const mapState: MapsState = useSelector(mapStore);

  const { discoverTags, discoverSelectedTag, discoverAllPins } = mapState;

  const { web_travel_spend_title_3, web_point_available } = useLocalization();

  const { foodCount, loadCount, marketCount, otherCount, travelCount } = getTagsCount(discoverAllPins || []);

  const checkIconColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? '#fff' : undefined;
  };
  const checkBackgroundColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? colors.secondary : '#fff';
  };
  const checkTextColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? '#fff' : colors.secondary;
  };

  const renderIcon = (id: string) => {
    switch (id) {
      case '1':
        return <OtherIcon fill={checkIconColor(Number(id))} className="mapMask__item-container__icon" />;
      case '2':
        return <TransportIcon fill={checkIconColor(Number(id))} className="mapMask__item-container__icon" />;
      case '3':
        return <FoodIcon fill={checkIconColor(Number(id))} className="mapMask__item-container__icon" />;
      case '4':
        return <TopUpIcon fill={checkIconColor(Number(id))} className="mapMask__item-container__icon" />;
      case '5':
        return <ShoppingIcon fill={checkIconColor(Number(id))} className="mapMask__item-container__icon" />;
      default:
        break;
    }
  };

  const renderCount = (id: string) => {
    switch (id) {
      case '1':
        return otherCount;
      case '2':
        return travelCount;
      case '3':
        return foodCount;
      case '4':
        return loadCount;
      case '5':
        return marketCount;
      default:
        break;
    }
  };

  const setSelectedItems = (id: number) => {
    dispatch(setDiscoverSelectedTag(id));
    setPage(2);
  };

  const Item = ({ value, id }: any) => (
    <Grid item>
      <div
        onClick={() => setSelectedItems(Number(id))}
        style={{
          backgroundColor: checkBackgroundColor(Number(id)),
          color: checkTextColor(Number(id)),
        }}
        className="mapMask__item-container"
        itemID="discoverSelectItemMaskBtnTest"
      >
        {renderIcon(id)}
        {value}
        <Typography variant="body1">
          <Box color={colors.gray} fontWeight={600} ml="5px">
            {renderCount(id)?.toString()}
          </Box>
        </Typography>
      </div>
    </Grid>
  );

  return (
    <div id="mapMask">
      <CloseIcon
        fill="#fff"
        onClick={() => {
          setPage(2);
        }}
      />
      <div className="mapMask">
        <Typography variant="h3">
          <Box fontSize="34px" fontWeight={600} color="#fff">
            {discoverAllPins
              ? web_point_available.replace('{}', discoverAllPins.length.toString())
              : web_travel_spend_title_3}
          </Box>
        </Typography>

        <Grid className="mapMask__nav" container>
          {discoverTags &&
            Object.keys(discoverTags).map((key: string) => {
              // @ts-ignore
              return <Item key={key} value={discoverTags[key]} id={key} />;
            })}
        </Grid>
      </div>
    </div>
  );
};
export default MapMask;
