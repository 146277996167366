import { Box, Button, Divider, FormHelperText, Grid, Typography } from '@material-ui/core';
import { FormErrorWrapper } from 'Components';
import { Modal3DSecure } from 'Components/Modal3DSecure';
import { formNames } from 'Config';
import { useCardType, useCreditCardType, useLocalization } from 'Hooks';
import { masterPassPaymentNormalize } from 'Normalize';
import { CardTransactionsState, getSeasonTicketLoadableRequest, storeCardTransactions } from 'Stores/CardTransactions';
import {
  close3DSecureModal,
  creditCardTransactionsStore,
  onGetMasterpassTokenRequest,
  setActiveSelectedCreditCard,
} from 'Stores/CreditCardTransactions';
import { FormState, resetFormError, storeFormError } from 'Stores/Form';
import { UserState, userStore } from 'Stores/User';
import { storeWelcome } from 'Stores/Welcome';
import { fontSizes, gutters } from 'Theme/Variables';
import { MasterPassRefType, MasterPassTokenEnum, cardBalanceNormalize } from 'Utils';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { FC, memo, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChooseCard } from './ChooseCard';
import { ChooseCreditCard } from './ChooseCreditCard';
import { errorCodes } from './constant';

import './LoadSubscription.scss';

type LoadSubscriptionParams = {};

const LoadSubscription: FC<LoadSubscriptionParams> = memo(() => {
  const {
    web_desc_total_amount,
    msg_web_upload_tl_add_debit_card_success,
    web_btn_go_to_pay,
    web_title_subscription_information,
    web_lbl_subscription_amount,
    web_lbl_subscription_transaction_fee,
    web_lbl_subscription_load_usage,
    web_lbl_subscription_expiration_date,
    msg_web_upload_tl_add_istanbulkart_success,
    msg_web_upload_tl_add_debit_card_no_check,
    msg_web_zorunlu_alan,
    card_colour_digital_card_activation_control,
  } = useLocalization();

  const dispatch = useDispatch();
  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const welcomeData = useSelector(storeWelcome);
  const formData: FormState = useSelector(storeFormError);

  const {
    commissionAmount,
    creditCardList,
    activeSelectedCreditCard,
    userTempCreditCard,
    isUserAddedCreditCardWithMasterpass,
    is3DSecureModalOpen,
    vsPosBelbim3DsecureUrl,
    activePayment,
  } = useSelector(creditCardTransactionsStore);

  const { Cellphone } = welcomeData;
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const userCards: cardItemParams[] = cardData?.cards;
  const userPrimaryCard = userCards.filter((item) => item?.IsPrimary);
  const {
    activeSelectedCard,
    isUserAddingNewCardInTransactions,
    cardSeasonTicketPrice,
    tempCardInfo,
    loading: { cardLoading },
  }: any = cardData;

  const { cardColor, cardPath } = useCardType(userPrimaryCard[0]?.ProductCode);
  const { creditCardBrand } = useCreditCardType(userTempCreditCard);

  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  useLayoutEffect(() => {
    // If user have masterpass cards show first added card
    if (creditCardList && creditCardList?.length > 0 && !activeSelectedCreditCard && !userTempCreditCard) {
      dispatch(setActiveSelectedCreditCard(creditCardList[creditCardList?.length - 1]));
    }
    // If user comes this screen from transactions show primary card
    if (userPrimaryCard && userPrimaryCard.length > 0 && !activeSelectedCard) {
      dispatch(
        getSeasonTicketLoadableRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          MifareId: userPrimaryCard[0]?.MifareId,
          SeasonTicketType: '1',
          primaryCard: {
            ...userPrimaryCard[0],
            cardColorClass: cardColor || '',
            cardPath: cardPath || '',
          },
        })
      );
    }
  }, [creditCardList]);

  useEffect(() => {
    if (!!formData?.errorCode) {
      dispatch(resetFormError());
    }
    if (activeSelectedCard && activeSelectedCard) {
      dispatch(
        getSeasonTicketLoadableRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
          MifareId: activeSelectedCard?.MifareId,
          SeasonTicketType: '1',
        })
      );
    }
  }, [activeSelectedCard, activeSelectedCreditCard]);

  const isDijitalCard = card_colour_digital_card_activation_control?.includes(activeSelectedCard?.ProductCode);

  const handleSubmitPayment = () => {
    if (!activeSelectedCard || !activeSelectedCreditCard) {
      setErrorMessage(true);
      return;
    }
    if (!isUserAddedCreditCardWithMasterpass && userTempCreditCard) {
      dispatch(
        onGetMasterpassTokenRequest({
          activeSelectedCreditCard: activeSelectedCreditCard,
          SessionToken: sessionToken,
          CustomerNumber: customerNumber,
          TokenType: MasterPassTokenEnum.REGISTER_PURCHASE,
          RefTrnType: isDijitalCard ? MasterPassRefType.DIGITAL_ABONMAN_BALANCE : MasterPassRefType.ABONMAN,
          Amount: cardSeasonTicketPrice.SeasonTicketPrice,
          CommissionAmount: commissionAmount,
          DeviceType: '10',
          MifareId: activeSelectedCard.MifareId,
          PanMasked: activeSelectedCreditCard.Value1,
          PmtAccountId: isDijitalCard ? userData?.digitalAccount?.AccountId : 0,
          request: {
            data: masterPassPaymentNormalize(
              cardSeasonTicketPrice.SeasonTicketPrice + (commissionAmount || 0),
              activeSelectedCreditCard.Name
            ),
          },
        })
      );
    } else {
      dispatch(
        onGetMasterpassTokenRequest({
          SessionToken: sessionToken,
          CustomerNumber: customerNumber,
          TokenType: MasterPassTokenEnum.REGISTER_PURCHASE,
          RefTrnType: isDijitalCard ? MasterPassRefType.DIGITAL_ABONMAN_BALANCE : MasterPassRefType.ABONMAN,
          Amount: cardSeasonTicketPrice.SeasonTicketPrice,
          CommissionAmount: commissionAmount,
          DeviceType: '10',
          MifareId: activeSelectedCard.MifareId,
          PanMasked: activeSelectedCreditCard.Value1,
          PmtAccountId: isDijitalCard ? userData?.digitalAccount?.AccountId : 0,
          request: {
            data: masterPassPaymentNormalize(
              cardSeasonTicketPrice.SeasonTicketPrice + (commissionAmount || 0),
              activeSelectedCreditCard.Name
            ),
          },
        })
      );
    }
  };

  const showHideSeasonTicketPrice = () => {
    if (
      formData?.errorCode === errorCodes.ABONMAN_YUKLENEMEZ ||
      formData?.errorCode === errorCodes.BU_AY_ABONMAN_YUKLENEMEZ
    ) {
      return false;
    }
    return true;
  };

  const uploadDetails = [
    {
      title: web_lbl_subscription_amount,
      value: cardBalanceNormalize(cardSeasonTicketPrice?.SeasonTicketPrice),
      unit: 'TL',
    },
    {
      title: web_lbl_subscription_transaction_fee,
      value: cardBalanceNormalize(commissionAmount),
      unit: 'TL',
    },
    {
      title: web_lbl_subscription_load_usage,
      value: cardSeasonTicketPrice?.SeasonTicketLimit,
    },
    /*  {
      title: web_lbl_subscription_expiration_date,
      value: moment(cardSeasonTicketPrice?.ExpireDate).format('DD.MM.YYYY'),
    }, */
  ];

  return (
    <FormErrorWrapper formName={formNames.uploadSubscription} resetFormOnRouteChange>
      <div id="loadSubscription">
        <Box>
          <ChooseCard selectedCard={activeSelectedCard} />
          {isUserAddingNewCardInTransactions && tempCardInfo && (
            <Typography variant="body1" className="loadSubscription__tempCard">
              {msg_web_upload_tl_add_istanbulkart_success}
            </Typography>
          )}
          {errorMessage && !activeSelectedCard && <FormHelperText error>{msg_web_zorunlu_alan}</FormHelperText>}
          <ChooseCreditCard creditCardList={creditCardList} selectedCreditCard={activeSelectedCreditCard} />
          {userTempCreditCard && (
            <Typography variant="body1" className="loadSubscription__tempCreditCard">
              {isUserAddedCreditCardWithMasterpass
                ? msg_web_upload_tl_add_debit_card_success
                : msg_web_upload_tl_add_debit_card_no_check}
            </Typography>
          )}
          {errorMessage && !activeSelectedCreditCard && <FormHelperText error>{msg_web_zorunlu_alan}</FormHelperText>}
        </Box>

        {activeSelectedCard && showHideSeasonTicketPrice() && (
          <Box>
            <Typography variant="h6">
              <Box fontWeight="bold" marginBottom={fontSizes.small} marginTop={fontSizes.big}>
                {web_title_subscription_information}
              </Box>
            </Typography>
            <Divider />
            <Box className="loadSubscription__abonmanInfo">
              {uploadDetails.map((item, index) => (
                <Grid container className="loadSubscription__abonmanInfo__item" key={index}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="body1" className="loadSubscription__abonmanInfo__title">
                      {item?.title}
                    </Typography>
                  </Grid>
                  {!cardLoading && cardSeasonTicketPrice?.SeasonTicketPrice && (
                    <Grid item xs={6} sm={8}>
                      <Typography variant="h3">
                        <Box fontWeight="bold" fontSize={fontSizes.small}>
                          {`${item?.value} ${item?.unit || ''}`}
                        </Box>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Box>
          </Box>
        )}

        <Box>
          {cardSeasonTicketPrice?.SeasonTicketPrice && showHideSeasonTicketPrice() && (
            <Box height={55} marginBottom={gutters.big}>
              <Typography variant="body1" className="loadSubscription__totalBalance">
                {web_desc_total_amount}
              </Typography>
              <Typography variant="h3" align="center">
                <Box fontWeight="bold" fontSize={fontSizes.big}>
                  {!cardLoading &&
                    cardBalanceNormalize(cardSeasonTicketPrice?.SeasonTicketPrice + commissionAmount) !== 'NaN' && (
                      <Box>{cardBalanceNormalize(cardSeasonTicketPrice?.SeasonTicketPrice + commissionAmount)} TL</Box>
                    )}
                </Box>
              </Typography>
            </Box>
          )}
          <Box marginTop={!showHideSeasonTicketPrice() || !activeSelectedCard ? gutters.large : 0}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              onClick={handleSubmitPayment}
              disabled={
                formData?.errorCode === errorCodes.ABONMAN_YUKLENEMEZ ||
                formData?.errorCode === errorCodes.BU_AY_ABONMAN_YUKLENEMEZ ||
                !activeSelectedCard
              }
              itemID="uploadSubscriptionBtnTest"
            >
              {web_btn_go_to_pay}
            </Button>
          </Box>
        </Box>
        {/* RERENDERI  VE GEREKSIZ SORGUYU ENGELLENMEK İÇİN  */}
        {is3DSecureModalOpen && (
          <Modal3DSecure
            open={is3DSecureModalOpen}
            link={vsPosBelbim3DsecureUrl}
            paymentNo={activePayment}
            close={() => dispatch(close3DSecureModal())}
          />
        )}
      </div>
    </FormErrorWrapper>
  );
});

export default LoadSubscription;
