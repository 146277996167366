import { DashboardLayout, ChevronDarkRightIcon } from 'Components';
import { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { fontSizes } from 'Theme/Variables';
import { onGetAgreementRequest } from 'Stores/Account';
import { useDispatch } from 'react-redux';
import { agreementsTypes, generateLanguageParam } from 'Utils';

import './Agreements.scss';

type AgreementsProps = {};

type AgreementstItemType = {
  title: string;
  id: number;
  onClick: () => void;
  itemID: string;
};

const Agreements: FC<AgreementsProps> = memo(() => {
  const dispatch = useDispatch();

  const {
    web_title_agreements,
    web_clarification_text,
    web_framework_contract,
    web_ibb_clarification_text,
    web_contracts_acikriza,
    web_istanbulkart_terms_of_use,
  } = useLocalization();

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam() }));
  };

  const agreements: AgreementstItemType[] = [
    {
      title: web_clarification_text,
      onClick: () => handleAgreementSelect(agreementsTypes.AYDINLATMA_METNI),
      id: 7,
      itemID: 'seeAydınlatmaMetniBtnTest',
    },
    {
      title: web_framework_contract,
      onClick: () => handleAgreementSelect(agreementsTypes.CERCEVE_SOZLESMESI),
      id: 6,
      itemID: 'seeCerceveSozlesmeBtnTest',
    },
    // {
    //   title: web_ibb_clarification_text,
    //   onClick: () => handleAgreementSelect(agreementsTypes.IBB_AYDINLATMA_METNI),
    //   id: 5,
    //   itemID: 'seeIbbAydinlatmaMetniBtnTest',
    // },
    {
      title: web_contracts_acikriza,
      onClick: () => handleAgreementSelect(agreementsTypes.ACIK_RIZA_METNI),
      id: 4,
      itemID: 'seeAcikRizaMetniBtnTest',
    },
    {
      title: web_istanbulkart_terms_of_use,
      onClick: () => handleAgreementSelect(agreementsTypes.ISTANBULKART_KULLANIM),
      id: 2,
      itemID: 'istanbulKartBtnTest',
    },
  ];

  return (
    <DashboardLayout backButton>
      <div id="agreements">
        <Typography variant="h3" align="center">
          <Box fontWeight={600} className="agreements__title">
            {web_title_agreements}
          </Box>
        </Typography>
        {agreements.map(({ title, onClick, id, itemID }) => (
          <AgreementsItem id={id} title={title} onClick={onClick} key={id} itemID={itemID} />
        ))}
      </div>
    </DashboardLayout>
  );
});

export default Agreements;

const AgreementsItem: FC<AgreementstItemType> = memo(({ title, onClick, itemID }) => {
  return (
    <div className="agreements__item" onClick={onClick} itemID={itemID}>
      <Typography variant="h6" align="center">
        <Box fontWeight="bold" fontSize={fontSizes.regular}>
          {title}
        </Box>
      </Typography>
      <ChevronDarkRightIcon />
    </div>
  );
});
