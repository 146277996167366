import { Box, Grid, Typography } from '@material-ui/core';
import { ChevronDarkRightIcon } from 'Components';
import { useCardName, useCardType, useLocalization } from 'Hooks';
import { CustomerCardApplicationListItemModel } from 'Models';
import { FC } from 'react';

import { routePath } from 'Navigator/routes';
import { setRelationData } from 'Stores/CardTransactions';
import { colors, fontSizes } from 'Theme/Variables';
import { ApprovalStatusEnum } from 'Utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './CardApplicationsItem.scss';

type CardApplicationsItemProps = {
  item: CustomerCardApplicationListItemModel;
};
const CardApplicationsItem: FC<CardApplicationsItemProps> = ({ item }) => {
  const history = useHistory();
  const {
    web_approval_status_c,
    web_approval_status_p,
    web_approval_status_r,
    web_approval_status_s,
    web_approval_status_w,
    web_approval_status_x,
    web_cargo,
    web_cargo_shipment_unsuccessfull_desc,
  } = useLocalization();

  const productName = useCardName(item.ProductCode, item?.SubProductCode, true);
  const dispatch = useDispatch();

  const { cardPath } = useCardType(item.ProductCode);

  const renderStatusInfo = (status: ApprovalStatusEnum) => {
    switch (status) {
      case ApprovalStatusEnum.CHECK:
        return web_approval_status_c;

      case ApprovalStatusEnum.PENDING:
        return web_approval_status_p;

      case ApprovalStatusEnum.WAITING_APPLICATION:
        return web_approval_status_x;

      case ApprovalStatusEnum.WAITING_CREATE:
        return web_approval_status_w;

      case ApprovalStatusEnum.WAS_CREATED:
        return item?.CardApplicationStatus === 6
          ? web_cargo_shipment_unsuccessfull_desc
          : item?.PttPostCode && Number(item?.PttPostCode) > 0
          ? web_cargo
          : web_approval_status_s;

      case ApprovalStatusEnum.REJECT:
        return web_approval_status_r;

      default:
        break;
    }
  };

  const getInfoColor = (inPtt: boolean, status: ApprovalStatusEnum) => {
    if (inPtt && status === ApprovalStatusEnum.WAS_CREATED) {
      return colors.systemGreen;
    } else if ((!inPtt && status === ApprovalStatusEnum.REJECT) || item?.CardApplicationStatus === 6) {
      return colors.errorText;
    } else {
      return colors.checkboxLabel;
    }
  };

  const onClickItem = () => {
    dispatch(
      setRelationData({
        CustodyCustomerNumber: item.IsCustodyApplication ? item?.CustodyCustomerNumber : undefined,
      })
    );
    history.push(routePath.cardApplicationsDetail, item);
  };

  return (
    <Grid item sm={6} xs={12} lg={6} id="cardApplicationsItem">
      <Box onClick={onClickItem} className="cardApplicationsItem" itemID="goCardApplicationDetailBtnTest">
        <img className="cardApplicationsItem__img" src={cardPath} />
        <Box className="cardApplicationsItem__column">
          <Typography variant="h3">
            <Box fontWeight={600}>{productName}</Box>
          </Typography>
          <Typography variant="h3">
            <Box mt={0.5} color={getInfoColor(item.SendToPtt, item.ApprovalStatus)} fontSize={fontSizes.small}>
              {renderStatusInfo(item.ApprovalStatus)}
            </Box>
          </Typography>
        </Box>
        <ChevronDarkRightIcon />
      </Box>
    </Grid>
  );
};
export default CardApplicationsItem;
