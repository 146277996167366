import * as React from 'react';

function ShoppingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="0vzkua68ya"
          d="M5.833 0C4.17 0 2.816 1.353 2.816 3.017v1.81h.805v-1.81c0-1.232.98-2.212 2.212-2.212 1.233 0 2.213.98 2.213 2.212v1.81h.805v-1.81C8.85 1.353 7.498 0 5.833 0zM1.006 5.632L0 15.661h11.667L10.66 5.632H1.006z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H20V20H0z"
                  transform="translate(-480 -174) translate(155 170) translate(317) translate(8 4)"
                />
                <g transform="translate(-480 -174) translate(155 170) translate(317) translate(8 4) translate(4.167 1.667)">
                  <mask id="1v2jz0z3zb" fill="#fff">
                    <use xlinkHref="#0vzkua68ya" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#0vzkua68ya" />
                  <g fill={props.fill ? props.fill : '#30ABAB'} mask="url(#1v2jz0z3zb)">
                    <path d="M0 0H20V20H0z" transform="translate(-4.167 -1.667)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ShoppingIcon;
