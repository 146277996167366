import { cardItemParams } from './CardItem/constant';

export const cardTypes = {
  IKT_STD: 'IKT_STD',
  IKT_MVI: 'IKT_MVI',
  DGK_STD: 'DGK_STD',
};

export const cardTypesColor = {
  IKT_STD: 'red',
  IKT_MVI: 'blue',
  DGK_STD: 'DGK_STD',
};

const ignoredCards = {
  DGK_ORC: 'DGK_ORC',
  DGK_ORT: 'DGK_ORT',
  DGK_STD: 'DGK_STD',
};

export const findDigitalCards = (cards: cardItemParams[]) => {
  const filteredCards = cards?.filter(
    (item) =>
      item.ProductCode === ignoredCards.DGK_ORC ||
      item.ProductCode === ignoredCards.DGK_ORT ||
      item.ProductCode === ignoredCards.DGK_STD
  );
  return filteredCards;
};

export const filterDigitalCards = (cards: cardItemParams[]) => {
  const filteredCards = cards?.filter(
    (item) =>
      item.ProductCode !== ignoredCards.DGK_ORC &&
      item.ProductCode !== ignoredCards.DGK_ORT &&
      item.ProductCode !== ignoredCards.DGK_STD
  );
  return filteredCards;
};

export const cardOwnerType = {
  user: 'user',
  other: 'other',
};
