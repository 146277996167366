import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters, colors } from 'Theme/Variables';
import { AlertIcon } from 'Components';
import { get } from 'lodash';
import { useLocalization } from 'Hooks';
import './FormError.scss';

type FormErrorProps = { cmsCode: string; desc?: string };

const FormError: FC<FormErrorProps> = ({ cmsCode, desc }) => {
  const cmsData = useLocalization();
  const errorText: string = get(cmsData, cmsCode);

  return (
    <Box id="formError">
      <Box display="flex" alignItems="center">
        <AlertIcon width={32} />
        <Typography variant="body1" align="center" component="span">
          <Box fontSize={fontSizes.regular} fontWeight="bold" color={colors.toastErrorText} paddingLeft={gutters.small}>
            {errorText || cmsCode}
          </Box>
        </Typography>
      </Box>
      {!!desc && (
        <Box color={colors.checkboxLabel} paddingLeft={gutters.small} display="flex">
          <Box width={32} />
          <Typography variant="body1" align="center" component="span">
            {desc}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormError;
