import { FC, SetStateAction } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { CloseIcon } from 'Components/Svg';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { setDiscoverIsDirectionEnable, setDiscoverSelectedPin } from 'Stores/Maps';
import './MapPopup.scss';

type MapPopupProps = {
  title?: string;
  desc?: string;
  distance?: string;
  reset?: any;
};
const MapPopup: FC<MapPopupProps> = ({ title, desc, distance, reset }) => {
  const dispatch = useDispatch();
  const { web_travel_directions } = useLocalization();
  return (
    <div id="mapPopup">
      <CloseIcon onClick={() => reset() || dispatch(setDiscoverSelectedPin(undefined))} />
      <Box className="mapPopup">
        <Box className="mapPopup__first-column">
          <Typography variant="h3">
            <Box mt={gutters.small} fontWeight={600} color={colors.secondary} fontSize={fontSizes.small}>
              {title}
            </Box>
          </Typography>
          <Typography variant="h3">
            <Box mt={gutters.small} color={colors.checkboxLabel} fontSize="13px">
              {desc}
            </Box>
          </Typography>
          <Typography variant="h3">
            <Box color={colors.checkboxLabel} fontSize="13px" mt={gutters.small}>
              {distance ? `${distance} km` : ''}
            </Box>
          </Typography>
        </Box>
        <Box className="mapPopup__second-column">
          <Typography variant="h3">
            <Box
              mt={2}
              onClick={() => dispatch(setDiscoverIsDirectionEnable(true))}
              className="mapPopup__second-column__link"
              fontSize="13px"
              color={colors.primary}
            >
              {web_travel_directions}
            </Box>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default MapPopup;
