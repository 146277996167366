import { FC, useEffect } from 'react';

import './TooltipCustomCenter.scss';

type TooltipCustomCenterProps = {
  title: string;
  onClose: Function;
  show: boolean;
};

const TooltipCustomCenter: FC<TooltipCustomCenterProps> = ({ title, onClose, show }) => {
  useEffect(() => {
    show &&
      setTimeout(() => {
        onClose();
      }, 3000);
  }, []);

  return <div id="custom-tooltip">{title}</div>;
};

export default TooltipCustomCenter;
