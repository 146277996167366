import React, { FunctionComponent, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { MaskedTextField } from 'Components';
import { FormikProps } from 'formik';
import { useErrorMessage, useLocalization } from 'Hooks';
import { gutters } from 'Theme/Variables';
import { disableCopyPaste } from 'Utils';
import { userTypes } from 'Views/ForgotPassword/ForgotPassword';
import { useLocation } from 'react-router';
import { routePath } from 'Navigator/routes';

type PasswordFormInputs = {
  password: string;
  rePassword: string;
};

type PasswordFormProps = {
  formikProps: FormikProps<PasswordFormInputs & any>;
  userType?: string;
  notShowTitle?: boolean;
};

const PasswordForm: FunctionComponent<PasswordFormProps> = ({ formikProps, userType, notShowTitle }) => {
  const { pathname } = useLocation();
  const {
    handleChange,
    values: { password, rePassword },
    errors: { password: errorPassword, rePassword: errorRePassword },
    touched: { password: touchedPassword, rePassword: touchedRePassword },
    setTouched,
    handleBlur,
  } = formikProps as FormikProps<PasswordFormInputs>;
  const { getError, getErrorMessage } = useErrorMessage();
  const { web_sifre_title, web_plc_password1, web_plc_password2, web_title_new_password_screen } = useLocalization();
  useEffect(() => {
    setTouched({});
    // eslint-disable-next-line
  }, []);
  const defaultProps = {
    mask: '999999',
    passwordInput: true,
    onCopy: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => disableCopyPaste(e),
    onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => disableCopyPaste(e),
    onChange: handleChange,
  };

  return (
    <>
      {!notShowTitle && (
        <Typography variant="h5" align="center">
          <Box fontWeight={600} marginBottom={gutters.big}>
            {userType === userTypes.waitingForSetPassword || pathname === routePath.signup
              ? web_sifre_title
              : web_title_new_password_screen}
          </Box>
        </Typography>
      )}
      <Box marginBottom={gutters.regular}>
        <MaskedTextField
          name="password"
          label={web_plc_password1}
          value={password}
          onBlur={handleBlur}
          error={getError(errorPassword, touchedPassword)}
          helperText={getErrorMessage(errorPassword, touchedPassword)}
          itemID="passwordInputTest"
          {...defaultProps}
        />
      </Box>
      <Box marginBottom={gutters.big}>
        <MaskedTextField
          name="rePassword"
          label={web_plc_password2}
          value={rePassword}
          onBlur={handleBlur}
          error={getError(errorRePassword, touchedRePassword)}
          helperText={getErrorMessage(errorRePassword, touchedRePassword)}
          itemID="passwordInputTest"
          {...defaultProps}
        />
      </Box>
    </>
  );
};

export default PasswordForm;
