import { FC } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography, Dialog, Divider } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { CloseIcon } from 'Components';
import { useSelector } from 'react-redux';
import moment from 'moment';

import './TransactionDetail.scss';
import { balanceTransferStore } from 'Stores/BalanceTransfer';

type TransactionDetailProps = {
  isVisible: boolean;
  hide: () => void;
  props?: any;
};

const TransactionDetail: FC<TransactionDetailProps> = ({ isVisible, hide }) => {
  const {
    web_title_transaction_popup,
    web_transaction_popup_transaction_type,
    web_transaction_popup_transaction_fee,
    web_transaction_popup_transaction_date,
    digital_card_to_physical_card_money_transfer_transaction_type_balance,
    web_transaction_detail_popup_transaction_time,
  } = useLocalization();

  const balanceTransferData = useSelector(balanceTransferStore);

  const amount = balanceTransferData.Amount;
  const transactions = [
    {
      title: web_transaction_popup_transaction_type,
      desc: digital_card_to_physical_card_money_transfer_transaction_type_balance,
    },
    {
      title: web_transaction_popup_transaction_date,
      desc: moment(new Date(balanceTransferData.InsertDate)).format('DD.MM.YYYY'),
    },
    {
      title: web_transaction_detail_popup_transaction_time,
      desc: moment(new Date(balanceTransferData.InsertDate)).format('HH:mm:ss'),
    },
    {
      title: web_transaction_popup_transaction_fee,
      desc: `${parseFloat(amount).toFixed(2).replace('.', ',')} TL`,
    },
  ];

  return (
    <Dialog open={isVisible} onClose={hide}>
      <div className="dialogContainer ">
        <Box id="transactionDetail">
          <CloseIcon onClick={() => hide()} />
          <Typography variant="h3" align="center">
            <Box fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.large}>
              {web_title_transaction_popup}
            </Box>
          </Typography>
        </Box>
        <Box>
          <Divider />
          {transactions.map((item, index) => (
            <Box key={index}>
              <Box padding={fontSizes.regular}>
                <Typography variant="body1">
                  <Box className="transactionDetail__itemTitle">{item?.title}</Box>
                </Typography>
                <Typography variant="h3">
                  <Box fontSize={fontSizes.regular} fontWeight="bold">
                    {item?.desc}
                  </Box>
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </div>
    </Dialog>
  );
};

export default TransactionDetail;
