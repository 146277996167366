import { FC, useCallback } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { MailIconBlue, PhoneCircle, LocationBlue } from 'Components';
import { SocialMedia } from '../SocialMedia';
import Items, { ItemsType } from '../Items/Items';
import { useHistory, useLocation } from 'react-router-dom';
import { routePath } from 'Navigator/routes';
import { fontSizes } from 'Theme/Variables';
import { isMobile } from 'react-device-detect';

import './ContactWrapper.scss';

type ContactWrapperType = {};

const ContactWrapper: FC<ContactWrapperType> = () => {
  const {
    web_title_call_153,
    web_desc_call_153,
    web_title_write_to_us,
    web_desc_write_to_us,
    web_title_ibm_points,
    web_desc_ibm_points,
    web_title_contact_us,
    web_title_contact_us_social_media,
  } = useLocalization();

  const location = useLocation();
  const history = useHistory();

  const onSendMail = useCallback(() => {
    window.open(`mailto:${web_desc_write_to_us}`);
  }, []);

  const call153 = () => {
    window.open('tel:153');
  };

  const data: ItemsType[] = [
    {
      title: web_title_call_153,
      subTitle: web_desc_call_153,
      isNext: false,
      Icon: PhoneCircle,
      func: isMobile ? call153 : undefined,
      itemID: 'call153BtnTest',
    },
    {
      title: web_title_write_to_us,
      subTitle: web_desc_write_to_us,
      isNext: true,
      Icon: MailIconBlue,
      func: onSendMail,
      itemID: 'writeUsBtnTest',
    },
    {
      title: web_title_ibm_points,
      subTitle: web_desc_ibm_points,
      isNext: true,
      Icon: LocationBlue,
      func: () => {
        if (location.pathname === routePath.contact) {
          history.push(routePath.ibmPoints);
        } else {
          history.push(routePath.welcomeIbmPoints);
        }
      },
      itemID: 'ibmPointsBtnTest',
    },
  ];

  return (
    <div id="contactWrapper">
      <Typography variant="h3" align="center">
        <Box
          className="contactWrapper__title"
          fontSize={location.pathname === routePath.contact ? fontSizes.regularBig : fontSizes.big}
        >
          {web_title_contact_us}
        </Box>
      </Typography>
      {data.map((e: ItemsType, index: number) => (
        <Items key={index.toString()} {...e} firstItem={index === 0} lastItem={index === data.length - 1} />
      ))}
      <SocialMedia title={web_title_contact_us_social_media} />
    </div>
  );
};

export default ContactWrapper;
