import { FC, memo } from 'react';
import { CloseIcon, Map } from 'Components';
import { AccountState, CampaignItem, storeAccount } from 'Stores/Account';
import { useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
type CampaignMapProps = {
  hide: () => void;
  isVisible: boolean;
};
const CampaignMap: FC<CampaignMapProps> = memo(({ hide, isVisible }) => {
  const accountState: AccountState = useSelector(storeAccount);
  const userActiveCampaign: CampaignItem | undefined = accountState.activeCampaign;

  const lat = userActiveCampaign?.Location?.Latitude ? userActiveCampaign?.Location?.Latitude : '';
  const lang = userActiveCampaign?.Location?.Longitude ? userActiveCampaign?.Location?.Longitude : '';

  return (
    <Dialog id="dialogWithActions" open={isVisible} onClose={hide} keepMounted={false}>
      <div className="dialogContainer">
        <CloseIcon onClick={() => hide()} />
        <Map />
      </div>
    </Dialog>
  );
});
export default CampaignMap;
