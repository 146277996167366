import { FC, Fragment } from 'react';
import { DashboardLayout, MultiStepProgress, StepWrapper } from 'Components';
import { useCardName, useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { RouteComponentProps, useHistory, StaticContext } from 'react-router';

import './CardApplicationsDetail.scss';
import { CustomerCardApplicationListItemModel } from 'Models';
import { useState } from 'react';
import { gutters } from 'Theme/Variables';
import { CardApplicationInformation } from './CardApplicationInformation';
import { useEffect } from 'react';
import { UserState, userStore } from 'Stores/User';
import {
  CardTransactionsState,
  onGetActiveApplicationDetailRequest,
  onOnlineApplicationCardRequest,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { MapsState, mapStore, onGetApplicationCenterListRequest } from 'Stores/Maps';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalRejectEnum, ApprovalStatusEnum } from 'Utils';
import { CardApplicationRejectImage } from './CardApplicationRejectImage';
import { onAddApplicationBodyData } from 'Config';
import { routePath } from 'Navigator/routes';
import {
  clearApplicationData,
  getProductPriceRequest,
  incStep,
  setActiveCard,
  setApplicationData,
  setCardApplicationStatus,
  setDeliveryId,
  setUpdateApplicationId,
} from 'Stores/CardApplication';

type CardApplicationsDetailProps = RouteComponentProps<{}, StaticContext, CustomerCardApplicationListItemModel>;

const CardApplicationsDetail: FC<CardApplicationsDetailProps> = ({ location: { state } }) => {
  const item: CustomerCardApplicationListItemModel = state;

  const dispatch = useDispatch();
  const history = useHistory();
  const { CustodyCustomerNumber } = useSelector(storeCardTransactions);

  const [step, setStep] = useState<number>(1);
  const [isChangePhoto, setIsChangePhoto] = useState<boolean>(false);

  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const mapData: MapsState = useSelector(mapStore);
  const { applicationCenterList } = mapData;

  const { customerNumber, sessionToken } = userData;
  const { activeCardApplicationDetail, istanbulCardTypeList } = cardData;
  const productName = useCardName(item.ProductCode, item.SubProductCode, true);

  const {
    web_card_applications_detail_title,
    web_card_applications_progressbar1,
    web_card_applications_progressbar2,
    web_card_applications_progressbar3,
    web_up_application,
    web_card_application_header1,
    web_card_application_header2,
    web_card_application_header3,
  } = useLocalization();

  const liveTitles = [
    web_card_applications_progressbar1,
    web_card_applications_progressbar2,
    web_card_applications_progressbar3,
  ];

  const getTitle = (): string => {
    switch (step) {
      case 1:
        return web_card_application_header1;
      case 2:
        return web_card_application_header2;
      case 3:
        return web_card_application_header3;
      default:
        return '';
    }
  };

  const checkApprovelStatusForPTT = () => {
    const status = activeCardApplicationDetail?.ApprovalStatus;
    const isSendToPTT = Number(activeCardApplicationDetail?.PttPostCode) > 0;
    if (status === ApprovalStatusEnum.WAS_CREATED && isSendToPTT) {
      setStep(3);
    }
  };

  const checkIsWaitingStatus = () => {
    const status = activeCardApplicationDetail?.ApprovalStatus;
    const isSendToPTT = Number(activeCardApplicationDetail?.PttPostCode) > 0;

    switch (status) {
      case ApprovalStatusEnum.WAITING_CREATE:
      case ApprovalStatusEnum.WAS_CREATED:
        if (!isSendToPTT || item.CardApplicationStatus === 6) {
          setStep(2);
        }
        break;
      case ApprovalStatusEnum.PENDING:
      case ApprovalStatusEnum.CHECK:
        setStep(1);
        break;
      case ApprovalStatusEnum.WAITING_APPLICATION:
        setStep(1);
        break;
      default:
        break;
    }
  };

  const isApplicationRejectImage = (): boolean => {
    const isPayment = activeCardApplicationDetail?.ReceiptNumber !== null;
    const isReject = activeCardApplicationDetail?.ApprovalStatus === ApprovalStatusEnum.REJECT;
    const isRejectForImage =
      Number(activeCardApplicationDetail?.RejectReasonCode!) === ApprovalRejectEnum.INFORMATION_ERROR;
    return isPayment && isReject && isRejectForImage;
  };

  useEffect(() => {
    if (activeCardApplicationDetail) {
      checkApprovelStatusForPTT();
      checkIsWaitingStatus();
    }
  }, [activeCardApplicationDetail]);

  useEffect(() => {
    dispatch(
      onOnlineApplicationCardRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      onGetActiveApplicationDetailRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        ProductCode: item.ProductCode,
        ApplicationId: item.ApplicationId,
      })
    );
    dispatch(onGetApplicationCenterListRequest({ CustomerNumber: customerNumber }));
    dispatch(
      getProductPriceRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        ProductCode: item.ProductCode,
        CustodyCustomerNumber: item.IsCustodyApplication ? CustodyCustomerNumber : undefined,
      })
    );
  }, []);

  const incStepOperation = () => {
    if (
      activeCardApplicationDetail?.ProductCode === 'IKT_ORC' ||
      activeCardApplicationDetail?.ProductCode === 'IKT_ORT'
    ) {
      dispatch(incStep());
      dispatch(incStep());
      dispatch(incStep());
    } else {
      if (activeCardApplicationDetail?.ProductCode === 'IKT_IKT') {
        return;
      }
      dispatch(incStep());
    }
  };

  const updateApplication = () => {
    const activeApplicationCard = istanbulCardTypeList?.find(
      (item) => item.ProductCode === activeCardApplicationDetail?.ProductCode && item.ProductName === productName
    );
    const printTypes = '1, 3, 4, 5';
    const hasPhoto = printTypes.includes(String(activeApplicationCard?.CardPrintType));

    const updateData: onAddApplicationBodyData = {
      AddressDetail: {
        AddressDetail: activeCardApplicationDetail?.Address.AddressDetail,
        AddressType: activeCardApplicationDetail?.Address.AddressType,
        City: activeCardApplicationDetail?.Address.City,
        CityCode: activeCardApplicationDetail?.Address.CityCode,
        CountryCode: activeCardApplicationDetail?.Address.CountryCode,
        District: activeCardApplicationDetail?.Address.District,
        PostalCode: activeCardApplicationDetail?.Address.PostalCode,
        Street: activeCardApplicationDetail?.Address.Street,
        Town: activeCardApplicationDetail?.Address.Town,
        TownCode: activeCardApplicationDetail?.Address.TownCode,
      },
      ApplicationCenterId: activeCardApplicationDetail?.ApplicationCenterId,
      ApplicationReasonId: activeCardApplicationDetail?.ApplicationReasonId,
      CustomerNumber: customerNumber,
      DocumentData: hasPhoto
        ? `data:image/${activeCardApplicationDetail?.MimeType};charset=utf-8;base64,${activeCardApplicationDetail?.PhotoBytes}`
        : '',
      FileName: activeCardApplicationDetail?.FileName,
      IsFree: false,
      MimeType: activeCardApplicationDetail?.MimeType,
      OrganizationId: activeCardApplicationDetail?.OrganizationId,
      OrganizationTypeId: activeCardApplicationDetail?.OrganizationTypeId,
      ProductCode: activeCardApplicationDetail?.ProductCode,
      ReceiptNumber: activeCardApplicationDetail?.ReceiptNumber,
      ReceiptDate: activeCardApplicationDetail?.ReceiptDate,
      SessionToken: sessionToken,
      ShippingFee: activeCardApplicationDetail?.ShippingFee,
      SubProductCode: '',
      ShippingLocationDetailId: activeCardApplicationDetail?.ShippingLocationDetailId,
    };
    dispatch(clearApplicationData({ withoutCard: false }));
    dispatch(setActiveCard(activeApplicationCard!));
    dispatch(setApplicationData(updateData));
    dispatch(setUpdateApplicationId(activeCardApplicationDetail?.ApplicationId!));
    dispatch(setCardApplicationStatus(item.CardApplicationStatus));
    incStepOperation();
    history.push(routePath.cardApplicationSteps);
  };

  const getAdress = () => {
    if (activeCardApplicationDetail?.ApplicationCenterId === 0) {
      return activeCardApplicationDetail?.Address?.Text.toString().toLocaleLowerCase('tr-TR');
    } else {
      const applicationCenter = applicationCenterList?.find(
        (item) => item.ApplicationCenterId === activeCardApplicationDetail?.ApplicationCenterId
      );
      return `${applicationCenter?.ApplicationCenterName}/${applicationCenter?.Description}`
        .toString()
        .toLocaleLowerCase('tr-TR');
    }
  };

  return (
    <DashboardLayout backButton backAction={() => (isChangePhoto ? setIsChangePhoto(false) : history.goBack())}>
      <Fragment>
        <div id="cardApplicationsDetail">
          {!isChangePhoto && (
            <Typography variant="h3" align="center">
              <Box fontWeight={600}>{web_card_applications_detail_title} </Box>
            </Typography>
          )}
          <Box mt={gutters.big} />
          {!isApplicationRejectImage() && (
            <StepWrapper noStyle>
              <div className="cardApplicationsDetail__stepper">
                <MultiStepProgress titles={liveTitles} activeStep={step} />
              </div>
              {step >= 1 && activeCardApplicationDetail && (
                <CardApplicationInformation
                  isShipment={step === 3}
                  title={getTitle()}
                  item={activeCardApplicationDetail}
                  address={getAdress()}
                  updateApplication={updateApplication}
                />
              )}
            </StepWrapper>
          )}
          {isApplicationRejectImage() && activeCardApplicationDetail && (
            <CardApplicationRejectImage
              isChangePhoto={isChangePhoto}
              setIsChangePhoto={setIsChangePhoto}
              item={activeCardApplicationDetail}
              address={getAdress()}
            />
          )}
          {activeCardApplicationDetail?.ReceiptDate === null && (
            <Box
              onClick={updateApplication}
              mt={gutters.big}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button style={{ maxWidth: 550 }} variant="contained" color="secondary" fullWidth>
                {web_up_application}
              </Button>
            </Box>
          )}
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default CardApplicationsDetail;
