import { spaceRemover } from 'Utils';
import { AddCardSchemaInputs } from 'Validations';

export const mbSaveRegisterCardWithSeriNoNormalize = (
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { seriNo, cardName } = values;
  return {
    ClientKey: 'MobileClient',
    ClientPass: 'MobileClient',
    SessionToken: sessionToken,
    CustomerNumber: customerNumber,
    MifareId: seriNo,
    NickName: cardName,
  };
};

export const mbSaveRegisterCardWithCardNoNormalize = (
  values: AddCardSchemaInputs,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { cardNumber, cardName } = values;
  return {
    ClientKey: 'MobileClient',
    ClientPass: 'MobileClient',
    SessionToken: sessionToken,
    CustomerNumber: customerNumber,
    MifareId: spaceRemover(cardNumber),
    NickName: cardName,
  };
};

export const saveCardBelongsToSomeoneElseNormalize = (
  tempCardInfo: any,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { seriNo, cardName } = tempCardInfo;

  return {
    ClientKey: 'MobileClient',
    ClientPass: 'MobileClient',
    SessionToken: sessionToken,
    CustomerNumber: customerNumber,
    MifareId: seriNo,
    NickName: cardName,
  };
};

export const saveCardAfterTransactionsNormalize = (
  tempCardInfo: any,
  customerNumber?: number,
  sessionToken?: string
) => {
  const { seriNo, cardName, cardNumber } = tempCardInfo;

  return {
    ClientKey: 'MobileClient',
    ClientPass: 'MobileClient',
    SessionToken: sessionToken,
    CustomerNumber: customerNumber,
    MifareId: cardNumber ? cardNumber : seriNo,
    NickName: cardName,
  };
};
