import { BigGrayPeopleIcon, Container } from 'Components';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userStore } from 'Stores/User/slices';
import { get } from 'lodash';
import { FC, memo } from 'react';

import HeaderBgPath from 'Assets/Icons/heart2.svg';

import './MyAccountHeader.scss';

type MyAccountHeaderProps = {};

const MyAccountHeader: FC<MyAccountHeaderProps> = memo(() => {
  const userData = useSelector(userStore);

  const name = get(userData, 'name');
  const surname = get(userData, 'surname');
  return (
    <div id="myAccountHeader">
      <div className="myAccountHeader__bg" style={{ backgroundImage: `url(${HeaderBgPath})` }}>
        <Container>
          <Box display="flex" alignItems="center">
            <div className="myAccountHeader__profile">
              <BigGrayPeopleIcon />
            </div>
            <Box className="myAccountHeader__user--wrapper">
              <Box className="myAccountHeader__user--info">{name?.toLocaleLowerCase('tr-TR')}</Box>
              <Box className="myAccountHeader__user--info">{surname?.toLocaleLowerCase('tr-TR')}</Box>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
});

export default MyAccountHeader;
