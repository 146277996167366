import React, { FC, useEffect, useState } from 'react';
import { Dialog, TextField, DialogContent, DialogTitle } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';
import { turkishCharacterNormalize } from 'Utils';
import { useSelector } from 'react-redux';

import './DialogStreet.scss';
import { AccountState, storeAccount } from 'Stores/Account';

export type streetItemProp = {
  kayitNo: number;
  csbmAdi: '101.';
};

type DialogStreetProps = {
  hide: () => void;
  isOpen: boolean;
  selectedValue?: string;
  setSelectedValue: Function;
};

const DialogStreet: FC<DialogStreetProps> = ({ hide, isOpen, selectedValue, setSelectedValue }) => {
  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const lowerCaseSearchKey = searchKey.toLocaleLowerCase();

  const accountData: AccountState = useSelector(storeAccount);

  const streets: streetItemProp[] | undefined = accountData?.streetList;

  const itemClickHandle = (streetItem: streetItemProp) => {
    setSelectedValue(streetItem);
    hide();
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogStreet" onClose={onClose} scroll="paper" keepMounted={false} open={isOpen}>
      <DialogTitle className="dialogStreet-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogStreet-container">
          <JobBlock
            streetItems={streets}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={lowerCaseSearchKey}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const JobBlock: FC<{
  streetItems: streetItemProp[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ streetItems, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogStreet-container__list">
      {!!streetItems &&
        streetItems.length > 0 &&
        streetItems
          .filter((item: streetItemProp) => {
            return (
              !!item?.csbmAdi &&
              turkishCharacterNormalize(item?.csbmAdi).includes(turkishCharacterNormalize(lowerCaseSearchKey))
            );
          })
          .map((item: streetItemProp, index) => (
            <Item
              key={index}
              title={item.csbmAdi}
              isSelected={item.csbmAdi.toLocaleLowerCase('tr-TR') === selectedValue}
              onClick={() => itemClickHandle(item)}
            />
          ))}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseAdressStreetBtnTest">
      <div
        className={classNames({
          'dialogStreet-container__list-item': true,
          'dialogStreet-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title.toLocaleLowerCase('tr-TR')}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default DialogStreet;
