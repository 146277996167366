import { Fragment, FC, useEffect, memo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DashboardLayout } from 'Components';
import { WarnAndTips } from './WarnsAndTips';
import { SpecialOpportunity } from './SpecialOpportunity';
import { NewsAndCampaigns } from './NewsAndCampaigns';
import { useDispatch, useSelector } from 'react-redux';
import { onGetCardListRequest } from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';
import { MasterpassServicess, MasterPassTokenEnum } from 'Utils';
import { getBinListRequest, onGetCreditCardListFail, onGetMasterpassTokenRequest } from 'Stores/CreditCardTransactions';
import { checkMasterPassAccountNormalize } from 'Normalize';
import {
  AccountState,
  getCampaignsRequest,
  getCityListRequest,
  getNewsRequest,
  onGetCustomerInfoRequest,
  storeAccount,
} from 'Stores/Account';
import { useLocalization } from 'Hooks';
import { localStorageKeys } from 'Config';

import './Home.scss';
import { AppState, onInıtDataStore, setUpdateAgreementRequired, storeApp } from 'Stores/App';
import { applicationStore } from 'Stores/CardApplication';
import UpdateAgreements from 'Views/UpdateAgreements/UpdateAgreements';

export type AgreementListProps = {
  AgreementType: number;
  AgreementVersion: number;
  Required: boolean;
  ReadOnly: boolean;
};

type HomeParams = {};
type HomeProps = RouteComponentProps<HomeParams>;

const Home: FC<HomeProps> = memo(() => {
  const { web_warning_visible_all } = useLocalization();
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const onInıtData = useSelector(onInıtDataStore);
  const appState: AppState = useSelector(storeApp);
  const accountState: AccountState = useSelector(storeAccount);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const userCampaigns = accountState?.campaigns;
  const userNews = accountState?.news;
  const updateRequired = appState.agreementUpdateRequired;

  const language = localStorage.getItem(localStorageKeys.i18nextLng);
  const languageId = language === 'en' ? '1' : '2';

  const checkAgreementStatus = () => {
    const userAcceptedAgreementList = userData.currentAgreementList;
    const agreementList: AgreementListProps[] = onInıtData?.AgreementList;

    const isAcceptedBefore = userAcceptedAgreementList?.some((e: any) => e.AgreementType === 7);
    const newVersion = agreementList?.find((e) => e.AgreementType === 7)?.AgreementVersion;
    const acceptedVersion7 = userAcceptedAgreementList?.find((e: any) => e.AgreementType === 7)?.AgreementVersion;

    if (!isAcceptedBefore || acceptedVersion7 !== newVersion) {
      dispatch(setUpdateAgreementRequired(true));
    } else {
      dispatch(setUpdateAgreementRequired(false));
    }
  };

  useEffect(() => {
    checkAgreementStatus();
  }, [userData.currentAgreementList]);

  useEffect(() => {
    dispatch(
      onGetMasterpassTokenRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        TokenType: MasterPassTokenEnum.CHECK_MASTERPASSS,
        request: {
          data: checkMasterPassAccountNormalize(),
        },
      })
    );
    dispatch(
      onGetCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      getBinListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      getCampaignsRequest({
        LanguageId: languageId,
        Token: sessionToken,
        CardId: '18',
      })
    );
    dispatch(getNewsRequest());
    dispatch(
      getCityListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
  }, []);

  return (
    <Fragment>
      <DashboardLayout>
        <Fragment>
          {updateRequired && <UpdateAgreements />}
          {/*   <SpecialOpportunity /> */}
          {!!web_warning_visible_all && !updateRequired && <WarnAndTips />}
          {((userCampaigns && userCampaigns?.length > 0) || (userNews && userNews?.length > 0)) && !updateRequired && (
            <NewsAndCampaigns />
          )}
        </Fragment>
      </DashboardLayout>
    </Fragment>
  );
});
export default Home;
