import * as React from 'react';

function AlertBlue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <defs>
        <path
          id="pz1e0ga05a"
          d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C15.196 1.054 12.652 0 10 0zm0 15c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm1-4c0 .552-.448 1-1 1s-1-.448-1-1V6c0-.552.448-1 1-1s1 .448 1 1v5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-933 -561) translate(915 536) translate(18.049 11.445) translate(0 14)"
                />
                <g transform="translate(-933 -561) translate(915 536) translate(18.049 11.445) translate(0 14) translate(2 2)">
                  <mask id="gahks36wcb" fill="#fff">
                    <use xlinkHref="#pz1e0ga05a" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#pz1e0ga05a" />
                  <g fill="#55D3FF" mask="url(#gahks36wcb)">
                    <path d="M0 0H24V24H0z" transform="translate(-2 -2)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AlertBlue;
