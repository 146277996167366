import * as React from 'react';

function MenuBarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || 30}
      height={props.width || 30}
      viewBox="0 0 30 30"
      {...props}
    >
      <defs>
        <path
          id="prefix__a"
          d="M21.967 12.917a.95.95 0 01.95.95v.6a.95.95 0 01-.95.95h-20.6a.95.95 0 01-.95-.95v-.6a.95.95 0 01.95-.95h20.6zm0-6.25a.95.95 0 01.95.95v.6a.95.95 0 01-.95.95h-20.6a.95.95 0 01-.95-.95v-.6a.95.95 0 01.95-.95h20.6zm0-6.25a.95.95 0 01.95.95v.6a.95.95 0 01-.95.95h-20.6a.95.95 0 01-.95-.95v-.6a.95.95 0 01.95-.95h20.6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h30v30H0z" />
        <g transform="translate(3.333 7.083)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#prefix__a" />
          <g fill="#1AAADC" mask="url(#prefix__b)">
            <path d="M-5-8.75h33.333v33.333H-5z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MenuBarIcon;
