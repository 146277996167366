import { axiosHelper } from 'Services/AxiosHelper';
import { onOnlineApplicationCardQueryBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';
import { OnOnlineApplicationCardQueryItemModel } from 'Models';

const command = 'RI.OnOnlineApplicationCardQuery';

const onOnlineApplicationCardQuery = async (
  params: onOnlineApplicationCardQueryBodyData
): Promise<OnOnlineApplicationCardQueryItemModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onOnlineApplicationCardQuery;
