import { FC, Fragment } from 'react';
import { ApplicationEmptyIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import './CardApplicationEmpty.scss';
import { colors, fontSizes, gutters } from 'Theme/Variables';
type CardApplicationEmptyProps = {};
const CardApplicationEmpty: FC<CardApplicationEmptyProps> = ({}) => {
  const { web_there_is_no_application } = useLocalization();
  return (
    <div id="cardApplicationEmpty">
      <Box mt={gutters.regular} />
      <ApplicationEmptyIcon />
      <Typography align="center">
        <Box mt={gutters.regular} fontSize={fontSizes.regular} color={colors.checkboxLabel}>
          {web_there_is_no_application}
        </Box>
      </Typography>
    </div>
  );
};
export default CardApplicationEmpty;
