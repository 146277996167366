import * as React from 'react';

function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="phoneIcon"
      {...props}
    >
      <defs>
        <path
          id="tbenoxmaya"
          d="M16.862 21c-7.65-.01-13.85-6.21-13.86-13.86C3.002 4.854 4.856 3 7.142 3c.233-.002.465.02.693.063.22.033.438.087.648.162.3.106.523.362.585.675l1.233 5.4c.068.298-.02.61-.234.828-.117.126-.126.135-1.233.711.887 1.945 2.442 3.507 4.383 4.401.585-1.116.594-1.125.72-1.242.218-.213.53-.302.828-.234l5.4 1.233c.302.07.547.291.648.585.076.214.133.433.17.657.037.226.055.455.055.684-.035 2.276-1.9 4.097-4.176 4.077z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-320 -1393) translate(0 1299) translate(320 94)">
              <path d="M0 0H24V24H0z" />
              <mask id="vda9xbbd5b" fill="#fff">
                <use xlinkHref="#tbenoxmaya" />
              </mask>
              <g fill="currentColor" mask="url(#vda9xbbd5b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhoneIcon;
