import { Box, Button, Typography } from '@material-ui/core';
import { useCardName, useLocalization } from 'Hooks';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { ApprovalStatusEnum, renderApplicationDate } from 'Utils';
import moment from 'moment';
import { FC } from 'react';
import { InformationRow } from '../InformationRow';
import './CardApplicationInformation.scss';
moment.locale('fr');

type CardApplicationInformationProps = {
  item: any;
  title: string;
  isShipment: boolean;
  address: string;
  updateApplication: any;
};
const CardApplicationInformation: FC<CardApplicationInformationProps> = ({
  item,
  title,
  isShipment,
  address,
  updateApplication,
}) => {
  const {
    web_card_applications_detail_number,
    web_card_applications_detail_date,
    web_card_applications_detail_type,
    web_card_applications_detail_reference,
    web_card_applications_detail_amount,
    web_delivery_location,
    web_card_applications_detail_cargo_btn,
    web_payment_awaited,
  } = useLocalization();

  const cardName = useCardName(item.ProductCode, item.SubProductCode, true);

  const checkBackgroundColor = () => {
    if (
      item.ApprovalStatus === ApprovalStatusEnum.REJECT ||
      item.ApprovalStatus === ApprovalStatusEnum.WAITING_APPLICATION ||
      item.CardApplicationStatus === 6
    ) {
      return colors.errorBg;
    }
    return colors.toastSuccess;
  };

  const checkTextColor = () => {
    if (item.CardApplicationStatus === 6) return colors.errorText;
    switch (item.ApprovalStatus) {
      case ApprovalStatusEnum.REJECT:
      case ApprovalStatusEnum.WAITING_APPLICATION:
        return colors.errorText;
      default:
        return colors.systemGreen;
    }
  };

  const cargoTracking = () => {
    window.open('https://gonderitakip.ptt.gov.tr/Track/Verify?q=' + item.PttPostCode, '_blank');
  };

  return (
    <div id="cardApplicationInformation">
      <Box mt={gutters.big} bgcolor={checkBackgroundColor()} className="cardApplicationInformation__area">
        <div className="cardApplicationInformation__area__row">
          <Typography variant="h3">
            <Box fontWeight={600} color={checkTextColor()}>
              {title}
            </Box>
          </Typography>
          {isShipment && (
            <Typography variant="h3">
              <Box
                onClick={cargoTracking}
                className="cardApplicationInformation__area__row__ship"
                fontSize="13px"
                fontWeight={500}
                color={colors.darkPrimary}
              >
                {web_card_applications_detail_cargo_btn}
              </Box>
            </Typography>
          )}
        </div>
        <Typography>
          <Box mt={gutters.regular} fontSize={fontSizes.small} color={colors.checkboxLabel}>
            {item.ApprovalStatus === ApprovalStatusEnum.WAITING_APPLICATION
              ? web_payment_awaited
              : web_delivery_location}
          </Box>
        </Typography>
        <Typography>
          <Box className="cardApplicationInformation__adress" fontSize={fontSizes.small} color={colors.checkboxLabel}>
            {item.ShippingLocationName ? item.ShippingLocationName : address}
          </Box>
        </Typography>
      </Box>

      {item.CardApplicationStatus === 6 && (
        <Box
          onClick={updateApplication}
          mt={gutters.regular}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button style={{ maxWidth: 550 }} variant="contained" color="secondary" fullWidth>
            Kartı yeniden kargola
          </Button>
        </Box>
      )}

      <Box mt={gutters.regular} />

      <InformationRow topDivider name={web_card_applications_detail_number} desc={item.ApplicationId.toString()} />
      <InformationRow
        topDivider
        name={web_card_applications_detail_date}
        desc={renderApplicationDate(item.ApplicationDate)}
      />
      <InformationRow
        botDivider={item?.ReceiptDate === null}
        topDivider
        name={web_card_applications_detail_type}
        desc={cardName}
      />
      {item?.ReceiptDate !== null && (
        <InformationRow topDivider name={web_card_applications_detail_reference} desc={`${item.ReceiptNumber}`} />
      )}
      {item?.ReceiptDate !== null && (
        <InformationRow
          topDivider
          botDivider
          name={web_card_applications_detail_amount}
          desc={`${Number(item.Amount)} TL`}
        />
      )}
    </div>
  );
};

export default CardApplicationInformation;
