const useLineBreaks = (text: string): JSX.Element[] => {
  return text.split('\\n').map((item) => (
    <>
      {item}
      <br />
    </>
  ));
};

export default useLineBreaks;
