import { FunctionComponent, InputHTMLAttributes, PropsWithChildren, useState } from 'react';
import { InputBaseComponentProps, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { PasswordShowIcon, PasswordHideIcon } from 'Components';

type TextMaskCustomProps = {
  mask: string;
  label: string;
  passwordInput?: boolean;
  innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;
} & InputHTMLAttributes<HTMLInputElement> &
  PropsWithChildren<InputBaseComponentProps>;

const MaskedTextField: FunctionComponent<TextMaskCustomProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const ShowHidePasswordIcon = showPassword ? PasswordHideIcon : PasswordShowIcon;

  const { mask = '', label = '', inputProps, passwordInput, innerRef, inputMode, ...otherProps } = props;

  return (
    <InputMask mask={mask} maskPlaceholder="" {...otherProps}>
      <TextField
        label={label}
        variant="filled"
        fullWidth
        inputProps={{ inputMode: inputMode ? inputMode : 'numeric' }}
        ref={innerRef}
        {...(passwordInput && {
          InputProps: {
            endAdornment: <ShowHidePasswordIcon onClick={() => setShowPassword(!showPassword)} />,
          },
          type: showPassword ? 'text' : 'password',
        })}
      />
    </InputMask>
  );
};

export default MaskedTextField;
