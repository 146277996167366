import { Box } from '@material-ui/core';
import { FC, useEffect } from 'react';

import './Tooltip.scss';

type TooltipProps = {
  title: string;
  onClose: () => void;
};

const Tooltip: FC<TooltipProps> = ({ title, onClose }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 3000);
  }, []);
  return (
    <div id="cardsDetailTooltip">
      <Box>{title}</Box>
    </div>
  );
};

export default Tooltip;
