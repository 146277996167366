import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { IstanbulKartLogo } from 'Components';
import { useWindowDimensions } from 'Hooks';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

import './FullPageWrapper.scss';

type FullPageWrapperProps = {
  children: JSX.Element;
};

const FullPageWrapper: FunctionComponent<FullPageWrapperProps> = ({ children }) => {
  const { height } = useWindowDimensions();
  const history = useHistory();

  return (
    <div id="fullPageWrapper" style={{ minHeight: height }}>
      <Box paddingY={gutters.large}>
        <IstanbulKartLogo onClick={() => history.push(routePath.welcome)} />
      </Box>
      {children}
    </div>
  );
};

export default FullPageWrapper;
