import * as React from 'react';

function PaginationLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="51x11s195a"
          d="M14.293 5.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L10.415 12l5.292 5.293c.36.36.388.928.083 1.32l-.083.094c-.36.36-.928.388-1.32.083l-.094-.083-6-6c-.36-.36-.388-.928-.083-1.32l.083-.094 6-6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-564.000000, -1229.000000) translate(556.000000, 1221.000000) translate(8.000000, 8.000000)">
              <path d="M0 0H24V24H0z" />
              <mask id="8pva2c45nb" fill="#fff">
                <use xlinkHref="#51x11s195a" />
              </mask>
              <g fill="#383C52" mask="url(#8pva2c45nb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PaginationLeft;
