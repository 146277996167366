import { masterPassRegisterNormalize } from 'Normalize';
import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { setFullScreen, showOTPDialog } from 'Stores/Dialog';
import { MasterPassTokenEnum, generateLanguageParam } from 'Utils';
import { useEffect } from 'react';
import { useQuery } from 'Hooks';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';
import { onInıtDataRequest, setLanguage } from 'Stores/App';

const VirtualCardCreate = () => {
  const url = useQuery();

  const language = url.get('language') as string;
  const phoneNumber = url.get('phoneNumber') as string;
  const masterpassToken = url.get('masterpassToken') as string;
  const reqRefNumber = url.get('reqRefNumber') as string;
  const cardNumber = url.get('cardNumber') as string;
  const expiryDate = url.get('expiryDate') as string;
  const cvv = url.get('cvv') as string;
  const cardName = url.get('cardName') as string;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showOTPDialog());
    dispatch(setFullScreen(true));
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language || '');
      dispatch(setLanguage());
      dispatch(onInıtDataRequest(generateLanguageParam()));
    }

    if (cardNumber && expiryDate && cvv && cardName && masterpassToken && phoneNumber && reqRefNumber) {
      dispatch(
        onGetMasterpassForMobileRequest({
          TokenType: MasterPassTokenEnum.REGISTER,
          request: {
            data: masterPassRegisterNormalize(
              '',
              cardNumber,
              expiryDate,
              cvv,
              cardName,
              masterpassToken,
              phoneNumber,
              reqRefNumber
            ),
          },
        })
      );
    }
  }, []);

  return <></>;
};

export default VirtualCardCreate;
