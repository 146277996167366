import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import './MultiStepProgress.scss';
import { TickIcon } from 'Components';
import cx from 'classnames';

type MultiStepProgressProps = {
  titles: string[];
  activeStep: number;
};

const MultiStepProgress: FunctionComponent<MultiStepProgressProps> = ({ titles, activeStep }) => {
  const isMiniSeperator = titles.length > 4;
  const seperatorClassName = cx('multistepProgress-item__separator', { mini: isMiniSeperator });
  return (
    <div id="multistepProgress">
      {titles.map((title, index) => (
        <div className="multistepProgress-item" key={index}>
          <div className="multistepProgress-item__inner">
            <div
              className={classNames({
                'multistepProgress-item__inner-circle': true,
                'multistepProgress-item__inner-circle--onProgress': index === activeStep,
                'multistepProgress-item__inner-circle--done': index < activeStep,
                'multistepProgress-item__inner-circle--next': index > activeStep,
              })}
            >
              {index < activeStep && <TickIcon />}
            </div>
            <Typography variant="subtitle1" align="center">
              {title}
            </Typography>
          </div>
          {index < titles.length - 1 && <div className={seperatorClassName} />}
        </div>
      ))}
    </div>
  );
};

export default MultiStepProgress;
