import { FC, Fragment } from 'react';
import { DashboardLayout, Checkbox, InfoIcon, DialogTerms } from 'Components';
import { useLocalization, useModal } from 'Hooks';
import { Box, Button, Link, Typography } from '@material-ui/core';
import './ExplicitConsentConfirm.scss';
import { gutters } from 'Theme/Variables';
import { agreementsTypes, generateLanguageParam, paranthesisNormalize } from 'Utils';
import { onGetAgreementRequest, onUpdateOnlinePermissionRequest, storeAccount } from 'Stores/Account';
import { useDispatch, useSelector } from 'react-redux';
import { UserState, userStore } from 'Stores/User';
import { useState } from 'react';
import { AppState, storeApp } from 'Stores/App';
import { useEffect } from 'react';
type ExplicitConsentConfirmProps = {};
const ExplicitConsentConfirm: FC<ExplicitConsentConfirmProps> = ({}) => {
  const dispatch = useDispatch();

  const {
    web_account_explicit_consent_text,
    web_btn_accept,
    web_account_explicit_consent_text_info,
    web_contracts_acikriza,
  } = useLocalization();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const accountStore = useSelector(storeAccount);
  const userData: UserState = useSelector(userStore);
  const appData: AppState = useSelector(storeApp);

  const { agreementContent } = accountStore;
  const { currentAgreementList } = userData;
  const { AgreementList } = appData.onInıtData;

  const { isVisible: isVisibleExplicit, show: showExplicit, hide: hideExplicit } = useModal();

  const sessionToken = userData?.sessionToken;
  const customerNumber = userData?.customerNumber;

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  const setPermission = () => {
    const agreement1 = currentAgreementList.find((item: any) => item.AgreementType === 1);
    const agreement2 = currentAgreementList.find((item: any) => item.AgreementType === 2);
    const agreement5 = currentAgreementList.find((item: any) => item.AgreementType === 5);

    dispatch(
      onUpdateOnlinePermissionRequest({
        Language: generateLanguageParam(),
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        OnlinePermissionMessage: {
          1: agreement1 ? AgreementList.find((item: any) => item.AgreementType === 1)?.AgreementVersion : undefined,
          2: agreement2 ? AgreementList.find((item: any) => item.AgreementType === 2)?.AgreementVersion : undefined,
          4: isChecked ? AgreementList.find((item: any) => item.AgreementType === 4)?.AgreementVersion : -1,
          5: agreement5 ? AgreementList.find((item: any) => item.AgreementType === 5)?.AgreementVersion : undefined,
          6: AgreementList.find((item: any) => item.AgreementType === 6)?.AgreementVersion,
        },
      })
    );
  };

  useEffect(() => {
    setIsChecked(currentAgreementList && currentAgreementList.some((item: any) => item.AgreementType === 4));
  }, [currentAgreementList]);

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="explicitConsentConfirm">
          <Typography variant="h3" align="center">
            <Box mb={gutters.large} fontWeight={600}>
              {web_account_explicit_consent_text}
            </Box>
          </Typography>
          <Box
            className="explicitConsentConfirm__checkBorder"
            marginBottom={gutters.small}
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
            itemID="smsPermissionBtnTest"
          >
            <Checkbox
              name="explicit"
              color="primary"
              checked={isChecked}
              label={
                <Typography variant="h6" align="center">
                  <Box fontWeight={600}>{web_account_explicit_consent_text}</Box>
                </Typography>
              }
              marginBottomGutter="0"
            />
          </Box>
          <Box mt={gutters.big} className="flexAlignCenter" />
          <Button onClick={setPermission} fullWidth variant="contained" color="secondary">
            {web_btn_accept}
          </Button>

          <Box mt={gutters.big} className="flexAlignCenter">
            <InfoIcon className="iconRightPadding" />
            <Link
              variant="body1"
              onClick={() => {
                handleAgreementSelect(agreementsTypes.ACIK_RIZA_METNI);
                showExplicit();
              }}
              underline="always"
              color="textSecondary"
              itemID="showSmsPermissionBtnTest"
            >
              {paranthesisNormalize(web_account_explicit_consent_text_info)}
            </Link>
          </Box>
        </div>
        <DialogTerms
          open={isVisibleExplicit}
          hide={hideExplicit}
          headerTextAlign="left"
          isButtonOkay
          title={web_contracts_acikriza}
          content={agreementContent?.AgreementContent.split('61OF61')[0]}
        />
      </Fragment>
    </DashboardLayout>
  );
};
export default ExplicitConsentConfirm;
