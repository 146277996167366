import * as yup from 'yup';
import { dateValidation } from 'Utils';
import { errorMessages } from 'Config';
import { PasswordSchema } from './PasswordSchema';

export const SingupConditionsStepSchema = (condition: boolean) => {
  return yup.object({
    conditionsAccepted: condition
      ? yup.boolean().required(errorMessages.require).oneOf([true], errorMessages.checkboxTrue)
      : yup.boolean(),
  });
};

export const SingupInfoStepSchema = (condition: boolean) => {
  return yup.object({
    name: yup.string().required(errorMessages.require),
    surname: yup.string().required(errorMessages.require),
    mail: yup.string().email(errorMessages.mailFormat),
    termsAccepted: condition ? yup.boolean() : yup.boolean(),
    clarificationAccepted: yup.boolean().required(errorMessages.require).oneOf([true], errorMessages.checkboxTrue),
  });
};

export const SignupContactStepSchema = yup.object({
  viaSms: yup.boolean(),
  viaMail: yup.boolean(),
  mail2: yup
    .string()
    .email(errorMessages.mailFormat)
    .when(['mail', 'viaMail'], {
      is: (mail: string, viaMail: boolean) => (!mail && viaMail ? true : false),
      then: yup.string().email(errorMessages.mailFormat).required(errorMessages.require),
    }),
});

export const SignupIdentityStepSchema = yup.object({
  tcNo: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  birthDate: yup
    .string()
    .required(errorMessages.require)
    .min(10, errorMessages.minBirthDate)
    .test('dateValidation', errorMessages.dateFormat, (value) => dateValidation(value)),
  propertyType: yup.number(),
});

export const SignupSchema = [
  SignupIdentityStepSchema,
  SingupInfoStepSchema,
  SignupContactStepSchema,
  SingupConditionsStepSchema,
  PasswordSchema,
];

export type SignupSchemaInputs = signUpInitialValues;

export const signupSchemaInitialValues: signUpInitialValues = {
  name: '',
  surname: '',
  mail: '',
  mail2: '',
  termsAccepted: false,
  clarificationAccepted: false,
  viaSms: false,
  viaMail: false,
  conditionsAccepted: false,
  tcNo: '',
  birthDate: '',
  password: '',
  rePassword: '',
  propertyType: undefined,
};

export type signUpInitialValues = {
  name: string;
  surname: string;
  mail: string;
  mail2: string;
  termsAccepted: boolean;
  clarificationAccepted: boolean;
  viaSms: boolean;
  viaMail: boolean;
  conditionsAccepted: boolean;
  tcNo: string;
  birthDate: string;
  password: string;
  rePassword: string;
  propertyType: number | undefined;
};
