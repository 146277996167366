import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { AccountState, getTownListRequest, getTownOtherListRequest, storeAccount } from 'Stores/Account';
import { UserState, userStore } from 'Stores/User';
import { turkishCharacterNormalize } from 'Utils';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DialogCity.scss';

export type citiesData = {
  CityId: string;
  CityName: string;
};

type DialogCityProps = {
  hide: () => void;
  isOpen: boolean;
  selectedValue?: any;
  setSelectedValue: Function;
};

const DialogCity: FC<DialogCityProps> = ({ hide, isOpen, selectedValue, setSelectedValue }) => {
  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const customerNumber = userData.customerNumber;
  const sessionToken = userData.sessionToken;

  const accountData: AccountState = useSelector(storeAccount);
  const cities: citiesData[] | undefined = accountData.cityList;

  const itemClickHandle = (cityItem: citiesData) => {
    setSelectedValue(cityItem);
    dispatch(getTownListRequest(cityItem.CityId));
    dispatch(
      getTownOtherListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        CityId: cityItem.CityId,
      })
    );
    hide();
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogCity" onClose={onClose} scroll="paper" keepMounted={false} open={isOpen}>
      <DialogTitle className="dialogCity-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCity-container">
          <CityBlock
            cities={cities}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={searchKey.toLocaleLowerCase()}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CityBlock: FC<{
  cities: citiesData[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ cities, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogCity-container__list" itemID="chooseCityBtnTest">
      {!!cities &&
        cities.length > 0 &&
        cities
          .filter((item: citiesData) => {
            return turkishCharacterNormalize(item.CityName).includes(turkishCharacterNormalize(lowerCaseSearchKey));
          })
          .map((item: citiesData, index) => (
            <Item
              key={index}
              title={item.CityName}
              isSelected={item.CityName === selectedValue}
              onClick={() => itemClickHandle(item)}
            />
          ))}
    </ul>
  );
};

const Item: FC<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseAdressCityBtnTest">
      <div
        className={classNames({
          'dialogCity-container__list-item': true,
          'dialogCity-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default DialogCity;
