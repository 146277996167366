import { PayloadAction } from '@reduxjs/toolkit';
import {
  formNames,
  onGetCardListBodyData,
  onSaveCustomerCardBodyData,
  getRegisteredCardTransactionBodyData,
  saveRegisterCardTransactionBodyData,
  onGetCardTransactionListBodyData,
  InstrunctionListReqBodyData,
  onDeleteCustomerCardByClosedBodyData,
  onRegisterCardTransactionBodyData,
  deleteRegisteredDefaultCardTransactionBodyData,
  setDefaultCardBodyData,
  changeCardNickNameBodyData,
  getSeasonTicketPriceReqBodyData,
  onCheckProductReqBodyData,
  onOnlineApplicationQueryBodyData,
  onOnlineApplicationCardQueryBodyData,
  onChangeRegisterCardNickNameBodyData,
  getCardVisaReqBodyData,
  onCustomerApplicationListBodyData,
  OnGetApplicationCenterListBodyData,
  OnUpdateApplicationBodyData,
} from 'Config';
import {
  MbGetOtherCardListModel,
  OnGetCardListModel,
  OnSaveCustomerCardModel,
  OnGetCardTransactionListModel,
  InstrunctionListReqModel,
  OnCountInstructionListModel,
  OnChangePasswordCustomerReqModel,
  GetSeasonTicketPriceModel,
  OnCheckProductModel,
  OnOnlineApplicationQueryModel,
  OnOnlineApplicationCardQueryModel,
  OnOnlineApplicationCardQueryItemModel,
  ProductPriceModel,
  GetCardVisaModel,
  CustomerCardApplicationListModel,
  ApplicationCenterListModel,
  CardApplicationUpdateModel,
  ApplicationDetailModel,
  OnGetAccountWithCardsModel,
  OnGetAccountWithCardsData,
} from 'Models';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  CardApplicationResponseTypeEnum,
  isBelbimRequestSuccess,
  MasterPassAccountStatusEnum,
  sendEVAMCustomEvent,
} from 'Utils';
import {
  onGetCardListRequest,
  onGetCardListRequestSuccess,
  onGetCardListRequestFail,
  onSaveCustomerCardRequest,
  onSaveCustomerCardSuccess,
  onSaveCustomerCardFail,
  setAddCardStep,
  mbGetOtherCardListRequest,
  mbGetOtherCardListRequestSuccess,
  mbGetOtherCardListRequestFail,
  mbSaveRegisterCardRequest,
  mbSaveRegisterCardSuccess,
  mbSaveRegisterCardFail,
  onGetCardTransactionListRequest,
  onGetCardTransactionListSuccess,
  onGetCardTransactionListFail,
  getInstructionListRequest,
  getInstructionListSuccess,
  getInstructionListFail,
  onDeleteCustomerCardByClosedFail,
  onDeleteCustomerCardByClosedSuccess,
  onDeleteCustomerCardByClosedRequest,
  setDeleteCardStep,
  onRegisterCardTransactionRequest,
  onRegisterCardTransactionSuccess,
  onRegisterCardTransactionFail,
  onDeleteRegisteredCardTransactionRequest,
  onDeleteRegisteredCardTransactionSuccess,
  onDeleteRegisteredCardTransactionFail,
  showUserBelongToSomeoneElseModal,
  closeUserBelongToSomeoneElseModal,
  setActiveSelectedCard,
  setNormalizeCardTransactionStates,
  setIsUserAddingNewCardInTransactions,
  setTempCardInfo,
  showUserAddedCardDetail,
  setCardStateLoading,
  onCountInstructionListRequest,
  onCountInstructionListSuccess,
  onCountInstructionListFail,
  changeCardNickNameRequestSuccess,
  changeCardNickNameRequestError,
  setDefaultCardRequestSuccess,
  setDefaultCardRequestError,
  setDefaultCardRequest,
  changeCardNickNameRequest,
  storeCardTransactions,
  setInActiveCard,
  setIsUserInUploadTLProcess,
  setIsUserInUploadSubscriptionProcess,
  setIsUserInAutomaticInstructionProcess,
  getSeasonTicketLoadableRequest,
  getSeasonTicketLoadableRequestSuccess,
  getSeasonTicketLoadableRequestError,
  setSeasonTicketLoadable,
  onCheckProductRequest,
  onCheckProductSuccess,
  onCheckProductFail,
  setUploadTLStep,
  setAutomaticInstructionStep,
  setUploadSubscriptionStep,
  setLowerLimit,
  onCheckCardIsUploadableSubsRequest,
  onCheckCardIsUploadableSubsSuccess,
  onCheckCardIsUploadableSubsFail,
  onOnlineApplicationRequest,
  onOnlineApplicationCardRequest,
  onOnlineApplicationCardSuccess,
  onOnlineApplicationCardFail,
  openDeletePrivilegedDialog,
  openCantApplyDialog,
  onOnlineApplicationSuccess,
  onOnlineApplicationFail,
  onChangeRegisterCardNickNameRequest,
  getCardVisaRequest,
  getCardVisaSuccess,
  getCardVisaFail,
  setCardVisaDate,
  openCantApplyForeign,
  openAlreadyHave,
  checkApplicationTypeFourRequest,
  checkApplicationTypeFourSuccess,
  checkApplicationTypeFourFail,
  onCustomerApplicationListRequest,
  onCustomerApplicationListSuccess,
  onCustomerApplicationListFail,
  checkUserCardsHasVisaRequest,
  checkUserCardsHasVisaSuccess,
  checkUserCardsHasVisaFail,
  setIsUserHasVisaExpireWarning,
  onGetActiveApplicationDetailSuccess,
  onGetActiveApplicationDetailRequest,
  onGetActiveApplicationDetailFail,
  openOnOnlineApplicationDialog,
  onGetAccountWithCardsSuccess,
  onGetAccountWithCardsFail,
  onGetAccountWithCardsRequest,
} from './slices';
import { setFormError } from 'Stores/Form/slices';
import {
  saveRegisterCard,
  onSaveCustomerCard,
  deleteRegisteredCardTransaction,
  setDefaultCard,
  changeCardNickName,
  onDeleteCustomerCardByClosed,
} from 'Services/Mutations';
import {
  onGetCardTransactionList,
  getInstructionList,
  onGetCardList,
  getRegisteredCardTransaction,
  onRegisterCardTransaction,
  onCountInstructionList,
  getSeasonTicketPrice,
  onCheckProduct,
  onChangeRegisterCardNickName,
  productPrice,
  getCardVisa,
  checkApplicationTypeFour,
  onCustomerApplicationList,
  onUpdateApplication,
  onGetAccountWithCards,
} from 'Services/Queries';
import {
  CreditCardTransactionsState,
  creditCardTransactionsStore,
  onGetCommissionByBinNumberRequest,
  resetCommissionAmount,
  setActiveSelectedCreditCard,
  setLoadAmount,
  setUserTemporaryCreditCard,
} from 'Stores/CreditCardTransactions';
import { UserState, userStore } from 'Stores/User';
import { routePath } from 'Navigator/routes';
import { push, replace } from 'connected-react-router';
import { showToastify } from 'Stores/Toastify';
import { closeInputDialog, show736ErrorDialog } from 'Stores/Dialog';
import { CardTransactionsState } from './types';
import { getCommissionByBinNumberNormalize } from 'Normalize';
import onOnlineApplicationQuery from 'Services/Queries/OnOnlineApplicationQuery';
import onOnlineApplicationCardQuery from 'Services/Queries/onOnlineApplicationCardQuery';
import EVAMSdkEventTypes from 'Utils/EVAMSdkEventTypes';
import moment from 'moment';
import onGetApplicationCenterList from 'Services/Queries/OnGetApplicationCenterList';
import {
  clearApplicationData,
  getProductPriceRequest,
  setActiveCard,
  setActiveShippingPrice,
  setH,
} from 'Stores/CardApplication';
import { setActiveUserAdress } from 'Stores/Account';

// Workers

function* handleOnGetCardListRequestSaga({ payload }: PayloadAction<onGetCardListBodyData>) {
  try {
    const onGetCardListResult: OnGetCardListModel = yield call(() => onGetCardList(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onGetCardListResult);

    if (requestSuccess) {
      yield put(onGetCardListRequestSuccess(onGetCardListResult.data.CardList));
    } else {
      yield put(onGetCardListRequestFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error: any) {
    yield put(onGetCardListRequestFail(error));
  }
}

function* handleCheckUserCardsHasVisaRequestSaga({ payload }: PayloadAction<onGetCardListBodyData>) {
  // Belbim Backendinden dolayı böyle yapılmak zorunda kalındı.Servis sağlamadılar
  try {
    const onGetCardListResult: OnGetCardListModel = yield call(() => onGetCardList(payload));

    const { responseCode, requestSuccess } = isBelbimRequestSuccess(onGetCardListResult);

    if (requestSuccess) {
      // to check if user cards has visa warning , loop for every card and make request
      for (const cardItem of onGetCardListResult.data.CardList) {
        const instructionListResult: InstrunctionListReqModel = yield call(() =>
          getInstructionList({
            CustomerNumber: payload.CustomerNumber,
            SessionToken: payload.SessionToken,
            MifareId: cardItem.MifareId,
            TaskOrInstruction: 0,
          })
        );
        const { requestSuccess, responseCode } = isBelbimRequestSuccess(instructionListResult);
        if (requestSuccess) {
          const cardHasVisaExpire = instructionListResult.data.TaskList.find((item) => item.TaskTypeId === 3);
          if (!!cardHasVisaExpire) {
            const cardInfoHasVisaExpire = onGetCardListResult.data.CardList.find(
              (item) => item.MifareId === cardItem.MifareId
            );
            yield put(setIsUserHasVisaExpireWarning(true));
            yield put(setInActiveCard(cardInfoHasVisaExpire));
            yield put(checkUserCardsHasVisaSuccess());
            return;
          }
        } else {
          yield put(setIsUserHasVisaExpireWarning(false));
          yield put(checkUserCardsHasVisaFail());
          yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
        }
      }
    } else {
      yield put(checkUserCardsHasVisaFail());
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error) {
    yield put(checkUserCardsHasVisaFail());
  }
}

function* handleOnGetTransactionListRequestSaga({ payload }: PayloadAction<onGetCardTransactionListBodyData>) {
  try {
    const onGetTransactionListResult: OnGetCardTransactionListModel = yield call(() =>
      onGetCardTransactionList(payload)
    );
    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onGetTransactionListResult);
    if (requestSuccess) {
      yield put(onGetCardTransactionListSuccess(onGetTransactionListResult));
      // yield put(onSetActiveCardTransactionPageCount(onGetTransactionListResult.data.TotalPageCount));
    } else {
      yield put(onGetCardTransactionListFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error: any) {
    yield put(onGetCardTransactionListFail(error));
  }
}

function* handleOnRegisterCardTransactionRequestSaga({ payload }: PayloadAction<onRegisterCardTransactionBodyData>) {
  try {
    const onReggisterCardTransactionResult: OnGetCardTransactionListModel = yield call(() =>
      onRegisterCardTransaction(payload)
    );

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onReggisterCardTransactionResult);
    if (requestSuccess) {
      yield put(onRegisterCardTransactionSuccess(onReggisterCardTransactionResult));
    } else {
      yield put(onRegisterCardTransactionFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error: any) {
    yield put(onRegisterCardTransactionFail(error));
  }
}

function* handleInstructionListRequestSaga({ payload }: PayloadAction<InstrunctionListReqBodyData>) {
  try {
    const instructionListResult: InstrunctionListReqModel = yield call(() => getInstructionList(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(instructionListResult);

    if (requestSuccess) {
      yield put(getInstructionListSuccess(instructionListResult));
    } else {
      yield put(getInstructionListFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error: any) {
    yield put(onGetCardTransactionListFail(error));
  }
}

function* handleOnSaveCustomerCardRequest({ payload }: PayloadAction<onSaveCustomerCardBodyData>) {
  const userData: UserState = yield select(userStore);
  const { customerNumber, sessionToken } = userData;
  try {
    const onSaveCustomerCardResult: OnSaveCustomerCardModel = yield call(() => onSaveCustomerCard(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onSaveCustomerCardResult);

    const cardBelongstoSomeoneElseError = '1212';

    if (requestSuccess) {
      yield put(onSaveCustomerCardSuccess(onSaveCustomerCardResult));
      yield put(
        onGetCardListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );
      yield put(
        onCountInstructionListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );

      const evamSdkData = {
        customer_no: customerNumber,
        kart_id: payload.MifareId,
        tarih: moment(new Date()).format('DD.MM.YYYY'),
        Kanal_bilgisi: 'web',
        // product code
      };

      sendEVAMCustomEvent(EVAMSdkEventTypes.ISTANBUL_KART_EKLEME, evamSdkData);

      yield put(setAddCardStep(4));
    } else {
      if (responseCode === cardBelongstoSomeoneElseError) {
        yield put(
          setTempCardInfo({
            cardName: payload.NickName,
            seriNo: payload.MifareId,
            cardNumber: payload.AliasNumber,
          })
        );
        yield put(showUserBelongToSomeoneElseModal());
      } else {
        yield put(onSaveCustomerCardFail(errorMessage));
        yield put(setFormError({ errorCode: responseCode, formName: formNames.addCard }));
      }
    }
  } catch (error: any) {
    yield put(onSaveCustomerCardFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.addCard }));
  }
}

function* handleMbSaveRegisterCardRequest({ payload }: PayloadAction<saveRegisterCardTransactionBodyData>) {
  const userData: UserState = yield select(userStore);
  const { customerNumber, sessionToken } = userData;
  try {
    const mbSaveRegisteredCardResult: OnSaveCustomerCardModel = yield call(() => saveRegisterCard(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(mbSaveRegisteredCardResult);

    if (requestSuccess) {
      yield put(mbSaveRegisterCardSuccess(mbSaveRegisteredCardResult));
      yield put(
        mbGetOtherCardListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );
      yield put(
        onCountInstructionListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );
      const evamSdkData = {
        customer_no: customerNumber,
        kart_id: payload.MifareId,
        tarih: moment(new Date()).format('DD.MM.YYYY'),
        Kanal_bilgisi: 'web',
      };

      sendEVAMCustomEvent(EVAMSdkEventTypes.YONETTIGI_KARTLARA_TL_YUKLEME, evamSdkData);
      yield call(() =>
        mbGetOtherCardListRequest({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );
      yield put(closeUserBelongToSomeoneElseModal());
      yield put(setAddCardStep(4));
    } else {
      yield put(onSaveCustomerCardFail(errorMessage));
      yield put(closeUserBelongToSomeoneElseModal());
      yield put(setFormError({ errorCode: responseCode, formName: formNames.addCard }));
    }
  } catch (error: any) {
    yield put(mbSaveRegisterCardFail(error));
    yield put(closeUserBelongToSomeoneElseModal());
    yield put(setFormError({ errorCode: error, formName: formNames.addCard }));
  }
}

function* handleMbGetOtherCardListRequestSaga({ payload }: PayloadAction<getRegisteredCardTransactionBodyData>) {
  try {
    const mbGetOtherCardListResult: MbGetOtherCardListModel = yield call(() => getRegisteredCardTransaction(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(mbGetOtherCardListResult);

    if (requestSuccess) {
      yield put(mbGetOtherCardListRequestSuccess(mbGetOtherCardListResult.data.getRegisteredCardTransactionList));
    } else {
      yield put(mbGetOtherCardListRequestFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.others }));
    }
  } catch (error: any) {
    yield put(mbGetOtherCardListRequestFail(error));
  }
}

function* handleOnDeleteCustomerCardByClosed({ payload }: PayloadAction<onDeleteCustomerCardByClosedBodyData>) {
  try {
    const onDeleteCardResult: MbGetOtherCardListModel = yield call(() => onDeleteCustomerCardByClosed(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onDeleteCardResult);
    yield put(onGetCardListRequest({ SessionToken: payload.SessionToken, CustomerNumber: payload.CustomerNumber }));
    yield put(
      mbGetOtherCardListRequest({ SessionToken: payload.SessionToken, CustomerNumber: payload.CustomerNumber })
    );
    if (requestSuccess) {
      yield put(setDeleteCardStep(payload.CardCloseReason));
      yield put(onDeleteCustomerCardByClosedSuccess());

      const evamSdkData = {
        customer_no: payload.CustomerNumber,
        kart_id: payload.MifareId,
        // TODO reason should be string and has to explanation
        sebep: payload.CardCloseReason,
        tarih: moment(new Date()).format('DD.MM.YYYY'),
        Kanal_bilgisi: 'web',
      };

      sendEVAMCustomEvent(EVAMSdkEventTypes.ISTANBULKART_SILME, evamSdkData);
    } else {
      yield put(onDeleteCustomerCardByClosedFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.cardDelete,
        })
      );
    }
  } catch (error: any) {
    yield put(onDeleteCustomerCardByClosedFail(error));
  }
}
// Other card delete transaction
function* handleDeleteRegisteredCardTransaction({
  payload,
}: PayloadAction<deleteRegisteredDefaultCardTransactionBodyData>) {
  try {
    const onDeleteCardResult: MbGetOtherCardListModel = yield call(() => deleteRegisteredCardTransaction(payload));
    yield put(
      mbGetOtherCardListRequest({ SessionToken: payload.SessionToken, CustomerNumber: payload.CustomerNumber })
    );
    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onDeleteCardResult);

    if (requestSuccess) {
      yield put(setDeleteCardStep(0));
      yield put(onDeleteRegisteredCardTransactionSuccess());
      yield put(setInActiveCard(undefined));
    } else {
      yield put(onDeleteRegisteredCardTransactionFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.cardDelete,
        })
      );
    }
  } catch (error: any) {
    yield put(onDeleteRegisteredCardTransactionFail(error));
    yield put(
      setFormError({
        errorCode: error,
        formName: formNames.cardDelete,
      })
    );
  }
}

function* handlesSetNormalizeUploadTLStates() {
  try {
    yield put(setActiveSelectedCard(undefined));
    yield put(resetCommissionAmount());
    yield put(setLoadAmount(0));
    yield put(setUserTemporaryCreditCard(undefined));
    yield put(setActiveSelectedCreditCard(undefined));
    yield put(setIsUserAddingNewCardInTransactions(false));
    yield put(setTempCardInfo(undefined));
    yield put(setIsUserInUploadTLProcess(false));
    yield put(setIsUserInUploadSubscriptionProcess(false));
    yield put(setIsUserInAutomaticInstructionProcess(false));
    yield put(setSeasonTicketLoadable(undefined));
    yield put(setUploadTLStep(0));
    yield put(setAutomaticInstructionStep(0));
    yield put(setUploadSubscriptionStep(0));
    yield put(setLowerLimit(undefined));
    yield put(setCardVisaDate(0));
  } catch (error) {
    console.log(error);
  }
}

function* handleShowUserAddedCardDetail({ payload }: PayloadAction<any>) {
  const userData: UserState = yield select(userStore);
  const { customerNumber, sessionToken } = userData;

  try {
    if (payload.isUserCard) {
      const onGetCardListResult: OnGetCardListModel = yield call(() =>
        onGetCardList({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );
      const { requestSuccess } = isBelbimRequestSuccess(onGetCardListResult);
      if (requestSuccess) {
        const cardItem = onGetCardListResult.data.CardList.find((item: any) => item.MifareId === payload.mifareId);
        if (cardItem) {
          yield put(setCardStateLoading(false));
          yield put(setInActiveCard(cardItem));
          yield put(push(routePath.cardDetails));
        }
      }
    } else {
      const mbGetOtherCardListResult: MbGetOtherCardListModel = yield call(() =>
        getRegisteredCardTransaction({
          CustomerNumber: customerNumber,
          SessionToken: sessionToken,
        })
      );

      const { requestSuccess } = isBelbimRequestSuccess(mbGetOtherCardListResult);
      if (requestSuccess) {
        const cardItem = mbGetOtherCardListResult.data.getRegisteredCardTransactionList.find(
          (item: any) => item.MifareId === payload.mifareId
        );
        if (cardItem) {
          yield put(setCardStateLoading(false));
          yield put(setInActiveCard(cardItem));
          yield put(push(routePath.otherCardDetails));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// update card nickname
function* handleChangeCardNickNameRequestSaga({ payload }: PayloadAction<changeCardNickNameBodyData>) {
  try {
    const store: CardTransactionsState = yield select(storeCardTransactions);
    const existData: any = store.activeInProcessCard;
    const response: OnChangePasswordCustomerReqModel = yield call(() => changeCardNickName(payload));

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(changeCardNickNameRequestSuccess());
      yield put(
        showToastify({
          toastMessage: 'web_card_settings_update_card_name_success',
          type: 'success',
        })
      );
      yield put(setInActiveCard({ ...existData, NickName: payload.CardNickName }));
      yield put(closeInputDialog());
    } else {
      yield put(changeCardNickNameRequestError(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.cardUpdateNickname,
        })
      );
    }
  } catch (error: any) {
    yield put(changeCardNickNameRequestError(error));
    yield put(setFormError({ errorCode: error, formName: formNames.changePassword }));
  }
}
// update card nickname
function* handleOnChangeRegisterCardNickNameRequestSaga({
  payload,
}: PayloadAction<onChangeRegisterCardNickNameBodyData>) {
  try {
    const store: CardTransactionsState = yield select(storeCardTransactions);
    const existData: any = store.activeInProcessCard;
    const response: OnChangePasswordCustomerReqModel = yield call(() => onChangeRegisterCardNickName(payload));

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(changeCardNickNameRequestSuccess());
      yield put(
        showToastify({
          toastMessage: 'web_card_settings_update_card_name_success',
          type: 'success',
        })
      );
      yield put(setInActiveCard({ ...existData, NickName: payload.NickName }));
      yield put(closeInputDialog());
    } else {
      yield put(changeCardNickNameRequestError(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.cardUpdateNickname,
        })
      );
    }
  } catch (error: any) {
    yield put(changeCardNickNameRequestError(error));
    yield put(setFormError({ errorCode: error, formName: formNames.changePassword }));
  }
}

// SetFavoriteCard Saga
function* handleSetDefaultCardRequestSaga({ payload }: PayloadAction<setDefaultCardBodyData>) {
  try {
    const store: CardTransactionsState = yield select(storeCardTransactions);
    const existData: any = store.activeInProcessCard;

    const response: OnChangePasswordCustomerReqModel = yield call(() => setDefaultCard(payload));

    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(setDefaultCardRequestSuccess());
      yield put(setInActiveCard({ ...existData, IsPrimary: !existData.IsPrimary }));
      if (payload.MifareId !== '') {
        yield put(
          showToastify({
            toastMessage: 'web_card_settings_make_favourite_card_success',
            type: 'success',
          })
        );
      }
    } else {
      yield put(setDefaultCardRequestError(errorMessage));
    }
  } catch (error: any) {
    yield put(setDefaultCardRequestError(error));
  }
}

// Waiting Cards Instructıons For User Cards screen
function* handleOnCountInstructionListRequestSaga({ payload }: PayloadAction<onDeleteCustomerCardByClosedBodyData>) {
  try {
    const onCountInstructionListResult: OnCountInstructionListModel = yield call(() => onCountInstructionList(payload));
    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onCountInstructionListResult);
    if (requestSuccess) {
      yield put(onCountInstructionListSuccess(onCountInstructionListResult));
    } else {
      yield put(onCountInstructionListFail(errorMessage));
    }
  } catch (error: any) {
    yield put(onCountInstructionListFail(error));
  }
}

function* handleGetSeasonTicketPriceRequestSaga({ payload }: PayloadAction<getSeasonTicketPriceReqBodyData>) {
  const userData: UserState = yield select(userStore);
  const cardData: CardTransactionsState = yield select(storeCardTransactions);
  const creditCardData: CreditCardTransactionsState = yield select(creditCardTransactionsStore);

  const activeCard = cardData?.activeSelectedCard;
  const activeCreditCard = creditCardData?.activeSelectedCreditCard;
  const { customerNumber, sessionToken } = userData;
  try {
    const getSeasonTicketPriceResult: GetSeasonTicketPriceModel = yield call(() =>
      getSeasonTicketPrice({
        CustomerNumber: payload.CustomerNumber,
        SessionToken: payload.SessionToken,
        MifareId: payload.MifareId,
        SeasonTicketType: payload.SeasonTicketType,
      })
    );

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(getSeasonTicketPriceResult);
    if (requestSuccess) {
      if (payload?.primaryCard) {
        yield put(setActiveSelectedCard(payload?.primaryCard));
      }
      yield put(
        onGetCommissionByBinNumberRequest(
          getCommissionByBinNumberNormalize(
            getSeasonTicketPriceResult.data.SeasonTicketPrice,
            activeCreditCard?.Value1.slice(0, 6),
            activeCard?.CardBalance,
            customerNumber,
            sessionToken
          )
        )
      );
      yield put(getSeasonTicketLoadableRequestSuccess(getSeasonTicketPriceResult));
    } else {
      if (!payload?.primaryCard) {
        yield put(
          setFormError({
            errorCode: responseCode,
            formName: formNames.uploadSubscription,
          })
        );
      }

      yield put(getSeasonTicketLoadableRequestError(errorMessage));
    }
  } catch (error: any) {
    yield put(getSeasonTicketLoadableRequestError(error));
  }
}
// Check if user can upload subscription to card for limit
function* handleOnCheckCardIsUploadableSubsRequestSaga({ payload }: PayloadAction<any>) {
  const creditCardStore: CreditCardTransactionsState = yield select(creditCardTransactionsStore);
  const userCreditCards = creditCardStore?.creditCardList;

  const accountStatus = creditCardStore.masterPassAccountStatus;

  const userData: UserState = yield select(userStore);
  const { customerNumber, sessionToken } = userData;

  try {
    const getSeasonTicketPriceResult: GetSeasonTicketPriceModel = yield call(() =>
      getSeasonTicketPrice({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        MifareId: payload.MifareId,
        SeasonTicketType: '1',
      })
    );

    const { responseCode, errorMessage } = isBelbimRequestSuccess(getSeasonTicketPriceResult);
    if (responseCode === '736') {
      yield put(show736ErrorDialog());
      yield put(onCheckCardIsUploadableSubsFail(errorMessage));
    } else {
      yield put(setActiveSelectedCard(payload));
      yield put(setIsUserInUploadSubscriptionProcess(true));
      yield put(onCheckCardIsUploadableSubsSuccess());
      if ((userCreditCards && userCreditCards?.length > 0) || accountStatus === MasterPassAccountStatusEnum.NO_LINKED) {
        yield put(push(routePath.uploadSubscription));
      } else {
        yield put(push(routePath.addCreditCard));
      }
    }
  } catch (error: any) {
    yield put(getSeasonTicketLoadableRequestError(error));
  }
}

function* handleOnCheckProductRequestSaga({ payload }: PayloadAction<onCheckProductReqBodyData>) {
  try {
    const cardState: CardTransactionsState = yield select(storeCardTransactions);

    const {
      isUserInUploadTLProcess,
      isUserInUploadSubscriptionProcess,
      isUserInAutomatiInstructionProcess,
    } = cardState;

    const onDeleteCardResult: OnCheckProductModel = yield call(() => onCheckProduct(payload));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(onDeleteCardResult);

    if (requestSuccess) {
      if (isUserInUploadTLProcess) {
        yield put(onCheckProductSuccess());
        yield put(
          setTempCardInfo({
            cardName: payload.NickName,
            seriNo: payload.MifareId,
            cardNumber: payload.AliasNumber,
          })
        );
        yield put(setActiveSelectedCard(onDeleteCardResult.data));
        yield put(push(routePath.addBalanceToCard));
      }
      if (isUserInUploadSubscriptionProcess) {
        if (onDeleteCardResult.data.SeasonTicketLoadable) {
          yield put(onCheckProductSuccess());
          yield put(
            setTempCardInfo({
              cardName: payload.NickName,
              seriNo: payload.MifareId,
              cardNumber: payload.AliasNumber,
            })
          );
          yield put(setActiveSelectedCard(onDeleteCardResult.data));
          yield put(push(routePath.uploadSubscription));
        } else {
          yield put(onCheckProductFail(errorMessage));
          yield put(
            setFormError({
              errorCode: 'web_subscription_new_card_msg',
              formName: formNames.addCard,
            })
          );
        }
      }
      if (isUserInAutomatiInstructionProcess) {
        yield put(onCheckProductSuccess());
        setTempCardInfo({
          cardName: payload.NickName,
          seriNo: payload.MifareId,
          cardNumber: payload.AliasNumber,
        });
        yield put(push(routePath.automaticInstruction));
      }
    } else {
      yield put(onCheckProductFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.addCard,
        })
      );
    }
  } catch (error: any) {
    yield put(onCheckProductFail(error));
  }
}

function* handleOnOnlineApplicationRequestSaga({ payload }: PayloadAction<any>) {
  try {
    const onOnlineApplicationQueryResult: OnOnlineApplicationQueryModel = yield call(() =>
      onOnlineApplicationQuery(payload.request)
    );
    const product: OnOnlineApplicationCardQueryItemModel = payload.product;
    const products = onOnlineApplicationQueryResult.data.PrivilegedCustomerProducts;
    const productResult = onOnlineApplicationQueryResult.data.ProductResult;
    const responseCode = onOnlineApplicationQueryResult.data.ResponseCode;
    const relationShipApply = payload?.relationShipApply;
    const { requestSuccess } = isBelbimRequestSuccess(onOnlineApplicationQueryResult);
    let _CustomerNumber;
    if (payload.request._CustomerNumber) {
      _CustomerNumber = payload.request._CustomerNumber;
      delete payload.request._CustomerNumber;
    }

    if (requestSuccess) {
      const activeShippinFee =
        payload?.product?.ProductCode === 'PRS_TOP' ? 0 : onOnlineApplicationQueryResult.data.ShippingFee;
      const activeUpstate =
        payload?.product?.ProductCode === 'PRS_TOP' ? 0 : onOnlineApplicationQueryResult.data.UpstateShippingFee;
      yield put(setActiveShippingPrice([activeShippinFee, activeUpstate]));

      if (product) {
        yield put(setActiveCard(product));
      }

      switch (productResult) {
        case CardApplicationResponseTypeEnum.CANT_TAKE:
          yield put(openCantApplyDialog());
          break;
        case CardApplicationResponseTypeEnum.ALREADY_HAVE:
          yield put(openAlreadyHave());
          break;
        case CardApplicationResponseTypeEnum.FOREIGN:
          yield put(openCantApplyForeign());
          break;
        case CardApplicationResponseTypeEnum.CAN_TAKE:
          if (!onOnlineApplicationQueryResult.data.HasAddressInfo) {
            yield put(setActiveUserAdress(undefined));
            yield put(
              push(routePath.userAdressDetail, {
                userMustHaveContactAddress: true,
                Custody: payload?.request?.CustodyTckn,
                stepData: payload?.request,
              })
            );
            yield put(onOnlineApplicationSuccess());
            return;
          }
          if (products && products.length >= 0) {
            yield put(setActiveCard(product));
            yield put(openDeletePrivilegedDialog());
          } else {
            yield put(clearApplicationData({ withoutCard: false, withoutStep: true }));
            yield put(setActiveCard(product));
            yield put(
              getProductPriceRequest({
                CustomerNumber: _CustomerNumber ? _CustomerNumber : payload.request?.CustomerNumber,
                SessionToken: payload.request.SessionToken,
                ProductCode: product.ProductCode,
                CustodyCustomerNumber: payload.request.CustomerNumber || undefined,
              })
            );
            yield put(
              push(routePath.cardApplicationSteps, {
                item: !!relationShipApply ? product : undefined,
                relationShipApply,
              })
            );
            //
          }
          if (!!payload?.callback) {
            payload.callback();
          }
          yield put(setH(onOnlineApplicationQueryResult.data.h));
          break;
        default:
          if (!onOnlineApplicationQueryResult.data.HasAddressInfo) {
            yield put(setActiveUserAdress(undefined));
            yield put(
              push(routePath.userAdressDetail, {
                userMustHaveContactAddress: true,
                data: payload?.request,
                Custody: payload?.request?.CustodyTckn,
              })
            );
            yield put(onOnlineApplicationSuccess());
            return;
          }
          break;
      }
      yield put(onOnlineApplicationSuccess());
    } else {
      yield put(openOnOnlineApplicationDialog());
      yield put(onOnlineApplicationFail(responseCode));
    }
  } catch (error) {
    yield put(onOnlineApplicationFail('generic_error_msg'));
  }
}

function* handleOnOnlineApplicationCardRequestSaga({ payload }: PayloadAction<onOnlineApplicationCardQueryBodyData>) {
  try {
    const onOnlineApplicationCardQueryResult: OnOnlineApplicationCardQueryModel = yield call(() =>
      onOnlineApplicationCardQuery(payload)
    );
    const { requestSuccess } = isBelbimRequestSuccess(onOnlineApplicationCardQueryResult);
    if (requestSuccess) {
      yield put(onOnlineApplicationCardSuccess(onOnlineApplicationCardQueryResult.data.ProductList));
    } else {
      yield put(onOnlineApplicationCardFail());
    }
  } catch (error) {
    yield put(onOnlineApplicationCardFail());
  }
}

function* handleGetCardVisaRequestSaga({ payload }: PayloadAction<getCardVisaReqBodyData>) {
  try {
    const response: GetCardVisaModel = yield call(() => getCardVisa(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(getCardVisaSuccess(response));
    } else {
      yield put(getCardVisaFail());
    }
  } catch (error) {
    yield put(getCardVisaFail());
  }
}

function* handleCheckApplicationTypeFour({ payload }: PayloadAction<any>) {
  try {
    const response: ProductPriceModel = yield call(() =>
      checkApplicationTypeFour({ ...payload, adress: undefined }, payload.adress)
    );

    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(checkApplicationTypeFourSuccess());
    } else {
      yield put(checkApplicationTypeFourFail());
      yield put(
        setFormError({
          errorCode: errorMessage,
          formName: formNames.reportStepForm,
        })
      );
    }
  } catch (error: any) {
    yield put(checkApplicationTypeFourFail());
    setFormError({
      errorCode: error,
      formName: formNames.reportStepForm,
    });
  }
}

function* handleOnCustomerApplicationList({ payload }: PayloadAction<onCustomerApplicationListBodyData>) {
  try {
    const response: CustomerCardApplicationListModel = yield call(() => onCustomerApplicationList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onCustomerApplicationListSuccess(response.data.ApplicationList));
    } else {
      yield put(onCustomerApplicationListFail());
    }
  } catch (error) {
    yield put(onCustomerApplicationListFail());
  }
}

function* handleOnCustomerActiveApplicationDetail({ payload }: PayloadAction<onCustomerApplicationListBodyData>) {
  try {
    const response: ApplicationDetailModel = yield call(() => onCustomerApplicationList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    const shippingFee = payload?.ProductCode === 'PRS_TOP' ? 0 : response.data.LocalShippingFee;
    const upstateShippingFee = payload?.ProductCode === 'PRS_TOP' ? 0 : response.data.UpstateShippingFee;

    if (requestSuccess) {
      yield put(onGetActiveApplicationDetailSuccess(response.data.ApplicationList[0]));
      yield put(setActiveShippingPrice([shippingFee, upstateShippingFee]));
    } else {
      yield put(onGetActiveApplicationDetailFail());
    }
  } catch (error) {
    yield put(onGetActiveApplicationDetailFail());
  }
}

function* handleOnGetAccountWithCards({ payload }: PayloadAction<OnGetAccountWithCardsData>) {
  try {
    const response: OnGetAccountWithCardsModel = yield call(() => onGetAccountWithCards(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onGetAccountWithCardsSuccess(response.data));
    } else {
      yield put(onGetAccountWithCardsFail());
    }
  } catch (error) {
    yield put(onGetAccountWithCardsFail());
  }
}

// Watchers
function* cardTransactionsSagaWatcher() {
  yield takeLatest(getCardVisaRequest, handleGetCardVisaRequestSaga);
  yield takeLatest(checkUserCardsHasVisaRequest, handleCheckUserCardsHasVisaRequestSaga);
  yield takeLatest(onCheckCardIsUploadableSubsRequest, handleOnCheckCardIsUploadableSubsRequestSaga);
  yield takeLatest(onCheckProductRequest, handleOnCheckProductRequestSaga);
  yield takeLatest(getInstructionListRequest, handleInstructionListRequestSaga);
  yield takeLatest(setNormalizeCardTransactionStates, handlesSetNormalizeUploadTLStates);
  yield takeLatest(onRegisterCardTransactionRequest, handleOnRegisterCardTransactionRequestSaga);
  yield takeLatest(mbSaveRegisterCardRequest, handleMbSaveRegisterCardRequest);
  yield takeLatest(onGetCardTransactionListRequest, handleOnGetTransactionListRequestSaga);
  yield takeLatest(onSaveCustomerCardRequest, handleOnSaveCustomerCardRequest);
  yield takeLatest(onGetCardListRequest, handleOnGetCardListRequestSaga);
  yield takeLatest(mbGetOtherCardListRequest, handleMbGetOtherCardListRequestSaga);
  yield takeLatest(onDeleteCustomerCardByClosedRequest, handleOnDeleteCustomerCardByClosed);
  yield takeLatest(onDeleteRegisteredCardTransactionRequest, handleDeleteRegisteredCardTransaction);
  yield takeLatest(showUserAddedCardDetail, handleShowUserAddedCardDetail);
  yield takeLatest(onCountInstructionListRequest, handleOnCountInstructionListRequestSaga);
  yield takeLatest(setDefaultCardRequest, handleSetDefaultCardRequestSaga);
  yield takeLatest(changeCardNickNameRequest, handleChangeCardNickNameRequestSaga);
  yield takeLatest(getSeasonTicketLoadableRequest, handleGetSeasonTicketPriceRequestSaga);
  yield takeLatest(onOnlineApplicationRequest, handleOnOnlineApplicationRequestSaga);
  yield takeLatest(onOnlineApplicationCardRequest, handleOnOnlineApplicationCardRequestSaga);
  yield takeLatest(onChangeRegisterCardNickNameRequest, handleOnChangeRegisterCardNickNameRequestSaga);
  yield takeLatest(checkApplicationTypeFourRequest, handleCheckApplicationTypeFour);
  yield takeLatest(onCustomerApplicationListRequest, handleOnCustomerApplicationList);
  yield takeLatest(onGetAccountWithCardsRequest, handleOnGetAccountWithCards);
  yield takeLatest(onGetActiveApplicationDetailRequest, handleOnCustomerActiveApplicationDetail);
}

// eslint-disable-next-line
export default cardTransactionsSagaWatcher;
