import { errorMessages } from 'Config';
import * as yup from 'yup';

export const SingupConditionsSchema = yup.object({
  termsAccepted: yup.boolean().required().oneOf([true], errorMessages.checkboxTrue),
});

export type SingupConditionsInputs = yup.InferType<typeof SingupConditionsSchema>;

export const signupCondtionsSchemaInitialValues: SingupConditionsInputs = {
  termsAccepted: false,
};
