import { memo } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { WelcomeGetApp } from './WelcomeGetApp';
import { useHistory } from 'react-router-dom';
import { colors, fontSizes, gutters } from 'Theme/Variables';

import './WelcomeSignIn.scss';

const WelcomeSignIn = memo(() => {
  const history = useHistory();

  const {
    web_title_welcome,
    web_desc_welcome,
    web_login_btn,
    web_signup_text,
    web_signup_blue_text,
  } = useLocalization();

  return (
    <Grid item lg={5} id="welcomeSignIn">
      <Box>
        <Typography variant="h4">
          <Box className="welcomeSignIn-title" fontWeight="Bold">
            {web_title_welcome}
          </Box>
        </Typography>
        <Typography variant="h5">
          <Box className="welcomeSignIn-desc" color={colors.alternative}>
            {web_desc_welcome}
          </Box>
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className="welcomeSignIn-button"
            itemID="welcomeSignInButtonTest"
            onClick={() => history.push('/login')}
          >
            {web_login_btn}
          </Button>
        </Box>
        <Box
          marginBottom={gutters.regular}
          fontSize={fontSizes.regular}
          color={colors.alternative}
          fontWeight={'medium'}
          className="welcomeSignIn-Upbutton"
        >
          {web_signup_text} <span onClick={() => history.push('/signup')}>{web_signup_blue_text}</span>
        </Box>
      </Box>
      <WelcomeGetApp showOnlyDesktop />
    </Grid>
  );
});

export default WelcomeSignIn;
