import { localStorageKeys } from 'Config';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetApiGWTokenModel, GetCmsModel, OnInıtModel } from 'Models';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getApiGWRefreshToken, getCms, getPublicIp, getApiGWToken, onInıtData } from 'Services/Queries';
import {
  getApiGWRefreshTokenCookie,
  getApiGWTokenCookie,
  isBelbimRequestSuccess,
  languageId,
  setApiGWCookies,
} from 'Utils';
import {
  cmsRequest,
  cmsRequestSuccess,
  apiGWTokenRequest,
  setLanguage,
  publicIpRequest,
  onInıtDataRequestError,
  onInıtDataRequestSuccess,
  onInıtDataRequest,
  appIsMaintenance,
  setVersionStatus,
  fetchVersionNumber,
  resetAllStore,
} from './slices';
import { resetToastifyStore, showToastify } from 'Stores/Toastify';
import { push, RouterState } from 'connected-react-router';
import { routePath } from 'Navigator/routes';
import axios from 'axios';
import { resetCardTransactionStore } from 'Stores/CardTransactions';
import { resetAccountStore } from 'Stores/Account';
import { resetUserStore } from 'Stores/User';
import { resetCreditCardTransactionStore } from 'Stores/CreditCardTransactions';
import { resetFormStore } from 'Stores/Form';
import { resetSignUpStore } from 'Stores/SignUp';
import { resetTransactionsStore } from 'Stores/Transactions';
import { resetDialogStore } from 'Stores/Dialog';

export const staticErrorMessage = 'Bir hata oluştu. Lütfen tekrar deneyin.';
// Workers

function* handleCmsRequest() {
  try {
    const userGWToken = getApiGWTokenCookie();
    if (!userGWToken) {
      yield put(apiGWTokenRequest());
    }

    const getCmsResult: GetCmsModel = yield call(() => getCms({ languageId: languageId() }));

    const { responseCode, requestSuccess, errorMessage } = isBelbimRequestSuccess(getCmsResult);

    yield put(cmsRequestSuccess(getCmsResult.data));
  } catch (error) {
    yield put(
      showToastify({
        toastMessage: staticErrorMessage,
        type: 'error',
      })
    );
  }
}

function* handleOnSetLanguage() {
  yield call(handleCmsRequest);
}

function* handleOnApiGWTokenRequest() {
  const tokenCookie = getApiGWTokenCookie();
  const refreshTokenCookie = getApiGWRefreshTokenCookie();

  if (!tokenCookie) {
    if (refreshTokenCookie) {
      try {
        const getApiGwRefreshTokenResult: GetApiGWTokenModel = yield call(() =>
          getApiGWRefreshToken(refreshTokenCookie)
        );

        setApiGWCookies(getApiGwRefreshTokenResult.token);
      } catch (error) {
        yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
      }
    } else {
      try {
        const getApiGwTokenResult: GetApiGWTokenModel = yield call(getApiGWToken);
        const { token, refreshToken } = getApiGwTokenResult;

        setApiGWCookies(token, refreshToken);
      } catch (error) {
        yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
      }
    }
  }
}

function* handlePublicIpRequest() {
  const publicIp = localStorage.getItem('publicIpV4');
  if (!publicIp) {
    try {
      const response: string = yield call(getPublicIp);
      localStorage.setItem('publicIpV4', response);
    } catch (error) {
      yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    }
  }
}

function* resetAllStoreSaga() {
  try {
    yield put(resetCardTransactionStore());
    yield put(resetAccountStore());
    yield put(resetUserStore());
    yield put(resetCreditCardTransactionStore());
    yield put(resetDialogStore());
    yield put(resetFormStore());
    yield put(resetSignUpStore());
    yield put(resetToastifyStore());
    yield put(resetTransactionsStore());
    yield put(resetUserStore());
  } catch (error) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
  }
}

function* handleSetVersionStatusSaga({ payload }: PayloadAction<string>) {
  const semverGreaterThan = (versionA: string, versionB: string): boolean => {
    const versionsA = versionA?.split(/\./g);
    const versionsB = versionB?.split(/\./g);

    while (versionsA?.length || versionsB?.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());

      if (a === b) continue;

      return a > b || isNaN(b);
    }

    return false;
  };

  // try {
  //   const metaJson: { data: { version: string } } = yield call(() => axios.get(`/meta.json?${new Date().getTime()}`));
  //   const latestVersion: string = metaJson.data.version;
  //   const currentVersion: string = payload;
  //   const shouldForceRefresh: boolean = semverGreaterThan(latestVersion, currentVersion);

  //   if (shouldForceRefresh) {
  //     yield put(
  //       setVersionStatus({
  //         loading: false,
  //         isLatestVersion: false,
  //       })
  //     );
  //   } else {
  //     console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
  //     yield put(
  //       setVersionStatus({
  //         loading: false,
  //         isLatestVersion: true,
  //       })
  //     );
  //   }
  // } catch (e) {
  //   console.log(e);
  // }
}

function* handleOnInıtRequestSaga({ payload }: PayloadAction<string>) {
  try {
    const onLoginResult: OnInıtModel = yield call(() => onInıtData(payload));

    yield put(onInıtDataRequestSuccess(onLoginResult));
    if (onLoginResult.data.UnderMaintenance) {
      yield put(appIsMaintenance(!onLoginResult.data.UnderMaintenance));
      yield put(push(routePath.maintenance));
    }
  } catch (error: any) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    yield put(onInıtDataRequestError(error));
  }
}

// Watchers
function* appSagasWatcher() {
  yield takeLatest(fetchVersionNumber, handleSetVersionStatusSaga);
  yield takeLatest(setLanguage, handleOnSetLanguage);
  yield takeLatest(resetAllStore, resetAllStoreSaga);

  yield takeLatest(cmsRequest, handleCmsRequest);
  yield takeLatest(apiGWTokenRequest, handleOnApiGWTokenRequest);
  yield takeLatest(publicIpRequest, handlePublicIpRequest);
  yield takeLatest(onInıtDataRequest, handleOnInıtRequestSaga);
}

// eslint-disable-next-line
export default appSagasWatcher;
