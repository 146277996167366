import * as React from 'react';

function GreenTickBigFilledIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      className="greenTickBigFilledIcon"
      {...props}
    >
      <defs>
        <path
          id="lx07c0y0sa"
          d="M36 6C19.431 6 6 19.431 6 36c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30 0-7.956-3.16-15.587-8.787-21.213C51.587 9.16 43.956 6 36 6zm12.9 22.83l-13.71 18c-.566.736-1.442 1.168-2.37 1.17-.923.005-1.798-.416-2.37-1.14l-7.32-9.33c-.66-.847-.817-1.98-.413-2.975.404-.994 1.307-1.697 2.37-1.845 1.063-.147 2.124.283 2.783 1.13l4.89 6.24 11.34-15c.654-.857 1.715-1.3 2.785-1.163s1.984.835 2.4 1.83c.415.994.269 2.136-.385 2.993v.09z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-732 -351) translate(732 351)">
            <path d="M0 0H72V72H0z" />
            <circle cx="36" cy="36" r="36" fill="#FFF" />
            <circle cx="36" cy="36" r="30" fill="#F5F5F6" />
            <mask id="dvrku092bb" fill="#fff">
              <use xlinkHref="#lx07c0y0sa" />
            </mask>
            <g fill="#3AC562" mask="url(#dvrku092bb)">
              <path d="M0 0H72V72H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GreenTickBigFilledIcon;
