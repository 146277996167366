import React, { FunctionComponent, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import './InputOneCharBox.scss';
import { useLocalization } from 'Hooks';
import classNames from 'classnames';

type InputOneCharBoxProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  amount?: number;
  disabled?: boolean;
  isPassword?: boolean;
  showPasswordToggle?: boolean;
};

const InputOneCharBox: FunctionComponent<InputOneCharBoxProps> = ({
  value,
  setValue,
  amount = 5,
  disabled,
  isPassword,
  showPasswordToggle,
}) => {
  const { web_link_btn_show, web_link_btn_hide } = useLocalization();
  const [hideValue, setHideValue] = useState<boolean>(false);
  useEffect(() => {
    setHideValue(isPassword || false);
    // eslint-disable-next-line
  }, []);
  return (
    <div id="inputOneCharBox">
      <InputVerification
        onChange={(val) => setValue(val)}
        value={value.trim()}
        placeholder=""
        length={amount}
        hideValue={hideValue}
        disabled={disabled}
      />
      {showPasswordToggle && (
        <Typography variant="body1" align="center" className="inputOneCharBox-container__showPassword">
          <span onClick={() => setHideValue(!hideValue)}>{hideValue ? web_link_btn_show : web_link_btn_hide}</span>
        </Typography>
      )}
    </div>
  );
};

export default InputOneCharBox;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ---- CUSTOMIZED NPM PACKAGE REACT INPUT VERIFICATION ---- //
//
//
//
//
//
//
//
//
//
//
//
//
//

const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
  SPACE: 32,
};

type Props = {
  length?: number;
  onChange: (data: string) => any;
  placeholder?: string;
  value?: string;
  hideValue?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const InputVerification = ({ length = 4, onChange, placeholder = '·', value: pValue, hideValue, disabled }: Props) => {
  const emptyValue = new Array(length).fill(placeholder);

  const [activeIndex, setActiveIndex] = React.useState<number>(-1);
  const [value, setValue] = React.useState<string[]>(pValue ? pValue.split('') : emptyValue);

  const codeInputRef = React.createRef<HTMLInputElement>();
  const itemsRef = React.useMemo(() => new Array(length).fill(null).map(() => React.createRef<HTMLDivElement>()), [
    length,
  ]);

  const isCodeRegex = new RegExp(`^[0-9]{${length}}$`);

  const getItem = (index: number) => itemsRef[index]?.current;
  const focusItem = (index: number): void => getItem(index)?.focus();
  const blurItem = (index: number): void => getItem(index)?.blur();

  const onItemFocus = (index: number) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  };

  const onInputKeyUp = ({ key, keyCode }: React.KeyboardEvent) => {
    const inputValue = codeInputRef.current?.value;
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;

    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isLast = nextIndex === length;
    const isDeleting = keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;
    const isSpace = keyCode === KEY_CODE.SPACE;

    if (isSpace) return;

    // keep items focus in sync
    onItemFocus(activeIndex);

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = placeholder;
      setValue(newValue);

      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }

      return;
    }

    // if the key pressed is not a number
    // don't do anything
    let keyInput;
    if (Number.isNaN(+key)) {
      if (!inputValue?.[0]) return;
      keyInput = inputValue?.[0];
    }

    // reset the current value
    // and set the new one
    if (codeInput) codeInput.value = '';
    if (!!key && key !== 'Unidentified') newValue[activeIndex] = key;
    else newValue[activeIndex] = keyInput ?? '';
    setValue(newValue);

    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }

    if (codeInput) codeInput.blur();
    if (currentItem) currentItem.blur();

    setActiveIndex(-1);
  };

  // handle mobile autocompletion
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = e.target;
    const isCode = isCodeRegex.test(changeValue);
    if (!isCode) return;

    setValue(changeValue.split(''));
    blurItem(activeIndex);
  };

  const onInputBlur = () => {
    // https://github.com/ugogo/react-input-verification-code/issues/1
    if (activeIndex === -1) return;

    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current;
    if (!codeInput) return;

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault();

      const pastedString = e.clipboardData?.getData('text');
      if (!pastedString) return;

      const isNumber = !Number.isNaN(+pastedString);
      if (isNumber) setValue(pastedString.split(''));
    };

    if (itemsRef[0].current) {
      onItemFocus(0);
      itemsRef[0].current.scrollIntoView();
    }

    codeInput.addEventListener('paste', onPaste);
    return () => codeInput.removeEventListener('paste', onPaste);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onChange(value.join(''));
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (!disabled) {
      setActiveIndex(0);
      focusItem(0);
    }
    // eslint-disable-next-line
  }, [disabled]);

  useEffect(() => {
    if (typeof pValue !== 'string') return;

    if (pValue === '' && value.join('') === emptyValue.join('')) return;

    if (pValue !== value.join('')) setValue(pValue.split(''));
    // eslint-disable-next-line
  }, [pValue]);

  const itemWidth = 45;
  const itemHeight = 50;
  const itemSpacing = 10;
  return (
    <>
      <div className="inputOneCharBox-container">
        <input
          ref={codeInputRef}
          autoComplete="off"
          type="password"
          inputMode="numeric"
          onChange={onInputChange}
          onKeyUp={onInputKeyUp}
          onBlur={onInputBlur}
          style={{
            position: 'absolute',
            top: 0,
            left: activeIndex * itemWidth + itemSpacing * activeIndex,
            opacity: 0,
            width: itemWidth,
            height: itemHeight,
            zIndex: -1,
          }}
          disabled={disabled}
          itemID="inputOneCharBoxTest"
        />

        {itemsRef.map((ref, index) => (
          <div
            key={index}
            ref={ref}
            role="button"
            tabIndex={0}
            className={classNames({
              'inputOneCharBox-container__input': true,
              'inputOneCharBox-container__input--filled': !!value[index],
              'inputOneCharBox-container__input--hide': hideValue,
              'inputOneCharBox-container__input--xSmall': length === 6,
              'inputOneCharBox-container__input--disabled': disabled,
              'inputOneCharBox-container__input--active': !disabled && index === activeIndex,
            })}
            onFocus={onItemFocus(index)}
          >
            {hideValue && !!value[index] ? <>&#8226;</> : value[index] || placeholder}
          </div>
        ))}
      </div>
    </>
  );
};
