import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import './PasswordFormInfo.scss';
import { useLocalization } from 'Hooks';
import { listItemParser } from 'Utils';

type PasswordFormInfoProps = {
  rules: string;
};

const PasswordFormInfo: FunctionComponent<PasswordFormInfoProps> = ({ rules }) => {
  const { web_password_rules_title } = useLocalization();
  const rulesParsed = listItemParser(rules);

  return (
    <Box className="passwordFormInfo">
      <Typography variant="body1" component="div">
        <Box fontWeight={600} marginBottom={gutters.small}>
          {web_password_rules_title}
        </Box>
        {rules && <ul>{!!rulesParsed && rulesParsed.map((rule, index) => <li key={index}>{rule}</li>)}</ul>}
      </Typography>
    </Box>
  );
};

export default PasswordFormInfo;
