import { onOnlineApplicationCardQueryBodyData } from 'Config';
import { SubProductModel } from 'Models/OnGetSubProductList';
import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnGetSubProductList';

const OnGetSubProductList = async (params: onOnlineApplicationCardQueryBodyData): Promise<SubProductModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default OnGetSubProductList;
