import * as React from 'react';

function MailIconBlue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="16" fill={props.fill} viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17 0H3C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0ZM17 2L10.5 6.47C10.1906 6.64863 9.8094 6.64863 9.5 6.47L3 2H17Z"
        fill={props.fill ? props.fill : '#1AAADC'}
      />
    </svg>
  );
}

export default MailIconBlue;
