import { OnKillCustomerSessionAllBodyData } from 'Config';
import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnKillCustomerSessionAll';

const onKillCustomerSessionAll = async (params: OnKillCustomerSessionAllBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onKillCustomerSessionAll;
