import { PayloadAction } from '@reduxjs/toolkit';
import { onCheckPhoneNumberBodyData } from 'Config/ServiceConfig';
import { push } from 'connected-react-router';
import { OnCheckPhoneNumberModel } from 'Models';
import { routePath } from 'Navigator/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { onCheckPhoneNumber } from 'Services/Queries';
import { closeOTPDialog, showBlockedDialog, showOTPDialog } from 'Stores/Dialog';
import { isBelbimRequestSuccess, nullRemover } from 'Utils';
import {
  onCheckNumberRequest,
  onCheckNumberRequestError,
  onCheckNumberRequestSuccess,
  setUserNumber,
  setWelcomeStateNormalize,
} from './slices';
import { decrementLoginFormStep, incrementLoginFormStep } from '../User/slices';
import { customerStatusResult } from '.';
import { showToastify } from 'Stores/Toastify/slices';
import { staticErrorMessage } from 'Stores/App/sagas';
import { setActiveCategory, onGetFaqRequest } from 'Stores/Account';
import { setCreditCardList } from 'Stores/CreditCardTransactions';
import { resetSignupStep } from 'Stores/SignUp';

// Workers
function* handleOnCheckNumberRequestSaga({
  payload: { CountryCode, Cellphone },
}: PayloadAction<onCheckPhoneNumberBodyData>) {
  const parsedCellPhone = nullRemover(Cellphone);
  try {
    const onCheckNumberResult: OnCheckPhoneNumberModel = yield call(() =>
      onCheckPhoneNumber({ CountryCode, Cellphone: parsedCellPhone })
    );
    const { requestSuccess, errorMessage } = isBelbimRequestSuccess(onCheckNumberResult);
    if (requestSuccess) {
      yield put(onCheckNumberRequestSuccess(onCheckNumberResult));
      const customerStatus = onCheckNumberResult.data.CustomerStatus;
      yield put(setUserNumber({ CountryCode, Cellphone: parsedCellPhone }));
      switch (customerStatusResult[customerStatus]) {
        case 'Passive':
          yield put(showOTPDialog());
          break;
        case 'Active':
          yield put(
            push(routePath.login, {
              countryCode: CountryCode,
              phoneNumber: parsedCellPhone,
            })
          );
          yield put(incrementLoginFormStep());

          break;
        case 'Blocked':
          yield put(showBlockedDialog());
          break;
        case 'BlockedFromWrongAccess':
          yield put(
            push(routePath.forgotPassword, {
              descriptionKey: 'web_bloklu_uye_desc',
              countryCode: CountryCode,
              phoneNumber: parsedCellPhone,
              userType: '5',
            })
          );
          break;
        case 'WaitingForSetPassword':
          yield put(
            push(routePath.forgotPassword, {
              descriptionKey: 'web_sifre_bekleyen_uye_desc',
              countryCode: CountryCode,
              phoneNumber: parsedCellPhone,
              userType: '7',
            })
          );
          break;
        case 'OldCustomer':
          yield put(
            push(routePath.forgotPassword, {
              descriptionKey: 'web_eskiuye_desc',
              countryCode: CountryCode,
              phoneNumber: parsedCellPhone,
              userType: '8',
            })
          );
          break;
        default:
          break;
      }
    } else {
      yield put(showToastify({ toastMessage: errorMessage, type: 'error' }));
      yield put(onCheckNumberRequestError(errorMessage));
    }
  } catch (error: any) {
    yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
    yield put(onCheckNumberRequestError(error));
  }
}

function* handlesSetNormalizeWelcometates() {
  try {
    yield put(closeOTPDialog());
    yield put(resetSignupStep());
    yield put(decrementLoginFormStep());
    yield put(setActiveCategory(0));
    yield put(onGetFaqRequest());
    yield put(setCreditCardList());
  } catch (error) {
    console.log(error);
  }
}

// Watchers
function* welcomeSagasWatcher() {
  yield takeLatest(setWelcomeStateNormalize, handlesSetNormalizeWelcometates);
  yield takeLatest(onCheckNumberRequest, handleOnCheckNumberRequestSaga);
}

// eslint-disable-next-line
export default welcomeSagasWatcher;
