import * as React from 'react';

function CameraIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="jikx39i5ga"
          d="M11.614 0c.733 0 1.332.587 1.332 1.307 0 .723.6 1.31 1.332 1.31h2.397c.732 0 1.328.614 1.325 1.367v8.65c0 .749-.593 1.366-1.328 1.366H1.328C.603 14 0 13.386 0 12.633V3.984c0-.75.6-1.366 1.328-1.366H3.72c.736 0 1.338-.591 1.338-1.31C5.057.587 5.653 0 6.39 0zM9 4.223c-2.112 0-3.826 1.752-3.826 3.912 0 2.166 1.717 3.914 3.826 3.914 2.112 0 3.82-1.748 3.82-3.914 0-2.16-1.708-3.912-3.82-3.912zM9 5.75c1.288 0 2.333 1.07 2.333 2.389 0 1.32-1.045 2.389-2.333 2.389-1.288 0-2.333-1.07-2.333-2.39C6.667 6.82 7.712 5.75 9 5.75zm6.266-2.206c-.366 0-.668.309-.668.684 0 .378.302.683.668.683.372 0 .667-.305.667-.683 0-.379-.295-.684-.667-.684z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-596.000000, -692.000000) translate(580.000000, 678.000000) translate(16.000000, 14.000000)"
              />
              <g transform="translate(-596.000000, -692.000000) translate(580.000000, 678.000000) translate(16.000000, 14.000000) translate(3.000000, 5.000000)">
                <mask id="zpfimsde4b" fill="#fff">
                  <use xlinkHref="#jikx39i5ga" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#jikx39i5ga" />
                <g fill="#383C52" mask="url(#zpfimsde4b)">
                  <path d="M0 0H24V24H0z" transform="translate(-3.000000, -5.000000)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CameraIcon;
