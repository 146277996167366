import { FC, memo, useState } from 'react';
import { useLocalization } from 'Hooks';
import classNames from 'classnames';
import { localStorageKeys } from 'Config';

import CardBackEnPath from 'Assets/Images/cards/cardbacken.png';
import CardFrontEnPath from 'Assets/Images/cards/cardfronten.png';
import CardBackTrPath from 'Assets/Images/cards/cardbacktr.png';
import CardFrontTrPath from 'Assets/Images/cards/cardfronttr.png';

import './CardVisual.scss';

type CardVisualProps = {};

const CardVisual: FC<CardVisualProps> = memo(() => {
  const [active, setActive] = useState<boolean>(true);

  const { web_btn_newcard, web_btn_oldcard } = useLocalization();

  const language = localStorage.getItem(localStorageKeys.i18nextLng);

  return (
    <div id="cardVisual">
      <div className="card-visual__container">
        <div className="card-visual__nav">
          <button
            onClick={() => setActive(true)}
            className={classNames({
              'card-visual__nav-active': active,
            })}
          >
            {web_btn_newcard}
          </button>
          <button
            onClick={() => setActive(false)}
            className={classNames({
              'card-visual__nav-active': !active,
            })}
          >
            {web_btn_oldcard}
          </button>
        </div>
        <div></div>
        <div className="card-visual__img">
          {active && <div>{language === 'en' ? <img src={CardFrontEnPath} /> : <img src={CardFrontTrPath} />}</div>}
          {!active && <div>{language === 'en' ? <img src={CardBackEnPath} /> : <img src={CardBackTrPath} />}</div>}
        </div>
      </div>
    </div>
  );
});

export default CardVisual;
