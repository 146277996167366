import { isValid, parse } from 'date-fns';

const MIN_YEAR_FROM_NOW = 1;
const MAX_YEAR_FROM_NOW = 120;

const dateValidation = (date?: string) => {
  if (date?.length === 10) {
    const formattedDate = parse(date!, 'dd.MM.yyyy', new Date());
    const inputDate = new Date(formattedDate);

    if (isValid(inputDate)) {
      const inputDateYear = inputDate.getFullYear();
      const todayYear = new Date().getFullYear();

      if (inputDateYear <= todayYear - MIN_YEAR_FROM_NOW && inputDateYear >= todayYear - MAX_YEAR_FROM_NOW) {
        return true;
      }
    } else {
      return false;
    }
  }
  return false;
};

export default dateValidation;
