import { OnCheckPhoneNumberModel } from 'Models';

export interface WelcomeState {
  isLoading: boolean;
  error: string;
  onCheckPhoneNumberData: OnCheckPhoneNumberModel | undefined;
  CountryCode: string;
  Cellphone: string;
}

export const customerStatusResult = {
  '-1': 'Passive',
  0: 'Passive',
  1: 'Active',
  2: 'WaitingForSmsApproval',
  3: 'Blocked',
  4: 'WaitingForEmailApproval',
  5: 'BlockedFromWrongAccess',
  6: 'Contant',
  7: 'WaitingForSetPassword',
  8: 'OldCustomer',
};
