import { axiosHelper } from 'Services/AxiosHelper';
import { OnInıtModel } from 'Models';
import { onInıtBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnInit';

const onInıtData = async (Language: string): Promise<OnInıtModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: { Language },
    })
  ).data;

export default onInıtData;
