import { FC, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import './Map.scss';
import {
  CurrentLocationIcon,
  FoodPointIcon,
  LoadPointIcon,
  MarketPointIcon,
  OtherPointIcon,
  PlaceIcon,
  TravelPointIcon,
} from 'Components/Svg';
import { PinsItemModel } from 'Models';
import { mapDirection, stringToLocation, VIEW_TYPE } from 'Utils';

import { PointList } from 'Components/PointList';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  mapStore,
  setDiscoverCurrentMapLocation,
  setDiscoverSelectedPinFromList,
  setDiscoverViewType,
} from 'Stores/Maps';

const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();

type MapProps = {
  height?: string;
  children?: JSX.Element;
  onClickFunction?: Function;
};

const Map: FC<MapProps> = ({ height, children, onClickFunction }) => {
  const dispatch = useDispatch();
  const mapState = useSelector(mapStore);

  const {
    zoom,
    discoverSelectedPin,
    discoverSelectedPinFromList,
    discoverCurrentMapLocation,
    userLocation,
    discoverIsDirectionEnable,
    discoverAllPins,
    discoverViewType,
  } = mapState;

  const [mapRender, setMapRender] = useState<any>();

  useEffect(() => {
    directionsRenderer.setMap(null);
    directionsRenderer.setOptions({ suppressMarkers: true });
    mapDirection({
      discoverIsDirectionEnable,
      directionsService,
      directionsRenderer,
      mapRender,
      userLocation,
      discoverSelectedPin,
    });
  }, [discoverSelectedPin, discoverIsDirectionEnable, mapRender]);

  useEffect(() => {
    if (discoverSelectedPinFromList && mapRender) {
      mapRender.setCenter({
        lat: stringToLocation(discoverSelectedPinFromList.Latitude),
        lng: stringToLocation(discoverSelectedPinFromList.Longitude),
      });
      mapRender.setZoom(19);
    }
  }, [discoverSelectedPinFromList, mapRender]);

  const renderIcon = (tagId: number): JSX.Element => {
    switch (tagId) {
      case 1:
        return <OtherPointIcon className="map__location-icon" />;
      case 2:
        return <TravelPointIcon className="map__location-icon" />;
      case 3:
        return <FoodPointIcon className="map__location-icon" />;
      case 4:
        return <LoadPointIcon className="map__location-icon" />;
      case 5:
        return <MarketPointIcon className="map__location-icon" />;

      default:
        return <MarketPointIcon className="map__location-icon" />;
    }
  };

  const setMapCenter = () => {
    mapRender.setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
    mapRender.setZoom(16);
  };

  const onSelectedPointFromList = (item: PinsItemModel) => {
    dispatch(setDiscoverViewType());
    dispatch(setDiscoverSelectedPinFromList(item));
  };

  const setMapCenterLocation = () => {
    if (mapRender && !discoverIsDirectionEnable) {
      const location = {
        latitude: mapRender.getCenter().lat(),
        longitude: mapRender.getCenter().lng(),
      };

      dispatch(setDiscoverCurrentMapLocation({ ...location }));
    }
  };

  return (
    <div id="map">
      {children}
      <Box zIndex={discoverViewType === VIEW_TYPE.MAP ? 1 : -1} onClick={setMapCenter} className="map__currentLocation">
        <PlaceIcon className="map__currentLocation__icon" />
      </Box>
      {discoverViewType === VIEW_TYPE.MAP ? (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyDz4VkQWoG5biNiKmwCGNkL_ocDVQCJenk' }}
          center={{ lat: discoverCurrentMapLocation.latitude, lng: discoverCurrentMapLocation.longitude }}
          defaultZoom={zoom}
          options={{
            fullscreenControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
          }}
          style={{ minHeight: '30vh', height }}
          onGoogleApiLoaded={({ map }) => {
            setMapRender(map);
          }}
          onChange={setMapCenterLocation}
        >
          <Marker
            icon={<CurrentLocationIcon className="map__current-icon" />}
            lat={userLocation.latitude}
            lng={userLocation.longitude}
          />

          {discoverAllPins &&
            discoverAllPins.map((item: any, index: any) => {
              return (
                <Marker
                  icon={renderIcon(item.Tags[0])}
                  onClickFunction={onClickFunction}
                  item={item}
                  lat={stringToLocation(item.Latitude)}
                  lng={stringToLocation(item.Longitude)}
                  key={index}
                />
              );
            })}
        </GoogleMapReact>
      ) : (
        <PointList onSelectedPointFromList={onSelectedPointFromList} />
      )}
    </div>
  );
};
export default Map;

type MarkerProps = {
  lat?: number;
  lng?: number;
  item?: any;
  onClickFunction?: Function;
  icon: JSX.Element;
};

const Marker: FC<MarkerProps> = ({ item, onClickFunction, icon }) => {
  return (
    <div onClick={() => onClickFunction && onClickFunction(item)} id="marker" itemID="discoverChooseLocationBtnTest">
      {icon}
    </div>
  );
};
