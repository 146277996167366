import * as React from 'react';

function IbmPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="33"
      height="40"
      viewBox="0 0 33 40"
      {...props}
    >
      <defs>
        <path
          id="lvsdo3vxla"
          d="M9.5 17.417c4.372 0 7.917-3.545 7.917-7.917S13.872 1.583 9.5 1.583 1.583 5.128 1.583 9.5c0 2.1.834 4.113 2.32 5.598C5.386 16.583 7.4 17.417 9.5 17.417zm-2.921-9.39l2.375-2.264c.034-.033.074-.06.118-.079.038-.033.08-.06.127-.079.192-.085.41-.085.602 0 .097.038.186.094.261.166l2.375 2.375c.31.31.31.814 0 1.124-.31.31-.814.31-1.124 0l-1.021-1.029v4.426c0 .437-.355.791-.792.791-.437 0-.792-.354-.792-.791V8.186l-1.037.99c-.317.301-.818.289-1.12-.028-.302-.317-.29-.819.028-1.12z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#FFF"
              fillRule="nonzero"
              stroke="#1AAADC"
              strokeWidth="2"
              d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691h0c6.072-6.008 6.072-15.795.033-21.803z"
              transform="translate(-230.000000, -412.000000) translate(231.000000, 413.000000)"
            />
            <g transform="translate(-230.000000, -412.000000) translate(231.000000, 413.000000) translate(6.000000, 5.000000)">
              <path d="M0 0H19V19H0z" />
              <mask id="gk8ifesgab" fill="#fff">
                <use xlinkHref="#lvsdo3vxla" />
              </mask>
              <g fill="#1AAADC" mask="url(#gk8ifesgab)">
                <path d="M0 0H19V19H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IbmPin;
