import * as React from 'react';

function FoodIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="5d01s74v8a"
          d="M7.7 0C8.798 0 10 1.877 10 3.57c0 1.353-.653 2.184-1.588 2.46l.119 7.693c.015.492-.371.892-.846.892s-.846-.4-.846-.892l.119-7.692c-.92-.293-1.573-1.108-1.573-2.462C5.385 1.877 6.602 0 7.7 0zM.39 0c.217 0 .389.174.389.395v3.302c0 .095.077.174.17.174h.421c.094 0 .172-.079.172-.174V.411c0-.221.17-.395.389-.395.202 0 .374.174.39.379v3.302c0 .095.077.174.17.174h.42c.094 0 .172-.079.172-.174V.411c0-.221.171-.395.39-.395.202 0 .373.174.373.379v4.013c0 .664-.498 1.233-1.152 1.296l.109 7.995c.015.506-.39.916-.888.916s-.887-.41-.887-.916l.124-8.01C.514 5.624 0 5.071 0 4.392V.395C0 .174.171 0 .39 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H20V20H0z"
                  transform="translate(-348 -174) translate(155 170) translate(185) translate(8 4)"
                />
                <g transform="translate(-348 -174) translate(155 170) translate(185) translate(8 4) translate(5 2.5)">
                  <mask id="zszmisjfbb" fill="#fff">
                    <use xlinkHref="#5d01s74v8a" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#5d01s74v8a" />
                  <g fill={props.fill ? props.fill : '#1AAADC'} mask="url(#zszmisjfbb)">
                    <path d="M0 0H20V20H0z" transform="translate(-5 -2.5)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FoodIcon;
