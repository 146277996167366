import { BaseModelStatusType, BaseDataModel } from 'Models';
import { get } from 'lodash';
import { useLocalization } from 'Hooks';
import { applicationStore } from 'Stores/CardApplication';
import { storeCmsData } from 'Stores/App';

const isBelbimRequestSuccess = (response: {
  status: BaseModelStatusType;
  data: BaseDataModel;
}): { requestSuccess: boolean; errorMessage: string; responseCode: string } => {
  const status = get(response, 'status');
  const responseCode = get(response, 'data.ResponseCode');

  if (status === 'success' && responseCode === '0') {
    return {
      requestSuccess: true,
      errorMessage: '',
      responseCode,
    };
  } else {
    const errorMessage =
      status === 'success' ? get(response, 'data.ResponseDescription') : 'Bir hata oluştu. Lütfen sayfayı yenileyin.';

    return {
      requestSuccess: false,
      errorMessage,
      responseCode,
    };
  }
};

export default isBelbimRequestSuccess;
