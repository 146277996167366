import { errorMessages } from 'Config';
import moment from 'moment';
import * as yup from 'yup';

export const AddCreditCardSchema = (condition: boolean): AddCreditCardSchemaInputs => {
  return yup.object({
    creditCardNumber: yup.string().required(errorMessages.require).min(19, errorMessages.minCreditCard),
    expireDate: yup
      .string()
      .required(errorMessages.require)
      .min(5, errorMessages.minExpireDate)
      .test('expireDate', errorMessages.invalidExpireDate, (expireDate?: string | null) => {
        expireDate = expireDate || '';
        if (expireDate.slice(0, 2) == '00') {
          return false;
        } else if (+expireDate.slice(0, 2) <= 12) {
          return true;
        } else {
          return false;
        }
      })
      .test('expireDate', errorMessages.invalidExpireDate, (expireDate?: string | null) => {
        expireDate = expireDate || '';
        if (+expireDate.slice(3, 5) + 2000 >= moment().year()) {
          return true;
        } else {
          return false;
        }
      }),

    cvv: yup.string().required(errorMessages.require).min(3, errorMessages.minCvv),
    cardName: yup.string().when('addCardToMasterPass', {
      is: true,
      then: yup.string().required(errorMessages.require),
      otherwise: yup.string(),
    }),
    addCardToMasterPass: condition ? yup.boolean() : yup.boolean().oneOf([true], errorMessages.checkboxTrue),
  });
};

export type AddCreditCardSchemaInputs = yup.AnyObjectSchema;

export const addCreditCardSchemaInitialValues: InitialValues = {
  creditCardNumber: '',
  expireDate: '',
  cvv: '',
  cardName: '',
  addCardToMasterPass: false,
};
type InitialValues = {
  creditCardNumber: string;
  expireDate: string;
  cvv: string;
  cardName: string;
  addCardToMasterPass: boolean;
};
