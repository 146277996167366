import * as React from 'react';

function OtherIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <path
          id="fjgasydt9a"
          d="M1.667 0c.92 0 1.666.747 1.666 1.667s-.746 1.666-1.666 1.666C.747 3.333 0 2.587 0 1.667 0 .747.747 0 1.667 0zM7.5 0c.92 0 1.667.747 1.667 1.667S8.42 3.333 7.5 3.333s-1.667-.746-1.667-1.666C5.833.747 6.58 0 7.5 0zm5.833 0C14.253 0 15 .747 15 1.667s-.747 1.666-1.667 1.666-1.666-.746-1.666-1.666c0-.92.746-1.667 1.666-1.667z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H20V20H0z"
                  transform="translate(-840.000000, -174.000000) translate(155.000000, 170.000000) translate(677.000000, 0.000000) translate(8.000000, 4.000000)"
                />
                <g transform="translate(-840.000000, -174.000000) translate(155.000000, 170.000000) translate(677.000000, 0.000000) translate(8.000000, 4.000000) translate(2.500000, 8.333333)">
                  <mask id="q93l1ado9b" fill="#fff">
                    <use xlinkHref="#fjgasydt9a" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#fjgasydt9a" />
                  <g fill="#30ABAB" mask="url(#q93l1ado9b)">
                    <path d="M0 0H20V20H0z" transform="translate(-3.333333, -9.166667)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OtherIcon;
