import { FC, Fragment } from 'react';
import { ArrowLeftIcon, ArrowUpIcon, BigTransactionIcon, SettingsIcon, BalanceIcon, DashboardLayout } from 'Components';
import HeartLogo from 'Assets/Images/cardTypes/istanbul_heart_half.png';
import HeartLogoMobile from 'Assets/Images/cardTypes/istanbul_heart_mobile.png';
import { routePath } from 'Navigator/routes';
import { useHistory } from 'react-router-dom';
import { Box, BoxProps, Grid } from '@material-ui/core';
import { colors, deviceWidth, fontSizes, gutters } from 'Theme/Variables';
import { cardBalanceNormalize, IbanNormalize, isAdult, styleNumParse } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import { setDigitalCardActive, setIsUserInUploadTLProcess } from 'Stores/CardTransactions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDigitalAccount, useLocalization, useModal, useWindowDimensions } from 'Hooks';
import { CalendarIcon, CopyIcon } from './Icons';
import { CopyTooltip } from './CopyTooltip';
import { MetroIcon } from 'Components/Svg';
import { VirtualCardItem } from 'Views/UserCards/AllCards/VirtualCardItem';
import { InfoBox } from './InfoBox';

import './DigitalAccount.scss';
import { VirtualCardInformation } from 'Views/UserCards/AllCards/VirtualCardInformation';
import { UserState, userStore } from 'Stores/User';

type ProcessButtonProps = BoxProps & {
  title: string;
  icon: React.ReactNode;
};

const ProcessButton: FC<ProcessButtonProps> = ({ title, icon, ...boxProps }) => {
  return (
    <Box className="content__process-button" {...boxProps}>
      <Box>{icon}</Box>
      <Box
        fontWeight={700}
        fontSize={fontSizes.small}
        color={colors.secondary}
        textAlign={'center'}
        className="content__process-button__text"
      >
        {title}
      </Box>
    </Box>
  );
};

const DigitalAccount: FC = () => {
  const {
    web_digital_account_title,
    web_digital_account_balance_desc,
    web_digital_account_cash_balance,
    web_emergency_limit_user_balance_text,
    web_digital_account_iban,
    web_digital_account_transaction_recommendations,
    web_dijital_account_transactions,
    web_transactions_upload_tl,
    web_transaction_list_micro_credit,
    web_cards_connected_to_my_account,
    web_my_digital_account_copy_btn,
    web_my_digital_account_copy_btn_success,
    web_transactions_transfer_money,
  } = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const digitalAccount = useDigitalAccount();
  const dimensions = useWindowDimensions();
  const { isVisible, show, hide } = useModal();
  const userData: UserState = useSelector(userStore);

  const isUserAdult = isAdult(userData?.birthDate);
  const isKycDone = userData?.isKYCCompleted;

  const isCashBalanceShow = isUserAdult ? true : isKycDone ? true : false;

  const balanceGradient =
    digitalAccount?.AccountProductColor === 'Yeşil'
      ? 'linear-gradient(348deg, #00BABE 0%, #00878A 100%)'
      : 'linear-gradient(348deg, #7b9bff 0%, #1547e1 100%)';

  const cashBalance = digitalAccount.balances.find((i) => i.BalanceCategoryCode === 'Cash')?.Balance ?? 0;
  const isShowLimit = digitalAccount.balances.find((i) => i.BalanceCategoryCode === 'MerchantPayment')
    ?.NegativeBalanceAllowed;

  return (
    <DashboardLayout noContainer marginTop={0}>
      <Fragment>
        <Box id="digitalCardPage">
          <Box className="header" style={{ background: balanceGradient }}>
            <img className="header__heart-web" src={HeartLogo} alt="" />
            <img className="header__heart-mobile" src={HeartLogoMobile} alt="" />

            <Box className="header__container">
              <Box className="header__back-button">
                <ArrowLeftIcon onClick={() => history.push(routePath.cards)} className="cardDetailsHeader__backIcon" />
              </Box>
              <Box className="header__title-container">
                <Box className="header__title" letterSpacing={'-1px'}>
                  {web_digital_account_title}
                </Box>
                <SettingsIcon
                  className="header__settings-button"
                  onClick={() => history.push(routePath.digitalCardSettings)}
                />
              </Box>
              <InfoBox
                title={web_digital_account_balance_desc}
                value={cardBalanceNormalize(digitalAccount.balanceTotal)}
                spaceBetween="large"
                tl
              />
              <Box display={'flex'} marginTop={'16px'} marginBottom={'16px'}>
                {isKycDone && (
                  <InfoBox
                    title={web_digital_account_cash_balance}
                    value={cardBalanceNormalize(cashBalance)}
                    tl
                    marginRight={'24px'}
                  />
                )}
                {isShowLimit && isCashBalanceShow && (
                  <InfoBox
                    title={web_emergency_limit_user_balance_text}
                    value={cardBalanceNormalize(digitalAccount.readyLinitAvailable)}
                    tl
                  />
                )}
              </Box>
              {digitalAccount.iban !== '' && isCashBalanceShow && (
                <Box display={'flex'} alignItems={'flex-end'}>
                  <InfoBox
                    title={web_digital_account_iban}
                    value={IbanNormalize(digitalAccount.iban)}
                    spaceBetween={'small'}
                    iban
                  />
                  <CopyToClipboard onCopy={() => {}} text={digitalAccount.iban}>
                    <Box className="header__copy-button" onClick={show}>
                      {isVisible && <CopyTooltip title={web_my_digital_account_copy_btn_success} onClose={hide} />}
                      <CopyIcon color={'rgba(255, 255, 255, 0.70)'} />
                      <Box
                        className="header__copy-button__text"
                        fontWeight={500}
                        fontSize={fontSizes.regular}
                        color={'rgba(255, 255, 255, 0.70)'}
                      >
                        {web_my_digital_account_copy_btn}
                      </Box>
                    </Box>
                  </CopyToClipboard>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="content-container">
            <Box className="content">
              <ProcessButton
                onClick={() => {
                  history.push(routePath.cardTransactionHistory);
                }}
                title={web_dijital_account_transactions}
                icon={<CalendarIcon className="content__icon" />}
              />
              <Box
                fontWeight={700}
                fontSize={fontSizes.regularBig}
                color={colors.secondary}
                className="content__action-title"
              >
                {web_digital_account_transaction_recommendations}
              </Box>
              <Box className="content__process-button-container">
                <ProcessButton
                  onClick={() => {
                    dispatch(setIsUserInUploadTLProcess(true));
                    dispatch(setDigitalCardActive());
                    history.push(routePath.addBalanceToCard);
                  }}
                  title={web_transactions_upload_tl}
                  icon={<ArrowUpIcon className="content__icon" />}
                />
                <ProcessButton
                  onClick={() => {
                    history.push('/transactions/balanceTransfer');
                  }}
                  title={web_transactions_transfer_money}
                  icon={<BigTransactionIcon className="content__icon" />}
                />
                <ProcessButton
                  title={web_transaction_list_micro_credit}
                  onClick={() => history.push('/transactions/microCreditOperation')}
                  icon={
                    dimensions.width < styleNumParse(deviceWidth.tiny) ? (
                      <MetroIcon className="content__icon" />
                    ) : (
                      <BalanceIcon width={'40px'} height={'40px'} className="content__icon" />
                    )
                  }
                />
              </Box>
            </Box>
            {isCashBalanceShow && (
              <Box className="content-container__cards">
                <Fragment>
                  <Box>
                    <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.secondary}>
                      {web_cards_connected_to_my_account}
                    </Box>
                  </Box>
                  <Box marginBottom={gutters.big} marginTop={'16px'}>
                    <Grid container spacing={3}>
                      <VirtualCardItem virtualCard={digitalAccount.cardMasters.find((card) => card.IsVirtualCard)} />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item lg={4} sm={6} xs={12}>
                        <VirtualCardInformation />
                      </Grid>
                    </Grid>
                  </Box>
                </Fragment>
              </Box>
            )}
          </Box>
        </Box>
      </Fragment>
    </DashboardLayout>
  );
};

export default DigitalAccount;
