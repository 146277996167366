import { Loading } from 'Components';
import { useQuery } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { loginWithOpenId } from 'Services/Queries';
import { onGetCardListRequest } from 'Stores/CardTransactions';
import { setCustomerNumber, setMiniAppUserInformation, setSessionToken } from 'Stores/User';
import { setUserNumber } from 'Stores/Welcome';
import { getEnvironment, getPublicIpFromStorage } from 'Utils';

const isDev = getEnvironment();

const CLIENT_ID = 'dolutlyukle';
const CLIENT_ID_PROD = 'dolutlyukle';
const CURRENT_CLIENT_ID = isDev ? CLIENT_ID : CLIENT_ID_PROD;

const LOGIN_REDIRECT_URL_PROD =
  'https://ibb03.api.ibb-prod.istanbulsenin.kobil.com/auth/realms/ibb03/protocol/openid-connect/auth';
const LOGIN_REDIRECT_URL_TEST =
  'https://ibb01.api.ibb-test.2ndfloor.aws-dev.kobil.com/auth/realms/ibb01/protocol/openid-connect/auth';
const LOGIN_REDIRECT_URL = isDev ? LOGIN_REDIRECT_URL_TEST : LOGIN_REDIRECT_URL_PROD;

const NONCE = Math.floor(Math.random() * 1000000);

const MicroCreditMiniApp: FC = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const REDIRECT_URL = `${protocol}//${host}/microcredit`;

  const url = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const session_state = url.get('session_state');
  const code = url.get('code');

  const redirectToLogin = () => {
    window.location.href = `${LOGIN_REDIRECT_URL}?client_id=${CURRENT_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=openid&response_type=code&response_mode=query&nonce=${NONCE}`;
  };

  const loginRequest = async () => {
    const loginResult = await loginWithOpenId({
      Code: code!,
      RedirectUri: REDIRECT_URL,
      ClientIP: getPublicIpFromStorage() || '',
    });

    const customerNumber = loginResult?.data?.CustomerInfo?.CustomerNumber;
    const sessionToken = loginResult?.data?.SessionToken;
    const Cellphone: string = loginResult?.data?.CustomerInfo?.Cellphone;
    const Email: string = loginResult?.data?.CustomerInfo?.Email;
    const Name: string = loginResult?.data?.CustomerInfo?.Name;
    const Surname: string = loginResult?.data?.CustomerInfo?.Surname;
    const BirthDate: string = loginResult?.data?.CustomerInfo?.BirthDate;
    const CountryCode: string = loginResult?.data?.CustomerInfo?.CountryCode;
    const DigitalAccount: string = loginResult?.data?.DigitalAccount;
    const HESCode: string = loginResult?.data?.CustomerInfo?.HesCode;
    const CurrentAgreementList: any = loginResult?.data?.CurrentAgreementList;

    dispatch(setCustomerNumber(customerNumber));
    dispatch(setSessionToken(sessionToken));
    dispatch(setUserNumber({ Cellphone, CountryCode }));
    dispatch(
      setMiniAppUserInformation({
        Email,
        Name,
        BirthDate,
        Surname,
        DigitalAccount,
        HESCode,
        CurrentAgreementList,
      })
    );
    dispatch(
      onGetCardListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );

    history.push(routePath.microCreditOperation);
  };

  useEffect(() => {
    if (!code && !session_state) {
      redirectToLogin();
    } else {
      loginRequest();
    }
  }, []);

  return <Loading />;
};

export default MicroCreditMiniApp;
