import React, { FunctionComponent, ReactNode } from 'react';
import { Checkbox } from 'Components';
import { Typography, Link, CheckboxProps as MaterialUICheckBoxProps } from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';

type CheckboxUnderlineType = {
  text?: string;
  content?: ReactNode;
  errorText?: string;
  verticallyCentered?: boolean;
  marginBottomGutter?: string;
  onUnderlineTextClick?: Function;
} & MaterialUICheckBoxProps;

const CheckboxUnderlineLabel: FunctionComponent<CheckboxUnderlineType> = ({
  text,
  content,
  onUnderlineTextClick,
  verticallyCentered,
  ...props
}) => {
  const regex = /{(.*?)}/gi;
  const generateLinkedText = () => {
    if (text?.includes('{')) {
      const values = regex.exec(text || '')?.filter((item: string) => !item?.includes('{'));
      const parts = text?.split(regex).map((item: string, index: number) => {
        const key = nanoid();
        if (values?.includes(item)) {
          return (
            <Link
              key={key}
              variant="body1"
              onClick={() => onUnderlineTextClick && onUnderlineTextClick()}
              underline="always"
              color="primary"
            >
              {values?.[0]}
            </Link>
          );
        }
        return <span key={key}>{item}</span>;
      });
      return parts;
    }
    return text;
  };
  // const parts = !keyValue
  //   ? text
  //   : text?.split(keyValue)?.map((part, index) => {
  //       if ((!!keyValue && part === keyValue) || part === '') {
  //         // const finalText = textValue?.startsWith('%') ? textValue?.substr(1) : textValue;
  //         const finalText =
  //         return (
  //           <Link
  //             key={index}
  //             variant="body1"
  //             onClick={() => onUnderlineTextClick && onUnderlineTextClick()}
  //             underline="always"
  //             color="primary"
  //           >
  //             {finalText}
  //           </Link>
  //         );
  //       }
  //       return part;
  //     });

  return (
    <Checkbox
      label={
        <Typography
          variant="body1"
          style={verticallyCentered ? { display: 'flex', alignItems: 'center' } : {}}
          gutterBottom={!verticallyCentered}
        >
          {content || generateLinkedText()}
        </Typography>
      }
      {...props}
    />
  );
};

export default CheckboxUnderlineLabel;
