import * as React from 'react';

function MarketPointIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="37"
      viewBox="0 0 31 37"
      {...props}
    >
      <defs>
        <path
          id="80njbyw5sa"
          d="M18 5.143v1.286c0 .71-.576 1.285-1.286 1.285H1.286C.576 7.714 0 7.14 0 6.43V5.143c0-.71.576-1.286 1.286-1.286h15.428c.71 0 1.286.576 1.286 1.286zm-4.089-1.929h-1.697m4.957 5.079c-.149.042-.302.064-.457.064H1.286c-.161.001-.321-.02-.476-.064h16.36zM7.483.148c-.131-.11-.3-.162-.47-.146-.17.015-.328.098-.437.23L4.09 3.213h1.697l1.8-2.16c.107-.133.156-.304.137-.474-.02-.17-.106-.326-.24-.432zm6.428 3.066L11.424.231c-.147-.176-.378-.261-.604-.223-.227.04-.416.196-.495.412-.08.216-.038.457.109.634l1.78 2.16h1.697zm3.279 5.079l-.964 6.763c-.136.958-.96 1.668-1.929 1.658H3.684c-.966.01-1.79-.696-1.929-1.652L.81 8.292h16.38zm-10.761 6.39l-.643-3.857c-.037-.23-.194-.423-.41-.506-.218-.083-.463-.043-.644.103-.18.147-.269.379-.232.608l.643 3.858c.05.314.324.544.643.54h.103c.171-.026.325-.119.427-.26.101-.14.142-.315.113-.486zm3.214-3.754c0-.355-.288-.643-.643-.643-.355 0-.643.288-.643.643v3.857c0 .355.288.643.643.643.355 0 .643-.288.643-.643v-3.857zm3.857.102c.037-.23-.052-.461-.232-.608-.18-.146-.426-.186-.643-.103-.217.083-.374.276-.41.506l-.644 3.857c-.03.17.012.346.113.487.102.14.256.233.427.259h.103c.319.004.592-.226.643-.54l.643-3.858z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#FFCC65"
                fillRule="nonzero"
                d="M26.475 4.506c-6.07-6.008-15.875-6.008-21.946 0-6.039 6.008-6.039 15.795 0 21.835L15.502 37l10.94-10.691c6.072-6.008 6.072-15.795.033-21.803z"
                transform="translate(-537.000000, -388.000000) translate(254.000000, 286.000000) translate(283.000000, 102.000000)"
              />
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-537.000000, -388.000000) translate(254.000000, 286.000000) translate(283.000000, 102.000000) translate(3.500000, 4.000000)"
                />
                <g transform="translate(-537.000000, -388.000000) translate(254.000000, 286.000000) translate(283.000000, 102.000000) translate(3.500000, 4.000000) translate(3.000000, 4.000000)">
                  <mask id="zfq7ci58db" fill="#fff">
                    <use xlinkHref="#80njbyw5sa" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#80njbyw5sa" />
                  <g fill="#F47524" mask="url(#zfq7ci58db)">
                    <path d="M0 0H24V24H0z" transform="translate(-3.000000, -4.000000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MarketPointIcon;
