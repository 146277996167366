import { FC, Fragment, useEffect } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography, Grid } from '@material-ui/core';
import { NoAdress } from './NoAdress';
import { AdressItem } from './AdressItem';
import { AddNewAdress } from './AddNewAdress';
import { UserState, userStore } from 'Stores/User';
import { useDispatch, useSelector } from 'react-redux';
import { AdressItemType } from 'Models/OnGetCustomerInfoModel';
import { useHistory } from 'react-router-dom';
import {
  AccountState,
  onGetCustomerInfoRequest,
  setActiveUserAdress,
  setNormalizeAdressParameters,
  storeAccount,
} from 'Stores/Account';

import './UserAdressPage.scss';

type UserAdressPageProps = {};
const UserAdressPage: FC<UserAdressPageProps> = () => {
  const { web_my_address } = useLocalization();

  const userData: UserState = useSelector(userStore);
  const userAdressList: AdressItemType[] | undefined = userData.adressList;

  const accountData: AccountState = useSelector(storeAccount);
  const isLoading = accountData.loading;

  const dispatch = useDispatch();

  const customerNumber = userData.customerNumber;
  const sessionToken = userData.sessionToken;

  useEffect(() => {
    dispatch(setActiveUserAdress(undefined));
    dispatch(
      onGetCustomerInfoRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(setNormalizeAdressParameters());
  }, []);

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="userAddressPage">
          {(!userAdressList || userAdressList.length === 0) && !isLoading && <NoAdress />}
          {userAdressList && userAdressList.length > 0 && (
            <Box>
              <Typography variant="h3" align="center">
                <Box fontWeight="bold" className="userAddressPage__title">
                  {web_my_address}
                </Box>
              </Typography>
              <Grid container spacing={3}>
                {userAdressList &&
                  userAdressList.map((item: AdressItemType, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      onClick={() => {
                        dispatch(
                          setActiveUserAdress({
                            adressDetail: item.AddressDetail,
                            adressType: item.AddressType.toString(),
                            cityCode: item.CityCode.toString(),
                            town: item.Town,
                            district: item.District,
                            street: item.Street,
                            postalCode: item.PostalCode,
                          })
                        );
                      }}
                    >
                      <AdressItem adressDetail={item.AddressDetail} district={item.Town} />
                    </Grid>
                  ))}
                <Grid item xs={12} sm={6} md={4}>
                  <AddNewAdress />
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default UserAdressPage;
