import { FunctionComponent, useEffect } from 'react';
import { Box, Dialog, DialogProps, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import './DialogWithInput.scss';
import { DialogCloseComponent } from 'Components/DialogCloseComponent';
type DialogWithInputProps = {
  hide: () => void;
  title?: string | null;
  alignTitle?: string;
  primaryButton?: {
    label: string;
    handleClick: Function;
  };
  children?: JSX.Element;
  closeIcon?: boolean;
} & DialogProps;

const DialogWithInput: FunctionComponent<DialogWithInputProps> = ({
  title,
  alignTitle,
  children,
  closeIcon,
  open,
  hide,
}) => {
  return (
    <Dialog open={open} onBackdropClick={hide} id="dialogWithActions" disablePortal={true}>
      {closeIcon && <DialogCloseComponent closeFunction={hide} />}
      <div className="dialogContainer">
        {title && (
          <Box textAlign={alignTitle ? alignTitle : 'center'}>
            <Typography variant="h5">
              <Box fontWeight={600} marginBottom={gutters.regular}>
                {title}
              </Box>
            </Typography>
          </Box>
        )}
        {children}
      </div>
    </Dialog>
  );
};

const DialogTermsWrapper: FunctionComponent<DialogWithInputProps> = (props) => {
  if (props.open) {
    return <DialogWithInput {...props} />;
  } else {
    return null;
  }
};

export default DialogTermsWrapper;
