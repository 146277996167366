import { errorMessages } from 'Config';
import * as yup from 'yup';

export const AutomaticInstructionsSchema = yup.object({
  lowerLimit: yup.number().required(errorMessages.require),
});

export type AutomaticInstructionsSchemaInputs = yup.InferType<typeof AutomaticInstructionsSchema>;

export const AutomaticInstructionsInitialValues = (cardLowerLimit: number) => {
  return {
    lowerLimit: cardLowerLimit,
  };
};
