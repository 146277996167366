import { axiosHelper } from 'Services/AxiosHelper';
import { OnGetCardListModel } from 'Models';
import { onGetCardListBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnGetCardList';

const onConfirmSms = async (params: onGetCardListBodyData): Promise<OnGetCardListModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onConfirmSms;
