import * as React from 'react';

function CurrentLocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="31" height="30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(.5)" fill="none" fill-rule="evenodd">
        <circle fill="#6393F2" fill-rule="nonzero" opacity=".3" cx="15" cy="15" r="15" />
        <g transform="translate(7.5 7.5)">
          <circle fill="#6393F2" fill-rule="nonzero" cx="7.5" cy="7.5" r="6.25" />
          <circle stroke="#FFF" strokeWidth="2" cx="7.5" cy="7.5" r="7.5" />
        </g>
      </g>
    </svg>
  );
}

export default CurrentLocationIcon;
