import { FC, Fragment, SetStateAction, Dispatch, useState } from 'react';
import { CloseIcon, SearchIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { ApplicationCenterListItemModel } from 'Models';

import './ApplicationsCenterDialog.scss';
import { fontSizes } from 'Theme/Variables';
import { turkishCharacterNormalize } from 'Utils';
import { applicationStore } from 'Stores/CardApplication';
import { useSelector } from 'react-redux';

type ApplicationsCenterDialogProps = {
  open: boolean;
  close: Dispatch<SetStateAction<boolean>>;
  setCurrentApplicationCenter: Dispatch<SetStateAction<ApplicationCenterListItemModel | undefined>>;
  currentApplicationCenter?: ApplicationCenterListItemModel;
  applicationCenterList?: ApplicationCenterListItemModel[];
};

type ItemProps = {
  item: ApplicationCenterListItemModel;
  func: Dispatch<SetStateAction<ApplicationCenterListItemModel | undefined>>;
};

const ApplicationsCenterDialog: FC<ApplicationsCenterDialogProps> = ({
  open,
  close,
  setCurrentApplicationCenter,
  currentApplicationCenter,
  applicationCenterList,
}) => {
  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');
  const { deliveryId } = useSelector(applicationStore);

  return (
    <Dialog
      id="dialogCity"
      onClose={() => close(false)}
      scroll="paper"
      keepMounted={false}
      open={deliveryId == 1 ? open : false}
    >
      <DialogTitle className="dialogCity-search">
        <CloseIcon onClick={() => close(false)} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogCity-container">
          <SearchBlock
            applicationCenterList={applicationCenterList}
            searchKey={searchKey}
            lowerCaseSearchKey={searchKey.toLocaleLowerCase()}
            setCurrentApplicationCenter={setCurrentApplicationCenter}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const SearchBlock: FC<{
  applicationCenterList?: ApplicationCenterListItemModel[];
  searchKey: string;
  lowerCaseSearchKey: string;
  setCurrentApplicationCenter: Dispatch<SetStateAction<ApplicationCenterListItemModel | undefined>>;
}> = ({ applicationCenterList, lowerCaseSearchKey, setCurrentApplicationCenter }) => {
  return (
    <ul className="dialogCity-container__list">
      {!!applicationCenterList &&
        applicationCenterList.length > 0 &&
        applicationCenterList
          .filter((item: ApplicationCenterListItemModel) => {
            return item.ApplicationCenterName?.toLocaleLowerCase()?.includes(lowerCaseSearchKey);
          })
          .map((item: ApplicationCenterListItemModel, index) => (
            <Item key={index} item={item} func={setCurrentApplicationCenter} />
          ))}
    </ul>
  );
};

const Item: FC<ItemProps> = ({ item, func }) => (
  <div
    onClick={() => func(item)}
    className="applicationsCenterDialog__item"
    itemID="chooseCardApplicationCenterBtnTest"
  >
    <Typography variant="h3">
      <Box fontSize={fontSizes.small} fontWeight={600}>
        {item.ApplicationCenterName + ' IBM'}
      </Box>
    </Typography>
    <Typography>
      <Box className="applicationsCenterDialog__item__desc" mt={0.6} fontSize={fontSizes.small} fontWeight="normal">
        {item.Description.toLocaleLowerCase('tr-TR')}
      </Box>
    </Typography>
  </div>
);

export default ApplicationsCenterDialog;
