import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { maintenanceStore, storeHasAccess } from 'Stores/App';
import { Maintenance } from 'Views/Maintenance';
import { routePath } from './routes';

type PrivateRouteProps = {
  component: any;
} & RouteProps;

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const hasAccess = useSelector(storeHasAccess);
  const isMaintenance = useSelector(maintenanceStore);

  return isMaintenance ? (
    <Maintenance />
  ) : (
    // <Route
    //   {...rest}
    //   render={(props) => (hasAccess ? <Component {...props} /> : <Redirect to={routePath.access} />)}
    // ></Route>
    <Route {...rest} render={(props) => (hasAccess ? <Component {...props} /> : <Component {...props} />)}></Route>
  );
};

export default PrivateRoute;
/*

*/
