import { FC, useState, ChangeEvent, useRef, LegacyRef, useCallback, SetStateAction, Dispatch } from 'react';
import { useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { checkCameraPermission, getBase64ImageType, getImageFromDevice } from 'Utils';
import { BigCirclePlus, CameraIcon, DialogWithActions, PhotoStepPopup } from 'Components';

import Webcam from 'react-webcam';
import moment from 'moment';

import './ChangePhoto.scss';

type ChangePhotoProps = {
  setFileName: Dispatch<SetStateAction<string>>;
  setPhotoData: Dispatch<SetStateAction<string>>;
  setMimType: Dispatch<SetStateAction<string>>;
  setIsChangePhoto: Dispatch<SetStateAction<boolean>>;
};

const ChangePhoto: FC<ChangePhotoProps> = ({ setFileName, setPhotoData, setMimType, setIsChangePhoto }) => {
  const {
    web_card_application_add_photo,
    web_photo_adding_method_pc,
    web_photo_adding_method_webcam,
    web_photo_adding_method_title,
    web_photo_click_to_change,
    web_card_application_click_to_add,
    web_btn_next,
    web_btn_vazgec,
    web_take_photo_btn,
    web_photo_rules_popup_btn,
    web_btn_okay,
    web_photo_rules_link_btn,
  } = useLocalization();

  const webcamRef: any = useRef(null);
  const inputRef: LegacyRef<HTMLInputElement> | undefined = useRef(null);

  const [isOpenPhotoDialog, setIsOpenPhotoDialog] = useState<boolean>(false);
  const [isPermissionGranted, setIsPermissionGrandted] = useState<boolean>(false);
  const [isOpenCamera, setIsOpenCamera] = useState<boolean>(false);
  const [imageType, setImageType] = useState<string>('');
  const [photoSrc, setPhotoSrc] = useState<string | undefined>();
  const [isOpenRuleDialog, setIsOpenRuleDialog] = useState<boolean>(false);
  const [isOpenIntroDialog, setIsOpenIntroDialog] = useState<boolean>(false);

  const capture = useCallback(() => {
    const imageSrc: string = webcamRef?.current?.getScreenshot();
    setImageType(getBase64ImageType(imageSrc));
    setIsOpenCamera(false);
    setPhotoSrc(imageSrc);
  }, [webcamRef]);

  const getCameraPermission = async () => {
    setIsOpenCamera(true);
    await checkCameraPermission(setIsPermissionGrandted);
  };

  const choosedFromPc = async (event: ChangeEvent<HTMLInputElement>) => {
    const { type, src } = await getImageFromDevice(event);
    setImageType(type);
    src !== '' && setPhotoSrc(src);
    setIsOpenPhotoDialog(false);
  };

  const confirmImage = () => {
    const src = photoSrc?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
    setFileName(String(moment(new Date()).format('ddMMyyyyhhmmss')));
    setMimType(imageType);
    setPhotoData(src ?? '');
    setIsChangePhoto(false);
  };

  return (
    <div id="changePhoto">
      <Typography variant="h3" align="center">
        <Box mt={gutters.bigger} fontWeight={600}>
          {web_card_application_add_photo}
        </Box>
      </Typography>

      <input onChange={choosedFromPc} ref={inputRef} accept="image/png, image/jpg, image/jpeg" type="file" hidden />
      <div
        onClick={() => !isOpenCamera && !photoSrc && setIsOpenIntroDialog(true)}
        className="changePhoto__area"
        itemID="updateOpenCameraDialogBtnTest"
      >
        {photoSrc ? (
          <>
            <img src={photoSrc} className="changePhoto__img" />
            <div
              itemID="updateChangePhotoBtnTest"
              onClick={() => {
                setIsOpenPhotoDialog(true);
              }}
              className="changePhoto__change-image"
            >
              {web_photo_click_to_change}
            </div>
          </>
        ) : !isOpenCamera || !isPermissionGranted ? (
          <>
            <BigCirclePlus />
            <Typography variant="h3" align="center">
              <Box color={colors.darkGray} fontSize={fontSizes.smaller}>
                {web_card_application_click_to_add}
              </Box>
            </Typography>
          </>
        ) : (
          <Webcam
            ref={webcamRef}
            videoConstraints={{
              width: 200,
              height: 256,
              facingMode: 'user',
            }}
            screenshotFormat="image/jpeg"
            width={200}
            height={250}
          />
        )}
      </div>
      <Typography variant="h3" align="center">
        {!!photoSrc ? (
          <Box
            onClick={() => setIsOpenRuleDialog(true)}
            className="addPhotoStep__rules"
            mt={gutters.big}
            mb={gutters.big}
            color={colors.primary}
            fontSize={fontSizes.smaller}
            style={{
              cursor: 'pointer',
            }}
            itemID="openPhotoRuleBtnTest"
          >
            {web_photo_rules_link_btn}
          </Box>
        ) : (
          <div style={{ height: 54 }}></div>
        )}
      </Typography>
      <Box mb={gutters.big} />
      {!isOpenCamera || !isPermissionGranted ? (
        <Button
          onClick={() => photoSrc && confirmImage()}
          variant="contained"
          color="secondary"
          fullWidth
          itemID="updateLoadImageBtnTest"
        >
          {web_btn_next}
        </Button>
      ) : (
        <>
          <Button
            startIcon={<CameraIcon />}
            onClick={capture}
            variant="outlined"
            color="secondary"
            fullWidth
            itemID="updateTakePhotoBtnTest"
          >
            {web_take_photo_btn}
          </Button>
          <Box mt={gutters.small} />
          <Button
            style={{ color: 'red' }}
            onClick={() => setIsOpenCamera(false)}
            variant="text"
            color="default"
            fullWidth
            itemID="updateCloseCameraBtnTest"
          >
            {web_btn_vazgec}
          </Button>
        </>
      )}
      <DialogWithActions
        alignTitle="left"
        closeIcon
        primaryButton={{
          handleClick: () => {
            inputRef.current?.click();
          },
          label: web_photo_adding_method_pc,
        }}
        secondaryButton={{
          handleClick: () => {
            setPhotoSrc(undefined);
            getCameraPermission();
            setIsOpenPhotoDialog(false);
          },
          label: web_photo_adding_method_webcam,
          type: 'contained',
        }}
        open={isOpenPhotoDialog}
        hide={() => setIsOpenPhotoDialog(false)}
        title={web_photo_adding_method_title}
      />
      <PhotoStepPopup
        isOpen={isOpenIntroDialog || isOpenRuleDialog}
        buttonText={isOpenRuleDialog ? web_btn_okay : web_photo_rules_popup_btn}
        onClick={() => {
          setIsOpenIntroDialog(false);
          if (!isOpenRuleDialog) {
            setIsOpenPhotoDialog(true);
          }
          setIsOpenRuleDialog(false);
        }}
      />
    </div>
  );
};

export default ChangePhoto;
