export type userInfoTypesProps = {
  phone: number;
  mail: number;
  job: number;
  iban: number;
};

export type cityItemProp = {
  cityCode: string;
  city: string;
};

export const userInfoTypes: userInfoTypesProps = {
  phone: 1,
  mail: 2,
  job: 3,
  iban: 4,
};

export const cityCodes: cityItemProp[] = [
  {
    cityCode: '1',
    city: 'ADANA',
  },
  {
    cityCode: '2',
    city: 'ADIYAMAN',
  },
  {
    cityCode: '3',
    city: 'AFYONKARAHİSAR',
  },
  {
    cityCode: '4',
    city: 'AĞRI',
  },
  {
    cityCode: '5',
    city: 'AMASYA',
  },
  {
    cityCode: '6',
    city: 'ANKARA',
  },
  {
    cityCode: '7',
    city: 'ANTALYA',
  },
  {
    cityCode: '8',
    city: 'ARTVİN',
  },
  {
    cityCode: '9',
    city: 'AYDIN',
  },
  {
    cityCode: '10',
    city: 'BALIKESİR',
  },
  {
    cityCode: '11',
    city: 'BİLECİK',
  },
  {
    cityCode: '12',
    city: 'BİNGÖL',
  },
  {
    cityCode: '13',
    city: 'BİTLİS',
  },
  {
    cityCode: '14',
    city: 'BOLU',
  },
  {
    cityCode: '15',
    city: 'BURDUR',
  },
  {
    cityCode: '16',
    city: 'BURSA',
  },
  {
    cityCode: '17',
    city: 'ÇANAKKALE',
  },
  {
    cityCode: '18',
    city: 'ÇANKIRI',
  },
  {
    cityCode: '19',
    city: 'ÇORUM',
  },
  {
    cityCode: '20',
    city: 'DENİZLİ',
  },
  {
    cityCode: '21',
    city: 'DİYARBAKIR',
  },
  {
    cityCode: '22',
    city: 'EDİRNE',
  },
  {
    cityCode: '23',
    city: 'ELAZIĞ',
  },
  {
    cityCode: '24',
    city: 'ERZİNCAN',
  },
  {
    cityCode: '25',
    city: 'ERZURUM',
  },
  {
    cityCode: '26',
    city: 'ESKİŞEHİR',
  },
  {
    cityCode: '27',
    city: 'GAZİANTEP',
  },
  {
    cityCode: '28',
    city: 'GİRESUN',
  },
  {
    cityCode: '29',
    city: 'GÜMÜŞHANE',
  },
  {
    cityCode: '30',
    city: 'HAKKARİ',
  },
  {
    cityCode: '31',
    city: 'HATAY',
  },
  {
    cityCode: '32',
    city: 'ISPARTA',
  },
  {
    cityCode: '33',
    city: 'MERSİN',
  },
  {
    cityCode: '34',
    city: 'İSTANBUL',
  },
  {
    cityCode: '35',
    city: 'İZMİR',
  },
  {
    cityCode: '36',
    city: 'KARS',
  },
  {
    cityCode: '37',
    city: 'KASTAMONU',
  },
  {
    cityCode: '38',
    city: 'KAYSERİ',
  },
  {
    cityCode: '39',
    city: 'KIRKLARELİ',
  },
  {
    cityCode: '40',
    city: 'KIRŞEHİR',
  },
  {
    cityCode: '41',
    city: 'KOCAELİ',
  },
  {
    cityCode: '42',
    city: 'KONYA',
  },
  {
    cityCode: '43',
    city: 'KÜTAHYA',
  },
  {
    cityCode: '44',
    city: 'MALATYA',
  },
  {
    cityCode: '45',
    city: 'MANİSA',
  },
  {
    cityCode: '46',
    city: 'KAHRAMANMARAŞ',
  },
  {
    cityCode: '47',
    city: 'MARDİN',
  },
  {
    cityCode: '48',
    city: 'MUĞLA',
  },
  {
    cityCode: '49',
    city: 'MUŞ',
  },
  {
    cityCode: '50',
    city: 'NEVŞEHİR',
  },
  {
    cityCode: '51',
    city: 'NİĞDE',
  },
  {
    cityCode: '52',
    city: 'ORDU',
  },
  {
    cityCode: '53',
    city: 'RİZE',
  },
  {
    cityCode: '54',
    city: 'SAKARYA',
  },
  {
    cityCode: '55',
    city: 'SAMSUN',
  },
  {
    cityCode: '56',
    city: 'SİİRT',
  },
  {
    cityCode: '57',
    city: 'SİNOP',
  },
  {
    cityCode: '58',
    city: 'SİVAS',
  },
  {
    cityCode: '59',
    city: 'TEKİRDAĞ',
  },
  {
    cityCode: '60',
    city: 'TOKAT',
  },
  {
    cityCode: '61',
    city: 'TRABZON',
  },
  {
    cityCode: '62',
    city: 'TUNCELİ',
  },
  {
    cityCode: '63',
    city: 'ŞANLIURFA',
  },
  {
    cityCode: '64',
    city: 'UŞAK',
  },
  {
    cityCode: '65',
    city: 'VAN',
  },
  {
    cityCode: '66',
    city: 'YOZGAT',
  },
  {
    cityCode: '67',
    city: 'ZONGULDAK',
  },
  {
    cityCode: '68',
    city: 'AKSARAY',
  },
  {
    cityCode: '69',
    city: 'BAYBURT',
  },
  {
    cityCode: '70',
    city: 'KARAMAN',
  },
  {
    cityCode: '71',
    city: 'KIRIKKALE',
  },
  {
    cityCode: '72',
    city: 'BATMAN',
  },
  {
    cityCode: '73',
    city: 'ŞIRNAK',
  },
  {
    cityCode: '74',
    city: 'BARTIN',
  },
  {
    cityCode: '75',
    city: 'ARDAHAN',
  },
  {
    cityCode: '76',
    city: 'IĞDIR',
  },
  {
    cityCode: '77',
    city: 'YALOVA',
  },
  {
    cityCode: '78',
    city: 'KARABÜK',
  },
  {
    cityCode: '79',
    city: 'KİLİS',
  },
  {
    cityCode: '80',
    city: 'OSMANİYE',
  },
  {
    cityCode: '81',
    city: 'DÜZCE',
  },
];
