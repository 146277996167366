import { FC, memo } from 'react';
import {
  ContactPermissionIcon,
  CreditCardIcon,
  DashboardLayout,
  LanguageIcon,
  LogoutIcon,
  HesDarkIcon,
  PhoneIcon,
  QuestionMarkIcon,
  LockIcon,
  ContractsIcon,
  ChevronDarkRightIcon,
  DarkAvatarIcon,
  Container,
  TimeIcon,
  FolderEmptyIcon,
} from 'Components';
import { Box, Grid, Typography } from '@material-ui/core';
import { useLocalization } from 'Hooks';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { MyAccountHeader } from './MyAccountHeader';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllStore, setAccessFalse } from 'Stores/App';
import { useEffect } from 'react';
import { setNormalizeCardTransactionStates } from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';

import './MyAccount.scss';
import { logout } from 'Services/Queries';

type MyAccountProps = {};

type MyAccountItemType = {
  title: string;
  Icon: any;
  onClick: () => void;
  itemID: string;
};

const MyAccount: FC<MyAccountProps> = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);

  const customerNumber = userData.customerNumber;
  const sessionToken = userData.sessionToken;
  const isMiniApp = userData?.isMiniAppLogin;

  useEffect(() => {
    dispatch(setNormalizeCardTransactionStates());
  }, []);

  const {
    web_my_account,
    web_my_account_information,
    web_account_my_permissions,
    web_my_account_bank_credit_card,
    web_my_account_hes_code,
    web_my_account_change_password,
    web_my_account_help,
    web_my_account_faq,
    web_my_account_contact_us,
    web_my_account_other,
    web_my_account_language,
    web_my_account_contracts,
    web_my_account_logout,
    web_last_login_information,
    web_help_money_transfer_wishes_complaints_list,
  } = useLocalization();

  const myAccountItems: MyAccountItemType[] = [
    {
      title: web_my_account_information,
      Icon: DarkAvatarIcon,
      onClick: () => history.push(routePath.userInfo),
      itemID: 'goUserInfoPageBtnTest',
    },
    {
      title: web_account_my_permissions,
      Icon: ContactPermissionIcon,
      onClick: () => history.push(routePath.permissions),
      itemID: 'goContactPermissionPageBtnTest',
    },
    {
      title: web_my_account_bank_credit_card,
      Icon: CreditCardIcon,
      onClick: () => history.push(routePath.creditCards),
      itemID: 'goCreditCardsPageBtnTest',
    },
    // {
    //   title: web_my_account_hes_code,
    //   Icon: HesDarkIcon,
    //   onClick: () => history.push(routePath.hes),
    //   itemID: 'goHesCodePageBtnTest',
    // },
    {
      title: web_my_account_change_password,
      Icon: LockIcon,
      onClick: () => history.push(routePath.changePassword),
      itemID: 'goChangePasswordPageBtnTest',
    },
    {
      title: web_last_login_information,
      Icon: TimeIcon,
      onClick: () => history.push(routePath.loginInformation),
      itemID: 'goLoginInfoPageBtnTest',
    },
  ];

  const help: MyAccountItemType[] = [
    /*     {
      title: web_help_money_transfer_wishes_complaints_list,
      Icon: () => <FolderEmptyIcon className="helpfolderEmptyIcon" />,
      onClick: () => history.push(routePath.requestAndComplaint),
      itemID: 'goCustomerComplaintPageBtnTest',
    }, */
    {
      title: web_my_account_faq,
      Icon: QuestionMarkIcon,
      onClick: () => history.push(routePath.faq),
      itemID: 'goFaqPageBtnTest',
    },
    {
      title: web_my_account_contact_us,
      Icon: PhoneIcon,
      onClick: () => history.push(routePath.contact),
      itemID: 'goContactUsPageBtnTest',
    },
  ];

  const others: MyAccountItemType[] = [
    {
      title: web_my_account_language,
      Icon: LanguageIcon,
      onClick: () => history.push(routePath.changeLanguage),
      itemID: 'goChangeLanguagePageBtnTest',
    },
    {
      title: web_my_account_contracts,
      Icon: ContractsIcon,
      onClick: () => history.push(routePath.agreements),
      itemID: 'goAgreementsBtnTest',
    },
    {
      title: web_my_account_logout,
      Icon: LogoutIcon,
      onClick: () => {
        history.push(routePath.welcome);
        logout({ sessionToken, customerNumber });
        dispatch(setAccessFalse());
        dispatch(resetAllStore());
      },
      itemID: 'logOutBtnTest',
    },
  ];

  return (
    <DashboardLayout noContainer>
      <div id="myAccount">
        <MyAccountHeader />
        <Container>
          <Grid container spacing={4}>
            <Grid xs={12} sm={6} item>
              <Box className="myAccount__title">{web_my_account}</Box>
              {myAccountItems.map(({ title, Icon, onClick, itemID }, index) =>
                isMiniApp && itemID === 'goChangePasswordPageBtnTest' ? (
                  ''
                ) : (
                  <MyAccountItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} />
                )
              )}
            </Grid>
            <Grid xs={12} sm={6} item>
              <Box className="myAccount__title myAccount__title__help">{web_my_account_help}</Box>
              {help.map(({ title, Icon, onClick, itemID }, index) => (
                <MyAccountItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} />
              ))}
              <Box className="myAccount__title myAccount__title__other">{web_my_account_other}</Box>
              {others.map(({ title, Icon, onClick, itemID }, index) =>
                isMiniApp && itemID === 'logOutBtnTest' ? (
                  ''
                ) : (
                  <MyAccountItem title={title} Icon={Icon} onClick={onClick} key={index} itemID={itemID} />
                )
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </DashboardLayout>
  );
});

export default MyAccount;

const MyAccountItem: FC<MyAccountItemType> = memo(({ title, Icon, onClick }) => {
  return (
    <div className="myAccount__item" onClick={onClick}>
      <Box textAlign="center" display="flex" alignItems="center">
        <Icon />
        <Typography variant="h6" align="center">
          <Box className="myAccount__item__title">{title}</Box>
        </Typography>
      </Box>
      <ChevronDarkRightIcon />
    </div>
  );
});
