import { Box, Button, Typography } from '@material-ui/core';
import { ArrowDownIcon, Checkbox, FormErrorWrapper, FullPageWrapper } from 'Components';
import { formNames } from 'Config';
import { useLocalization, useResize } from 'Hooks';
import useOnScreen from 'Hooks/useOnScreen';
import { AccountState, onGetAgreementRequest, setUserAcceptedNewVersionV2, storeAccount } from 'Stores/Account';
import { gutters } from 'Theme/Variables';
import { agreementsTypes, generateLanguageParam, scrollTop } from 'Utils';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import '../Signup/SingupConditionsStep/SingupConditionsStep.scss';

type SingupConditionsStepProps = {};

const HomeAgreementEcplicit: FunctionComponent<SingupConditionsStepProps> = ({}) => {
  const {
    web_title_sozlesmeler,
    web_btn_next,
    web_version_express_consent_text_read_accept,
    web_btn_scroll,
  } = useLocalization();
  const dispatch = useDispatch();
  const history = useHistory();

  const accountState: AccountState = useSelector(storeAccount);
  const userAccepted = accountState.userAcceptedNewVersionV2;

  const containerRef = useRef<any>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useResize(containerRef);
  const buttonContainerIsVisible = useOnScreen(buttonContainerRef);

  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  useEffect(() => {
    dispatch(
      onGetAgreementRequest({
        AgreementType: agreementsTypes.ACIK_RIZA_METNI,
        Language: generateLanguageParam(),
        isSignupProcess: true,
      })
    );
    scrollTop();
  }, []);

  return (
    <FullPageWrapper>
      <div id="signupConditions">
        <div className="signupConditions-container" ref={containerRef}>
          <Typography variant="h5" align="center">
            <Box className="signupConditions-title">{web_title_sozlesmeler}</Box>
          </Typography>
          <Box marginBottom={gutters.big}>
            <Typography variant="subtitle2">{agreementContent?.AgreementContent}</Typography>
          </Box>
          <div className="signupConditions-button-container" ref={buttonContainerRef}>
            <FormErrorWrapper formName={formNames.signup} resetFormOnRouteChange>
              <Box className="checkbox-error">
                <Checkbox
                  name="clarificationAccepted"
                  color="primary"
                  onClick={() => {
                    dispatch(setUserAcceptedNewVersionV2(!userAccepted));
                  }}
                  checked={userAccepted}
                  label={web_version_express_consent_text_read_accept}
                  itemID="signUpInfoConditionsAcceptedInputTest"
                />
              </Box>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                itemID="signUpNextBtnTest"
              >
                {web_btn_next}
              </Button>
            </FormErrorWrapper>
          </div>
          {!buttonContainerIsVisible && (
            <div
              className="signupConditions-dialog"
              style={{
                width: width || 0,
              }}
              onClick={() => buttonContainerRef.current?.scrollIntoView()}
            >
              <Typography variant="body2" component="div" className="signupConditions-dialog__inner flexAlignCenter">
                <Box>{web_btn_scroll}</Box>
                <ArrowDownIcon />
              </Typography>
            </div>
          )}
        </div>
      </div>
    </FullPageWrapper>
  );
};

export default HomeAgreementEcplicit;
