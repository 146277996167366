import React, { FunctionComponent, useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useInterval, useLocalization } from 'Hooks';
import { colors, gutters } from 'Theme/Variables';
import { secondsToMinutes } from 'Utils';

type CountdownProps = {
  countdownSeconds: number;
  onCountEnd: Function;
  onResetTimer: Function;
  errorForReset: boolean;
};

const Countdown: FunctionComponent<CountdownProps> = ({
  countdownSeconds,
  onCountEnd,
  onResetTimer,
  errorForReset,
}) => {
  const { web_resend_otp } = useLocalization();
  const [remainingSeconds, setRemainingSeconds] = useState<number>(countdownSeconds);

  useInterval(() => {
    if (remainingSeconds > 0) {
      setRemainingSeconds(remainingSeconds - 1);
    }
    if (errorForReset) {
      setRemainingSeconds(0);
    }
  }, 1000);

  useEffect(() => {
    if (remainingSeconds === 0) {
      onCountEnd();
    } // eslint-disable-next-line
  }, [remainingSeconds]);

  return (
    <Typography component="div" variant="h6">
      <Box
        marginTop={gutters.regular}
        textAlign="center"
        color={remainingSeconds ? colors.checkboxLabel : colors.primary}
      >
        {remainingSeconds ? (
          secondsToMinutes(remainingSeconds)
        ) : (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onResetTimer();
              setRemainingSeconds(countdownSeconds);
            }}
          >
            {web_resend_otp}
          </span>
        )}
      </Box>
    </Typography>
  );
};

export default Countdown;
