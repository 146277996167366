import * as React from 'react';

function BelbimGrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="83" height="19" viewBox="0 0 83 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <rect width="83" height="19" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 16.337V2.11914H5.23673C9.00883 2.11914 10.9018 3.33506 10.9018 5.76689C10.9018 7.05189 10.2662 7.97764 9.00883 8.54415C10.9847 9.16592 11.9657 10.3956 11.9657 12.2471C11.9657 14.9691 10.0866 16.3232 6.34211 16.3232H0V16.337ZM5.41635 7.74275C6.68754 7.74275 7.32313 7.24533 7.32313 6.26431C7.32313 5.18656 6.68754 4.63388 5.41635 4.63388H3.57866V7.74275H5.41635ZM3.57866 13.7394H5.63743C6.42501 13.7394 7.00533 13.6565 7.3784 13.4769C7.93109 13.2281 8.20743 12.7584 8.20743 12.0813C8.20743 10.893 7.35076 10.2989 5.62361 10.2989H3.57866V13.7394Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2812 16.337V2.11914H37.8461V13.5874H43.967V16.337H34.2812Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.5703 16.337V2.11914H50.8071C54.5792 2.11914 56.4721 3.33506 56.4721 5.76689C56.4721 7.05189 55.8365 7.97764 54.5792 8.54415C56.555 9.16592 57.5361 10.3956 57.5361 12.2471C57.5361 14.9691 55.6707 16.3232 51.9124 16.3232H45.5703V16.337ZM50.9867 7.74275C52.2579 7.74275 52.8935 7.25915 52.8935 6.26431C52.8935 5.18656 52.2579 4.63388 50.9867 4.63388H49.149V7.74275H50.9867ZM49.1352 13.7394H51.1939C51.9815 13.7394 52.5619 13.6565 52.9349 13.4769C53.4876 13.2281 53.764 12.7584 53.764 12.0813C53.764 10.893 52.9073 10.2989 51.1801 10.2989H49.1352V13.7394Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9531 16.3369V2.38152H66.7252V3.8876C67.8582 2.71314 69.2123 2.13281 70.7736 2.13281C72.5284 2.13281 73.7167 2.86513 74.3385 4.34357C75.734 2.86513 77.2539 2.13281 78.912 2.13281C81.634 2.13281 83.0019 3.68034 83.0019 6.78922V16.3231H79.2021V7.31427C79.2021 5.67002 78.6356 4.84099 77.5026 4.84099C76.4663 4.84099 75.5544 5.47658 74.7944 6.74777V16.3369H71.1191V7.31427C71.1191 5.67002 70.5525 4.84099 69.4195 4.84099C68.3694 4.84099 67.4713 5.47658 66.7252 6.74777V16.3369H62.9531Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.0898 16.336V6.70537C58.1312 6.76064 58.1727 6.80209 58.228 6.84354C58.6839 7.28569 59.2366 7.50677 59.886 7.50677C60.5493 7.50677 61.1019 7.28569 61.5441 6.85736C61.5994 6.80209 61.6546 6.74682 61.6961 6.67773H61.7514V16.336H58.0898Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.5898 4.16309C57.5898 3.51368 57.8109 2.96099 58.2531 2.51884C58.709 2.06287 59.2617 1.8418 59.9111 1.8418C60.5744 1.8418 61.1271 2.06287 61.5692 2.50502C62.0114 2.94717 62.2324 3.49986 62.2324 4.16309C62.2324 4.82632 62.0114 5.379 61.5692 5.82116C61.1271 6.26331 60.5744 6.47057 59.9111 6.47057C59.2617 6.47057 58.709 6.24949 58.2531 5.80734C57.8109 5.36519 57.5898 4.8125 57.5898 4.16309Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4163 8.83463C14.5493 6.47189 16.7186 4.41312 19.3024 3.01758C18.0865 4.37167 16.9258 5.90538 16.4146 7.48054C14.7842 12.5376 18.349 16.3097 24.0693 15.9505C27.3578 15.7432 30.8398 14.2924 33.4098 12.0678C30.6463 16.1025 24.774 18.7692 19.634 18.3408C13.9275 17.8572 11.1364 13.6015 13.4163 8.83463Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2088 7.02403C19.1208 5.11725 20.8756 3.45918 22.962 2.32617C21.9809 3.41773 21.221 4.63365 20.8203 5.91865C19.5077 9.99474 22.1882 13.0621 26.8031 12.772C29.4561 12.6061 32.178 11.3488 34.2506 9.55258C32.0261 12.7996 27.3697 15.038 23.2245 14.6925C18.6095 14.3057 16.3711 10.879 18.2088 7.02403Z"
          fill="#AFB1BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7446 6.77528C26.2523 8.157 24.7324 8.72351 23.7238 8.84786C24.3593 8.46098 25.6305 7.72867 26.501 6.62329C28.1314 4.55071 28.3111 2.18796 26.7635 1.15167C25.8654 0.557527 24.6357 0.585163 23.4336 1.11022C25.1193 -0.230053 27.6755 -0.409678 28.8776 0.861508C30.2178 2.2985 29.4855 4.99286 27.7446 6.77528Z"
          fill="#AFB1BA"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="83" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BelbimGrayIcon;
