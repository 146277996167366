import { FC, Fragment } from 'react';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Grid, Typography } from '@material-ui/core';
import { UserState, userStore } from 'Stores/User';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  CardTransactionsState,
  onCustomerApplicationListRequest,
  onOnlineApplicationCardRequest,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import CardApplicationEmpty from './CardApplicationEmpty/CardApplicationEmpty';
import { CardApplicationsItem } from './CardApplicationsItem';
import { CustomerCardApplicationListItemModel } from 'Models';
import { gutters } from 'Theme/Variables';

import './CardApplications.scss';
import { clearApplicationData } from 'Stores/CardApplication';

type CardApplicationsProps = {};

const CardApplications: FC<CardApplicationsProps> = ({}) => {
  const dispatch = useDispatch();

  const { web_card_applications_title } = useLocalization();

  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);

  const { customerNumber, sessionToken } = userData;
  const { customerCardApplicationList } = cardData;

  useEffect(() => {
    dispatch(
      onOnlineApplicationCardRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
    dispatch(
      onCustomerApplicationListRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
        ProductCode: '',
      })
    );
    dispatch(clearApplicationData({ withoutCard: false }));
  }, []);

  return (
    <DashboardLayout backButton>
      <Fragment>
        <div id="cardApplications">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{web_card_applications_title}</Box>
          </Typography>
        </div>
        {(!customerCardApplicationList || customerCardApplicationList.length === 0) && <CardApplicationEmpty />}
        <Box mt={gutters.big} />
        <Grid spacing={1} container>
          {customerCardApplicationList &&
            customerCardApplicationList.map((item: CustomerCardApplicationListItemModel, index) => {
              return <CardApplicationsItem key={index.toString()} item={item} />;
            })}
        </Grid>
      </Fragment>
    </DashboardLayout>
  );
};
export default CardApplications;
