import { useSelector } from 'react-redux';
import { getDigitalAccountInformation } from 'Stores/CardTransactions';
import { useDispatch } from 'react-redux';
import { onGetAccountWithCardsRequest } from 'Stores/CardTransactions';
import { UserState, userStore } from 'Stores/User';
import { useEffect } from 'react';

const useDigitalAccount = () => {
  const userData: UserState = useSelector(userStore);
  const dispatch = useDispatch();
  const digitalAccount = useSelector(getDigitalAccountInformation);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  useEffect(() => {
    console.log('update');

    dispatch(
      onGetAccountWithCardsRequest({
        CustomerNumber: customerNumber,
        SessionToken: sessionToken,
      })
    );
  }, [customerNumber, dispatch, sessionToken]);
  console.log('digitalAccount', digitalAccount.balanceTotal);
  return digitalAccount;
};

export default useDigitalAccount;
