export type formNameKeys =
  | 'dialogOTP'
  | 'login'
  | 'signup'
  | 'forgotPassword'
  | 'hes'
  | 'changePassword'
  | 'others'
  | 'addCard'
  | 'cardUpdateNickname'
  | 'cardDelete'
  | 'addCreditCard'
  | 'addBalanceToCard'
  | 'creditCardDetail'
  | 'updateUserMail'
  | 'updateUserIbanNumber'
  | 'updateUserPhoneNumber'
  | 'contactPermission'
  | 'uploadSubscription'
  | 'updatedJob'
  | 'loadAutomaticInstructions'
  | 'addUserAdress'
  | 'reportStepForm'
  | 'mobileAddCreditCard'
  | 'masterpassConnect'
  | 'paymentStep'
  | 'balanceTransfer';

const formNames: { [key in formNameKeys]: formNameKeys } = {
  dialogOTP: 'dialogOTP',
  login: 'login',
  signup: 'signup',
  forgotPassword: 'forgotPassword',
  hes: 'hes',
  changePassword: 'changePassword',
  others: 'others',
  addCard: 'addCard',
  cardUpdateNickname: 'cardUpdateNickname',
  cardDelete: 'cardDelete',
  addCreditCard: 'addCreditCard',
  addBalanceToCard: 'addBalanceToCard',
  creditCardDetail: 'creditCardDetail',
  updateUserMail: 'updateUserMail',
  updateUserIbanNumber: 'updateUserIbanNumber',
  updateUserPhoneNumber: 'updateUserPhoneNumber',
  contactPermission: 'contactPermission',
  uploadSubscription: 'uploadSubscription',
  updatedJob: 'updatedJob',
  loadAutomaticInstructions: 'loadAutomaticInstructions',
  addUserAdress: 'addUserAdress',
  reportStepForm: 'reportStepForm',
  mobileAddCreditCard: 'mobileAddCreditCard',
  masterpassConnect: 'masterpassConnect',
  paymentStep: 'paymentStep',
  balanceTransfer: 'balanceTransfer',
};

export default formNames;
