import { Box } from '@material-ui/core';
import { ChevronDarkRightIcon } from 'Components';
import { useCreditCardType } from 'Hooks';
import { MasterPassCardType } from 'Models';
import { FC } from 'react';
import { colors, fontSizes } from 'Theme/Variables';

import './CreditCardListItem.scss';

const CreditCardListItem: FC<MasterPassCardType> = (props) => {
  const { creditCardBrandUrl, creditCardLogoUrl } = useCreditCardType(props);
  return (
    <div className="credit-card-list__item" itemID="seeCreditCardDetailBtnTest">
      <Box width={66}>
        <img src={creditCardLogoUrl} alt="" />
      </Box>
      <Box display="flex" flex={1} paddingLeft="24px" paddingRight="24px" flexDirection="column">
        <Box color={colors.secondary} fontSize={fontSizes.small}>
          {props.Name}
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginTop={'3px'} style={{ opacity: 0.5 }} color={colors.checkboxLabel} fontSize={fontSizes.small}>
            {props.Value1}
          </Box>
          <span className="credit-card-list__item--dot" />
          <Box display="flex">
            <img src={creditCardBrandUrl} className="credit-card-list__item--brand" alt="" />
          </Box>
        </Box>
      </Box>
      <ChevronDarkRightIcon />
    </div>
  );
};
export default CreditCardListItem;
