import { FC } from 'react';
import { Loading, SuccessIconLarge } from 'Components';
import { DashboardLayout } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { routePath } from 'Navigator/routes';

import { useQuery } from 'Hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateCommercialStatus4MailRequest, storeAccount } from 'Stores/Account';

import './ContactPermissionSuccess.scss';

type ContactPermissionSuccessProps = {};

const ContactPermissionSuccess: FC<ContactPermissionSuccessProps> = () => {
  const { replace } = useHistory();
  const dispatch = useDispatch();

  const { loading } = useSelector(storeAccount);

  const url = useQuery();
  const data = url.get('data');

  if (!data) {
    replace(routePath.welcome);
  }

  useEffect(() => {
    if (data) {
      dispatch(
        onUpdateCommercialStatus4MailRequest({
          CommercialData: data,
        })
      );
    }
  }, [data]);

  const { web_close_mail, web_close_noti_exp } = useLocalization();

  return !loading ? (
    <div id="contactPermissionDisableSuccess">
      <SuccessIconLarge />
      <Typography variant="h3" align="center">
        <Box mt={gutters.bigger} fontWeight={600}>
          {web_close_mail}
        </Box>
      </Typography>
      <Typography variant="h3" align="center">
        <Box fontSize={fontSizes.regular} color={colors.checkboxLabel} mt={gutters.big}>
          {web_close_noti_exp}
        </Box>
      </Typography>
      <Box mt={gutters.large} />
    </div>
  ) : (
    <Loading />
  );
};
export default ContactPermissionSuccess;
