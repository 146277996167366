import { FC, useEffect, useLayoutEffect } from 'react';
import { DashboardLayout } from 'Components';
import { CardDetailsHeader } from './CardDetailsHeader';
import { useCardType } from 'Hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardTransactionsState,
  setLoadBalanceWithoutMasterpass,
  setNormalizeCardTransactionStates,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { CardTransactions } from './CardTransactions';

import './CardDetails.scss';

import { useHistory } from 'react-router';

const CardDetails: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cardState: CardTransactionsState = useSelector(storeCardTransactions);
  const cardItem: any = cardState?.activeInProcessCard;
  const cardList: any = cardState?.cards;

  const { cardPath, cardColor } = useCardType(cardItem?.ProductCode);

  const checkCardIsRemoved = () => {
    const cardNoRemoved = cardList.some((item: any) => item.CardId === cardItem?.CardId);
    if (!cardNoRemoved) {
      history.goBack();
    }
  };

  useLayoutEffect(() => {
    checkCardIsRemoved();
  }, [cardList]);

  useEffect(() => {
    dispatch(setLoadBalanceWithoutMasterpass(false));
    dispatch(setNormalizeCardTransactionStates());
  }, []);

  return (
    <DashboardLayout noContainer marginTop={0}>
      <div id="cardDetails">
        <CardDetailsHeader cardItem={cardItem} cardColorClass={cardColor} cardPath={cardPath} />
        <CardTransactions cardColorClass={cardColor} cardItem={cardItem} cardPath={cardPath} />
      </div>
    </DashboardLayout>
  );
};

export default CardDetails;
