import { campaignsReqBodyData } from 'Config';
import { axiosHelper } from 'Services/AxiosHelper';
import { GetFaqModel } from 'Models';
import { getApiGWTokenCookie } from 'Utils';

const command = 'cms.getCampaign';

const getCampaigns = async (params: campaignsReqBodyData): Promise<any> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
      isTmobLabsUrl: true,
    })
  ).data;

export default getCampaigns;
