import { Typography, Box } from '@material-ui/core';
import { IstanbulKartLogo, MaintenanceIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { FC, memo } from 'react';
import { gutters } from 'Theme/Variables';

import './Maintenance.scss';

const Maintenance: FC = memo(() => {
  const { web_maintenance_header, web_maintenance_desc } = useLocalization();

  return (
    <div id="maintenance">
      <div className="maintenance-container">
        <IstanbulKartLogo className="maintenance-container__icon" />
        <MaintenanceIcon />
        <Typography align="center" variant="h4">
          <Box className="maintenance-container__header" fontWeight={'bold'}>
            {web_maintenance_header}
          </Box>
        </Typography>
        <Typography align="center" variant="h5">
          <Box marginTop={gutters.regular}>{web_maintenance_desc}</Box>
        </Typography>
      </div>
    </div>
  );
});
export default Maintenance;
