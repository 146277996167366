import React, { FunctionComponent } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { FormikProps } from 'formik';
import { SignupSchemaInputs } from 'Validations';
import { Checkbox } from 'Components';
import { useErrorMessage, useLocalization } from 'Hooks';

type SignupContactStepProps = {
  formikProps: FormikProps<SignupSchemaInputs>;
};

const SignupContactStep: FunctionComponent<SignupContactStepProps> = ({
  formikProps: {
    handleChange,
    values: { viaSms, viaMail, mail2, mail },
    errors: { mail2: errorMail2 },
    touched: { mail2: touchedMail2 },
    handleBlur,
    setFieldValue,
  },
}) => {
  const {
    web_iletisim2_title,
    web_iletisim2_check1,
    web_iletisim2_check2,
    web_iletisim2_desc,
    web_plc_mail,
    web_email_obligation_desc,
    web_email_obligation,
  } = useLocalization();

  const { getError, getErrorMessage } = useErrorMessage();

  return (
    <div>
      <Typography variant="h5" align="center">
        <Box fontWeight={600} marginBottom={gutters.big} marginTop={gutters.big}>
          {web_iletisim2_title}
        </Box>
      </Typography>
      <Checkbox
        name="viaSms"
        color="primary"
        checked={viaSms}
        onChange={handleChange}
        label={web_iletisim2_check1}
        marginBottomGutter={gutters.small}
        itemID="signUpViaSmsBtnTest"
      />
      <Checkbox
        name="viaMail"
        color="primary"
        checked={viaMail}
        onChange={handleChange}
        label={web_iletisim2_check2}
        marginBottomGutter={gutters.small}
        itemID="signUpViaMailBtnTest"
      />
      <Box marginBottom={gutters.regular}>
        <Typography variant="body1" gutterBottom>
          {web_iletisim2_desc}
        </Typography>
      </Box>
      {!mail && viaMail && (
        <Box>
          <TextField
            name="mail2"
            label={web_email_obligation}
            variant="filled"
            fullWidth
            value={mail2}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              setFieldValue('mail2', mail2?.trim());
            }}
            error={getError(errorMail2, touchedMail2)}
            helperText={getErrorMessage(errorMail2, touchedMail2)}
            inputProps={{ maxLength: 50 }}
            itemID="signUpMailInputBtnTest"
          />
          <Box marginBottom={gutters.regular} marginTop={gutters.small}>
            <Typography variant="body1" gutterBottom>
              {web_email_obligation_desc}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SignupContactStep;
