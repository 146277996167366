import { FC, SetStateAction } from 'react';
import { FoodIcon, ShoppingIcon, TopUpIcon, TransportIcon, IconList, OtherIcon, PointListMapIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Grid, Box } from '@material-ui/core';
import { colors, gutters } from 'Theme/Variables';
import { Dispatch } from 'react';
import { GetTagsModel } from 'Models';

import './MapInterface.scss';
import { VIEW_TYPE } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import { MapsState, mapStore, setDiscoverSelectedTag, setDiscoverViewType } from 'Stores/Maps';

type MapInterfaceProps = {};

const MapInterface: FC<MapInterfaceProps> = () => {
  const dispatch = useDispatch();
  const mapState: MapsState = useSelector(mapStore);

  const { discoverSelectedTag, discoverTags, discoverViewType } = mapState;

  const { web_travel_spend_city, web_travel_spend_list, web_travel_spend_map } = useLocalization();

  const checkIconColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? '#fff' : undefined;
  };
  const checkBackgroundColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? colors.secondary : '#fff';
  };
  const checkTextColor = (id: number): string | undefined => {
    return discoverSelectedTag === id ? '#fff' : colors.secondary;
  };

  const setSelectedItems = (id: number) => {
    dispatch(setDiscoverSelectedTag(id));
  };

  const renderIcon = (id: string) => {
    switch (id) {
      case '1':
        return <OtherIcon fill={checkIconColor(Number(id))} className="mapInterface__item-container__icon" />;
      case '2':
        return <TransportIcon fill={checkIconColor(Number(id))} className="mapInterface__item-container__icon" />;
      case '3':
        return <FoodIcon fill={checkIconColor(Number(id))} className="mapInterface__item-container__icon" />;
      case '4':
        return <TopUpIcon fill={checkIconColor(Number(id))} className="mapInterface__item-container__icon" />;
      case '5':
        return <ShoppingIcon fill={checkIconColor(Number(id))} className="mapInterface__item-container__icon" />;

      default:
        break;
    }
  };

  const Item = ({ value, id }: any) => (
    <Grid item>
      <div
        onClick={() => setSelectedItems(Number(id))}
        style={{
          backgroundColor: checkBackgroundColor(Number(id)),
          color: checkTextColor(Number(id)),
        }}
        className="mapInterface__item-container"
        itemID="discoverSelectPointsBtnTest"
      >
        {renderIcon(id)}
        {value}
      </div>
    </Grid>
  );
  return (
    <div id="mapInterface">
      <Grid className="mapInterface__nav" container>
        <Grid item container sm={9} lg={9}>
          {discoverTags &&
            Object.keys(discoverTags).map((key: string) => {
              // @ts-ignore
              return <Item key={key} value={discoverTags[key]} id={key} />;
            })}
        </Grid>
        <Grid className="mapInterface__right-side" item lg={3} sm={3}>
          <div className="mapInterface__options">
            {/* <Box
              onClick={() => setDialogOpen(true)}
              className="mapInterface__options__location-box"
              itemID="discoverOpenDialogBtnTest"
            >
              {townName ? townName : web_travel_spend_city}
            </Box> */}
            <Box
              onClick={() => dispatch(setDiscoverViewType())}
              className="mapInterface__options__list-box"
              // mt={gutters.small}
              itemID="discoverSetViewTypeBtnTest"
            >
              {discoverViewType === VIEW_TYPE.MAP ? (
                <IconList className="mapInterface__options__list-box__icon" />
              ) : (
                <PointListMapIcon className="mapInterface__options__list-box__icon" />
              )}
              <span className="mapInterface__options__label">
                {discoverViewType === VIEW_TYPE.MAP ? web_travel_spend_list : web_travel_spend_map}
              </span>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default MapInterface;
