import { FC, Fragment, memo } from 'react';
import { CloseIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';

import './CampaignConditions.scss';
import { CampaignItem } from 'Stores/Account';

type CampaignConditionsProps = {
  hide: () => void;
  isVisible: boolean;
  userActiveCampaign?: CampaignItem;
};

const CampaignConditions: FC<CampaignConditionsProps> = memo(({ hide, isVisible, userActiveCampaign }) => {
  const { web_campaign_campaign_conditions, web_btn_okay } = useLocalization();

  const conditions = userActiveCampaign?.Conditions.split('•');

  return (
    <Dialog open={isVisible} onClose={hide}>
      <div className="dialogContainer">
        <CloseIcon onClick={() => hide()} />
        <div id="campaignConditions">
          <Typography variant="h3">
            <Box fontWeight={600} fontSize={fontSizes.big}>
              {web_campaign_campaign_conditions}
            </Box>
          </Typography>
          <Box className="campaignConditions__content">
            {/**use slice because first dot comes empty */}
            <ul>
              {conditions &&
                conditions.length > 0 &&
                conditions.slice(1, conditions.length).map((item, index) => (
                  <li key={index}>
                    <Typography variant="body1">
                      <Box fontSize={fontSizes.regular} marginBottom={gutters.regular}>
                        {item}
                      </Box>
                    </Typography>
                  </li>
                ))}
            </ul>
          </Box>
          <Box marginTop={gutters.small}>
            <Button variant="contained" color="secondary" fullWidth onClick={hide}>
              {web_btn_okay}
            </Button>
          </Box>
        </div>
      </div>
    </Dialog>
  );
});
export default CampaignConditions;
