import { spaceRemover } from 'Utils';
import { addCreditCardSchemaInitialValues } from 'Validations';

export const userTemporaryCreditCardNormalize = (values: typeof addCreditCardSchemaInitialValues) => {
  // cardNumber used as Value1 for compatiblity
  const formValues = {
    Name: values.cardName,
    Value1: spaceRemover(values.creditCardNumber),
    ExpireDate: values.expireDate,
    Cvv: values.cvv,
  };
  return formValues;
};
