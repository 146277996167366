import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { GreenTickBigIcon } from 'Components';
import { useLocalization } from 'Hooks';

const PasswordFormSuccess = () => {
  const { web_title_pasword_reset_success, web_desc_pasword_reset_success } = useLocalization();
  return (
    <>
      <Typography variant="h5" align="center">
        <Box fontWeight={600} marginBottom={gutters.large}>
          {web_title_pasword_reset_success}
        </Box>
      </Typography>
      <GreenTickBigIcon />
      <Typography variant="body1" align="center" component="div">
        <Box marginBottom={gutters.large} marginTop={gutters.regular} fontSize={fontSizes.regular}>
          {web_desc_pasword_reset_success}
        </Box>
      </Typography>
    </>
  );
};

export default PasswordFormSuccess;
