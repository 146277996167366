import * as React from 'react';

function TransactionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="e7yvwgjfza">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <path
          id="t1gqmx4iwb"
          d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zM8.961 10.075c-.39-.39-1.024-.39-1.414 0l-2.102 2.1-.078.058-.074.067c-.36.36-.388.928-.083 1.32l.083.094 2.121 2.121.094.084c.393.305.96.277 1.32-.084.36-.36.389-.927.084-1.32l-.084-.094-.42-.421h5.628l.117-.007c.497-.057.883-.48.883-.993 0-.552-.448-1-1-1H8.45l.511-.511.083-.094c.305-.393.278-.96-.083-1.32zm3.496-6.008c-.39-.387-1.019-.387-1.407.002-.39.392-.39 1.023-.001 1.413l.514.517L6 6c-.552 0-1 .448-1 1s.448 1 1 1h5.606l-.425.428-.083.094c-.303.392-.276.958.085 1.319l.093.083c.39.304.954.277 1.316-.084l2.118-2.127.088-.101c.134-.176.201-.385.203-.595L15 7l.001-.017c0-.024-.002-.048-.005-.072l-.006-.056-.009-.049c-.004-.023-.01-.047-.017-.07l-.01-.037c-.072-.227-.222-.42-.42-.545L12.46 4.07l-.002-.003z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#e7yvwgjfza)" transform="translate(-20 -272) translate(19 62)">
            <g>
              <path d="M0 0H24V24H0z" transform="translate(1 210)" />
              <g transform="translate(1 210) translate(2 2)">
                <mask id="n51xicz4bc" fill="#fff">
                  <use xlinkHref="#t1gqmx4iwb" />
                </mask>
                <use fill="#383C52" xlinkHref="#t1gqmx4iwb" />
                <g fill="#383C52" mask="url(#n51xicz4bc)">
                  <path d="M0 0H24V24H0z" transform="translate(-2 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TransactionIcon;
