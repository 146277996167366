import { FC } from 'react';
import { DarkPlusIcon, LocationIcon } from 'Components';
import { useLocalization } from 'Hooks';
import { Box, Typography } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { useHistory } from 'react-router-dom';

import './NoAdress.scss';
import { routePath } from 'Navigator/routes';

type NoAdressProps = {};
const NoAdress: FC<NoAdressProps> = ({}) => {
  const { web_no_saved_adress, web_start_new_adress, web_add_adress } = useLocalization();
  const history = useHistory();
  return (
    <div id="noAdress">
      <LocationIcon />
      <Typography variant="h3">
        <Box fontWeight="bold" marginBottom={gutters.regular}>
          {web_no_saved_adress}
        </Box>
      </Typography>
      <Typography variant="body1">
        <Box marginBottom={gutters.large} fontSize={fontSizes.regular}>
          {web_start_new_adress}
        </Box>
      </Typography>
      <Box
        className="noAdress__addAdress"
        onClick={() => history.push(routePath.userAdressDetail)}
        itemID="addAdressBtnTest"
      >
        <DarkPlusIcon />
        <Typography variant="h6" align="center">
          <Box fontWeight="bold">{web_add_adress}</Box>
        </Typography>
        <Box />
      </Box>
    </div>
  );
};
export default NoAdress;
