import { FC, memo } from 'react';
import { Box } from '@material-ui/core';
import { WelcomeHeader } from 'Views/Welcome/WelcomeHeader';
import { ContactWrapper } from 'Views/Contact/ContactWrapper';
import { gutters } from 'Theme/Variables';
import { ArrowLeftIcon, Container } from 'Components';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './WelcomeContact.scss';

type WelcomeContactProps = {};

const WelcomeContact: FC<WelcomeContactProps> = memo(() => {
  const history = useHistory();
  return (
    <div id="welcomeContact">
      <WelcomeHeader />
      <Container>
        <ArrowLeftIcon onClick={() => history.push(routePath.welcome)} />
        <Box marginTop={gutters.large}>
          <ContactWrapper />
        </Box>
      </Container>
    </div>
  );
});
export default WelcomeContact;
