import { FC, Fragment } from 'react';
import { AbonmanCardIcon, ChevronDarkRightIcon, CloseIcon, DarkPlusIcon, TickIcon } from 'Components';
import { useDigitalAccount, useLocalization, useModal } from 'Hooks';
import { Box, Typography, Dialog } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { SelectedCardType } from './constant';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAddCardStep,
  setDigitalCardActive,
  setIsUserAddingNewCardInTransactions,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import { SelectedCard } from './SelectedCard';
import { DigitalAccountCard } from 'Components/DigitalAccountCard';

import './ChooseCard.scss';

type ChooseCardProps = {
  selectedCard?: SelectedCardType;
};

const ChooseCard: FC<ChooseCardProps> = ({ selectedCard }) => {
  const {
    web_title_card_to_be_loaded,
    web_choose_istanbulkart,
    web_choose_istanbulkart_desc,
    web_title_istanbulkart_popup,
    web_desc_istanbulkart_popup,
    web_btn_enter_new_card_creditcard_popup,
    web_title_my_cards,
    web_title_others_card,
    web_digital_account_tl_yukle_pop_up,
  } = useLocalization();

  const cardData = useSelector(storeCardTransactions);
  const dispatch = useDispatch();
  const digitalAccount = useDigitalAccount();

  const userCards: cardItemParams[] = cardData?.cards;
  const userOtherCards: cardItemParams[] = cardData?.otherCards;

  const { isVisible, show, hide } = useModal();
  const history = useHistory();

  const digitalSelected = cardData.isDigitalActive;
  return (
    <div id="chooseCard">
      <Typography variant="h6" align="center">
        <Box fontWeight="bold" marginBottom={fontSizes.regular} textAlign="left">
          {web_title_card_to_be_loaded}
        </Box>
      </Typography>
      {!digitalSelected && !selectedCard && (
        <Box className="chooseCard__container" onClick={() => show()}>
          <Box display="flex" alignItems="center">
            <AbonmanCardIcon />
            <Box>
              <Typography variant="h6">
                <Box fontWeight="bold" className="chooseCard__toBeLoaded">
                  {web_choose_istanbulkart}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box>{web_choose_istanbulkart_desc}</Box>
              </Typography>
            </Box>
          </Box>
          <ChevronDarkRightIcon />
        </Box>
      )}
      {!digitalSelected && selectedCard && (
        <Box onClick={show}>
          <SelectedCard cardItem={selectedCard} isDialog={false} />
        </Box>
      )}
      {digitalSelected && (
        <DigitalAccountCard
          onClick={() => {
            show();
          }}
          marginTop={'16px'}
          marginBottom={gutters.regular}
          rightSideIcon={
            <ChevronDarkRightIcon
              color={'rgba(255, 255, 255, 0.3)'}
              style={{ transform: 'rotate(90deg)', marginTop: '-10px', marginRight: '16px' }}
            />
          }
        />
      )}
      <Dialog open={isVisible} onClose={hide}>
        <div className="dialogContainer chooseCard__dialog">
          <CloseIcon onClick={hide} />
          <Box>
            <Typography variant="h3" align="center">
              <Box fontWeight="bold" fontSize={fontSizes.big} marginBottom={gutters.regular}>
                {web_title_istanbulkart_popup}
              </Box>
            </Typography>
            <Typography variant="body1" align="center">
              <Box fontSize={fontSizes.regular} marginBottom={gutters.regular}>
                {web_desc_istanbulkart_popup}
              </Box>
            </Typography>
            <Box className="chooseCard__dialog__container">
              {digitalAccount && (
                <Box>
                  <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.gray}>
                    {web_digital_account_tl_yukle_pop_up}
                  </Box>
                  <DigitalAccountCard
                    marginTop={'16px'}
                    marginBottom={gutters.regular}
                    onClick={() => {
                      dispatch(setDigitalCardActive());
                      hide();
                    }}
                    containerStyle={
                      digitalSelected
                        ? {
                            border: `2px solid #383c52`,
                            boxSizing: 'border-box',
                          }
                        : {}
                    }
                    rightSideIcon={
                      digitalSelected ? (
                        <Fragment>
                          <TickIcon className="chooseCard__dialog__container__tick" />
                          <div className="chooseCard__dialog__container__tick__background" />
                        </Fragment>
                      ) : null
                    }
                  />
                </Box>
              )}
              {userCards && userCards?.length > 0 && (
                <Box>
                  <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.gray}>
                    {web_title_my_cards}
                  </Box>
                  {/** IF user Has favorite card it has to be first */}
                  {userCards &&
                    userCards?.length > 0 &&
                    userCards
                      .filter((item) => item?.CardStatusCode === 'A' && item?.IsPrimary === true)
                      .map((item, index) => (
                        <SelectedCard
                          key={index}
                          cardItem={item}
                          isSelected={!digitalSelected && selectedCard?.MifareId === item?.MifareId ? true : false}
                          isDialog
                          hide={hide}
                        />
                      ))}
                  {userCards &&
                    userCards
                      .filter((item) => item?.CardStatusCode === 'A' && item?.IsPrimary !== true)
                      .map((item, index) => (
                        <SelectedCard
                          key={index}
                          cardItem={item}
                          isSelected={!digitalSelected && selectedCard?.MifareId === item?.MifareId ? true : false}
                          isDialog
                          hide={hide}
                        />
                      ))}
                </Box>
              )}
              {userOtherCards && (
                <Box>
                  <Box
                    fontSize={fontSizes.regularBig}
                    fontWeight="bold"
                    color={colors.gray}
                    marginBottom={gutters.regular}
                    marginTop={gutters.regular}
                  >
                    {web_title_others_card}
                  </Box>
                  <Box marginTop={gutters.bigger}>
                    {userOtherCards &&
                      userCards?.length > 0 &&
                      userOtherCards
                        .filter((item) => item?.IsPrimary !== true)
                        .map((item, index) => (
                          <SelectedCard
                            key={index}
                            cardItem={item}
                            isSelected={!digitalSelected && selectedCard?.MifareId === item?.MifareId}
                            isDialog
                            hide={hide}
                          />
                        ))}
                  </Box>
                </Box>
              )}
            </Box>
            <div
              onClick={() => {
                history.push(routePath.addCard);
                dispatch(setAddCardStep(2));
                dispatch(setIsUserAddingNewCardInTransactions(true));
              }}
              itemID="addNewCardToUploadTLBtnTest"
            >
              <Box className="chooseCard__dialog--addButton">
                <DarkPlusIcon />
                <Box flex={1}>
                  <Typography variant="h6" align="center">
                    <Box fontWeight={600}>{web_btn_enter_new_card_creditcard_popup}</Box>
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  );
};

export default ChooseCard;
