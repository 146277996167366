import {
  GetPinsBodyData,
  onChangeCustomerInfoReqBodyData,
  onChangePhoneValidationReqBodyData,
  organizationListBodyData,
  organizationTypeListBodyData,
} from './../../Config/ServiceConfig';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  onLoginBodyData,
  onConfirmLoginSmsBodyData,
  onSendTokenChangePasswordBodyData,
  onConfirmSmsBodyData,
  insertOrUpdateHesCodeBodyData,
  onUpdateCommercialStatusReqBodyData,
} from 'Config/ServiceConfig';
import { push, replace, routerActions } from 'connected-react-router';
import {
  OnLoginModel,
  OnConfirmLoginSmsModel,
  OnConfirmSmsModel,
  OnSendTokenChangePasswordModel,
  InsertOrUpdateHesCodeModel,
  OnUpdateCommercialStatusModel,
  OnChangeCustomerInfoModel,
  OnChangePhoneValidationModel,
  OrganizationTypeListModel,
  OrganizationListModel,
  GetTagsModel,
  GetPinsModel,
} from 'Models';
import { routePath } from 'Navigator/routes';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  onLogin,
  onConfirmLoginSms,
  onConfirmSms,
  onSendTokenChangePassword,
  onUpdateCommercialStatus,
  onChangeCustomerInfo,
  organizationTypeList,
  getTags,
  getPins,
} from 'Services/Queries';
import {
  onLoginRequest,
  onLoginRequestSuccess,
  onConfirmLoginSmsRequest,
  onConfirmLoginSmsRequestSuccess,
  onSendTokenChangePasswordRequest,
  onSendTokenChangePasswordSuccess,
  onConfirmSmsRequest,
  onConfirmSmsRequestSuccess,
  onConfirmSmsRequestForForgotPassword,
  incrementForgotPasswordFormStep,
  insertOrUpdateHesCodeRequest,
  insertOrUpdateHesCodeRequestSuccess,
  insertOrUpdateHesCodeRequestFail,
  decrementHesCodeState,
  onLoginRequestFail,
  onConfirmLoginSmsRequestFail,
  onSendTokenChangePasswordFail,
  onConfirmSmsRequestFail,
  onConfirmSmsRequestForForgotPasswordFail,
  onUpdateCommercialStatusRequest,
  onUpdateCommercialStatusRequestSuccess,
  onUpdateCommercialStatusRequestFail,
  onChangeCustomerInfoRequest,
  onChangeCustomerInfoRequestSuccess,
  onChangeCustomerInfoRequestFail,
  userStore,
  onChangePhoneValidationRequest,
  onChangePhoneValidationRequestSuccess,
  onChangePhoneValidationRequestFail,
  organizationTypeListRequest,
  organizationTypeListSuccess,
  organizationTypeListFail,
  organizationListRequest,
  organizationListSuccess,
  organizationListFail,
  setReCaptchaToken,
} from './slices';

import { showOTPDialog, closeOTPDialog, show651ErrorDialog } from '../Dialog/slices';
import { setUserNumber, storeWelcome } from '../Welcome/slices';
import { WelcomeState } from '../Welcome/types';
import { isBelbimRequestSuccess, sendEVAMCustomEvent, spaceRemover } from 'Utils';
import { setFormError } from 'Stores/Form';
import { formNameKeys, formNames } from 'Config';
import { showToastify } from 'Stores/Toastify/slices';
import { insertOrUpdateHesCode, onChangePhoneValidation } from 'Services/Mutations';
import { setAccessTrue } from 'Stores/App';
import { userInfoTypes } from 'Views/UserInfo/constant';
import { UserState } from './types';
import {
  setUserNewNumberForUpdate,
  showHideIbanDialog,
  showHideUserJobDialog,
  showHideUserMailDialog,
  showHideUserMailInfoDialog,
  showHideUserPhoneDialog,
} from 'Stores/Account';
import organizationList from 'Services/Queries/OrganizationList';
import EVAMSdkEventTypes from 'Utils/EVAMSdkEventTypes';
import { setH } from '.';

const userEnterPasswordWrong5Times = {
  '594': '594',
  '651': '651',
};

function* handleOnLoginRequestSaga({
  payload,
}: PayloadAction<{ onLoginData: onLoginBodyData; formName: formNameKeys }>) {
  const { onLoginData, formName } = payload;

  try {
    const onLoginResult: OnLoginModel = yield call(() => onLogin(onLoginData));

    const { requestSuccess, responseCode } = isBelbimRequestSuccess(onLoginResult);

    if (requestSuccess) {
      yield put(onLoginRequestSuccess(onLoginResult));
      yield put(showOTPDialog());
      yield put(setReCaptchaToken(null));
    } else {
      if (
        responseCode === userEnterPasswordWrong5Times['594'] ||
        responseCode === userEnterPasswordWrong5Times['651']
      ) {
        yield put(show651ErrorDialog());
        yield put(onLoginRequestFail(responseCode));
      } else {
        yield put(onLoginRequestFail(responseCode));
        yield put(setFormError({ errorCode: responseCode, formName }));
      }
    }
  } catch (error: any) {
    yield put(onLoginRequestFail(error));
    yield put(setFormError({ errorCode: error, formName }));
  }
}

// Workers
function* handleOnConfirmLoginSmsRequestSaga({ payload }: PayloadAction<onConfirmLoginSmsBodyData>) {
  try {
    const onConfirmLoginSmsResult: OnConfirmLoginSmsModel = yield call(() => onConfirmLoginSms(payload));

    const { requestSuccess, responseCode } = isBelbimRequestSuccess(onConfirmLoginSmsResult);

    if (requestSuccess) {
      yield put(closeOTPDialog());
      yield put(onConfirmLoginSmsRequestSuccess(onConfirmLoginSmsResult));
      yield put(setAccessTrue());

      if (onConfirmLoginSmsResult.data.ResetPassword) {
        yield put(push(routePath.changePassword));
      } else {
        yield put(replace(routePath.home));
      }
      const evamSdkData = {
        customerNumber: onConfirmLoginSmsResult?.data?.CustomerNumber,
      };
      sendEVAMCustomEvent(EVAMSdkEventTypes.KULLANICI_LOGIN, evamSdkData);
    } else {
      yield put(onConfirmLoginSmsRequestFail(responseCode));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.dialogOTP }));
    }
  } catch (error: any) {
    yield put(onConfirmLoginSmsRequestFail(error));
    setFormError({ errorCode: error, formName: formNames.dialogOTP });
  }
}

function* handleOnSendTokenChangePasswordRequestSaga({ payload }: PayloadAction<onSendTokenChangePasswordBodyData>) {
  try {
    const onSendTokenChangePasswordResult: OnSendTokenChangePasswordModel = yield call(() =>
      onSendTokenChangePassword(payload)
    );

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onSendTokenChangePasswordResult);

    if (requestSuccess) {
      yield put(onSendTokenChangePasswordSuccess(onSendTokenChangePasswordResult));
      yield put(showOTPDialog());
    } else {
      yield put(onSendTokenChangePasswordFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.forgotPassword,
        })
      );
    }
  } catch (error: any) {
    yield put(onSendTokenChangePasswordFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.forgotPassword }));
  }
}

function* handleOnConfirmSmsRequestSaga({ payload }: PayloadAction<onConfirmSmsBodyData>) {
  try {
    const onConfirmSmsResult: OnConfirmSmsModel = yield call(() => onConfirmSms(payload));

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onConfirmSmsResult);

    if (requestSuccess) {
      yield put(onConfirmSmsRequestSuccess(onConfirmSmsResult));

      yield put(setH(onConfirmSmsResult?.data.h));

      const welcomeState: WelcomeState = yield select(storeWelcome);

      const customerStatus = welcomeState.onCheckPhoneNumberData?.data.CustomerStatus;

      const { Cellphone, CountryCode } = welcomeState;

      if (customerStatus == '-1' || customerStatus == '0') {
        yield put(closeOTPDialog());
        yield put(
          push(routePath.signup, {
            countryCode: CountryCode,
            phoneNumber: Cellphone,
          })
        );
      } else {
        yield put(
          push(routePath.login, {
            type: 1,
            countryCode: CountryCode,
            phoneNumber: Cellphone,
          })
        );
      }
      yield put(closeOTPDialog());
    } else {
      yield put(onConfirmSmsRequestFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.dialogOTP }));
    }
  } catch (error: any) {
    yield put(onConfirmSmsRequestFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.dialogOTP }));
  }
}

function* handleOnConfirmSmsForForgotPasswordRequestSaga({ payload }: PayloadAction<onConfirmSmsBodyData>) {
  try {
    const onConfirmSmsResult: OnConfirmSmsModel = yield call(() => onConfirmSms(payload));

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onConfirmSmsResult);

    if (requestSuccess) {
      yield put(closeOTPDialog());
      yield put(onConfirmSmsRequestSuccess(onConfirmSmsResult));
      yield put(incrementForgotPasswordFormStep());
    } else {
      yield put(onConfirmSmsRequestForForgotPasswordFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.dialogOTP,
        })
      );
    }
  } catch (error: any) {
    yield put(onConfirmSmsRequestForForgotPasswordFail(error));
    yield put(
      setFormError({
        errorCode: error,
        formName: formNames.dialogOTP,
      })
    );
  }
}

function* handleInsertOrUpdateHesCodeRequestSaga({ payload }: PayloadAction<insertOrUpdateHesCodeBodyData>) {
  try {
    const response: InsertOrUpdateHesCodeModel = yield call(() => insertOrUpdateHesCode(payload));

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(insertOrUpdateHesCodeRequestSuccess(payload.HesCode ? payload.HesCode : ''));
      yield put(decrementHesCodeState());
      yield put(
        showToastify({
          toastMessage: 'web_success_hes_guncelleme',
          type: 'success',
        })
      );
    } else {
      yield put(insertOrUpdateHesCodeRequestFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.hes }));
    }
  } catch (error: any) {
    yield put(insertOrUpdateHesCodeRequestFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.hes }));
  }
}

function* handleOnUpdateCommercialStatusRequestSaga({ payload }: PayloadAction<onUpdateCommercialStatusReqBodyData>) {
  try {
    const userData: UserState = yield select(userStore);

    const onUpdateCommercialStatusResult: OnUpdateCommercialStatusModel = yield call(() =>
      onUpdateCommercialStatus(payload)
    );

    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onUpdateCommercialStatusResult);

    if (requestSuccess) {
      yield put(onUpdateCommercialStatusRequestSuccess(payload.CommercialStatusChannelType));
      const evamSdkData = {
        customer_no: userData?.customerNumber,
        CommercialStatusChannelType: payload?.CommercialStatusChannelType,
        EmailAdress: userData?.email,
        CellPhone: `+${userData?.countryCode} ${userData?.phoneNumber}`,
      };

      sendEVAMCustomEvent(EVAMSdkEventTypes.TICARI_ILETI_IZNI_ALINMASI, evamSdkData);
      yield put(
        showToastify({
          toastMessage: 'web_account_contact_info_update_text',
          type: 'success',
        })
      );
    } else {
      yield put(onUpdateCommercialStatusRequestFail(errorMessage));
      yield put(
        setFormError({
          errorCode: responseCode,
          formName: formNames.contactPermission,
        })
      );
    }
  } catch (error: any) {
    yield put(onSendTokenChangePasswordFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.contactPermission }));
  }
}
function* handleOnChangeCustomerInfoRequestSaga({ payload }: PayloadAction<onChangeCustomerInfoReqBodyData>) {
  try {
    const onChangeCustomerInfoResult: OnChangeCustomerInfoModel = yield call(() => onChangeCustomerInfo(payload));
    const { requestSuccess, responseCode, errorMessage } = isBelbimRequestSuccess(onChangeCustomerInfoResult);

    const userData: UserState = yield select(userStore);

    if (requestSuccess) {
      yield put(onChangeCustomerInfoRequestSuccess(payload));
      switch (payload.TypeOfField) {
        case userInfoTypes.iban:
          yield put(showHideIbanDialog(false));
          if (!userData.iban) {
            yield put(
              showToastify({
                toastMessage: 'msg_web_iban_added',
                type: 'success',
              })
            );
          } else {
            yield put(
              showToastify({
                toastMessage: 'msg_web_iban_updated',
                type: 'success',
              })
            );
          }
          break;
        case userInfoTypes.job:
          yield put(showHideUserJobDialog(false));
          if (!userData.job) {
            yield put(
              showToastify({
                toastMessage: 'msg_web_job_added',
                type: 'success',
              })
            );
          } else {
            yield put(
              showToastify({
                toastMessage: 'msg_web_job_updated',
                type: 'success',
              })
            );
          }
          break;
        case userInfoTypes.phone:
          yield put(showOTPDialog());
          yield put(
            setUserNewNumberForUpdate({
              cellPhone: payload.Cellphone,
              countryCode: payload.CountryCode,
            })
          );
          break;
        case userInfoTypes.mail:
          yield put(showHideUserMailDialog(false));
          yield put(showHideUserMailInfoDialog(true));
          break;
        default:
          break;
      }
    } else {
      yield put(onChangeCustomerInfoRequestFail(errorMessage));
      switch (payload.TypeOfField) {
        case userInfoTypes.iban:
          yield put(
            setFormError({
              errorCode: errorMessage,
              formName: formNames.updateUserIbanNumber,
            })
          );
          break;
        case userInfoTypes.job:
          yield put(
            setFormError({
              errorCode: responseCode,
              formName: formNames.updatedJob,
            })
          );
          break;
        case userInfoTypes.phone:
          yield put(
            setFormError({
              errorCode: responseCode,
              formName: formNames.updateUserPhoneNumber,
            })
          );
          break;
        case userInfoTypes.mail:
          yield put(
            setFormError({
              errorCode: responseCode,
              formName: formNames.updateUserMail,
            })
          );
          break;
        default:
          break;
      }
    }
  } catch (error: any) {
    yield put(onChangeCustomerInfoRequestFail(error));
    yield put(setFormError({ errorCode: error, formName: formNames.contactPermission }));
  }
}

function* handleOnChangePhoneValidationRequestSaga({ payload }: PayloadAction<onChangePhoneValidationReqBodyData>) {
  try {
    const response: OnChangePhoneValidationModel = yield call(() => onChangePhoneValidation(payload));

    const { requestSuccess, errorMessage, responseCode } = isBelbimRequestSuccess(response);

    if (requestSuccess) {
      yield put(onChangePhoneValidationRequestSuccess(payload));
      yield put(
        setUserNumber({
          Cellphone: payload.CellPhone ? payload.CellPhone : '',
          CountryCode: payload.CountryCode ? payload.CountryCode : '',
        })
      );
      yield put(
        setUserNewNumberForUpdate({
          cellPhone: undefined,
          countryCode: undefined,
        })
      );
      yield put(closeOTPDialog());
      yield put(showHideUserPhoneDialog(false));
      yield put(
        showToastify({
          toastMessage: 'msg_web_phone_number_updated',
          type: 'success',
        })
      );
    } else {
      yield put(onChangePhoneValidationRequestFail(errorMessage));
      yield put(setFormError({ errorCode: responseCode, formName: formNames.dialogOTP }));
    }
  } catch (error: any) {
    yield put(setFormError({ errorCode: error, formName: formNames.dialogOTP }));
  }
}
function* handleOrganizationTypeListRequestSaga({ payload }: PayloadAction<organizationTypeListBodyData>) {
  try {
    const response: OrganizationTypeListModel = yield call(() => organizationTypeList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(organizationTypeListSuccess(response.data.OrganizationTypeList));
    } else {
      yield put(organizationTypeListFail());
    }
  } catch (error) {
    yield put(organizationTypeListFail());
  }
}
function* handleOrganizationListRequestSaga({ payload }: PayloadAction<organizationListBodyData>) {
  try {
    const response: OrganizationListModel = yield call(() => organizationList(payload));
    const { requestSuccess } = isBelbimRequestSuccess(response);
    if (requestSuccess) {
      yield put(organizationListSuccess(response.data.OrganizationList));
    } else {
      yield put(organizationListFail());
    }
  } catch (error) {
    yield put(organizationListFail());
  }
}

// Watchers
function* userSagasWatcher() {
  yield takeLatest(onChangePhoneValidationRequest, handleOnChangePhoneValidationRequestSaga);
  yield takeLatest(insertOrUpdateHesCodeRequest, handleInsertOrUpdateHesCodeRequestSaga);
  yield takeLatest(onChangeCustomerInfoRequest, handleOnChangeCustomerInfoRequestSaga);
  yield takeLatest(onUpdateCommercialStatusRequest, handleOnUpdateCommercialStatusRequestSaga);
  yield takeLatest(onLoginRequest, handleOnLoginRequestSaga);
  yield takeLatest(onConfirmLoginSmsRequest, handleOnConfirmLoginSmsRequestSaga);
  yield takeLatest(onSendTokenChangePasswordRequest, handleOnSendTokenChangePasswordRequestSaga);
  yield takeLatest(onConfirmSmsRequest, handleOnConfirmSmsRequestSaga);
  yield takeLatest(onConfirmSmsRequestForForgotPassword, handleOnConfirmSmsForForgotPasswordRequestSaga);
  yield takeLatest(organizationTypeListRequest, handleOrganizationTypeListRequestSaga);
  yield takeLatest(organizationListRequest, handleOrganizationListRequestSaga);
}

export default userSagasWatcher;
