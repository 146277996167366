import { axiosHelper } from 'Services/AxiosHelper';
import { VsPosBelbimModel } from 'Models';
import { vsPosBeldimReqBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'vpos.vpos';

const VsPosBelbimReq = async (params: vsPosBeldimReqBodyData): Promise<VsPosBelbimModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
      isVposUrl: true,
    })
  ).data;

export default VsPosBelbimReq;
