import { axiosHelper } from 'Services/AxiosHelper';
import { OnSendTokenChangePasswordModel } from 'Models';
import { onSendTokenChangePasswordBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';

const command = 'RI.OnSendToken4ChangePassword';

const onSendTokenChangePassword = async (
  params: onSendTokenChangePasswordBodyData
): Promise<OnSendTokenChangePasswordModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onSendTokenChangePassword;
