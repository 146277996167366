import { FC, memo } from 'react';
import { WelcomeHeader } from 'Views/Welcome/WelcomeHeader';
import { FAQ } from 'Views/FAQ';
import { Box } from '@material-ui/core';
import { gutters } from 'Theme/Variables';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onGetFaqRequest } from 'Stores/Account';
import { Container, ArrowLeftIcon } from 'Components';
import { useHistory } from 'react-router-dom';
import { routePath } from 'Navigator/routes';

import './WelcomeFaq.scss';

type WelcomeFaqProps = {};

const WelcomeFaq: FC<WelcomeFaqProps> = memo(() => {
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(onGetFaqRequest());
  }, []);
  return (
    <div id="welcomeFaq">
      <WelcomeHeader />
      <Container>
        <Box marginTop={gutters.large}>
          <FAQ />
        </Box>
      </Container>
    </div>
  );
});
export default WelcomeFaq;
