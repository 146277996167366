import { FC, useEffect, useRef, useState } from 'react';
import {
  FormErrorWrapper,
  FormWrapper,
  MaskedTextField,
  MasterPassIcon,
  Tooltip,
  CheckboxUnderlineLabel,
  ArrowLeftIcon,
  Modal3DSecure,
  DialogWithActions,
} from 'Components';
import { Box, TextField, Typography, Button, Grid } from '@material-ui/core';
import { useLocalization, useErrorMessage, useModal, useQuery, useLanguage } from 'Hooks';
import { Formik } from 'formik';
import { formikGlobalConfig, formNames, inputMaskTypes } from 'Config';
import { gutters } from 'Theme/Variables';
import { AddCreditCardSchema, addCreditCardSchemaInitialValues } from 'Validations';
import { useDispatch } from 'react-redux';
import { onGetMasterpassForMobileRequest } from 'Stores/CreditCardTransactions';
import { expireDateUnmask, generateLanguageParam, MasterPassTokenEnum, spaceRemover } from 'Utils';
import { masterPassRegisterNormalize, mobileMasterpassConnectNormalize } from 'Normalize';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { onInıtDataRequest, setLanguage } from 'Stores/App';

import './MobileAddCreditCard.scss';
import { useCallback } from 'react';
import sanitizeHtml from 'Utils/SanitizeHtml';

type MobileAddCreditCardProps = {};

const MobileAddCreditCard: FC<MobileAddCreditCardProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useQuery();
  const [isShowMasterPassDetail, setIsShowMasterPassDetail] = useState(false);
  const [isOpenRuleDialog, setIsOpenRuleDialog] = useState(false);
  const {
    web_title_add_credit_card,
    web_desc_add_creditcard__upload_tl,
    web_plc_add_credit_card_number,
    web_plc_add_credit_card_name,
    web_plc_add_credit_card_expiration_date_detail,
    web_plc_add_credit_card_cvv,
    web_plc_add_credit_card_expiration_date,
    web_check_masterpass_add_credit_card,
    web_btn_next,
    web_msg_credit_card_cvv,
    web_title_cvv_box,
    web_masterpass_link,
    web_masterpass_link_title,
    masterpass_saved_cards_popup_header,
    masterpass_saved_cards_popup_desc,
    masterpass_saved_cards_popup_btn,
    web_check_masterpass_add_credit_card_for_automatic_instruction,
    web_automatic_instruction_masterpass_link_header,
    web_automatic_instruction_masterpass_link_desc,
    web_automatic_instruction_masterpass_link_button,
  } = useLocalization();

  const { isVisible, show, hide } = useModal();

  const { getError, getErrorMessage } = useErrorMessage();

  const i18n = useLanguage();

  const phoneNumber = url.get('phoneNumber');
  const isObligation = url.get('isObligation');
  const masterpassToken = url.get('masterpassToken');
  const language = url.get('language');
  const reqRefNumber = url.get('reqRefNumber');
  const isRequired = isObligation === 'true' ? true : false;
  const isLinked = url.get('isLinked') === 'false' ? false : true;
  const isInstruction = url.get('isInstruction') === 'true' ? true : false;
  const formRef = useRef<any>();

  const resetFormState = () => {
    formRef?.current?.resetForm();
  };

  useEffect(() => {
    if (!phoneNumber || !masterpassToken) {
      history.push(routePath.welcome);
    }

    if (language) {
      i18n.changeLanguage(language || '');
      dispatch(setLanguage());
      dispatch(onInıtDataRequest(generateLanguageParam()));
    }

    resetFormState();
  }, []);

  useEffect(() => {
    if (!isLinked) {
      setIsOpenRuleDialog(true);
    }
  }, []);

  const handleSubmit = (values: typeof addCreditCardSchemaInitialValues) => {
    const cardNumber = spaceRemover(values.creditCardNumber);
    const expireDate = expireDateUnmask(values.expireDate);
    if (values.addCardToMasterPass) {
      dispatch(
        onGetMasterpassForMobileRequest({
          TokenType: MasterPassTokenEnum.REGISTER,
          request: {
            data: masterPassRegisterNormalize(
              '',
              cardNumber,
              expireDate,
              values.cvv,
              values.cardName,
              masterpassToken || '',
              phoneNumber || '',
              reqRefNumber || ''
            ),
          },
        })
      );
    } else {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          cardNumber,
          expireDate,
          cvv: values.cvv,
          cardName: values.cardName,
          processDone: true,
          isCardAddedWithMasterpass: false,
        })
      );
    }
  };

  const handleBackAction = useCallback(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        navigateBack: true,
      })
    );
  }, []);

  return (
    <Box className="mobileAddCreditCardWrapper">
      <FormErrorWrapper formName={formNames.mobileAddCreditCard} resetFormOnRouteChange>
        <div id="mobileAddCreditCard">
          <ArrowLeftIcon onClick={handleBackAction} />
          <Typography variant="h3" align="center">
            <Box fontWeight={600} marginBottom={gutters.small} marginTop={gutters.regular}>
              {web_title_add_credit_card}
            </Box>
          </Typography>
          <Box className="mobileAddCreditCard__desc">
            <Typography variant="body1" align="center">
              {web_desc_add_creditcard__upload_tl}
            </Typography>
          </Box>
          <Formik
            initialValues={addCreditCardSchemaInitialValues}
            validationSchema={AddCreditCardSchema(!isRequired)}
            onSubmit={handleSubmit}
            {...formikGlobalConfig}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                setFieldValue,
                values: { creditCardNumber, expireDate, cvv, cardName, addCardToMasterPass },
                errors: {
                  creditCardNumber: errorCreditCardNumber,
                  expireDate: errorExpireDate,
                  cvv: errorCvv,
                  cardName: errorCardName,
                  addCardToMasterPass: errorCardToMasterPass,
                },
                touched: {
                  creditCardNumber: touchedCreditCardNumber,
                  expireDate: touchedExpireDate,
                  cvv: touchedCvv,
                  cardName: touchedCardName,
                  addCardToMasterPass: touchedAddToMasterPass,
                },
              } = formikProps;
              return (
                <FormWrapper onSubmit={formikProps.handleSubmit}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="column"
                    height="80vh"
                  >
                    <Box>
                      <Box className="mobileAddCreditCard__inputs">
                        <MaskedTextField
                          name="creditCardNumber"
                          label={web_plc_add_credit_card_number}
                          value={creditCardNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 14 }}
                          mask={inputMaskTypes.userCardNo}
                          variant="filled"
                          fullWidth
                          error={getError(errorCreditCardNumber, touchedCreditCardNumber)}
                          helperText={getErrorMessage(errorCreditCardNumber, touchedCreditCardNumber)}
                          itemID="creditCardNumberInputTest"
                        />
                      </Box>
                      <Box className="mobileAddCreditCard__inputs addCreditCard__middleBlock">
                        <Grid container className="mobileAddCreditCard__innerContainer" spacing={1}>
                          <Grid item xs={7}>
                            <Box className="mobileAddCreditCard__expireDate">
                              <MaskedTextField
                                name="expireDate"
                                mask={inputMaskTypes.creditCardExpireDate}
                                label={web_plc_add_credit_card_expiration_date}
                                value={expireDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={web_plc_add_credit_card_expiration_date_detail}
                                error={getError(errorExpireDate, touchedExpireDate)}
                                helperText={getErrorMessage(errorExpireDate, touchedExpireDate)}
                                itemID="creditCardExpireDateInputTest"
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Box className="mobileAddCreditCard__cvc">
                              <MaskedTextField
                                name="cvv"
                                label={web_plc_add_credit_card_cvv}
                                variant="filled"
                                fullWidth
                                value={cvv}
                                mask={inputMaskTypes.cvv}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError(errorCvv, touchedCvv)}
                                helperText={getErrorMessage(errorCvv, touchedCvv)}
                                inputProps={{ maxLength: 3, inputMode: 'numeric' }}
                                itemID="creditCardCvvInputTest"
                              />
                              <Box className="mobileAddCreditCard__tooltip__box">
                                <Tooltip
                                  title={web_title_cvv_box}
                                  id="mobileAddCreditCard__tooltip"
                                  desc1={web_msg_credit_card_cvv}
                                  calculate
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="mobileAddCreditCard__inputs">
                        <TextField
                          name="cardName"
                          label={web_plc_add_credit_card_name}
                          variant="filled"
                          fullWidth
                          value={cardName}
                          onChange={(e) => setFieldValue('cardName', sanitizeHtml(e.target.value))}
                          onBlur={handleBlur}
                          error={getError(errorCardName, touchedCardName)}
                          helperText={getErrorMessage(errorCardName, touchedCardName)}
                          inputProps={{ maxLength: 20 }}
                          itemID="creditCardNameInputTest"
                        />
                      </Box>
                    </Box>
                    <Box className="mobileAddCreditCard__bottom">
                      <Box className="mobileAddCreditCard__checkbox">
                        {isLinked && (
                          <>
                            <CheckboxUnderlineLabel
                              name="addCardToMasterPass"
                              color="primary"
                              errorText={getErrorMessage(
                                errorCardToMasterPass,
                                touchedAddToMasterPass && !addCardToMasterPass
                              )}
                              checked={addCardToMasterPass}
                              onChange={handleChange}
                              onUnderlineTextClick={() => setIsShowMasterPassDetail(true)}
                              value={addCardToMasterPass}
                              text={
                                isInstruction
                                  ? web_check_masterpass_add_credit_card_for_automatic_instruction
                                  : web_check_masterpass_add_credit_card
                              }
                              marginBottomGutter="0"
                              className="mobileAddCreditCard__checkbox--flex"
                              itemID="creditCardMasterpassCheckboxInputTest"
                            />
                            <MasterPassIcon />
                          </>
                        )}
                      </Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        itemID="mobileAddCreditCardBtnTest"
                        disabled={isInstruction && !addCardToMasterPass ? true : false}
                      >
                        {web_btn_next}
                      </Button>
                    </Box>
                  </Box>
                </FormWrapper>
              );
            }}
          </Formik>
        </div>
      </FormErrorWrapper>
      <DialogWithActions
        alignTitle="left"
        closeIcon
        masterpassLogo
        primaryButton={{
          handleClick: () => {
            setIsOpenRuleDialog(false);
            dispatch(
              onGetMasterpassForMobileRequest({
                TokenType: MasterPassTokenEnum.LINK,
                request: {
                  data: mobileMasterpassConnectNormalize({
                    sendSmsLanguage: language,
                    referenceNo: reqRefNumber,
                    token: masterpassToken,
                    msisdn: phoneNumber,
                  }),
                },
              })
            );
          },
          label: isInstruction ? web_automatic_instruction_masterpass_link_button : masterpass_saved_cards_popup_btn,
        }}
        open={isOpenRuleDialog}
        text={isInstruction ? web_automatic_instruction_masterpass_link_desc : masterpass_saved_cards_popup_desc}
        hide={() => {
          setIsOpenRuleDialog(false);
          if (isInstruction) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                navigateBack: true,
              })
            );
          }
        }}
        title={isInstruction ? web_automatic_instruction_masterpass_link_header : masterpass_saved_cards_popup_header}
      />

      {isShowMasterPassDetail && (
        <Modal3DSecure
          isFibabank
          open={isShowMasterPassDetail}
          link={'https://www.masterpassturkiye.com/TermsAndConditions.aspx'}
          close={() => setIsShowMasterPassDetail(false)}
        />
      )}
    </Box>
  );
};

export default MobileAddCreditCard;
