import React from 'react';

const MetroIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#1547E1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.17219 18.678L8.21219 17.159H15.7582L16.7972 18.678H7.17219ZM8.14119 13.936C7.61919 13.936 7.19419 13.512 7.19419 12.991C7.19419 12.47 7.61919 12.045 8.14119 12.045C8.66219 12.045 9.08619 12.47 9.08619 12.991C9.08619 13.512 8.66219 13.936 8.14119 13.936ZM14.9142 12.991C14.9142 12.47 15.3382 12.045 15.8592 12.045C16.3812 12.045 16.8062 12.47 16.8062 12.991C16.8062 13.512 16.3812 13.936 15.8592 13.936C15.3382 13.936 14.9142 13.512 14.9142 12.991ZM7.12119 8.43L7.48819 6.583C7.54219 6.251 7.72319 5.934 7.99219 5.707C8.26519 5.478 8.62619 5.367 8.96319 5.359H15.0322C15.4032 5.36 15.7262 5.478 15.9962 5.708C16.2662 5.933 16.4472 6.251 16.5042 6.6L16.8712 8.453C16.9222 8.651 16.9252 8.888 16.8742 9.112C16.8222 9.338 16.7182 9.55 16.5742 9.725C16.4272 9.903 16.2402 10.045 16.0332 10.137C15.8442 10.221 15.6382 10.264 15.4322 10.264C15.4122 10.264 15.3922 10.264 15.3722 10.263H8.58519C8.38119 10.274 8.14319 10.222 7.93919 10.129C7.73519 10.035 7.55119 9.892 7.40719 9.715C7.26219 9.536 7.16119 9.324 7.11319 9.102C7.06419 8.885 7.06619 8.651 7.12119 8.43ZM19.4952 15.748C19.8832 15.302 20.1652 14.789 20.3332 14.226C20.5032 13.661 20.5482 13.078 20.4682 12.492L19.5682 5.928C19.4332 4.944 18.9482 4.043 18.2002 3.389C17.4532 2.737 16.4942 2.376 15.5012 2.375H8.50119C7.51319 2.375 6.55919 2.731 5.81219 3.379C5.05719 4.032 4.56719 4.937 4.43119 5.928L3.53119 12.493C3.45219 13.078 3.49719 13.661 3.66519 14.225C3.83419 14.79 4.11719 15.302 4.50519 15.749C4.89119 16.192 5.36019 16.542 5.89819 16.788C6.09519 16.878 6.30219 16.934 6.50819 16.992L4.14019 20.451C3.90619 20.794 3.99419 21.26 4.33619 21.494C4.46519 21.583 4.61319 21.625 4.75919 21.625C4.99719 21.625 5.23319 21.511 5.37819 21.299L6.14519 20.178H17.8232L18.5902 21.299C18.7362 21.511 18.9712 21.625 19.2102 21.625C19.3562 21.625 19.5032 21.583 19.6332 21.494C19.9742 21.261 20.0622 20.794 19.8282 20.452L17.4662 17C17.6822 16.942 17.8972 16.882 18.1032 16.788C18.6392 16.542 19.1072 16.193 19.4952 15.748Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MetroIcon;
