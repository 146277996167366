import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogProps, TextField, DialogContent, DialogTitle } from '@material-ui/core';
import { BlueTickIcon, CloseIcon, SearchIcon } from 'Components';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';
import { turkishCharacterNormalize } from 'Utils';
import { useSelector } from 'react-redux';
import { onInıtDataStore } from 'Stores/App';

import './DialogAdressType.scss';
import { turkishLowerCase } from 'Utils/TurkishCharacterNormalize';

export type adressTypeData = {
  AddressCode: string;
  AddressCodeDesc: string;
};

type DialogAdressTypeProps = {
  hide: () => void;
  isOpen: boolean;
  selectedValue?: string;
  setSelectedValue: Function;
};

const DialogAdressType: FunctionComponent<DialogAdressTypeProps> = ({
  hide,
  isOpen,
  selectedValue,
  setSelectedValue,
}) => {
  const { web_account_search } = useLocalization();
  const [searchKey, setSearchKey] = useState<string>('');

  const onInıtData = useSelector(onInıtDataStore);

  const adress: adressTypeData[] | undefined = onInıtData.AdressList;

  const itemClickHandle = (adressItem: adressTypeData) => {
    setSelectedValue(adressItem);
    hide();
  };

  const onClose = () => {
    setSearchKey('');
    hide();
  };

  useEffect(() => {
    setSearchKey('');
  }, [hide]);

  return (
    <Dialog id="dialogAdressType" onClose={onClose} scroll="paper" keepMounted={false} open={isOpen}>
      <DialogTitle className="dialogAdressType-search">
        <CloseIcon onClick={() => hide()} />
        <TextField
          label={web_account_search}
          variant="filled"
          value={searchKey}
          onChange={(e) => setSearchKey(e.currentTarget.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
        />
      </DialogTitle>

      <DialogContent>
        <div className="dialogAdressType-container">
          <AdressTypeBlock
            adress={adress}
            searchKey={searchKey}
            selectedValue={selectedValue}
            lowerCaseSearchKey={searchKey.toLocaleLowerCase()}
            itemClickHandle={itemClickHandle}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const AdressTypeBlock: FunctionComponent<{
  adress: adressTypeData[] | undefined;
  searchKey: string;
  lowerCaseSearchKey: string;
  selectedValue?: string;
  itemClickHandle: Function;
}> = ({ adress, lowerCaseSearchKey, selectedValue, itemClickHandle }) => {
  return (
    <ul className="dialogAdressType-container__list">
      {!!adress &&
        adress.length > 0 &&
        adress
          .filter(({ AddressCodeDesc }) => {
            return turkishLowerCase(AddressCodeDesc).includes(turkishLowerCase(lowerCaseSearchKey));
          })
          .map((item: adressTypeData, index) => (
            <Item
              key={index}
              title={item.AddressCodeDesc}
              isSelected={item.AddressCodeDesc === selectedValue}
              onClick={() => itemClickHandle(item)}
            />
          ))}
    </ul>
  );
};

const Item: FunctionComponent<{
  title: string;
  onClick: Function;
  isSelected: boolean;
}> = ({ title, onClick, isSelected }) => {
  return (
    <li onClick={() => onClick()} itemID="chooseAdressTypeBtnTest">
      <div
        className={classNames({
          'dialogAdressType-container__list-item': true,
          'dialogAdressType-container__list-item--selected': isSelected,
        })}
      >
        <span className="input-text">{title}</span>
        {isSelected && <BlueTickIcon />}
      </div>
    </li>
  );
};

export default DialogAdressType;
