import * as yup from 'yup';
import { errorMessages } from 'Config';
import { dateValidation } from 'Utils';

const CardInfoValidationSchema = yup.object({
  tckn: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  birthDate: yup
    .string()
    .required(errorMessages.require)
    .min(10, errorMessages.minBirthDate)
    .test('dateValidation', errorMessages.dateFormat, (value) => dateValidation(value)),
});

export default CardInfoValidationSchema;
