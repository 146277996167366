import * as yup from 'yup';
import { PasswordSchema } from './PasswordSchema';
import { dateValidation } from 'Utils';
import { errorMessages } from 'Config';

export const OldUserPasswordChangeSchemaFirstStep = yup.object({
  tcNo: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  birthDate: yup
    .string()
    .required(errorMessages.require)
    .min(10, errorMessages.minBirthDate)
    .test('dateValidation', errorMessages.dateFormat, (value) => dateValidation(value)),
});

export const OldUserPasswordChangeSchema = [OldUserPasswordChangeSchemaFirstStep, PasswordSchema];

export type OldUserPasswordChangeSchemaInputs = yup.InferType<
  typeof OldUserPasswordChangeSchemaFirstStep & typeof PasswordSchema
>;

export const oldUserPasswordChangeSchemaInitialValues: OldUserPasswordChangeSchemaInputs = {
  birthDate: '',
  tcNo: '',
  password: '',
  rePassword: '',
};
