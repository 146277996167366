import { FC, Fragment } from 'react';
import { DashboardLayout } from 'Components';
import { Box } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';

import './DigitalAccountSettings.scss';
import { useSelector } from 'react-redux';
import { userStore } from 'Stores/User';
import { useLocalization } from 'Hooks';

const DigitalAccountSettings: FC = () => {
  const { web_digital_account_settings_header, web_digital_account_name_settings } = useLocalization();
  const user = useSelector(userStore);

  return (
    <DashboardLayout backButton marginTop={gutters.large}>
      <Fragment>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          id="digitalCardSettings"
        >
          <Box fontWeight={700} fontSize={fontSizes.regularBig} color={colors.secondary}>
            {web_digital_account_settings_header}
          </Box>
          <Box
            border={`2px solid ${colors.separator}`}
            borderRadius={'12px'}
            padding={fontSizes.regularBig}
            width={'100%'}
            marginTop={gutters.large}
          >
            <Box fontWeight={700} lineHeight={'22px'} fontSize={fontSizes.small} color={colors.darkGray}>
              {web_digital_account_name_settings}
            </Box>
            <Box id="digitalCardSettings__name" fontSize={fontSizes.regularBig} color={colors.secondary}>
              {`${user.name} ${user.surname}`.toLocaleLowerCase('tr-TR')}
            </Box>
          </Box>
        </Box>
      </Fragment>
    </DashboardLayout>
  );
};

export default DigitalAccountSettings;
