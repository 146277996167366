import { useEffect } from 'react';
import { useState } from 'react';

const useCustomerLocation = () => {
  const [latitude, setLatitude] = useState<any>(0);
  const [longitude, setLongitude] = useState<any>(0);

  const checkPermissionQuery = () => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        // Will return ['granted', 'prompt', 'denied']
        const permission = result.state;
        if (permission === 'granted' || permission === 'prompt') {
          getLocation();
        }
      });
    } else if (navigator.geolocation) {
      getLocation();
    }
  };
  const getLocation = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  checkPermissionQuery();

  return { latitude, longitude };
};

export default useCustomerLocation;
