import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { DialogWithActions, LocationIcon, SelectArrowDownIcon } from 'Components';
import { Box, Typography, Button } from '@material-ui/core';
import { AdressItemType } from 'Models/OnGetCustomerInfoModel';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { AdressListDialog } from './AdressListDialog';
import { onAddApplicationBodyData } from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveUserAdress, getTownListRequest, AccountState, storeAccount } from 'Stores/Account';

import './AdressList.scss';
import {
  ApplicationProcessState,
  applicationStore,
  closeApplicationEmtpyAdressError,
  incStep,
  openApplicationEmtpyAdressError,
  openEmptyStreetError,
  setApplicationData,
} from 'Stores/CardApplication';
import { useLocalization } from 'Hooks';
import { addressInvalid } from '../helper';

export type AddressDetail = {
  AddressType?: any;
  CountryCode?: number;
  City?: string;
  CityCode?: number;
  TownCode?: string | number;
  District?: string;
  Street?: string;
  AddressDetail?: string;
  PostalCode?: string;
};

type AdressListProps = {
  adressList: Array<AdressItemType>;
  setIsAddNewAdress: Dispatch<SetStateAction<boolean>>;
  onEditChangeAddress: () => void;
  fillExportedData: (e: any) => void;
};
const AdressList: FC<AdressListProps> = ({ adressList, setIsAddNewAdress, fillExportedData, onEditChangeAddress }) => {
  const {
    web_btn_okay,
    web_card_incorrect_adkn_address_alert_title,
    web_card_incorrect_adkn_address_alert_desc,
    web_card_incorrect_address_alert_title,
    web_card_incorrect_address_alert_desc,
  } = useLocalization();
  const dispatch = useDispatch();

  const store: ApplicationProcessState = useSelector(applicationStore);

  const { activeApplicationData, applicationEmtpyAdressError } = store;
  const accountStore: AccountState = useSelector(storeAccount);
  const { townList } = accountStore;

  const currentAdressFromState = activeApplicationData?.AddressDetail;

  const appAdress: AdressItemType = {
    AddressDetail: currentAdressFromState?.AddressDetail || '',
    AddressType: currentAdressFromState?.AddressType || '0',
    CityCode: currentAdressFromState?.CityCode || 0,
    District: currentAdressFromState?.District || '',
    Street: currentAdressFromState?.Street || '',
    PostalCode: String(currentAdressFromState?.PostalCode) || '',
    Town: String(currentAdressFromState?.Town || ''),
  };

  const [currentAdress, setCurrentAdress] = useState<AdressItemType | any>(
    appAdress.AddressDetail !== '' ? appAdress : adressList[0]
  );
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  useEffect(() => {
    if (appAdress.AddressDetail === '') {
      dispatch(
        setApplicationData({
          AddressDetail: {
            AddressDetail: adressList[0].AddressDetail,
            AddressType: adressList[0].AddressType,
            District: adressList[0].District,
            CityCode: adressList[0].CityCode,
            PostalCode: adressList[0].PostalCode,
            Street: adressList[0].Street,
            Town: adressList[0].Town,
          },
        })
      );
    }
    currentAdress?.AddressType === 7 ? setIsAddNewAdress(true) : setIsAddNewAdress(false);
  }, []);

  const setDefaultAdress = (adress: AdressItemType) => {
    dispatch(
      setApplicationData({
        AddressDetail: {
          AddressDetail: adress.AddressDetail,
          AddressType: adress.AddressType,
          District: adress.District,
          CityCode: adress.CityCode,
          PostalCode: adress.PostalCode,
          Street: adress.Street,
          TownCode: adress.Town,
        },
      })
    );
    setCurrentAdress(adress);
  };

  const handleSubmit = () => {
    const contactAdress =
      (currentAdressFromState as AdressItemType) || adressList?.find((item) => item.AddressType === 9);
    // const townCode = townList?.find(
    //   (item) => item.ilceAdi.toLocaleLowerCase('tr-TR') === contactAdress?.Town.toLocaleLowerCase('tr-TR')
    // );

    if (currentAdress.AddressType === 7) {
      dispatch(openApplicationEmtpyAdressError());
    } else {
      if (addressInvalid(currentAdress)) {
        onEditChangeAddress();
        fillExportedData({
          cityCode: currentAdress.CityCode,
          town: currentAdress.Town,
          district: currentAdress.District,
          street: currentAdress.Street,
          adressDetail: currentAdress.AddressDetail,
          adressType: currentAdress.AddressType,
          postCode: currentAdress.PostalCode,
        });
        return;
        // dispatch(openApplicationEmtpyAdressError());
      } else if (contactAdress && !contactAdress?.Street) {
        dispatch(openEmptyStreetError());
        return;
      } else {
        fillExportedData({
          cityCode: currentAdress.CityCode,
          town: currentAdress.Town,
          district: currentAdress.District,
          street: currentAdress.Street,
          adressDetail: currentAdress.AddressDetail,
          adressType: currentAdress.AddressType,
          postCode: currentAdress.PostalCode,
        });
        dispatch(setActiveUserAdress(currentAdress));
        dispatch(incStep());
      }
    }
  };

  return (
    <div id="adressList">
      <Box onClick={() => setIsOpenDialog(true)} className="adressList__default-pick">
        <LocationIcon />
        <Box ml={gutters.small} className="adressList__adress-detail">
          <Typography variant="h3">
            <Box fontWeight={600} fontSize={fontSizes.regular}>
              {currentAdress?.Town}
            </Box>
          </Typography>

          <Typography
            style={{ maxWidth: '100%', overflow: 'hidden', wordBreak: 'break-all' }}
            className="adressList__adress-detail__desc"
            variant="body1"
          >
            {currentAdress?.AddressDetail}
          </Typography>
        </Box>
        <SelectArrowDownIcon />
      </Box>
      <AdressListDialog
        setIsAddNewAdress={setIsAddNewAdress}
        currentAdress={currentAdress}
        setCurrentAdress={setDefaultAdress}
        adressList={adressList}
        open={isOpenDialog}
        close={setIsOpenDialog}
      />
      <Box mt={gutters.small} />
      <Button
        onClick={handleSubmit}
        color="secondary"
        fullWidth
        variant="contained"
        itemID="cardApplicationNextBtnTest"
      >
        Devam et
      </Button>

      <DialogWithActions
        alignTitle="left"
        primaryButton={{
          label: web_btn_okay,
          handleClick: () => dispatch(closeApplicationEmtpyAdressError()),
        }}
        open={applicationEmtpyAdressError}
        hide={() => dispatch(closeApplicationEmtpyAdressError())}
        title={
          currentAdress.AddressType === 7
            ? web_card_incorrect_adkn_address_alert_title
            : web_card_incorrect_address_alert_title
        }
        text={
          currentAdress.AddressType === 7
            ? web_card_incorrect_adkn_address_alert_desc
            : web_card_incorrect_address_alert_desc
        }
      />
    </div>
  );
};
export default AdressList;
