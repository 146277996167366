import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';
import { GetCardVisaModel } from 'Models';
import { getCardVisaReqBodyData } from 'Config';

const command = 'b2c.GetCardVisa';

const getCardVisa = async (params: getCardVisaReqBodyData): Promise<GetCardVisaModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default getCardVisa;
