import * as React from 'react';

function NavigationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="pqh0zubvha"
          d="M14.67 22h-.06c-.453-.027-.83-.356-.92-.8l-1.54-7.57c-.08-.393-.387-.7-.78-.78L3.8 11.31c-.445-.088-.775-.466-.803-.919-.028-.453.252-.868.683-1.011l16-5.33c.367-.152.789-.072 1.075.204.286.275.382.693.245 1.066l-5.33 16c-.143.425-.552.703-1 .68z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-29 -1393) translate(0 1299) translate(29 94)">
              <path d="M0 0H24V24H0z" />
              <mask id="g3nfi3khlb" fill="#fff">
                <use xlinkHref="#pqh0zubvha" />
              </mask>
              <g fill="currentColor" mask="url(#g3nfi3khlb)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NavigationIcon;
