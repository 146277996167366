import * as React from 'react';

function WaitingCardShortIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="52px"
      height="33px"
      viewBox="0 0 52 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Group</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="element/tag/bekleyenkart" transform="translate(-17.000000, 0.000000)">
          <g id="Group" transform="translate(17.000000, 0.000000)">
            <polygon id="Rectangle" fill="#C22026" points="46 5 52 0 52 28 46 33"></polygon>
            <path
              d="M6,0 L52,0 L52,0 L52,28 L6,28 C2.6862915,28 1.29399067e-15,25.3137085 0,22 L0,6 C4.82366169e-16,2.6862915 2.6862915,1.4968968e-15 6,0 Z"
              id="Rectangle"
              fill="#FF5959"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaitingCardShortIcon;
