import { onGetCardTransactionListBodyData } from 'Config';
import moment from 'moment';
import { CardTransactionListTypeEnum, getDateFromSelectedFilter } from 'Utils';
export type beforeType = {
  beforeValue?: number;
};

export const getCardTransactionListNormalize = (values: onGetCardTransactionListBodyData & beforeType) => {
  const formValues = {
    CustomerNumber: values.CustomerNumber,
    SessionToken: values.SessionToken,
    CardId: values.CardId,
    TrnType: values.TrnType ? values.TrnType : CardTransactionListTypeEnum.ALL,
    PageIndex: values.PageIndex,
    PageSize: values.PageSize ? values.PageSize : 10,
    MinTrnAmount: values.MinTrnAmount && Number(values.MinTrnAmount),
    MaxTrnAmount: values.MaxTrnAmount && Number(values.MaxTrnAmount),
    StartDate: values.StartDate
      ? moment(values.StartDate).format('YYYY-MM-DD')
      : getDateFromSelectedFilter(values.beforeValue || 7),
    EndDate: values.EndDate
      ? moment(values.EndDate).add(1, 'days').format('YYYY-MM-DD')
      : moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
  };
  return formValues;
};
