import * as React from 'react';

function FibaBankLogoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="120" height="35" viewBox="0 0 120 35" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="-13.311%" y1="28.854%" x2="71.122%" y2="113.382%" id="8bn2ygloia">
          <stop stop-color="#8DC63F" offset="0%" />
          <stop stop-color="#F7FAF2" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="49mer0rybb">
          <stop stop-color="#006CB7" offset="18%" />
          <stop stop-color="#0F75AB" offset="30%" />
          <stop stop-color="#358E8A" offset="54%" />
          <stop stop-color="#72B556" offset="87%" />
          <stop stop-color="#8DC63F" offset="100%" />
        </linearGradient>
        <linearGradient x1="15.461%" y1="14.313%" x2="57.744%" y2="50.03%" id="hbj4b1k1vc">
          <stop stop-color="#8DC63F" offset="0%" />
          <stop stop-color="#94C94B" offset="16%" />
          <stop stop-color="#B8D988" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="dcyzw9ltkd">
          <stop stop-color="#8DC63F" offset="0%" />
          <stop stop-color="#006CB7" offset="56%" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          d="M63.92 22.677v-1.623a4.425 4.425 0 0 0-1.777-.343c-.822 0-1.011.343-1.011 1.203 0 .764.18 1.107 1.011 1.107a4.71 4.71 0 0 0 1.777-.344zm2.692-4.24v4.773c0 1.566-3.706 1.833-5.271 1.833-2.345 0-2.904-1.374-2.904-3.302 0-1.643.307-2.961 2.904-2.961.867.013 1.73.109 2.579.286v-.706c0-.84-.345-1.299-.994-1.375a4.754 4.754 0 0 0-1.145.018c-.383.04-.902.116-1.318.192-.417.075-.688-1.165-.84-1.528a.81.81 0 0 1-.039-.478.371.371 0 0 1 .287-.267 10.5 10.5 0 0 1 2.73-.38c2.693 0 4.01 1.354 4.01 3.895zM51.298 17.443v5.05c0 .133 1.414.324 2.33.324.554 0 .687-.65.687-1.376v-3.1c0-.706-.133-1.356-.687-1.356-.916 0-1.814.192-2.33.458zm0-5.671v3.495a7.282 7.282 0 0 1 2.885-.687c2.788 0 2.826 1.756 2.826 3.684v3.075c0 2.524-.667 3.704-2.806 3.704-1.082 0-3.99-.056-5.175-.839a.938.938 0 0 1-.42-.822V11.523c0-.42 1.509-.458 2.005-.458.38 0 .685.191.685.707zM101.762 11.772c0-.516-.306-.707-.689-.707-.496 0-2.003.038-2.003.458v13.062c0 .42 1.507.458 2.003.458.383 0 .69-.18.69-.705V19.62l2.939 4.889c.268.516 1.442.54 1.68.54.84 0 1.165-.15 1.11-.495-.307-.84-2.026-3.533-3.037-5.156l2.5-3.967a.968.968 0 0 0 .116-.36c.038-.325-.287-.515-1.165-.515-.402 0-1.356.038-1.585.458l-2.559 4.162v-7.404zM94.233 24.585c0 .42 1.49.458 1.984.458.343 0 .721-.21.721-.514v-6.265c0-1.89-.18-3.722-2.94-3.722-3.018 0-5.443.992-5.443 1.832v8.21c0 .42 1.529.459 2.005.459.325 0 .689-.21.689-.514v-7.01c0-.171 1.298-.496 2.31-.496.591 0 .667.803.667 1.7l.007 5.862z"
          fill="#0055B8"
        />
        <path
          d="M43.51 13.343v-1.804c0-.418 1.509-.476 1.983-.476.36 0 .707.21.707.541v1.686c0 .307-.344.516-.707.516-.474-.005-1.983-.043-1.983-.463z"
          fill="#006CB7"
          fill-rule="nonzero"
        />
        <path
          d="M46.276 15.285v9.053c0 .515-.307.705-.689.705-.496 0-2.005-.038-2.005-.458v-9.549c0-.42 1.51-.458 2.005-.458.382 0 .689.191.689.707z"
          fill="#0055B8"
          fill-rule="nonzero"
        />
        <path
          d="M36.7 19.104h4.218c.382 0 .42-1.412.42-1.89 0-.324-.209-.669-.478-.669H36.7V13.62h5.277c.4 0 .458-1.412.458-1.89 0-.324-.211-.668-.516-.668h-7.394c-.36 0-.688.21-.688.497v12.966c0 .4 1.662.514 2.164.514.36 0 .706-.285.706-.591l-.007-5.344zM83.978 22.677v-1.623a4.407 4.407 0 0 0-1.775-.343c-.822 0-1.013.343-1.013 1.203 0 .764.191 1.107 1.013 1.107a4.689 4.689 0 0 0 1.775-.344zm2.692-4.24v4.773c0 1.566-3.704 1.833-5.27 1.833-2.343 0-2.902-1.374-2.902-3.302 0-1.643.304-2.961 2.903-2.961.866.013 1.729.109 2.577.286v-.706c0-.84-.343-1.299-.992-1.375a4.768 4.768 0 0 0-1.147.018c-.38.04-.902.116-1.316.192-.415.075-.69-1.165-.84-1.528a.795.795 0 0 1-.039-.478.371.371 0 0 1 .285-.267c.89-.246 1.809-.374 2.732-.38 2.693 0 4.01 1.354 4.01 3.895zM71.358 17.443v5.05c0 .133 1.412.324 2.33.324.554 0 .687-.65.687-1.376v-3.1c0-.706-.133-1.356-.687-1.356-.918 0-1.814.192-2.33.458zm0-5.671v3.495a7.267 7.267 0 0 1 2.886-.687c2.787 0 2.825 1.756 2.825 3.684v3.075c0 2.524-.669 3.704-2.807 3.704-1.082 0-3.991-.056-5.174-.839a.938.938 0 0 1-.42-.822V11.523c0-.42 1.507-.458 2.005-.458.379 0 .685.191.685.707zM113.671 22.677v-1.623a4.418 4.418 0 0 0-1.776-.343c-.82 0-1.012.343-1.012 1.203 0 .764.192 1.107 1.012 1.107a4.701 4.701 0 0 0 1.776-.344zm2.693-4.24v4.773c0 1.566-3.706 1.833-5.271 1.833-2.345 0-2.902-1.374-2.902-3.302 0-1.643.305-2.961 2.902-2.961.866.013 1.73.109 2.578.286v-.706c0-.84-.344-1.299-.993-1.375a4.754 4.754 0 0 0-1.145.018c-.383.04-.902.116-1.319.192-.416.075-.687-1.165-.84-1.528a.81.81 0 0 1-.038-.478.371.371 0 0 1 .287-.267c.89-.246 1.807-.374 2.73-.38 2.692 0 4.01 1.354 4.01 3.895z"
          fill="#0055B8"
        />
        <path fill="url(#8bn2ygloia)" d="m8.822 12.775 12.76-1.971L10.792 0z" transform="translate(3.636 7.273)" />
        <path fill="url(#49mer0rybb)" d="m0 10.804 8.822 1.97L10.792 0z" transform="translate(3.636 7.273)" />
        <path fill="url(#hbj4b1k1vc)" d="m8.822 12.775 12.76-1.971-10.79 10.778z" transform="translate(3.636 7.273)" />
        <path fill="url(#dcyzw9ltkd)" d="m0 10.804 8.822 1.97 1.97 8.808z" transform="translate(3.636 7.273)" />
      </g>
    </svg>
  );
}

export default FibaBankLogoIcon;
