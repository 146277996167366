import { Grid, Box, Typography } from '@material-ui/core';
import { FC, Fragment, memo, useEffect } from 'react';
import { useLocalization } from 'Hooks';
import { AbonmanIcon, ArrowUpIcon, BigTransactionIcon, TimeIcon } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { fontSizes, gutters } from 'Theme/Variables';
import { cardItemDetailType } from '../constant';
import { userStore } from 'Stores/User/slices';
import {
  cardIsLoading,
  onRegisterCardTransactionRequest,
  storeCardTransactions,
  setActiveSelectedCard,
  setIsUserInUploadTLProcess,
  setIsUserInUploadSubscriptionProcess,
  setIsUserInAutomaticInstructionProcess,
  onCheckCardIsUploadableSubsRequest,
} from 'Stores/CardTransactions/slices';
import { registerCardTransactionNormalize } from 'Normalize';
import { routePath } from 'Navigator/routes';
import { OtherCardLastTransactionItem } from 'Views/OtherCardDetails/OtherCardTransactions/OtherCardLastTransactionItem';
import { TransactionButton } from 'Views/CardDetails/CardTransactions/TransactionButton';
import { useHistory } from 'react-router';
import { creditCardTransactionsStore } from 'Stores/CreditCardTransactions';
import { CantUploadSubsModal } from 'Views/CardDetails/CardTransactions/CantUploadSubsModal';

import './OtherCardTransactions.scss';

type OtherCardTransactionsProps = {
  cardColorClass?: string;
  cardItem: cardItemDetailType;
  cardPath?: string;
};

const OtherCardTransactions: FC<OtherCardTransactionsProps> = memo(({ cardColorClass, cardItem, cardPath }) => {
  const {
    web_card_detail_upload_tl,
    web_card_detail_money_transfer,
    web_card_detail_last_transactions,
    web_card_detail_all_transactions,
    web_desc_card_detail_last_transactions,
    web_card_detail_upload_subscriptipn,
    web_card_detail_automatic_instruction,
  } = useLocalization();

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector(userStore);
  const cardTransactionData = useSelector(storeCardTransactions);
  const creditCardStoreData = useSelector(creditCardTransactionsStore);
  const isLoading = useSelector(cardIsLoading);

  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;
  const cardTransactions = cardTransactionData?.activeCardTransactions;
  const userCreditCards = creditCardStoreData?.creditCardList;

  useEffect(() => {
    dispatch(
      onRegisterCardTransactionRequest(
        registerCardTransactionNormalize(cardItem?.MifareId, customerNumber, sessionToken)
      )
    );
  }, []);

  return (
    <div id="otherCardTransactions">
      <Box>
        <CantUploadSubsModal />
      </Box>
      <Box className="otherCardTransactions__container">
        <Fragment>
          <Box className={`otherCardTransactions__${cardColorClass}`}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TransactionButton
                  title={web_card_detail_upload_tl}
                  Icon={ArrowUpIcon}
                  itemID="uploadTLBtnTest"
                  onClick={() => {
                    dispatch(setIsUserInUploadTLProcess(true));
                    dispatch(
                      setActiveSelectedCard({
                        ...cardItem,
                        cardColorClass,
                        cardPath,
                        isOtherCard: true,
                      })
                    );
                    if (userCreditCards && userCreditCards.length !== 0) {
                      history.push(routePath.addBalanceToCard);
                    } else {
                      history.push(routePath.addCreditCard);
                    }
                  }}
                />
              </Grid>
              {cardItem?.SeasonTicketLoadable && (
                <Grid item xs={6}>
                  <TransactionButton
                    title={web_card_detail_upload_subscriptipn}
                    Icon={AbonmanIcon}
                    itemID="uploadSubscriptionBtnTest"
                    onClick={() =>
                      dispatch(
                        onCheckCardIsUploadableSubsRequest({
                          ...cardItem,
                          cardColorClass,
                          cardPath,
                        })
                      )
                    }
                  />
                </Grid>
              )}
              {/*  Faz-2 İçin saklanmıştır */}
              {/*   <Grid item xs={6}>
                <TransactionButton title={web_card_detail_money_transfer} Icon={BigTransactionIcon} onClick={() => console.log('hey')} />
              </Grid>
              <Grid item xs={6}>
                <TransactionButton
                  title={web_card_detail_automatic_instruction}
                  Icon={TimeIcon}
                  onClick={() => {
                    dispatch(setIsUserInAutomaticInstructionProcess(true));
                    dispatch(
                      setActiveSelectedCard({
                        ...cardItem,
                        cardColorClass,
                        cardPath,
                        isOtherCard: true,
                      })
                    );
                    if (userCreditCards && userCreditCards.length !== 0) {
                      history.push(routePath.automaticInstruction);
                    } else {
                      history.push(routePath.addCreditCard);
                    }
                  }}
                />
              </Grid> */}
            </Grid>
          </Box>
          <Box className="otherCardTransactions__last-transactions">
            <Typography variant="h3">
              <Box fontWeight="bold" fontSize={fontSizes.regularBig} marginBottom={gutters.regular}>
                {web_card_detail_last_transactions}
              </Box>
            </Typography>
            {cardTransactions && cardTransactions.length > 0
              ? cardTransactions
                  .slice(0, 4)
                  .map((item, index) => <OtherCardLastTransactionItem transactionItem={item} key={index} />)
              : !isLoading && <Typography variant="subtitle2">{web_desc_card_detail_last_transactions}</Typography>}
            {cardTransactions && cardTransactions?.length > 4 && (
              <Box textAlign="center">
                <Typography variant="h3">
                  <Box
                    className="otherCardTransactions__see--transactions"
                    onClick={() =>
                      history.push(routePath.cardTransactionHistory, {
                        ...cardItem,
                        isOtherCard: true,
                      })
                    }
                  >
                    {web_card_detail_all_transactions}
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Fragment>
      </Box>
    </div>
  );
});

export default OtherCardTransactions;
