import * as yup from 'yup';
import { errorMessages } from 'Config';

export const UpdatePhoneNumberSchema = yup.lazy(({ countryCode }) =>
  yup.object({
    phone: yup
      .string()
      .required(errorMessages.require)
      .min(countryCode === '90' ? 12 : 0, errorMessages.minPhone),
    countryCode: yup.string().required(),
  })
);
export type UpdatePhoneNumberSchemaInput = yup.InferType<typeof UpdatePhoneNumberSchema>;

export const UpdatePhoneNumberSchemaInitialValues = (phone: string, countryCode: string) => {
  return {
    phone,
    countryCode,
  };
};
