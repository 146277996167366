import * as yup from 'yup';
import { errorMessages } from 'Config';

export const LotteryFormSchema = yup.object({
  nameSurname: yup.string().required(errorMessages.require),
  tckn: yup.string().required(errorMessages.require).min(11, errorMessages.minTc),
  instagramUserName: yup.string().required(errorMessages.require),
  aggrement: yup.bool().oneOf([true], errorMessages.checkboxTrue),
});

export type LotteryFormSchemaInputs = yup.InferType<typeof LotteryFormSchema>;

export const LotteryFormSchemaInitialValues: LotteryFormSchemaInputs = {
  nameSurname: '',
  tckn: '',
  instagramUserName: '',
  aggrement: false,
};
