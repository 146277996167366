import { axiosHelper } from 'Services/AxiosHelper';
import { getApiGWTokenCookie } from 'Utils';
import { CardApplicationUpdateModel, GetCardVisaModel } from 'Models';
import { OnUpdateApplicationBodyData } from 'Config';

const command = 'RI.OnUpdateApplication';

const onUpdateApplication = async (params: OnUpdateApplicationBodyData): Promise<CardApplicationUpdateModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onUpdateApplication;
