import { axiosHelper } from 'Services/AxiosHelper';
import { onOnlineApplicationQueryBodyData } from 'Config';
import { getApiGWTokenCookie } from 'Utils';
import { OnOnlineApplicationQueryModel } from 'Models';

const command = 'RI.OnOnlineApplicationQuery';

const onOnlineApplicationQuery = async (
  params: onOnlineApplicationQueryBodyData
): Promise<OnOnlineApplicationQueryModel> =>
  (
    await axiosHelper({
      method: 'post',
      gatewayData: {
        command,
        token: getApiGWTokenCookie(),
      },
      data: params,
    })
  ).data;

export default onOnlineApplicationQuery;
