import { FC } from 'react';
import { useLocalization } from 'Hooks';
import { WelcomeDrawer } from 'Views/Welcome/WelcomeHeader/WelcomeDrawer';
import { HomeIcon, ProfileIcon, TinyWhiteCardIcon, TransactionIcon, LocationIcon, PlusIcon } from 'Components';

import './HomeDrawer.scss';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';

type HeaderMenuItemType = {
  title: string;
  onClick: () => void;
  Icon?: any;
  itemID: string;
};

type HomeDrawerProps = {};

const HomeDrawer: FC<HomeDrawerProps> = () => {
  const history = useHistory();

  const {
    web_link_btn_kartlarim_homepage,
    web_link_btn_islemler_homepage,
    web_link_btn_kart_basvurusu_homepage,
    web_hesabım_profil_homepage,
    web_link_btn_anasayfa_homepage,
    web_link_btn_gez_ve_harca_homepage,
  } = useLocalization();

  const menuItems: HeaderMenuItemType[] = [
    {
      title: web_link_btn_kart_basvurusu_homepage,
      onClick: () => history.push(routePath.cardApplication),
      Icon: PlusIcon,
      itemID: 'navigateCardApplicationBtnTest',
    },
    {
      title: web_link_btn_gez_ve_harca_homepage,
      onClick: () => history.push(routePath.discover),
      Icon: LocationIcon,
      itemID: 'navigateAccountBtnTest',
    },
    {
      title: web_link_btn_islemler_homepage,
      onClick: () => history.push(routePath.transactions),
      Icon: TransactionIcon,
      itemID: 'navigateTransactionsBtnTest',
    },
    {
      title: web_link_btn_kartlarim_homepage,
      onClick: () => history.push(routePath.cards),
      Icon: TinyWhiteCardIcon,
      itemID: 'navigateCardsBtnTest',
    },
    {
      title: web_hesabım_profil_homepage,
      onClick: () => history.push(routePath.myAccount),
      Icon: ProfileIcon,
      itemID: 'navigateAccountBtnTest',
    },
    {
      title: web_link_btn_anasayfa_homepage,
      onClick: () => history.push(routePath.home),
      Icon: HomeIcon,
      itemID: 'navigateHomePageBtnTest',
    },
  ];

  return <WelcomeDrawer menuItems={menuItems} isDrawerLinks={false} />;
};
export default HomeDrawer;
